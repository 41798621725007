import { useEffect, useState } from "react";
import {
  fetchProjectOverview,
  fetchProjectOngoingTasks,
  fetchProjectResources,
  fetchProjectTimeline,
  fetchProjectRunningCost,
} from "../../../common/services/Project";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import noResult from "../../../../assets/noResult.svg";
import toast from "react-hot-toast";
import { setViewProjectTab } from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BarChart from "../../../common/Charts/BarChart";
import { TooltipItem } from "chart.js"; 


ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip
);

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

export interface ProjectOverView {
  ProjectId: number;
  project_name: string;
  status: string;
  start_date: string;
  end_date: string | null;
  resources: Resource;
  tasks: Task;
  time_logs: TimeLog;
  contractual_hours: number | null;
  contractual_cost: number | null;
}

interface Resource {
  total_resource: string | number;
  // active_resource: string | number;
  // final_value: string;
  percentage: string;
}

interface Task {
  // total_task: string | number;
  // completed_task: string | number;
  final_value: string;
  percentage: string;
}

interface TimeLog {
  actual_hours: number;
  logged_hours: number;
  final_value: string;
  percentage: string;
}

interface IProjectOngoingTasks {
  tasks: Task2[];
  totalTasks: number;
  limit: number;
  totalPages: number;
}

interface Task2 {
  id: number;
  task_name: string;
  description: string | null;
  task_document: string[];
  start_date: string;
  end_date: string | null;
  status: string;
}

interface IProjectResourcesList {
  id: number;
  billing_cost: number | null;
  User: User2;
}

interface User2 {
  resource_id: number;
  employee_id: string;
  employee_code: string;
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  Department: Department;
}

interface Department {
  name: string;
}

interface WeeklyCostData {
  estimatedCost: number;
  actualCost: number;
}

interface CostOverviewData {
  label: string;
  data: number[];
  color: string;
  borderColor?: string;
  borderWidth?: number;
}

interface IHoursData {
  estimated_hours: number;
  actual_hours: number;
}

interface IHoursOverviewData {
  label: string;
  data: number[];
  color: string;
}

interface ProjectOverviewProps {
  startDate?: Date | null;
  endDate?: Date | null;
}

const ProjectOverview: React.FC<ProjectOverviewProps> = ({
  startDate,
  endDate,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const id = location.state?.id;
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [loading, setLoading] = useState(true);
  const [tableDataOverview, setTableDataOverview] = useState<ProjectOverView>();
  const [tableDataProjectOngoingTasks, setTableDataProjectOngoingTasks] =
    useState<Task2[]>([]);
  const [tableDataProjectResources, setTableDataProjectResources] = useState<
    IProjectResourcesList[]
  >([]);
  // const [runningCostData, setRunningCostData] = useState<
  //   Record<string, WeeklyCostData>
  // >({});
  const [runningCostData, setRunningCostData] = useState<WeeklyCostData>({
    estimatedCost: 0,
    actualCost: 0,
  });
  const [timelineData2, setTimelineData2] = useState<IHoursData>({
    estimated_hours: 0,
    actual_hours: 0,
  });
  // const [timelineData, setTimelineData] = useState([]);
  // const [view, setView] = useState("Weekly");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const projectOverviewData = await fetchProjectOverview(
          projectId,
          startDate ? startDate : null,
          endDate ? endDate : null
        );
        if (projectOverviewData?.result) {
          const projectOverview = {
            ProjectId: projectOverviewData?.result?.ProjectId,
            project_name: projectOverviewData?.result?.project_name,
            status: projectOverviewData?.result?.status,
            start_date: projectOverviewData?.result?.start_date,
            end_date: projectOverviewData?.result?.end_date,
            contractual_hours: projectOverviewData?.result?.estimated_hours,
            contractual_cost: projectOverviewData?.result?.contractual_cost,
            resources: {
              total_resource: String(
                projectOverviewData?.result?.resources?.total_resource
              ),
              percentage: String(
                projectOverviewData?.result?.resources?.percentage
              ),
            },
            tasks: {
              final_value: String(
                projectOverviewData?.result?.tasks?.final_value
              ),
              percentage: String(
                projectOverviewData?.result?.tasks?.percentage
              ),
            },
            time_logs: {
              final_value: String(
                projectOverviewData?.result?.time_logs?.final_value
              ),
              percentage: String(
                projectOverviewData?.result?.time_logs?.percentage
              ),
              actual_hours: Number(
                projectOverviewData?.result?.time_logs?.actual_hours
              ),
              logged_hours: Number(
                projectOverviewData?.result?.time_logs?.logged_hours
              ),
            },
          };
          setTableDataOverview(projectOverview);
        }
      } catch (error) {
        toast.error("Error fetching project overview.");
        console.error(error);
      }

      try {
        const ongoingTasksData = await fetchProjectOngoingTasks(
          projectId,
          5,
          startDate ? startDate : null,
          endDate ? endDate : null
        );
        if (ongoingTasksData?.result) {
          setTableDataProjectOngoingTasks(
            ongoingTasksData?.result?.tasks ?? {}
          );
        }
      } catch (error) {
        toast.error("Error fetching ongoing tasks.");
        console.error(error);
      }

      try {
        const resourcesData = await fetchProjectResources(
          projectId,
          5,
          startDate ? startDate : null,
          endDate ? endDate : null
        );
        if (resourcesData?.result) {
          setTableDataProjectResources(resourcesData?.result?.rows ?? {});
        }
      } catch (error) {
        toast.error("Error fetching project resources.");
        console.error(error);
      }

      try {
        const runningCostData = await fetchProjectRunningCost(
          projectId,
          startDate ? startDate : null,
          endDate ? endDate : null
        );
        if (runningCostData?.result) {
          setRunningCostData(runningCostData?.result ?? {});
        } else {
          toast.error("Error fetching running cost data.");
        }
      } catch (error) {
        toast.error("Error fetching running cost.");
        console.error(error);
      }

      setLoading(false);
    };

    fetchData();
  }, [projectId, startDate, endDate]);

  useEffect(() => {
    const fetchTimeline = async () => {
      try {
        const timelineData = await fetchProjectTimeline(
          projectId,
          // view === "Weekly" ? "week" : "month",
          startDate ? startDate : null,
          endDate ? endDate : null
        );
        if (timelineData.result) {
          setTimelineData2(timelineData?.result ?? {});
        }
      } catch (error) {
        toast.error("Error fetching timeline data.");
        console.error(error);
      }
    };

    fetchTimeline();
  }, [projectId, startDate, endDate]);

  const taskDataDoughNut = {
    labels: ["Completed", "Remaining"],
    datasets: [
      {
        data: [
          tableDataOverview?.tasks?.percentage || 0,
          Number(
            (100 - (Number(tableDataOverview?.tasks?.percentage) || 0)).toFixed(
              2
            )
          ),
        ],
        backgroundColor: ["#A0D4A3", "#CEDFFF"],
        // hoverBackgroundColor: ["#0F9446", "#3968F233"],
        hoverBorderColor: ["transparent"],
      },
    ],
  };

  const optionsDoughtNut: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // title: {
      //   display: false,
      // },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          color: "#1C2024",
          boxWidth: 15,
          boxHeight: 15,
          borderRadius: 4,
        },
      },
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "#000000",
        borderColor: "transparent",
        borderWidth: 1,
        callbacks: {
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const dataIndex = tooltipItem.dataIndex;
            const label = dataset.labels ? dataset.labels[dataIndex] : "";
            const value = dataset.data ? dataset.data[dataIndex] : 0;
            return `${label} : ${value}%`;
          },
          labelColor: function (tooltipItem: any) {
            const backgroundColor = tooltipItem.dataset.backgroundColor
              ? tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex]
              : "#000000";
            return {
              borderColor: "transparent",
              backgroundColor: backgroundColor,
            };
          },
        },
      },
    },
  };

  const costOverviewLabels: string[] = ["Cost"];
  // const costOverviewLabels: string[] = Object?.keys(runningCostData);
  // const estimatedCosts: number[] = costOverviewLabels?.map(
  //   (week) => runningCostData[week]?.estimatedCost ?? 0
  // );
  // const actualCosts: number[] = costOverviewLabels?.map(
  //   (week) => runningCostData[week]?.actualCost ?? 0
  // );

  const costOverviewData: CostOverviewData[] = [
    {
      label: "Estimated Cost",
      data: [runningCostData?.estimatedCost],
      color: "#8AB3FF",
      borderWidth: 0,
      borderColor: "transparent",
    },
    {
      label: "Actual Cost",
      data: [runningCostData?.actualCost],
      color: "#EDD9F2",
      borderWidth: 0,
      borderColor: "transparent",
    },
  ];

  const costOverviewOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#1C2024",
          boxWidth: 15,
          boxHeight: 15,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        borderWidth: 0, 
        borderColor: "transparent",
        callbacks: {
          labelColor: function (tooltipItem: TooltipItem<"bar">) { 
            return {
              borderWidth: 0, 
              borderColor: "transparent", 
              backgroundColor: tooltipItem?.dataset?.backgroundColor , 
            };
          },
        },
      },      
    },
    scales: {
      x: {
        display: false,
        stacked: false,
        grid: { display: false },
      },
      y: {
        stacked: false,
      },
    },
    datasets: {
      bar: {
        categoryPercentage: 0.6,
        barPercentage: 0.5,
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    },
  };

  const hoursLabels: string[] = ["Hours"];
  // const costOverviewLabels: string[] = Object?.keys(runningCostData);
  // const estimatedCosts: number[] = costOverviewLabels?.map(
  //   (week) => runningCostData[week]?.estimatedCost ?? 0
  // );
  // const actualCosts: number[] = costOverviewLabels?.map(
  //   (week) => runningCostData[week]?.actualCost ?? 0
  // );

  const hoursData: IHoursOverviewData[] = [
    {
      label: "Estimated Hours",
      data: [timelineData2?.estimated_hours],
      color: "#A0D4A3",
    },
    {
      label: "Actual Hours",
      data: [timelineData2?.actual_hours],
      color: "#F97C7C",
    },
  ];

  const hoursOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#1C2024",
          boxWidth: 15,
          boxHeight: 15,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        borderWidth: 0, 
        borderColor: "transparent",
        callbacks: {
          labelColor: function (tooltipItem: TooltipItem<"bar">) { 
            return {
              borderWidth: 0, 
              borderColor: "transparent", 
              backgroundColor: tooltipItem?.dataset?.backgroundColor , 
            };
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        stacked: false,
        grid: { display: false },
      },
      y: {
        stacked: false,
        // ticks: { beginAtZero: true },
      },
    },
    datasets: {
      bar: {
        categoryPercentage: 0.6,
        barPercentage: 0.7,
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    },
  };

  return (
    <div className="overflow-x-auto mt-1">
      <div>
        {/* Metrics Section */}
        <div className="flex gap-5">
          {/* Active Resources */}
          <div className="flex flex-col p-4 flex-[1_0_0] border border-gray-200 rounded-lg shadow-sm bg-white">
            <div className="text-[#4B5563] text-base font-medium leading-6 font-inter">
              Total Resources
            </div>
            <div className="text-[#1F2937] text-2xl font-semibold leading-8 font-inter">
              {tableDataOverview?.resources?.total_resource || "-"}
            </div>
            {/* <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
              <div
                className="h-2 rounded-full"
                style={{
                  width: `${
                    Number(tableDataOverview?.resources?.percentage) || 0
                  }%`,
                  background:
                    "linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%)",
                }}
              ></div>
            </div> */}
          </div>

          {/* Total Tasks */}
          <div className="flex flex-col p-4 flex-[1_0_0] border border-gray-200 rounded-lg shadow-sm bg-white">
            <div className="text-[#4B5563] text-base font-medium leading-6 font-inter">
              Total Task
            </div>
            <div className="flex justify-between w-full">
              <div className="text-[#1F2937] text-2xl font-semibold leading-8 font-inter">
                {tableDataOverview?.tasks?.final_value || "-"}
              </div>
              <div className="text-[#6B7280] text-[12px] font-normal leading-[12px] text-right font-poppins mt-auto">
                {tableDataOverview?.tasks?.percentage || "-"}%
              </div>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
              <div
                className="h-2 rounded-full"
                style={{
                  width: `${
                    Number(tableDataOverview?.tasks?.percentage) || 0
                  }%`,
                  background:
                    "linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%)",
                }}
              ></div>
            </div>
          </div>

          <div className="flex flex-col p-4 flex-[1_0_0] border border-gray-200 rounded-lg shadow-sm bg-white">
            <div className="text-[#4B5563] text-base font-medium leading-6 font-inter">
              Contractual Hours
            </div>
            <div className="text-[#1F2937] text-2xl font-semibold leading-8 font-inter">
              {tableDataOverview?.contractual_hours || "-"}
            </div>
          </div>

          <div className="flex flex-col p-4 flex-[1_0_0] border border-gray-200 rounded-lg shadow-sm bg-white">
            <div className="text-[#4B5563] text-base font-medium leading-6 font-inter">
              Contractual Cost
            </div>
            <div className="text-[#1F2937] text-2xl font-semibold leading-8 font-inter">
              {tableDataOverview?.contractual_cost || "-"}
            </div>
          </div>

          {/* Time Logged */}
          <div className="flex flex-col p-4 flex-[1_0_0] border border-gray-200 rounded-lg shadow-sm bg-white">
            <div className="text-[#4B5563] text-base font-medium leading-6 font-inter">
              Time Logged
            </div>
            <div className="flex justify-between w-full">
              <div className="text-[#1F2937] text-base font-semibold leading-8 font-inter">
                {/* {tableDataOverview?.time_logs?.final_value || "--"}hrs */}
                {`${tableDataOverview?.time_logs
                  ?.logged_hours!}/${tableDataOverview?.time_logs
                  ?.actual_hours!}hrs`}
              </div>
              <div className="text-[#6B7280] text-[12px] font-normal leading-[12px] text-right font-poppins mt-auto">
                {tableDataOverview?.time_logs?.percentage || "-"}%
              </div>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
              <div
                className="h-2 rounded-full"
                style={{
                  width: `${
                    Number(tableDataOverview?.time_logs?.percentage) || 0
                  }%`,
                  background:
                    "linear-gradient(90deg, #4165C4 0%, #8E1FD2 100%)",
                }}
              ></div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-wrap gap-5 my-5">
          <div className="flex flex-col justify-between w-full sm:w-[257px] h-[298px] p-4 bg-[#FCFCFC] border border-gray-200 rounded-[6px]">
            <h2 className="text-gray-800 text-[16px] font-semibold leading-[16px] tracking-[-0.4px] font-poppins mt-3">
              Project Progress
            </h2>
            <div className="h-56">
              <Doughnut data={taskDataDoughNut} options={optionsDoughtNut} />
            </div>
            {/* <div className="flex justify-center">
              <div className="flex items-center mr-4">
                <div className="w-4 h-4 mr-2 bg-[#0F9446]"></div>
                <span>Completed</span>
              </div>
              <div className="flex items-center">
                <div className="w-4 h-4 mr-2 bg-[#3968F2] bg-opacity-20"></div>
                <span>Remaining</span>
              </div>
            </div> 
          </div>
          <div className="flex flex-col justify-between items-start w-full sm:flex-1 h-[298px] p-4 bg-[#FCFCFC] border border-gray-200 rounded-[6px]">
            <TimeLogChart />
          </div>
          <div className="flex flex-col justify-between items-start w-full sm:w-[378px] h-[298px] p-4 bg-[#FCFCFC] border border-gray-200 rounded-[6px]">
            <TimelineChart />
          </div>
        </div> */}

        <div className="flex flex-wrap gap-5 my-5">
          {/* Project Progress */}
          <div className="flex flex-grow flex-col justify-between w-full sm:w-[48%] md:w-[48%] lg:w-[25%] h-[298px] p-4 bg-[#FCFCFC] border border-gray-200 rounded-[6px]">
            <h2 className="text-gray-800 text-[16px] font-semibold leading-[16px] tracking-[-0.4px] font-poppins mt-2 mb-1">
              Project Progress
            </h2>
            <div className="h-56">
              <Doughnut data={taskDataDoughNut} options={optionsDoughtNut} />
            </div>
          </div>

          {/* Time Log Overview */}
          <div className="flex flex-grow flex-col justify-between items-start w-full sm:w-[48%] md:w-[48%] lg:w-[40%] h-[298px] p-4 bg-[#FCFCFC] border border-gray-200 rounded-[6px]">
            {/* <TimeLogChart /> */}
            <div className="flex justify-between items-center mb-4">
              <p className="text-light-black font-semibold text-base font-['Poppins']">
                Running Cost
              </p>
            </div>
            <div className="flex justify-center w-full h-[81%] mt-2">
              <BarChart
                chartData={costOverviewData}
                labels={costOverviewLabels}
                options={costOverviewOptions}
              />
            </div>
          </div>

          {/* Timeline Chart */}
          <div className="flex flex-grow flex-col justify-between items-start w-full sm:w-[98%] md:w-[98%] lg:w-[31%] h-[298px] p-4 bg-[#FCFCFC] border border-gray-200 rounded-[6px]">
            {/* <TimelineChart
              timelineData={timelineData}
              view={view}
              setView={setView}
            /> */}
            <div className="flex justify-between items-center mb-4">
              <p className="text-light-black font-semibold text-base font-['Poppins']">
                Hours Overview
              </p>
            </div>
            <div className="flex justify-center w-full h-[81%] mt-2">
              <BarChart
                chartData={hoursData}
                labels={hoursLabels}
                options={hoursOptions}
              />
              {/* <StackedBarChart
                chartData={hoursData}
                labels={hoursLabels}
                options={hoursOptions}
                barThickness={70}
              /> */}
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          {/* <div className="flex flex-col items-start gap-[15px] h-[298px] p-[16px_16px_0px_16px] flex-[1_0_0] border border-gray-200 rounded-[6px]"> */}
          <div className="bg-white border border-gray-200 rounded-lg p-4 w-1/2">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col justify-center text-gray-800 text-[16px] font-semibold leading-[16px] tracking-[-0.4px] font-poppins">
                Ongoing Tasks
              </div>
              {tableDataProjectOngoingTasks?.length >= 5 && (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => {
                    // setValue("tasks");
                    dispatch(setViewProjectTab("tasks"));
                  }}
                >
                  View All
                </button>
              )}
            </div>

            <ul className="space-y-3.5">
              {tableDataProjectOngoingTasks &&
              tableDataProjectOngoingTasks?.length > 0 ? (
                tableDataProjectOngoingTasks?.map((task: Task2) => (
                  <li
                    key={task?.id}
                    className="flex justify-between items-center"
                  >
                    <div className="">
                      <p className="text-gray-900 text-[14px] font-normal leading-[18px] font-poppins">
                        {task?.task_name}
                      </p>
                      <p className="text-gray-500 text-[10px] font-normal leading-[13px] font-poppins">
                        Due: {task?.end_date}
                      </p>
                    </div>
                    <span className="flex items-center justify-center gap-[10px] px-[10px] py-[2px] rounded-[4px] bg-[rgba(194,237,255,0.48)] text-[12px] font-medium leading-[16px] text-[#3968F2] font-poppins">
                      {/* {task.status} */}
                      Ongoing
                    </span>
                  </li>
                ))
              ) : (
                <div className="flex justify-center items-center h-48">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </ul>
          </div>
          {/* </div> */}

          {/* <div className="flex flex-col items-start gap-[15px] h-[298px] px-4 pt-4 pb-0 flex-[1_0_0] border border-gray-200 rounded-[6px]"> */}
          <div className="bg-white border border-gray-200 rounded-lg p-4 w-1/2">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col justify-center text-gray-800 text-[16px] font-semibold leading-[16px] tracking-[-0.4px] font-poppins">
                Resources
              </div>
              {tableDataProjectResources?.length >= 5 && (
                <button
                  className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
                  onClick={() => {
                    // setValue("resources");
                    dispatch(setViewProjectTab("resources"));
                  }}
                >
                  View All
                </button>
              )}
            </div>

            {/* Resource List */}
            <ul className="space-y-1">
              {tableDataProjectResources &&
              tableDataProjectResources?.length > 0 ? (
                tableDataProjectResources?.map(
                  (resource: IProjectResourcesList) => (
                    <li
                      key={resource?.id}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={resource?.User?.profile_image}
                          alt={`${resource?.User?.firstName} ${resource?.User?.lastName}`}
                          className="flex w-[36px] h-[36px] shrink-0 rounded-full justify-center items-center bg-lightgray bg-cover bg-center"
                        />
                        <div>
                          <p
                            className="text-[#020617] text-[14px] cursor-pointer font-medium leading-[20px] font-poppins"
                            onClick={() => {
                              navigate("/employee-list-details", {
                                state: { id: resource?.User?.resource_id },
                              });
                            }}
                          >
                            {`${resource?.User?.firstName} ${resource?.User?.lastName}`}
                          </p>
                          <p className="text-[#64748B] text-[14px] font-normal leading-[20px] font-poppins">
                            {resource?.User?.employee_id}
                          </p>
                        </div>
                      </div>
                      <span className="flex items-center justify-center gap-[10px] px-[10px] py-[2px] rounded-[4px] bg-[rgba(15,148,70,0.10)] text-[#0F9446] text-[12px] font-medium leading-[16px] font-poppins">
                        Active
                      </span>
                    </li>
                  )
                )
              ) : (
                <div className="flex justify-center items-center h-48">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </ul>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectOverview;
