import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import right from "../../../../image/chevron-right.png";
import { useNavigate } from "react-router-dom";
import upload from "../../../../assets/plus 1.png";
import profile from "../../../../assets/Ellipse 580.png";
import {
  createTaskApi,
  Fetch_Task_List,
} from "../../../common/services/Project";
import toast from "react-hot-toast";
import eye from "../../../../assets/Crud_Icons/view.svg";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../common/Button";
import { ArrowUpDown } from "lucide-react";
import AddTaskDialog from "../AddTaskDialog";
import DynamicTable from "../../../common/DynamicTable";
import TablePaginationDemo from "../../../common/Pagenation";
import { debounce } from "lodash";
import Search from "../../../SearchBar/Search";
import Search2 from "../../../SearchBar/Search2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setProjectId,
  setProjectTab,
} from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { convertHoursIntoHoursMinutes } from "../../../../utils/helperData";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface FormValues {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  status: string;
}
interface RowData {
  id: number;
  project_id: number;
  customer_id: number;
  group_id: number | null;
  task_name: string;
  estimated_hours: number;
  actual_hours: number;
  start_date: string;
  end_date: string;
  status: string;
  description: string;
  task_document: any[];
  TaskResources: TaskResource[];
  Module: { module_name: string } | null;
  ProjectMilestone: {
    milestone_name: string;
  } | null;
  efforts: string | null;
}

interface TaskResource {
  id: number;
  task_id: number;
  user_id: number;
  User: User;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  Department: Department;
  Designation: Designation;
  employee_id: string;
  profile_image: string;
}

interface Department {
  id: number;
  name: string;
}

interface Designation {
  id: number;
  name: string;
}

const AddTask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [show, setShow] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; project_id: number }[]
  >([]);
  // const param = useParams();

  const Status = [
    { value: "inprogress", label: "Working" },
    { value: "todo", label: "To do" },
    { value: "completed", label: "Finished" },
    { value: "onhold", label: "Hold" },
  ];
  const statusMap = Object.fromEntries(
    Status.map(({ value, label }) => [value, label])
  );

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: "milestone_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Milestone / Sprint
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.ProjectMilestone?.milestone_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.ProjectMilestone?.milestone_name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "module_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Module
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Module?.module_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">{row?.original?.Module?.module_name ?? "-"}</div>
        );
      },
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },

    {
      accessorKey: "task_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "category",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Assigned To
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.TaskResources?.[0]?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const data = row?.original;

        if (data?.TaskResources?.length > 0) {
          const [firstUser, ...otherUsers] = data.TaskResources;

          return (
            <div className="flex items-center gap-1">
              {firstUser?.User && (
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={firstUser?.User?.profile_image || profile}
                    alt="Profile"
                  />
                  <div className="flex flex-col justify-between">
                    <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                      {firstUser?.User?.firstName} {firstUser?.User?.lastName} (
                      {firstUser.User?.Department?.name})
                    </span>
                    <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                      {firstUser?.User?.employee_id}
                    </span>
                  </div>
                </div>
              )}

              {otherUsers?.length > 0 && (
                <span className="text-gray-900 pb-1">
                  ,{" "}
                  <span className="rounded-full p-1 bg-[#E5E7EB] ">
                    +{otherUsers?.length}
                  </span>
                </span>
              )}
            </div>
          );
        }

        return "-";
      },
    },

    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.start_date
          ? new Date(row.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.start_date
          ? new Date(row.original.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "end_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_date
          ? new Date(row.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.end_date
          ? new Date(row.original.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Estimated hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.estimated_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.estimated_hours
              ? convertHoursIntoHoursMinutes(
                  row?.original?.estimated_hours ?? 0
                )
              : "-"}
          </div>
        );
      },
      // cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status ?? "-";
        return <div>{statusMap[status] ?? status}</div>;
      },
    },
    // {
    //   accessorKey: "variance",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       className="p-0"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //     >
    //       Variance
    //       <ArrowUpDown className="ml-2 size-4" />
    //     </Button>
    //   ),
    //   // accessorFn: (row) =>
    //   //   row?.status
    //   //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
    //   //     : "-",
    //   cell: ({ row }) => {
    //     let diff = null;
    //     if (row?.original?.actual_hours && row?.original?.estimated_hours) {
    //       diff = row?.original?.actual_hours - row?.original?.estimated_hours;
    //     }
    //     return <div className="">{diff ?? "-"}</div>;
    //   },
    // },

    // {
    //   accessorKey: "status",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       className="p-0"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //     >
    //       Progress
    //       {/* <ArrowUpDown className="ml-2 size-4" /> */}
    //     </Button>
    //   ),
    //   // accessorFn: (row) =>
    //   //   row?.status
    //   //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
    //   //     : "-",
    //   cell: ({ row }) => {
    //     let diff = null;
    //     let status = "";
    //     if (row?.original?.actual_hours && row?.original?.estimated_hours) {
    //       diff = row?.original?.actual_hours - row?.original?.estimated_hours;
    //       status = diff > 0 ? "On Time" : "Delay";
    //     }

    //     return <div className="capitalize">{status ?? "-"}</div>;
    //   },
    // },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/view-task-details", {
                  state: { id: row.original.id, fromAdd: true },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-task", {
                  state: { id: row.original.id, fromAdd: true },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Task_List(
        {
          project_id: projectId,
          numOfItems: numOfItems,
          page: page,
          searchTerm: searchTerm,
        }
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        const rows = response?.data?.result?.rows || [];

        const formattedData = rows?.map((row: any) => ({
          id: row?.id,
          project_id: row?.project_id,
          customer_id: row?.customer_id,
          group_id: row?.group_id,
          task_name: row?.task_name,
          estimated_hours:
            row?.estimated_hours && row?.estimated_hours !== 0
              ? row?.estimated_hours
              : null,
          actual_hours: row?.actual_hours,
          start_date: row?.start_date,
          end_date: row?.end_date,
          status: row?.status,
          description: row?.description,
          task_document: row?.task_document,
          TaskResources: row?.TaskResources,
          Module: row?.Module,
          ProjectMilestone: row?.ProjectMilestone,
          efforts: row?.efforts,
        }));

        setTableData(formattedData);
        setCount(response?.data?.result?.count || 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems, searchTerm]);

  return (
    <>
      <div>
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() => {
                dispatch(setProjectTab(1));
                // navigate(`/add-resources/${param.id}`);
              }}
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-4 w-4 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
              Add Task
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => {
                dispatch(setProjectTab(1));
                // navigate(`/add-resources/${param.id}`)
              }}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <Button
              variant={"gradient"}
              onClick={() => {
                dispatch(setProjectId(null));
                dispatch(setProjectTab(0));
                navigate("/project-management");
              }}
            >
               <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >Save</div>
            </Button>
          </div>
        </div>

        {/* <div className="flex justify-end mt-10">
          <button
            onClick={() => setShow(true)}
            className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
          >
            <img className="w-4 h-4 mr-2" src={upload} alt="plus icon" />
            Add Task
          </button>
        </div> */}
        <div className="flex justify-between mt-5">
          <Search2 onChange={updateSearchTerm} label="Search with task name" />
          <button
            onClick={() => setShow(true)}
            className="flex items-center font-[600] text-[14px] py-1 px-4 buttoncss text-white rounded-lg"
          >
            <img className="w-4 h-4 mr-2" src={upload} alt="plus icon" />
            Add Task
          </button>
        </div>

        <div className="overflow-x-auto my-5">
          <div className="table_main_content w-full relative overflow-auto bg-white ">
            <DynamicTable<RowData>
              onRowClick={(row) => {
                navigate("/view-task-details", {
                  state: { id: row?.id, fromAdd: true },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>

          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={page}
              numOfItems={numOfItems}
            />
          )}
        </div>
      </div>
      <AddTaskDialog show={show} setShow={setShow} fetchData={fetchData} />
    </>
  );
};

export default AddTask;
