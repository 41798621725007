import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartDataItem {
  label: string;
  value: number;
  color: string;
}

interface DoughnutChartProps {
  chartData: ChartDataItem[];
  title?: string;
  options?: any; // Allows custom options from outside
}
export const centerTextPlugin = {
  id: "centerText",
  beforeDraw: (chart: any) => {
    if (!chart.options.showCenterText) return;

    const { width, height, ctx } = chart;
    ctx.save();

    const text = chart.options.centerText;

    if (!text) return;

    ctx.font = "bold 16px sans-serif";
    ctx.fillStyle = "#374151";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    const lines = text.split("\n");
    const lineHeight = 24;

    const totalTextHeight = lineHeight * lines.length;
    let offsetY = height / 2 - totalTextHeight / 2;

    // Draw each line of text
    lines.forEach((line: any, index: number) => {
      ctx.fillText(line, width / 2, offsetY + index * lineHeight);
    });

    ctx.restore();
  },
};

ChartJS.register(centerTextPlugin);
const DoughnutChart: React.FC<DoughnutChartProps> = ({
  chartData,
  title,
  options,
}) => {
  const data = {
    labels: chartData.map((item) => item.label),
    datasets: [
      {
        data: chartData.map((item) => item.value),
        backgroundColor: chartData.map((item) => item.color),
        borderWidth: 0,
        hoverOffset: 0,
        hoverBorderWidth: 0,
        borderColor: "transparent",
        hoverBorderColor: "transparent",
      },
    ],
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#000",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
      centerTextPlugin,
    },
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      {title && <h3 className="text-lg font-semibold mb-2">{title}</h3>}
      <Doughnut data={data} options={options || defaultOptions} />
    </div>
  );
};

export default DoughnutChart;
