import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="fixed inset-x-0 z-20 bottom-0 w-full bg-gray-800 text-white text-center">
      <div className="flex justify-center items-center text-center px-16 py-1 w-full bg-gray-900">
        © 2024-{currentYear} Go-EMS. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
