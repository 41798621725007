import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MyStatefulEditor from "../EditEditor";
import FileUpload from "../../ExpenseManagement/EditFileUoload";
import TextFieldWithPopup from "../EditTextFeildPopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import right from "../../../image/chevron-right.png";
import {
  Fetch_Milestone_List,
  fetchEditTask,
  updateTask,
  UploadTaskFileApi,
} from "../../common/services/Project";
import toast from "react-hot-toast";
import { EmployeeAction } from "../EditTextFeildPopup";
import { MilestoneOption } from "./AddProject/AddProjectDetails";
import { useSelector } from "react-redux";
import { Button } from "../../common/Button";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

interface FormValues {
  task_name: string;
  estimated_hours: string | number | null;
  actual_hours: string;
  status: string;
  module_name: string;
  project_id: number | null;
  milestone_id: number | null;
}

const EditTask = () => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);
  const projectStartDate = useSelector(
    (state: any) => state.projectSlice2.project_startDate
  );
  const projectEndDate = useSelector(
    (state: any) => state.projectSlice2.project_endDate
  );
  const [editorContent, setEditorContent] = useState<string>("");
  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  // const [projectID, setProjectId] = useState();
  const [loading, setLoading] = useState(false);
  const [autoFiles, setAutoFiles] = useState<UploadedFile[]>([]);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; action: string }[]
  >([]);
  const [taskName, setTaskName] = useState("");

  // const project_id = location?.state?.id;

  const navigate = useNavigate();
  const handleAutoFilesChange = (files: UploadedFile[]) => {
    setAutoFiles(files);
  };
  const param = useParams();
  const [milestoneOptions, setMilestoneOptions] = useState<MilestoneOption[]>(
    []
  );

  const location = useLocation();
  const id = location.state?.id;
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  // const projectId = location.state?.projectId;
  const path = location.state?.path;
  const fromAdd = location?.state?.fromAdd;
  const Status = [
    { value: "inprogress", label: "Working" },
    { value: "todo", label: "To do" },
    { value: "completed", label: "Finished" },
    { value: "onhold", label: "Hold" },
  ];

  const [formValues, setFormValues] = useState<FormValues>({
    task_name: "",
    estimated_hours: "",
    actual_hours: "",
    status: "",
    project_id: null,
    module_name: "",
    milestone_id: null,
  });

  const fetchMilestoneOptions = async () => {
    try {
      const response = await Fetch_Milestone_List({
        customer_id: formValues?.project_id || projectId,
        // numOfItems: 10,
        // page: 1,
      });
      if (response.status === 200) {
        setMilestoneOptions(
          response?.data?.result?.rows?.map((milestone: any) => ({
            id: Number(milestone?.id),
            name: milestone?.milestone_name,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching milestone options:", error);
    }
  };

  useEffect(() => {
    fetchMilestoneOptions();
  }, [id, formValues?.project_id, projectId]);

  const fetchTaskData = async () => {
    try {
      setLoading(true);
      const data = await fetchEditTask(id);

      if (data?.result) {
        const taskResources = data?.result?.TaskResources || [];
        const taskDocuments = data?.result?.task_document || [];

        const files = taskDocuments?.map((url: string) => ({ url }));

        setTaskName(data.result?.task_name ?? "");
        setFormValues({
          task_name: data.result?.task_name,
          estimated_hours:
            data.result?.estimated_hours &&
            Number(data.result?.estimated_hours) !== 0
              ? Number(data.result?.estimated_hours)
              : null,
          actual_hours: data.result?.actual_hours,
          status: data.result?.status,
          project_id: data.result?.project_id,
          module_name: data.result?.Module?.module_name,
          milestone_id: data.result?.ProjectMilestone?.id,
        });

        setStartData(
          data.result?.start_date ? dayjs(data.result.start_date) : null
        );
        setEndDate(data?.result?.end_date ? dayjs(data.result.end_date) : null);
        setEditorContent(data.result?.description);
        setInitialFiles(files);
        setUploadedFiles(files);

        const userIds = taskResources?.map(
          (resource: any) => resource?.user_id
        );
        setSelectedEmployeeIds(userIds);
      }
    } catch (error) {
      console.error("Error fetching task data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, [id]);

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };
  const [enddateError, setEndDateError] = useState(false);

  const [dateError, setDateError] = useState(false);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([]);
  const [hasresource, setHasResource] = useState(false);

  const handleSelectedEmployeesChange = (action: EmployeeAction) => {
    // const projectId = formValues?.project_id;
    if (action.action === "delete") {
      const userId = action.user_id;
      const findUser = projectResources?.find(
        (option) => option?.user_id === userId
      );
      if (findUser && findUser?.action === "add") {
        const new_project = projectResources?.filter(
          (res) => res?.user_id !== findUser?.user_id
        );
        setProjectResources(new_project);
      } else {
        setProjectResources((prevResources) => {
          const existingIndex = prevResources.findIndex(
            (resource) => resource.user_id === action.user_id
          );

          if (existingIndex !== -1) {
            return prevResources.map((resource, index) =>
              index === existingIndex
                ? { ...resource, action: action.action }
                : resource
            );
          } else {
            return [...prevResources, action];
          }
        });
      }
      return;
    }
    setProjectResources((prevResources) => {
      const existingIndex = prevResources.findIndex(
        (resource) => resource.user_id === action.user_id
      );

      if (existingIndex !== -1) {
        return prevResources.map((resource, index) =>
          index === existingIndex
            ? { ...resource, action: action.action }
            : resource
        );
      } else {
        return [...prevResources, action];
      }
    });
  };

  const [disabelAll, setDisableAll] = useState(false);

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues?.task_name === "" || !formValues?.task_name)
      tempErrors.task_name = "Task Name is required";
    if (formValues?.status === "" || !formValues?.status)
      tempErrors.status = "Task Status is required";
    // if (formValues?.estimated_hours === "" || !formValues?.estimated_hours)
    //   tempErrors.estimated_hours = "Estimated Hours are required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  // useEffect(() => {
  //   fetchEditTask(id)
  //     .then((data) => {
  //       if (data.result) {
  //         const taskResources = data?.result?.TaskResources;

  //         const { task_document } = data?.result;

  //         const files = task_document?.map((name: string, index: number) => ({
  //           url: task_document[index],
  //         }));
  //         setTaskName(data?.result?.task_name ?? "");
  //         setFormValues({
  //           task_name: data?.result?.task_name,
  //           // estimated_hours: data?.result?.estimated_hours,
  //           estimated_hours:
  //               data?.result?.estimated_hours &&
  //               Number(data?.result?.estimated_hours) !== 0
  //                 ? Number(data?.result?.estimated_hours)
  //                 : null,
  //           actual_hours: data?.result?.actual_hours,
  //           status: data?.result?.status,
  //           project_id: data?.result?.project_id,
  //           module_name: data?.result?.Module?.module_name,
  //           milestone_id: data?.result?.ProjectMilestone?.id,
  //         });
  //         {
  //           data.result.start_date
  //             ? setStartData(dayjs(data.result.start_date))
  //             : setStartData(null);
  //         }
  //         {
  //           data.result.end_date
  //             ? setEndDate(dayjs(data.result.end_date))
  //             : setEndDate(null);
  //         }
  //         setEditorContent(data.result.description);
  //         setInitialFiles(files);
  //         setUploadedFiles(files);
  //         const userIds = taskResources.map(
  //           (resource: any) => resource.user_id
  //         );
  //         setSelectedEmployeeIds(userIds);
  //         // setProjectId(data.result.project_id);
  //       }
  //     })
  //     .catch((error) => console.error(error))
  //     .finally(() => setLoading(false));
  // }, []);
  const uploadFile = async () => {
    try {
      const response = await UploadTaskFileApi({
        docs: autoFiles?.map((file) => file?.url),
        projectId: param?.id ? parseInt(param.id) : null,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleUpdate = () => {
    if (autoFiles?.length > 0) {
      uploadFile();
    } else {
      !startDate && setDateError(true);
      if (!startDate) {
        setDateError(true);
        return;
      }
      if (endDate && endDate?.isBefore(startDate)) {
        setEndDateError(true);
        return;
      }
      const res = validate();
      if (!dateError && res && !enddateError) {
        submitChanges();
      } else {
        toast.error("Please fill all required details.");
      }
    }
  };

  const submitChanges = async () => {
    let newProjectResources: any = [];
    // if (projectResources[0] && projectResources?.length) {
    //   newProjectResources = [
    //     projectResources[0],
    //     projectResources[projectResources?.length - 1],
    //   ];
    // }
    if (projectResources?.length) {
      const updatedProjectResources = [...projectResources];
      const firstResource = projectResources[0];
      const lastResource = projectResources[projectResources?.length - 1];

      if (selectedEmployeeIds?.length > 0 && projectResources?.length > 1) {
        if (firstResource?.user_id === lastResource?.user_id) {
          // if first and last are same then send last action then only
          if (lastResource.action === "delete") {
            newProjectResources = [lastResource];
          }
        } else {
          // if not same then first sent with delete
          newProjectResources.push({ ...firstResource, action: "delete" });

          // if not same, then last is sent only for add
          if (lastResource.action === "add") {
            newProjectResources.push({ ...lastResource, action: "add" });
          }
        }
      } else if (
        selectedEmployeeIds?.length > 0 &&
        projectResources?.length > 0
      ) {
        // if initial employee exist then only send delete
        if (lastResource.action === "delete") {
          newProjectResources = [lastResource];
        }
      } else if (projectResources?.length > 0) {
        // if no employee exist then user is sent only with add
        if (lastResource.action === "add") {
          newProjectResources = [lastResource];
        }
      }
    }

    // projectResources?.map((resource) => {
    //   const duplicate
    // })

    try {
      const data = await updateTask(
        id,
        formValues?.task_name,
        String(formValues?.project_id),
        hasresource ? Number(formValues?.estimated_hours) : null,
        dayjs(startDate)?.format("YYYY-MM-DD"),
        dayjs(endDate)?.format("YYYY-MM-DD"),
        editorContent?.replace(/<[^>]+>/g, ""),
        formValues?.status,
        uploadedFiles?.map((files) => files?.url),
        projectResources,
        formValues?.module_name,
        formValues?.milestone_id
      );

      // if (path === "/view-project-details") {
      //   navigate(path, {
      //     state: { tab: "tasks", id: formValues?.project_id || projectId },
      //   });
      // } else {
      //   navigate(-1);
      // }
      navigate(-1);

      if (data && data.result) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          // if (fromAdd) {
          //   navigate(`/add-task/${projectID}`);
          // } else {
          //   navigate(`/edit-task-details/${formValues.project_id}`);
          // }
          // navigate(-1);
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Some error occured, please try again");
    }
    setLoading(false);
  };

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            // onClick={() =>
            //   fromAdd
            //     ? navigate(`/add-task/${projectID}`)
            //     : navigate(`/edit-task-details/${projectID}`)
            // }
            // onClick={() => navigate(-1)}
            onClick={() => {
              // if (path === "/view-project-details") {
              //   navigate(path, {
              //     state: {
              //       tab: "tasks",
              //       id: formValues?.project_id || projectId,
              //     },
              //   });
              // } else {
              //   navigate(-1);
              // }
              navigate(-1);
            }}
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            {`Edit Task Details (${taskName})`}
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            // onClick={() =>
            //   fromAdd
            //     ? navigate(`/add-task/${projectID}`)
            //     : navigate(`/edit-task-details/${projectID}`)
            // }
            // onClick={() =>
            //   navigate(path, {
            //     state: { tab: "tasks" },
            //   })
            // }

            onClick={() => {
              // if (path === "/view-project-details") {
              //   navigate(path, {
              //     state: {
              //       tab: "tasks",
              //       id: formValues?.project_id || projectId,
              //     },
              //   });
              // } else {
              //   navigate(-1);
              // }
              navigate(-1);
            }}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button
            variant={"gradient"}
            onClick={() => {
              handleUpdate();
            }}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-3 ">
              <TextField
                label="Milestone / Sprint"
                placeholder="Milestone / Sprint"
                select
                name="milestone_id"
                value={
                  formValues?.milestone_id
                    ? String(formValues?.milestone_id)
                    : ""
                }
                // onChange={handleFrormChange}
                onChange={handleFrormChange}
              >
                {milestoneOptions?.length > 0 ? (
                  milestoneOptions?.map((option) => (
                    <MenuItem key={option?.id} value={option?.id}>
                      {option?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </TextField>
              <TextField
                id="outlined-required"
                label="Module Name"
                disabled={disabelAll}
                placeholder="Enter Module Name"
                name="module_name"
                value={formValues?.module_name}
                onChange={handleFrormChange}
              />
              <TextField
                required
                id="outlined-required"
                label="Task Name"
                disabled={disabelAll}
                placeholder="Enter Task Name"
                name="task_name"
                value={formValues?.task_name}
                onChange={handleFrormChange}
                error={!!errors.task_name}
                helperText={errors.task_name}
              />
              {hasresource && (
                <TextField
                  id="outlined-required"
                  label="Estimate Hours"
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  disabled={disabelAll}
                  placeholder="Enter Estimated Hours"
                  name="estimated_hours"
                  value={formValues?.estimated_hours}
                  // onChange={handleFrormChange}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    if (value?.length <= 4) {
                      setFormValues({
                        ...formValues,
                        [name]: value,
                      });

                      if (value !== "") {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          [name]: "",
                        }));
                      }
                    }
                  }}
                  // error={!!errors.estimated_hours}
                  // helperText={errors.estimated_hours}
                />
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date *"
                  value={startDate}
                  disabled={disabelAll}
                  format="DD/MM/YYYY"
                  onChange={(newValue) => {
                    setStartData(newValue);
                    setDateError(false);
                    if (newValue?.isAfter(endDate)) {
                      setEndDate(null);
                    }
                  }}
                  minDate={dayjs(projectStartDate)}
                  maxDate={dayjs(projectEndDate)}
                  slotProps={{
                    textField: {
                      error: dateError,
                      helperText: dateError ? "Please select a start date" : "",
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date *"
                  format="DD/MM/YYYY"
                  value={endDate}
                  disabled={disabelAll}
                  minDate={startDate}
                  maxDate={dayjs(projectEndDate)}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                    setEndDateError(false);
                  }}
                  slotProps={{
                    textField: {
                      error: enddateError,
                      helperText: enddateError
                        ? "End Date is required."
                        : "",
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                select
                label="Status"
                placeholder="Select Status"
                name="status"
                disabled={disabelAll}
                value={formValues?.status}
                onChange={handleFrormChange}
                error={!!errors.status}
                helperText={errors.status}
              >
                {Status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="grid grid-cols-1">
              <TextFieldWithPopup
                initialSelectedEmployeeIds={selectedEmployeeIds}
                onSelectedEmployeesChange={handleSelectedEmployeesChange}
                setHasResource={setHasResource}
              />
            </div>

            <div className="mt-3">
              <MyStatefulEditor
                content={editorContent}
                onChange={setEditorContent}
              />
            </div>
            <div className="mt-3">
              <span className="font-[Poppins]">Attachments</span>
              <FileUpload
                existingFiles={initialFiles}
                onFilesChange={handleFilesChange}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditTask;
