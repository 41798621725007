import React, { useCallback, useEffect, useState } from "react";
import rightArrow from "../../assets/chevron-right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import TextField from "@mui/material/TextField";
import { DesignationForm } from "../../utils/helperData";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  fetchEditDesignation,
  updateDesignation,
} from "../common/services/DesignationManagement";
import { useSelector } from "react-redux";
import { Button } from "../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const validationData: any = ["designation"];

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  width: "325px",
};

export const EditDesignation = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [designation, setDesignation] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = React.useState<{ [key: string]: any }>({});
  // const [updateData, setUpdateData] = useState<any>(null);
  // console.log(designation, "designation-->>>");

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customerId = 0;
  let access_token = "";
  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customerId = loginData.customer_id;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/designation-management");
  };
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;

  const fetchDesginationList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchEditDesignation(id);
      if (response.status_code === 200) {
        setDesignation(response?.result?.name);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDesginationList();
  }, [fetchEditDesignation]);

  function handleValidation() {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["designation"] = !designation;
    //updated["resources"] = !resourceList.length;

    setIsValid(updated);

    if (!designation) {
      return;
    }
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    updated[`${item}`] = false;

    setIsValid(updated);
  }

  const submitChanges = async () => {
    setLoading(true);
    try {
      const data = await updateDesignation(id, designation, "active");

      if (data && data.result) {
        // console.log("final data:", data);
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/designation-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  const handleClick = async () => {
    handleValidation();
    if (designation?.trim() !== "") {
      await submitChanges();
    } else {
      toast.error("Please add a designation name.");
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  return (
    <div className="px-4 py-6 bg-[#F9F9F9] h-[90vh]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Designation Updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/designation-management")}>
            <img
              src={rightArrow}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <div className="flex flex-col">
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Designation Details
            </span>
          </div>
        </div>

        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate(`/designation-management`)}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button onClick={handleClick} variant={"gradient"}>
            Update
          </Button>
        </div>
      </div>

      {/* <div className="w-[100%] mt-6 p-4 rounded overflow-hidden shadow-lg bg-white m-4">
        <div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="p-4">
              <label className="text-[#262626] font-[500] text-[14px]">
                Designation Details
              </label>
            </div>
            <TextField
             onChange={(e) => {
              setDesignation(e.target.value);
            }}
              required
              id="outlined-required"
              label="Designation"
              defaultValue="Enter Designation"
            />
          </Box>
        </div>
      </div> */}

      <div className="w-full mt-5">
        <div className=" py-[25px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] shadow-custom bg-white">
          <div className="text-[#262626] font-[500] text-[14px] ml-6 font-['Poppins']">
            Designation details
          </div>
          <Container component="main">
            <Box sx={{ paddingBottom: "32px" }}>
              <Box
                component="form"
                className="flex flex-col gap-8"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: "0px" }}
              >
                <Grid
                  container
                  style={{
                    gap: "20px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "self-start",
                    alignSelf: "stretch",
                  }}
                >
                  {DesignationForm.map((item: any) => (
                    <Grid item>
                      <TextField
                        autoComplete={item.autoComplete}
                        name={item.name}
                        required
                        id={item.id}
                        label={item.label}
                        placeholder={item.placeholder}
                        value={designation}
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        error={isValid[`${item.name}`]}
                        helperText={
                          isValid[`${item.name}`]
                            ? "This field is required!"
                            : ""
                        }
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                          handleIsValidItem(item.name);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>

                {/*                        
                        <RenderResources
                          renderData={customerResourceList}
                          isChecked={false}
                          cssClass={cssClass}
                          resourceList={resourceList}
                          handleResourceListChildren={
                            handleResourceListChildrenChange
                          }
                          handleResourceListParent={
                            handleResourceListParentChange
                          }
                        /> */}
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};
