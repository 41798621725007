import Footer from "../Layout/footer";
import mission from "../../assets/mission.svg";
import employee from "../../assets/employee.png";
import attendance from "../../assets/attendance.png"
import project from "../../assets/project.png"
import appraisal from "../../assets/appraisal.png"
import timesheet from "../../assets/timesheet.png"
import comp from "../../assets/comprehensive.svg";
import user from "../../assets/userFreindly.svg"
import security from "../../assets/security.svg"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AboutUs = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }
  return (
    <div className="w-full p-4">
      <div className="flex flex-col gap-[5px]">
        <div className="flex flex-col gap-1">
          <div
            className="p-11 rounded-xl bg-gradient-to-r"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.2) 0%, rgba(142, 31, 210, 0.2) 100%)",
            }}
          >
            <div className="flex flex-col gap-3">
              <div className="h-[50px] w-full flex items-center justify-center">
                <span className="font-[Poppins] font-semibold text-[42px] leading-[50.4px] text-[#0F172A] text-center">
                  About Us
                </span>
              </div>
              <div className="w-full flex justify-center items-center">
                <span className="font-[Poppins] font-normal text-[18px] leading-[28.8px] tracking-[0.02em] text-[#0F172A] text-center">
                  Go-Employee is a B2B application developed by AppVin
                  Technologies where businesses offer employee management system
                  to their employees. Companies register themselves on the
                  Go-Employee company portal and allow the Go-Employee product
                  to fetch their employees' company related data details from
                  their Company database system. Using the Go-Employee mobile
                  application, their employees can apply for leave, mark attendance,
                  log working hours in a task of a project assigned to them and
                  add expenses(declaration) for a period. The Employees can call
                  their Reporting manager for support from the Go-Employee
                  application and send the feedback to the Go-Employee support
                  team. The employees can also track their approved, rejected
                  and process leaves and declarations in the Go-Employee app. An
                  employee can withdraw any leave or declarations made in the
                  Go-Employee app.
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-start items-center gap-10 h-[432px] pr-[6%]">
            <div className="flex flex-col gap-[19px]">
              <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text-[#000000]">
                Our Mission
              </span>
              <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                HR and Payroll teams often experience significant overload
                towards the end of the month. Imagine saving 4 working days each
                month by automating mundane and repetitive tasks. Kredily offers
                free Payroll and HR software for organizations in India,
                simplifying the lives of HR professionals, finance managers, and
                business founders.
              </span>
            </div>
            <img src={mission} alt="mission" className="w-[39%]" />
          </div>
          <div className="flex flex-col gap-[19px] pr-[20%]">
            <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text-[#000000]">
              Features
            </span>
            <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
              Our comprehensive HRMS portal offers a wide range of features to
              streamline your HR processes and empower your employees. Here are
              some of the key capabilities:
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="py-9">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2.5 h-full w-1/2">
                <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text">
                  EMPLOYEE MANAGEMENT
                </span>
                <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                  Manage employee information in a centralized database and
                  track history, performance, and development for informed
                  decision-making
                </span>
              </div>
              <img
                src={employee}
                alt="employee management"
                className="w-[40%]"
              />
            </div>
          </div>
          <div className="py-9">
            <div className="flex justify-between items-center">
              <img
                src={attendance}
                alt="employee management"
                className="w-[40%]"
              />
              <div className="flex flex-col gap-2.5 h-full w-1/2">
                <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text">
                  ATTENDANCE MANAGEMENT
                </span>
                <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                  Automate attendance tracking, manage leave requests, and
                  generate reports for accurate payroll and compliance.
                </span>
              </div>
            </div>
          </div>
          <div className="py-9">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2.5 h-full w-1/2">
                <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text">
                  PROJECT MANAGEMENT
                </span>
                <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                  Plan, execute, and monitor projects with task assignment,
                  progress tracking, and reporting for performance measurement.
                </span>
              </div>
              <img
                src={project}
                alt="employee management"
                className="w-[40%]"
              />
            </div>
          </div>
          <div className="py-9">
            <div className="flex justify-between items-center">
              <img
                src={appraisal}
                alt="employee management"
                className="w-[40%]"
              />
              <div className="flex flex-col gap-2.5 h-full w-1/2">
                <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text">
                  APPRAISAL MANAGEMENT
                </span>
                <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                  Conduct performance reviews, set goals, provide feedback, and
                  manage compensation based on achievements.
                </span>
              </div>
            </div>
          </div>
          <div className="py-9">
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2.5 h-full w-1/2">
                <span className="font-[Poppins] font-semibold text-[30px] leading-[45px] text">
                  TIMESHEET
                </span>
                <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                  Record and approve timesheets, track productivity, and ensure
                  compliance with labor laws and regulations.
                </span>
              </div>
              <img
                src={timesheet}
                alt="employee management"
                className="w-[40%]"
              />
            </div>
          </div>
        </div>
        <div className="bg-[#FAFBFF] pr-[9%] flex justify-start items-center py-16">
          <div className="flex flex-col gap-[50px]">
            <span className="font-[Poppins] font-bold text-[42px] leading-[57px] tracking-[0.1px] text-[#0F172A]">
              WHY CHOOSE US?
            </span>
            <div className="flex flex-col gap-[27px]">
              <div className="flex justify-start gap-4 items-start">
                <img src={comp} alt="" className="h-12 w-12" />
                <div className="flex flex-col gap-2">
                  <span className="font-[Poppins] font-semibold text-[22px] leading-[30px] tracking-[-1%] text-[#272D37]">
                    Comprehensive Employee Management:
                  </span>
                  <span>
                    <div className="p-0 m-0">
                      <span className="p-0 m-0">
                        <span className="font-[Poppins] font-meduim text-[18px] leading-[27px] text-[#0F172A]">
                          All-in-One Solution:
                        </span>{" "}
                        <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                          Manage employee information, attendance, payroll,
                          performance appraisals, and more from a single
                          platform.
                        </span>
                      </span>
                    </div>
                    <div className="p-0 m-0 pt-1">
                      <span className="p-0 m-0">
                        <span className="font-[Poppins] font-meduim text-[18px] leading-[27px] text-[#0F172A]">
                          Customisation:
                        </span>{" "}
                        <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                          Tailor the system to fit the unique needs of your
                          business, ensuring seamless integration with existing
                          workflows and systems.
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="flex justify-start gap-4 items-start">
                <img src={user} alt="" className="h-12 w-12" />
                <div className="flex flex-col gap-2">
                  <span className="font-[Poppins] font-semibold text-[22px] leading-[30px] tracking-[-1%] text-[#272D37]">
                    User-Friendly Interface:
                  </span>
                  <span>
                    <div className="p-0 m-0">
                      <span className="p-0 m-0">
                        <span className="font-[Poppins] font-meduim text-[18px] leading-[27px] text-[#0F172A]">
                          Intuitive Design:
                        </span>{" "}
                        <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                          Easy-to-navigate interface that requires minimal
                          training, ensuring quick adoption by all users.
                        </span>
                      </span>
                    </div>
                    <div className="p-0 m-0 pt-1">
                      <span className="p-0 m-0">
                        <span className="font-[Poppins] font-meduim text-[18px] leading-[27px] text-[#0F172A]">
                          Mobile Accessibility:
                        </span>{" "}
                        <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                          Access essential HR functions on the go with our fully
                          responsive mobile application, providing flexibility
                          and convenience for employees and managers.
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="flex justify-start gap-4 items-start">
                <img src={security} alt="" className="h-12 w-12" />
                <div className="flex flex-col gap-2">
                  <span className="font-[Poppins] font-semibold text-[22px] leading-[30px] tracking-[-1%] text-[#272D37]">
                    Enhanced Security and Compliance:
                  </span>
                  <span>
                    <div className="p-0 m-0">
                      <span className="p-0 m-0">
                        <span className="font-[Poppins] font-meduim text-[18px] leading-[27px] text-[#0F172A]">
                          Data Security:
                        </span>{" "}
                        <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                          State-of-the-art security measures to protect
                          sensitive employee information, including encryption
                          and regular security audits.
                        </span>
                      </span>
                    </div>
                    <div className="p-0 m-0 pt-1">
                      <span className="p-0 m-0">
                        <span className="font-[Poppins] font-meduim text-[18px] leading-[27px] text-[#0F172A]">
                          Compliance:
                        </span>{" "}
                        <span className="font-[Poppins] font-normal text-[18px] leading-[27px] text-[#0F172A]">
                          Stay compliant with local and international labor laws
                          and regulations, with regular updates to ensure your
                          HR practices meet legal standards.
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AboutUs;
