// import React from 'react'

// const AddLeaveBalance = () => {
//   return (
//     <div>AddLeaveBalance</div>
//   )
// }

// export default AddLeaveBalance

import React from "react";
import right from "../../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import check from "../../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const AddLeaveBalance = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <div className="p-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Leave balance added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center px-4 h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate(-1)}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Employee Leave Balance Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex  rounded-lg  justify-center items-center  borderButton "
          >
            <div className="text text-center  font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button variant={"gradient"} form="form" onClick={handleOpen}>
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </Button>
        </div>
      </div>

      <div className="rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">
            Sick Leave Details
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 mt-4 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Leave Type"
                select
                defaultValue=" "
              />
              <TextField
                required
                id="outlined-required"
                label="Total Leave"
                select
                defaultValue=" "
              />
              <TextField
                required
                id="outlined-required"
                label="Used Leave"
                select
                defaultValue=" "
              />
              <TextField
                required
                id="outlined-required"
                label="Remaining Leave"
                select
                defaultValue=" "
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddLeaveBalance;
