import React, { useCallback, useEffect, useState, useRef } from "react";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate, useParams } from "react-router-dom";
import download from "../../image/Vector (1).png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import clock from "../../assets/clock.svg";
import location from "../../assets/location.svg";
import Filter from "../Filter/Filter";
import right from "../../image/chevron-right.png";
import * as XLSX from "xlsx";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, User } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import { createGlobalStyle } from "styled-components";
import TableSkeleton from "../common/TableSkeleton";
import toast from "react-hot-toast";
import { Fetch_User_Attendance_Multi_List } from "../common/services/Employee";
import { LocationAndAddress } from "../AttendenceDetails/MultiCheckInDetails";

dayjs.extend(duration);

interface IAttendenceList {
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  date: Date;
  start_time: string;
  check_in_address: string;
  end_time: string;
  check_out_address: string;
  id: number;
  mode_of_work: string;
  check_in_status: string | null;
  duration: string;
  selected_address: string;
  selected_checkout_address: string;
}

interface AttendanceStats {
  WFH: number;
  WFO: number;
  DELAYED: number;
  NOT_CHECKED_IN: number;
}

interface Items {
  start_time: string;
  end_time: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any;
}

// export const LocationAndAddress: React.FC<{
//   dateTime: string;
//   address: string;
// }> = ({ dateTime, address }) => {
//   const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

//   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handlePopoverClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);

//   return (
//     <div>
//       <Typography
//         aria-owns={open ? "mouse-over-popover" : undefined}
//         aria-haspopup="true"
//         onMouseEnter={handlePopoverOpen}
//         onMouseLeave={handlePopoverClose}
//       >
//         <div className="flex flex-col justify-center gap-1">
//           <div className="flex gap-1 justify-start items-center">
//             <img
//               src={clock}
//               alt="time"
//               className="h-[12px] w-[12px] text-[#49454F]"
//             />
//             <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
//               {dateTime}
//             </span>
//           </div>
//           {address ? (
//             <div className="flex gap-1 items-center">
//               <img
//                 src={location}
//                 alt=""
//                 className="h-[12px] w-[12px] text-[#938F99]"
//               />
//               <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500] overflow-hidden whitespace-nowrap text-ellipsis w-40">
//                 {address}
//               </span>
//             </div>
//           ) : (
//             <></>
//           )}
//         </div>
//       </Typography>
//       <Popover
//         id="mouse-over-popover"
//         sx={{ pointerEvents: "none" }}
//         open={open}
//         anchorEl={anchorEl}
//         anchorOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//         onClose={handlePopoverClose}
//         disableRestoreFocus
//       >
//         <Typography sx={{ p: 1 }}>
//           <div className="flex flex-col justify-center gap-1">
//             <div className="flex gap-1 justify-start items-center">
//               <img
//                 src={clock}
//                 alt="time"
//                 className="h-[12px] w-[12px] text-[#49454F]"
//               />
//               <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
//                 {dateTime}
//               </span>
//             </div>
//             <div className="flex gap-1 items-center">
//               <img
//                 src={location}
//                 alt=""
//                 className="h-[12px] w-[12px] text-[#938F99]"
//               />
//               <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500]">
//                 {address}
//               </span>
//             </div>
//           </div>
//         </Typography>
//       </Popover>
//     </div>
//   );
// };

const UserMultiCheckInDetails = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [tempSelectedDates, setTempSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const [tableData, setTableData] = useState<IAttendenceList[]>([]);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [stats, setStats] = useState<AttendanceStats>();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const selectedCalendarRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const UserId = param.id;
  const date = param.date;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_User_Attendance_Multi_List({
        customer_id: customer_id,
        id: UserId,
        date: date,
        numOfItems: numOfItems,
        page: page,
      });
      if (response.status === 200) {
        // const dataWithDurations: IAttendenceList[] = response.data.result?.rows?.map(
        //   (item: Partial<IAttendenceList>) => {
        //     const startDateTime = item.start_time ? dayjs(item.start_time) : null;
        //     const endDateTime = item.end_time ? dayjs(item.end_time) : null;

        //     let duration = "00:00";
        //     if (startDateTime && endDateTime) {
        //       const diff = dayjs.duration(endDateTime.diff(startDateTime));
        //       const totalMinutes = Math.floor(diff.asMinutes());
        //       const hours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
        //       const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        //       duration = `${hours}:${minutes}`;
        //     }

        //     return {
        //       User: {
        //         firstName: item.User?.firstName || "",
        //         lastName: item.User?.lastName || "",
        //         employee_id: item.User?.employee_id || "",
        //         profile_image: item.User?.profile_image || "",
        //       },
        //       date: item.date || new Date(),
        //       start_time: item.start_time || "",
        //       check_in_address: item.check_in_address || "",
        //       end_time: item.end_time || "",
        //       check_out_address: item.check_out_address || "",
        //       id: item.id || 0,
        //       mode_of_work: item.mode_of_work || "",
        //       check_in_status: item.check_in_status || null,
        //       duration,
        //     };
        //   }
        // );

        // setTableData(dataWithDurations);

        const dataWithDurations = response.data.result?.rows?.map(
          (item: Items) => {
            const startDateTime = dayjs(item.start_time);
            const endDateTime = dayjs(item.end_time);
            const duration = dayjs.duration(endDateTime.diff(startDateTime));

            // Calculate the total duration in hours and minutes
            const totalMinutes = Math.floor(duration.asMinutes());
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            // Format hours and minutes to ensure two digits
            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");

            const durationString = `${formattedHours}:${formattedMinutes}`;

            return {
              ...item,
              duration: durationString,
            };
          }
        );

        setTableData(dataWithDurations?.length ? dataWithDurations : []);

        setCount(response.data.result.count);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems, searchTerm, date]);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "attendence.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
    }
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | DepartmentUnit | undefined
  ) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | DepartmentUnit | undefined
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  // const handleStorageChange = (event: StorageEvent) => {
  //   if (event.key === "start_session" || event.key === "end_session") {
  //     const start = localStorage.getItem("start_session") || "";
  //     const end = localStorage.getItem("end_session") || "";

  //     setStartSession(start);
  //     setEndSession(end);
  //   }
  // };

  // useEffect(() => {
  //   // Initialize state with values from localStorage
  //   const start = localStorage.getItem("start_session") || "";
  //   const end = localStorage.getItem("end_session") || "";
  //   setStartSession(start);
  //   setEndSession(end);

  //   // Add event listener to handle storage changes
  //   window.addEventListener("storage", handleStorageChange);

  //   // Cleanup listener on component unmount
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startingDate, endingDate] = dates;
    if (startingDate && endingDate) setIsCalendarOpen(!isCalendarOpen);
    setSelectedDates(dates);
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, [customer_id, dispatch, navigate]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, [customer_id, dispatch, navigate]);

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleSelectedCalanderClick = () => {
    setIsSelectedOpen(!isSelectedOpen);
  };

  const handleFilterApply = () => {
    // const [startDate, endDate] = selectedDates;
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    // setTempSelectedDates(selectedDates);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    // dispatch(AttendanceActionData.setStartDate(startDate));
    // dispatch(AttendanceActionData.setEndDate(endDate));
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
    // setSelectedDates([null, null]);
  };

  const handleSelectedDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
  };

  const handleDelete = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    setSelectedBusinessUnit(undefined);
    // fetchData();
  };

  const columns2: ColumnDef<IAttendenceList>[] = [
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date
          ? new Date(row.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date
          ? new Date(row.original.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px]">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "start_time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Check In Time & Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.start_time ? dayjs(row.start_time).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div className="">
            <LocationAndAddress
              dateTime={dayjs(row?.original?.start_time).format("h:mm A")}
              address={row?.original?.check_in_address}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "end_time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Check Out Time & Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_time ? dayjs(row.end_time).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div className="">
            <div className="">
              {row.original.end_time ? (
                <>
                  <LocationAndAddress
                    dateTime={dayjs(row?.original?.end_time).format("h:mm A")}
                    address={row?.original?.check_out_address}
                  />
                </>
              ) : (
                <span>--</span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Duration
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.duration ?? "-",
      cell: ({ row }) => {
        if (row?.original?.end_time) {
          return <div>{row?.original?.duration} Hrs.</div>;
        } else {
          return <div>--</div>;
        }
      },
    },
    {
      accessorKey: "check_in_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.check_in_status ?? "-",
      cell: ({ row }) => {
        return row?.original?.check_in_status ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full border ${
              row.original.check_in_status === "OnTime"
                ? "border-[#0F9446] bg-[rgba(15,148,70,0.10)]"
                : "border-[#EB3F3F] bg-[#EDCFCF]"
            }`}
          >
            {row.original.check_in_status}
          </div>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      accessorKey: "mode_of_work",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Workspace
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.mode_of_work ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.mode_of_work ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "selected_address",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Selected Check in Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.selected_address ? row?.selected_address : "-",
      cell: ({ row }) => {
        return row?.original?.selected_address ? (
          <div>
            <LocationAndAddress address={row.original.selected_address} />
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      accessorKey: "selected_checkout_address",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Selected Check Out Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.selected_checkout_address ? row?.selected_checkout_address : "-",
      cell: ({ row }) => {
        return row?.original?.selected_checkout_address ? (
          <div>
            <LocationAndAddress address={row.original.selected_checkout_address} />
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button type="button" className="p-0 mr-4">
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
                onClick={() =>
                  navigate(
                    `/attendance-management/edit-attendence/${row?.original?.id}`,
                    {
                      state: {
                        id: row?.original?.id,
                        userId: UserId,
                        date: date,
                      },
                    }
                  )
                }
              />
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectedCalendarRef.current &&
        !selectedCalendarRef.current.contains(event.target as Node)
      ) {
        setIsSelectedOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <div className="flex flex-row items-center h-12 ">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate(-1)}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Employee Attendance
          </span>
        </div>
      </div>

      <div className="overflow-x-auto mt-5">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-19rem)] relative overflow-auto bg-white">
            <DynamicTable<IAttendenceList>
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
      <Filter
        isDate
        isOther
        heading="Attendance Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};

export default UserMultiCheckInDetails;
