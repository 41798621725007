import {
  api_key_work as api_key,
  base_api,
  // session_query,
} from "../../../utils/helperData";
import axios from "axios";
import {
  FETCH_ATTENDENCE_LIST,
  FETCH_ATTENDENCE_OVERVIEW_STATS,
  FETCH_ATTENDENCE_STATS,
  FETCH_ATTENDENCE_USER,
  FETCH_DASHBOARD_WFH_LIST,
  FETCH_DEDUCTION_LIST,
  FETCH_DEPARTMENT_WISE_ATTENDENCE_STATS,
  FETCH_ELSEWHERE_LIST,
  FETCH_LEAVE_DASHBORAD_LIST,
  FETCH_LEAVE_LIST,
  FETCH_ON_SITE_LIST,
  FETCH_TOTAL_EMP_LIST,
  FETCH_USER_LOCATION,
  FETCH_WFH_LIST,
  FETCH_WFO_LIST,
  FETCH_WORK_FROM_OFFICE_LIST,
} from "../api";
import { Dayjs } from "dayjs";
import moment from "moment";
import axiosInstance from "../../../app/api/axiosInstance";
import { queryAllByAltText } from "@testing-library/react";

// const session_query = `session_start=${localStorage.getItem(
//   "start_session"
// )}&session_end=${localStorage.getItem("end_session")}`;

export const Fetch_Attendence_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  //sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null,
  filterStatus?: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}${session_query}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (filterStatus && filterStatus !== "") {
    query += `&filterStatus=${filterStatus}`;
  }

  return axiosInstance.get(
    //`${FETCH_ATTENDENCE_LIST}${query}&session_start=2024-04-01&session_end=2025-03-31`,
    `${FETCH_ATTENDENCE_USER}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_MultiAttendence_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  //sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null,
  date: string,
  userId?: string
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}&date=${date}${session_query}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axiosInstance.get(
    `${base_api}/api/v1/attendance/user/${userId}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Attendence_Stats = (
  customer_id: number,
  selectedDate: Dayjs | null,
  groupIds?: (number | null)[] | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }
  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_ATTENDENCE_STATS}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Attendence_Overview_Stats = (
  customer_id: number,
  selectedDate: Dayjs | null,
  groupIds?: (number | null)[] | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }
  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_ATTENDENCE_OVERVIEW_STATS}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_User_Location = ({
  date,
  groupIds,
  department,
  numOfItems,
  page,
  searchTerm,
  customer_id,
}: {
  date: Date | null;
  groupIds: string[];
  department: string[];
  numOfItems: number;
  page: number;
  searchTerm: string;
  customer_id: number;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}${session_query}`;

  if (date) {
    const newDate = moment(date)?.format("YYYY-MM-DD");
    query += `&date=${newDate}`;
  }
  if (groupIds?.length > 0) {
    query += `&group_id=[${groupIds}]`;
  }
  if (department?.length > 0) {
    query += `&department_id=[${department}]`;
  }
  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }
  return axiosInstance.get(`${FETCH_USER_LOCATION}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: `Bearer ${access_token}`,
    },
  });
};
export const Fetch_User_Specific_Location = ({
  userId,
  date,
}: {
  userId: number | null;
  date: Date | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `${session_query}`;
  if (date) {
    const newDate = moment(date)?.format("YYYY-MM-DD");
    query += `&date=${newDate}`;
  }
  return axiosInstance.get(`${FETCH_USER_LOCATION}/${userId}?${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const Fetch_Department_Wise_Attendance = (
  customer_id: number,
  selectedDate: Dayjs | null,
  groupIds?: (number | null)[] | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }
  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(
    `${FETCH_DEPARTMENT_WISE_ATTENDENCE_STATS}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Attendence_List1 = (customer_id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  return axiosInstance.get(
    //`${FETCH_ATTENDENCE_LIST}${query}&session_start=2024-04-01&session_end=2025-03-31`,
    `${FETCH_ATTENDENCE_LIST}${query}&session_start=${localStorage.getItem(
      "start_session"
    )}&session_end=${localStorage.getItem("end_session")}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Edit_Attendence_List = (customer_id: number, id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  return axiosInstance.get(
    `${FETCH_ATTENDENCE_LIST}${query}&session_start=2024-04-01&session_end=2025-03-31&` +
      id,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Edit_Attendence_List_List = (id: number) => {
  const access_token = localStorage.getItem("access_token");

  return fetch(`${FETCH_ATTENDENCE_LIST}/${id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createAttendence = async (
  user_id: string | null,
  status: string,
  end_time: Dayjs | null,
  start_time: Dayjs | null,
  date: string | undefined,
  modeOfEmp: string
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    user_id: user_id,
    status: status,
    start_time: start_time,
    date: date,
    mode_of_work: modeOfEmp,
  };

  if (status !== "CHECKIN") {
    data.end_time = end_time;
  }

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/checkin-checkout`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateAttendence = async (
  id: number,
  start_time: string | null | Dayjs,
  end_time: string | null | Dayjs,
  modeOfEmp: string,
  checkoutChange?: boolean
) => {
  const access_token = localStorage.getItem("access_token");

  const body: any = {
    start_time: start_time,
    mode_of_work: modeOfEmp,
  };

  if (end_time) {
    body.end_time = end_time;
  }
  if (checkoutChange) {
    body.checkout_by = "MANUAL";
  }

  try {
    const response = await axiosInstance.patch(
      `${base_api}/api/v1/attendance/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Deductions_List = ({
  customer_id,
  numOfItems,
  page,
  searchTerm,
  group_id,
  department_id,
  startDate,
  endDate,
}: {
  customer_id: number;
  numOfItems: number;
  page: number;
  searchTerm?: string;
  group_id?: number;
  department_id?: number;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}${session_query}`;

  if (searchTerm) {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axiosInstance.get(`${FETCH_DEDUCTION_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const Fetch_Total_Employee_List = (
  customer_id: number,
  page: number,
  size: number,
  searchTerm: string,
  selectedDate: Dayjs,
  groupIds: (number | null)[],
  active?: boolean
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }
  if (active) {
    query += `&active=${true}`;
  }

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds?.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_TOTAL_EMP_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_In_Office_List = (
  page: number,
  size: number,
  searchTerm: string,
  selectedDate: Dayjs,
  groupIds: (number | null)[]
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `?date=${date}`;
  }

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds?.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_WFO_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_On_Site_List = (
  page: number,
  size: number,
  searchTerm: string,
  selectedDate: Dayjs,
  groupIds: (number | null)[]
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `?date=${date}`;
  }

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds?.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_ON_SITE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Work_Elsewhere_List = (
  page: number,
  size: number,
  searchTerm: string,
  selectedDate: Dayjs,
  groupIds: (number | null)[]
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `?date=${date}`;
  }

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds?.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_ELSEWHERE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_WFH_List = (
  page: number,
  size: number,
  searchTerm: string,
  selectedDate: Dayjs,
  groupIds: (number | null)[]
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `?date=${date}`;
  }

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds?.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_WFH_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_On_Leave_List = (
  page: number,
  size: number,
  searchTerm: string,
  selectedDate: Dayjs,
  groupIds: (number | null)[]
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `?date=${date}`;
  }

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds?.join(",")}`;
  }

  return axiosInstance.get(`${FETCH_LEAVE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
