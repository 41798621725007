import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import check from "../../../image/checkmark-circle-02.svg";
import { MenuItem } from "@mui/material";
import EmployeeSearch from "../../EmployeeManagement/EmployeeSearch";
import {
  Fetch_Edit_Leave_List,
  Fetch_Leave_Type_List,
  updateLeave,
} from "../../common/services/Leave";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Leave_Unit } from "../../../redux/actions/Leave/Leave";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import TimeRangePicker from "./TimeRangePicer";
import { Button } from "../../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface Leave_Type {
  id: string;
  leave_type_name: string;
}

interface FormValues {
  // from_time: string;
  // to_time: string;
  leave_period_type: string;
  leave_type: string;
  reason: string;
  employee_id: string;
  leave_session: string;
  no_of_days: number;
  timeRange?: { start: Dayjs | null; end: Dayjs | null };
  user_id: number;
}

const initialFormValues: FormValues = {
  // from_time: "",
  // to_time: "",
  leave_period_type: "",
  leave_type: "",
  reason: "",
  employee_id: "",
  leave_session: "",
  no_of_days: 0,
  timeRange: { start: null, end: null },
  user_id: 0,
};

export const EditLeave = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const leave = useSelector((state: any) => state.leave.LeaveData);
  const currentPage = useSelector((state: any) => state.leave.currentPage);
  // console.log(leave, "leaveleaveleave------->>>>>>>>>>");

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  // const [dateAll, setDateAll] = useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [selectLeaveType, setSelectLeaveType] = useState<Leave_Type[]>();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [timeRangeError, setTimeRangeError] = useState<string | null>(null);

  const [endDateError, setEndDateError] = useState("");

  const [isEndDisplay, setIsEndDisplay] = useState<boolean | null>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const handleOpen = () => {
    if (formValues?.no_of_days && formValues.no_of_days <= 0) {
      setIsEndDisplay(true);
      setEndDateError("Invalid End Date");
      return;
    }
    const res = validate();
    if (res) submitChanges();
    else toast.error("Please fill all the required fields.");
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/leave-holiday-management");
  };

  const dispatch = useDispatch();

  const fetchLeaveType = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Leave_Type_List(
        searchTerm,
        numOfItems,
        page
      );
      // customer_id,
      // numOfItems,
      // page,
      //sortType,
      // searchTerm
      // startDate,
      // endDate
      if (response.status === 200) {
        setSelectLeaveType(response?.data?.result?.rows);
      } else {
      }
    } catch (error) {}
    //setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    // console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const { api } = useParams();
  const location = useLocation();
  const id = location.state?.id;

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    // if (formValues.reason === "") tempErrors.reason = "Reason is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value == "Full Day")
      setFormValues({
        ...formValues,
        [name]: value,
      });
    else if (value == "Short Day")
      setFormValues({
        ...formValues,
        [name]: value,
        no_of_days: 0.2,
      });
    else if (value == "First Half" || value === "Second Half") {
      setFormValues({
        ...formValues,
        [name]: value,
        no_of_days: 0.5,
      });
    }

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const LeavePeriodType = [
    { value: "Full Day", label: "Full Day" },
    { value: "First Half", label: "Half Day (First Half)" },
    { value: "Second Half", label: "Half Day (Second Day)" },
    { value: "Short Day", label: "Short Leave" },
  ];

  const LeaveSessionOptions = [
    { value: "First Half", label: "First Half" },
    { value: "Second Half", label: "Second Half" },
  ];

  const fetchData = async () => {
    try {
      const response = await Fetch_Edit_Leave_List(id);
      if (response.status === 200) {
        const leave = response?.data?.result;
        const fromDateTime = leave?.from_date
          ? moment(leave.from_date).format("DD/MM/YYYY")
          : "";
        const toDateTime = `${leave?.to_date}`;

        setFormValues({
          leave_period_type: leave?.no_of_days || "",
          reason: leave.reason,
          employee_id: leave?.User?.employee_id,
          leave_type: leave?.leave_type_id || "",
          leave_session: leave?.leave_session || "",
          no_of_days: leave?.no_of_days,
          timeRange: {
            start: dayjs(fromDateTime),
            end: dayjs(toDateTime),
          },
          user_id: leave?.User?.id,
        });
        setStartDate(dayjs(leave?.from_date));
        setEndDate(dayjs(leave?.to_date));
      } else {
        // console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitChanges = async () => {
    setLoading(true);
    try {
      const formatStart = (date: Dayjs | null) =>
        date?.format("YYYY-MM-DD") || "";
      const formatEnd = (date: Dayjs | null) =>
        date?.format("YYYY-MM-DD") || "";

      const data = await updateLeave(
        id,
        formValues?.user_id,
        formValues?.leave_type,
        formValues?.no_of_days,
        formatStart(startDate),
        formatEnd(endDate),
        formValues?.reason,
        formValues?.leave_session,
        formValues?.timeRange?.start || "",
        formValues?.timeRange?.end || ""
      );

      if (data && data?.result) {
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          // navigate("/leave-holiday-management");
          navigate(-1);
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee Leave details updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#F9F9F9] px-4 py-6 min-h-[90vh]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() =>
                // navigate("/leave-holiday-management", {
                //   state: { page: currentPage },
                // })
                navigate(-1)
              }
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Employee Leave Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate(-1)}
              className="flex w-[148px] borderButton  p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <Button form="form" variant={"gradient"} onClick={handleOpen}>
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </Button>
          </div>
        </div>

        <div className="rounded-md overflow-hidden shadow-custom bg-white mt-10">
          <div className="container mt-2 mx-auto p-4">
            <div className="text-[#262626] mb-4 font-[500] text-[14px] ml-4 font-['Poppins']">
              Edit Leave details
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-3 gap-4">
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="Leave Period Type"
                  name="leave_session"
                  // value={formValues.leave_session}
                  value={formValues?.leave_session}
                  onChange={handleTypeChange}
                >
                  {LeavePeriodType?.map((option) => (
                    <MenuItem key={option?.value} value={option?.value}>
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>

                {formValues.leave_session == "Short Day" && (
                  <div className="p-0 m-0 col-span-2">
                    {/* <TimeRangePicker
                      label="Time Range"
                      value={start: null, null}
                      onChange={(newValue) =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          timeRange: newValue,
                        }))
                      }
                      onError={setTimeRangeError}
                    /> */}
                  </div>
                )}
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="Leave Type"
                  name="leave_type"
                  value={formValues?.leave_type}
                  onChange={handleFrormChange}
                >
                  {selectLeaveType?.map((option) => (
                    <MenuItem key={Number(option.id)} value={Number(option.id)}>
                      {option.leave_type_name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  id="outlined-required"
                  label="Employee Id"
                  disabled
                  value={formValues?.employee_id}
                />
                {formValues?.leave_session === "First Half" ||
                formValues?.leave_session === "Second Half" ||
                formValues?.leave_session == "Short Day" ? (
                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        format="DD/MM/YYYY"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          setEndDate(newValue);
                          // setIsStart(true);
                          // setIsStartDisplay(false);
                        }}
                        className="w-[95%]"
                        // minDate={dayjs()}
                      />
                    </LocalizationProvider>
                    {/* {isStartDisplay && (
                    <span className="px-4 text-red-600 text-xs font-medium">
                      Date is required
                    </span>
                  )} */}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                            if (endDate)
                              formValues.no_of_days =
                                Number(endDate?.diff(newValue, "day")) + 1;
                          }}
                          className="w-full"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          format="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            setIsEndDisplay(false);
                            if (startDate)
                              formValues.no_of_days =
                                Number(newValue?.diff(startDate, "day")) + 1;
                          }}
                          className="w-full"
                          minDate={startDate}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: isEndDisplay ? "#d32f2f" : "",
                              },
                              "&:hover fieldset": {
                                borderColor: isEndDisplay ? "#d32f2f" : "",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: isEndDisplay ? "#d32f2f" : "",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: isEndDisplay ? "#d32f2f" : "",
                            },
                            "& .MuiSvgIcon-root": {
                              color: isEndDisplay ? "#d32f2f" : "",
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {isEndDisplay && (
                        <span className="px-4 text-[#d32f2f] text-xs font-medium">
                          {endDateError}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="grid grid-cols-1 gap-4 mt-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Reason"
                  multiline
                  rows="3"
                  name="reason"
                  value={formValues?.reason}
                  onChange={handleFrormChange}
                  error={!!errors.reason}
                  helperText={errors.reason}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
