import React from "react";
import { EmployeeData } from "./ViewEmployeeList";
import dayjs from "dayjs";
// import edit from "../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";

export default function PersonalEmpDetails({
  id,
  employeeData,
}: {
  id: string;
  employeeData: EmployeeData;
}) {
  const navigate = useNavigate();

  const present_experience = (dateOfJoining: string | null) => {
    if (!dateOfJoining) return "-";

    const joiningDate = dayjs(dateOfJoining);
    const now = dayjs();

    const years = now.diff(joiningDate, "year");
    const months = now.diff(joiningDate.add(years, "year"), "month");

    return `${years} year${years !== 1 ? "s" : ""} ${months} month${
      months !== 1 ? "s" : ""
    }`;
  };

  return (
    <div>
      {/* <div className="flex justify-end pr-4 pt-2">
        <img
          src={edit}
          alt="Edit"
          className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
          onClick={() => {
            navigate(`/edit-employee`, {
              state: { id: id },
            });
          }}
        />
      </div> */}
      <div className="grid grid-cols-3 pb-6 pr-6 pl-7 gap-y-5 mt-4">
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Employee ID</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.empId ?? "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Employee Code</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.empCode ?? "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Full Name</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.empName
              ? `${employeeData?.empName} ${employeeData?.empLastName}`
              : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Gender</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.prefix === "Mr." ? "Male" : "Female"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Contact Number
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.number ? employeeData?.number : "-"}
          </p>
        </div>
        {/* <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Emergency Contact{" "}
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            -
          </p>
        </div> */}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Email Id </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.email ? employeeData?.email : "-"}
          </p>
        </div>{" "}
        {/* <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Blood Group </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.bloodGroup ? employeeData?.bloodGroup : "-"}
          </p>
        </div>{" "} */}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Date of birth</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.DOB
              ? dayjs(employeeData?.DOB).format("DD MMM YYYY")
              : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Date of joining
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.DOJ
              ? dayjs(employeeData?.DOJ).format("DD MMM YYYY")
              : "-"}
          </p>
        </div>{" "}
        {/* <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Marital Status
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            -
          </p>
        </div> */}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Current Address
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black w-[250px] break-normal">
            {employeeData?.currentAddress ? employeeData?.currentAddress : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Permanent Address
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black w-[250px] break-normal">
            {employeeData?.permanentAddress
              ? employeeData?.permanentAddress
              : "-"}
          </p>
        </div>{" "}
        {/* <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Location</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.location ? employeeData?.location : "-"}
          </p>
        </div>{" "} */}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Role</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.role ? employeeData?.role : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Team Manager </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.team_manager_firstName
              ? `${employeeData?.team_manager_firstName} ${employeeData?.team_manager_lastName}`
              : "-"}
          </p>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Reporting Manager
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.reporting_manager_firstName
              ? `${employeeData?.reporting_manager_firstName} ${employeeData?.reporting_manager_lastName}`
              : "-"}
          </p>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Business Unit</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.business_unit ? employeeData?.business_unit : "-"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Department</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.department ? employeeData?.department : "-"}
          </p>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">Designation</p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.designation ? employeeData?.designation : "-"}
          </p>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Mode of employment
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.MoE === "full_time"
              ? "Full Time"
              : employeeData?.MoE === "part_time"
              ? "Part Time"
              : employeeData?.MoE === "contract"
              ? "Contract"
              : employeeData?.MoE === "internship"
              ? "Intern"
              : "-"}
          </p>
        </div>{" "}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Employment Status
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black capitalize">
            {employeeData?.status ? employeeData?.status : "-"}
          </p>
        </div>
        {/* <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            LinkedIn Profile
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            -
          </p>
        </div> */}
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Allotted WFH per week
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.alloted_wfh_per_week || "0"}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Years Of Experience
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {employeeData?.YOE || "0"} years
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-['Poppins'] text-grey400">
            Present Job Experience
          </p>
          <p className="text-sm font-medium font-['Poppins']  text-light-black">
            {present_experience(employeeData?.DOJ)}
          </p>
        </div>
      </div>
    </div>
  );
}
