import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import search from "../../assets/Search.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import del from "../../image/Vector.png";
import { Fetch_Expense_Type_List } from "../common/services/ExpenseType";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import TablePaginationDemo from "../common/Pagenation";
import { createGlobalStyle } from "styled-components";
import download from "../../image/Vector (1).png";
import date from "../../assets/date.svg";
import filter from "../../assets/filter.svg";
import DatePicker from "react-datepicker";
import { Fetch_Clients_List } from "../common/services/Clients";
import dayjs from "dayjs";
import moment from "moment";
import eye from "../../assets/Crud_Icons/view.svg";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import DynamicTable from "../common/DynamicTable";
import { ArrowUpDown, Download } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { Close } from "@mui/icons-material";
import { RootState } from "../../redux/store";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import StatusBadge from "../common/StatusBadge";
import TableSkeleton from "../common/TableSkeleton";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

interface Leave_Type {
  id: string;
  client_name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<Leave_Type[]>([]);
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );

  let customer_id = 0;
  let access_token = localStorage.getItem("access_token");

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Clients_List(
        customer_id,
        searchTerm ?? "",
        numOfItems,
        page,
        dates ? dates[0] : null,
        dates ? dates[1] : null
      );

      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);

        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {}
    setLoading(false);
  };
  const handleDownload = () => {
    if (tableData?.length > 0) {
      // Define the column headers
      const columns = [
        "Client Name",
        "Email",
        "Added Date",
        "Status",
        "Last Update",
      ];

      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...tableData?.map((row) => [
          row.client_name,
          row.email || "-", // If email is missing, set it to "-"
          row.createdAt
            ? new Date(row.createdAt).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "-", // Handle missing createdAt
          row.status
            ? row.status.charAt(0).toUpperCase() + row.status.slice(1) // Capitalize first letter
            : "-", // Ensure status is handled correctly
          row.updatedAt
            ? new Date(row.updatedAt).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "-", // Handle missing updatedAt
        ]),
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Client Data");

      // Generate the binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "client_data.xlsx"; // Customize download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchLeaveType();
  }, [page, numOfItems, searchTerm, dates]);

  const handleFilterApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        date: filterData?.dateRange,
      })
    );
    setDates(filterData?.dateRange);
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        date: [null, null],
      })
    );
  };

  const columns: ColumnDef<Leave_Type>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.id ?? "-",
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "client_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Client Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.client_name ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 pl-2 pr-2.5">
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item?.client_name}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => {
        const email = info.getValue() ?? "-";
        return email ? email : "-";
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Added Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.createdAt
          ? new Date(row.original.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Last Update
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.updatedAt
          ? new Date(row.updatedAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.updatedAt
          ? new Date(row.original.updatedAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const status: string = row?.original?.status ?? "-";
        const statusList = ["active", "inactive", "hold"];
        const statusColors = ["#E0F1E7", "#FFEAEA", "#FFEAEA"];
        const textColors = ["#0F9446", "#EB3F3F", "#C33D3D"];
        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/client-details`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/edit-clients`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between mt-6">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center w-full">
            <PersistedSearch label="Search with Client Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {dates && !(dates[0] === null && dates[1] === null) ? (
                  <button
                    onClick={handleDeleteDate}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white mt-5">
        {loading ? (
          <TableSkeleton />
        ) : (
          <DynamicTable<Leave_Type>
            onRowClick={(row) =>
              navigate(`/client-details`, {
                state: { id: row?.id },
              })
            }
            data={tableData}
            loading={loading}
            columns={columns}
            enableSorting
            enablePagination
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Client Filter"
        onApply={handleFilterApply}
        isDate={true}
        isOther={false}
      />
    </>
  );
};

export default Clients;
