import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../../redux/actions/signupActions";
import Icon from "../../assets/tick.svg";
import logo from "../../image/MicrosoftTeams-image (3).png";
import Header from "../common/header";
import { SET_PASSWORD, SIGNUP } from "../../utils/routeConstant";
import loginimage1 from "../../image/loginslide1.png";
import loginimage2 from "../../image/loginslide2.png";
import loginlogo from "../../image/loginlogo.png";
import Carousel from "react-material-ui-carousel";
import griddesign from "../../image/ornamentlogin.png";

const VerifyEmail: React.FC = () => {
  const numberOfDigits = 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verify, setVerify] = useState(false);
  const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(""));
  const [seconds, setSeconds] = useState<number>(30);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(true);
  const otpBoxReference = useRef<HTMLInputElement[]>([]);
  const [autoPlay, setAutoPlay] = useState(false);


  // Accessing the userData state from Redux store
  const userData = useSelector((state: any) => state.signup.details);

  if (userData === null) {
    const signup_token = localStorage.getItem("signup_token");
    if (signup_token) {
      //! fetch userData using api and save it to reducer
    } else {
      navigate(`${SIGNUP}`);
    }
  }

  let completeOtp: string = "";
  let userEmail: string = "";

  if (userData) userEmail = userData.sign_up_details.email;

  const handleChange = (value: string, index: number) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  // const handleOtp=()=>{
  //   navigate("/signup-password")
  // }


  
    useEffect(() => {
      setAutoPlay(true);
    }, []);

  const handleOtp = async () => {
    setVerify(true);
    // validation
    if (completeOtp.length < 6) {
      toast.dismiss();
      toast.error("Fill complete OTP");
      return;
    }
    // Dispatch cannotLogin action
    try {
      toast.loading("Verifying email...");
      const response = await dispatch<any>(verifyEmail(userEmail, completeOtp));
      toast.dismiss();
      if (response.success) {
        toast.success(<b>{response.message}</b>);
        navigate("/signup-password");
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.response.data.message);
    } finally {
      setVerify(false);
    }
  };

  const handleBackspaceAndEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (
      e.key === "Enter" &&
      e.currentTarget.value &&
      index < numberOfDigits - 1
    ) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  if (otp.every((digit) => /\d/.test(digit))) {
    completeOtp = otp.join("");
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isTimerActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(timer);
  }, [seconds, isTimerActive]);

  const handleResendOtp = () => {
    alert("OTP Resent!");
    setSeconds(30);
    setIsTimerActive(true);
  };

  return (
    <div className="">
      {/* <Header /> */}
      <div className="flex justify-center  h-screen px-4 py-4 ">
      <div className="relative w-1/2 flex flex-col justify-center items-center  bg-gradient-to-r from-[#E8EBF6] to-[#F1E3FD] rounded-[8px] overflow-hidden ">
      <img
            src={griddesign}
            alt="Gridline Ornament"
            className="absolute top-[0px] left-[0px] w-[424px] h-[370px] object-contain pointer-events-none"
          />
       <section className=" rounded-xl w-full min-h-screen flex flex-col justify-between pt-10 pb-10 ">
            <div className=" top-1 pl-4">
              <div className="font-poppins text-[35px] font-[600] text-[#020617] leading-[44px] mb-4">
                Automated HR Workflows for Smarter Management.
              </div>
              <div className="w-[98px] h-[2px] bg-[#374151]"></div>
            </div>

            <div dir="rtl">
              <Carousel
                autoPlay={autoPlay}
                interval={5000}
                animation="slide"
                duration={500}
                indicators={false}
                navButtonsAlwaysInvisible={true}
                stopAutoPlayOnHover={false}
                className="rounded-lg overflow-hidden"
                indicatorContainerProps={{
                  style: { marginTop: "-10px" },
                }}
              >
                <div className=" p-4  flex flex-col items-center text-center">
                  <h3 className="text-[18px] font-poppins font-[500]">
                    Track Employee Attendance
                  </h3>
                  <p className="text-sm text-gray-600 text-center max-w-[400px]">
                    Stay updated with employee work hours, leaves, and
                    attendance history.
                  </p>
                  <img
                    src={loginimage1}
                    alt="Attendance"
                    className=" w-[630px] object-contain mt-4 mb-2 rounded"
                  />
                </div>

                <div className=" p-4  flex flex-col items-center text-center">
                  <h3 className="text-[18px] font-poppins font-[500]">
                    Track Project Health and Profitability
                  </h3>
                  <p className="text-sm text-gray-600 text-center max-w-[400px]">
                    Track progress, assign tasks, and manage contributions
                    seamlessly.
                  </p>

                  <img
                    src={loginimage2}
                    alt="Project"
                    className=" w-[630px] object-contain mt-4 mb-2 rounded"
                  />
                </div>
              </Carousel>
            </div>
          </section>
        </div>
        <div className="w-1/2 bg-white flex flex-col items-center justify-center p-10 rounded-xl">
        <div className=" items-center gap-2 mb-6">
            <img
              src={loginlogo}
              alt="Logo"
              className=" w-[200px] h-auto object-cover mb-2 rounded"
            />
          </div>
          <div className=" shadow-lg border border-[#E2E8F0] rounded-lg p-4 top-[73px]">
          <div className=" mb-4 ml-5">
              <p className="text-neutral-800 font-['Inter'] font-semibold text-[22px] text-[#374151]">
                {" "}
                Email verification              </p>
              <p className="text-neutral-400 font-['poppins'] font-[400] text-[14px] text-[#374151] max-w-full break-words">
              Please enter 6 digit code we just sent to <br /><span className="text-[#4165C4] text-[12px] font-[500] font-['Poppins']">
                {userEmail || "your@email.com"}
              </span>
              </p>
            </div>
          <form className="flex flex-col w-[21rem]">
            <div className="flex justify-center gap-2">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  ref={(reference: any) =>
                    (otpBoxReference.current[index] = reference!)
                  }
                  className="w-[2.3rem] h-[2.5rem] bg-zinc-100 rounded-[3px] border border-[#C6C6C6] text-center"
                />
              ))}
            </div>
            <div className="flex justify-center my-[2.5rem]">
              <button
                className="text-white w-[19rem] h-10 buttoncss rounded-[8px] text-sm font-semibold font-['Poppins']"
                onClick={handleOtp}
                type="button"
              >
                Verify
              </button>
            </div>
            {/* <div className="flex justify-center font-[400] text-[14px] mt-4">
              Resend OTP
            </div> */}

            {/* Uncomment to add resend button */}
            {/* {isTimerActive ? (
              <div className="flex justify-center font-[400] font-['Poppins'] text-[14px] text-gray-500 mb-5 gap-2">
                <button className="disabled">
                  Resend OTP
                </button>
                <div>
                  {Math.floor(seconds / 60)}:
                  {String(seconds % 60).padStart(2, "0")}
                </div>
              </div>
            ) : (
              <div className="flex justify-center font-[400] font-['Poppins'] text-[14px] mt-5 mb-5">
                <button
                  onClick={handleResendOtp}
                  className="text-blue-500"
                >
                  Resend OTP
                </button>
              </div>
            )} */}
          </form>

        </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
