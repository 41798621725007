import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { EditProjectDetails } from "../../EditProject/EditProjectDetails";
import EditResources from "./EditResources";
import EditTask from "./EditTask";
import ProjectUpdate from "./ProjectUpdate";
import { useDispatch } from "react-redux";
import {
  setProjectTab,
  setProjectId,
} from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { useSelector } from "react-redux";

// const Label = ({ number, title, description, isSelected }: any) => {
//   return (
//     <div className="w-full h-full p-[17px]">
//       <div className="flex flex-col gap-2.5">
//         <div
//           className={`w-10 h-10 flex justify-center items-center border-[3px] rounded-full ${
//             isSelected ? "border-white" : "border-black"
//           }`}
//           style={{ borderColor: isSelected ? "white" : "var(--22, #4165C4)" }}
//         >
//           <span
//             className={`font-[Poppins] font-semibold text-[17px] leading-[25.5px] ${
//               isSelected ? "text-white" : "text-[#4165C4]"
//             }`}
//           >
//             {number}
//           </span>
//         </div>
//         <div className="flex flex-col gap-[4px]">
//           <span
//             className={`font-[Poppins] font-[600] text-[16px] leading-[25.5px] text-left ${
//               isSelected ? "text-white" : "text-black"
//             }`}
//           >
//             {title}
//           </span>
//           <span
//             className={`font-[Poppins] font-[500] text-[12px] text-left ${
//               isSelected ? "text-[#EDEDED]" : "text-[#1D1A22]"
//             }`}
//           >
//             {description}
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

const Label = ({ number, title, description, isSelected }: any) => {
  return (
    <div
      className={`flex items-left w-full px-2 py-3 rounded-md transition-all
        // isSelected
        //   ? "bg-gradient-to-r from-[#4A00E0] to-[#8E2DE2] text-white"
        //   : "bg-[#E8E0FF] text-[#374151]"
      `}
    >
      <div
        className={`w-10 h-10 flex justify-center items-center`}
        // style={{ borderColor: isSelected ? "white" : "var(--22, #4165C4)" }}
      >
        <span className="w-10 h-10 flex items-center text-[#4165C4] bg-[#FFFFFF] justify-center shrink-0 rounded-sm text-base font-bold">
          {number}
        </span>
      </div>
      <div className="ml-3 flex flex-col gap-[4px]">
        <span
          className={`font-[Poppins] font-medium text-base leading-[20.5px] text-left ${
            isSelected ? "text-white" : "text-black"
          }`}
        >
          {title}
        </span>
        <span
          className={`font-[Poppins] font-normal text-xs text-left ${
            isSelected ? "text-white" : "text-black"
          }`}
        >
          {description}
        </span>
      </div>
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const param = useParams();
  const navigate = useNavigate();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const EditProjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const projectTab = useSelector((state: any) => state.projectSlice2.project_tab);
  const [value, setValue] = useState(projectTab ?? 0);

  useEffect(() => {
    setValue(projectTab);
  }, [projectTab]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    // dispatch(setProjectId(param?.id ?? null));
    // dispatch(setProjectTab(newValue));
  };

  return (
    <div className="max-h-[90vh] w-full px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
          variant="fullWidth"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .Mui-selected": {
              background: "linear-gradient(90deg, #4165c4 0%, #8e1fd2 100%);",
              color: "#fff",
            },
            background: "#E8E0FF",
            borderRadius: "6px",
          }}
        >
          <Tab
            label={
              <Label
                number="1"
                title="Project"
                description="Configure the project details"
                isSelected={value === 0}
              />
            }
            {...a11yProps(0)}
            className="text-lg"
            style={{ textTransform: "none" }}
            onClick={() => {
              if (value !== 0) {
                setValue(0);
                // dispatch(setProjectId(param?.id ?? ""));
                dispatch(setProjectTab(0));
              }
            }}
          />
          <Tab
            label={
              <Label
                number="2"
                title="Resources"
                description="Add resources & billable cost/hour"
                isSelected={value === 1}
              />
            }
            {...a11yProps(1)}
            className="text-lg"
            style={{ textTransform: "none" }}
            onClick={() => {
              if (value !== 1) {
                setValue(1);
                // dispatch(setProjectId(param?.id ?? ""));
                dispatch(setProjectTab(1));
              }
            }}
          />
          <Tab
            label={
              <Label
                number="3"
                title="Tasks"
                description="Add the tasks and assign to the resources"
                isSelected={value === 2}
              />
            }
            {...a11yProps(2)}
            className="text-lg"
            style={{ textTransform: "none" }}
            onClick={() => {
              if (value !== 2) {
                setValue(2);
                // dispatch(setProjectId(param?.id ?? ""));
                dispatch(setProjectTab(2));
              }
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ProjectUpdate />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditResources />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EditTask />
        </TabPanel>
      </Box>
    </div>
  );
};

export default EditProjects;
