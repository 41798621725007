import React, { useEffect, useState, useRef } from "react";
import {
  Fetch_Announcement_Lst,
  Fetch_Birthday_Anniversary_Lst,
  Fetch_Delayed,
  Fetch_Leave_Dashboard_List,
  Fetch_NotCheckin,
  Fetch_WFH_Dashboard_List,
  Fetch_Work_From_Office,
} from "../common/services/Dashboard";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import noResult from "../../assets/noResult.svg";
import { enGB, enUS } from "date-fns/locale";
import avatar from "../../assets/avatar.svg";
import viewAll from "../../assets/viewAll.svg";
import ShowWfhModal from "./ShowWFH";
import ShowNotCheckInModal from "./ShowNotCheckIn";
import ShowDelayModal from "./ShowDelay";
import "./Dashboard.css";
import ShowLeaveModal from "./showLeave";
import ShowWfoModal from "./showWFO";
import date2 from "../../assets/date2.svg";
import { createGlobalStyle } from "styled-components";
import DatePicker from "react-datepicker";
import dayjs, { Dayjs } from "dayjs";
import { ClassNames } from "@emotion/react";
import DashboardAdmin from "../DashboardAdmin/DashboardAdmin";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

// dayjs.extend(duration);

interface Leave_Type {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  wfh_session: string;
}

interface DashboardData {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  leave_session: string;
}

interface WFO {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
}

interface BirthdayAnniversary {
  firstName: string;
  lastName: string;
  profile_image: string;
  type: string;
}

interface Announcement {
  title: string;
  date: string;
  description: string;
}

interface Delayed {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
  };
  delay_session: string;
}

interface NotCheckIn {
  firstName: string;
  lastName: string;
  profile_image: string;
  notCheckIn_session: string;
}

const Dashboard = () => {
  const role = useSelector(
    (state: any) => state?.auth?.login?.login_details?.role
  );
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);
  const [tableData, setTableData] = useState<Leave_Type[]>([]);
  const [wfhCount, setWfhCount] = useState(0);
  const [wfoCount, setWfoCount] = useState(0);
  const [delayCount, setDelayCount] = useState(0);
  const [notCheckCount, setNotCheckCount] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);
  const [tableData1, setTableData1] = useState<DashboardData[]>([]);
  const [tableData2, setTableData2] = useState<BirthdayAnniversary[]>([]);
  const [tableData3, setTableData3] = useState<Announcement[]>([]);
  const [tableData4, setTableData4] = useState<WFO[]>([]);
  const [delayedList, setDelayedList] = useState<Delayed[]>([]);
  const [notCheckinList, setNotCheckinList] = useState<NotCheckIn[]>([]);
  const [wfh, setWfh] = useState<boolean>(false);
  const [wfo, setWfo] = useState<boolean>(false);
  const [leave, setLeave] = useState<boolean>(false);
  const [notCheckIn, setNotCheckIn] = useState<boolean>(false);
  const [delay, setDelay] = useState<boolean>(false);
  const selectedCalendarRef = useRef<HTMLDivElement>(null);
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  // const handleSelectedDateChange = (newValue: Dayjs) => {
  //   setSelectedDate(newValue);
  // };

  const handleSelectedCalanderClick = () => {
    setIsSelectedOpen(!isSelectedOpen);
  };

  useEffect(() => {
    if (role === "Admin") return;
    const fetchData = async () => {
      try {
        const formattedDate = selectedDate
          ? selectedDate.format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD");
        const results = await Promise.allSettled([
          Fetch_WFH_Dashboard_List(10, 1, selectedDate),
          Fetch_Leave_Dashboard_List(10, 1, selectedDate),
          Fetch_Birthday_Anniversary_Lst(10, 1, selectedDate),
          Fetch_Announcement_Lst(10, 1, selectedDate),
          Fetch_Work_From_Office(10, 1, selectedDate),
          Fetch_Delayed({ numOfItems: 10, page: 1, date: selectedDate }),
          Fetch_NotCheckin({ numOfItems: 10, page: 1, date: selectedDate }),
        ]);

        const [
          wfhResponse,
          leaveResponse,
          birthdayResponse,
          announcementResponse,
          wfoResponse,
          delayedResponse,
          notCheckinResponse,
        ] = results.map((result) =>
          result.status === "fulfilled" ? result.value : null
        );

        wfhResponse && setTableData(wfhResponse?.data?.result?.rows);
        wfhResponse && setWfhCount(wfhResponse?.data?.result?.count);
        leaveResponse && setTableData1(leaveResponse?.data?.result?.rows);
        leaveResponse && setLeaveCount(leaveResponse?.data?.result?.count);
        birthdayResponse && setTableData2(birthdayResponse?.data?.result?.rows);
        announcementResponse &&
          setTableData3(announcementResponse?.data?.result?.rows);
        wfoResponse && setTableData4(wfoResponse?.data?.result?.rows);
        wfoResponse && setWfoCount(wfoResponse?.data?.result?.count);
        delayedResponse && setDelayedList(delayedResponse?.data?.result?.rows);
        delayedResponse && setDelayCount(delayedResponse?.data?.result?.count);
        notCheckinResponse &&
          setNotCheckinList(notCheckinResponse?.data?.result?.rows);
        notCheckinResponse &&
          setNotCheckCount(notCheckinResponse?.data?.result?.count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedDate]);

  const displayWFH = tableData?.slice(0, 3);
  const displayLeave = tableData1?.slice(0, 3);
  const displayWFO = tableData4?.slice(0, 3);
  const displayDelay = delayedList?.slice(0, 3);
  const displayNotCheckIn = notCheckinList?.slice(0, 3);

  const currentDate = new Date();  

  const formattedDate = format(selectedDate.toDate(), "EEEE, do MMMM yyyy", {
    locale: enGB,
  });

  if(role=== "Admin"){
    return (
      <DashboardAdmin />
    )
  }

  return (
    <div className="bg-[#F9F9F9] pb-10">
      <header className="flex justify-between items-center h-10 px-4 pt-6 pb-2">
        <div>
          <p className="text-[#64748B] font-[400] font-[Poppins] text-[14px]">
            {formattedDate}
          </p>
          <h1 className="text-[#3F3F3F] font-[500] font-[Poppins] text-[20px]">
            {loginData && loginData.first_name ? (
              <span>
                {" "}
                <span className="text-[#3D3D3D] font-[400] pr-1">Welcome</span>
                {loginData.first_name} {loginData.last_name}
              </span>
            ) : signupData && signupData.sign_up_details.firstName ? (
              `Welcome ${signupData.sign_up_details.firstName} ${signupData.sign_up_details.lastName}`
            ) : (
              "Welcome"
            )}
          </h1>
        </div>
        <div className="flex gap-x-2">
          <div
            className="flex h-12 p-4 justify-between items-center rounded-md border-[1px] border-solid border-[#E2E8F0] bg-white"
            ref={selectedCalendarRef}
          >
            <img
              src={date2}
              alt="date icon"
              className="w-5 h-5 cursor-pointer"
              onClick={handleSelectedCalanderClick}
            />
            <GlobalStyle />
            <DatePicker
              className="w-[110px] text-center text-['Inter'] font-[400] cursor-pointer dashboard-calendar"
              dateFormat={"dd MMM yyyy"}
              selected={selectedDate?.toDate() || null}
              onChange={(date: Date | null) => {
                setSelectedDate(date ? dayjs(date) : dayjs());
                handleSelectedCalanderClick();
              }}
              open={isSelectedOpen}
              onInputClick={handleSelectedCalanderClick}
            />
          </div>
        </div>
      </header>
      <div className="grid grid-cols-1 px-4 md:grid-cols-3 gap-6 mt-1">
        <div className="col-span-2 ">
          <div className="bg-white shadow-custom rounded-lg p-4 mt-4 h-[calc(100vh-11rem)] overflow-y-auto scrollbar-hide">
            {tableData1?.length !== 0 ? (
              <div className="mb-2 mt-1">
                <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] pb-4">
                  On Leave ({leaveCount})
                </h2>

                <div className="grid grid-cols-4 gap-4">
                  {displayLeave?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.User?.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.User?.firstName} {user?.User?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.leave_session}
                        </p>
                      </div>
                    </div>
                  ))}
                  {tableData1 && tableData1?.length > 4 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer w-12 h-12 rounded-full"
                        onClick={() => setLeave(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {tableData?.length !== 0 ? (
              <div className="mb-2 mt-1">
                <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] pb-4">
                  Working From Home ({wfhCount})
                </h2>

                <div className="grid grid-cols-4 gap-4">
                  {displayWFH?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.User?.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.User?.firstName} {user?.User?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.wfh_session}
                        </p>
                      </div>
                    </div>
                  ))}
                  {tableData && tableData?.length > 4 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setWfh(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div style={{ minHeight: "mb-2" }}>
              <h2 className="text-[#1D1A22] font-[500] text-[20px] pb-4 font-[Poppins]">
                Working From Office ({wfoCount})
              </h2>

              {tableData4?.length !== 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  {displayWFO?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.User?.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                        {user?.User?.firstName} {user?.User?.lastName}
                      </p>
                    </div>
                  ))}
                  {tableData4 && tableData4?.length > 4 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setWfo(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="h-[calc(100% - 30px)] w-full flex justify-center items-center">
                  <img src={noResult} alt="No results" />
                </div>
              )}
            </div>

            {delayedList?.length !== 0 ? (
              <div className="mb-2 mt-1">
                <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] pb-4">
                  Delayed Check In ({delayCount})
                </h2>

                <div className="grid grid-cols-4 gap-4">
                  {displayDelay?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.User?.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.User?.firstName} {user?.User?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.delay_session}
                        </p>
                      </div>
                    </div>
                  ))}
                  {delayedList && delayedList?.length > 4 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setDelay(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {notCheckinList?.length !== 0 ? (
              <div className="mb-2 mt-1">
                <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] pb-4">
                  Not Check In ({notCheckCount})
                </h2>

                <div className="grid grid-cols-4 gap-4">
                  {displayNotCheckIn?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.profile_image || avatar}
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.firstName} {user?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.notCheckIn_session}
                        </p>
                      </div>
                    </div>
                  ))}
                  {notCheckinList && notCheckinList?.length > 4 ? (
                    <div className="w-full flex justify-center items-start">
                      <img
                        src={viewAll}
                        alt="view all button"
                        className="cursor-pointer h-12 w-12"
                        onClick={() => setNotCheckIn(true)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="min-h-[72vh] mt-4">
          <div className="bg-white shadow-custom rounded-lg p-4 h-[calc(47vh-5rem)] overflow-y-auto scrollbar-hide">
            <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] mb-4">
              Announcements
            </h2>
            {tableData3?.length !== 0 ? (
              <div className="flex flex-col">
                <div className="overflow-y-auto scrollBar">
                  {tableData3?.map((item) => (
                    <div className="mb-4 bg-[#EAE0FF4D] p-2 rounded-[5px] ">
                      <div className="flex justify-between w-full">
                        <p className="text-[#1F2937] text-[16px] font-[500] font-[Poppins]">
                          {item?.title}
                        </p>
                        {/* <span className="font-[Poppins] font-[400] text-[#4B5563] text-[12px]">
                          {item?.date}
                        </span> */}
                      </div>
                      <p className="font-[Poppins] font-[400] text-[#4B5563] text-[12px]">
                        {item?.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-48">
                <img src={noResult} alt="No results" />
              </div>
            )}
          </div>
          <div className="bg-white shadow-custom rounded-lg p-4 mt-4 h-[calc(48vh-5rem)] overflow-y-auto scrollbar-hide">
            <h2 className="text-[#1D1A22] font-[500] text-[20px] font-[Poppins] mb-4">
              Birthdays & Anniversaries
            </h2>
            {tableData2?.length !== 0 ? (
              <div className="gap-2 pt-2">
                {tableData2?.map((item) => (
                  <div className="flex viewBackground p-1.5 rounded-md">
                    <img
                      src={item?.profile_image || avatar}
                      alt=""
                      className="w-8 h-8 rounded-full"
                    />
                    <div className="flex flex-col">
                      <p className="text-[#1D1A22] font-[500] font-[Poppins] text-[12px]">
                        {item?.firstName} {item?.lastName}'s
                      </p>
                      <p className="text-[#49454F] font-[500] font-[Poppins] text-[10px]">
                        {item?.type}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-full w-full flex justify-center items-center pt-2">
                <img src={noResult} alt="No results" className="h-20" />
              </div>
            )}
          </div>
        </div>
      </div>
      <ShowWfhModal
        isOpen={wfh}
        onClose={() => setWfh(false)}
        date={selectedDate}
      />
      <ShowLeaveModal
        isOpen={leave}
        onClose={() => setLeave(false)}
        date={selectedDate}
      />
      <ShowWfoModal
        isOpen={wfo}
        onClose={() => setWfo(false)}
        date={selectedDate}
      />
      <ShowNotCheckInModal
        isOpen={notCheckIn}
        onClose={() => setNotCheckIn(false)}
        date={selectedDate}
      />
      <ShowDelayModal
        isOpen={delay}
        onClose={() => setDelay(false)}
        date={selectedDate}
      />
    </div>
  );
};

export default Dashboard;
