import React, { useCallback, useEffect, useState } from "react";
import DoughnutChart, {
  centerTextPlugin,
} from "../common/Charts/DoughnutChart";
import StackedBarChart from "../common/Charts/StackedBarChart";
import Search from "../SearchBar/Search";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import { debounce } from "lodash";
import TablePaginationDemo from "../common/Pagenation";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import view from "../../assets/Crud_Icons/view.svg";
import toast from "react-hot-toast";
import {
  Fetch_Expense_Data,
  Fetch_Expense_List,
  Fetch_User_Expense_Chart,
  Fetch_Expense_Status,
} from "../common/services/Employee";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axiosInstance from "../../app/api/axiosInstance";

interface IEmployeeExpenseList {
  id: number;
  expense_date: string | null;
  createdAt: string | null;
  ExpenseCategory: {
    expense_category_name: string;
  };
  expense_amount: string;
  expense_currency: string;
  status: string;
}
interface Category {
  category_id: number;
  category_name: string;
  amount: number;
}

interface IExpense {
  week: number;
  total_amount: number;
  categories: Category[];
}

interface ITotalExpenseData {
  total_approved_amount: string;
  approved_count: string;
  pending_count: string;
}

interface ExpenseCategory {
  expense_category_name: string;
}

interface IExpenseStats {
  category_id: number;
  total_amount: string;
  expense_count: string;
  ExpenseCategory: ExpenseCategory;
}

// const expenseStats = [
//   {
//     label: "Travel",
//     value: 45,
//     color: "#ACC9FF",
//   },
//   {
//     label: "Food",
//     value: 25,
//     color: "#BEE5B9",
//   },
//   {
//     label: "Other",
//     value: 30,
//     color: "#E6C6ED",
//   },
// ];

const generatePastelColor = () => {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 70%, 85%)`;
};

export default function EmployeeExpense({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) {
  const navigate = useNavigate();
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [expenseListData, setExpenseListData] = useState<
    IEmployeeExpenseList[]
  >([]);
  const [expenseListCount, setExpenseListCount] = useState<number>(10);
  const [totalExpenseData, setTotalExpenseData] = useState<ITotalExpenseData>({
    total_approved_amount: "",
    approved_count: "",
    pending_count: "",
  });
  const [expenseDoughnutData, setExpenseDoughnutData] = useState<
    IExpenseStats[]
  >([]);

  const expenseStats = expenseDoughnutData.map((item) => ({
    label: item.ExpenseCategory.expense_category_name,
    value: parseFloat(item.total_amount),
    color: generatePastelColor(),
  }));

  const defaultDoughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    centerText: `Total Amount\nINR ${expenseStats.reduce(
      (sum, stat) => sum + stat.value,
      0
    )}`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
      centerTextPlugin,
    },
  };

  // const [view, setView] = useState<string>("Weekly");
  const [page, setPage] = useState<number>(1);
  const [numOfItems, setNumOfItems] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [graphData, setGraphData] = useState<IExpense[]>([]);

  function handleNumberOfItems(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 1000),
    [setSearchTerm]
  );
  const downloadExcelFile = async () => {
    try {
      let query = `/api/v1/expense-report/${id}?customer_id=${customer_id}`;

      if (startDate && endDate) {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Expense_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const fetchExpenseData = async () => {
    try {
      const response = await Fetch_Expense_Data({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response?.status === 200) {
        setTotalExpenseData(response.data?.result);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  const fetchExpenseList = async () => {
    try {
      const response = await Fetch_Expense_List({
        id: id,
        customer_id: customer_id,
        search: searchTerm,
        page: page,
        size: numOfItems,
        start_date: startDate,
        end_date: endDate,
      });
      if (response?.status === 200) {
        setExpenseListData(response.data?.result?.rows);
        setExpenseListCount(response.data?.result?.count);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  const fetchExpenseChartData = async () => {
    try {
      const response = await Fetch_User_Expense_Chart(id, startDate, endDate);
      if (response?.status_code === 200) {
        setExpenseDoughnutData(response?.result);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchExpenseData();
    fetchExpenseChartData();
  }, [id, startDate, endDate]);

  useEffect(() => {
    fetchExpenseList();
  }, [id, page, searchTerm, startDate, endDate, numOfItems]);

  const columns: ColumnDef<IEmployeeExpenseList>[] = [
    {
      accessorKey: "expense_category_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Expense Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.ExpenseCategory?.expense_category_name ?? "-",
      cell: ({ row }) => {
        if (row?.original?.ExpenseCategory?.expense_category_name) {
          return (
            <div className="pl-2">
              {row?.original?.ExpenseCategory?.expense_category_name}
            </div>
          );
        } else {
          return <div>--</div>;
        }
      },
    },
    {
      accessorKey: "expense_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Expense Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.expense_date
          ? new Date(row.expense_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.expense_date
          ? new Date(row.original.expense_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px]">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Applied On
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.createdAt
          ? new Date(row.original.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px]">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "expense_amount",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.expense_amount ?? "-",
      cell: ({ row }) => {
        if (row?.original?.expense_amount && row?.original?.expense_currency) {
          return (
            <div>
              {row?.original?.expense_currency} {row?.original?.expense_amount}
            </div>
          );
        } else if (row?.original?.expense_amount) {
          return <div>{row?.original?.expense_amount}</div>;
        } else {
          return <div>--</div>;
        }
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.status ?? "-";
        return row?.original?.status ? (
          <div
            className={`flex justify-center items-center py-[2px] px-[2px] rounded-full ${
              row.original.status === "Approved"
                ? " bg-[#ECFDF3] text-[#137F40]"
                : row.original.status === "Pending"
                ? " bg-[#FEEBDD] text-[#FF9950]"
                : " bg-[#FEEBDD] text-[#FF9950]"
            }`}
          >
            {name}
          </div>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0 mr-4"
              onClick={() =>
                navigate("/view-expense", {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={view}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const fetchWeeklyExpense = useCallback(async () => {
    try {
      const response = await Fetch_Expense_Status({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setGraphData(response?.data?.result ?? []);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [endDate, id, startDate]);

  const categoryColors: Record<string, string> = {
    Food: "#6496F4",
    Travel: "#8AB3FF",
    Other: "#CEDFFF",
  };

  const expenseGraphData = Object.keys(categoryColors).map((category) => ({
    label: category,
    data: graphData.map((week) => {
      const categoryData = week.categories.find((cat) =>
        category === "Other"
          ? !["Food", "Travel"].includes(cat.category_name)
          : cat.category_name === category
      );
      return categoryData ? categoryData.amount : 0;
    }),
    color: categoryColors[category],
  }));
  const graphLabels = graphData.map((week) => `Week ${week.week}`);
  console.log({ expenseGraphData });
  useEffect(() => {
    fetchWeeklyExpense();
  }, [fetchWeeklyExpense]);

  const expenseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => {
            return tooltipItems[0].label;
          },
          // label: (tooltipItem: any) => {
          //   let datasetLabel = data?.datasets[tooltipItem.datasetIndex].label;
          //   let value = tooltipItem.raw;
          //   return `${datasetLabel}: ${value}`;
          // },
          footer: (tooltipItems: any) => {
            // Calculate total sum for the hovered bar
            const total = tooltipItems?.reduce(
              (sum: number, item: any) => sum + item.raw,
              0
            );
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div className="flex justify-between gap-4 flex-wrap items-center ">
        <div className="h-[98px] rounded-lg flex-1 bg-[rgba(172,201,255,0.25)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Total Amount
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {totalExpenseData?.total_approved_amount}
          </p>
        </div>{" "}
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(237,217,242,0.34)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Total Claims
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {totalExpenseData?.approved_count ||
              0 + totalExpenseData?.pending_count ||
              0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(205,235,197,0.30)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Approved
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {totalExpenseData?.approved_count}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(251,215,150,0.30)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Pending
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {totalExpenseData?.pending_count}
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-between">
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[25%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Expense Type
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <DoughnutChart
              chartData={expenseStats}
              options={defaultDoughnutOptions}
            />
          </div>
        </div>
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[74%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Expense Overview
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <StackedBarChart
              chartData={expenseGraphData}
              labels={graphLabels}
              options={expenseOptions}
            />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee name"
            />
            <div className="h-full flex items-center">
              <div className="h-full pl-4">
                <Button
                  onClick={downloadExcelFile}
                  className="border rounded-md gap-2"
                >
                  <Download /> Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-2">
        <div className="table_main_content w-full max-h-[calc(100vh-27rem)] relative overflow-auto bg-white">
          <DynamicTable<IEmployeeExpenseList>
            data={expenseListData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>
      </div>

      {expenseListCount >= 10 && (
        <TablePaginationDemo
          count={expenseListCount}
          handleItemsChange={handleNumberOfItems}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </div>
  );
}
