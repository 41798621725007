import React, { useEffect, useState } from "react";
import { fetchEditTask } from "../../common/services/Project";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../../image/chevron-right.png";
import Receipt from "../../ExpenseManagement/ImageView";
import ModalViewer from "../../ExpenseManagement/ModalViewer";
import ResourceCard from "../../common/ResourceCard";
import DocumentCard from "../../common/shared/DocumentCard";

interface TaskData {
  id: number;
  task_name: string;
  estimated_hours: string | number;
  actual_hours: string | number | null;
  start_date: string | null;
  end_date: string | null;
  status: string;
  project_id: number | string;
  description: string | null;
  module_name: string | null;
  milestone_name: string | null;
  task_document: string[] | [] | null;
}

interface Resources {
  user_id: string;
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
    Designation: {
      name: string;
    };
  };
}

interface ApiResponse {
  rows: {
    id: number;
    expense_date: string;
    expense_amount: number;
    task_document: string[];
    receipt_filename: string[];
  }[];
}

interface HtmlContentProps {
  htmlString: string | undefined;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ htmlString }) => {
  const trimmedHtml = htmlString?.trim() || "-";
  return <div dangerouslySetInnerHTML={{ __html: trimmedHtml }} />;
};

const downloadFile = (url: string, filename: string) => {
  try {
    const link = document.createElement("a");
    link.href = url;

    const cleanFilename = filename.replace(/[^a-zA-Z0-9.\-_]/g, "_");
    link.download = cleanFilename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {}
};

const Status = [
  { value: "inprogress", label: "Working" },
  { value: "todo", label: "To do" },
  { value: "completed", label: "Finished" },
  { value: "onhold", label: "Hold" },
];
const statusMap = Object.fromEntries(Status.map(({ value, label }) => [value, label]));

const ViewTask = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const path = location.state?.path;
  // const [receipts, setReceipts] = useState<ApiResponse["rows"]>([]);
  // const [taskDocument, setTaskDocument] = useState<string[]>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [resources, setResources] = useState<Resources[]>([]);
  const [taskData, setTaskData] = useState<TaskData>({
    id: 0,
    task_name: "",
    estimated_hours: "",
    actual_hours: "",
    start_date: "",
    end_date: "",
    status: "",
    project_id: "",
    description: "",
    module_name: "",
    milestone_name: "",
    task_document: [],
  });
  // const fromAdd = location?.state?.fromAdd;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchEditTask(id);

        if (data?.result) {
          const result = data?.result;

          setTaskData({
            id: result?.id ?? null,
            task_name: result?.task_name ?? "-",
            estimated_hours: result?.estimated_hours ?? null,
            actual_hours: result?.actual_hours ?? null,
            start_date: result?.start_date ?? null,
            end_date: result?.end_date ? result?.end_date : null,
            status: result?.status ?? "-",
            project_id: result?.project_id ?? "-",
            description: result?.description ?? "-",
            module_name: result?.Module?.module_name ?? "-",
            milestone_name: result?.ProjectMilestone?.milestone_name ?? "-",
            task_document: result?.task_document ?? [],
          });

          // setReceipts([result ?? {}]);
          // setTaskDocument(result?.task_document ?? []);
          setResources(
            Array.isArray(result?.TaskResources) ? result?.TaskResources : []
          );
        }
      } catch (error) {
        console.error("Error fetching task data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  return (
    <div className="px-4 pt-6 pb-12">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Task Details
          </span>
        </div>
      </div>
      <div className="flex flex-col p-4 border border-gray-200 rounded-lg shadow-lg bg-white mt-2">
        <div className="flex justify-between items-start pb-3 border-b border-gray-200">
          <div>
            <div className="flex gap-2">
              <h1 className="text-[#111827] text-lg font-[700] font-inter">
                {taskData?.task_name} {"-"}
              </h1>
              <div className="flex flex-col justify-center items-center gap-[7px] px-[9px] py-0 rounded-[2px] bg-[#E5E7EB]">
                <span className="text-light-black text-base font-medium leading-6 font-['Poppins']">
                  {/* {taskData?.status &&
                    taskData?.status?.charAt(0)?.toUpperCase() +
                      taskData?.status?.slice(1)} */}
                  {statusMap[taskData?.status] ?? taskData?.status}
                </span>
              </div>
            </div>
            <div className="text-base mt-2 leading-6 font-['Poppins'] font-normal text-light-black">
              Task ID: {taskData?.id}
            </div>
          </div>
          <div className="text-right">
            <div className="text-[#111827] text-base font-semibold leading-6 font-inter">
              Start & End Date
            </div>
            <div className="text-light-black text-base font-normal leading-6 font-inter mt-2">
              {taskData?.start_date
                ? new Date(taskData.start_date).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "-"}{" "}
              -{" "}
              {taskData?.end_date
                ? new Date(taskData.end_date).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "N/A"}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 p-1 py-3 gap-4">
          <p>
            <div className="text-custom-gray-500 font-['Poppins'] font-medium text-[16px] ">
              Milestone Name
            </div>{" "}
            <div className="text-light-black font-['Poppins'] font-[600] mt-2 text-[16px] ">
              {taskData?.milestone_name ?? "-"}
            </div>
          </p>
          <p>
            <div className=" text-custom-gray-500 font-['Poppins'] font-medium text-[16px] ">
              Module Name
            </div>{" "}
            <div className="text-light-black font-['Poppins'] font-[600] mt-2 text-[16px] ">
              {taskData?.module_name ?? "-"}
            </div>
          </p>
          <p>
            <div className=" text-custom-gray-500 font-['Poppins'] font-medium text-[16px] ">
              Estimated Hours
            </div>

            <div className="text-light-black font-['Poppins'] font-[600] mt-2 text-[16px] ">
              {" "}
              {taskData?.estimated_hours
                ? taskData?.estimated_hours + " hrs"
                : "-"}
            </div>
          </p>
          <p>
            <div className=" text-custom-gray-500 font-['Poppins'] font-medium text-[16px] ">
              Actual Hours
            </div>
            <div className="text-light-black font-['Poppins'] font-[600] mt-2 text-[16px] ">
              {" "}
              {taskData?.actual_hours ? taskData?.actual_hours + " hrs" : "-"}
            </div>
          </p>
        </div>
        <p>
          <div className="text-custom-gray-500 font-['Poppins'] font-medium text-[16px] pl-1 pt-2">
            Assigned To:
          </div>
          <div className="p-1 grid grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3 pt-1">
            {resources?.map((resource, index) => (
              <ResourceCard key={index} resource={resource} />
            ))}
          </div>
        </p>

        <p>
          <div className="text-custom-gray-500 font-['Poppins'] font-medium text-[16px] pl-1 pt-3">
            Description
          </div>
          <div className="p-1 text-light-black font-poppins text-base font-semibold">
            {/* {taskData?.description} */}
            <HtmlContent htmlString={taskData?.description || "-"} />
          </div>
        </p>

        <p>
          <div className="text-custom-gray-500 font-['Poppins'] font-medium text-[16px] p-1 py-2 grid grid-cols-4 pt-3">
            Documents:
          </div>
          <div className="p-1">
            {taskData?.task_document?.map((doc, i) => (
              <DocumentCard
                key={i}
                fileUrl={doc}
                onView={() => handleView(doc)}
                onDownload={() =>
                  downloadFile(
                    doc,
                    doc?.split("/")?.pop() || `Document ${i + 1}`
                  )
                }
              />
            ))}
          </div>
        </p>
      </div>
      {selectedFileUrl && (
        <ModalViewer
          isOpen={!!selectedFileUrl}
          onRequestClose={closeModal}
          fileUrl={selectedFileUrl}
        />
      )}
    </div>
  );
};

export default ViewTask;
