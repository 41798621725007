import { Dispatch } from "redux";
import { RoleManagementActions } from "../../reducers/roleManagement/roleManagementSlice";
import { Role_Resource_List_Api, get_Role_Resourse_Api } from "../../../components/common/services/roleManagement";

export const fetchRoleManagementList = (
  customer_id: number,
  numOfItems: number,
  page: number
) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const response = await Role_Resource_List_Api(customer_id,numOfItems,page);
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Could not fetch service data!");
      }
      return data;
    };

    try {
      const resData = await fetchData();
      const RoleManagementListData = resData.result;
      dispatch(
        RoleManagementActions.setRoleManagementData(
          RoleManagementListData || []
        )
      );
    } catch (error) {
      if (error instanceof Error) {

      } else {

      }
    }
  };
};

export const getCustomerResourseList: any = async () => {
    const response = await get_Role_Resourse_Api();
    if (response.status === 200) {
      const userData = response?.data || null;
      return userData.result; 
    }
    return response;
  };