import { Skeleton } from "../../@/components/ui/skeleton";

export function SkeletonCard() {
  return (
    <div className="mb-3 mt-1 flex flex-col space-y-3">
      <Skeleton className="h-6 w-[30%]" />
      <Skeleton className="h-4 w-4/5" />
      <Skeleton className="h-4 w-3/5" />
      <Skeleton className="h-4 w-2/5" />
    </div>
  );
}

export function SkeletonCards() {
  return (
    <div className="mt-5 flex flex-wrap gap-4">
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="flex-1 min-w-[200px] h-[100px] p-4 border border-custom-border-gray rounded-lg bg-white shadow-sm"
        >
          <Skeleton className="h-[20px]" />

          <Skeleton className="h-[32px] mt-2" />
        </div>
      ))}
    </div>
  );
}
