import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TabState {
  value: string;
}

const initialState: TabState = {
  value: "1",
};

const mainTabSlice = createSlice({
  name: "mainTabValue",
  initialState,
  reducers: {
    setMainTabValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    resetMainTabValue: (state) => {
      state.value = "1";
    },
  },
});

export const { setMainTabValue, resetMainTabValue } = mainTabSlice.actions;
export default mainTabSlice;
