import React, { useCallback, useEffect, useState, useRef } from "react";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import del from "../../assets/Crud_Icons/delete.svg";
import { Fetch_Expense_Type_List } from "../common/services/ExpenseType";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import TablePaginationDemo from "../common/Pagenation";
import Search from "../SearchBar/Search";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import { RootState } from "../../redux/store";

interface IExpense_Type_List {
  id: string;
  expense_category_name: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Expense Type
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center mt-6">
          <Button onClick={onDelete} variant={"gradient"}>
            Yes, Sure
          </Button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded py-2 w-1/3"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const Clients = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IExpense_Type_List | null>(
    null
  );
  const [tableData, setTableData] = useState<IExpense_Type_List[]>([]);

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const handleDeleteClick = (item: IExpense_Type_List) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/expenses-category/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const fetchLeaveType = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Expense_Type_List(
        customer_id,
        searchTerm ?? "",
        numOfItems,
        page
      );

      if (response.status === 200) {
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  }, [customer_id, numOfItems, page, searchTerm]);

  useEffect(() => {
    fetchLeaveType();
  }, [fetchLeaveType]);

  const columns: ColumnDef<IExpense_Type_List>[] = [
    {
      accessorKey: "expense_category_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 ml-4"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Expense Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const name: string = row?.original?.expense_category_name;
        return <div className="ml-4">{name}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-7">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2 mr-4">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-client/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() => handleDeleteClick(row?.original)}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-6 flex justify-between">
        <PersistedSearch label="Search with expense type" />
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white">
            <DynamicTable<IExpense_Type_List>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}

        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </>
  );
};

export default Clients;
