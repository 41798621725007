import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import { Dayjs } from "dayjs";
import axios from "axios";
import axiosInstance from "../../../app/api/axiosInstance";

const year_session_query = `session_start=${localStorage.getItem(
  "start_year"
)}&session_end=${localStorage.getItem("end_year")}`;

export const createWFH = async (
  //customerId: number,
  no_of_days: number | null,
  reason: string,
  from_date: string | null | Dayjs,
  to_date: string | null | Dayjs,
  user_id: string | null,
  wfh_session: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    //customerId: customerId,
    no_of_days: no_of_days,
    reason: reason,
    from_date: from_date,
    to_date: to_date,
    user_id: user_id,
    //wfh_session:wfh_session
    ...(wfh_session && { wfh_session: wfh_session }), // Only include wfh_session if it has a value
  };

  try {
    const response = await axiosInstance.post(`${base_api}/api/v1/wfh?${year_session_query}`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditWFH = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axiosInstance.get(`${base_api}/api/v1/wfh/${id}?${year_session_query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateWFH = async (
  id: string,
  no_of_days: string | null,
  reason: string,
  from_date: string | null | Dayjs,
  to_date: string | null | Dayjs,
  user_id: string | null,
  wfh_session: string | null
  // date_of_birth:string|null|Dayjs,
  // date_of_joining:string|null|Dayjs,
  // date_of_leaving:string|null|Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    no_of_days: no_of_days,
    reason: reason,
    from_date: from_date,
    to_date: to_date,
    user_id: user_id,
    wfh_session: wfh_session,
  };

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/wfh/${id}?${year_session_query}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/wfh/approve-reject/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const multiWFHApproveRejectApi = async ({
  status,
  selectedIds,
}: {
  status: string;
  selectedIds: number[];
}) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    wfh_id: selectedIds,
    status,
  };

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v2/wfh/approve-reject`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
