import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ChartDataItem {
  label: string;
  data: number[];
  color: string;
}

interface BarChartProps {
  chartData: ChartDataItem[];
  labels: string[];
  title?: string;
  options?: any;
  barThickness?: number;
}

const BarChart: React.FC<BarChartProps> = ({
  chartData,
  labels,
  title,
  options,
  barThickness,
}) => {
  const data = {
    labels,
    datasets: chartData.map((item) => ({
      label: item.label,
      data: item.data,
      backgroundColor: item.color,
      barThickness: barThickness ?? 30,
    })),
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      title: {
        display: !!title,
        text: title,
        font: { size: 16 },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => tooltipItems[0].label,
          label: (tooltipItem: any) => {
            let datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
            let value = tooltipItem.raw;
            return `${datasetLabel}: ${value}`;
          },
          footer: (tooltipItems: any) => {
            const total = tooltipItems.reduce(
              (sum: number, item: any) => sum + item.raw,
              0
            );
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: { display: false },
      },
      y: {
        stacked: false,
        ticks: { beginAtZero: true },
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <Bar data={data} options={options || defaultOptions} />
    </div>
  );
};

export default BarChart;
