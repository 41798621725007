import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SessionState {
  session_start: any;
  session_end: any;
}

const initialState: SessionState = {
  session_start: localStorage.getItem("start_session") || "",
  session_end: localStorage.getItem("end_session") || "",
};

const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionStartR: (state, action: PayloadAction<any>) => {
      state.session_start = action.payload;
      localStorage.setItem("start_session", action.payload);
    },
    setSessionEndR: (state, action: PayloadAction<any>) => {
      state.session_end = action.payload;
      localStorage.setItem("end_session", action.payload);
    },
  },
});

export const { setSessionStartR, setSessionEndR } = SessionSlice.actions;
export default SessionSlice;