import React, { useCallback, useEffect, useState, useRef } from "react";
import download from "../../image/Vector (1).png";
import eye from "../../assets/Crud_Icons/view.svg";
import filter from "../../assets/filter.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Fetch_Employee_Expense_List } from "../common/services/EmployeeExpense";
import { useSelector } from "react-redux";
import del from "../../assets/Crud_Icons/delete.svg";
import {
  api_key_work as api_key,
  api_key_work,
  base_api,
} from "../../utils/helperData";
import { debounce } from "lodash";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { useDispatch } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import Search from "../SearchBar/Search";
import { Chip } from "@material-ui/core";
import Filter from "../Filter/Filter";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";

import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";
import { RootState } from "../../redux/store";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import axiosInstance from "../../app/api/axiosInstance";

interface Employee_Expense_List {
  id: string;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  ExpenseCategory: {
    expense_category_name: string;
  };
  expense_date: string;
  expense_amount: string;
  expense_currency: string;
  status: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
  itemName?: string;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Employee Expense
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this expense?
        </h4>
        <div className="flex space-x-4 justify-center">
          <Button onClick={onDelete} variant={"gradient"} className=" mr-2">
            Yes Sure
          </Button>
          <button
            onClick={onCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

const Project = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] =
    useState<Employee_Expense_List | null>(null);
  const [tableData, setTableData] = useState<Employee_Expense_List[]>([]);

  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);

  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const dispatch = useDispatch();

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const navigate = useNavigate();
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const handleDeleteClick = (item: Employee_Expense_List) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const downloadExcelFile = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      const startSession = localStorage.getItem("start_session");
      const endSession = localStorage.getItem("end_session");

      const session_query =
        startSession && endSession
          ? `&session_start=${startSession}&session_end=${endSession}`
          : "";
      let query = `/api/v1/expense-dashboard-report?customer_id=${customer_id}${session_query}`;
      if (dates && !(dates[0] === null || dates[1] === null)) {
        const start = moment(dates[0]).format("YYYY-MM-DD");
        const end = moment(dates[1]).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      if (busId) {
        query += `&group_id=${busId}`;
      }
      if (depId) {
        query += `&department_id=${depId}`;
      }
      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",

        headers: {
          "x-api-key": api_key_work,
          Authorization: "Bearer " + access_token,
        },
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Expense_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/expenses/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const fetchLeaveType = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_Expense_List(
        customer_id,
        searchTerm ?? "",
        numOfItems,
        page,
        Number(busId),
        Number(depId),
        dates ? dates[0] : null,
        dates ? dates[1] : null
      );

      if (response.status === 200) {
        setTableData(
          response.data?.result?.rows ? response.data?.result?.rows : []
        );
        setCount(response.data?.result?.count);

        const fetchedData: EmployeeData[] = response.data?.result?.rows;
        setTableData1(fetchedData);

        const columnMapping: { [key: string]: string } = {
          id: "ID",
          name: "Name",
          designation: "Designation",
          department: "Department",
          employee_code: "Employee Code",
        };

        const cols: Column[] = Object?.keys(fetchedData[0])?.map((key) => ({
          id: key,
          displayName:
            columnMapping[key] || key.charAt(0)?.toUpperCase() + key?.slice(1),
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  }, [busId, customer_id, dates, depId, numOfItems, page, searchTerm]);

  useEffect(() => {
    fetchLeaveType();
  }, [fetchLeaveType]);

  const columns2: ColumnDef<Employee_Expense_List>[] = [
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 ">
            <img
              className="w-8 h-8 rounded-full"
              src={item.User.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item.User.firstName} {item.User.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item.User.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "ExpenseCategory",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Expense Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.ExpenseCategory.expense_category_name ?? "-",
      cell: ({ row }) => {
        const name: string =
          row?.original?.ExpenseCategory?.expense_category_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "expense_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.expense_date
          ? new Date(row.expense_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.expense_date
          ? new Date(row.original.expense_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "expense_amount",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.expense_amount ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.expense_amount;
        return (
          <div className="">
            {name ? `${row?.original?.expense_currency} ${name}` : "-"}
          </div>
        );
      },
    },

    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status;

        return status === "Rejected" ? (
          <div className="px-[10px] rounded-full w-fit py-[2px] flex justify-center items-center bg-[#EDCFCF] text-[#EB3F3F]">
            Rejected
          </div>
        ) : status === "Approved" ? (
          <div className="px-[10px] rounded-full py-[2px] w-fit flex justify-center items-center bg-[#E0F1E7] text-[#0F9446]">
            Approved
          </div>
        ) : (
          <div className="px-[10px] rounded-full py-[2px] w-fit flex justify-center items-center bg-[#FEEBDD] text-[#FF9950]">
            Pending
          </div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-6">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/view-expense", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-expense", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row?.original);
              }}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setIsOpen(false);
  };
  const handleDelete = () => {
    setStartDate(null);
    setEndDate(null);

    setBusId("");
    setDepId("");
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
  };

  return (
    <>
      <div className="mt-6 flex w-full justify-between space-x-2">
        <div className="h-[40px] w-full">
          <div className="h-full w-full flex justify-between items-center">
            <PersistedSearch label="Search with Name and Id" />

            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={downloadExcelFile} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4 shadow-sm">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white ">
            <DynamicTable<Employee_Expense_List>
              data={tableData}
              onRowClick={(row) => {
                navigate("/view-expense", {
                  state: { id: row?.id },
                });
              }}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Expense Filter"
        onApply={handleApply}
        isDate
        isOther
      />
    </>
  );
};

export default Project;
