import React from "react";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import TablePagination from "@mui/material/TablePagination";
import { useSelector } from "react-redux";

import "./Pagenation.css";

interface PagenationProps {
  count: number;
  handleItemsChange: (value: number) => void;
  handlePageChange: (value: number) => void;
  currentPage: number;
  numOfItems: number;
  noPadding?: boolean;
}

const TablePaginationDemo: React.FC<PagenationProps> = ({
  count,
  handleItemsChange,
  handlePageChange,
  currentPage,
  numOfItems,
  noPadding,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handlePageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    handleItemsChange(newRowsPerPage);
    handlePageChange(1);
  };

  return (
    <StyledEngineProvider injectFirst>
      <TablePagination
        component="div"
        count={count}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={numOfItems}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
        rowsPerPageOptions={[10, 20, 50, 100, 200]}
        labelRowsPerPage="Items Per Page"
        sx={{
          ".MuiTablePagination-toolbar": {
            paddingLeft: noPadding ? "0px" : undefined,
            flexWrap: noPadding ? "wrap" : undefined,
          },
          ...(noPadding && {
            ".MuiTablePagination-selectLabel": {
              marginRight: "4px",
              marginLeft: "4px",
            },
            ".MuiTablePagination-select": {
              marginRight: "4px",
              marginLeft: "4px",
            },
            ".MuiInputBase-root": {
              marginRight: "4px",
              marginLeft: "4px",
            },
            "& .MuiTablePagination-actions": {
              marginLeft: "0px !important",
            },
          }),
        }}
      />
    </StyledEngineProvider>
  );
};

export default TablePaginationDemo;
