import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { debounce } from "lodash";

import right from "../../../image/chevron-right.png";

import Search from "../../SearchBar/Search";
import TablePaginationDemo from "../../common/Pagenation";
import { Fetch_Task_List_All } from "../../common/services/Project";

interface TaskData {
  id: string;
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  start_date: string;
  end_date: string;
}

function ViewTaskList() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const [taskData, setTaskData] = useState<TaskData[]>([]);
  const [page, setPage] = useState(1);
  const [numOfItems, setNumOfItems] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(0);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  function handlePageChange(value: number) {
    setPage(value);
  }

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  const fetchTask = async () => {
    try {
      const response = await Fetch_Task_List_All(id, searchTerm, numOfItems, page);
      if (response.status === 200) {
        setTaskData(response?.data?.result?.rows);
        setCount(response?.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchTask();
  }, [page, numOfItems, searchTerm]);

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-row gap-[13px] items-center">
        <button
          onClick={() =>
            navigate("/view-project-details", {
              state: { id: id },
            })
          }
        >
          <img
            src={right}
            alt="right arrow sign"
            className="h-6 w-6 shrink-0 bg-black rounded-full"
          />
        </button>
        <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
          Tasks
        </span>
      </div>
      <div className="mt-4">
        <Search label="Search with Task or Employee Name" onChange={updateSearchTerm}/>
      </div>
      <table className="w-full min-w-max text-left table-auto mt-6 shadow-sm">
        <thead className="theadBg border border-[#E0E3E7]">
          <tr className="h-11">
            <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
              Task Name
            </th>
            <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
              Estimate Hours
            </th>
            <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
              Actual Hours
            </th>
            <th className="pl-6 pr-2.5 py-2.5 text-[14px] font-['Poppins'] text-[#1D1A22]">
              Variance
            </th>
            <th className="pl-6 pr-0.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
              Start Date
            </th>
            <th className="pl-6 pr-0.5 py-2.5 font-['Poppins'] text-[14px] text-[#1D1A22]">
              End Date
            </th>
          </tr>
        </thead>
        <tbody>
          {taskData?.map((items) => (
            <tr
              className="h-10 border-[0.6px] border-solid font-['Poppins'] border-[#E0E3E7] hover:bg-[#f9f9f9] cursor-pointer"
              onClick={() =>
                navigate("/view-project-task-details", {
                  state: { id: items.id },
                })
              }
            >
              <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 pr-2.5 py-1">
                {items.task_name}
              </td>
              <td className="text-[#3F4254] font-['Poppins'] font-[500] text-[12px] py-1 pl-6">
                {items.estimated_hours}
              </td>
              <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 pr-2.5 py-1">
                {items.actual_hours}
              </td>
              <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 pr-2.5 py-1">
                {Number(items?.actual_hours) - Number(items?.estimated_hours)}
              </td>
              <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 py-1">
                {items.start_date}
              </td>
              <td className="text-[#3F4254] font-[500] font-['Poppins'] text-[12px] pl-6 py-1">
                {items.end_date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
    </div>
  );
}

export default ViewTaskList;
