import React, { useCallback, useEffect, useState, useRef } from "react";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../image/Vector (1).png";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
// import { FETCH_ATTENDENCE_USER } from "../common/api";
import {
  Fetch_Attendence_List,
  Fetch_Attendence_Stats,
} from "../common/services/Attendence";
import { AttendanceActionData } from "../../redux/reducers/AttendenceSlice/AttendenceSlice";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import maskPlus from "../../image/Mask group.svg";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import clock from "../../assets/clock.svg";
import location from "../../assets/location.svg";
import filter from "../../assets/filter.svg";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import * as XLSX from "xlsx";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import DatePicker from "react-datepicker";
import date2 from "../../assets/date2.svg";
import { createGlobalStyle } from "styled-components";
import toast from "react-hot-toast";
import { Close } from "@mui/icons-material";
import TableSkeleton from "../common/TableSkeleton";
import { SkeletonCards } from "../common/skeletonCard";
import axios from "axios";
import moment from "moment";
import { api_key_work, base_api } from "../../utils/helperData";
import axiosInstance from "../../app/api/axiosInstance";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import { RootState } from "../../redux/store";
import { MenuItem, TextField } from "@mui/material";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

dayjs.extend(duration);

interface IAttendenceList {
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  date: Date;
  start_time: string;
  check_in_address: string;
  end_time: string;
  check_out_address: string;
  id: number;
  mode_of_work: string;
  check_in_status: string | null;
  duration: string;
  user_id: number;
  selected_address: string;
  selected_checkout_address: string;
}

interface AttendanceStats {
  WFH: number;
  WFO: number;
  DELAYED: number;
  NOT_CHECKED_IN: number;
}

interface Items {
  start_time: string;
  end_time: string;
  // [key: string]: any;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

export interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any;
}

const LocationAndAddress: React.FC<{
  dateTime?: string;
  address: string;
}> = ({ dateTime, address }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className="flex flex-col justify-center gap-1">
          {dateTime && (
            <div className="flex gap-1 justify-start items-center">
              <img
                src={clock}
                alt="time"
                className="h-[12px] w-[12px] text-[#49454F]"
              />
              <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
                {dateTime}
              </span>
            </div>
          )}
          {address ? (
            <div className="flex gap-1 items-center">
              <img
                src={location}
                alt=""
                className="h-[12px] w-[12px] text-[#938F99]"
              />
              <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500] overflow-hidden whitespace-nowrap text-ellipsis w-40">
                {address}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          <div className="flex flex-col justify-center gap-1">
            {dateTime && (
              <div className="flex gap-1 justify-start items-center">
                <img
                  src={clock}
                  alt="time"
                  className="h-[12px] w-[12px] text-[#49454F]"
                />
                <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
                  {dateTime}
                </span>
              </div>
            )}
            <div className="flex gap-1 items-center">
              <img
                src={location}
                alt=""
                className="h-[12px] w-[12px] text-[#938F99]"
              />
              <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500]">
                {address}
              </span>
            </div>
          </div>
        </Typography>
      </Popover>
    </div>
  );
};

const AttendenceDetails = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  // const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  // const [startSession, setStartSession] = useState("");
  // const [endSession, setEndSession] = useState("");

  // const startDate = useSelector((state: any) => state.attendence.startDate);

  // const endDate = useSelector((state: any) => state.attendence.endDate);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  // const [selectedDates, setSelectedDates] = useState<
  //   [Date | null, Date | null]
  // >([null, null]);
  // const [tempSelectedDates, setTempSelectedDates] = useState<
  //   [Date | null, Date | null]
  // >([null, null]);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  // const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const [tableData, setTableData] = useState<IAttendenceList[]>([]);
  // const [bussness, setBusiness] = useState<Business_Unit[]>();
  // const [dateRange, setDateRange] = useState(false);
  // const [departmentSelect, setDepartmentSelect] = useState(false);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  // const [businessSelect, setBusinessSelect] = useState(false);
  // const [department, setDepartment] = useState<DepartmentUnit[]>();
  // const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  // const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  // const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  // const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  const [stats, setStats] = useState<AttendanceStats>();
  // const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);

  // const selectedCalendarRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [selectedAttendence, setSelectedAttendence] = useState<
    string | undefined
  >(filter_data?.status);

  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );

  const announcementOptions = [
    { label: "All Status", value: "" },
    { label: "From Office", value: "WFO" },
    { label: "From Home", value: "WFH" },
    { label: "Delay", value: "Delayed" },
    // { label: "Not Checked In", value: "notCheckIn" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Attendence_List(
        customer_id,
        numOfItems,
        page,
        // Number(selectedBusinessUnit?.id),
        Number(busId),
        // Number(selectedDepartmentUnit?.id),
        Number(depId),
        searchTerm ?? "",
        dates ? dates[0] : null,
        dates ? dates[1] : null,
        selectedAttendence
      );
      if (response.status === 200) {
        const fetchedData: EmployeeData[] = response.data.result.rows;
        const columnMapping: { [key: string]: string } = {
          "User.firstName": "First Name",
          "User.lastName": "Last Name",
          date: "Date",
          status: "Status",
          start_time: "CheckIn Time",
          check_in_address: "Check In Address",
          mode_of_work: "CheckIn Mode",
          end_time: "Check Out Time",
          check_out_address: "Check Out Address",
          mode_of_work_checkout: "CheckOut Mode",
          checkout_by: "Check Out By",
        };

        const filteredData = fetchedData?.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "start_time" && row[keys[0]]) {
                  filteredRow[keys[0]] = dayjs(row[keys[0]]).format(
                    "MMM DD, YYYY HH:mm"
                  );
                } else if (keys[0] === "end_time" && row[keys[0]]) {
                  filteredRow[keys[0]] = dayjs(row[keys[0]]).format(
                    "MMM DD, YYYY HH:mm"
                  );
                } else {
                  filteredRow[keys[0]] = row[keys[0]];
                }
                // filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          const startTime = row.start_time ? dayjs(row.start_time) : null;
          const endTime = row.end_time ? dayjs(row.end_time) : null;

          if (startTime && endTime) {
            const durationMinutes = endTime.diff(startTime, "minute");
            const hours = Math.floor(durationMinutes / 60);
            const minutes = Math.floor((durationMinutes % 60) / 0.6);

            // Format duration as HH:mm
            const formattedDuration = `${String(hours).padStart(
              2,
              ""
            )}.${String(minutes)}`;
            filteredRow["duration"] = formattedDuration;
          } else {
            filteredRow["duration"] = "N/A"; // Handle cases where either start or end time is missing
          }

          return filteredRow;
        });

        setTableData1(filteredData);

        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));

        cols.push({ id: "duration", displayName: "Duration" });

        setColumns(cols);

        setDataFetched(true);

        // const dataWithDurations: IAttendenceList[] = response.data.result?.rows?.map(
        //   (item: Partial<IAttendenceList>) => {
        //     const startDateTime = item.start_time ? dayjs(item.start_time) : null;
        //     const endDateTime = item.end_time ? dayjs(item.end_time) : null;

        //     let duration = "00:00";
        //     if (startDateTime && endDateTime) {
        //       const diff = dayjs.duration(endDateTime.diff(startDateTime));
        //       const totalMinutes = Math.floor(diff.asMinutes());
        //       const hours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
        //       const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        //       duration = `${hours}:${minutes}`;
        //     }

        //     return {
        //       User: {
        //         firstName: item.User?.firstName || "",
        //         lastName: item.User?.lastName || "",
        //         employee_id: item.User?.employee_id || "",
        //         profile_image: item.User?.profile_image || "",
        //       },
        //       date: item.date || new Date(),
        //       start_time: item.start_time || "",
        //       check_in_address: item.check_in_address || "",
        //       end_time: item.end_time || "",
        //       check_out_address: item.check_out_address || "",
        //       id: item.id || 0,
        //       mode_of_work: item.mode_of_work || "",
        //       check_in_status: item.check_in_status || null,
        //       duration,
        //     };
        //   }
        // );

        // setTableData(dataWithDurations);

        const dataWithDurations = response.data.result?.rows?.map(
          (item: Items) => {
            const startDateTime = dayjs(item.start_time);
            const endDateTime = dayjs(item.end_time);
            const duration = dayjs.duration(endDateTime.diff(startDateTime));

            // Calculate the total duration in hours and minutes
            const totalMinutes = Math.floor(duration.asMinutes());
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            // Format hours and minutes to ensure two digits
            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");

            const durationString = `${formattedHours}:${formattedMinutes}`;

            return {
              ...item,
              duration: durationString,
            };
          }
        );
        // console.log({ dataWithDurations });
        setTableData(dataWithDurations?.length ? dataWithDurations : []);

        setCount(response.data.result.count);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  // const isFirstRender = useRef(true);

  // useEffect(() => {
  //   fetchData();
  // }, [
  //   page,
  //   numOfItems,
  //   searchTerm,
  //   startDate,
  //   endDate,
  //   selectedBusinessUnit?.id,
  //   selectedDepartmentUnit?.id,
  //   selectedAttendence,
  //   busId,
  //   depId,
  //   dates,
  // ]);
  useEffect(() => {
    fetchData();
  }, [searchTerm, page, numOfItems, busId, depId, dates, selectedAttendence]);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "attendence.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
    }
  };

  // const handleBusinessUnitSelect = (
  //   option: Business_Unit | DepartmentUnit | undefined
  // ) => {
  //   setTempSelectedBusinessUnit(option);
  // };

  // const handleDepartmentUnitSelect = (
  //   option: Business_Unit | DepartmentUnit | undefined
  // ) => {
  //   setTempSelectedDepartmentUnit(option);
  // };

  // const handleStorageChange = (event: StorageEvent) => {
  //   if (event.key === "start_session" || event.key === "end_session") {
  //     const start = localStorage.getItem("start_session") || "";
  //     const end = localStorage.getItem("end_session") || "";

  //     setStartSession(start);
  //     setEndSession(end);
  //   }
  // };

  // useEffect(() => {
  //   // Initialize state with values from localStorage
  //   const start = localStorage.getItem("start_session") || "";
  //   const end = localStorage.getItem("end_session") || "";
  //   setStartSession(start);
  //   setEndSession(end);

  //   // Add event listener to handle storage changes
  //   window.addEventListener("storage", handleStorageChange);

  //   // Cleanup listener on component unmount
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  // const handleDateChange = (dates: [Date | null, Date | null]) => {
  //   const [startingDate, endingDate] = dates;
  //   if (startingDate && endingDate) setIsCalendarOpen(!isCalendarOpen);
  //   setSelectedDates(dates);
  // };

  // const updateSearchTerm = useCallback(
  //   debounce((event: any) => {
  //     setSearchTerm(event.target.value);
  //     setPage(1);
  //   }, 1000),
  //   [setSearchTerm]
  // );

  // useEffect(() => {
  //   const fetchHistory = async () => {
  //     try {
  //       const historyData = await dispatch<any>(
  //         EmployeeUnit(customer_id, navigate)
  //       );

  //       if (historyData) {
  //         const recentThree = historyData?.result;
  //         setBusiness(recentThree);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching  history:", error);
  //     }
  //   };

  //   fetchHistory();
  // }, [customer_id, dispatch, navigate]);

  const downloadExcelFile = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      let query = `/api/v1/attendance-report?customer_id=${customer_id}`;
      // if (startDate && endDate) {
      //   const start = moment(startDate).format("YYYY-MM-DD");
      //   const end = moment(endDate).format("YYYY-MM-DD");
      //   query += `&start_date=${start}&end_date=${end}`;
      // }
      if (dates && !(dates[0] === null && dates[1] === null)) {
        const start = moment(dates[0]).format("YYYY-MM-DD");
        const end = moment(dates[1]).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",

        headers: {
          "x-api-key": api_key_work,
          Authorization: "Bearer " + access_token,
        },
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Attendance_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  // useEffect(() => {
  //   const fetchDepartment = async () => {
  //     try {
  //       const historyData = await dispatch<any>(
  //         Department_Unit(customer_id, navigate)
  //       );

  //       if (historyData) {
  //         const recentThree = historyData?.result.rows;
  //         setDepartment(recentThree);
  //       }
  //     } catch (error) {}
  //   };

  //   fetchDepartment();
  // }, [customer_id, dispatch, navigate]);

  // const handleCalanderClick = () => {
  //   setIsCalendarOpen(!isCalendarOpen);
  // };

  // const handleSelectedCalanderClick = () => {
  //   setIsSelectedOpen(!isSelectedOpen);
  // };

  // const handleFilterApply = () => {
  //   // const [startDate, endDate] = selectedDates;
  //   setStartDate(selectedDates[0]);
  //   setEndDate(selectedDates[1]);

  //   // setTempSelectedDates(selectedDates);

  //   setSelectedBusinessUnit(tempSelectedBusinessUnit);
  //   setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
  //   // dispatch(AttendanceActionData.setStartDate(startDate));
  //   // dispatch(AttendanceActionData.setEndDate(endDate));
  //   if (selectedDates[0] !== null || selectedDates[1] !== null) {
  //     setDateRange(true);
  //   } else {
  //     setDateRange(false);
  //   }
  //   // Check if department and business are selected
  //   if (tempSelectedDepartmentUnit) {
  //     setDepartmentSelect(true);
  //   }
  //   if (tempSelectedBusinessUnit) {
  //     setBusinessSelect(true);
  //   }

  //   // Close the filter popup/modal
  //   setIsOpen(false);

  //   // Clear all if all fields are empty or undefined
  //   if (
  //     (selectedDates[0] === null || selectedDates[1] === null) &&
  //     !tempSelectedDepartmentUnit &&
  //     !tempSelectedBusinessUnit
  //   ) {
  //     setSelectedDates([null, null]);
  //     setStartDate(null);
  //     setEndDate(null);
  //     setDepartmentSelect(false);
  //     setBusinessSelect(false);
  //     setTempSelectedBusinessUnit(undefined);
  //     setTempSelectedDepartmentUnit(undefined);
  //     setSelectedDepartmentUnit(undefined);
  //     setSelectedBusinessUnit(undefined);
  //     fetchData(); // Fetch data again after reset
  //   }
  // };

  // const handleDeleteDate = () => {
  //   setDateRange(false);
  //   dispatch(AttendanceActionData.setStartDate(null));
  //   dispatch(AttendanceActionData.setEndDate(null));
  //   setTempSelectedDates([null, null]);
  //   fetchData();
  // };
  // const handleDeleteDepartment = () => {
  //   setDepartmentSelect(false);
  //   setTempSelectedDepartmentUnit(undefined);
  //   setSelectedDepartmentUnit(undefined);
  //   fetchData();
  // };
  // const handleDeleteBusiness = () => {
  //   setBusinessSelect(false);
  //   setTempSelectedBusinessUnit(undefined);
  //   setSelectedBusinessUnit(undefined);
  //   fetchData();
  // };

  // const handleSelectedDateChange = (newValue: Dayjs | null) => {
  //   setSelectedDate(newValue);
  // };

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setIsOpen(false);
  };
  const handleDelete = () => {
    setBusId(null);
    setDepId(null);
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
      })
    );
  };

  // const handleDelete = () => {
  //   setDateRange(false);
  //   setSelectedDates([null, null]);
  //   setStartDate(null);
  //   setEndDate(null);
  //   setBusinessSelect(false);
  //   setTempSelectedBusinessUnit(undefined);
  //   setDepartmentSelect(false);
  //   setTempSelectedDepartmentUnit(undefined);
  //   setSelectedDepartmentUnit(undefined);
  //   setSelectedBusinessUnit(undefined);
  //   setBusId("");
  //   setDepId("");
  //   setDates([null, null]);
  //   dispatch(
  //     setFilterPersistData({
  //       department: "",
  //       group: "",
  //       date: [null, null],
  //     })
  //   );
  // };

  const columns2: ColumnDef<IAttendenceList>[] = [
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 pl-2 pr-2.5">
            <img
              className="w-8 h-8 rounded-full"
              src={item.User.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item.User.firstName} {item.User.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item.User.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date
          ? new Date(row.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date
          ? new Date(row.original.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px]">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "start_time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Check In Time & Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.start_time ? dayjs(row.start_time).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div className="">
            <LocationAndAddress
              dateTime={dayjs(row?.original?.start_time).format("h:mm A")}
              address={row?.original?.check_in_address}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "end_time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Check Out Time & Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_time ? dayjs(row.end_time).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div className="">
            <div className="">
              {row.original.end_time ? (
                <>
                  <LocationAndAddress
                    dateTime={dayjs(row?.original?.end_time).format("h:mm A")}
                    address={row?.original?.check_out_address}
                  />
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Duration
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.duration ?? "-",
      cell: ({ row }) => {
        if (row?.original?.end_time) {
          return <div>{row?.original?.duration} Hrs.</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      accessorKey: "check_in_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.check_in_status ?? "-",
      cell: ({ row }) => {
        return row?.original?.check_in_status ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full ${
              row.original.check_in_status === "OnTime"
                ? "bg-[#E0F1E7] text-[#0F9446]"
                : "bg-[#EDCFCF] text-[#EB3F3F]"
            }`}
          >
            {row.original.check_in_status}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      accessorKey: "mode_of_work",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Workspace
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.mode_of_work ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.mode_of_work ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "selected_address",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Selected Check in Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.selected_address ? row?.selected_address : "-",
      cell: ({ row }) => {
        return row?.original?.selected_address ? (
          <div>
            <LocationAndAddress address={row.original.selected_address} />
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      accessorKey: "selected_checkout_address",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Selected Check Out Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.selected_checkout_address ? row?.selected_checkout_address : "-",
      cell: ({ row }) => {
        return row?.original?.selected_checkout_address ? (
          <div>
            <LocationAndAddress
              address={row.original.selected_checkout_address}
            />
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    // {
    //   id: "actions",
    //   header: ({ column }) => (
    //     <div className="flex justify-end mr-2">
    //       <Button variant="ghost" className="p-0">
    //         Action
    //       </Button>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div className="flex items-center justify-end gap-2">
    //         <Button
    //           type="button"
    //           className="p-0 mr-4"
    //           onClick={(event) => {
    //             event.stopPropagation();
    //             navigate(
    //               `/attendance-management/edit-attendence/${row?.original?.id}`,
    //               {
    //                 state: { id: row?.original?.id },
    //               }
    //             );
    //           }}
    //         >
    //           <img
    //             src={edit}
    //             alt="Edit"
    //             className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
    //           />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  // console.log({ filter_data, busId, depId, dates });

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       selectedCalendarRef.current &&
  //       !selectedCalendarRef.current.contains(event.target as Node)
  //     ) {
  //       setIsSelectedOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleAttendenceAllStatusesChange = async (value: string) => {
    setSelectedAttendence(value);
    dispatch(setFilterPersistData({ status: value }));

    // const access_token = localStorage.getItem("access_token");

    // console.log("Access Token:", access_token);

    // // if (!access_token) {
    // //   console.error("Token missing! User might be logged out.");
    // //   return;
    // // }

    // // let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}&${session_query}`;

    // // if (value) {
    // //   query += `&filterStatus=${value}`;
    // // }

    // // const url = `${FETCH_ATTENDENCE_USER}${query}`;

    // try {
    //   setLoading(true);

    //   // Axios request
    //   const response = await axios.get(url, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${access_token}`,
    //     },
    //   });

    //   // Check if response is successful
    //   if (response.status === 200) {
    //     const data = response.data;
    //     setTableData(data.result.rows || []); // Set table data based on the response
    //   } else {
    //     console.error("Error fetching data:", response.status);
    //   }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   // Optionally, you can show a message to the user
    // } finally {
    //   setLoading(false);
    // }
  };

  // const handleApply = (filterData: FilterData) => {
  //   dispatch(
  //     setFilterPersistData({
  //       department: filterData?.department,
  //       group: filterData?.business,
  //       date: filterData?.dateRange,
  //     })
  //   );
  //   setBusId(filterData?.business ?? null);
  //   setDepId(filterData?.department ?? null);
  //   setDates(filterData?.dateRange);
  //   setIsOpen(false);
  // };

  return (
    <div className="pb-12 min-h-[90vh] bg-[#F9F9F9]">
      {/* {loading1 ? (
        <SkeletonCards />
      ) : (
        <div className="mt-5 flex flex-wrap gap-4">
          {[
            { label: "Check In From Office", value: stats?.WFO ?? "-" },
            { label: "Check In From Home", value: stats?.WFH ?? "-" },
            { label: "Check In After 10:30", value: stats?.DELAYED ?? "-" },
            { label: "Not Checked In", value: stats?.NOT_CHECKED_IN ?? "-" },
          ].map((stat, index) => (
            <div
              key={index}
              className="flex-1 min-w-[200px] h-[100px] p-4 border border-custom-border-gray rounded-lg bg-white shadow-sm"
            >
              <div className="font-poppins text-[14px] font-medium">
                {stat?.label}
              </div>
              <div className="font-poppins mt-1 text-[24px] font-bold">
                {stat?.value}
              </div>
            </div>
          ))}
        </div>
      )} */}

      <div className="mt-6">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            {/* <Search
              onChange={updateSearchTerm}
              label="Search with employee name"
            /> */}
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                <div className="relative">
                  {/* <select
  className="border px-4 py-2 rounded-lg font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F] focus:outline-none"
  title="All Statuses"
                    value={selectedAttendence}
                    onChange={(e) =>
                      handleAttendenceAllStatusesChange(e.target.value)
                    }
                  >
                    {announcementOptions?.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option.label}
                      </option>
                    ))}
                  </select> */}

                  <TextField
                    id="outlined-required"
                    select
                    // label="Task Status"
                    placeholder="All Statuses"
                    name="status"
                    value={selectedAttendence}
                    sx={{
                      height: "40px",
                      backgroundColor: "white",
                      borderRadius: "0.5rem",
                      "& .MuiOutlinedInput-root": {
                        height: "100%",
                        minWidth: 80,
                        borderRadius: "0.5rem",
                        border: "none",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "29px",
                        color: "#49454F",
                        outline: "none",
                        "&:focus": {
                          outline: "none",
                        },
                        "& fieldset": {
                          border:
                            "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))", // Default border transparent
                        },
                        "&:hover fieldset": {
                          borderColor:
                            "rgb(205 205 205 / var(--tw-border-opacity, 1))", // Green border on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor:
                            "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important", // Green border on focus
                          borderWidth: "1px",
                        },
                      },
                    }}
                    onChange={(e) =>
                      handleAttendenceAllStatusesChange(e.target.value)
                    }
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    {announcementOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={downloadExcelFile} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full relative overflow-auto bg-white">
            <DynamicTable<IAttendenceList>
              onRowClick={(row) => {
                navigate(`/attendance-management/${row.user_id}/${row.date}`);
              }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      {isOpen && (
        <AttFilter
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          heading="Attendance Filter"
          onApply={handleApply}
          isDate
          isOther
        />
      )}
    </div>
  );
};

export default AttendenceDetails;
