import React from "react";
import {
  type ColumnDef,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../@/components/ui/table";
import { SkeletonCard } from "./skeletonCard";

const arrowStyles = {
  zIndex: 0,
  position: "relative" as const,
};

interface DynamicTableProperties<TData> {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  loading?: boolean;
  onRowClick?: (rowData: TData) => void;
  enableSorting?: boolean;
  enablePagination?: boolean;
  collapsible?: boolean;
  renderExpandedContent?: (rowData: TData) => React.ReactNode; // Function to render expanded row content
  tableClass?: string;
  getSubRows?: (row: TData) => TData[];
}

function DynamicTable<TData>({
  data,
  columns,
  loading = false,
  onRowClick,
  enableSorting = false,
  enablePagination = false,
  collapsible = false,
  renderExpandedContent,
  tableClass = "border-l border-r border-b min-w-max ",
  getSubRows = (row) => (row as any).children ?? [],
}: DynamicTableProperties<TData>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [expandedRows, setExpandedRows] = React.useState<Set<number>>(
    new Set()
  ); // Track expanded rows

  // Apply the style to any expansion arrows in the column definitions
  const columnsWithStyledArrows = React.useMemo(() => {
    if (!columns || !Array.isArray(columns)) {
      return [];
    }

    return columns.map((column) => {
      // If this is the column that contains the expansion arrow
      // Use a type-safe approach to check for expander property
      const isExpander =
        column.id === "expander" ||
        (column.meta && "isExpander" in column.meta && column.meta.isExpander);

      if (isExpander) {
        return {
          ...column,
          cell: (props: any) => {
            // Only call cell if it's a function
            const originalCell =
              typeof column.cell === "function"
                ? column.cell(props)
                : column.cell;

            return <div style={arrowStyles}>{originalCell}</div>;
          },
        };
      }
      return column;
    });
  }, [columns]);

  const table = useReactTable<TData>({
    data: data || [],
    columns: columnsWithStyledArrows || columns || [], // Fallback to original columns or empty array
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    ...(enableSorting && { getSortedRowModel: getSortedRowModel() }),
    getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  // Toggle expanded row state
  const handleRowToggle = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(rowIndex)) {
        newExpandedRows.delete(rowIndex); // Collapse
      } else {
        newExpandedRows.add(rowIndex); // Expand
      }
      return newExpandedRows;
    });
  };

  return (
    <Table className={tableClass}>
      <TableHeader className="text-[#4B5563] bg-white sticky top-0 z-10 font-[poppins] text-sm font-semibold">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id} className="px-4">
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody className="text-[#111827] font-[poppins] text-sm pt-2">
        {loading ? (
          // Render loading state
          <TableRow>
            <TableCell colSpan={columns?.length}>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </TableCell>
          </TableRow>
        ) : table.getRowModel()?.rows?.length > 0 ? (
          table.getRowModel().rows.map((row, rowIndex) => {
            return (
              <React.Fragment key={row.id}>
                {/* Table Row */}
                <TableRow
                  onClick={() => {
                    if (collapsible) handleRowToggle(rowIndex); // Only toggle if collapsible is true
                    onRowClick && onRowClick(row.original);
                  }}
                  className="cursor-pointer px-4"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="h-12" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>

                {/* Expanded Row Content (Collapsible Section) */}
                {collapsible &&
                expandedRows.has(rowIndex) &&
                renderExpandedContent ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={columns?.length}>
                        <div className="">
                          {renderExpandedContent(row.original)}
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          // Render no data state
          <TableRow>
            <TableCell colSpan={columns?.length} className="text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default DynamicTable;
