import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileUpload from "./FileUpload";
import axios from "axios";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import { InputAdornment, MenuItem } from "@mui/material";
import { Department_Unit } from "../../redux/actions/Employee/Employee";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import {
  Expense_All_Data,
  Expense_Type_Unit,
} from "../../redux/actions/ExpenseType/ExpenseTypeAction";
import { createEmployeeExpense } from "../common/services/EmployeeExpense";
import toast from "react-hot-toast";
import { setDate } from "date-fns";
import { Button } from "../common/Button";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface FormSelectValues {
  expense_type: string;
  currency: string;
  amount: string;
  department: string;
  reason: string;
}

const AddExpense = () => {
  //const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const expenseType = useSelector(
    (state: any) => state.expenseType.ExpenseTypeData
  );

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [applicationDate, setapplicationDate] = React.useState<Dayjs | null>(
    null
  );
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [isDate, setIsDate] = useState<boolean | null>(false);
  const [isDateDisplay, setIsDateDisplay] = useState<boolean | null>(false);
  const [errorDisplay, setErrorDisplay] = useState<boolean>(false);
  const [employeeError, setEmployeeError] = useState<string | null>(null);

  const handleOpen = () => {
    !isDate && setIsDateDisplay(true);
    // fileError && setErrorDisplay(true);
    if (selectedEmployeeId === null) {
      setEmployeeError("Employee is required");
      return;
    }

    const res = validate();
    if (res && isDate) postData();
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    navigate("/expense-management");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/expense-management");
  };

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    dispatch<any>(Expense_All_Data(customer_id, navigate));
  }, []);

  const [formSelectValues, setFormSelectValues] = useState<FormSelectValues>({
    expense_type: "",
    currency: "",
    amount: "",
    department: "",
    reason: "",
  });

  const [errors, setErrors] = useState<Partial<FormSelectValues>>({});
  const [fileError, setFileError] = useState<boolean>(false);

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormSelectValues({
      ...formSelectValues,
      [name]: value,
    });

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validate = (): boolean => {
    let tempErrors: Partial<FormSelectValues> = {};

    if (formSelectValues.expense_type === "")
      tempErrors.expense_type = "Expense Type is required";
    if (formSelectValues.amount === "")
      tempErrors.amount = "Amount is required";
    if (formSelectValues.department === "")
      tempErrors.department = "department is required";
    if (formSelectValues.currency === "")
      tempErrors.currency = "Currency is required";
    if (formSelectValues.reason === "")
      tempErrors.reason = "Reason is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    const fetchDepartment = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );
        console.log(historyData, "historyData department---->>>");

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  useEffect(() => {
    uploadedFiles.length === 0 ? setFileError(true) : setFileError(false);
  }, [uploadedFiles.length]);

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);

    // if (files.length !== 0) {
    //   setErrorDisplay(false);
    // }
  };

  const Currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    { value: "AD", label: "AD" },
    { value: "AED", label: "AED" },
  ];

  const postData = async () => {
    setLoading(true);
    try {
      const response = await createEmployeeExpense(
        // customer_id,
        //formSelectValues.empId,
        selectedEmployeeId,
        formSelectValues.expense_type,
        dayjs(applicationDate).format("YYYY-MM-DD"),
        formSelectValues.amount,
        formSelectValues.currency,
        uploadedFiles.map((files) => files.originalName),
        uploadedFiles.map((files) => files.url),
        // ["png"],
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        formSelectValues.reason
      );

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        navigate("/expense-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/expense-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Expense
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
          >
            <div 
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Cancel
            </div>
          </button>
          <Button form="form" variant={"gradient"} onClick={handleOpen}>
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Submit
            </div>
          </Button>
        </div>
      </div>

      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container mx-auto p-4">
          <h2 className="text-lg font-semibold mb-4">Expense Details</h2>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
                employeeError={employeeError}
              />
              <TextField
                required
                id="outlined-required"
                select
                name="department"
                label="Department"
                value={formSelectValues.department}
                onChange={handleFrormChange}
                error={!!errors.department}
                helperText={errors.department}
                SelectProps={{
                  MenuProps: {
                    disablePortal: true,
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        overflow: "auto",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  },
                }}
              >
                {department?.map((option) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="outlined-required"
                select
                label="Expense Type"
                defaultValue=" "
                name="expense_type"
                value={formSelectValues?.expense_type}
                onChange={handleFrormChange}
                error={!!errors.expense_type}
                helperText={errors.expense_type}
              >
                {expenseType?.map((option: any) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.expense_category_name}
                  </MenuItem>
                ))}
              </TextField>
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Application Date"
                    value={applicationDate}
                    onChange={(newValue) => {
                      setapplicationDate(newValue);
                      setIsDate(true);
                      setIsDateDisplay(false);
                    }}
                    className="w-full"
                    maxDate={dayjs()}
                    // helperText={}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isDateDisplay ? "#d32f2f" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isDateDisplay ? "#d32f2f" : "",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: isDateDisplay ? "#d32f2f" : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: isDateDisplay ? "#d32f2f" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        color: isDateDisplay ? "#d32f2f" : "",
                      },
                    }}
                  />
                </LocalizationProvider>
                {isDateDisplay && (
                  <span className="px-4 text-[#d32f2f] text-xs font-medium">
                    Application Date is required
                  </span>
                )}
              </div>
              <TextField
                required
                id="outlined-required"
                select
                label="Select Currency"
                defaultValue=" "
                name="currency"
                value={formSelectValues?.currency}
                onChange={handleFrormChange}
                error={!!errors.currency}
                helperText={errors.currency}
              >
                {Currency?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Amount"
                defaultValue=" "
                name="amount"
                value={formSelectValues.amount}
                onChange={handleFrormChange}
                error={!!errors.amount}
                helperText={errors.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formSelectValues?.currency}
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="grid grid-cols-1 pt-4">
              <TextField
                required
                id="outlined-required"
                label="Reason"
                defaultValue=" "
                name="reason"
                value={formSelectValues.reason}
                onChange={handleFrormChange}
                multiline
                rows={5}
                error={!!errors.reason}
                helperText={errors.reason}
              />
            </div>

            {/* {errorDisplay ? (
              <span className=" px-6 text-[#d32f2f] text-xs font-medium">
                File Upload is required
              </span>
            ) : (
              <></>
            )} */}
            <FileUpload onFilesChange={handleFilesChange} />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
