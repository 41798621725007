import React from "react";

export const getColors = {
  Approved: {
    bg: "bg-green-100",
    border: "border-green-200",
    text: "text-green-300",
  },
  Pending: {
    bg: "bg-yellow-100",
    border: "border-yellow-200",
    text: "text-yellow-300",
  },
  Rejected: {
    bg: "bg-red-100",
    border: "border-red-200",
    text: "text-red-300",
  },
};
export default function StatusChip({
  status,
}: {
  status: keyof typeof getColors;
}) {
  const color = getColors[status];
  return (
    <div
      className={`flex justify-center items-center rounded-sm px-[10px] py-1 h-[24px] w-[72px] border-2
        ${color?.border} ${color?.text} ${color?.bg} 
        font-[poppins] text-xs font-medium`}
    >
      {status}
    </div>
  );
}
