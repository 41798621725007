import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import toast from "react-hot-toast";
import axios from "axios";
import { updateResources } from "../common/services/Project";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "../common/Button";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  employeeId: string;
  fetchData?: () => Promise<void>;
  currency?: string | null;
}

interface EmployeeData {
  name: string;
  id: string;
  billableCost: string;
  actualCost: string;
}

interface Resources_Data {
  billing_cost: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  employeeId,
  fetchData,
  currency,
}) => {
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const id = location.state?.id;

  const [resourcesData, setResourcesData] = useState<Resources_Data>({
    billing_cost: "",
  });

  const [errors, setErrors] = useState<Partial<Resources_Data>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<Resources_Data> = {};

    if (Number(resourcesData.billing_cost) <= 0)
      tempErrors.billing_cost = "Billable cost is required";

    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
    }

    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setResourcesData({
      ...resourcesData,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  useEffect(() => {
    // Reset errors when modal is closed
    if (!isOpen) {
      setErrors({});
    }

    if (isOpen && employeeId) {
      // Fetch data from API

      const access_token = localStorage.getItem("access_token");
      fetch(
        `${process.env.REACT_APP_BASE_API}/api/v1/project-resources/${employeeId}`,
        {
          headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data.result, "data-resources-modal----->>>>>>>>>>>");

          setEmployeeData(data.result);

          setResourcesData({
            billing_cost: data.result?.billing_cost,
          });
        })
        .catch((error) =>
          console.error("Error fetching employee data:", error)
        );
    }
  }, [isOpen, employeeId]);

  const submitChanges = async () => {
    setLoading(true);
    try {
      const data = await updateResources(
        employeeId,
        Number(resourcesData.billing_cost)
      );

      if (data && data.result) {
        console.log("final data:", data);

        setResourcesData({ billing_cost: "" });

        onClose();

        if (fetchData) {
          fetchData();
        }
        setTimeout(() => {
          onClose();
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };
  const handleUpdate = (event: React.FormEvent) => {
    event.preventDefault();
    let res = validate();
    res && submitChanges();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
        <h2 className="text-xl font-bold py-4">Edit Resource Details</h2>
        <div>
          <div className=" mx-auto py-4">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols- gap-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Billable Cost/hr"
                  name="billing_cost"
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={resourcesData?.billing_cost ?? ""}
                  onChange={handleFrormChange}
                  error={!!errors.billing_cost}
                  helperText={errors.billing_cost}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency ?? ""}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </div>
            </Box>
          </div>
          <div className="flex justify-end gap-5">
            <button
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
              onClick={() => {
                onClose();
                setResourcesData({
                  billing_cost: "",
                });
              }}
            >
               <div 
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Cancel
            </div>
            </button>
            <Button variant={"gradient"} onClick={(e) => handleUpdate(e)}>
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
