import React from "react";
import { TeamMember } from "./TeamStructure";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, ChevronRightIcon } from "lucide-react";
import UserBadge from "../common/UserBadge";
import DynamicTable from "../common/DynamicTable";

interface TeamStructureTableProps {
  teamData: TeamMember[];
}

const TeamStructureTable: React.FC<TeamStructureTableProps> = ({
  teamData,
}) => {
  const columns2: ColumnDef<TeamMember>[] = [
    {
      accessorKey: "user.firstName",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0 pl-2">
          Employee Name & Id
        </Button>
      ),
      cell: ({ row }) => {
        const user = row.original?.user;
        const hasSubRows = row.getCanExpand();

        return (
          <div
            className="flex items-center"
            style={{ paddingLeft: `${row.depth * 1.5}rem` }}
          >
            {hasSubRows ? (
              <button
                onClick={row.getToggleExpandedHandler()}
                className="transition-transform duration-200"
              >
                <ChevronRightIcon
                  className={`size-6 ${row.getIsExpanded() ? "rotate-90" : ""}`}
                />
              </button>
            ) : (
              <div className="pl-6"></div>
            )}
            <UserBadge user={user} />
          </div>
        );
      },
    },
    {
      accessorKey: "user.Department.name",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Department
        </Button>
      ),
      cell: ({ row }) => {
        const name = row?.original?.user?.Department?.name ?? "-";
        return <div>{name}</div>;
      },
    },
    {
      accessorKey: "user.Designation.name",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Designation
        </Button>
      ),
      cell: ({ row }) => {
        const name = row?.original?.user?.Designation?.name ?? "-";
        return <div>{name}</div>;
      },
    },
  ];

  return (
    <div className="m-4 mt-6">
      <div className="table_main_content w-full max-h-[calc(100vh-14rem)] relative overflow-auto bg-white">
        <DynamicTable<TeamMember>
          data={teamData}
          columns={columns2}
          loading={false}
          enableSorting
          enablePagination
          getSubRows={(row) => row.children ?? []}
        />
      </div>
    </div>
  );
};

export default TeamStructureTable;
