import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  TextField,
  Box,
  Typography,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyStatefulEditor from "./Editor";
import FileUpload from "../ExpenseManagement/FileUpload";
import toast from "react-hot-toast";
import {
  createPolicy,
  Fetch_Policy_Category_List,
} from "../common/services/companyManagement";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
// import EmployeeSearch from "./PolicyApproverSearch";
import dayjs, { Dayjs } from "dayjs";
import EmployeeSearch from "./MultiSearch";
import { Button } from "../common/Button";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface RowData {
  id: string;
  name: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface FormValues {
  policy_name: string;
  policy_category: string;
  group: string;
  department: string;
  description: string;
  recurrence: string;
  version_number: string;
  experience: Number;
}

export const AddPolicy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [open, setOpen] = React.useState(false);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [editorContent, setEditorContent] = useState<string>("");
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [joiningErr, setJoiningErr] = React.useState<boolean>(false);
  const [approverErr, setApproversErr] = useState<boolean>(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [deptError, setDeptError] = useState<string>("");
  const [departmentName, setDepartmentName] = useState<string[]>([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<number[]>(
    []
  );
  const [formValues, setFormValues] = useState<FormValues>({
    policy_name: "",
    policy_category: "",
    department: "",
    group: "",
    description: "",
    recurrence: "",
    version_number: "",
    experience: 0,
  });
  const [formErrors, setFormErrors] = useState<Partial<FormValues>>({});
  const handleOpen = () => {
    const res1 = validateForm();
    if (
      res1 &&
      joining &&
      selectedDepartmentIds.length > 0 &&
      selectedEmployeeId2.length > 0
      // editorContent
    ) {
      postData();
    } else {
      if (!joining) setJoiningErr(true);
      if (selectedDepartmentIds.length === 0)
        setDeptError("Departments are required");
      if (selectedEmployeeId2.length === 0) {
        setApproversErr(true);
      }
      toast.error("Please fill all required fields");
    }
  };

  const validateForm = (): boolean => {
    let tempErrors: Partial<FormValues> = {};
    if (!formValues.policy_name) {
      tempErrors.policy_name = "Policy name is required";
    }
    if (!formValues.policy_category) {
      tempErrors.policy_category = "Policy category is required";
    }
    if (!formValues.group) {
      tempErrors.group = "Business Unit is required";
    }
    if (!formValues.recurrence) {
      tempErrors.recurrence = "Recurrence Period is required";
    }
    if (!formValues.version_number) {
      tempErrors.version_number = "Version number is required";
    }

    setFormErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/policies");
  };

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  const Recurrency_Period = [
    { value: "ANNUALLY", label: "ANNUALLY" },
    { value: "BI_ANNUALLY", label: "BI_ANNUALLY" },
    { value: "QUATERLY", label: "QUATERLY" },
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "NEVER", label: "NEVER" },
  ];

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const [selectedEmployeeId2, setSelectedEmployeeId2] = useState<string[]>([]);

  const handleEmployeeSelect2 = (employeeId: string[]) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId2 = (employeeId: string[]) => {
    setSelectedEmployeeId2(employeeId);
    setApproversErr(false);
  };

  const postData = async () => {
    setLoading(true);
    try {
      await createPolicy(
        formValues.policy_name,
        formValues.policy_category,
        formValues.group,
        selectedDepartmentIds,
        editorContent,
        formValues.recurrence,
        formValues.version_number,

        selectedEmployeeId2,
        dayjs(joining).format("YYYY-MM-DD"),
        uploadedFiles.map((files) => files.url)
      );

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        navigate("/policies");
      }, 1000);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleDepartmentChange = (
    event: SelectChangeEvent<typeof departmentName>
  ) => {
    const {
      target: { value },
    } = event;

    setDeptError("");

    if (value?.includes("All")) {
      if (departmentName.length === department?.length) {
        setDepartmentName([]);
        setSelectedDepartmentIds([]);
      } else {
        const names = department?.map((option) => option?.name) || [];
        setDepartmentName(names);
        const ids = department?.map((option) => Number(option?.id)) || [];
        setSelectedDepartmentIds(ids);
      }
    } else {
      const selectedNames =
        typeof value === "string" ? value.split(",") : value;

      setDepartmentName(selectedNames);

      const selectedIds =
        department
          ?.filter((dept) => selectedNames.includes(dept.name))
          .map((dept) => Number(dept.id)) || [];

      setSelectedDepartmentIds(selectedIds);
    }

    if (value !== "") setDeptError("");
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_Category_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
        //sortType,
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        setTableData(response.data?.result);
        //setCount(response.data?.result?.count);
      } else {
      }
    } catch (error) {}

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    // searchTerm,
    page,
    numOfItems,
  ]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, []);

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Policy added successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="w-full flex flex-row justify-between">
        <div className="w-full flex flex-row items-start h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/policies")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Add Policy Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/policies")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            >
              <div className=" text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize ">
                Cancel
              </div>
            </button>
            <Button
              type="submit"
              form="form"
              onClick={handleOpen}
              variant={"gradient"}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Create
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white">
        <div className="container mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Policy Name"
                placeholder="Enter Project"
                value={formValues.policy_name}
                onChange={handleFrormChange}
                name="policy_name"
                error={!!formErrors.policy_name}
                helperText={formErrors.policy_name}
              />
              <TextField
                required
                id="outlined-required"
                label="Policy category"
                select
                placeholder="Policy Category"
                name="policy_category"
                value={formValues.policy_category}
                onChange={handleFrormChange}
                error={!!formErrors.policy_category}
                helperText={formErrors.policy_category}
              >
                {tableData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                select
                label="Business Unit"
                name="group"
                defaultValue="hello"
                variant="outlined"
                value={formValues.group}
                onChange={handleFrormChange}
                error={!!formErrors.group}
                helperText={formErrors.group}
              >
                {bussness?.map((option) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Effective Date *"
                    value={joining}
                    format="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setJoining(newValue);
                      setJoiningErr(false);
                    }}
                    className="w-full"
                    slotProps={{
                      textField: {
                        error: joiningErr,
                        helperText: joiningErr
                          ? "Effective date is required"
                          : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <TextField
                required
                id="outlined-required"
                label="Reccurence Period"
                select
                name="recurrence"
                value={formValues.recurrence}
                onChange={handleFrormChange}
                error={!!formErrors.recurrence}
                helperText={formErrors.recurrence}
              >
                {Recurrency_Period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                type="number"
                id="outlined-required"
                label="Version Number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Enter Declaration"
                value={formValues.version_number}
                onChange={handleFrormChange}
                name="version_number"
                error={!!formErrors.version_number}
                helperText={formErrors.version_number}
              />
              <div className="mt-2 px-2">
                <FormControl className="w-full" sx={{ padding: 0 }}>
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ color: deptError ? "#d32f2f" : "" }}
                  >
                    Department *
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={departmentName}
                    onChange={handleDepartmentChange}
                    input={<OutlinedInput label="Department" />}
                    renderValue={(selected) => selected.join(", ")}
                    error={!!deptError}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {department && department?.length > 0 ? (
                      <MenuItem key={"All"} value={"All"}>
                        <Checkbox
                          checked={
                            departmentName?.length === department?.length
                          }
                        />
                        <ListItemText primary={"Select All"} />
                      </MenuItem>
                    ) : null}
                    {department?.map((option) => (
                      <MenuItem key={Number(option.id)} value={option.name}>
                        <Checkbox
                          checked={departmentName.indexOf(option.name) > -1}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {deptError}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="pr-4">
                <EmployeeSearch
                  onEmployeeSelect={handleEmployeeSelect2}
                  onEmployeeSelectId={handleEmployeeSelectId2}
                  error={approverErr}
                />
              </div>
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Policy Description
              </span>
              <MyStatefulEditor
                onChange={setEditorContent}
                // error={!editorContent}
                // helperText="Policy Description is required"
              />
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Documents
              </span>
              <FileUpload onFilesChange={handleFilesChange} />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
