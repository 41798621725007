import React from "react";
import Modal from "react-modal";
import { SaveAlt } from "@mui/icons-material";

interface ModalViewerProps {
  isOpen: boolean;
  onRequestClose: () => void;
  fileUrl: string;
}

const downloadFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ModalViewer: React.FC<ModalViewerProps> = ({
  isOpen,
  onRequestClose,
  fileUrl,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90vw",
      height: "90vh",
      border: "0px",
      padding: "20px",
      borderRadius: "8px",
      background: "transparent",
      overflow: "initial",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 100,
    },
  };

  // Extract file extension
  const fileExtension = fileUrl.split(".").pop()?.toLowerCase();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File Viewer"
      style={customStyles}
    >
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex w-full justify-end gap-2">
          <button
            onClick={() => downloadFile(fileUrl, "file")}
            className="mt-4 border-white border-2 bg-transparent rounded-lg font-[Poppins] text-white px-5 py-[10px]"
          >
            <SaveAlt />
          </button>
          <button
            onClick={onRequestClose}
            className="mt-4 border-white border-2 bg-transparent rounded-lg font-[Poppins] text-white px-5 py-[10px]"
          >
            Close
          </button>
        </div>
        {fileExtension === "pdf" ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            className="flex-grow"
          />
        ) : fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "png" ? (
          <img
            src={fileUrl}
            alt="Preview"
            className="object-contain w-10/12 h-[90%]"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <p className="text-white font-[Poppins]">
              Please download to view.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalViewer;
