import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Footer from "../Layout/footer";
import search from "../../assets/images/search.png";
import rightArrow from "../../assets/chevron-right.svg";
// import assign from "../../assets/images/assign.png";

import { api_key_work as api_key } from "../../utils/helperData";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import close from "../../assets/images/close.png";
import { useSelector } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import { Checkbox } from "@material-tailwind/react";
import { debounce } from "lodash";
// import edit_new from "../../assets/images/edit_new.png";
// import { ROLE_MANAGEMENT } from "../../utils/routeConstant";
import {
  fetchModalDataRoleDetail,
  fetchRoleUsers,
  postRoleAssignment,
} from "../common/services/roleManagement";
import TableSkeleton from "../common/TableSkeleton";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import profile from "../../assets/Ellipse 580.png";
// import Search2 from "../SearchBar/Search2";
import Search from "../SearchBar/Search";
import { valueOrDefault } from "chart.js/dist/helpers/helpers.core";

const tableHeadings = [
  { label: "First Name", width: "w-[17%]" },
  { label: "Last Name", width: "w-[17%]" },
  { label: "Added Date", width: "w-[22%]" },
  { label: "Email", width: "w-[32%]" },
  { label: "Role", width: "w-[27%]" },
];

const modalHeadings = [
  { label: "", width: "w-[15%]" },
  { label: "First Name", width: "w-[28.34%]" },
  { label: "Last Name", width: "w-[28.34%]" },
  { label: "Email", width: "w-[28.34%]" },
];

const RoleDetails = () => {
  interface Role {
    role_name: string;
  }

  interface IRoleUsers {
    firstName: string;
    lastName: string;
    createdAt: string;
    email: string;
    Role: Role;
    status: string;
    id: number;
    employee_id: string;
    profile_image: string;
  }

  interface ModalData {
    role_id: number;
    firstName: string;
    lastName: string;
    email: string;
    id: number;
  }

  const [tableData, setTableData] = useState<IRoleUsers[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [name_id, setName_id] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }
  if (signupData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = signupData.sign_up_details.customer_id;
  }

  const navigate = useNavigate();

  const [UserIsOpen, setUserIsOpen] = useState(false);

  const location = useLocation();
  const roleId = location.state?.id;

  /////////////pagination////////////////

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [triggerFetchModal, setTriggerFetchModal] = useState(false);
  ///////////////////search modal
  const [searchTerm, setSearchTerm] = useState("");

  ////////////////////modal fetch

  const [modalData, setmodalData] = useState<ModalData[]>([]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
    setTriggerFetch(true);
  }

  function handlePageChange(value: number) {
    setPage(value);
    setTriggerFetch(true);
  }
  const [triggerFetch, setTriggerFetch] = useState(false);

  // useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchRoleUsers(roleId, numOfItems, page, searchTerm);

        if (data?.result) {
          setTableData(data?.result?.rows ?? []);
          setCount(data?.result?.count ?? 0);
        } else {
          console.error("Error: data.result.rows is undefined");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
    };
  //   if (triggerFetch) {
  //     fetchData();
  //     setTriggerFetch(false);
  //   }
  // }, [
  //   firstName,
  //   lastName,
  //   createdAt,
  //   email,
  //   role,
  //   status,
  //   name_id,
  //   triggerFetch,
  //   page,
  //   numOfItems,
  //   searchTerm,
  // ]);


    useEffect(() => {
      fetchData();
    }, [searchTerm, email, firstName, lastName, page, numOfItems,]);

  useEffect(() => {
    if (!UserIsOpen) {
      setTriggerFetch(true);
      setUserIsOpen(false);
    }
  }, [UserIsOpen]);
  ////modal pagination
  const [modalCount, setModalCount] = useState(0);
  const [modalnumOfItems, setModalNumOfItems] = useState(10);
  const [modalpage, setModalPage] = useState(1);

  function handleNumberOfPagesModal(value: number) {
    setModalNumOfItems(value);
    setTriggerFetchModal(true);
  }

  function handlePageChangeModal(value: number) {
    setModalPage(value);
    setTriggerFetchModal(true);
  }



  const handlCloseModal = () => {
    setUserIsOpen(false);
    setSearchTerm("");
  };

  const fetchModalData = useCallback(
    debounce(async (searchTerm: string) => {
      setLoading(true);
      try {
        const data = await fetchModalDataRoleDetail(
          customer_id,
          modalnumOfItems,
          modalpage,
          searchTerm
        );

        if (data?.result) {
          setmodalData(data?.result?.rows);
          setModalCount(data?.result?.count);
        } else {
          console.error("Error: data.result.rows is undefined");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
    }, 100),
    [customer_id, modalnumOfItems, modalpage, api_key, access_token , searchTerm]
  );

  useEffect(() => {
    if (triggerFetchModal) {
      fetchModalData(searchTerm);
      setTriggerFetchModal(false);
    }
  }, [triggerFetchModal, fetchModalData, modalnumOfItems, modalpage]);

  ///////////////search modal

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
      
    }, 100), // delay in ms
    []
  );

  const filteredTableData = useMemo(() => {
    if (!modalData) {
      return [];
    }

    return modalData?.filter((row) =>
      row?.firstName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  }, [modalData]);

  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [uncheckedItems, setUncheckedItems] = useState<number[]>([]);

  const handleCheckboxChange = (e: any, id: any, role_id: any) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, id]);
      setUncheckedItems(uncheckedItems?.filter((itemId) => itemId !== id));
    } else {
      setCheckedItems(checkedItems?.filter((itemId) => itemId !== id));
      if (roleId === role_id) {
        setUncheckedItems([...uncheckedItems, id]);
      }
    }
  };

  const postData = async () => {
    setLoading(true);
    try {
      const data = await postRoleAssignment(
        checkedItems,
        uncheckedItems,
        roleId
      );
      setTriggerFetch(true);
    } catch (error) {
      console.error("Error posting data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkedIds = modalData
      ?.filter((item) => item?.role_id === roleId)
      ?.map((item) => item?.id);
    setCheckedItems(checkedIds);
  }, [modalData, roleId]);

  const columns: ColumnDef<IRoleUsers>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          User Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 pl-2 pr-2.5 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle
                cx="3.99537"
                cy="3.74537"
                r="3.49537"
                fill={
                  item?.status === "active"
                    ? "#00CBA0"
                    : item?.status === "inactive"
                    ? "#FF9950"
                    : item?.status === "archived"
                    ? "#FA1464"
                    : "#D3D3D3"
                }
              />
            </svg>
            <img
              className="w-8 h-8 rounded-full"
              src={item?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item?.firstName} {item?.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item?.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Added Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.createdAt
          ? new Date(row.original.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "role_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Role
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        return <div className="">{row?.original?.Role?.role_name}</div>;
      },
    },
  ];

  return (
    <div className="px-4 py-6 bg-[#F9F9F9] min-h-[90vh]">
      <div className="flex flex-col">
        <div className="flex-grow">
          {/* Your main content goes here */}
          <div className="h-full">
            <div className="flex flex-row items-center h-12 justify-between">
              <div className="flex flex-row gap-[10px] justify-center items-center">
                <button onClick={() => navigate("/role-management")}>
                  <img
                    src={rightArrow}
                    alt="right arrow sign"
                    className="h-6 w-6 shrink-0 bg-black rounded-full"
                  />
                </button>
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  Role Management
                </span>
              </div>
              {/* <div className="flex ">
                <button
                  onClick={() => {
                    setUserIsOpen(true);
                    fetchModalData("");
                  }}
                  className="flex w-[120px] py-[2px] px-[2px] justify-center items-center rounded-md bg-[#5D42D1] h-9"
                >
                  <div className="flex justify-center items-center gap-[7px]">
                    <img
                      src={assign}
                      alt="right arrow sign"
                      className="h-4 w-4 shrink-0 relative"
                    />
                    <span className="text-white font-[Poppins] text-xs leading-[20px]">
                      Assign User
                    </span>
                  </div>
                </button>
              </div> */}
            </div>

            <div className="w-full mb-[5px]">
              <div className="p-2.5 flex flex-col items-flex-end gap-[17px] self-stretch rounded-md">
              <div className="h-[40px] w-full">
                <div className="h-full flex justify-between items-center">
                  <Search
                    onChange={updateSearchTerm}
                    label="Search with User name"
                  />
                </div>
              </div>

                <div className="flex flex-row justify-end gap-[31px]">
                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#00CBA0"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Active users
                    </span>
                  </div>

                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#FF9950"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Inactive users
                    </span>
                  </div>

                  <div className="flex flex-row justify-between gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <circle
                        cx="3.99537"
                        cy="3.74537"
                        r="3.49537"
                        fill="#FA1464"
                      />
                    </svg>
                    <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                      Archived users
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-0 py-0 mt-0">
              {loading ? (
                <TableSkeleton />
              ) : (
                <div className="table_main_content w-full max-h-[calc(100vh-17rem)] relative overflow-auto bg-white">
                  <DynamicTable<IRoleUsers>
                    data={tableData}
                    loading={false}
                    columns={columns}
                    enableSorting
                    enablePagination
                  />
                </div>
              )}
            </div>
            {count >= 10 && (
              <TablePaginationDemo
                count={count}
                handleItemsChange={handleNumberOfPages}
                handlePageChange={handlePageChange}
                currentPage={page}
                numOfItems={numOfItems}
              />
            )}
          </div>
        </div>
        <Footer />
        {/* {loading && (
          <div className="bg-black opacity-70 fixed top-0 left-0 h-screen w-screen">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
              role="status"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )} */}
      </div>
      <Modal
        isOpen={UserIsOpen}
        onRequestClose={() => setUserIsOpen(false)}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // This is black with 50% opacity
          },
          content: {
            width: "80%",
            height: "95%",
            maxWidth: "600px",
            maxHeight: "600px",
            margin: "auto",
            overflow: "auto",
          },
        }}
      >
        <div className="flex flex-col px-5 py-5 bg-white rounded max-w-[639px]">
          <div className="flex gap-5 justify-between p-2.5 text-xl font-medium leading-6 text-gray-700 bg-white rounded max-md:flex-wrap max-md:max-w-full">
            <div className="flex-auto font-semibold">User list</div>
            {/* <button onClick={() => setUserIsOpen(false)}> */}
            <button  title="btn-1" onClick={handlCloseModal}>
              <img
                loading="lazy"
                src={close}
                className="shrink-0 w-6 border border-solid aspect-square border-slate-100"
                alt="error"
              />
            </button>
          </div>
          <div className="flex gap-5 justify-between px-px mt-4 w-full text-xs max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-5 justify-between px-2.5 py-3 text-gray-400 bg-white rounded-md border border-solid border-stone-300">
              <input
                type="text"
                placeholder="Search"
                className="text-[#B5B5C3] font-[Poppins] text-[13px] font-normal leading-normal bg-transparent outline-none"
                onChange={updateSearchTerm}
              />
              <img
                src={search}
                alt="search sign"
                className="h-[19px] w-[19px] shrink-0"
              />
            </div>
            <div className="flex">
              <button
                onClick={() => {
                  setUserIsOpen(false);
                  postData();
                }}
                className="flex w-[150px] py-[2px] px-[2px] justify-center items-center rounded-md bg-[#5D42D1] h-9"
              >
                <div className="flex justify-center items-center gap-[7px]">
                  <span className="text-white font-[Poppins] text-xs leading-[20px]">
                    (Un)Assign
                  </span>
                </div>
              </button>
            </div>
          </div>
          <br />
          <div className="px-0 py-0 mt-0">
            <table className="mt-0 w-full min-w-max text-left table-auto">
              <thead className="">
                <tr className="flex flex-row w-full items-center pl-6 pr-2.5 py-2.5 rounded-[3px] bg-[#E1E1E1] gap-8 h-11">
                  {modalHeadings.map((item) => {
                    const cssClass =
                      "flex cursor-pointer transition-colors items-center " +
                      item.width;
                    return (
                      <th className={cssClass}>
                        <span className="shrink-0 text-[#3F4254] font-[Poppins] text-xs font-medium leading-[11.185px] tracking-[0.72px] capitialize">
                          {item.label}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="">
                {filteredTableData.map((item) => {
                  return (
                    <tr className="w-full flex px-2.5 py-1 items-center gap-8 border-[0.6px] border-solid border-[#E0E3E7] h-8">
                      <td className="w-[10%] ">
                        <div className="flex flex-row items-center gap-1 shrink-0 ]">
                          <Checkbox
                            ripple={false}
                            crossOrigin=""
                            checked={checkedItems.includes(item.id)} // change this line
                            // onChange={(e) =>
                            //   handleCheckboxChange(
                            //     e,
                            //     item.role_id,
                            //     item.role_id
                            //   )
                            // }
                            onChange={(e) =>
                              handleCheckboxChange(e, item.id, item.role_id)
                            }
                            className="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0"
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                          />
                        </div>
                      </td>
                      <td className="w-[20%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className="ml-7 text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {item.firstName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[25%]">
                        <div className="flex items-center">
                          <p className="ml-10 text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                            {item.lastName}
                          </p>
                        </div>
                      </td>
                      <td className="w-[35%]">
                        <div className="flex items-center">
                          <div className="flex flex-row items-center gap-1 shrink-0">
                            <p className="text-[#262626] shrink-0 font-[Poppins] text-[10px] leading-[13.981px] font-medium">
                              {item.email}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePaginationDemo
            count={modalCount}
            handleItemsChange={handleNumberOfPagesModal}
            handlePageChange={handlePageChangeModal}
            currentPage={1}
            numOfItems={10}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RoleDetails;
