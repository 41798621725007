import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "../../image/MicrosoftTeams-image (3).png";
import toast from "react-hot-toast";
import { CountryCode } from "libphonenumber-js/core";
import { signUpUser, checkEmailExist } from "../../redux/actions/signupActions";
// import { authActions } from "../../redux/reducers/Auth/authSlice";
import { signupActions } from "../../redux/reducers/Signup/signupSlice";
import Header from "../common/header";
import Icon from "../../assets/tick.svg";

import {
  LOGIN,
  ORGANISATION_DETAILS,
  SET_PASSWORD,
  VERIFY_EMAIL,
} from "../../utils/routeConstant";
import { MuiTelInput } from "mui-tel-input";
import {
  UserManagementForm,
  flattenGroupData,
  labelStyle,
  validateEmail,
  validatePhoneNumber,
  inputStyle,
  menuStyle,
} from "../../utils/helperData";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import loginimage1 from "../../image/loginslide1.png";
import loginimage2 from "../../image/loginslide2.png";
import loginlogo from "../../image/loginlogo.png";
import griddesign from "../../image/ornamentlogin.png";

const Signup: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = React.useState<string>("");
  const [fullName, setFullName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [countryCode, setCountryCode] = React.useState("");
  const [countryInitial, setCountryInitial] = useState<CountryCode>();
  const [onboardingStatus, setOnboardingStatus] = useState<string>("");
  const [nationalNumber, setNationalNumber] = useState("");

  const signupData = useSelector((state: any) => state.signup.details);

  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    setAutoPlay(true);
  }, []);

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {}
      );
    } else {
    }
  }, []);

  useEffect(() => {
    // Fetch country based on latitude and longitude
    async function fetchCountry() {
      try {
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        if (response.ok) {
          const data = await response.json();
          setCountryInitial(data.countryCode);
        } else {
        }
      } catch (error) {}
    }

    if (latitude !== undefined && longitude !== undefined) {
      fetchCountry();
    }
  }, [latitude, longitude]);

  const handlePhoneChange = (value: string, info: any) => {
    setPhone(value);
    setNationalNumber(info?.nationalNumber);
    setCountryCode(`+${info?.countryCallingCode}`);
  };

  // const handleSignup=()=>{
  //   navigate("/orgnisatioal-details")
  // }

  const handleSignup = async () => {
    // if (!isChecked) {
    //   toast.dismiss();
    //   // toast.error("Please fill complete details");
    // }

    if (isChecked) {
      if (!email?.trim() || !phone?.trim() || !fullName?.trim()) {
        toast.dismiss();
        toast.error("Please fill complete details.");

        return;
      }
      if (!isValidEmail(email)) {
        toast.dismiss();
        toast.error("Please provide a valid email address");

        return;
      }
      setIsSignUp(true);
      if (signupData !== null) {
        const onboardingStatus = signupData.sign_up_details.onboarding_status;

        if (onboardingStatus !== "") {
          localStorage.setItem("signup_token", signupData?.signup_token);
          if (onboardingStatus === "USER_CREATED") {
            navigate(`${ORGANISATION_DETAILS}`);
            return;
          }

          if (onboardingStatus === "ORGANISATION_UPDATED") {
            navigate(`${VERIFY_EMAIL}`);
            return;
          }

          if (onboardingStatus === "EMAIL_VERIFIED") {
            navigate(`${SET_PASSWORD}`);
            return;
          }
          if (onboardingStatus === "PASSWORD_UPDATED") {
            toast.dismiss();
            toast.success("Please login to continue!");
            navigate(`${LOGIN}`);
            return;
          }
        }
      }

      const names = fullName?.split(" ");
      let firstName = names?.slice(0, -1).join(" ");
      let lastName: string = "";
      if (firstName) {
        lastName = names[names?.length - 1];
      } else {
        firstName = names[names?.length - 1];
        lastName = "";
      }

      setIsSignUp(true);
      // Dispatch login action
      try {
        toast.dismiss();
        toast.loading("Processing...");
        const userData = await dispatch<any>(
          signUpUser(email, nationalNumber, firstName, lastName, countryCode)
        );
        if (userData) {
          const signup_token = userData?.data?.result?.signup_token;
          const customer_id: string = userData?.data?.result?.customer_id;
          const onboarding_status: string =
            userData?.data?.result?.onboarding_status;
          setOnboardingStatus(onboarding_status);

          if (signup_token) {
            // setting token to local storage
            localStorage.setItem("signup_token", signup_token);
            dispatch(
              signupActions.setSignupDetails({
                signup_token: signup_token,
                sign_up_details: {
                  email,
                  phone: nationalNumber,
                  firstName,
                  lastName,
                  countryCode,
                  customer_id,
                  onboarding_status: onboardingStatus,
                },
                organisation_details: null,
              })
            );
          }
        }
        // Dispatch action to save token in Redux
        // Redirect to dashboard (you can use React Router or any other routing library)
        navigate(`${ORGANISATION_DETAILS}`);
        toast.dismiss();
      } catch (error: any) {
        toast.dismiss();
        toast.error(error?.response?.data?.message || "Something went wrong");
      } finally {
        setIsSignUp(false);
      }
    } else if (!email.trim() || !phone.trim() || !fullName.trim()) {
      toast.dismiss();
      toast.error("Please fill complete details.");

      return;
    } else if (!isValidEmail(email)) {
      toast.dismiss();
      toast.error("Please provide a valid email address");

      return;
    } else if (!isChecked) {
      toast.dismiss();
      toast.error(
        "You must agree to our terms and conditions before continuing."
      );
    }
    // Validation: Check if all details are provided
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChecked = () => {
    setIsChecked((prev) => !prev);
  };

  const handleEmailExist = async () => {
    if (email) {
      try {
        const userData = await dispatch<any>(checkEmailExist(email));
        if (userData.signup_token) {
          setEmail(userData.email);
          setPhone(userData.phone);
          setCountryCode(userData.country_code);
          setFullName(userData.firstName + " " + userData.lastName);
          setOnboardingStatus(userData.onboarding_status);

          localStorage.setItem("signup_token", userData.signup_token);
          dispatch(
            signupActions.setSignupDetails({
              signup_token: userData.signup_token,
              sign_up_details: {
                email,
                phone,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                countryCode,
                customer_id: userData?.customer_id,
                onboarding_status: userData?.onboarding_status,
              },
              organisation_details: null,
            })
          );
        }
        // Further logic with userData
      } catch (error) {
        // Handle errors
      }
    }
  };

  return (
    <div className=" ">
      {/* <Header /> */}
      {/* <div className="flex"></div> */}
      <div className="flex justify-center  h-screen px-4 py-4 ">
        <div className="relative w-1/2 flex flex-col justify-center items-center  bg-gradient-to-r from-[#E8EBF6] to-[#F1E3FD] rounded-[8px] overflow-hidden ">
          <img
            src={griddesign}
            alt="Gridline Ornament"
            className="absolute top-[0px] left-[0px] w-[424px] h-[370px] object-contain pointer-events-none"
          />

          <section className=" rounded-xl w-full min-h-screen flex flex-col justify-between pt-10 pb-10 ">
            <div className=" top-1 pl-4">
              <div className="font-poppins text-[35px] font-[600] text-[#020617] leading-[44px] mb-4">
                Automated HR Workflows for Smarter Management.
              </div>
              <div className="w-[98px] h-[2px] bg-[#374151]"></div>
            </div>

            <div dir="rtl">
              <Carousel
                autoPlay={autoPlay}
                interval={5000}
                animation="slide"
                duration={500}
                indicators={false}
                navButtonsAlwaysInvisible={true}
                stopAutoPlayOnHover={false}
                className="rounded-lg overflow-hidden"
                indicatorContainerProps={{
                  style: { marginTop: "-10px" },
                }}
              >
                <div className=" p-4  flex flex-col items-center text-center">
                  <h3 className="text-[18px] font-poppins font-[500]">
                    Track Employee Attendance
                  </h3>
                  <p className="text-sm text-gray-600 text-center max-w-[400px]">
                    Stay updated with employee work hours, leaves, and
                    attendance history.
                  </p>
                  <img
                    src={loginimage1}
                    alt="Attendance"
                    className=" w-[630px] object-contain mt-4 mb-2 rounded"
                  />
                </div>

                <div className=" p-4  flex flex-col items-center text-center">
                  <h3 className="text-[18px] font-poppins font-[500]">
                    Track Project Health and Profitability
                  </h3>
                  <p className="text-sm text-gray-600 text-center max-w-[400px]">
                    Track progress, assign tasks, and manage contributions
                    seamlessly.
                  </p>

                  <img
                    src={loginimage2}
                    alt="Project"
                    className=" w-[630px] object-contain mt-4 mb-2 rounded"
                  />
                </div>
              </Carousel>
            </div>
          </section>
        </div>

        <div className="w-1/2 bg-white flex flex-col items-center justify-center p-10 rounded-xl">
          <div className=" items-center gap-2 ">
            <img
              src={loginlogo}
              alt="Logo"
              className=" w-[200px] h-auto object-cover mb-2 rounded"
            />
          </div>

          <div className=" shadow-lg border border-[#E2E8F0] rounded-lg p-4 top-[73px]">
            <div className=" ml-5">
              <p className="text-neutral-800 font-['Inter'] font-semibold text-[22px] text-[#374151]">
                {" "}
                Register for an account{" "}
              </p>
              <p className="text-neutral-400 font-['poppins'] font-[400] text-[14px] text-[#374151] max-w-full break-words">
                Add your details
              </p>
            </div>

            <form className="flex flex-col  max-w-[414px] max-h-[510px]">
              <label
                className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-[0.87rem] ml-5"
                htmlFor="full-name"
              >
                Full Name
              </label>
              <div className="flex justify-center mt-1">
                <input
                  className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-sm border border-[#262626] px-2"
                  id="full-name"
                  type="text"
                  value={fullName}
                  autoComplete="off"
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>

              <div className="mt-[1rem]">
                <div>
                  <label
                    className="text-neutral-800 text-xs font-semibold font-['Poppins'] mb-[4px] ml-5 text-left"
                    htmlFor="email"
                  >
                    Work Email
                  </label>
                  <div className="flex justify-center mt-1">
                    <input
                      className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                      id="email"
                      type="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmailExist}
                      required
                    />
                  </div>
                </div>

                <div className="mt-[1rem]">
                  <label
                    className="text-neutral-800 text-xs font-semibold font-['Poppins'] mt-[0.87rem] mb-[4px] ml-5"
                    htmlFor="phone"
                  >
                    Phone Number
                  </label>
                  <div className="flex flex-row gap-2 mt-1">
                    <MuiTelInput
                      // label="Phone"
                      id="phone"
                      name="phone"
                      // placeholder="Enter Phone Number"
                      defaultCountry={countryInitial || "IN"}
                      focusOnSelectCountry
                      autoComplete="off"
                      className="w-[18.75rem] h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                      // required
                      // error={isValid[`phone`]}
                      // helperText={
                      //   isValid[`phone`] === 2
                      //     ? "This field is required!"
                      //     : isValid[`phone`] === 1
                      //     ? "Length must be of 8-16 characters long!"
                      //     : ""
                      // }
                      InputLabelProps={{
                        shrink: true,
                        // sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          marginLeft: "20px",
                          borderRadius: "3px",
                          height: "2.5rem",
                          // width: "300px",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                    {/* <PhoneInput
                    className="my-custom-class"
                    defaultCountry={countryInitial}
                    value={phone}
                    onChange={handlePhoneChange}
                    autoComplete="off"
                  /> */}
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-2 ml-[1.3rem] mt-[6px] items-center">
                <div className="w-6 h-6 mt-2.5 rounded-lg">
                  <input
                    type="checkbox"
                    className="w-4 h-4 border-2 rounded-lg form-checkbox form-checkbox checked:bg-blue-400"
                    checked={isChecked}
                    onChange={handleChecked}
                  ></input>
                </div>

                <div className="flex w-[16.6rem] h-[2.5rem] ml-[-5px] items-center">
                  <div className="text-neutral-800 text-[10px] font-medium font-['Inter'] items-center mt-[8px]">
                    By clicking below, you agree to the{" "}
                    <Link className="underline" to={""}>
                      Go-EMS Terms of Services{" "}
                    </Link>
                    and{" "}
                    <Link className="underline" to={""}>
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="text-white w-[18.75rem] h-[2.5rem] buttoncss rounded-[8px] text-sm font-semibold font-['Poppins']"
                  type="button"
                  onClick={handleSignup}
                  //disabled={isSignUp}
                >
                  Continue
                </button>
              </div>

              <div className="mt-[.5rem] justify-center flex  flex-col md:flex-row gap-[4px]">
                <div>
                  <span className="text-black text-[12px] font-[400] font-['Poppins']">
                    Already have an GO-EMS account?{" "}
                  </span>
                  <NavLink
                    to="/"
                    className="text-[#679BFF] text-xs font-medium font-['Poppins'] underline"
                  >
                    {/* <span className="text-blue-400 text-xs font-semibold font-['Poppins']">
                    Log in
                  </span> */}
                    <strong>Log In</strong>
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
          <p className="font-poppins text-[14px] font-[400] text-[#6B7280] leading-[22px] mb-4  text-center  pt-3">
            © Go-EMS. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
