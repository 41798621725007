import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TextField, Box, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import toast from "react-hot-toast";

import right from "../../image/chevron-right.png";

import {
  Fetch_Asset_Catergory,
  Fetch_Project_List,
  Update_Asset,
  View_Asset,
} from "../common/services/Asset";
import { AssetGategoryData } from "./AssetCategory";
import MyStatefulEditor from "../ProjectManagement/EditEditor";
import FileUpload from "../ExpenseManagement/EditFileUoload";
import SuccessModal from "../common/SuccessModal";
import { Button } from "../common/Button";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

interface Projects {
  id: number;
  project_name: string;
}

interface UserData {
  firstName: string;
  lastName: string;
}

interface EditData {
  asset_category_id: string;
  asset_name: string;
  asset_serial_number: string;
  description: string;
  project_id: string;
  assigned_date: Dayjs | string | null;
  image_filename: string[];
}

function EditAsset() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  const [project, setProject] = useState<Projects[]>([]);
  const [open, setOpen] = useState(false);
  const [date, setDate] = React.useState<Dayjs | string | null>(null);
  const [category, setCategory] = useState<AssetGategoryData[]>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [userData, setUserData] = useState<UserData>();
  const [editorContent, setEditorContent] = useState<string>("");
  const [editData, setEditData] = useState<EditData>({
    asset_category_id: "",
    asset_name: "",
    asset_serial_number: "",
    project_id: "",
    description: "",
    assigned_date: "",
    image_filename: [""],
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    setEditData({
      ...editData,
      image_filename: files.map((file) => file.url),
    });
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleClick = () => {
    submitChanges();
  };

  const submitChanges = async () => {
    try {
      const data = await Update_Asset(id, editData);
      if (data.status_code === 200) {
        setOpen(true);
        setTimeout(() => {
          handleClose();
          navigate("/asset-management");
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    View_Asset(id)
      .then((data) => {
        if (data.result) {
          setEditData({
            asset_category_id: String(data.result.asset_category_id),
            asset_name: data.result.asset_name,
            asset_serial_number: data.result.asset_serial_number,
            project_id: String(data.result.project_id),
            description: data.result.description,
            assigned_date: data.result.assigned_date,
            image_filename: data.result.image_filename,
          });
          setUserData(data.result.User);
          setEditorContent(data.result.description);
          setDate(dayjs(data.result.assigned_date));
          const image_filename = data.result.image_filename;
          const files = image_filename?.map((name: string, index: number) => ({
            url: image_filename[index],
          }));
          setUploadedFiles(files);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Asset_Catergory(customer_id, 100, 1, "");
        if (response.status === 200) {
          setCategory(response.data.result.rows);
        } else {
          toast.error(response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Project_List(customer_id);
        if (response.status === 200) {
          setProject(response.data.result);
        } else {
          toast.error(response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(
    () =>
      setEditData({
        ...editData,
        description: editorContent,
      }),
    [editorContent, setEditorContent]
  );

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate(-1)}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Asset Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/asset-management")}
              className="flex w-[148px] rounded-lg p-[13.18px_50px_14.82px_51px] justify-center items-center  borderButton h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <Button
              type="submit"
              form="form"
              variant={"gradient"}
              onClick={handleClick}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </Button>
          </div>
        </div>
        <div className="rounded-lg shadow-custom p-4 bg-white">
          <div className="flex flex-col gap-8">
            <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626]">
              Assigned Assset Details
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-8">
                <TextField
                  required
                  disabled
                  id="outlined-required"
                  label="Employee Name"
                  value={userData?.firstName + " " + userData?.lastName}
                />
                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Asset Category"
                  name="asset_category_id"
                  value={editData.asset_category_id}
                  onChange={handleFormChange}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                >
                  {category?.map((option) => (
                    <MenuItem key={Number(option.id)} value={String(option.id)}>
                      {option.category}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  id="outlined-required"
                  label="Asset Name"
                  name="asset_name"
                  value={editData.asset_name}
                  onChange={handleFormChange}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Serial Number"
                  name="asset_serial_number"
                  value={editData.asset_serial_number}
                  onChange={handleFormChange}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Assigned Date"
                    value={date}
                    minDate={dayjs()}
                    onChange={(newValue) =>
                      setEditData({
                        ...editData,
                        assigned_date: dayjs(newValue).format("MM-DD-YYYY"),
                      })
                    }
                  />
                </LocalizationProvider>
                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Assigned Project"
                  name="project_id"
                  value={editData.project_id}
                  onChange={handleFormChange}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                >
                  {project?.map((option) => (
                    <MenuItem key={Number(option.id)} value={String(option.id)}>
                      {option.project_name}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="col-span-3">
                  <MyStatefulEditor
                    content={editorContent}
                    onChange={setEditorContent}
                  />
                </div>
                <div className="col-span-3 relative">
                  <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626] absolute px-2">
                    Attachment
                  </span>
                  <FileUpload
                    existingFiles={uploadedFiles}
                    onFilesChange={handleFilesChange}
                  />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={open}
        onclose={handleClose}
        label="Asset Details Updated Successfully!"
      />
    </div>
  );
}

export default EditAsset;
