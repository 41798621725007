import { Skeleton, Stack } from "@mui/material";
import React from "react";

const TableSkeleton = () => {
  return (
    <div className="mt-4">
      <Stack spacing={1} className="mt-4">
        <Skeleton variant="rounded" width="100%" height={30} />

        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={20} />

      </Stack>
    </div>
  );
};

export default TableSkeleton;
