import React, { useEffect, useState } from "react";
import PersonalEmpDetails from "./PersonalEmpDetails";
import { EmployeeData } from "./ViewEmployeeList";
import { fetchEditEmployee } from "../common/services/Employee";
import noResult from "../../assets/noResult.svg";
const tabs = [
  {
    label: "Personal Details",
    value: "personal",
  },
  {
    label: "Educational Details",
    value: "educational",
  },
  {
    label: "Experience",
    value: "experience",
  },
  {
    label: "Bank Details",
    value: "bank",
  },
  {
    label: "Documents",
    value: "documents",
  },
  {
    label: "Salary Structure",
    value: "salary",
  },
];
export default function EmployeeInfo({ id }: { id: string }) {
  const [selected, setSelected] = useState("personal");
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    empCode: "",
    empId: "",
    empName: "",
    empLastName: "",
    email: "",
    code: "",
    number: "",
    business_unit: "",
    role: "",
    department: "",
    designation: "",
    team_manager_firstName: "",
    team_manager_lastName: "",
    reporting_manager_firstName: "",
    reporting_manager_lastName: "",
    status: "",
    MoE: "",
    YOE: "",
    DOB: "",
    DOJ: "",
    DOL: null,
    profile_image: null,
    location: null,
    employeeType: "",
    bloodGroup: null,
    shiftId: null,
    isActive: false,
    createdAt: "",
    updatedAt: "",
    prefix: "",
    alloted_wfh_per_week: 0,
    currentAddress:"",
    permanentAddress:"",
    business_unitaddress:"",
  });
  useEffect(() => {
    fetchEditEmployee(id)
      .then((data) => {
        if (data.result) {
          setEmployeeData({
            empCode: data?.result?.employee_code || "",
            empId: data?.result?.employee_id || "",
            empName: data?.result?.firstName || "",
            empLastName: data?.result?.lastName || "",
            email: data?.result?.email || "",
            code: data?.result?.country_code || "",
            number: data?.result?.phone || "",
            business_unit: data?.result?.Group?.name || "",
            role: data?.result?.Role?.role_name || "",
            department: data?.result?.Department?.name || "",
            designation: data?.result?.Designation?.name || "",
            team_manager_firstName: data?.result?.team_manager?.firstName || "",
            team_manager_lastName: data?.result?.team_manager?.lastName || "",
            reporting_manager_firstName:
              data?.result?.reporting_manager?.firstName || "",
            reporting_manager_lastName:
              data?.result?.reporting_manager?.lastName || "",
            status: data?.result?.status || "",
            MoE: data?.result?.mode_of_employment || "",
            YOE: data?.result?.experience || "",
            DOB: data?.result?.date_of_birth || "",
            DOJ: data?.result?.date_of_joining || "",
            DOL: data?.result?.date_of_leaving || null,
            profile_image: data?.result?.profile_image || null,
            location: data?.result?.location || null,
            employeeType: data?.result?.type || "",
            bloodGroup: data?.result?.blood_group || null,
            shiftId: data?.result?.shift_id || null,
            isActive: data?.result?.is_active || false,
            createdAt: data?.result?.createdAt || "",
            updatedAt: data?.result?.updatedAt || "",
            prefix: data?.result?.prefix || "",
            alloted_wfh_per_week: data?.result?.alloted_wfh_per_week || 0,
            currentAddress: data?.result?.current_address || "",
            permanentAddress: data?.result?.permanent_address || "",
            business_unitaddress: data?.result?.Group?.business_Unitaddress,
          });
        }
      })
      .catch((error) => console.error(error));
  }, [id]);

  return (
    <div className="mt-2 flex border border-[#E5E7EB] rounded-md">
      <div className="w-[15%] border-r border-[#E5E7EB] flex flex-col gap-3">
        {tabs.map((tab) => (
          <div
            key={tab.value}
            onClick={() => setSelected(tab.value)}
            className={`flex items-center text-sm font-medium text-dark-grey px-3 py-3 w-full cursor-pointer bg-custom-gray ${
              selected === tab.value ? "border-l-4 border-blue-700" : ""
            } rounded-sm`}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="w-[85%] h-[60vh] overflow-auto">
        {selected === "personal" ? (
          <PersonalEmpDetails id={id} employeeData={employeeData} />
        ) : (
          <div className="flex justify-center items-center h-full w-full">
            <img
              src={noResult}
              alt="no-result"
              className="w-[250px] h-[250px]"
            />
          </div>
        )}
      </div>
    </div>
  );
}
