import React from "react";
import { useNavigate } from "react-router-dom";

import { Rating, TextField } from "@mui/material";

import profile from "../../assets/Ellipse 580.png";
import right from "../../image/chevron-right.png";
import star from "../../assets/star.svg";
import { Button } from "../common/Button";

function ManagerEdit() {
  const navigate = useNavigate();
  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex justify-between items-start w-full h-10">
        <div className="flex gap-[13px]">
          <button onClick={() => navigate("/appraisal-management")} 
            title="none">
            <img
              src={right}
              alt=""
              className="h-5 w-5 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="font-[Poppins] font-semibold text-[18px] leading-[27px] text-[#1D1A22]">
            Edit Feedback
          </span>
        </div>
        <div className="flex gap-3 items-center">
          <button
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            style={{ border: "2px solid var(--22, #4165C4)" }}
            onClick={() => navigate("/appraisal-management")}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
            Cancel</div>
          </button>
          <Button
            variant={"gradient"}
            onClick={() => navigate("/appraisal-management")}
          >
            <div 
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              >
              Update
            </div>
          </Button>
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-2.5">
        <div className="px-5 py-4 rounded-[9.6px] shadow-custom h-32 bg-white">
          <div className="flex justify-between w-full items-end h-full">
            <div className="flex flex-col h-full justify-between">
              <span className="font-[Poppins] font-medium text-[16px] leading-[16px]">
                Employee Response
              </span>
              <div className="flex justify-center items-center py-2.5 pl-2.5 pr-14 rounded-md viewBackground">
                <div className="flex items-center gap-2 5">
                  <img
                    src={profile}
                    alt="profile image"
                    className="h-10 w-10"
                  />
                  <div className="flex flex-col">
                    <span className="font-[Poppins] font-medium text-[14px] leading[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-[Poppins] font-medium text-[18px] leading-[27px] text-[#79747E]">
                Business Unit:{" "}
                <span className="text-[#1D1A22]">Appvin, Noida</span>
              </p>
              <p className="font-[Poppins] font-medium text-[18px] leading-[27px] text-[#79747E]">
                Department: <span className="text-[#1D1A22]">Design</span>
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-[Poppins] font-medium text-[18px] leading-[27px] text-[#79747E]">
                Due Date: <span className="text-[#1D1A22]">23 Aug 2024</span>
              </p>
              <div className="font-[Poppins] font-medium text-[18px] leading-[27px] text-[#79747E] flex gap-1">
                Status:{" "}
                <span className="flex justify-center items-center max-w-min py-[1px] px-3 bg-[#D4F8C7]">
                  <span className="font-[Poppins] font-medium text-[14px] leading-[24px] tracking-[0.15px] text-[#262626]">
                    Completed
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto shadow-sm rounded-[3px]">
          <table className="overflow-hidden w-full text-left table-auto bg-white border border-[#E0E0E0]">
            <thead className="rounded-t-[3px] border border-[#E0E0E0]">
              <tr className="w-full">
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5 pl-3">
                  KPI
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5 pl-3">
                  Rating
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                  Comments
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                  Rating
                </th>
                <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                  Comments
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="w-full hover:bg-gray-100">
                <td className="pl-3 py-2.5 font-[Poppins] font-medium text-[12px] leading-[15.2px] text-[#49454F]">
                  Lorem ipsum dolor sit amet, consectetur incididunt ut labore
                  et?
                </td>
                <td className="h-full pl-3 py-2.5 font-[Poppins] font-medium text-[12px] leading-[15.2px] text-[#49454F]">
                  <div className="h-full bg-[#EDEDED] rounded-3xl px-3 py-1.5 flex justify-between items-center gap-1">
                    <p>4</p>
                    <img src={star} alt="rating" />
                  </div>
                </td>
                <td className="py-2.5 font-[Poppins] font-medium text-[14px] leading-[15.2px] text-[#49454F]5">
                  <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                    Lorem ipsum dolor sit amet, consectetur incididunt ut labore
                    et?
                  </span>
                </td>
                <td className="py-2.5">
                  <Rating name="size-large" size="large" />
                </td>
                <td className="py-2.5">
                  <TextField />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ManagerEdit;
