
import React, { useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import AddResources from "./AddResources";
import AddProjectDetails from "./AddProjectDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddTask from "./AddTask";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { setTaskPhase } from "../../../../redux/reducers/ProjectManagement/TaskSlice";
import { TaskCards } from "../../TaskCards";
import { TaskType } from "../../TaskType";
import { setProjectTab } from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import toast from "react-hot-toast";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const param = useParams();
  const navigate = useNavigate();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const CustomTabs: React.FC = () => {
  const projectTab = useSelector(
    (state: any) => state.projectSlice2.project_tab
  );
  const [value, setValue] = React.useState(projectTab ?? 0);

  useEffect(() => {
    setValue(projectTab);
  }, [projectTab]);

  const location = useLocation();
  const history = useNavigate();
  // const { taskPhase } = useSelector((state: RootState) => state?.task);
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const dispatch = useDispatch();
  // const param = useParams<{ id: string }>();

  // useEffect(() => {
  //   if (location.pathname === "/add-project") {
  //     setValue(0);
  //   } else if (/^\/add-resources\/[^/]+$/.test(location.pathname)) {
  //     setValue(1);
  //   } else if (/^\/add-task\/[^/]+$/.test(location.pathname)) {
  //     setValue(2);
  //   }
  // }, [location.pathname]);

  // const param = useParams();

  // const fetch_resources = async () => {
  //   try {
  //     const response = await Fetch_Resources_List(
  //       param.id,
  //       "",
  //       10,
  //       1,
  //     );
  //     if (response.status === 200) {
  //       console.log(response.data)
  //     } else {
  //       // console.error("Error:", response.status, response.statusText);
  //     }
  //   } catch (error) {
  //     // console.error("Error:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetch_resources();
  // }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // if (newValue === 0) {
    //   history("/add-project");
    // } else if (newValue === 1) {
    //   history("/add-resources");
    // } else if (newValue === 2) {
    //   history("/add-task");
    // }
  };
  // const handleBack = (id?: string) => {
  //   if (taskPhase) {
  //     dispatch(setTaskPhase(null));
  //   } else {
  //     if (id) {
  //       history(`/add-resources/${id}`);
  //     }
  //   }
  // };
  // const handleSelectPhase = (phase: string) => {
  //   dispatch(setTaskPhase(phase)); // Dispatch the action to update the Redux state
  // };

  

  const Label = ({ number, title, description, isSelected }: any) => {
    return (
      <div
        className={`flex items-left w-full px-2 py-3 rounded-md transition-all
          // isSelected
          //   ? "bg-gradient-to-r from-[#4A00E0] to-[#8E2DE2] text-white"
          //   : "bg-[#E8E0FF] text-[#374151]"
        `}
      >
        <div
          className={`w-10 h-10 flex justify-center items-center`}
          // style={{ borderColor: isSelected ? "white" : "var(--22, #4165C4)" }}
        >
          <span className="w-10 h-10 flex items-center text-[#4165C4] bg-[#FFFFFF] justify-center shrink-0 rounded-sm text-base font-bold">
            {number}
          </span>
        </div>
        <div className="ml-3 flex flex-col gap-[4px]">
          <span
            className={`font-[Poppins] font-medium text-base leading-[20.5px] text-left ${
              isSelected ? "text-white" : "text-black"
            }`}
          >
            {title}
          </span>
          <span
            className={`font-[Poppins] font-normal text-xs text-left ${
              isSelected ? "text-white" : "text-black"
            }`}
          >
            {description}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-[90vh] w-full px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <Box>
        <Tabs
          value={value}
          // onChange={handleChange}
          aria-label="full width tabs example"
          variant="fullWidth"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .Mui-selected": {
              background: "linear-gradient(90deg, #4165c4 0%, #8e1fd2 100%);",
              color: "#fff",
            },
            background: "#E8E0FF",
            borderRadius: "6px",
          }}
        >
          <Tab
            label={
              <Label
                number="1"
                title="Project"
                description="Configure the project details"
                isSelected={value === 0}
              />
            }
            {...a11yProps(0)}
            className="text-lg"
            style={{ textTransform: "none" }}
            // onClick={() => {
            //   if (value !== 0) {
            //     dispatch(setProjectTab(0));
            //     // history("/add-project" ,
            //     //   {state: { id: projectId }},
            //     // );
            //   }
            // }}
            onClick={() => {
              if (value !== 0 && projectId) {
                dispatch(setProjectTab(0));
                // history(`/add-resources/${projectId}`)
              } else if (value !== 0 && !projectId) {
                toast.error("Please fill all project details.");
              }
            }}
          />
          <Tab
            label={
              <Label
                number="2"
                title="Resources"
                description="Add resources & billable cost/hour"
                isSelected={value === 1}
                // onClick={() => {
                //   if (value !== 1) {
                //     history("/add-resources", {
                //       state: { id: param.id },
                //     });
                //   }
                // }}
              />
            }
            {...a11yProps(1)}
            className="text-lg"
            style={{ textTransform: "none" }}
            // onClick={() => {
            //   if (value !== 1) {
            //     history("/add-resources", {
            //       state: { id: param.id },
            //     });
            //   }
            // }}
            onClick={() => {
              if (value !== 1 && projectId) {
                dispatch(setProjectTab(1));
                // history(`/add-resources/${projectId}`)
              } else {
                toast.error("Please fill all project details.");
              }
            }}
            // history(`/add-resources/${param.id}`)}
          />
          <Tab
            label={
              <Label
                number="3"
                title="Tasks"
                description="Add the tasks and assign to the resources"
                isSelected={value === 2}
              />
            }
            {...a11yProps(2)}
            className="text-lg"
            style={{ textTransform: "none" }}
            onClick={() => {
              if (value !== 2 && projectId) {
                dispatch(setProjectTab(2));
                // history(`/add-resources/${projectId}`);
              } else {
                toast.error("Please fill all project details");
              }
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AddProjectDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddResources />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* {taskPhase ? (
            <TaskType phase={taskPhase} onBack={handleBack} />
          ) : (
            <TaskCards onSelectPhase={handleSelectPhase} onBack={handleBack} />
          )} */}
          <AddTask />
        </TabPanel>
      </Box>
    </div>
  );
};

export default CustomTabs;
