import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import Project from "./Project";
import Clients from "./Clients";
import "../EmployeeManagement/employee.css";
import maskPlus from "../../image/Mask group.svg";
import CustomTabs from "../common/CustomTabs";

import { useDispatch } from "react-redux";
import { Button } from "../common/Button";
import ExpenseOverview from "./ExpenseOverview";
import filter from "../../assets/filter.svg";
import ExpenseFilter, { FilterData } from "./ExpenseFilter";
import { setMainTabValue } from "../../redux/reducers/Tab/TabSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setFilterExpenseData } from "../../redux/reducers/Expense/ExpenseFilter";
import { Close } from "@mui/icons-material";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";

export const ExpenseManagement = () => {
  const history = useNavigate();
  const value = useSelector((state: RootState) => state.mainTabValue.value);
  const [isOpen, setIsOpen] = useState(false);
  const filter_data = useSelector(
    (state: RootState) => state.expenseFilterSlice
  );

  //const [value, setValue] = useState("1");
  const location = useLocation();
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(
      setFilterExpenseData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
        search: "",
      })
    );
    dispatch(setMainTabValue(newValue));
  };
  const handleDelete = () => {
    dispatch(
      setFilterExpenseData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
  };
  const tabs = [
    { label: "Overview", value: "1" },
    { label: "Expense", value: "2" },
    { label: "Expense Type", value: "3" },
  ];

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterExpenseData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setIsOpen(false);
  };

  return (
    <>
      <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-white">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box>
                  <CustomTabs tabs={tabs} onChange={handleChange} />
                </Box>
                {value === "1" ? (
                  <div className="flex gap-3">
                    {filter_data?.group ||
                    filter_data?.department ||
                    (filter_data?.date?.[0] && filter_data?.date?.[1]) ? (
                      <button
                        onClick={handleDelete}
                        className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                      >
                        <Close
                          sx={{
                            fontSize: "15px",
                          }}
                        />
                        Clear All
                      </button>
                    ) : null}
                    <Button onClick={() => setIsOpen(true)} variant={"border"}>
                      <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                      Filter
                    </Button>
                  </div>
                ) : null}

                {value === "2" ? (
                  <div>
                    <Button
                      onClick={() => history("/add-expense")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Expense
                    </Button>
                  </div>
                ) : value === "3" ? (
                  <div>
                    <Button
                      onClick={() => history("/add-client")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Expense Type
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <TabPanel className="tabpanel" value="1">
                <ExpenseOverview />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <Project />
              </TabPanel>
              <TabPanel className="tabpanel" value="3">
                <Clients />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      {isOpen && (
        <ExpenseFilter
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          heading="Expense Filter"
          onApply={handleApply}
        />
      )}
    </>
  );
};
