import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeBusinessUnit } from "../../../redux/actions/Employee/Employee";
import { RootState } from "../../../redux/store";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface BusinessUnit {
  id: number;
  name: string;
}

export default function BusinessUnitSelect({
  selectedBusinesses,
  setSelectedBusinesses,
}: {
  selectedBusinesses: number[];
  setSelectedBusinesses: (value: number[]) => void;
}) {
  const myBusId = useSelector(
    (state: RootState) => state?.employee?.business_unit
  );
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const dispatch = useDispatch();
  const [business, setBusiness] = React.useState<BusinessUnit[]>([]);

  const customer_id = loginData?.customer_id || 0;

  React.useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const response = await dispatch<any>(EmployeeBusinessUnit(customer_id));
        if (response?.result) {
          const uniqueBusinessUnits: BusinessUnit[] = Array?.from(
            new Map<number, BusinessUnit>(
              response?.result?.map((bu: BusinessUnit) => [bu?.id, bu])
            )?.values()
          );

          setBusiness(uniqueBusinessUnits);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    fetchBusinessUnits();
  }, [dispatch, customer_id]);

  React.useEffect(() => {
    if (myBusId && selectedBusinesses?.length === 0) {
      setSelectedBusinesses([myBusId]);
    }
  }, [myBusId, selectedBusinesses]);

  const isAllSelected =
    business?.length > 0 && selectedBusinesses?.length === business?.length;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    let value = event.target.value as string[];
    let selectedValues = value.map(Number);

    if (selectedValues.indexOf(-1) !== -1 && myBusId) {
      const allIds = business.map((b) => b.id);
      setSelectedBusinesses(isAllSelected ? [myBusId] : [...allIds]);
      return;
    }

    if (selectedBusinesses.length === 1 && selectedValues.length === 0) {
      return;
    }
    setSelectedBusinesses(selectedValues);
  };

  return (
    <FormControl sx={{ background: "#fff", height: "45px" }}>
      <Select
        id="demo-multiple-checkbox"
        multiple
        value={selectedBusinesses.map(String)}
        onChange={handleChange}
        input={<OutlinedInput />}
        sx={{
          height: "100%",
          "& .MuiSelect-select": {
            height: "40px",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0",
          },
        }}
        renderValue={(selected) => {
          const selectedUnits = selected
            ?.map((id) => business.find((b) => b?.id === Number(id)))
            ?.filter((b) => b !== undefined) as BusinessUnit[];

          if (selectedUnits?.length === 1) {
            return (
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 400,
                  fontStyle: "normal",
                  color: "#1F2937",
                  paddingX: 0.5,
                }}
              >
                {selectedUnits[0]?.name}
              </Box>
            );
          }

          const displayNames = selectedUnits?.slice(0, 1)?.map((b) => b?.name);
          const moreCount = selectedUnits?.length - 1;

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {displayNames.map((name, index) => (
                <Chip
                  key={index}
                  label={name}
                  sx={{
                    borderRadius: "4px",
                    color: "#1F2937",
                    backgroundColor: "#F3F4F6",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                />
              ))}
              {moreCount > 0 && (
                <Chip
                  label={`${moreCount}+`}
                  sx={{
                    color: "#4165C4",
                    borderRadius: "4px",
                    borderColor: "#d1d5db",
                    backgroundColor: "#F3F4F6",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    fontStyle: "normal",
                  }}
                />
              )}
            </Box>
          );
        }}
        // MenuProps={MenuProps}
      >
        {/* Select All Option */}
        <MenuItem key="all" value="-1">
          <Checkbox checked={isAllSelected} />
          <ListItemText primary="Select All" />
        </MenuItem>

        {/* Business Units */}
        {business.map((unit) => (
          <MenuItem key={unit.id} value={String(unit.id)}>
            <Checkbox checked={selectedBusinesses.includes(unit.id)} />
            <ListItemText primary={unit.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
