import { useEffect, useRef, useState } from "react";
import kebab_menu from "../../assets/images/kebab_menu.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { revertAll } from "../../redux/ResetRedux/RevertAll";
import downarrow from "../../assets/down_arrow.svg";
import uparrow from "../../assets/up_arrow.svg";
import moment from "moment";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import bussiness_man_icon from "../../assets/avatar.svg";


const Header = ({ onSessionChange }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);
  // const userProfileData = useSelector((state: any) => state.userProfile);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [startYear, setStartYear] = useState(0);
  const [endYear, setEndYear] = useState(0);
  const [selected1, setSelected1] = useState("");
  const [startYear1, setStartYear1] = useState(0);
  const [endYear1, setEndYear1] = useState(0);
  const [sessionStart, setSessionStart] = useState("");
  const [sessionEnd, setSessionEnd] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const selected_resource_id = useSelector(
    (state: any) => state.SideBar.selected_resource_id
  );
  const startingYear = 2021;
  const currentYear = new Date().getFullYear();

  // const options = [
  //   { session: "2021-22", startYear: 2021, endYear: 2022 },
  //   { session: "2022-23", startYear: 2022, endYear: 2023 },
  //   { session: "2023-24", startYear: 2023, endYear: 2024 },
  //   { session: "2024-25", startYear: 2024, endYear: 2025 },
  //   { session: "2025-26", startYear: 2025, endYear: 2026 },
  // ];

  const options = Array.from(
    { length: currentYear - startingYear + 1 },
    (_, i) => {
      const year = startingYear + i;
      return {
        session: `${year}-${(year + 1).toString().slice(-2)}`,
        startYear: year,
        endYear: year + 1,
      };
    }
  );

  // const optionsYear = [
  //   { session: "2021", startYear: 2021, endYear: 2021 },
  //   { session: "2022", startYear: 2022, endYear: 2022 },
  //   { session: "2023", startYear: 2023, endYear: 2023 },
  //   { session: "2024", startYear: 2024, endYear: 2024 },
  //   { session: "2025", startYear: 2025, endYear: 2025 },
  // ];

  const optionsYear = Array.from(
    { length: currentYear - startingYear + 1 },
    (_, i) => {
      const year = startingYear + i;
      return {
        session: `${year}`,
        startYear: year,
        endYear: year,
      };
    }
  );

  const toggleSessionDropdown = () => setIsOpen(!isOpen);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef1 = useRef<HTMLDivElement>(null);

  const handleClickOutside1 = (event: MouseEvent) => {
    if (
      dropdownRef1.current &&
      !dropdownRef1.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const logOut = () => {
    localStorage.clear();
    // dispatch(authActions.reset());
    dispatch(revertAll());
    navigate("/");
  };

  useEffect(() => {
    const storedYear = localStorage.getItem("selectedYear");
    if (storedYear) {
      const parsedSession = JSON.parse(storedYear);
      setSelected1(parsedSession.session);
      setStartYear1(parsedSession.startYear);
      setEndYear1(parsedSession.endYear);
    } else {
      const currentMonth1 = new Date().getMonth();
      const currentYear1 = new Date().getFullYear();
      let defaultStartYear1;
      let defaultEndYear1;

      defaultStartYear1 = currentYear1;
      defaultEndYear1 = currentYear1;

      const defaultSession1 = `${defaultStartYear1}`;
      setSelected1(defaultSession1);
      setStartYear1(defaultStartYear1);
      setEndYear1(defaultEndYear1);
    }

    const storedSession = localStorage.getItem("selectedSession");
    console.log({ storedSession });
    if (storedSession === "") {
      setSelected("All Sessions");
      setStartYear(0);
      setEndYear(0);

      localStorage.setItem("start_session", "");
      localStorage.setItem("end_session", "");

      return;
    }
    if (storedSession) {
      const parsedSession = JSON.parse(storedSession);
      setSelected(parsedSession.session);
      setStartYear(parsedSession.startYear);
      setEndYear(parsedSession.endYear);
    } else {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      let defaultStartYear;
      let defaultEndYear;

      if (currentMonth >= 3) {
        // April or later
        defaultStartYear = currentYear;
        defaultEndYear = currentYear + 1;
      } else {
        // January, February, March
        defaultStartYear = currentYear - 1;
        defaultEndYear = currentYear;
      }

      // localStorage.setItem("start_session", moment(defaultStartYear).format("YYYY-MM-DD"));
      // localStorage.setItem("end_session", moment(defaultEndYear).format("YYYY-MM-DD"));
      const defaultSession = `${defaultStartYear}-${defaultEndYear
        .toString()
        .slice(-2)}`;
      setSelected(defaultSession);
      setStartYear(defaultStartYear);
      setEndYear(defaultEndYear);
      // dispatch(setSessionStartR(defaultStartYear));
      // dispatch(setSessionEndR(defaultEndYear));
    }

    // if (selected_resource_id === 10) {
    //   const storedYear = localStorage.getItem("selectedYear");
    //   if (storedYear) {
    //     const parsedSession = JSON.parse(storedYear);
    //     setSelected1(parsedSession.session);
    //     setStartYear1(parsedSession.startYear);
    //     setEndYear1(parsedSession.endYear);  localStorage.setItem("start_session", "");

    //   } else {
    //     // Determine the current session
    //     const currentMonth = new Date().getMonth(); // January is 0!
    //     const currentYear = new Date().getFullYear();
    //     let defaultStartYear;
    //     let defaultEndYear;

    //     defaultStartYear = currentYear;
    //     defaultEndYear = currentYear;
    //     // if (currentMonth >= 0) {
    //     //   // April or later
    //     //   defaultStartYear = currentYear;
    //     //   defaultEndYear = currentYear;
    //     // } else {
    //     //   // January, February, March
    //     //   defaultStartYear = currentYear;
    //     //   defaultEndYear = currentYear;
    //     // }

    //     const defaultSession = `${defaultStartYear}`;
    //     setSelected1(defaultSession);
    //     setStartYear1(defaultStartYear);
    //     setEndYear1(defaultEndYear);
    //   }
    // } else {
    //   const storedSession = localStorage.getItem("selectedSession");
    //   if (storedSession) {
    //     const parsedSession = JSON.parse(storedSession);
    //     setSelected(parsedSession.session);
    //     setStartYear(parsedSession.startYear);
    //     setEndYear(parsedSession.endYear);
    //   } else {
    //     // Determine the current session
    //     const currentMonth = new Date().getMonth(); // January is 0!
    //     const currentYear = new Date().getFullYear();
    //     let defaultStartYear;
    //     let defaultEndYear;

    //     if (currentMonth >= 3) {
    //       // April or later
    //       defaultStartYear = currentYear;
    //       defaultEndYear = currentYear + 1;
    //     } else {
    //       // January, February, March
    //       defaultStartYear = currentYear - 1;
    //       defaultEndYear = currentYear;
    //     }

    //     const defaultSession = `${defaultStartYear}-${defaultEndYear
    //       .toString()
    //       .slice(-2)}`;
    //     setSelected(defaultSession);
    //     setStartYear(defaultStartYear);
    //     setEndYear(defaultEndYear);
    //   }
    // }
  }, []);

  useEffect(() => {
    if (startYear && endYear) {
      const start = new Date(startYear, 3, 1); // April 1st
      const end = new Date(endYear, 2, 31); // March 31st
      localStorage.setItem("start_session", moment(start).format("YYYY-MM-DD"));
      localStorage.setItem("end_session", moment(end).format("YYYY-MM-DD"));
      setSessionStart(moment(start).format("YYYY-MM-DD"));
      setSessionEnd(moment(end).format("YYYY-MM-DD"));
      onSessionChange(
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD")
      );
    }

    if (startYear1 && endYear1) {
      const start1 = new Date(startYear1, 0, 1);
      const end1 = new Date(endYear1, 11, 31);
      localStorage.setItem("start_year", moment(start1).format("YYYY-MM-DD"));
      localStorage.setItem("end_year", moment(end1).format("YYYY-MM-DD"));
      setSessionStart(moment(start1).format("YYYY-MM-DD"));
      setSessionEnd(moment(end1).format("YYYY-MM-DD"));
      onSessionChange(
        moment(start1).format("YYYY-MM-DD"),
        moment(end1).format("YYYY-MM-DD")
      );
    }

    // if (startYear && endYear && selected_resource_id !== 10) {
    //   const start = new Date(startYear, 3, 1); // April 1st
    //   const end = new Date(endYear, 2, 31); // March 31st
    //   localStorage.setItem("start_session", moment(start).format("YYYY-MM-DD"));
    //   localStorage.setItem("end_session", moment(end).format("YYYY-MM-DD"));
    //   setSessionStart(moment(start).format("YYYY-MM-DD"));
    //   setSessionEnd(moment(end).format("YYYY-MM-DD"));
    //   onSessionChange(
    //     moment(start).format("YYYY-MM-DD"),
    //     moment(end).format("YYYY-MM-DD")
    //   );
    // } else if (startYear1 && endYear1 && selected_resource_id === 10) {
    //   const start = new Date(startYear1, 0, 1);
    //   const end = new Date(endYear1, 11, 31);
    //   localStorage.setItem("start_year", moment(start).format("YYYY-MM-DD"));
    //   localStorage.setItem("end_year", moment(end).format("YYYY-MM-DD"));
    //   setSessionStart(moment(start).format("YYYY-MM-DD"));
    //   setSessionEnd(moment(end).format("YYYY-MM-DD"));
    //   onSessionChange(
    //     moment(start).format("YYYY-MM-DD"),
    //     moment(end).format("YYYY-MM-DD")
    //   );
    // }
  }, [
    startYear,
    endYear,
    startYear1,
    endYear1,
    onSessionChange,
    selected_resource_id,
  ]);

  const handleSessionClick = (option: any) => {
    if (option === "") {
      window.location.reload();
      setSelected("All Sessions");
      setStartYear(0);
      setEndYear(0);
      localStorage.setItem("selectedSession", "");
      setIsOpen(false);
      return;
    }
    window.location.reload();
    setSelected(option.session);
    setStartYear(option.startYear);
    setEndYear(option.endYear);
    localStorage.setItem("selectedSession", JSON.stringify(option));
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleSessionClick1 = (option: any) => {
    window.location.reload();
    setSelected1(option.session);
    setStartYear1(option.startYear);
    setEndYear1(option.endYear);
    localStorage.setItem("selectedYear", JSON.stringify(option));
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="flex sticky top-0 z-20 justify-center items-end py-1.5 px-5 bg-white border-solid shadow-sm border-b-[0.8px] border-b-slate-200 border-b-opacity-80 max-md:px-5 max-md:max-w-full">
      <div className="flex justify-between items-center w-full">
        <div ref={dropdownRef1} className="h-11 w-[240px]">
          {selected_resource_id === 10 ? (
            <div className="relative inline-block w-full">
              <FormControl
                sx={{
                  width: "auto",
                  backgroundColor: "white",
                  borderRadius: "12px",
                }}
              >
                <TextField
                  id="outlined-required"
                  select
                  name="status"
                  value={selected1 || "Select"}
                  sx={{
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      minWidth: 80,
                      borderRadius: "12px",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#6B7280",
                      "& fieldset": {
                        border:
                          "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
                      },
                      "&:hover fieldset": {
                        borderColor:
                          "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important",
                        borderWidth: "1px",
                      },
                    },
                  }}
                  onChange={(e) => {
                    const selectedOption = optionsYear.find(
                      (option) => option.session === e.target.value
                    );
                    if (selectedOption) {
                      handleSessionClick1(selectedOption);
                    }
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{ fontSize:"16px",  fontWeight: "400", color: "#1F2937" , fontFamily:"poppins" }}
                        >
                          Year
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                >
                  {optionsYear.map((option) => (
                    <MenuItem key={option.session} value={option.session}>
                      {option.session}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              {/* {isOpen && (
                <div className="absolute left-0 max-h-60 overflow-auto z-10 mt-1 w-[150px]  bg-white border border-[#F2F4F7] rounded-lg shadow-lg">
                  <ul className="font-[Poppins]">
                    {optionsYear.map((option) => (
                      <li
                        key={option.session}
                        className={`font-[Inter] font-medium text-base text-[#101828] pl-5 py-2 cursor-pointer hover:bg-gray-100 ${
                          selected1 === option.session ? "bg-gray-100" : ""
                        }`}
                        onClick={() => handleSessionClick1(option)}
                      >
                        {option.session}
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}
            </div>
          ) : (
            <div className="relative inline-block w-full">
              <FormControl
                sx={{
                  width: "auto",
                  backgroundColor: "white",
                  borderRadius: "12px",
                }}
              >
                <TextField
                  id="outlined-required"
                  select
                  name="session"
                  value={selected || "All Session"}
                  sx={{
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      minWidth: 80,
                      borderRadius: "12px",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#6B7280",
                      "& fieldset": {
                        border:
                          "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
                      },
                      "&:hover fieldset": {
                        borderColor:
                          "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important",
                        borderWidth: "1px",
                      },
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value === "All Sessions") {
                      handleSessionClick("");
                      return;
                    }
                    const selectedOption = options.find(
                      (option) => option.session === e.target.value
                    );
                    if (selectedOption) {
                      handleSessionClick(selectedOption || "");
                    }
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{ fontSize:"16px",  fontWeight: "400", color: "#1F2937" , fontFamily:"poppins" }}
                          >
                          Session
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem key="All sessions" value="All Sessions">
                    All Sessions
                  </MenuItem>

                  {options.map((option) => (
                    <MenuItem key={option.session} value={option.session}>
                      {option.session}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              {/* <div
                className="borderButton rounded-lg flex pl-[14px] items-center cursor-pointer w-full h-11"
                style={{ borderColor: "#EDEDED" }}
                onClick={toggleSessionDropdown}
              >
                <div className="flex gap-2">
                  <span className="font-[Inter] font-medium text-base text-[#101828]">
                    Session
                  </span>
                  <span className="font-[Inter] font-normal text-base text-[#475467]">
                    {selected || "Select"}
                  </span>
                </div>
                {isOpen ? (
                  <img src={uparrow} alt="up arrow" className="pl-1" />
                ) : (
                  <img src={downarrow} alt="down arrow" className="pl-1" />
                )}
              </div>
              {isOpen && (
                <div className="absolute left-0 max-h-60 z-10 mt-1 w-[240px]  bg-white border border-[#F2F4F7] rounded-lg shadow-lg">
                  <ul className="font-[Poppins]">
                    <li
                      key={"all"}
                      className={`font-[Inter] font-medium text-base text-[#101828] pl-5 py-2 cursor-pointer hover:bg-gray-100 ${
                        selected === "All sessions" ? "bg-gray-100" : ""
                      }`}
                      onClick={() => handleSessionClick("")}
                    >
                      All Sessions
                    </li>
                    {options.map((option) => (
                      <li
                        key={option.session}
                        className={`font-[Inter] font-medium text-base text-[#101828] pl-5 py-2 cursor-pointer hover:bg-gray-100 ${
                          selected === option.session ? "bg-gray-100" : ""
                        }`}
                        onClick={() => handleSessionClick(option)}
                      >
                        {option.session}
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}
            </div>
          )}
        </div>

        <div className="flex gap-2.5 max-md:mr-2">
          <div className="flex justify-center items-center aspect-square">
            <img
              loading="lazy"
              // src={profile_pic}
              src={ loginData?.profile_image ?
                loginData?.profile_image || "https://via.placeholder.com/150"  :
                bussiness_man_icon
              }
              className="w-11 rounded-full aspect-square bg-gray-500"
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center font-semibold whitespace-nowrap h-full">
            {loginData && loginData?.first_name
              ? `${loginData?.first_name} ${loginData?.last_name}`
              : signupData && signupData?.sign_up_details?.firstName
              ? `${signupData?.sign_up_details?.firstName} ${signupData?.sign_up_details?.lastName}`
              : "NA"}
            <div className="text-xs leading-5 text-teal-900">
              {loginData && loginData?.role ? loginData?.role : "NA"}
            </div>
          </div>

          <div ref={dropdownRef} className="relative">
            <button
              onClick={toggleDropdown}
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              type="button"
            >
              <img
                loading="lazy"
                src={kebab_menu}
                className="w-8 h-7 rounded-full aspect-square mt-2"
              />
            </button>
            {/* Dropdown menu  */}
            {dropdownOpen && (
              <div
                id="dropdown"
                className="absolute right-0 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  {/* <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Contact Support
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="#"
                      onClick={logOut}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
