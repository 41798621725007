import React, { useCallback, useEffect, useRef, useState } from "react";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import eye from "../../assets/Crud_Icons/view.svg";
import {
  Fetch_Project_List,
  Fetch_Status_List,
} from "../common/services/Project";
import Search from "../SearchBar/Search";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import CustomTabs from "../common/CustomTabs";
import { TabContext, TabPanel } from "@mui/lab";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import {
  api_key_work as api_key,
  api_key_work,
  base_api,
} from "../../utils/helperData";
import del from "../../assets/Crud_Icons/delete.svg";
import { RootState } from "../../redux/store";
import { MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setProjectId,
  resetProjectId,
  resetViewProjectTab,
  resetProjectStartDate,
  resetProjectEndDate,
  resetProjectTab,
} from "../../redux/reducers/ProjectManagement/ProjectSlice2";
import { clearAllFilters } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import filter from "../../assets/filter.svg";
import { Close } from "@mui/icons-material";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import ProjectFilter, { ProjectFilterData } from "./ProjectFilter";
import axiosInstance from "../../app/api/axiosInstance";
import moment from "moment";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface IProjectList {
  id: number;
  project_name: string;
  project_type: string;
  project_status: string;
  createdAt: string;
  updatedAt: string;
  Client: {
    client_name: string;
  };
  ProjectResources: ProjectResource[];
  status: string;
  billing_type?: string;
  start_date: string;
  end_date: string;
}

interface ProjectResource {
  id: number;
  employee_type: string;

  User: {
    firstName: string;
    lastName: string;
    employee_id: String;
  };
}
interface IProjectData {
  id: string;
  statusCounts: {
    upcoming: number;
    ongoing: number;
    completed: number;
    onhold: number;
    initiated: number;
    "in-progress": number;
    delayed: number;
  };
  projectStatusCounts: {
    Delay: number;
    "On time": number;
  };
}
const tabs = [
  { label: "All Projects", value: "" },
  { label: "Ongoing", value: "ongoing" },
  { label: "On Hold", value: "onhold" },
  { label: "Completed", value: "completed" },
  { label: "Upcoming Projects", value: "upcoming" },
  { label: "Delayed Projects", value: "Delay" },
];
const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [statusList, setStatusList] = useState<IProjectData>();
  const [count, setCount] = useState(0);
  const [updated, setUpdated] = useState<boolean>(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IProjectList[]>([]);
  const [value, setValue] = React.useState<string>("");

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IProjectList | null>(null);

  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  const [selectedTab, setSelectedTab] = useState<string | undefined>(
    filter_data?.status
  );
  const [isOpen, setIsOpen] = useState(false);
  const [project, setProject] = useState<string | null>(
    filter_data?.project ?? null
  );
  const [billing, setBilling] = useState<string | null>(
    filter_data?.billing ?? null
  );
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );

  let customer_id = 0;
  let access_token = localStorage.getItem("access_token");

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const fetchProjectsStats = async () => {
    try {
      const response = await Fetch_Status_List({});
      if (response.status === 200) {
        setStatusList(response.data?.result);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteClick = (item: IProjectList) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const DeleteConfirmationModal: React.FC<{
    onDelete: () => void;
    onCancel: () => void;
  }> = ({ onDelete, onCancel }) => {
    return (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-8 rounded shadow-lg">
          <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
            Delete Project
          </h2>
          <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
            Are you sure you want to delete this item?
          </h4>
          <div className="flex space-x-4 justify-center">
            <Button
              variant={"gradient"}
              onClick={onDelete}
              className=" rounded mr-2 "
            >
              Yes Sure
            </Button>
            <button
              onClick={onCancel}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      console.error("No access token found");
      return;
    }

    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/project/${selectedItem?.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.ok) setUpdated(!updated);
        else
          throw new Error(`Failed to delete project: ${response.statusText}`);
      } catch (error) {
        console.error("Error deleting project:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
        fetchProjectsList();
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const columns: ColumnDef<IProjectList>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },

    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "category",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Project Manager
        </Button>
      ),

      cell: ({ row }) => {
        const managers = row?.original?.ProjectResources?.filter(
          (item) => item?.employee_type === "Manager" && item?.User
        )?.map((item) => ({
          name: `${item?.User?.firstName} ${item?.User?.lastName}`.trim(),
          id: item?.User?.employee_id,
        }));

        if (!managers?.length) return <div>-</div>;

        const displayedManagers = managers
          ?.slice(0, 2)
          ?.map((m) => m.name)
          ?.join(", ");
        const remainingManagers = managers?.slice(2);
        return (
          <div className="rounded-full relative group inline-block">
            <span className="rounded-full cursor-pointer group-hover">
              {displayedManagers}
              {remainingManagers?.length > 0 &&
                `, +${remainingManagers?.length}`}
            </span>
            <div className="absolute left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block bg-white text-gray-900 text-sm font-inter font-normal shadow-lg min-w-max gap-[10px] z-10 rounded-[2px] px-[16px] py-[8px] max-h-[200px]">
              {managers?.map((manager, index) => (
                <div key={index} className="w-full text-left whitespace-nowrap">
                  {manager?.name}
                  <span className="text-gray-500  pl-[2px]">
                    (ID: {manager.id})
                  </span>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "client",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Client Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Client?.client_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">{row?.original?.Client?.client_name ?? "-"}</div>
        );
      },
    },
    {
      accessorKey: "project_type",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Billing/Project Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.project_type || row?.billing_type || "-",
      cell: ({ row }) => {
        const projectType = row?.original?.project_type || "";
        const billingType = row?.original?.billing_type || "";

        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {projectType && billingType
              ? `${projectType} , ${billingType}`
              : projectType || billingType}
          </div>
        );
      },
    },
    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date & End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => {
        const startDate = row?.start_date
          ? new Date(row.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";

        const endDate = row?.end_date
          ? new Date(row.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";

        return `${startDate} - ${endDate}`;
      },
      cell: ({ row }) => {
        const startDate = row?.original?.start_date
          ? new Date(row.original.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "NA";

        const endDate = row?.original?.end_date
          ? new Date(row.original.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "NA";

        return <div>{`${startDate} - ${endDate}`}</div>;
      },
    },
    {
      accessorKey: "project_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.project_status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.project_status;

        return status === "Delay" ? (
          <div className="px-[10px] rounded-full w-fit py-[2px] flex justify-center items-center bg-[#EDCFCF] text-[#EB3F3F]">
            Delay
          </div>
        ) : (
          <div className="px-[10px] rounded-full py-[2px] w-fit flex justify-center items-center bg-[#E0F1E7] text-[#0F9446]">
            On Time
          </div>
        );
      },
    },

    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                dispatch(setProjectId(row?.original?.id));
                dispatch(resetProjectStartDate());
                dispatch(resetProjectEndDate());
                dispatch(resetViewProjectTab());
                e.stopPropagation();
                navigate("/view-project-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                dispatch(setProjectId(row?.original?.id));
                dispatch(resetProjectTab());
                dispatch(resetProjectStartDate());
                dispatch(resetProjectEndDate());
                e.stopPropagation();
                navigate(`/edit-project-details`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row?.original);
              }}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const fetchProjectsList = useCallback ( async () => {
    setLoading(true);
    try {
      const response = await Fetch_Project_List(
        searchTerm ?? "",
        numOfItems,
        page,
        dates ? dates[0] : null,
        dates ? dates[1] : null,
        customer_id,
        value,
        project || "",
        billing || ""
      );
      if (response.status === 200) {
        setTableData(
          response?.data?.result?.rows?.map((row: any) => ({
            id: Number(row?.id),
            project_name: row?.project_name,
            project_type: row?.project_type,
            project_status: row?.project_status,
            createdAt: row?.createdAt,
            start_date: row?.start_date,
            end_date: row?.end_date,
            updatedAt: row?.updatedAt,
            Client: row?.Client
              ? { client_name: row?.Client?.client_name }
              : null,
            ProjectResources: row?.ProjectResources,
            status: row?.status,
            billing_type: row?.billing_type || null,
          })) || []
        );
        setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  },[billing, customer_id, dates, numOfItems, page, project, searchTerm, value]);

  useEffect(() => {
    fetchProjectsList();
  }, [fetchProjectsList]);

console.log({project,billing})
  useEffect(() => {
    fetchProjectsStats();
  }, [updated]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(value);
    setValue(newValue);
    setPage(1);
    setNumOfItems(10);
  };

  const handleApply = (filterData: ProjectFilterData) => {
    console.log({filterData})
    dispatch(
      setFilterPersistData({
        date: filterData?.dateRange,
        project: filterData?.projectType,
        billing: filterData?.billingType,
      })
    );
    setDates(filterData?.dateRange);
    setProject(filterData?.projectType ?? null);
    setBilling(filterData?.billingType ?? null);

    setIsOpen(false);
  };

  const handleDelete = () => {
    setDates([null, null]);
    setProject(null);
    setBilling(null);

    dispatch(
      setFilterPersistData({
        date: [null, null],
        project: null,
        billing: null,
      })
    );
  };

  const totalProjects = Object.values(statusList?.statusCounts ?? {}).reduce(
    (sum, count) => sum + count,
    0
  );

  const handleDownloadExcel = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      const startSession = localStorage.getItem("start_session");
      const endSession = localStorage.getItem("end_session");
  
      const queryParams = new URLSearchParams();
  
      // Required parameter
      queryParams.append("customer_id", customer_id.toString());
  
      // Add session filters if available
      if (startSession && endSession) {
        queryParams.append("session_start", startSession);
        queryParams.append("session_end", endSession);
      }
  
      // Add date range filter
      if (dates && dates[0] && dates[1]) {
        queryParams.append("start_date", moment(dates[0]).format("YYYY-MM-DD"));
        queryParams.append("end_date", moment(dates[1]).format("YYYY-MM-DD"));
      }
  
      // Add other filters if selected
      if (project) queryParams.append("project_type", project);
      if (billing) queryParams.append("billing_type", billing);
      if (value) queryParams.append("status", value);
  
      const query = `api/v1/project-download?${queryParams.toString()}`;
  
      const response = await axiosInstance.get(query, {
        responseType: "blob",
        headers: {
          "x-api-key": api_key_work,
          Authorization: `Bearer ${access_token}`,
        },
      });
  
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      const fileName = "Project_Report.xlsx";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  

  return (
    <>
      <div className="flex justify-between gap-4 flex-wrap items-center pt-4 ">
        <div className="h-[104px] rounded-md flex-1 flex flex-col justify-center items-start px-4 gap-2 border border-default-gray-200 bg-white shadow-sm">
          <p className="text-[#374151] text-sm font-medium font-['poppins']">
            Total Projects
          </p>
          <p className="text-2xl font-bold text-[#111827]">
            {totalProjects ?? 0}
          </p>
        </div>
        <div className="h-[104px] rounded-md flex-1 flex flex-col justify-center items-start px-4 gap-2 border border-default-gray-200 bg-white shadow-sm">
          <p className="text-[#374151] text-sm font-medium font-['poppins']">
            Ongoing Projects
          </p>
          <p className="text-2xl font-bold text-[#111827]">
            {statusList?.statusCounts?.ongoing ?? 0}
          </p>
        </div>{" "}
        <div className="h-[104px] rounded-md flex-1 flex flex-col justify-center items-start px-4 gap-2 border border-default-gray-200 bg-white shadow-sm">
          <p className="text-[#374151] text-sm font-medium font-['poppins']">
            Onhold Projects
          </p>
          <p className="text-2xl font-bold text-[#111827]">
            {statusList?.statusCounts?.onhold ?? 0}
          </p>
        </div>
        <div className="h-[104px] rounded-md flex-1 flex flex-col justify-center items-start px-4 gap-2 border border-default-gray-200 bg-white shadow-sm">
          <p className="text-[#374151] text-sm font-medium font-['poppins']">
            Completed Projects
          </p>
          <p className="text-2xl font-bold text-[#111827]">
            {statusList?.statusCounts?.completed ?? 0}
          </p>
        </div>
        <div className="h-[104px] rounded-md flex-1 flex flex-col justify-center items-start px-4 gap-2 border border-default-gray-200 bg-white shadow-sm">
          <p className="text-[#374151] text-sm font-medium font-['poppins']">
            Upcoming Projects
          </p>
          <p className="text-2xl font-bold text-[#111827]">
            {statusList?.statusCounts?.upcoming ?? 0}
          </p>
        </div>
        <div className="h-[104px] rounded-md flex-1 flex flex-col justify-center items-start px-4 gap-2 border border-default-gray-200 bg-white shadow-sm">
          <p className="text-[#374151] text-sm font-medium font-['poppins']">
            Delayed Projects
          </p>
          <p className="text-2xl font-bold text-[#111827]">
            {statusList?.projectStatusCounts?.Delay ?? 0}
          </p>
        </div>
      </div>
      <TabContext value={value}>
        <div className="flex justify-between mt-6">
          <div className="h-[40px] w-full">
            <div className="h-full flex justify-between items-center w-full">
              <PersistedSearch label="Search with Project Name" />
              <div className="h-full flex items-center gap-5">
                <div className="h-full flex justify-end items-center">
                  <div className="relative">
                    <TextField
                      id="outlined-required"
                      select
                      // label="Project Status"
                      placeholder="All Projects"
                      name="value"
                      value={value}
                      sx={{
                        height: "40px",
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        "& .MuiOutlinedInput-root": {
                          height: "100%",
                          minWidth: 80,
                          borderRadius: "0.5rem",
                          border: "none",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "29px",
                          color: "#49454F",
                          outline: "none",
                          "&:focus": {
                            outline: "none",
                          },
                          "& fieldset": {
                            border:
                              "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))", // Default border transparent
                          },
                          "&:hover fieldset": {
                            borderColor:
                              "rgb(205 205 205 / var(--tw-border-opacity, 1))", // Green border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor:
                              "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important", // Green border on focus
                            borderWidth: "1px",
                          },
                        },
                      }}
                      onChange={(e) => handleTabChange(e, e.target.value)}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      {tabs.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  {(project && project !== "") ||
                  (billing && billing !== "") ||
                  (dates && !(dates[0] === null && dates[1] === null)) ? (
                    <button
                      onClick={handleDelete}
                      className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                    >
                      <Close
                        sx={{
                          fontSize: "15px",
                        }}
                      />
                      Clear All
                    </button>
                  ) : null}
                </div>
                <Button variant={"border"} onClick={() => setIsOpen(true)}>
                  <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                  Filter
                </Button>
                <div className="h-full">
                  <Button onClick={handleDownloadExcel} variant={"border"}>
                    <Download size={15} strokeWidth={2.5} />
                    Download Report
                  </Button>
                </div>
              </div>

              {/* <CustomTabs tabs={tabs} onChange={handleTabChange} /> */}
            </div>
          </div>
        </div>

        {tabs?.map((tab) => (
          <TabPanel className="tabpanel" value={tab?.value}>
            <div className="table_main_content w-full relative overflow-auto bg-white mt-5">
              <DynamicTable<IProjectList>
                onRowClick={(row) => {
                  dispatch(setProjectId(row?.id));
                  dispatch(resetProjectStartDate());
                  dispatch(resetProjectEndDate());
                  dispatch(resetViewProjectTab());
                  navigate("/view-project-details", {
                    state: { id: row?.id },
                  });
                }}
                data={tableData}
                loading={loading}
                columns={columns}
                enableSorting
                enablePagination
              />
            </div>

            {count >= 10 && (
              <TablePaginationDemo
                count={count}
                handleItemsChange={handleNumberOfPages}
                handlePageChange={handlePageChange}
                currentPage={page}
                numOfItems={numOfItems}
              />
            )}
          </TabPanel>
        ))}

        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}

        {isOpen && (
          <ProjectFilter
            open={isOpen}
            handleClose={() => setIsOpen(false)}
            heading="Project Filter"
            onApply={handleApply}
            isDate
            // isOther={false}
            isProjectDetails={true}
          />
        )}

        {/* <Filter
          isDate
          isOther={false}
          heading="Project Filter"
          selectnameBusiness="Select Business Unit"
          optionsBusiness={bussness}
          onOptionSelectBusiness={handleBusinessUnitSelect}
          selectnameDepartment="Select Department"
          optionsDepartment={department}
          onOptionSelectDepartment={handleDepartmentUnitSelect}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onApply={handleFilterApply}
          selected={selectedDates[1]}
          startDate={selectedDates[0]}
          endDate={selectedDates[1]}
          isCalendarOpen={isCalendarOpen}
          handleCalanderClick={handleCalanderClick}
          handleDateChange={handleDateChange}
        /> */}
      </TabContext>
    </>
  );
};

export default Projects;
