import rightArrow from "../../assets/chevron-right.svg";
import Modal from "react-modal";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { RoleManagementForm, base_api } from "../../utils/helperData";
import Footer from "../Layout/footer";
import { useEffect, useState } from "react";
import { getCustomerResourseList } from "../../redux/actions/RoleManagement/RoleManagementActions";
import { useSelector } from "react-redux";
import tick from "../../assets/images/tick.png";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";
import { ROLE_MANAGEMENT } from "../../utils/routeConstant";
import { createRole } from "../common/services/roleManagement";
import { useNavigate } from "react-router-dom";
import RenderResources from "../common/ResourceList/Resources";
import { Button } from "../common/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  width: "325px",
};
const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};

interface Resource {
  resource_id: string;
  parent_id: string;
  resource_name: string;
}

interface Group {
  resource_id: number;
  resource_name: string;
  parent_id: number | null;
  children?: Group[];
}

const flattenGroupData = (groupData: Group[]): any => {
  const flattenedRecords: any[] = [];

  function flattenGroup(group: any[]) {
    console.log(group);
    group.forEach((item: any) => {
      const record = {
        resource_id: item.resource_id,
        resource_name: item.resource_name,
        parent_id: item.parent_id,
        children: item?.children?.length,
      };
      flattenedRecords.push(record);
      if (item.children && item.children.length > 0) {
        flattenGroup(item.children);
      }
    });
  }

  flattenGroup(groupData);

  return flattenedRecords;
};

const validationData: any = ["roleName", "resources"];

const AddRole = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [customerResourceList, setCustomerResourceList] = useState<any[]>([]);
  const [flattenResources, setFlattenResources] = useState<any[]>([]);
  const [resourceList, setResource_list] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [otherError, setOtherError] = useState("");

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  ///////// post request //////////
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customerId = 0;
  let access_token = "";
  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customerId = loginData.customer_id;
  }

  let message = "";
  const [roleName, setRoleName] = useState("");
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [isValid, setIsValid] = React.useState<{ [key: string]: any }>({});

  function handleValidation() {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["roleName"] = !roleName;
    updated["resources"] = !resourceList.length;

    setIsValid(updated);

    if (!roleName || !resourceList.length) {
      return;
    }
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    updated[`${item}`] = false;

    setIsValid(updated);
  }

  console.log(resourceList);

  function handleResourceListChildren(event: any, id: any, parent_id: any) {
    let newResourceList: any[] = [...resourceList];
    console.log(id);
    const subResourceObjects = flattenResources.filter(
      (idx: Resource) => idx.parent_id === id
    );
    if (event.target.checked) {
      // If the checkbox is being checked, add the resource_id of the child and its parent to resourceList
      const currentElement = flattenResources.find(
        (item) => item.resource_id === Number(id)
      );
      const selectedElement = flattenResources.find(
        (item) => item.resource_id === Number(event.target.id)
      );
      if (parent_id) {
        newResourceList = [...newResourceList, Number(id), Number(parent_id)];
        const parentElement = flattenResources.find(
          (item) => item.resource_id === parent_id
        );

        if (parentElement && parentElement?.parent_id) {
          newResourceList = [
            ...handleResourceListChildren(
              event,
              parent_id,
              parentElement?.parent_id
            ),
            ...newResourceList,
          ];
        }
      }
      console.log(currentElement);
      if (selectedElement && selectedElement?.children) {
        newResourceList = handleResourceListParent(
          event,
          currentElement?.resource_id,
          currentElement?.parent_id,
          [...newResourceList]
        );
      }
      if (!parent_id) {
        newResourceList = [...newResourceList, Number(id)];
      }
    } else {
      // If the checkbox is being unchecked, remove the resource_id of the child from resourceList
      const currentElement = flattenResources.find(
        (item) => item.resource_id === Number(id)
      );

      if (currentElement && currentElement?.children) {
        newResourceList = handleResourceListParent(
          event,
          currentElement?.resource_id,
          currentElement?.parent_id,
          [...newResourceList]
        );
      }
      // If this is the last child of its parent that's selected, also remove the parent
      if (
        !newResourceList.some((item) =>
          subResourceObjects.find(
            (subObj: Resource) => Number(subObj.resource_id) === item
          )
        )
      ) {
        newResourceList = newResourceList.filter((item) => item !== Number(id));
      }
      // setResource_list(newResourceList);
    }

    newResourceList = removeDuplicate(newResourceList);

    return newResourceList;
  }

  function handleResourceListParent(
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) {
    let newResourceList: any[] = [...temp, ...resourceList];

    const subResourceObjects = flattenResources.filter(
      (idx: Resource) => idx.parent_id === id
    );
    if (event.target.checked) {
      // If the checkbox is being checked, add the resource_id of the parent and all its children to resourceList
      newResourceList = [
        ...newResourceList,
        Number(id),
        ...subResourceObjects.map((subObj: Resource) =>
          Number(subObj.resource_id)
        ),
      ];
      // setResource_list([
      //   ...temp,
      //   ...resourceList,
      //   Number(id),
      //   ...subResourceObjects.map((subObj: Resource) =>
      //     Number(subObj.resource_id)
      //   ),
      // ]);
    } else {
      // If the checkbox is being unchecked, remove the resource_id of the parent and all its children from resourceList
      newResourceList = newResourceList.filter(
        (item) =>
          item !== Number(id) &&
          !subResourceObjects
            .map((subObj: Resource) => Number(subObj.resource_id))
            .includes(item)
      );
      // const currentElement = flattenResources.find((item) => (item.resource_id === Number(id)));

      // if(currentElement && currentElement?.children){
      //   newResourceList = handleResourceListParent(event, currentElement?.resource_id, currentElement?.parent_id, [...newResourceList]);
      // }
      // setResource_list(
      //   resourceList.filter(
      //     (item) =>
      //       item !== Number(id) &&
      //       !subResourceObjects
      //         .map((subObj: Resource) => Number(subObj.resource_id))
      //         .includes(item)
      //   )
      // );
    }

    newResourceList = removeDuplicate(newResourceList);

    return newResourceList;
  }

  function removeDuplicate(tempList: any[]) {
    let checkRepeat = new Set();
    tempList.forEach((item: any) => checkRepeat.add(item));

    return Array.from(checkRepeat);
  }

  function handleResourceListChildrenChange(
    event: any,
    id: any,
    parent_id: any
  ) {
    const tempList = handleResourceListChildren(event, id, parent_id);

    const resourceListArray = removeDuplicate(tempList);

    setResource_list(resourceListArray);
    handleIsValidItem("resources");
  }

  function handleResourceListParentChange(event: any, id: any, parent_id: any) {
    const tempList = handleResourceListParent(event, id, parent_id, []);

    const resourceListArray = removeDuplicate(tempList);

    setResource_list(resourceListArray);
    handleIsValidItem("resources");
  }

  const postData = async () => {
    setLoading(true);
    try {
      const response = await createRole(roleName, customerId, resourceList);

      setIsModalOpen(true);

      dispatch(
        userManagementActions.resetUserRoleData({
          count: 0,
          rows: [],
          currentPage: 1,
          itemsPerPage: 10,
          isNext: true,
        })
      );

      setTimeout(() => {
        setIsModalOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/role-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  /////////////post end ///////////

  useEffect(() => {
    async function fetchResourceList() {
      try {
        const data = await getCustomerResourseList();
        if (data) {
          setCustomerResourceList(data);
          setFlattenResources(flattenGroupData(data));
        } else {
          console.error("Failed to fetch customer resource list.");
        }
      } catch (error) {
        console.error("Error fetching customer resource list:", error);
      }
    }

    fetchResourceList();
  }, []);

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = async () => {
    handleValidation();
    if (!selectedRole) {
      setOtherError("This field is required");
    }
    if (!roleName || !resourceList.length || !selectedRole) {
      return;
    }

    await postData();
  };

  let cssClass =
    "h-4 w-4 rounded-md shrink-0 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0 ";

  cssClass += isValid["resources"]
    ? "bg-[#FFD8D8] border-[#DC2626]"
    : "border-gray-900/20";

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedRole(value);

    // If "Other" is selected, clear roleName; otherwise, store the selected value
    setRoleName(value !== "Other" ? value : "");

    handleIsValidItem("roleName");
  };

  return (
    <div className="w-full bg-[#F9F9F9] px-4 pt-6 pb-12">
      <div className="flex w-full flex-col min-h-screen">
        <div className="flex-grow w-full">
          <div className="w-full h-full">
            <div className="w-full flex flex-col gap-10">
              <div className="flex flex-row items-center w-full h-12 justify-between">
                <div className="flex flex-row gap-[10px] justify-center items-center w-full">
                  <div className="flex flex-col gap-5 w-full">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-2">
                        <button onClick={() => navigate("/role-management")}>
                          <img
                            src={rightArrow}
                            alt="right arrow sign"
                            className="h-6 w-6 shrink-0 bg-black rounded-full"
                          />
                        </button>
                        <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                          Add Role Details
                        </span>
                      </div>
                      <div className="flex flex-start gap-5">
                        <button
                          onClick={() => navigate(`/role-management`)}
                          className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
                        >
                          <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                            Cancel
                          </div>
                        </button>
                        <Button variant={"gradient"} onClick={handleClick}>
                          <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                            Save
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="flex flex-start gap-5">
                  <button
                    onClick={() => navigate(`/role-management`)}
                    className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
                  >
                    <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      Cancel
                    </div>
                  </button>
                  <button
                    onClick={handleClick}
                    className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg buttoncss h-12"
                  >
                    <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      Save
                    </div>
                  </button>
                </div> */}
              </div>

              <div className="w-full py-6 rounded-[9.601px] shadow-custom bg-white">
                <span className="text-black px-6 font-[Poppins] text-base font-semibold leading-normal">
                  Role Management
                </span>
                <div className=" py-[25px] flex flex-col flex-start gap-8 shrink-0 ">
                  <Container component="main" sx={{ padding: 0 }}>
                    <Box>
                      <Box
                        component="form"
                        className="flex flex-col gap-8"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: "0px" }}
                      >
                        <Grid
                          container
                          style={{
                            gap: "20px",
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {RoleManagementForm.map((item: any) => (
                            <Grid
                              item
                              key={item.id}
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                flexGrow: 1,
                                maxWidth: "100%",
                              }}
                            >
                              <TextField
                                select
                                id={item.id}
                                name={item.name}
                                label={item.label}
                                value={selectedRole}
                                onChange={handleRoleChange}
                                required
                                error={!!otherError} // Show error when no value is selected
                                helperText={otherError}
                                style={{ minWidth: "180px" }}
                                InputLabelProps={{
                                  shrink: true,
                                  sx: labelStyle,
                                }}
                                InputProps={{
                                  sx: {
                                    ...inputStyle,
                                    "& input:-webkit-autofill": {
                                      WebkitBoxShadow:
                                        "0 0 0 1000px #fff inset",
                                      WebkitTextFillColor: "inherit",
                                    },
                                  },
                                }}
                              >
                                {["HR", "Admin", "IT", "Other"].map((role) => (
                                  <MenuItem key={role} value={role}>
                                    {role}
                                  </MenuItem>
                                ))}
                              </TextField>

                              {selectedRole === "Other" && (
                                <TextField
                                  autoComplete={item.autoComplete}
                                  name={item.name}
                                  id={item.id}
                                  label="Enter Role"
                                  placeholder="Enter role"
                                  value={roleName}
                                  onChange={(e) => {
                                    setRoleName(e.target.value);
                                    handleIsValidItem(item.name);
                                  }}
                                  required
                                  error={isValid[item.name]}
                                  helperText={
                                    isValid[item.name]
                                      ? "This field is required!"
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                    sx: labelStyle,
                                  }}
                                  InputProps={{
                                    sx: {
                                      ...inputStyle,
                                      "& input:-webkit-autofill": {
                                        WebkitBoxShadow:
                                          "0 0 0 1000px #fff inset",
                                        WebkitTextFillColor: "inherit",
                                      },
                                    },
                                  }}
                                />
                              )}
                            </Grid>
                          ))}
                        </Grid>

                        <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[30px] mb-[10px]">
                          Accesses given to User
                        </div>

                        {isValid["resources"] && (
                          <div className="text-[#DC2626] font-[Poppins] text-xs font-normal leading-5 tracking-[-0.24px] mt-[-35px]">
                            Ensure at least one option is selected!
                          </div>
                        )}

                        {/* <Grid
                          container
                          style={{
                            gap: "48px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            alignSelf: "stretch",
                            marginBottom: "60px",
                          }}
                        >
                          {customerResourceList.map((obj: Resource) => {
                            if (!checkRepeat.has(obj.resource_id)) {
                              const subResourceObjects =
                                customerResourceList.filter(
                                  (idx: Resource) =>
                                    idx.parent_id === obj.resource_id
                                );

                              let cssClass = "h-4 w-4 rounded-md shrink-0 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0 ";

                              cssClass += isValid["resources"] ? "bg-[#FFD8D8] border-[#DC2626]" : "border-gray-900/20";
                              const subResources = subResourceObjects.map(
                                (subObj: Resource) => {
                                  checkRepeat.add(subObj.resource_id); // Add sub resource_id to the set
                                  return (
                                    <li
                                      className="w-[366px] flex flex-row items-start"
                                      key={subObj.resource_id}
                                    >
                                      <Checkbox
                                        ripple={false}
                                        crossOrigin=""
                                        value="insights"
                                        id={"insights"}
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        checked={resourceList.includes(
                                          Number(subObj.resource_id)
                                        )} // Check if the resource_id of the current item is in the resourceList array
                                        onChange={(event) => {
                                          // let newResourceList: number[];
                                          // if (event.target.checked) {
                                          //   // If the checkbox is being checked, add the resource_id of the child and its parent to resourceList
                                          //   setResource_list([
                                          //     ...resourceList,
                                          //     Number(subObj.resource_id),
                                          //     Number(obj.resource_id),
                                          //   ]);
                                          // } else {
                                          //   // If the checkbox is being unchecked, remove the resource_id of the child from resourceList
                                          //   newResourceList =
                                          //     resourceList.filter(
                                          //       (id) => id !==
                                          //         Number(subObj.resource_id)
                                          //     );
                                          //   // If this is the last child of its parent that's selected, also remove the parent
                                          //   if (!newResourceList.some((id) => subResourceObjects.find(
                                          //     (subObj: Resource) => Number(
                                          //       subObj.resource_id
                                          //     ) === id
                                          //   )
                                          //   )) {
                                          //     newResourceList =
                                          //       newResourceList.filter(
                                          //         (id) => id !==
                                          //           Number(obj.resource_id)
                                          //       );
                                          //   }
                                          //   setResource_list(
                                          //     newResourceList
                                          //   );
                                          // }
                                          // handleIsValidItem("resources");
                                          handleResourceListChildren(event, subObj.resource_id, subObj.parent_id)
                                        } }
                                        className={cssClass} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                      />
                                      <label
                                        htmlFor={subObj.resource_id}
                                        className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal pl-3 mt-[-3px]"
                                      >
                                        {subObj.resource_name}
                                      </label>
                                    </li>
                                  );
                                }
                              );

                              return (
                                <div
                                  key={obj.resource_id}
                                  className="gap-7 flex flex-row w-full"
                                >
                                  <div className="flex flex-row gap-3 w-[28%] items-center h-3">
                                    <Checkbox
                                      ripple={false}
                                      crossOrigin=""
                                      value="insights"
                                      id={"insights"}
                                      containerProps={{ className: "p-0" }}
                                      checked={resourceList.includes(
                                        Number(obj.resource_id)
                                      )} // Check if the resource_id of the current item is in the resourceList array
                                      onChange={(event) => {
                                        // if (event.target.checked) {
                                        //   // If the checkbox is being checked, add the resource_id of the parent and all its children to resourceList
                                        //   setResource_list([
                                        //     ...resourceList,
                                        //     Number(obj.resource_id),
                                        //     ...subResourceObjects.map(
                                        //       (subObj: Resource) => Number(subObj.resource_id)
                                        //     ),
                                        //   ]);
                                        // } else {
                                        //   // If the checkbox is being unchecked, remove the resource_id of the parent and all its children from resourceList
                                        //   setResource_list(
                                        //     resourceList.filter(
                                        //       (id) => id !==
                                        //         Number(obj.resource_id) &&
                                        //         !subResourceObjects
                                        //           .map((subObj: Resource) => Number(
                                        //             subObj.resource_id
                                        //           )
                                        //           )
                                        //           .includes(id)
                                        //     )
                                        //   );
                                        // }
                                        // handleIsValidItem("resources");
                                        handleResourceListParent(event, obj.resource_id, obj.parent_id)
                                      } }
                                      className={cssClass} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                    />

                                    <label
                                      htmlFor={obj.resource_name}
                                      className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
                                    >
                                      {obj.resource_name}
                                    </label>
                                  </div>
                                  <div className="flex flex-row gap-3 items-start">
                                    <ul className="grid grid-cols-2 gap-5">
                                      {subResources}
                                    </ul>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                         
                        </Grid> */}

                        <RenderResources
                          renderData={customerResourceList}
                          isChecked={false}
                          cssClass={cssClass}
                          resourceList={resourceList}
                          handleResourceListChildren={
                            handleResourceListChildrenChange
                          }
                          handleResourceListParent={
                            handleResourceListParentChange
                          }
                        />
                      </Box>
                    </Box>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {loading && (
          <div className="bg-black opacity-70 fixed top-0 left-0 h-screen w-screen">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
              role="status"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        // shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // This is black with 50% opacity
          },
          content: {
            width: "50%", // Decreased from 80%
            height: "50%", // Decreased from 95%
            maxWidth: "250px", // Decreased from 600px
            maxHeight: "150px", // Decreased from 600px
            margin: "auto",
            overflow: "auto",
          },
        }}
      >
        <div className="flex flex-col justify-center text-sm font-medium text-center whitespace-nowrap max-w-[450px] text-cyan-950">
          {" "}
          {/* Changed text-xl to text-sm */}
          <div className="flex py-2 flex-col items-center w-full bg-white rounded-2xl justify-center">
            <img
              loading="lazy"
              src={tick}
              className="w-12 aspect-square"
              alt="error"
            />
            <div className="mt-5 ">Role created Successfully</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddRole;
