import React, { useState, useEffect } from "react";
import Header from "../common/header";
import toast from "react-hot-toast";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendOrganisationDetails } from "../../redux/actions/signupActions";
import { signupActions } from "../../redux/reducers/Signup/signupSlice";
import { Fetch_Industry_Vertical_Data_Api } from "../common/services/onBoarding";
import { SIGNUP, VERIFY_EMAIL } from "../../utils/routeConstant";
import griddesign from "../../image/ornamentlogin.png";

// import styles from "./Signup.module.css";

interface Country {
  id: number;
  country_name: string;
}

interface State {
  id: number;
  state_name: string;
}

interface IndustryItem {
  id: number;
  name: string;
}

const OrgDetails: React.FC = () => {
  let email: string = "";
  let customer_id: string = "";
  let signup_token: string = "";
  const signupData = useSelector((state: any) => state.signup.details);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: signupData.sign_up_details.email,
    business_size: "default",
    industry_vertical: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    customer_code: "",
  });
  const [details, setDetails] = useState(false);
  const dispatch = useDispatch();
  const [countryData, setCountryData] = useState<Country[]>([]);
  const [countryId, setCountryId] = useState("0");
  const [stateData, setStateData] = useState<State[]>([]);
  const [stateId, setStateId] = useState("");
  const [enable, setEnable] = useState(true);
  const [industryVerticalData, setIndustryVerticalData] = useState<
    IndustryItem[]
  >([]);
  const [selectedIndustry, setSelectedIndustry] = useState("0");

  useEffect(() => {
    const getCountry = async () => {
      const reqdata = await fetch(
        `${base_api}/api/v1/country?page=1&size=264`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: signup_token,
          },
        }
      );
      const resdata = await reqdata.json();
      const validData = resdata.result.rows;
      setCountryData(validData);
    };
    getCountry();
  }, [signup_token]);

  // setFormData((prevState) => {
  //   return {...prevState, }
  // })

  //! fetching industry vertical
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Industry_Vertical_Data_Api();
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setIndustryVerticalData(data.result);
      } catch (error) {}
    };

    fetchData();
  }, []);

  if (signupData === null) {
    const signup_token = localStorage.getItem("signup_token");
    if (signup_token) {
      //! fetch userData using api and save it to reducer
    } else {
      navigate(`${SIGNUP}`);
    }
  }

  if (signupData) {
    email = signupData.sign_up_details.email;
    customer_id = signupData.sign_up_details.customer_id;
    signup_token = signupData.signup_token;
  }

  const handleCountry = async (e: any) => {
    setCountryId(e.target.value);

    if (e.target.value) {
      const reqStateData = await fetch(
        `${base_api}/api/v1/state?country_id=${e.target.value}&page=1&size=100`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: signup_token,
          },
        }
      );
      const resStateData = await reqStateData.json();
      const validData: [] = resStateData.result.rows;
      setStateData(validData);
      setEnable(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: e.target.value,
      }));
    } else {
      setStateData([]);
      setEnable(true);
    }
  };

  const handleIndustryVertical = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedIndustry(event.target.value);
    setFormData((prevState) => {
      return { ...prevState, industry_vertical: Number(event.target.value) };
    });
  };

  const handleState = (e: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateData[Number(e.target.value)].state_name,
    }));

    setStateId(e.target.value);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInitialsChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    toast.dismiss();
    e.preventDefault();
    // Post formData to backend here
    if (
      !formData.name.trim() ||
      !formData.address.trim() ||
      !formData.city.trim() ||
      !formData.state.trim() ||
      !formData.country.trim() ||
      !formData.business_size.trim() ||
      !formData.industry_vertical ||
      !formData.postal_code.trim() ||
      !formData.customer_code.trim()
    ) {
      toast.dismiss();
      toast.error("Please fill all the details.");

      return;
    }

    try {
      toast.dismiss();
      setDetails(true);
      toast.loading("Processing in...");
      const userData = await dispatch<any>(
        sendOrganisationDetails(formData, customer_id, signup_token)
      );
      if (userData) {
        const signup_token = localStorage.getItem("signup_token");

        if (signup_token)
          dispatch(signupActions.setOrganisationDetails(formData));
      }
      // Dispatch action to save token in Redux
      // Redirect to dashboard (you can use React Router or any other routing library)
      navigate(`${VERIFY_EMAIL}`);
      toast.dismiss();
      toast.success("Process completed successfully");
    } catch (error: any) {
      toast.dismiss();
      toast.error(error?.response?.data?.result?.error);
    } finally {
      setDetails(false);
    }
  };

  return (
    <div className="h-[100vh] bg-gradient-to-r justify-center items-center from-[#E8EBF6] to-[#F1E3FD]  overflow-hidden">
        <img
            src={griddesign}
            alt="Gridline Ornament"
            className="absolute top-[0px] left-[0px] w-[424px] h-[370px] object-contain pointer-events-none"
          />
      <div className="flex flex-row justify-center w-full">
        <div className="w-[1440px] h-[800px]">
          <div className="relative h-[800px] bg-[100%_100%]"></div>
          <div className="absolute w-[76.3%] h-[77.1%] top-[11.3%] left-[12%] bg-white rounded-[12px]">
            <form onSubmit={handleSubmit}>
              <button
                type="submit"
                disabled={details}
                className="absolute w-[27.2%] h-[8.7%] top-[85%] left-[65%] [font-'Poppins'] font-semibold text-white text-[16px] tracking-[0] leading-[24.5px] whitespace-nowrap buttoncss rounded-[8px]"
              >
                Next
              </button>
              <div className="w-[89%] h-[50.2%] inline-flex flex-col items-start gap-[20px] absolute top-[18%] left-[7%]">
                <div className="w-[100%] h-[26%] inline-flex items-start gap-[40px] relative flex-[0_0_auto]">
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Organisation's Name
                    </div>
                    <input
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      autoComplete="off"
                      placeholder="Enter your organisation's name"
                      type="text"
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    ></input>
                  </div>
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Organisation's Email
                    </div>
                    <input
                      name="email"
                      value={email}
                      autoComplete="off"
                      disabled
                      onChange={handleChange}
                      placeholder="Enter your organisation's email"
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    ></input>
                  </div>
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Select your business size
                    </div>
                    <select
                      name="business_size"
                      value={formData.business_size}
                      onChange={handleChange}
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    >
                      <option value="default" disabled>
                        Select business size
                      </option>
                      <option value="0-10">0-10</option>
                      <option value="10-50">10-50</option>
                      <option value="50-100">50-100</option>
                      <option value="100-200">100-500</option>
                    </select>
                  </div>
                </div>
                <div className="w-[100%] h-[26%] inline-flex items-start gap-[40px] relative flex-[0_0_auto]">
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Select your Industry vertical
                    </div>
                    <select
                      name="industry"
                      value={selectedIndustry}
                      onChange={(event) => handleIndustryVertical(event)}
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    >
                      <option value="0" disabled selected>
                        Select industry vertical
                      </option>
                      {industryVerticalData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Organisation's Address
                    </div>
                    <input
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      autoComplete="off"
                      placeholder="Enter your organisation's address"
                      type="text"
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    ></input>
                  </div>
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Country
                    </div>
                    <select
                      name="country"
                      value={countryId}
                      onChange={(e) => handleCountry(e)}
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    >
                      <option value="0" disabled selected>
                        Select country
                      </option>
                      {countryData.map((country, index) => (
                        <option key={country.id} value={country.id}>
                          {country.country_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="w-[100%] h-[26%] inline-flex items-start gap-[40px] relative flex-[0_0_auto]">
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      State
                    </div>
                    <select
                      name="state"
                      value={stateId}
                      onChange={handleState}
                      disabled={enable}
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    >
                      <option value="" disabled selected>
                        Select State
                      </option>
                      {stateData.map((state, index) => (
                        <option key={state.id} value={index}>
                          {state.state_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      City
                    </div>
                    <input
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      autoComplete="off"
                      placeholder="Enter city"
                      type="text"
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    ></input>
                  </div>
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Postal Code
                    </div>
                    <input
                      name="postal_code"
                      value={formData.postal_code}
                      onChange={handleChange}
                      autoComplete="off"
                      placeholder="Enter postal code"
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    ></input>
                  </div>
                </div>
                <div className="w-[100%] h-[26%] inline-flex items-start gap-[40px] relative flex-[0_0_auto]">
                  <div className="w-[29%] h-[90%] inline-flex flex-col items-start gap-[5px] relative flex-[0_0_auto]">
                    <div className="relative w-fit mt-[-1.00px] text-neutral-800 text-xs font-semibold font-['Poppins']">
                      Initials
                    </div>
                    <input
                      name="customer_code"
                      value={formData.customer_code}
                      onChange={handleChange}
                      autoComplete="off"
                      placeholder="Enter the initials for Employee Ids (eg. EMP )"
                      className="w-[100%] h-[63.4%] p-1 top-0 left-0 rounded-[3px] border border-solid border-[#262626] border-opacity-30 text-neutral-800 text-opacity-60 text-xs font-medium font-['Poppins']"
                    ></input>
                  </div>
                </div>
              </div>
            </form>
            <div className="absolute w-[289px] h-[45px] top-[20px] left-[7%]">
              <div className="absolute w-[246px] h-[14px] top-[31px] ">
                <p className="absolute text-black text-xs font-medium font-['Inter'] leading-[14px]">
                  This helps us personalise your experience.
                </p>
              </div>
              <p className="absolute font-['Poppins'] font-semibold text-black text-[16px] tracking-[-0.17px] leading-[normal]">
                Tell us a bit about your organisation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgDetails;
