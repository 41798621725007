import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ACTIVITY_LOG,
  HOME,
  ONBOARDING_QUESTIONS,
  PRIVACY_POLICY_CREATE_POLICY,
  ROLE_MANAGEMENT,
  ROPA_DASHBOARD,
  ROPA_REGISTER,
  USER_MANAGEMENT,
} from "../../utils/routeConstant";
import { encryptId } from "../../utils/cipher";
import { useSelector } from "react-redux";

var CryptoJS = require("crypto-js");

const PublicRoutes = ({ children }: { children: JSX.Element }) => {
  const role = useSelector(
    (state: any) => state?.auth?.login?.login_details?.role
  );
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("access_token") !== null;
  const currentPath = window.location.pathname;
  console.log(currentPath);
  console.log(role);

  useEffect(() => {
    if (currentPath === "/onboarding-questionnaire") {
      navigate(`${ONBOARDING_QUESTIONS}`);
    }
    // else if (isLoggedIn && currentPath.startsWith("/login/")) {
    //   console.log(currentPath);
    //   let id = currentPath.split("/")[2];
    //   if (id) {
    //     const cipherId = encryptId(String(id));
    //     navigate(`${PRIVACY_POLICY_CREATE_POLICY.slice(0, -1)}${cipherId}`);
    //   }
    // }
    // else if (
    //   isLoggedIn &&
    //   role === "Data Protection Officer" &&
    //   currentPath === "/home"
    // ) {
    //   console.log(role, currentPath);

    //   navigate(`${ROPA_DASHBOARD}`);
    // }
    else if (
      isLoggedIn &&
      !(
        currentPath.startsWith("/login/") ||
        currentPath === "/onboarding-questionnaire"
      )
    ) {
      navigate("/dashboard");
      //navigate(`${USER_MANAGEMENT}`);
    }
  }, [isLoggedIn, role]);
  useEffect(() => {
    if (!isLoggedIn) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = ""; // Reset overflow style when component is unmounted
    };
  }, [isLoggedIn]);

  if (isLoggedIn) return null; // Return null if user is authenticated

  return <div style={{ overflow: "hidden" }}>{children}</div>;
};

export default PublicRoutes;

// import React from 'react'

// const PublicRoutes = ({ children }: { children: JSX.Element }) => {

//   console.log(children,"children");

//   return <div style={{ overflow: "hidden" }}>{children}</div>;
// }

// export default PublicRoutes;
