import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ArrowUpDown } from "lucide-react";
import dayjs, { Dayjs } from "dayjs";
import { EmployeeData } from "./ViewEmployeeList";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Fetch_Project_Tasks,
  fetchEditEmployee,
} from "../common/services/Employee";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import eye from "../../assets/Crud_Icons/view.svg";
import { debounce } from "lodash";
import { MenuItem, TextField } from "@mui/material";
import Search from "../SearchBar/Search";
import DatePicker from "react-datepicker";
import date2 from "../../assets/date2.svg";
import EmployeeBanner from "./EmployeeBanner";
import TablePaginationDemo from "../common/Pagenation";

interface IDSRList {
  id: string;
  task_name: string;
  User: {
    name: string;
    id: string;
    Designation: {
      name: string;
    };
  };
  start_date: string | null;
  end_date: string | null;
  estimated_hours: number | null;
  actual_hours: number | null;
}

const ViewEmployeeDSRList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state?.id;
  const projectId = location.state?.projectId;
  const [tableData, setTableData] = useState<IDSRList[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const handleSelectedDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
  };
  const [page, setPage] = useState(1);
  const [numOfItems, setNumOfItems] = useState(10);
  const [count, setCount] = useState(10);
  function handleNumberOfPages(value: number) {
    setCount(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const selectedCalendarRef = useRef<HTMLDivElement>(null);
  const handleSelectedCalanderClick = () => {
    setIsSelectedOpen(!isSelectedOpen);
  };
  const handleClickOutside = () => {
    setIsSelectedOpen(false);
  };

  const fetchProjectTask = useCallback(async () => {
    try {
      const response = await Fetch_Project_Tasks({
        id: id,
        status: status,
        page: page,
        size: numOfItems,
        search: searchTerm,
        date: selectedDate,
        project_id: projectId ?? null,
      });
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [id, numOfItems, page, searchTerm, selectedDate, status, projectId]);

  useEffect(() => {
    fetchProjectTask();
  }, [fetchProjectTask]);

  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    empCode: "",
    empId: "",
    empName: "",
    empLastName: "",
    email: "",
    code: "",
    number: "",
    business_unit: "",
    role: "",
    department: "",
    designation: "",
    team_manager_firstName: "",
    team_manager_lastName: "",
    reporting_manager_firstName: "",
    reporting_manager_lastName: "",
    status: "",
    MoE: "",
    YOE: "",
    DOB: "",
    DOJ: "",
    DOL: null,
    profile_image: null,
    location: null,
    employeeType: "",
    bloodGroup: null,
    shiftId: null,
    isActive: false,
    createdAt: "",
    updatedAt: "",
    prefix: "",
    currentAddress: "",
    permanentAddress: "",
    business_unitaddress: "",
  });

  useEffect(() => {
    if (!id) return;

    fetchEditEmployee(id)
      .then((data) => {
        if (data?.result) {
          setEmployeeData({
            empCode: data?.result?.employee_code || "",
            empId: data?.result?.employee_id || "",
            empName: data?.result?.firstName || "",
            empLastName: data?.result?.lastName || "",
            email: data?.result?.email || "",
            code: data?.result?.country_code || "",
            number: data?.result?.phone || "",
            business_unit: data?.result?.Group?.name || "",
            role: data?.result?.Role?.role_name || "",
            department: data?.result?.Department?.name || "",
            designation: data?.result?.Designation?.name || "",
            team_manager_firstName: data?.result?.team_manager?.firstName || "",
            team_manager_lastName: data?.result?.team_manager?.lastName || "",
            reporting_manager_firstName:
              data?.result?.reporting_manager?.firstName || "",
            reporting_manager_lastName:
              data?.result?.reporting_manager?.lastName || "",
            status: data?.result?.status || "",
            MoE: data?.result?.mode_of_employment || "",
            YOE: data?.result?.experience || "",
            DOB: data?.result?.date_of_birth || "",
            DOJ: data?.result?.date_of_joining || "",
            DOL: data?.result?.date_of_leaving || null,
            profile_image: data?.result?.profile_image || null,
            location: data?.result?.location || null,
            employeeType: data?.result?.type || "",
            bloodGroup: data?.result?.blood_group || null,
            shiftId: data?.result?.shift_id || null,
            isActive: data?.result?.is_active || false,
            createdAt: data?.result?.createdAt || "",
            updatedAt: data?.result?.updatedAt || "",
            prefix: data?.result?.prefix || "",
            alloted_wfh_per_week: data?.result?.alloted_wfh_per_week || null,
            currentAddress: data?.result?.current_address || "",
            permanentAddress: data?.result?.permanent_address || "",
            business_unitaddress:
              data?.result?.Group?.businessUnit_address || "",
          });
        }
      })
      .catch((error) => console.error("Error fetching employee data:", error));
  }, [id]);

  const columns: ColumnDef<IDSRList>[] = [
    {
      accessorKey: "task_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const name: string = row?.original?.task_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.start_date
          ? new Date(row.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.start_date
          ? new Date(row.original.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "end_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_date
          ? new Date(row.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.end_date
          ? new Date(row.original.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocted hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Actual hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "variance",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Variance
        </Button>
      ),
      cell: ({ row }) => {
        let diff = null;
        if (row?.original?.actual_hours && row?.original?.estimated_hours) {
          diff =
            Number(row?.original?.estimated_hours) -
            Number(row?.original?.actual_hours);
        }
        return <div className="">{diff ? diff : "-"}</div>;
      },
    },
    {
      accessorKey: "effort_summary",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Effort Summary
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Progress
        </Button>
      ),
      cell: ({ row }) => {
        let diff = null;
        let status = "";
        if (row?.original?.actual_hours && row?.original?.estimated_hours) {
          diff =
            Number(row?.original?.estimated_hours) -
            Number(row?.original?.actual_hours);
          status = diff < 0 ? "Delay" : "On Time";
        }

        return <div className="capitalize">{status ?? "-"}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              onClick={() =>
                navigate("/view-task-details", {
                  state: { id: row?.original?.id },
                })
              }
              type="button"
              className="p-0"
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const Status = [
    { value: "", label: "All Tasks" },
    { value: "inprogress", label: "Working" },
    { value: "todo", label: "To do" },
    { value: "completed", label: "Finished" },
    { value: "onhold", label: "Hold" },
  ];

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="mx-5 mt-2">
      <div className="flex gap-[6px] mt-4">
        <div
          className="text-gray-500 font-inter text-base font-normal cursor-pointer"
          onClick={handleClick}
        >
          Project
        </div>
        <div>{">"}</div>
        <div className="text-gray-800 font-inter text-base font-normal">
          Task
        </div>
      </div>
      <EmployeeBanner employeeData={employeeData} />

      <div className="flex justify-between mt-3">
        <Search onChange={updateSearchTerm} label="Search" />
        <div className="flex gap-4">
          <div
            className="flex h-12 p-4 justify-between items-center rounded-md border-[1px] border-solid border-[#E2E8F0] bg-white"
            ref={selectedCalendarRef}
          >
            <img
              src={date2}
              alt="date icon"
              className="w-5 h-5 cursor-pointer"
              onClick={handleSelectedCalanderClick}
            />

            <DatePicker
              className="w-[125px] text-center text-['Inter'] font-[400] cursor-pointer pl-3 pr-2"
              dateFormat={"dd MMM yyyy"}
              selected={selectedDate?.toDate() || null} // pass null if no date is selected
              onChange={(date: Date | null) => {
                handleSelectedDateChange(date ? dayjs(date) : null); // convert to dayjs or null
                handleSelectedCalanderClick();
              }}
              open={isSelectedOpen}
              onInputClick={handleSelectedCalanderClick}
              onClickOutside={handleClickOutside}
              isClearable
              placeholderText="DD/MM/YYYY"
              readOnly
              customInput={
                <input
                  style={{
                    outline: "none",
                    border: "none",
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                />
              }
            />
          </div>

          {/* <TextField
            id="outlined-required"
            select
            label="Task Status"
            placeholder="Select Status"
            name="status"
            value={status}
            sx={{
              height: "48px",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                width: "150px",
              },
            }}
            onChange={(e) => setStatus(e.target.value)}
          >
            {Status.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField> */}

          <TextField
            id="outlined-required"
            select
            // label="Task Status"
            placeholder="Select Status"
            name="status"
            value={status}
            sx={{
              height: "48px",
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                width: "150px",
              },
            }}
            onChange={(e) => setStatus(e.target.value)}
            SelectProps={{
              displayEmpty: true,
            }}
          >
            {Status.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      <div className="overflow-x-auto mt-3">
        <div className="table_main_content w-full relative overflow-auto bg-white">
          <DynamicTable<IDSRList>
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>

        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        )}
      </div>
    </div>
  );
};

export default ViewEmployeeDSRList;
