import {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import TablePaginationDemo from "../../../common/Pagenation";
import { MenuItem, TextField } from "@mui/material";
import Search2 from "../../../SearchBar/Search2";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import dayjs, { Dayjs } from "dayjs";
import date2 from "../../../../assets/date2.svg";
import DynamicTable from "../../../common/DynamicTable";
import { useNavigate } from "react-router-dom";
import { ArrowUpDown } from "lucide-react";
import {
  calculateVariance,
  convertHoursIntoHoursMinutes,
} from "../../../../utils/helperData";
import { Button } from "../../../common/Button";
import { ColumnDef } from "@tanstack/react-table";
import profile from "../../../../assets/Ellipse 580.png";
import eye from "../../../../assets/Crud_Icons/view.svg";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import { Fetch_Task_List } from "../../../common/services/Project";
import { useSelector } from "react-redux";

interface IRowData {
  id: number;
  project_id: number;
  customer_id: number;
  group_id: number | null;
  task_name: string;
  estimated_hours: number;
  actual_hours: number;
  start_date: string;
  end_date: string;
  status: string;
  description: string;
  task_document: any[];
  TaskResources: TaskResource[];
  Module: { module_name: string };
  ProjectMilestone: {
    milestone_name: string | null;
  } | null;
}

interface TaskResource {
  id: number;
  task_id: number;
  user_id: number;
  User: User;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  Department: IDepartment;
  Designation: IDesignation;
  employee_id: string;
  profile_image: string;
}

interface IDepartment {
  id: number;
  name: string;
}
interface IDesignation {
  id: number;
  name: string;
}

const Status = [
  { value: "", label: "All Tasks" },
  { value: "inprogress", label: "Working" },
  { value: "todo", label: "To do" },
  { value: "completed", label: "Finished" },
  { value: "onhold", label: "Hold" },
];

const Tasks = () => {
  const navigate = useNavigate();
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [tasksTableData, setTasksTableData] = useState<IRowData[]>([]);
  const [status, setStatus] = useState<string>("");
  const [tasksCount, setTasksCount] = useState(0);
  const [numOfItems2, setNumOfItems2] = useState(10);
  const [page2, setPage2] = useState(1);
  const [searchTerm2, setSearchTerm2] = useState("");
  const selectedCalendarRef = useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const handleSelectedDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
  };
  const handleSelectedCalanderClick = () => {
    setIsSelectedOpen(!isSelectedOpen);
  };
  const handleClickOutside = () => {
    setIsSelectedOpen(false); // Close when clicked outside
  };
  const updateSearchTerm2 = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm2(event.target.value);
      setPage2(1);
    }, 100),
    [setSearchTerm2]
  );

  const statusMap = Object.fromEntries(
    Status.map(({ value, label }) => [value, label])
  );

  function handleNumberOfPages2(value: number) {
    setNumOfItems2(value);
  }

  function handlePageChange2(value: number) {
    setPage2(value);
  }

  const fetchTasksList = useCallback(async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Task_List(
        {
          project_id: projectId,
          numOfItems: numOfItems2,
          page: page2,
          searchTerm: searchTerm2,
          status: status,
          date: selectedDate,
        }
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        // setTasksTableData(response.data?.result?.rows || []);
        const rows = response?.data?.result?.rows || [];

        const formattedData = rows.map((row: any) => ({
          id: row?.id,
          project_id: row?.project_id,
          customer_id: row?.customer_id,
          group_id: row?.group_id,
          task_name: row?.task_name,
          estimated_hours:
            row?.estimated_hours && row?.estimated_hours !== 0
              ? row?.estimated_hours
              : null,
          actual_hours: row?.actual_hours,
          start_date: row?.start_date,
          end_date: row?.end_date,
          status: row?.status,
          description: row?.description,
          task_document: row?.task_document,
          TaskResources: row?.TaskResources,
          Module: row?.Module,
          ProjectMilestone: row?.ProjectMilestone,
          efforts: row?.efforts,
        }));

        setTasksTableData(formattedData);
        setTasksCount(response.data?.result?.count || 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  }, [projectId, searchTerm2, numOfItems2, page2, selectedDate, status]);

  useEffect(() => {
    fetchTasksList();
  }, [fetchTasksList]);

  const columns2: ColumnDef<IRowData>[] = [
    {
      accessorKey: "milestone_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Milestone / Sprint
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.ProjectMilestone?.milestone_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="py-3">
            {row?.original?.ProjectMilestone?.milestone_name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "module_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Module
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Module?.module_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="py-3">
            {row?.original?.Module?.module_name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.id ?? "-",
      cell: ({ row }) => {
        return <div className="py-3">{row?.original?.id ?? "-"}</div>;
      },
    },
    {
      accessorKey: "task_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "category",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Assigned To
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.TaskResources?.[0]?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const data = row?.original;

        if (data?.TaskResources?.length > 0) {
          const [firstUser, ...otherUsers] = data?.TaskResources;

          return (
            <div className="flex items-center gap-1">
              {firstUser?.User && (
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={firstUser?.User?.profile_image || profile}
                    alt="Profile"
                  />
                  <div className="flex flex-col justify-between">
                    <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                      {firstUser?.User?.firstName} {firstUser?.User?.lastName} (
                      {firstUser.User?.Department?.name})
                    </span>
                    <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                      {firstUser?.User?.employee_id}
                    </span>
                  </div>
                </div>
              )}

              {otherUsers?.length > 0 && (
                <span className="text-gray-900 pb-1">
                  ,{" "}
                  <span className="rounded-full p-1 bg-[#E5E7EB] ">
                    +{otherUsers?.length}
                  </span>
                </span>
              )}
            </div>
          );
        }

        return "-";
      },
    },
    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.start_date
          ? new Date(row.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.start_date
          ? new Date(row?.original?.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "end_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_date
          ? new Date(row.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.end_date
          ? new Date(row?.original?.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Estimated hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.estimated_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.estimated_hours
              ? convertHoursIntoHoursMinutes(
                  row?.original?.estimated_hours ?? 0
                )
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Filled hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.actual_hours ?? "-",
      cell: ({ row }) => {
        // return <div className="">{row?.original?.actual_hours ?? "-"}</div>;
        return (
          <div className="">
            {row?.original?.actual_hours
              ? // ? `${row?.original?.actual_hours} hrs`
                convertHoursIntoHoursMinutes(row?.original?.actual_hours ?? 0)
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "variance",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Variance
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // accessorFn: (row) =>
      //   row?.status
      //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
      //     : "-",
      cell: ({ row }) => {
        let diff = null;
        if (row?.original?.actual_hours && row?.original?.estimated_hours) {
          // diff = row?.original?.estimated_hours - row?.original?.actual_hours;
          diff = calculateVariance(
            row?.original?.estimated_hours,
            row?.original?.actual_hours
          );
        }
        return <div className="">{diff ?? "-"}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Progress
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // accessorFn: (row) =>
      //   row?.status
      //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
      //     : "-",
      cell: ({ row }) => {
        let diff = null;
        let status = "";
        if (row?.original?.actual_hours && row?.original?.estimated_hours) {
          diff = row?.original?.estimated_hours - row?.original?.actual_hours;
          status = diff < 0 ? "Delay" : "On Time";
        }

        // return <div className="capitalize">{status ?? "-"}</div>;
        return row?.original?.actual_hours && row.original?.estimated_hours ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full border ${
              status === "On Time"
                ? "border-[#0F9446] bg-[rgba(15,148,70,0.10)]"
                : "border-[#EB3F3F] bg-[#EDCFCF]"
            }`}
          >
            {status}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status ?? "-";
        return <div>{statusMap[status] ?? status}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/view-task-details", {
                  state: {
                    id: row?.original?.id,
                    path: "/view-project-details",
                  },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-task", {
                  state: {
                    id: row?.original?.id,
                    path: "/view-project-details",
                  },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex justify-between mt-4">
        <Search2 onChange={updateSearchTerm2} label="Search with task name" />
        <div className="flex gap-4">
          <div
            className="flex h-12 pl-4 pr-2 justify-between items-center rounded-md border-[1px] border-solid border-[#E2E8F0] bg-white"
            ref={selectedCalendarRef}
          >
            <DatePicker
              className="w-[125px] text-center text-['Inter'] font-[400] cursor-pointer pl-1 pr-2"
              dateFormat={"dd MMM yyyy"}
              selected={selectedDate?.toDate() || null} // pass null if no date is selected
              onChange={(date: Date | null) => {
                handleSelectedDateChange(date ? dayjs(date) : null); // convert to dayjs or null
                handleSelectedCalanderClick();
              }}
              open={isSelectedOpen}
              onInputClick={handleSelectedCalanderClick}
              onClickOutside={handleClickOutside}
              isClearable
              placeholderText="DD/MM/YYYY"
              readOnly
              customInput={
                <input
                  style={{
                    outline: "none",
                    border: "none",
                    boxShadow: "none",
                    textAlign: "left",
                  }}
                />
              }
            />
            <img
              src={date2}
              alt="date icon"
              className="w-5 h-5 cursor-pointer mr-1"
              onClick={handleSelectedCalanderClick}
            />
          </div>

          {/* <TextField
                    id="outlined-required"
                    select
                    label="Task Status"
                    placeholder="Select Status"
                    name="status"
                    value={status}
                    sx={{
                      height: "48px",
                      "& .MuiOutlinedInput-root": {
                        height: "100%",
                        width: "150px"
                      },
                    }}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {Status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField> */}
          <TextField
            id="outlined-required"
            select
            // label="Task Status"
            placeholder="Select Status"
            name="status"
            value={status}
            sx={{
              height: "48px",
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                height: "100%",
                minWidth: 80,
              },
              "& fieldset": {
                border:
                  "2px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
              },
              "&:hover fieldset": {
                borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important",
                borderWidth: "2px",
              },
            }}
            onChange={(e) => setStatus(e.target.value)}
            SelectProps={{
              displayEmpty: true,
            }}
          >
            {Status.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="overflow-x-auto mt-5">
        <div className="table_main_content w-full relative overflow-auto bg-white ">
          <DynamicTable<IRowData>
            onRowClick={(row) => {
              navigate("/view-task-details", {
                state: {
                  id: row?.id,
                  path: "/view-project-details",
                },
              });
            }}
            data={tasksTableData}
            loading={false}
            columns={columns2}
            enableSorting
            enablePagination
          />
        </div>

        {tasksCount >= 10 && (
          <TablePaginationDemo
            count={tasksCount}
            handleItemsChange={handleNumberOfPages2}
            handlePageChange={handlePageChange2}
            currentPage={page2}
            numOfItems={numOfItems2}
          />
        )}
      </div>
    </div>
  );
};

export default Tasks;
