import axios from "axios";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import { GET_USER_ROLE_ACESS_LIST, SEND_CREATE_USER_DATA } from "../api";
import axiosInstance from "../../../app/api/axiosInstance";

export const User_Management_List_Api = (url: string) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${base_api}${url}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Send_Create_User_Data = (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  country_code: string,
  role_id: number,
  group_access: number[],
  resource_list: number[],
  customer_id: number
) => {
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.post(
    `${SEND_CREATE_USER_DATA}`,
    {
      email,
      phone,
      firstName,
      lastName,
      country_code,
      group_access,
      resource_list,
      customer_id,
      role_id,
    },
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Customer_User_Api = (id: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_CREATE_USER_DATA}/${id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Get_User_Role_Access_List = (role_id: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${GET_USER_ROLE_ACESS_LIST}/${role_id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_User_Data = (id: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_CREATE_USER_DATA}/${id}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Send_Update_User_Data = (
  firstName: string,
  lastName: string,
  phone: string,
  country_code: string,
  role_id: number,
  status: string,
  group_access: number[],
  user_id: number
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SEND_CREATE_USER_DATA}/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      phone,
      firstName,
      lastName,
      country_code,
      group_access,
      role_id,
      status,
    }),
  });
};
