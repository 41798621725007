import React, { forwardRef, useEffect, useRef, useState } from "react";
import SelectDropdown from "../EmployeeManagement/SelectDropDown";
import { Department_Unit } from "../../redux/actions/Employee/Employee";
import DatePicker from "react-datepicker";
import { createGlobalStyle } from "styled-components";
import date from "../../assets/date.svg";
import { X } from "lucide-react";
import { Button } from "../common/Button";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface ModalProps {
  isOpen: boolean;
  isDate: boolean;
  isOther: boolean;
  heading: string;
  onClose: () => void;
  onApply: () => void;
  selectnameDepartment: string;
  optionsDepartment?: Business_Unit[] | Department_Unit[] | undefined;
  onOptionSelectDepartment: (
    option: Business_Unit | Department_Unit | undefined
  ) => void;
  selectnameBusiness: string;
  optionsBusiness?: Business_Unit[] | Department_Unit[] | undefined;
  onOptionSelectBusiness: (
    option: Business_Unit | Department_Unit | undefined
  ) => void;
  // selectnameProject: string;
  // optionsProject: Business_Unit[] | Department_Unit[] | undefined;
  // onOptionSelectProject: (option: Business_Unit | Department_Unit) => void;

  // date range
  selected: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  isCalendarOpen: boolean;
  handleDateChange: (dates: [Date | null, Date | null]) => void;
  handleCalanderClick: () => void;
}
const CustomDateInput = forwardRef(
  ({ value, onClick, onClear }: any, ref: any) => (
    <div
      className="flex justify-between items-center"
      onClick={onClick}
      ref={ref}
    >
      {" "}
      <input
        type="text"
        value={value}
        className="w-full min-w-[200px] px-1 text-[12px] h-9 bg-white border-white focus-visible:outline-none text-ellipsis"
        placeholder="Month Day, Year - Month Day, Year"
      />{" "}
      {value && (
        <X
          className="clear-icon cursor-pointer mr-1"
          size={16}
          onClick={onClear}
        />
      )}{" "}
    </div>
  )
);

const Filter: React.FC<ModalProps> = ({
  isOpen,
  isDate,
  isOther,
  heading,
  onClose,
  onApply,
  selectnameDepartment,
  optionsDepartment,
  onOptionSelectDepartment,
  selectnameBusiness,
  optionsBusiness,
  onOptionSelectBusiness,
  selected,
  startDate,
  endDate,
  isCalendarOpen,
  handleDateChange,
  handleCalanderClick,
}) => {
  const [dates, setDates] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);

  // Sync state when parent props change
  useEffect(() => {
    setDates([startDate, endDate]);
  }, [startDate, endDate]);

  const handleClear = () => {
    setDates([null, null]); // Clear local date state
    handleDateChange([null, null]); // Inform parent about date clearing

    // Reset department and business dropdowns
    onOptionSelectDepartment(undefined); // Reset department filter
    onOptionSelectBusiness(undefined); // Reset business filter
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white rounded-[10px] w-[360px] py-8 px-10 flex flex-col gap-6">
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-8">
            <span className="font-[Poppins] font-medium text-[20px] leading-4 text-[#262626]">
              {heading}
            </span>
            {isDate && (
              <div className="flex h-10 pr-2 justify-between items-center rounded-md border-[1px] border-solid border-[#CACACA]">
                <DatePicker
                  selected={dates?.[0]}
                  onChange={(date) => {
                    setDates(date as [Date | null, Date | null]);
                    handleDateChange(date as [Date | null, Date | null]);
                  }}
                  startDate={dates?.[0]}
                  endDate={dates?.[1]}
                  selectsRange
                  className="w-full py-2.5 px-1 text-[12px] h-[38px] bg-white border-white"
                  customInput={<CustomDateInput onClear={handleClear} />}
                  dateFormat="MMM d, yyyy"
                  placeholderText="Month Day, Year - Month Day, Year"
                  open={isCalendarOpen}
                  onInputClick={handleCalanderClick}
                  onClickOutside={() => {
                    handleClear();
                    handleCalanderClick();
                  }}
                />
                <img
                  src={date}
                  alt="date icon"
                  onClick={handleCalanderClick}
                  className="flex items-center justify-between relative cursor-pointer"
                />
              </div>
            )}
            {isOther && (
              <>
                <SelectDropdown
                  selectname={selectnameDepartment}
                  options={optionsDepartment}
                  onOptionSelect={onOptionSelectDepartment}
                  // naam="sel Department"
                />
                <SelectDropdown
                  selectname={selectnameBusiness}
                  options={optionsBusiness}
                  onOptionSelect={onOptionSelectBusiness}
                  // naam="sel Business"
                />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between items center">
          <button className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12" onClick={onClose}>
          <div 
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Cancel
            </div>
          </button>
          <Button variant={"gradient"} onClick={onApply}>
          <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Apply
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
