import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { cannotLogin } from "../../redux/actions/authActions";
import { authActions } from "../../redux/reducers/Auth/authSlice";
import Icon from "../../assets/tick.svg";
import Header from "../common/header";
import logo from "../../image/MicrosoftTeams-image (3).png";
import "../../index.css";
import { RECOVERY } from "../../utils/routeConstant";
import { Button } from "../common/Button";
import loginimage1 from "../../image/loginslide1.png";
import loginimage2 from "../../image/loginslide2.png";
import loginlogo from "../../image/loginlogo.png";
import Carousel from "react-material-ui-carousel";
import griddesign from "../../image/ornamentlogin.png";

// import styles from "./LoginScreen.module.css";

const CannotLogin: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [recoveryCode, setRecoveryCode] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    setAutoPlay(true);
  }, []);

  const handleCannotLogin = async () => {
    // Validation: Check if all details are provided
    if (!email.trim()) {
      toast.dismiss();
      toast.error("Please fill complete details.");

      return;
    }
    if (!isValidEmail(email)) {
      toast.dismiss();
      toast.error("Please provide a valid email address");

      return;
    }

    setRecoveryCode(true);
    // Dispatch cannotLogin action

    try {
      toast.dismiss();
      toast.loading("Sending code...");
      const responseData = await dispatch<any>(cannotLogin(email));
      if (responseData.success) {
        // Redirect to recovery (you can use React Router or any other routing library)
        toast.dismiss();
        toast.success(<b>Code send successfully!</b>);
        dispatch(authActions.setLoginDetails([{ email }, false, ""])); // Dispatch action to save token in Redux
        navigate(`${RECOVERY}`);
      }
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.response.data.message);
    } finally {
      setRecoveryCode(false);
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="">
      {/* <Header /> */}
      <div className="flex justify-center  h-screen px-4 py-4 ">
        <div className="relative w-1/2 flex flex-col justify-center items-center  bg-gradient-to-r from-[#E8EBF6] to-[#F1E3FD] rounded-[8px] overflow-hidden ">
          <img
            src={griddesign}
            alt="Gridline Ornament"
            className="absolute top-[0px] left-[0px] w-[424px] h-[370px] object-contain pointer-events-none"
          />
          <section className=" rounded-xl w-full min-h-screen flex flex-col justify-between pt-10 pb-10 ">
            <div className=" top-1 pl-4">
              <div className="font-poppins text-[35px] font-[600] text-[#020617] leading-[44px] mb-4">
                Automated HR Workflows for Smarter Management.
              </div>
              <div className="w-[98px] h-[2px] bg-[#374151]"></div>
            </div>

            <div dir="rtl">
              <Carousel
                autoPlay={autoPlay}
                interval={5000}
                animation="slide"
                duration={500}
                indicators={false}
                navButtonsAlwaysInvisible={true}
                stopAutoPlayOnHover={false}
                className="rounded-lg overflow-hidden"
                indicatorContainerProps={{
                  style: { marginTop: "-10px" },
                }}
              >
                <div className=" p-4  flex flex-col items-center text-center">
                  <h3 className="text-[18px] font-poppins font-[500]">
                    Track Employee Attendance
                  </h3>
                  <p className="text-sm text-gray-600 text-center max-w-[400px]">
                    Stay updated with employee work hours, leaves, and
                    attendance history.
                  </p>
                  <img
                    src={loginimage1}
                    alt="Attendance"
                    className=" w-[630px] object-contain mt-4 mb-2 rounded"
                  />
                </div>

                <div className=" p-4  flex flex-col items-center text-center">
                  <h3 className="text-[18px] font-poppins font-[500]">
                    Track Project Health and Profitability
                  </h3>
                  <p className="text-sm text-gray-600 text-center max-w-[400px]">
                    Track progress, assign tasks, and manage contributions
                    seamlessly.
                  </p>

                  <img
                    src={loginimage2}
                    alt="Project"
                    className=" w-[630px] object-contain mt-4 mb-2 rounded"
                  />
                </div>
              </Carousel>
            </div>
          </section>
        </div>
        <div className="w-1/2 bg-white flex flex-col items-center justify-center p-10 rounded-xl">
          <div className=" items-center gap-2 mb-6">
            <img
              src={loginlogo}
              alt="Logo"
              className=" w-[200px] h-auto object-cover mb-2 rounded"
            />
          </div>
          <div className=" shadow-lg border border-[#E2E8F0] rounded-lg p-4 top-[73px]">
            <div className=" mb-4 ml-5">
              <p className="text-neutral-800 font-['Inter'] font-semibold text-[22px] text-[#374151]">
                {" "}
                Forget Password?
              </p>
              <p className="text-neutral-400 font-['poppins'] font-[400] text-[14px] text-[#374151] max-w-full break-words">
                Please enter your email address to continue <br/> the forgot password
                process.
              </p>
            </div>
            <form className="flex flex-col">
              <label
                className="text-neutral-800 text-[14px] font-semibold font-['Poppins'] mt-2 mb-[4px] ml-5 text-left"
                htmlFor="email"
              >
                Email
              </label>
              <div className="flex justify-center">
                <input
                  className="w-[18.75rem] h-10 border-opacity-30 rounded-[3px] border border-[#262626] ml-5 mr-[20px] px-2"
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>
              <div className="flex justify-center mt-4 px-6" >
                <Button
                  variant={"gradient"}
                  type="button"
                  className="w-full"
                  onClick={handleCannotLogin}
                  disabled={recoveryCode}
                >
                  Send recovery code
                </Button>
              </div>

              <div className="flex flex-col md:flex-row mt-4">
                <div className="mb-4 md:mb-0">
                  <NavLink
                    to="/"
                    className="text-blue-400 text-[12px] font-[600] font-['Poppins'] ml-[7.8rem]"
                  >
                    Back to log in
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
          <p className="font-poppins text-[14px] font-[400] text-[#6B7280] leading-[22px] mb-4  text-center  pt-3">
            © Go-EMS. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CannotLogin;
