import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Fetch_User_Asset } from "../common/services/Employee";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import { useNavigate } from "react-router-dom";
import edit from "../../assets/Crud_Icons/edit.svg";
import eye from "../../assets/Crud_Icons/view.svg";
import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import DynamicTable from "../common/DynamicTable";
import { debounce } from "lodash";
import moment from "moment";
import axiosInstance from "../../app/api/axiosInstance";

interface User {
  id: number;
  prefix: string;
  firstName: string;
  lastName: string;
  employee_id: string;
  department_id: number;
  group_id: number;
  profile_image: string;
}

interface AssetCategory {
  id: number;
  category: string;
  quantity: number;
  customer_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface IAsset {
  id: number;
  user_id: number;
  customer_id: number;
  asset_category_id: number;
  asset_name: string;
  asset_serial_number: string;
  assigned_date: string;
  acknowledgment_status: boolean;
  description: string;
  project_id: number;
  image_filename: string[];
  createdby: number;
  modifiedby: number;
  isactive: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  User: User;
  AssetCategory: AssetCategory;
}

export default function EmployeeAsset({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [assetData, setAssetData] = useState<IAsset[]>([]);
  const navigate = useNavigate();
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 1000),
    [setSearchTerm]
  );

  const fetchAssetData = useCallback(async () => {
    try {
      const response = await Fetch_User_Asset({
        id: id,
        numOfItems: numOfItems,
        page: page,
        searchTerm: searchTerm,
        endDate: endDate,
        startDate: startDate,
        customer_id: customer_id,
      });
      if (response.status === 200) {
        setAssetData(
          response.data?.result?.rows ? response.data?.result?.rows : []
        );
        setCount(response?.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [customer_id, endDate, id, numOfItems, page, searchTerm, startDate]);
  useEffect(() => {
    fetchAssetData();
  }, [fetchAssetData]);
  const columns: ColumnDef<IAsset>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "AssetCategory",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.AssetCategory?.category ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.AssetCategory?.category ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "asset_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.asset_name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.asset_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "assigned_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Assigned Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.assigned_date
          ? new Date(row.assigned_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.assigned_date
          ? new Date(row.original.assigned_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "assigned_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Release Date
        </Button>
      ),

      cell: ({ row }) => {
        return <div className="">-</div>;
      },
    },
    {
      accessorKey: "acknowledgment_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Acknowledgement
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const status: string = row.original.acknowledgment_status
          ? "Yes"
          : "No";
        return <div className="">{status}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/view-asset`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-asset`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const downloadExcelFile = async () => {
    try {
      let query = `/api/v1/asset-report?customer_id=${customer_id}&user_id=${id}`;

      if (startDate && endDate) {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Asset_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file");
    }
  };

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div className="">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search onChange={updateSearchTerm} label="Search" />
            <div className="h-full flex items-center">
              <div className="h-full pl-4">
                <Button
                  onClick={downloadExcelFile}
                  className="border rounded-md gap-2"
                >
                  <Download /> Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-2">
        <div className="table_main_content w-full max-h-[calc(100vh-27rem)] relative overflow-auto bg-white">
          <DynamicTable<IAsset>
            data={assetData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </div>
  );
}
