import { useEffect, useState, useRef } from "react";
import eye from "../../../assets/Crud_Icons/view.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import profile from "../../../assets/Ellipse 580.png";
import TablePaginationDemo from "../../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../../assets/download_new.svg";
import { Fetch_Leave_Balance_List } from "../../common/services/Leave";
// import { debounce } from "lodash";
// import Search from "../../SearchBar/Search";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../../common/DynamicTable";
import TableSkeleton from "../../common/TableSkeleton";
import * as XLSX from "xlsx";
import PersistedSearch from "../../EmployeeManagement/EmployeeListSearch";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";


interface ILeave_Balance {
  firstName: string;
  lastName: string;
  middleName: string;
  employee_id: string;
  remaining_leave: number;
  total_leave: number;
  used_leave: number;
  profile_image: string;
  id: string;
}

const LeaveBalance = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  // const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<ILeave_Balance[]>([]);
  const [loading, setLoading] = useState(false);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  // const updateSearchTerm = useCallback(
  //   debounce((event: any) => {
  //     setSearchTerm(event.target.value);
  //   }, 1000), // delay in ms
  //   [setSearchTerm]
  // );

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_year" || event.key === "end_year") {
      const start = localStorage.getItem("start_year") || "";
      const end = localStorage.getItem("end_year") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_year") || "";
    const end = localStorage.getItem("end_year") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_Balance_List(
        numOfItems,
        page,
        searchTerm ?? ""
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  // const isFirstRender = useRef(true);

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    fetchData();
  }, [page, numOfItems, searchTerm, startSession, endSession]);

  useEffect(()=>{
    setPage(1);
  }, [searchTerm])

  const handleDownload = () => {
    if (tableData?.length > 0) {
      // Define the column headers
      const columns = [
        "Employee Name",
        "Employee ID",
        "Total Leave",
        "Used Leave",
        "Remaining Leave",
      ];

      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...tableData?.map((row) => [
          `${row?.firstName} ${row?.lastName}`,
          row?.employee_id,
          row?.total_leave,
          row?.used_leave,
          row?.remaining_leave,
        ]),
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leave Balance Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "leave_balance_data.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };

  const columns: ColumnDef<ILeave_Balance>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 pl-2 pr-2.5">
            <img
              className="w-8 h-8 rounded-full"
              src={item?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item?.firstName} {item?.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item?.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "total_leave",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Total Leave
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.total_leave ?? "-",
      cell: ({ row }) => {
        const name: number = row?.original?.total_leave ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "used_leave",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Used Leave
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.used_leave ?? "-",
      cell: ({ row }) => {
        const name: number = row?.original?.used_leave ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "remaining_leave",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Remaining Leave
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.remaining_leave ?? "-",
      cell: ({ row }) => {
        const name: number = row?.original?.remaining_leave ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/leave-balance-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-leave-balance", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-6 flex justify-between">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            {/* <Search
              onChange={updateSearchTerm}
              label="Search with Employee Name & Id"
            /> */}
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full">
              <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<ILeave_Balance>
              onRowClick={(row) => {
                navigate("/leave-balance-details", {
                  state: { id: row?.id },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </>
  );
};

export default LeaveBalance;
