import { Height } from "@mui/icons-material";
import React, { useState } from "react";
import RichTextEditor, { EditorValue } from "react-rte";

interface MyStatefulEditorProps {
  onChange?: (value: string) => void;
  disable?: boolean;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({
  onChange,
  disable,
}) => {
  const [value, setValue] = useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );

  const handleChange = (value: EditorValue) => {
    setValue(value);
    if (onChange) {
      onChange(value.toString("html"));
    }
  };

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
      disabled={disable}
      className="h-[300px]"
      editorClassName="h-full relative z-2"
      placeholder="Enter Description here ..."
      toolbarClassName="relative z-2"
      toolbarStyle={{
        zIndex: 0,
      }}
      editorStyle={{
        height: "240px",
      }}
    />
  );
};

export default MyStatefulEditor;
