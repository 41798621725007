import React from "react";
import { Box } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import CustomTabs from "../common/CustomTabs";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import { useDispatch } from "react-redux";
import AttendenceDetails from "./AttendenceDetails";
import maskPlus from "../../image/Mask group.svg";
import { createGlobalStyle } from "styled-components";
import { Button } from "../common/Button";
import AttendanceOverview from "./Overview/AttendanceOverview";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setMainTabValue } from "../../redux/reducers/Tab/TabSlice";
import BusinessUnitSelect from "../common/shared/BusinessUnitSelect";
import EmployeeLocation from "./EmpLocation/EmployeeLocation";
import { setFilterLocationData } from "../../redux/reducers/Attendance/LOcationFilter";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

export const Attendance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const value = useSelector((state: RootState) => state.mainTabValue.value);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
        search: "",
      })
    );
    dispatch(
      setFilterLocationData({
        department: [],
        group: [],
        date: null,
        search: "",
      })
    );
    dispatch(setMainTabValue(newValue));
  };

  const tabs = [
    { label: "Overview", value: "1" },
    { label: "Employee Attendance", value: "2" },
    { label: "Employee Location", value: "3" },
  ];

  const [selectedBusinesses, setSelectedBusinesses] = React.useState<number[]>(
    []
  );

  return (
    <div className="px-4 pt-6 pb-12 bg-white min-h-[90vh]">
      <div className="">
        <Box>
          <TabContext value={value}>
            <div className="flex justify-between">
              <Box>
                <CustomTabs tabs={tabs} onChange={handleChange} />
              </Box>
              {value === "1" && (
                <div className="">
                  <BusinessUnitSelect
                    selectedBusinesses={selectedBusinesses}
                    setSelectedBusinesses={setSelectedBusinesses}
                  />
                </div>
              )}

              {value === "2" && (
                <div className="flex gap-x-2">
                  <Button
                    onClick={() =>
                      navigate("/attendance-management/add-attendence")
                    }
                    variant={"gradient"}
                  >
                    <img
                      className="w-5 h-5 mr-2"
                      src={maskPlus}
                      alt="plus icon"
                    />
                    Add Attendence Details
                  </Button>
                </div>
              )}
            </div>

            <TabPanel className="tabpanel" value="1">
              <AttendanceOverview groupIds={selectedBusinesses} />
            </TabPanel>
            <TabPanel className="tabpanel" value="2">
              <AttendenceDetails />
            </TabPanel>
            <TabPanel className="tabpanel" value="3">
              <EmployeeLocation />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
