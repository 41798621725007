import React, { useEffect, useState } from "react";
import right from "../../../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

import { IconButton, MenuItem } from "@mui/material";

import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import edit from "../../../../assets/Crud_Icons/edit.svg";

import { X } from "lucide-react";
import { Fetch_Clients_List_Data } from "../../../common/services/Clients";
import {
  createProjectsApi,
  editProjectDetail,
  fetchEditProject,
  Fetch_Milestone_List,
} from "../../../common/services/Project";
import toast from "react-hot-toast";
import { Button } from "../../../common/Button";
import EmployeeSearch from "../../../EmployeeManagement/EmployeeSearch";

import FileUpload from "../../../ExpenseManagement/FileUpload";
import MyStatefulEditor from "../../Editor";
import { useDispatch } from "react-redux";
import {
  setProjectTab,
  setProjectId,
  setProjectStartDate,
  setProjectEndDate,
} from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import AddressAutocomplete from "../../../common/AddressAutocomplete";
import { IAddress } from "../AddProject/AddProjectDetails";
import { Clear } from "@mui/icons-material";
import { useInView } from "react-intersection-observer";
import SearchResources from "../../SearchResource";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface FormValues {
  project_name: string;
  status: string;
  currency: string;
  client: string;
  // start_date: string;
  // end_date: string;
  actual_hours: number | null;
  project_type: string;
  type: string;
  no_of_milestone: number | null;
  no_of_sprint: number | null;
  no_of_resource: number | null;
  contractual_hours: number | null;
  contractual_cost: number | null;
  approval_code: string | null;
}

interface Client_List {
  id: string;
  client_name: string;
}
export interface Milestone {
  name: string | null;
  hours: number | null;
  price: number | null;
  billing_date?: Dayjs | null;
  editable?: boolean;
  action?: string;
  id?: number | null;
}
export interface ICR {
  hours: number | null;
  price: number | null;
  milestone_id?: number | null;
  id?: number | null;
  action?: string;
}
export interface IResource {
  id?: number | null;
  per_hour_cost: number | null;
  user_id: string | null;
  employee_type?: string | null;
}
export interface MilestoneOption {
  id: number;
  name: string;
}

interface MilestoneError {
  hours?: string;
  price?: string;
  billing_date?: string;
}

interface IResourceError {
  per_hour_cost?: number | string | null;
  user_id?: string | null;
  employee_type?: string | null;
}

interface IAddressError {
  place?: string;
  // coordinates: string[];
}

const EditProjectDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [tableData, setTableData] = useState<Client_List[]>([]);
  const [clientLoading, setClientLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateError, setDateError] = useState(false);
  const [enddateError, setEndDateError] = useState(false);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");
  const [employeeError, setEmployeeError] = useState<string | null>(null);
  const [milestoneErrors, setMilestoneErrors] = useState<MilestoneError[]>([]);
  const [sprintErrors, setSprintErrors] = useState<MilestoneError[]>([]);
  const [resourceErrors, setResourceErrors] = useState<IResourceError[]>([]);
  const [milestoneOptions, setMilestoneOptions] = useState<MilestoneOption[]>(
    []
  );
  const [disableType, setDisableType] = useState(true);
  const [projectName, setProjectName] = useState("");

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }
  const location = useLocation();
  const path = location.state?.path;

  // const projectId = location?.state?.id ?? null;
  const [formValues, setFormValues] = useState<FormValues>({
    project_name: "",
    status: "",
    currency: "",
    client: "",
    // start_date: "",
    // end_date: "",
    actual_hours: null,
    project_type: "",
    type: "",
    no_of_milestone: null,
    no_of_resource: null,
    no_of_sprint: null,
    contractual_hours: null,
    contractual_cost: null,
    approval_code: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [milestones, setMilestones] = useState<Milestone[] | null>(null);
  const [sprints, setSprints] = useState<Milestone[] | null>(null);
  //   {
  //     hours: null,
  //     price: null,
  //     billing_date: null,
  //     name: null,
  //     editable: false,
  //   },
  // ]);
  const [resEdit, setResEdit] = useState([]);
  const [resources, setResources] = useState<IResource[] | null>(null);
  const [resChange, setResChange] = useState(false);
  const [milestoneChange, setMilestoneChange] = useState(false);
  const [sprintChange, setSprintChange] = useState(false);
  const [CR, setCR] = useState<ICR[]>([
    { hours: null, price: null, milestone_id: null, id: null },
  ]);
  const [locations, setLocations] = useState<(IAddress | null)[]>([null]);
  const [locationsError, setLocationsError] = useState<IAddressError[] | {}>(
    []
  );
  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (projectId) {
      fetchEditProject(projectId)
        .then((data) => {
          if (data?.result) {
            const { project_document } = data?.result;
            const files = project_document?.map(
              (name: string, index: number) => ({
                url: project_document[index],
              })
            );
            const new_milestones = data?.result?.ProjectMilestones?.map(
              ({ total_cost, milestone_name, ...rest }: any) => ({
                ...rest,
                price: total_cost,
                name: milestone_name,
              })
            );
            const new_CR = data?.result?.ChangeRequests?.map(
              ({ total_cost, billing_date, milestone_id, ...rest }: any) => ({
                ...rest,
                price: total_cost,
                milestone_id: milestone_id ?? null,
              })
            );
            const new_resources = data?.result?.ProjectResources?.map(
              ({ billing_cost, User, ...rest }: any) => ({
                ...rest,
                per_hour_cost: billing_cost || null,
                user_id: User?.id || null,
              })
            );

            if (!data?.result?.billing_type) {
              setDisableType(false);
            }

            setProjectName(data?.result?.project_name ?? "");

            setFormValues({
              project_name: data?.result?.project_name ?? "",
              status: data?.result?.status ?? "",
              currency: data?.result?.currency ?? "",
              client: data?.result?.client_id ?? "",
              // start_date: data.result.start_date,
              // end_date: data.result.end_date,
              actual_hours: data?.result?.actual_hours,
              project_type: data?.result?.project_type ?? null,
              approval_code: data?.result?.approval_code ?? null,
              type: data?.result?.billing_type ?? null,
              no_of_milestone:
                data?.result?.billing_type === "Fix Cost" &&
                (new_milestones?.length ?? null),
              no_of_resource: new_resources?.length ?? null,
              no_of_sprint:
                data?.result?.billing_type !== "Fix Cost" &&
                (new_milestones?.length ?? null),
              contractual_hours: data?.result?.estimated_hours
                ? Number(data?.result?.estimated_hours)
                : null,
              contractual_cost: data?.result?.contractual_cost
                ? Number(data?.result?.contractual_cost)
                : null,
            });
            setCR(new_CR || []);
            if (data?.result?.billing_type === "Fix Cost")
              setMilestones(new_milestones || []);
            else setSprints(new_milestones || []);
            setResources(new_resources || []);
            setEditorContent(data?.result?.description);
            setStartDate(dayjs(data?.result?.start_date));
            setEndDate(dayjs(data?.result?.end_date));
            setResEdit(data?.result?.ProjectResources);
            // const userIds = taskResources.map((resource: any) => resource.user_id);
            // setSelectedEmployeeIds(userIds);
            setLocations(data?.result?.addresses || []);
            setUploadedFiles(files);
            dispatch(setProjectStartDate(data?.result?.start_date));
            dispatch(setProjectEndDate(data?.result?.end_date));
          }
        })
        .catch((error) => console.error(error));
    }
  }, [projectId]);
  // const [cost, setCost] = useState<Milestone>({
  //   hours: null,
  //   price: 0,
  //   billing_date: null,
  // });

  // const handleCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;

  //   setCost((prevCost) => {
  //     const updatedCost = {
  //       ...prevCost,
  //       [name]: value,
  //     };

  //     return updatedCost;
  //   });
  // };

  const handleInputChange = (
    index: number,
    field: keyof Milestone,
    value: Milestone[keyof Milestone]
  ) => {
    setMilestoneErrors([]);
    setMilestoneChange(true);
  
    if (field === "price") {
      const rawValue = value?.toString().replace(/[^0-9]/g, "");
  
  
      setMilestones((prevMilestones) => {
        const updatedMilestones = prevMilestones?.length ? [...prevMilestones] : [];
  
        updatedMilestones[index] = {
          ...updatedMilestones[index],
          [field]: rawValue === "" ? null : Number(rawValue),
        };
  
        return updatedMilestones;
      });
  
      return;
    }
  
    setMilestones((prevMilestones) => {
      const updatedMilestones = prevMilestones?.length
        ? [...prevMilestones]
        : [];

      const milestone = { ...updatedMilestones[index], [field]: value };

      updatedMilestones[index] = milestone;
      return updatedMilestones;
    });
  };
  

  const handleSprintInputChange = (
    index: number,
    field: keyof Milestone,
    value: Milestone[keyof Milestone]
  ) => {
    setSprintErrors([]);
    setSprintChange(true);
    if (field === "price") {
      const rawValue = value
        ?.toString()
        .replace(/[^0-9.]/g, "")
        .replace(/^0+/, "");

      if (rawValue === "") {
        setSprints((prevMilestones) => {
          const updatedMilestones = prevMilestones?.length
            ? [...prevMilestones]
            : [];
          updatedMilestones[index] = {
            ...updatedMilestones[index],
            [field]: null,
          };
          return updatedMilestones;
        });
        return;
      }

      if (!isNaN(Number(rawValue))) {
        setSprints((prevMilestones) => {
          const updatedMilestones = prevMilestones?.length
            ? [...prevMilestones]
            : [];
          updatedMilestones[index] = {
            ...updatedMilestones[index],
            [field]: Number(rawValue),
          };
          return updatedMilestones;
        });
      }
      return;
    }
    setSprints((prevMilestones) => {
      const updatedMilestones = prevMilestones?.length
        ? [...prevMilestones]
        : [];

      const milestone = { ...updatedMilestones[index], [field]: value };

      updatedMilestones[index] = milestone;
      return updatedMilestones;
    });
  };

  const fetchMilestoneOptions = async () => {
    try {
      const response = await Fetch_Milestone_List({
        customer_id: projectId,
        // numOfItems: 10,
        // page: 1,
      });
      if (response.status === 200) {
        setMilestoneOptions(
          response?.data?.result?.rows?.map((milestone: any) => ({
            id: milestone?.id,
            name: milestone?.milestone_name,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching milestone options:", error);
    }
  };

  useEffect(() => {
    fetchMilestoneOptions();
  }, [projectId]);

  const handleResourceChange = (
    index: number,
    field: keyof IResource,
    value: IResource[keyof IResource]
  ) => {
    setResChange(true);
    setResourceErrors([]);
    if (field === "per_hour_cost") {
      const rawValue = value
        ?.toString()
        .replace(/[^0-9.]/g, "")
        .replace(/^0+/, "");

      if (rawValue === "") {
        setResources((prev) => {
          const updatedRes = prev ? [...prev] : [];

          const res = { ...updatedRes[index], [field]: null };

          updatedRes[index] = res;
          return updatedRes;
        });
        return;
      }

      if (!isNaN(Number(rawValue))) {
        setResources((prev) => {
          const updatedRes = prev ? [...prev] : [];

          const res = { ...updatedRes[index], [field]: Number(rawValue) };

          updatedRes[index] = res;
          return updatedRes;
        });
      }
      return;
    }
    setResources((prev) => {
      const updatedRes = prev ? [...prev] : [];

      const res = { ...updatedRes[index], [field]: value };

      updatedRes[index] = res;
      return updatedRes;
    });
  };
  const validateMilestones = (): boolean => {
    // console.log({ milestones });
    if (milestones?.length) {
      let totalMilestoneHours = 0;
      let totalMilestoneCost = 0;
      const newErrors = milestones?.map((milestone) => {
        const error: MilestoneError = {};
        if (!milestone?.hours) error.hours = "Hours are required";
        if (!milestone?.price) error.price = "Price is required";
        if (
          formValues?.project_type !== "Non Billable" &&
          !milestone?.billing_date
        ) {
          error.billing_date = "Billing Date is required";
        }
        totalMilestoneCost += milestone.price || 0;
        totalMilestoneHours += milestone.hours || 0;

        return error;
      });
      if (
        formValues?.contractual_hours &&
        totalMilestoneHours > formValues?.contractual_hours
      ) {
        toast.error("Milestone hours can't exceed contractual hours.");
        return false;
      }
      if (
        formValues?.contractual_cost &&
        totalMilestoneCost > formValues?.contractual_cost
      ) {
        toast.error("Total milestone cost can't exceed contractual cost.");
        return false;
      }

      setMilestoneErrors(newErrors);
      return newErrors?.every((error) => Object.keys(error)?.length === 0);
    }
    return true;
  };

  const validateSprints = (): boolean => {
    if (sprints?.length) {
      const newErrors = (sprints || []).map((sprint) => {
        const error: MilestoneError = {};
        if (!sprint?.hours) error.hours = "Hours are required";
        if (!sprint?.price) error.price = "Price is required";
        if (
          formValues?.project_type !== "Non Billable" &&
          formValues?.type !== "Time and Material" &&
          !sprint?.billing_date
        ) {
          error.billing_date = "Billing Date is required";
        }
        return error;
      });

      setSprintErrors(newErrors);
      return newErrors?.every((error) => Object.keys(error)?.length === 0);
    }
    return true;
  };

  const validateResources = (): boolean => {
    if (resources?.length) {
      let hasDuplicate = false;
      let hasEmptyFields = false;

      const newErrors = resources?.map((resource) => {
        const error: IResourceError = {};

        if (!resource?.user_id || !resource?.per_hour_cost) {
          error.per_hour_cost = !resource?.per_hour_cost
            ? "Cost/Hour is required"
            : "";
          error.user_id = !resource?.user_id ? "User ID is required" : "";
          hasEmptyFields = true;
        }

        const duplicateUser =
          resources?.filter((res) => res?.user_id === resource?.user_id)
            ?.length > 1;

        if (duplicateUser) {
          error.user_id = "Duplicate user ID found";
          hasDuplicate = true;
        }

        return error;
      });

      setResourceErrors(newErrors);

      if (hasDuplicate) {
        toast.error("Duplicate resources are not allowed");
        return false;
      }

      if (hasEmptyFields) {
        toast.error("Please fill all resource details.");
        return false;
      }

      return newErrors.every((error) => Object.keys(error).length === 0);
    }
    return true;
  };

  //   const newErrors = sprints.map((milestone) => {
  //     const error: MilestoneError = {};
  //     if (!milestone.hours) error.hours = "Hours are required";
  //     if (!milestone.price) error.price = "Price is required";
  //     if (!milestone.billing_date)
  //       error.billing_date = "Billing Date is required";
  //     return error;
  //   });

  //   setSprintErrors(newErrors);
  //   return newErrors.every((error) => Object.keys(error).length === 0);
  // };

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues?.project_name === "")
      tempErrors.project_name = "Project Name is required";
    if (formValues?.status === "")
      tempErrors.status = "Project Status is required";
    if (formValues?.project_type === "" || !formValues.project_type)
      tempErrors.project_type = "Billing Type is required";
    if (formValues?.approval_code === "" || !formValues.approval_code)
      tempErrors.approval_code = "Employee DSR is required";
    // if (formValues.type === "" || !formValues.type)
    //   tempErrors.type = "Project Type is required";
    // if (!formValues.currency) {
    //   tempErrors.currency = "Currency is required";
    // }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   if (name === "contractual_cost") {
  //     const rawValue = value?.replace(/[^0-9.]/g, "").replace(/^0+/, "");

  //     if (rawValue === "") {
  //       setFormValues({
  //         ...formValues,
  //         [name]: null,
  //       });
  //       return;
  //     }

  //     if (!isNaN(Number(rawValue))) {
  //       setFormValues({
  //         ...formValues,
  //         [name]: Number(rawValue),
  //       });
  //     }
  //     return;
  //   }
  //   setFormValues({
  //     ...formValues,
  //     [name]: value,
  //   });
  //   if (value !== "") {
  //     setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  //   }
  //   if (name === "type") {
  //   }
  //   // time should be according to status
  //   // if (name === "status") {
  //   //   setStartDate(null);
  //   //   setEndDate(null);
  //   // }
  // };

  const formatNumber = (value: string, currency: string) => {
    if (!value) return "";

    const sanitizedValue = value.replace(/[^0-9-]/g, "");

    const number = parseInt(sanitizedValue, 10);
    if (isNaN(number)) return value;

    const currencyLocaleMap: Record<string, string> = {
      INR: "en-IN",
      USD: "en-US",
      EUR: "de-DE",
      GBP: "en-GB",
      JPY: "ja-JP",
      CNY: "zh-CN",
      AUD: "en-AU",
      CAD: "en-CA",
    };

    const locale = currencyLocaleMap[currency];

    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
    }).format(number);
  };

  const [rawCost, setRawCost] = useState<string>(
    formValues?.contractual_cost?.toString() || ""
  );

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "contractual_cost") {
      const rawValue = value.replace(/[^0-9]/g, "");

      // if (rawValue.length > 15) return;

      setRawCost(rawValue);

      setFormValues((prev) => ({
        ...prev,
        [name]: rawValue === "" ? null : Number(rawValue),
      }));
      return;
    }
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const displayedCost =
    rawCost !== "" ? formatNumber(rawCost, formValues?.currency) : "";

  const Currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    { value: "AD", label: "AD" },
    { value: "AED", label: "AED" },
  ];

  const Status = [
    { value: "upcoming", label: "Upcoming" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
    { value: "onhold", label: "Onhold" },
  ];

  const ProjectTypes = [
    { value: "Fix Cost", label: "Fixed Cost" },
    { value: "Time and Material", label: "Time and Material" },
    { value: "Man Month", label: "Man Month" },
  ];

  const fetchClientsList = async (pageNum: number) => {
    try {
      const response = await Fetch_Clients_List_Data(customer_id, 10, pageNum);
      if (clientLoading) return;
      setClientLoading(true);
      if (response.status === 200) {
        const fetchedData = response.data?.result?.rows || [];
        setTableData((prev) => [...(prev || []), ...fetchedData]);
        setTotalCount(response.data?.result?.count || 0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setClientLoading(false);
  };

  useEffect(() => {
    setTableData([]);
    setCurrentPage(1);
    setTotalCount(null);
    fetchClientsList(1);
  }, [customer_id]);

  useEffect(() => {
    if (inView && tableData?.length < (totalCount || 0) && !clientLoading) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [clientLoading, inView, tableData?.length, totalCount]);

  useEffect(() => {
    if (currentPage > 1) fetchClientsList(currentPage);
  }, [currentPage]);
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, project_type: e.target.value });
    if (e.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, project_type: "" }));
    }
  };
  const handleDSRRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, approval_code: e.target.value });
    if (e.target.value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, approval_code: "" }));
    }
  };
  const handleAddLoaction = () => {
    setLocations((prev) => [...prev, null]);
  };

  const handlelocationChange = (
    index: number,
    value: google.maps.places.PlaceResult | null
  ) => {
    setLocations((prev) => {
      const updated = [...prev];
      updated[index] = {
        place: value?.formatted_address ?? "",
        coordinates: [
          value?.geometry?.location?.lat() ?? 0,
          value?.geometry?.location?.lng() ?? 0,
        ],
      };
      return updated;
    });
  };

  const postData = async () => {
    setLoading(true);
    let name: string;
    if (formValues?.type === "Fix Cost") {
      name = "Milestone";
    } else {
      name = "Sprint";
    }

    const edit_milestones = milestones?.map(({ editable, ...item }, index) => ({
      ...item,
      name: item.name?.trim() || `Milestone ${index + 1}`,
    }));

    const edit_sprints = sprints?.map(({ editable, ...item }, index) => ({
      ...item,
      name: item.name?.trim() || `Sprint ${index + 1}`,
    }));

    const filteredCR = CR?.filter(
      (cr) => cr?.hours !== null || cr?.price !== null
    );

    if (filteredCR && !validateChangeRequests(filteredCR)) {
      toast.error(
        "Please fill all fields in the Change Request in case you want to add a Change Request."
      );
      return;
    }
    const newLocations =
      locations?.filter((location) => location !== null) || [];

    // if (!validateResources2(resources)) {
    //   toast.error("Please fill all resource details.");
    //   return;
    // }

    try {
      let response;
      const payload: any = {
        project_name: formValues?.project_name,
        currency: formValues?.currency,
        client: formValues?.client ?? null,
        start_date: dayjs(startDate)?.format("YYYY-MM-DD"),
        end_date: endDate ? dayjs(endDate)?.format("YYYY-MM-DD") : null,
        status: formValues?.status,
        project_documents: uploadedFiles?.map((files) => files?.url),
        project_type: formValues?.project_type,
        approval_code: formValues?.approval_code,
        description: editorContent?.replace(/<[^>]+>/g, ""),
        billing_type: formValues?.type,
        projectId: projectId,
        estimated_hours: formValues?.contractual_hours
          ? Number(formValues?.contractual_hours)
          : null,
        contractual_cost: formValues?.contractual_cost
          ? Number(formValues?.contractual_cost)
          : null,
        addressses: newLocations,
      };
      if (filteredCR) {
        payload.ChangeRequests = filteredCR;
      }
      if (milestoneChange) {
        payload.milestones = edit_milestones;
      }
      if (sprintChange) {
        payload.milestones = edit_sprints;
      }
      if (resChange) {
        payload.resources = resources;
      }
      response = await editProjectDetail(payload);

      // const Id = response.data.result.id;

      setOpen(true);

      // setTimeout(() => {
      //   setOpen(false);
      //   //navigate(`${ROLE_MANAGEMENT}`);
      //   navigate(`/edit-resources-details/${Id}`, {
      //     state: { id: Id },
      //   });
      // }, 1000);
      if (path === "/view-project-details") {
        navigate(path, {
          state: { tab: "summary", id: projectId },
        });
      } else {
        // navigate("/project-management");
        dispatch(setProjectTab(1));
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };
  const handleMilestoneCountChange = (rawValue: string) => {
    setFormValues((prev) => ({
      ...prev,
      no_of_milestone: rawValue === "" ? null : Number(rawValue),
    }));
    const count = Number(rawValue);

    const curr_milestone = milestones ? milestones : [];
    setMilestoneChange(true);
    if (count > curr_milestone?.length) {
      const newMilestones = Array.from(
        { length: count - curr_milestone?.length },
        () => ({
          hours: null,
          price: null,
          billing_date: null,
          name: null,
          editable: false,
          id: null,
        })
      );
      setMilestones([...curr_milestone, ...newMilestones]);
    } else {
      setMilestones(curr_milestone.slice(0, count));
    }
    setMilestoneErrors([]);
  };

  const handleSprintCountChange = (rawValue: string) => {
    setFormValues((prev) => ({
      ...prev,
      no_of_sprint: rawValue === "" ? null : Number(rawValue),
    }));
    const count = Number(rawValue);

    const curr_sprint = sprints ? sprints : [];
    setSprintChange(true);
    if (count > curr_sprint?.length) {
      const newSprints = Array.from(
        { length: count - curr_sprint?.length },
        () => ({
          hours: null,
          price: null,
          billing_date: null,
          name: null,
          editable: false,
          id: null,
        })
      );
      setSprints([...curr_sprint, ...newSprints]);
    } else {
      setSprints(curr_sprint?.slice(0, count));
    }
    setSprintErrors([]);
  };

  const handleDeleteMilestone = (index: number) => {
    setMilestoneChange(true);
    const curr_milestone = milestones?.length ? [...milestones] : [];

    if (curr_milestone?.length > 1) {
      const updatedMilestones = curr_milestone
        ?.map((milestone, i) => {
          if (i === index) {
            return milestone?.id ? { ...milestone, action: "delete" } : null;
          }
          return milestone;
        })
        ?.filter((milestone): milestone is Milestone => milestone !== null);

      setMilestones(updatedMilestones);
      setFormValues((prevValues) => ({
        ...prevValues,
        no_of_milestone: updatedMilestones?.filter(
          (m) => !m?.action || m?.action !== "delete"
        )?.length,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        no_of_milestone: 0,
      }));
      setMilestones(null);
    }
  };

  const handleDeleteSprint = (index: number) => {
    setSprintChange(true);
    const curr_sprints = sprints ? [...sprints] : [];

    if (curr_sprints.length > 1) {
      const updatedSprints = curr_sprints
        ?.map((sprint, i) => {
          if (i === index) {
            return sprint.id ? { ...sprint, action: "delete" } : null;
          }
          return sprint;
        })
        ?.filter((sprint): sprint is Milestone => sprint !== null);

      setSprints(updatedSprints);
      setFormValues((prevValues) => ({
        ...prevValues,
        no_of_sprint: updatedSprints?.filter(
          (s) => !s?.action || s?.action !== "delete"
        ).length,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        no_of_sprint: 0,
      }));
      setSprints(null);
    }
  };

  const handleAddMilestone = () => {
    setMilestones([
      ...(milestones || []),
      {
        name: `Milestone ${milestones ? milestones?.length + 1 : 1}`,
        hours: 0,
        price: null,
        billing_date: null,
        editable: false,
        id: null,
      },
    ]);
    setFormValues({
      ...formValues,
      no_of_milestone: milestones ? milestones?.length + 1 : 1,
    });
  };

  const handleAddSprint = () => {
    setSprints([
      ...(sprints || []),
      {
        name: `Sprint ${sprints ? sprints?.length + 1 : 1}`,
        hours: 0,
        price: null,
        billing_date: null,
        editable: false,
        id: null,
      },
    ]);
    setFormValues({
      ...formValues,
      no_of_sprint: sprints ? sprints?.length + 1 : 0,
    });
  };

  const handleResourceCountChange = (count: number) => {
    setFormValues({
      ...formValues,
      no_of_resource: count,
    });
    setResChange(true);

    const currentResources = resources ?? [];

    if (count > currentResources?.length) {
      const newResources = Array.from(
        { length: count - currentResources?.length },
        () => ({
          per_hour_cost: null,
          user_id: null,
          employee_type: "Employee",
          id: null,
        })
      );
      setResources([...currentResources, ...newResources]);
    } else {
      setResources(currentResources?.slice(0, count));
    }
  };

  const handleAddCr = () => {
    const newCR = {
      hours: null,
      price: null,
      milestone_id: null,
      id: null,
    };
    setCR((prevCR) => [...prevCR, newCR]);
  };

  const handleCRChange = (
    index: number,
    field: keyof ICR,
    value: ICR[keyof ICR]
  ) => {
    setCR((prevCR) => {
      const updatedCR = [...prevCR];

      const CR = { ...updatedCR[index], [field]: value };

      updatedCR[index] = CR;
      return updatedCR;
    });
  };

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );
  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleDeleteCr = (index: number) => {
    const updatedCR = CR?.map((cr, i) => {
      if (i === index) {
        return cr?.id ? { ...cr, action: "delete" } : null;
      }
      return cr;
    })?.filter((cr): cr is ICR => cr !== null);

    setCR(updatedCR);
  };
  const validateChangeRequests = (crList: ICR[] | []) => {
    for (const cr of crList) {
      if (
        (cr?.hours !== null || cr?.price !== null) &&
        (cr?.hours === null || cr?.price === null)
      ) {
        return false;
      }
    }
    return true;
  };

  const validateLocations = (): boolean => {
    if (locations?.length) {
      const newErrors = locations?.map((location) => {
        const error: IAddressError = {};
        if (!location?.place) error.place = "Location is required";
        if (!location?.coordinates) error.place = "Location is required";
        return error;
      });

      setLocationsError(newErrors);
      return newErrors?.every((error) => Object.keys(error).length === 0);
    }
    return true;
  };

  const handleOpen = () => {
    !startDate && setDateError(true);
    if (endDate && endDate?.isBefore(startDate)) {
      setEndDateError(true);
      return;
    }
    const res = validate();

    if (
      formValues?.type === "Fix Cost" &&
      (milestones === null ||
        milestones?.filter((milestone) => milestone.action !== "delete")
          ?.length === 0)
    ) {
      toast.error("Atleast one milestone is required");
      return;
    }
    if (
      formValues?.type &&
      formValues?.type !== "Fix Cost" &&
      formValues?.type !== "Time and Material" && // Condition added here
      (sprints === null ||
        sprints?.filter((milestone) => milestone.action !== "delete")
          ?.length === 0)
    ) {
      toast.error("Atleast one sprint is required");
      return;
    }
    if (
      formValues?.type === "Fix Cost" &&
      milestones?.length &&
      !validateMilestones()
    ) {
      toast.error("Please fill all milestone details.");
      return;
    }
    if (
      formValues?.type !== "Fix Cost" &&
      formValues?.type !== "Time and Material" && // Condition added here
      sprints?.length &&
      !validateSprints()
    ) {
      toast.error("Please fill all sprint details.");
      return;
    }

    if (
      formValues?.no_of_resource &&
      formValues?.no_of_resource > 0 &&
      !validateResources()
    ) {
      return;
    }
    const validLocations = locations?.filter((location) => location !== null);

    if (validLocations?.length === 0) {
      toast.error("Please select at least one location.");

      return;
    }
    if (locations?.length && !validateLocations()) {
      toast.error("Please fill location");
      return;
    }
    if (!dateError && res) {
      postData();
      // dispatch(setProjectTab(1));
      // setValue(1);
    } else {
      toast.error("Please fill all the required fields.");
    }
  };

  const getStartDateMinDate = (status: string): Dayjs | null => {
    switch (status) {
      case "upcoming":
        return dayjs();
      default:
        return null;
    }
  };

  const getStartDateMaxDate = (
    status: string,
    endDate: Dayjs | null
  ): Dayjs | null => {
    switch (status) {
      case "completed":
        return dayjs();
      case "ongoing":
        return dayjs();
      default:
        return endDate;
    }
  };

  const getEndDateMinDate = (
    status: string,
    startDate: Dayjs | null
  ): Dayjs | null => {
    if (startDate) {
      return dayjs(startDate).add(1, "day");
    }
    switch (status) {
      case "upcoming":
        return dayjs().add(1, "day");
      case "ongoing":
      case "onhold":
        return dayjs();
      default:
        return null;
    }
  };

  const getEndDateMaxDate = (status: string): Dayjs | null => {
    switch (status) {
      case "completed":
        return dayjs();
      default:
        return null;
    }
  };

  const handleDeleteLocation = (index: number) => {
    const updated = locations?.filter((loc, i) => i !== index);
    setLocations(updated);
  };

  return (
    <div>
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            // onClick={() => navigate("/project-management")}
            //  onClick={() => navigate("/client")}
            onClick={() => {
              if (path === "/view-project-details") {
                navigate(path, {
                  state: { tab: "summary", id: projectId },
                });
              } else {
                navigate("/project-management");
                // navigate(-1);
              }
              // navigate(-1);
              // dispatch(setProjectId(null));
              dispatch(setProjectTab(0));
            }}
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-4 w-4 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
            {`Edit Project Details (${projectName})`}
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            // onClick={() => navigate("/project-management")}
            onClick={() => {
              navigate(-1);
            }}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            //onClick={(event) => handleCurrentPageChang  e("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button
            type="submit"
            form="form"
            variant={"gradient"}
            onClick={() => handleOpen()}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </Button>
        </div>
      </div>

      <div className="mt-3 rounded-lg overflow-hidden border-[#E2E8F0] shadow-custom bg-white">
        <div className="mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="mb-5 ml-3 mt-1 text-[#374151] font-[Poppins] text-[22px] font-medium">
              Project Details
            </div>
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Project name"
                placeholder="Enter Project"
                value={formValues?.project_name}
                name="project_name"
                onChange={handleFormChange}
                error={!!errors.project_name}
                helperText={errors.project_name}
              />

              <TextField
                required
                id="outlined-required"
                label="Status"
                select
                value={formValues?.status}
                onChange={handleFormChange}
                placeholder="Status"
                name="status"
                error={!!errors.status}
                helperText={errors.status}
              >
                {Status?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="outlined-required"
                label="Currency"
                select
                // placeholder="Enter Declaration"
                value={formValues?.currency}
                onChange={handleFormChange}
                name="currency"
                error={!!errors.currency}
                helperText={errors.currency}
              >
                {Currency?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-required"
                label="Client name"
                select
                value={formValues?.client}
                onChange={handleFormChange}
                placeholder="Select Client"
                name="client"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 300, scrollbarWidth: "thin" },
                      sx: {
                        minWidth: "unset",
                        width: "auto",
                        maxWidth: "100%",
                      },
                    },
                  },
                }}
              >
                {tableData.map((option, index) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    ref={index === tableData.length - 1 ? ref : null}
                  >
                    {option.client_name}
                  </MenuItem>
                ))}
                {loading && <MenuItem disabled>Loading...</MenuItem>}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Project type"
                select
                value={formValues?.type}
                onChange={handleFormChange}
                placeholder="Project Type"
                name="type"
                error={!!errors.type}
                helperText={errors.type}
                disabled={disableType}
              >
                {ProjectTypes?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-required"
                label="Contractual hours"
                value={String(formValues?.contractual_hours) ?? ""}
                onChange={handleFormChange}
                // placeholder="Contractual Hours"
                name="contractual_hours"
                type="number"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // disabled={projectId !== null}
              />

              <TextField
                id="outlined-required"
                label="Contractual Cost"
                value={displayedCost}
                onChange={handleFormChange}
                name="contractual_cost"
                type="text"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formValues?.currency || null}
                    </InputAdornment>
                  ),
                }}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date *"
                  format="DD/MM/YYYY"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    setDateError(false);
                  }}
                  minDate={getStartDateMinDate(formValues?.status)}
                  maxDate={getStartDateMaxDate(formValues?.status, endDate)}
                  slotProps={{
                    textField: {
                      error: dateError,
                      helperText: dateError ? "Please select a start date" : "",
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End date"
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                    setEndDateError(false);
                  }}
                  minDate={getEndDateMinDate(formValues?.status, startDate)}
                  maxDate={getEndDateMaxDate(formValues?.status)}
                  slotProps={{
                    textField: {
                      error: enddateError,
                      helperText: enddateError
                        ? "End Date cannot be before start date"
                        : "",
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </LocalizationProvider>

              {formValues?.type && (
                <TextField
                  required
                  id="outlined-required"
                  label={
                    formValues?.type === "Fix Cost"
                      ? "No. of milestones"
                      : "No. of sprints"
                  }
                  value={
                    formValues?.type === "Fix Cost"
                      ? formValues?.no_of_milestone ?? ""
                      : formValues?.no_of_sprint ?? ""
                  }
                  placeholder={
                    formValues?.type === "Fix Cost"
                      ? "No. of milestones"
                      : "No. of sprints"
                  }
                  name="no_of_milestone"
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  // onChange={(e) => {
                  //   formValues.type && formValues.type === "Fix Cost"
                  //     ? handleMilestoneCountChange(Number(e.target.value))
                  //     : handleSprintCountChange(Number(e.target.value));
                  // }}
                  onChange={(e) => {
                    const rawValue = e.target.value;

                    if (formValues.type === "Fix Cost") {
                      handleMilestoneCountChange(rawValue);
                    } else {
                      handleSprintCountChange(rawValue);
                    }
                  }}
                  disabled={projectId !== null}
                />
              )}

              {formValues?.type && (
                <TextField
                  label="No. of resources"
                  value={formValues?.no_of_resource || null}
                  placeholder="No. of resources"
                  name="no_of_resource"
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  inputProps={{ min: 1 }}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  onChange={(e) =>
                    handleResourceCountChange(Number(e.target.value))
                  }
                  disabled={projectId !== null}
                />
              )}

              {/* {formValues?.type ? (
                <TextField
                  required
                  id="outlined-required"
                  label="No. of milestones"
                  value={formValues?.no_of_milestone}
                  placeholder={"No. of milestones"}
                  name="no_of_milestone"
                  type="number"
                  // inputProps={{ min: 1 }}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  onChange={(e) => {
                    handleMilestoneCountChange(Number(e.target.value));
                  }}
                />
              ) : (
                <TextField
                  required
                  id="outlined-required"
                  label={"No. of sprints"}
                  value={formValues?.no_of_sprint}
                  placeholder={"No. of sprints"}
                  name="no_of_milestone"
                  type="number"
                  // inputProps={{ min: 1 }}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  onChange={(e) => {
                    handleSprintCountChange(Number(e.target.value));
                  }}
                />
              )} */}
            </div>

            <div className="mt-4 pl-2">
              <FormControl error={!!errors.project_type}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Billing Type *
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formValues?.project_type}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="Billable"
                    control={<Radio />}
                    label="Billable"
                  />
                  <FormControlLabel
                    value="Non Billable"
                    control={<Radio />}
                    label="Non Billable"
                  />
                  <FormControlLabel
                    value="Revenue Generation"
                    control={<Radio />}
                    label="Revenue Generation"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              <FormHelperText>
                <span className="text-[#d32f2f]">{errors.project_type}</span>
              </FormHelperText>
            </div>
            <div className="mt-4 pl-2">
              {/* <hr className="h-[px] bg-[#EDEDED]" /> */}

              <FormControl error={!!errors.approval_code}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  DSR Approval *
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formValues?.approval_code}
                  onChange={handleDSRRadioChange}
                >
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Approval from Both (Reporting M. & Project M.)"
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label="Anyone"
                  />
                </RadioGroup>
              </FormControl>
              <FormHelperText>
                <span className="text-[#d32f2f]">{errors.approval_code}</span>
              </FormHelperText>
            </div>
            {formValues?.type && (
              <div className="flex flex-col gap-4 mt-2">
                <hr className="h-[1px] bg-[#EDEDED]" />

                <div className="pl-2">
                  {formValues?.type !== "Time and Material" && (
                    <FormLabel>Estimated Cost/Hour</FormLabel>
                  )}

                  {formValues?.type === "Fix Cost" &&
                    milestones &&
                    milestones?.length > 0 &&
                    milestones?.map((milestone, index) => (
                      <div
                        className={`mt-2 p-2 rounded-lg border ${
                          milestone.action === "delete" ? "hidden" : "block"
                        }`}
                      >
                        <div className="flex flex-col gap-2 ">
                          <div className="flex justify-between px-2 mt-1 ">
                            <div className="flex gap-4 items-center">
                              <span className="text-sm font-medium font-['Poppins'] text-gray-900 ">
                                {formValues?.type === "Fix Cost" && (
                                  <input
                                    type="text"
                                    className="text-sm font-medium font-['Poppins'] text-gray-900 border-b border-gray-300 focus:outline-none focus:border-blue-500"
                                    value={
                                      milestone?.name ??
                                      `Milestone ${index + 1}`
                                    } // Provide fallback value
                                    onChange={(e) => {
                                      const updatedMilestones = [...milestones];
                                      updatedMilestones[index].name =
                                        e.target.value;
                                      setMilestones(updatedMilestones);
                                      setMilestoneChange(true);
                                    }}
                                    disabled={milestone.editable === false}
                                    onBlur={() => {
                                      const updatedMilestones = [...milestones];
                                      if (
                                        !updatedMilestones[index]?.name?.trim()
                                      ) {
                                        updatedMilestones[
                                          index
                                        ].name = `Milestone ${index + 1}`;
                                      }
                                      setMilestones(updatedMilestones);
                                      setMilestoneChange(true);
                                    }}
                                  />
                                )}
                              </span>
                              <img
                                src={edit}
                                alt="Edit"
                                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
                                onClick={() => {
                                  const updatedMilestones = [...milestones];
                                  updatedMilestones[index].editable = true;
                                  setMilestones(updatedMilestones);
                                }}
                              />
                            </div>

                            {formValues?.no_of_milestone &&
                              formValues?.no_of_milestone > 1 && (
                                <X
                                  className="cursor-pointer text-gray-700"
                                  onClick={() => handleDeleteMilestone(index)}
                                />
                              )}
                          </div>

                          <div className="justify-between grid grid-cols-3">
                            <TextField
                              required
                              label="Hours"
                              value={milestone?.hours || null}
                              type="number"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              inputProps={{ min: 1 }}
                              error={!!milestoneErrors[index]?.hours}
                              helperText={milestoneErrors[index]?.hours}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "hours",
                                  Number(e.target.value)
                                )
                              }
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                            />
                            <TextField
                              required
                              label="Total Cost"
                              value={
                                milestone?.price !== null
                                  ? formatNumber(
                                      milestone?.price?.toString() ?? "",
                                      formValues?.currency
                                    )
                                  : ""
                              }
                              type="text"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              inputProps={{ min: 1 }}
                              error={!!milestoneErrors[index]?.price}
                              helperText={milestoneErrors[index]?.price}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {formValues?.currency}
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label={
                                  formValues?.project_type !== "Non Billable" &&
                                  formValues?.type !== "Time and Material"
                                    ? "Billing Date *"
                                    : "Billing Date"
                                }
                                format="DD/MM/YYYY"
                                minDate={startDate}
                                value={dayjs(milestone?.billing_date || null)}
                                onChange={(newValue) =>
                                  handleInputChange(
                                    index,
                                    "billing_date",
                                    newValue?.format("YYYY-MM-DD") || null
                                  )
                                }
                                slots={{
                                  textField: (params) => (
                                    <TextField
                                      {...params}
                                      error={
                                        formValues?.project_type !==
                                          "Time and Material" &&
                                        !!milestoneErrors[index]?.billing_date
                                      }
                                      helperText={
                                        formValues?.project_type !==
                                          "Time and Material" &&
                                        milestoneErrors[index]?.billing_date
                                      }
                                      inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {milestone?.billing_date && (
                                              <IconButton
                                                onClick={() =>
                                                  handleInputChange(
                                                    index,
                                                    "billing_date",
                                                    null
                                                  )
                                                }
                                                edge="end"
                                              >
                                                <Clear fontSize="small" />
                                              </IconButton>
                                            )}
                                            {params?.InputProps?.endAdornment}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ),
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    ))}
                  {formValues?.type !== "Fix Cost" &&
                    sprints &&
                    sprints?.length > 0 &&
                    sprints?.map((milestone, index) => (
                      <div
                        className={`mt-2 p-2 rounded-lg border ${
                          milestone.action === "delete" ? "hidden" : "block"
                        }`}
                      >
                        <div className="flex flex-col gap-2 ">
                          <div className="flex justify-between px-2 mt-1 ">
                            <div className="flex gap-4 items-center">
                              <span className="text-sm font-medium font-['Poppins'] text-gray-900 ">
                                {formValues?.type !== "Fix Cost" ? (
                                  <input
                                    type="text"
                                    className="text-sm font-medium font-['Poppins'] text-gray-900 border-b border-gray-300 focus:outline-none focus:border-blue-500"
                                    value={
                                      milestone.name ?? `Sprint ${index + 1}`
                                    } // Provide fallback value
                                    onChange={(e) => {
                                      const updatedMilestones = [...sprints];
                                      updatedMilestones[index].name =
                                        e.target.value;
                                      setSprints(updatedMilestones);
                                      setSprintChange(true);
                                    }}
                                    disabled={milestone.editable === false}
                                    onBlur={() => {
                                      const updatedMilestones = [...sprints];
                                      if (
                                        !updatedMilestones[index]?.name?.trim()
                                      ) {
                                        updatedMilestones[
                                          index
                                        ].name = `Sprint ${index + 1}`;
                                      }
                                      setSprints(updatedMilestones);
                                      setSprintChange(true);
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </span>
                              <img
                                src={edit}
                                alt="Edit"
                                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
                                onClick={() => {
                                  const updatedMilestones = [...sprints];
                                  updatedMilestones[index].editable = true;
                                  setSprints(updatedMilestones);
                                }}
                              />
                            </div>

                            {formValues?.no_of_sprint &&
                              formValues?.no_of_sprint > 1 && (
                                <X
                                  className="cursor-pointer text-gray-700"
                                  onClick={() => handleDeleteSprint(index)}
                                />
                              )}
                          </div>

                          <div className="justify-between grid grid-cols-3">
                            <TextField
                              required
                              label="Hours"
                              value={milestone?.hours || null}
                              type="number"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              inputProps={{ min: 1 }}
                              error={!!sprintErrors[index]?.hours}
                              helperText={sprintErrors[index]?.hours}
                              onChange={(e) =>
                                handleSprintInputChange(
                                  index,
                                  "hours",
                                  Number(e.target.value)
                                )
                              }
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                            />

                            <TextField
                              required
                              label="Total Cost"
                              value={
                                milestone?.price !== null
                                  ? formatNumber(
                                      milestone?.price?.toString() ?? "",
                                      formValues?.currency
                                    )
                                  : ""
                              }
                              type="text"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              inputProps={{ min: 1 }}
                              error={!!sprintErrors[index]?.price}
                              helperText={sprintErrors[index]?.price}
                              onChange={(e) =>
                                handleSprintInputChange(
                                  index,
                                  "price",
                                  e.target.value
                                )
                              }
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {formValues?.currency}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label={
                                  formValues?.project_type !== "Non Billable" &&
                                  formValues?.type !== "Time and Material"
                                    ? "Billing Date *"
                                    : "Billing Date"
                                }
                                format="DD/MM/YYYY"
                                minDate={startDate}
                                value={
                                  milestone?.billing_date
                                    ? dayjs(milestone?.billing_date)
                                    : null
                                }
                                onChange={(newValue) => {
                                  const formattedDate = newValue
                                    ? dayjs(newValue)?.format("YYYY-MM-DD")
                                    : null;
                                  handleSprintInputChange(
                                    index,
                                    "billing_date",
                                    formattedDate
                                  );
                                }}
                                slots={{
                                  textField: (params) => (
                                    <TextField
                                      {...params}
                                      error={
                                        !!sprintErrors[index]?.billing_date
                                      }
                                      helperText={
                                        sprintErrors[index]?.billing_date
                                      }
                                      inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {milestone?.billing_date && (
                                              <IconButton
                                                onClick={() =>
                                                  handleSprintInputChange(
                                                    index,
                                                    "billing_date",
                                                    null
                                                  )
                                                }
                                                edge="end"
                                              >
                                                <Clear fontSize="small" />
                                              </IconButton>
                                            )}
                                            {params?.InputProps?.endAdornment}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ),
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    ))}
                  {formValues?.type === "Fix Cost" ? (
                    <div className="mt-2">
                      <Button
                        type="button"
                        onClick={handleAddMilestone}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm font-medium font-['Poppins']"
                      >
                        + Add Milestone
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <Button
                        type="button"
                        onClick={handleAddSprint}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm font-medium font-['Poppins']"
                      >
                        + Add Sprint
                      </Button>
                    </div>
                  )}
                </div>
                {formValues?.type === "Fix Cost" ? (
                  <div className="mt-2 pl-2">
                    <hr className="h-[1px] bg-[#EDEDED] mb-3" />

                    <FormLabel>Change Request Cost/Hour</FormLabel>
                    {CR?.filter((item) => item.action !== "delete")?.map(
                      (cr, index) => (
                        <div className="mt-2 p-2 rounded-lg border">
                          <div className="flex flex-col gap-2 ">
                            <div className="flex justify-between">
                              <span className="text-sm font-medium pl-2 font-['Poppins'] text-gray-900 mt-1">
                                Change Request Cost/Hour {index + 1}
                              </span>
                              {index !== 0 && (
                                <X
                                  className="mr-2 text-gray-700 cursor-pointer"
                                  onClick={() => handleDeleteCr(index)}
                                />
                              )}
                            </div>
                            <div className="grid grid-cols-3 justify-between">
                              <TextField
                                label="Hours"
                                value={cr?.hours || null}
                                type="number"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                inputProps={{ min: 1 }}
                                onChange={(e) =>
                                  handleCRChange(
                                    index,
                                    "hours",
                                    e.target.value
                                      ? parseInt(e.target.value)
                                      : null
                                  )
                                }
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                      display: "none",
                                    },
                                  "& input[type=number]": {
                                    MozAppearance: "textfield",
                                  },
                                }}
                              />

                              <TextField
                                label="Total Cost"
                                value={
                                  cr?.price !== null
                                    ? formatNumber(
                                        cr?.price?.toString() ?? "",
                                        formValues?.currency
                                      )
                                    : ""
                                }
                                type="text"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                inputProps={{ min: 1 }}
                                onChange={(e) =>
                                  handleCRChange(index, "price", e.target.value)
                                }
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                      display: "none",
                                    },
                                  "& input[type=number]": {
                                    MozAppearance: "textfield",
                                  },
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      {formValues?.currency}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                label="Milestone / Sprint"
                                select
                                value={cr?.milestone_id ?? ""}
                                onChange={(e) => {
                                  const selectedOptionId = Number(
                                    e.target.value
                                  );
                                  handleCRChange(
                                    index,
                                    "milestone_id",
                                    selectedOptionId
                                  );
                                }}
                              >
                                {milestones && milestoneOptions?.length > 0 ? (
                                  milestoneOptions?.map((option) => (
                                    <MenuItem
                                      key={option?.id}
                                      value={option?.id}
                                    >
                                      {option?.name}
                                    </MenuItem>
                                  ))
                                ) : formValues.type === "Fix Cost" ? (
                                  <MenuItem disabled>
                                    No milestones available
                                  </MenuItem>
                                ) : (
                                  <MenuItem disabled>
                                    No sprints available
                                  </MenuItem>
                                )}
                              </TextField>
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Billing Date"
                                format="DD/MM/YYYY"
                                value={
                                  cr?.billing_date
                                    ? dayjs(cr?.billing_date)
                                    : null
                                }
                                onChange={(newValue) => {
                                  handleCRChange(
                                    index,
                                    "billing_date",
                                    newValue
                                  );
                                }}
                                // slotProps={{
                                //   textField: {
                                //     error: dateError,
                                //     helperText: dateError
                                //       ? "Please select a start date"
                                //       : "",
                                //   },
                                // }}
                              />
                            </LocalizationProvider> */}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <div className="mt-2">
                      <Button
                        type="button"
                        onClick={handleAddCr}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm font-medium font-['Poppins']"
                      >
                        + Add Change Request
                      </Button>
                    </div>
                  </div>
                ) : null}
                <hr className="h-[1px] bg-[#EDEDED]" />
                {resources && resources?.length > 0 && (
                  <>
                    <div className=" pl-2">
                      <FormLabel>Resources</FormLabel>

                      {resources?.map((res, index) => {
                        const data: any = resEdit?.find(
                          (item: any) => item?.User?.id === res?.user_id
                        );
                        return (
                          <div className="mt-2 p-2 rounded-lg border">
                            <div className="flex flex-col gap-2 ">
                              <span className="text-sm font-medium pl-2 font-['Poppins'] text-gray-900 mt-1">
                                Resource {index + 1}
                              </span>

                              <div className=" justify-between grid grid-cols-3">
                                <SearchResources
                                  resource={resources}
                                  onEmployeeSelect={handleEmployeeSelect}
                                  onEmployeeSelectId={(selectedEmployee) =>
                                    handleResourceChange(
                                      index,
                                      "user_id",
                                      selectedEmployee
                                    )
                                  }
                                  employeeError={employeeError}
                                  selectedEmployeeForEdit={
                                    data?.User
                                      ? {
                                          id: data.User.id,
                                          firstName: data.User.firstName,
                                          lastName: data.User.lastName,
                                          employee_id: data.User.employee_id,
                                        }
                                      : null
                                  }
                                />
                                <TextField
                                  required
                                  disabled
                                  label="Internal Billable Cost/hr"
                                  value={
                                    res?.per_hour_cost
                                      ? formatNumber(
                                          res?.per_hour_cost?.toString() ?? "",
                                          formValues?.currency
                                        )
                                      : ""
                                  }
                                  type="text"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  inputProps={{ min: 1 }}
                                  onChange={(e) =>
                                    handleResourceChange(
                                      index,
                                      "per_hour_cost",
                                      e.target.value
                                    )
                                  }
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      {
                                        display: "none",
                                      },
                                    "& input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                  }}
                                  error={!!resourceErrors[index]?.per_hour_cost}
                                  helperText={
                                    resourceErrors[index]?.per_hour_cost
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {formValues?.currency &&
                                        formValues?.currency !== ""
                                          ? formValues?.currency
                                          : null}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr className="h-[1px] bg-[#EDEDED]" />
                  </>
                )}
              </div>
            )}
            <div className="mt-2 pl-2">
              <FormLabel>Locations</FormLabel>
              {locations?.map((item, index) => (
                <div className="mt-2 p-2 rounded-lg border">
                  <div className="flex flex-col gap-2 ">
                    <div className="flex justify-between">
                      <span className="text-sm font-medium pl-2 font-['Poppins'] text-gray-900 mt-1">
                        Location {index + 1}
                      </span>
                      {index !== 0 && (
                        <X
                          className="mr-2 text-gray-700 cursor-pointer"
                          onClick={() => handleDeleteLocation(index)}
                        />
                      )}
                    </div>
                    <div className="w-100">
                      <AddressAutocomplete
                        onSelect={(place) => handlelocationChange(index, place)}
                        label="Location *"
                        placeholder="Enter a location *"
                        initialLocation={item}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="mt-2">
                <Button
                  type="button"
                  onClick={handleAddLoaction}
                  className="bg-gray-100 border border-gray-300 text-gray-900 text-sm font-medium font-['Poppins']"
                >
                  + Add Location
                </Button>
              </div>
            </div>
            <div className="mt-6 pl-2 ">
              <span className="font-[Poppins] py-1">Description</span>
              <MyStatefulEditor onChange={setEditorContent} />
            </div>
            <div className="mt-8 pl-2">
              <span className="font-[Poppins] py-1">Attachments</span>
              <FileUpload
                text="File Types: .jpg, .png, .pdf, .xlsx , .txt , .csv and .docx"
                onFilesChange={handleFilesChange}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditProjectDetails;
