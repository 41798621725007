import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from "react";
import { debounce } from "lodash";
import profile from "../../assets/Ellipse 580.png";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Chip,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import cross from "../../assets/cross_new.svg";

import Search from "../SearchBar/Search";
import { makeStyles } from "@material-ui/core/styles";
import { Fetch_Add_All_Resource_List } from "../common/services/Project";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-root": {
      flexWrap: "wrap",
      gap: theme.spacing(0.5),
      padding: theme.spacing(1),
      "& .MuiInputAdornment-root": {
        height: "auto",
        maxWidth: "100%",
        margin: 0,
        whiteSpace: "normal",
      },
    },
    "& .MuiInputBase-input": {
      width: "auto",
      flexGrow: 1,
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
  },
  chip: {
    margin: "2px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#49454F",
  },
}));

interface Employee {
  id: number;
  user_id: number;
  employee_id: number;
  employee_type: string | null;
  customer_id: number;
  group_id: number | null;
  firstName: string;
  lastName: string;
  profile_image: string;
}

interface TextFieldWithPopupProps {
  onSelectedEmployeeChange: (ids: number[]) => void; // Accepts an array of numbers
  setHasResource: React.Dispatch<React.SetStateAction<boolean>>;
  disable?: boolean;
}

const TextFieldWithPopup: React.FC<TextFieldWithPopupProps> = ({
  onSelectedEmployeeChange,
  setHasResource,
  disable,
}) => {
  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData?.customer_id;
  }
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Fetch employees based on the search term
  const fetchEmployees = async (term: string) => {
    try {
      const response = await Fetch_Add_All_Resource_List(customer_id, term);
      if (response?.status === 200) {
        setEmployees(
          response?.data?.result?.rows?.map((row: any) => ({
            user_id: Number(row?.id),
            employee_id: row?.employee_id,
            employee_type: row?.employee_type,
            customer_id: Number(row?.customer_id),
            group_id: Number(row?.group_id),
            firstName: row?.firstName,
            lastName: row?.lastName,
            profile_image: row?.profile_image,
          }))
        );
      } else {
        console.error("Error fetching employees:", response?.status);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // Debounced input change handler to reduce API calls
  const handleInputChange = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  // Fetch employees when the search term changes
  useEffect(() => {
    fetchEmployees(searchTerm);
  }, [searchTerm, customer_id]);

  useEffect(() => {
    if (selectedEmployees.length > 0) {
      setHasResource(true);
    } else {
      setHasResource(false);
    }
  }, [selectedEmployees, setHasResource]);

  return (
    <Autocomplete
      multiple
      options={employees}
      getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
      isOptionEqualToValue={(option, value) =>
        option?.user_id === value?.user_id
      }
      value={selectedEmployees}
      onChange={(_, newValue) => {
        setSelectedEmployees(newValue);
        onSelectedEmployeeChange(newValue?.map((emp) => emp?.user_id));
      }}
      disabled={disable}
      onInputChange={(_, newInputValue) => {
        setSearchTerm(newInputValue);
        handleInputChange(newInputValue);
      }}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="Assign To"
          variant="outlined"
          fullWidth
          placeholder="Search by name or ID"
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} key={option?.id}>
          <ListItemAvatar>
            <Avatar src={option?.profile_image} />
          </ListItemAvatar>
          <ListItemText
            primary={`${option?.firstName} ${option?.lastName}`}
            secondary={option?.employee_id}
          />
        </ListItem>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            // key={option?.id}
            label={`${option?.firstName} ${option?.lastName}`}
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ width: "100%" }}
      noOptionsText="No employees found"
    />
  );
};

export default TextFieldWithPopup;
