import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import DoughnutChart from "../common/Charts/DoughnutChart";
import StackedBarChart from "../common/Charts/StackedBarChart";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import eye from "../../assets/Crud_Icons/view.svg";
import Search from "../SearchBar/Search";
import { MenuItem, TextField } from "@mui/material";
import { debounce } from "lodash";
// import UseEmployee from "./UseEmployee";
import TablePaginationDemo from "../common/Pagenation";
import {
  Fetch_Project_Timelog,
  Fetch_User_Billability_Report,
  Fetch_User_Projects,
} from "../common/services/Employee";
import { useNavigate } from "react-router-dom";
import DSRGraph from "./DSRGraph";
import { Fetch_Status_List } from "../common/services/Project";

interface Project {
  estimated_hours: number;
  actual_hours: number;
  status: string;
}

interface WeeklyData {
  total_estimated_hours: number;
  total_actual_hours: number;
  projects: {
    [projectName: string]: Project;
  };
}

interface IProjectTimeTracker {
  [week: string]: WeeklyData;
}

interface Client {
  id: string;
  client_name: string;
}

interface Resource {
  User: {
    firstName: string;
    lastName: string;
  };
}

interface IProjectList {
  id: number;
  project_name: string;
  ProjectResources: Resource;
  Client: Client | null;
  project_type: string;
  // billing_type: string;
  efforts: string;
  project_status: string;
}

interface SubPart {
  label: string | null;
  value?: number;
}

interface EmpDSRProps {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}
interface IWeekData {
  label1: string;
  data1: number[];
  color1: string;

  label2: string;
  data2: number[];
  color2: string;
}

const Status = [
  { value: "", label: "All Projects" },
  { value: "upcoming", label: "Upcoming" },
  { value: "ongoing", label: "Ongoing" },
  { value: "completed", label: "Completed" },
  { value: "onhold", label: "Onhold" },
];

interface Project {
  project_name: string | null;
  total_duration: number;
}

interface IBillableReport {
  Billable: {
    total_hours: string;
    projects: Project[] | [];
  };
  NonBillable: {
    total_hours: string;
    projects: Project[] | [];
  };
}
interface IProjectData {
  id: string;
  statusCounts: {
    upcoming: number;
    ongoing: number;
    completed: number;
    onhold: number;
    initiated: number;
    "in-progress": number;
  };
  projectStatusCounts: {
    Delay: number;
    "On time": number;
  };
}

export default function EmpDSR({
  id,
  startDate,
  endDate,
}: EmpDSRProps): JSX.Element {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<IProjectList[]>([]);
  const [graphData, setGraphData] = useState<IProjectTimeTracker>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [numOfItems, setNumOfItems] = useState<number>(10);
  const [statusList, setStatusList] = useState<IProjectData>();
  const [count, setCount] = useState<number>(10);
  // const { billabilityReport, startDate, endDate } = UseEmployee({
  //   id,
  // });
  const [billabilityReport, setBillabilityReport] = useState<IBillableReport>();

  const fetchBillibilityReport = async () => {
    try {
      const response = await Fetch_User_Billability_Report({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        const data = response?.data?.result;
        setBillabilityReport(data);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchBillibilityReport();
  }, [endDate, id, startDate]);
  const fetchProjectsStats = useCallback(async () => {
    try {
      const response = await Fetch_Status_List({
        userId: id,
      });
      if (response.status === 200) {
        setStatusList(response.data?.result);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [id]);
  useEffect(() => {
    fetchProjectsStats();
  }, [fetchProjectsStats]);
  const fetchProjectTimelog = useCallback(async () => {
    try {
      const response = await Fetch_Project_Timelog({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        const data = response?.data?.result;
        setGraphData(data);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [endDate, id, startDate]);
  useEffect(() => {
    fetchProjectTimelog();
  }, [fetchProjectTimelog]);

  const fetchProjectData = async () => {
    try {
      const response = await Fetch_User_Projects({
        id: id,
        status: status ? status : null,
        page: page,
        size: numOfItems,
        search: searchTerm,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [id, status, page, numOfItems, searchTerm, count]);

  const billabilityReportStats = [
    {
      label: "Billable hours",
      color: "#A0D4A3",
      subParts: billabilityReport?.Billable?.projects?.map((project) => ({
        label: project?.project_name,
        value: Number(project?.total_duration),
      })),
    },
    {
      label: "Non Billable hours",
      color: "#FDAAAA",
      subParts: billabilityReport?.NonBillable?.projects?.map((project) => ({
        label: project?.project_name,
        value: Number(project?.total_duration),
      })),
    },
  ];
  const aggregatedDoughnutData = billabilityReportStats?.map((item) => ({
    label: item?.label,
    value:
      item?.subParts?.reduce((acc, sub) => acc + (sub?.value || 0), 0) ?? 0,
    color: item?.color,
  }));
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem: any) {
            const mainLabel =
              billabilityReportStats[tooltipItem.dataIndex]?.label;
            const mainValue =
              aggregatedDoughnutData[tooltipItem.dataIndex]?.value;
            return `${mainLabel}: ${mainValue}hr`;
          },
          afterLabel: function (tooltipItem: any) {
            const subParts =
              billabilityReportStats[tooltipItem.dataIndex]?.subParts;
            return subParts?.map(
              (sub: SubPart) => `\n${sub?.label}: ${sub?.value}hr`
            );
          },
        },
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        // usePointStyle: true,
      },
    },
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const columns: ColumnDef<IProjectList>[] = [
    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "manager",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Project Manager
        </Button>
      ),
      accessorFn: (row) => row?.ProjectResources?.User?.firstName || "-",
      cell: ({ row }) => {
        const name: string =
          row?.original?.ProjectResources?.User?.firstName +
          row?.original?.ProjectResources?.User?.lastName;
        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {name || "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "Client",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Client Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // cell: (info) => info.getValue(),
      accessorFn: (row) => row?.Client?.client_name || "-",
      cell: ({ row }) => {
        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {row?.original?.Client?.client_name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "project_type",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project/Billing Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.project_type,
      cell: ({ row }) => {
        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {row?.original?.project_type ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated Hours
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Actual Hours
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    // {
    //   accessorKey: "efforts",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       className="p-0"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //     >
    //       Efforts
    //       <ArrowUpDown className="ml-2 size-4" />
    //     </Button>
    //   ),
    //   cell: (info) => info.getValue() ?? "-",
    //   // accessorFn: (row) => row?.project_name,
    //   // cell: ({ row }) => {
    //   //   return (
    //   //     <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
    //   //       {row?.original?.project_name ?? "-"}
    //   //     </div>
    //   //   );
    //   // },
    // },
    {
      accessorKey: "project_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.project_status,
      cell: ({ row }) => {
        const status = row?.original?.project_status;

        return status === "Delay" ? (
          <div className="px-[10px] rounded-full w-fit py-[2px] flex justify-center items-center bg-[#EDCFCF] text-[#EB3F3F]">
            Delay
          </div>
        ) : (
          <div className="px-[10px] rounded-full py-[2px] w-fit flex justify-center items-center bg-[#E0F1E7] text-[#0F9446]">
            On Time
          </div>
        );
      },
      // cell: (info) => info.getValue(),
    },

    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/employee-dsr-list`, {
                  state: { id: id, projectId: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const labels: string[] = [];
  const chartData = [];

  // Process each week
  for (const week in graphData) {
    labels.push(week); // Add the week label to labels array

    const weekData = graphData[week];
    const estimatedHours = weekData.total_estimated_hours || 0; // Ensure 0 if no data
    const actualHours = weekData.total_actual_hours || 0; // Ensure 0 if no data

    // Add the data for allocated (estimated) and actual hours
    chartData.push({
      label1: "Allocated Hours",
      label2: "Actual Hours",
      data1: [estimatedHours], // Add total estimated hours for this week
      data2: [actualHours], // Add total actual hours for this week
      color1: "rgba(54, 162, 235, 0.5)", // Blue for allocated
      color2: "rgba(255, 99, 132, 0.5)", // Pink for actual
    });
  }

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: false,
        callbacks: {
          title: (tooltipItems: any) => tooltipItems[0].label,
          label: (tooltipItem: any) => {
            const datasetIndex = tooltipItem.datasetIndex;
            const weekIndex = tooltipItem.dataIndex;
            const weekLabel = labels[weekIndex];

            if (!graphData || !graphData[weekLabel]) return "";

            const weekData = graphData[weekLabel];

            if (datasetIndex === 0) {
              // Allocated Hours Tooltip (Blue Bar)
              const totalAllocated = weekData?.total_estimated_hours;

              const allocatedProjects = Object.entries(weekData?.projects)
                ?.filter(
                  ([, project]: [string, any]) => project?.estimated_hours > 0
                )
                ?.map(
                  ([projectName, project]: [string, any]) =>
                    `${projectName}: ${project?.estimated_hours} hr`
                );

              return [
                `Total Allocated Hours: ${totalAllocated} hr`,
                ...allocatedProjects,
              ];
            } else if (datasetIndex === 1) {
              // Actual Hours Tooltip (Pink Bar)
              const totalActual = weekData?.total_actual_hours;

              const actualProjects = Object.entries(weekData.projects)
                .filter(
                  ([, project]: [string, any]) => project?.actual_hours > 0
                )
                .map(
                  ([projectName, project]: [string, any]) =>
                    `${projectName}: ${project?.actual_hours} hr`
                );

              return [
                `Total Actual Hours: ${totalActual} hr`,
                ...actualProjects,
              ];
            }

            return [];
          },
        },
        padding: 10,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        grid: { display: false },
        ticks: { autoSkip: false },
      },
      y: { stacked: false, ticks: { beginAtZero: true } },
    },
  };

  return (
    <div className="flex flex-col gap-4 mt-2">
      <div className="flex justify-between gap-4 flex-wrap items-center ">
        <div className="h-[98px] rounded-lg flex-1 bg-[rgba(172,201,255,0.30)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Ongoing Projects
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {statusList?.statusCounts?.ongoing ?? 0}
          </p>
        </div>{" "}
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(253,224,224,0.30)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            On hold Projects
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {statusList?.statusCounts?.onhold ?? 0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(221,242,211,0.30)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Completed Projects
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {" "}
            {statusList?.statusCounts?.completed ?? 0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(217,37,37,0.08)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Upcoming Projects
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {statusList?.statusCounts?.upcoming ?? 0}
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-between">
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[25%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Billability Report
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <DoughnutChart
              chartData={aggregatedDoughnutData}
              options={doughnutOptions}
            />
          </div>
        </div>
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[74%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Time log Overview
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <DSRGraph chartData={chartData} labels={labels} options={options} />
          </div>
        </div>
      </div>
      {/* <div className="w-full h-6 flex justify-between mb-2"> */}
      <div className="w-full flex items-center justify-between">
        <Search onChange={updateSearchTerm} label="Search" />
        <TextField
          id="outlined-required"
          select
          placeholder="Select Status"
          name="status"
          value={status}
          sx={{
            height: "48px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              height: "100%",
              Width: "150px" ,
            },
            "& fieldset": {
              border: "2px solid rgb(205 205 205 / var(--tw-border-opacity, 1))", 
            },
            "&:hover fieldset": {
              borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important", 
              borderWidth: "2px",
            },
          }}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          {Status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="table_main_content w-full relative overflow-auto h-full bg-white">
        <DynamicTable<IProjectList>
          data={tableData}
          loading={false}
          columns={columns}
          enableSorting
        />
      </div>
      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </div>
  );
}
