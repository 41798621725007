import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  TextField,
  Box,
  Typography,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyStatefulEditor from "./EditEditor";
import FileUpload from "../ExpenseManagement/EditFileUoload";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Fetch_Policy_Category_List,
  fetchEditPolicy,
  updatePolicy,
} from "../common/services/companyManagement";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import EmployeeSearch from "./MultiSearch";
import { Button } from "../common/Button";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  policy_name: string;
  policy_category: string;
  group: string;
  // department: string;
  description: string;
  recurrence: string;
  version_number: string;
}

interface RowData {
  id: string;
  name: string;
}

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

export const EditPolicyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const location = useLocation();
  const id = location.state?.id;

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [joiningErr, setJoiningErr] = React.useState<boolean>(false);
  const [editorContent, setEditorContent] = useState<string>("");
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [deptError, setDeptError] = useState<string>("");
  const [departmentName, setDepartmentName] = useState<string[]>([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<number[]>(
    []
  );
  const [approverErr, setApproversErr] = useState<boolean>(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [formErrors, setFormErrors] = useState<Partial<FormValues>>({});
  const handleOpen = () => {
    const res1 = validateForm();
    if (
      res1 &&
      joining &&
      selectedDepartmentIds.length > 0 &&
      selectedEmployeeId2.length > 0
      // editorContent
    ) {
      submitChanges();
    } else {
      if (!joining) setJoiningErr(true);
      if (selectedDepartmentIds.length === 0)
        setDeptError("Departments are required");
      if (selectedEmployeeId2.length === 0) setApproversErr(true);
      // if (!editorContent) toast.error("Description is required");
      toast.error("Please fill all required fields");
    }
  };

  const validateForm = (): boolean => {
    let tempErrors: Partial<FormValues> = {};
    if (!formValues.policy_name) {
      tempErrors.policy_name = "Policy name is required";
    }
    if (!formValues.policy_category) {
      tempErrors.policy_category = "Policy category is required";
    }
    if (!formValues.group) {
      tempErrors.group = "Business Unit is required";
    }
    if (!formValues.recurrence) {
      tempErrors.recurrence = "Recurrence Period is required";
    }
    if (!formValues.version_number) {
      tempErrors.version_number = "Version number is required";
    }

    setFormErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/policies");
  };

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);
  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  const [formValues, setFormValues] = useState<FormValues>({
    policy_name: "",
    policy_category: "",
    // department: "",
    group: "",
    description: "",
    recurrence: "",
    version_number: "",
  });

  const Recurrency_Period = [
    { value: "ANNUALLY", label: "ANNUALLY" },
    { value: "BI_ANNUALLY", label: "BI_ANNUALLY" },
    { value: "QUATERLY", label: "QUATERLY" },
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "NEVER", label: "NEVER" },
  ];

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleDepartmentChange = (
    event: SelectChangeEvent<typeof departmentName>
  ) => {
    const {
      target: { value },
    } = event;

    setDeptError("");

    if (value?.includes("All")) {
      if (departmentName.length === department?.length) {
        setDepartmentName([]);
        setSelectedDepartmentIds([]);
      } else {
        const names = department?.map((option) => option?.name) || [];
        setDepartmentName(names);
        const ids = department?.map((option) => Number(option?.id)) || [];
        setSelectedDepartmentIds(ids);
      }
    } else {
      const selectedNames =
        typeof value === "string" ? value.split(",") : value;

      setDepartmentName(selectedNames);

      const selectedIds =
        department
          ?.filter((dept) => selectedNames.includes(dept.name))
          .map((dept) => Number(dept.id)) || [];

      setSelectedDepartmentIds(selectedIds);
    }

    if (value !== "") setDeptError("");
  };

  const fetchPolicyCategories = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_Category_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
      );
      if (response.status === 200) {
        setTableData(response.data?.result);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchPolicyCategories();
  }, []);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, [setBusiness]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, [setDepartmentName]);

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      try {
        const data = await fetchEditPolicy(id);
        if (data.result) {
          const policy = data.result;
          setDepartmentName(
            policy.PolicyDepartments.map((dept: any) => dept.Department.name)
          );
          setSelectedDepartmentIds(
            policy.PolicyDepartments.map((dept: any) => dept.department_id)
          );
          setSelectedEmployeeId2(
            policy.approvers.map((approver: any) => approver.id)
          );

          const { document_url, receipt_name } = policy;
          const files = document_url?.map((name: string, index: number) => ({
            originalName: document_url[index],
          }));

          // const files = policy.document_url?.map(
          //   (url: string, index: number) => ({
          //     url: url[index],
          //   })
          // );

          // if (policy?.document_url) {
          //   const files = policy.document_url.map((url: string, index: number) => ({
          //     originalName: url.split('/').pop() || 'Unknown File',
          //     url,
          //     fileType: url.endsWith('.pdf') ? 'PDF' : 'Document',
          //     original: url,
          //   }));
          //   setUploadedFiles(files);
          //   setInitialFiles(files);
          // }

          setUploadedFiles(files);
          setInitialFiles(files);

          setFormValues({
            policy_name: data.result.name,
            policy_category: data.result.policyCategory_id,
            // department: data.result?.department_id,
            group: data.result?.group_id,
            description: data.result?.description,
            recurrence: data.result?.recurrence,
            version_number: data.result?.version_no,
          });
          setJoining(dayjs(data.result?.renewal_date));
          setEditorContent(data.result?.description);
        }
      } catch (error) {
        toast.error("Failed to fetch policy details");
      } finally {
        setLoading(false);
      }
    };

    fetchPolicyDetails();
  }, [id]);

  const [selectedEmployeeId2, setSelectedEmployeeId2] = useState<string[]>([]);

  const handleApproverSelect = (employeeId: string[]) => {};

  const handleApproverSelectId2 = (employeeId: string[]) => {
    setSelectedEmployeeId2(employeeId);
    setApproversErr(false);
  };

  const submitChanges = async () => {
    setLoading(true);
    try {
      const data = await updatePolicy(
        id,
        formValues.policy_name,
        formValues.policy_category,
        formValues.group,
        // formValues.department,
        selectedDepartmentIds,
        editorContent,
        formValues.recurrence,
        formValues.version_number,
        // selectedEmployee.id,
        selectedEmployeeId2,
        dayjs(joining).format("YYYY-MM-DD"),
        uploadedFiles.map((files) => files.url)
      );

      if (data.status_code === 200) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          navigate("/policies");
        }, 1000);
      } else {
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <div className="px-4 pt-6 pb-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Policy updated successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="w-full flex flex-row justify-between">
        <div className="w-full flex flex-row px-4 items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/policies")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Policy Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/policies")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
            >
              <div
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
                Cancel
              </div>
            </button>
            <Button
              type="submit"
              form="form"
              onClick={handleOpen}
              variant={"gradient"}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Policy Name"
                placeholder="Enter Project"
                value={formValues.policy_name}
                onChange={handleFormChange}
                name="policy_name"
                error={!!formErrors.policy_name}
                helperText={formErrors.policy_name}
              />
              <TextField
                required
                id="outlined-required"
                label="Policy category"
                select
                placeholder="Policy Category"
                name="policy_category"
                value={formValues.policy_category}
                onChange={handleFormChange}
                error={!!formErrors.policy_category}
                helperText={formErrors.policy_category}
              >
                {tableData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                select
                label="Business Unit"
                name="group"
                defaultValue="hello"
                variant="outlined"
                value={formValues.group}
                onChange={handleFormChange}
                error={!!formErrors.group}
                helperText={formErrors.group}
              >
                {bussness?.map((option) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Effective Date *"
                  value={joining}
                  format="DD/MM/YYYY"
                  onChange={(newValue) => {
                    setJoining(newValue);
                    setJoiningErr(false);
                  }}
                  slotProps={{
                    textField: {
                      error: joiningErr,
                      helperText: joiningErr
                        ? "Effective date is required"
                        : "",
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                label="Reccurence Period"
                select
                value={formValues.recurrence}
                onChange={handleFormChange}
                name="recurrence"
                // error={!!formErrors.recurrence}
                // helperText={formErrors.recurrence}
              >
                {Recurrency_Period.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="outlined-required"
                label="Version Number"
                value={formValues.version_number}
                onChange={handleFormChange}
                name="version_number"
                error={!!formErrors.version_number}
                helperText={formErrors.version_number}
              ></TextField>

              <div className="mt-2 px-2">
                <FormControl className="w-full" sx={{ padding: 0 }}>
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ color: deptError ? "#d32f2f" : "" }}
                  >
                    Department *
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={departmentName}
                    onChange={handleDepartmentChange}
                    input={<OutlinedInput label="Department" />}
                    renderValue={(selected) => selected.join(", ")}
                    error={!!deptError}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {department && department?.length > 0 ? (
                      <MenuItem key={"All"} value={"All"}>
                        <Checkbox
                          checked={
                            departmentName?.length === department?.length
                          }
                        />
                        <ListItemText primary={"Select All"} />
                      </MenuItem>
                    ) : null}
                    {department?.map((option) => (
                      <MenuItem key={Number(option.id)} value={option.name}>
                        <Checkbox
                          checked={departmentName.indexOf(option.name) > -1}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {deptError}
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="pr-4">
                <EmployeeSearch
                  onEmployeeSelect={handleApproverSelect}
                  onEmployeeSelectId={handleApproverSelectId2}
                  employeesToEdit={selectedEmployeeId2}
                  error={approverErr}
                />
              </div>
            </div>

            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Policy Description
              </span>
              <MyStatefulEditor
                content={editorContent}
                onChange={setEditorContent}
                // error={!editorContent}
                // helperText="Policy Description is required"
              />
            </div>
            <div className="mt-4 pl-2">
              <span className="font-['Poppins'] font-medium text-[12px] leading-normal text-[#322F37]">
                Documents
              </span>
              <FileUpload
                existingFiles={initialFiles}
                onFilesChange={handleFilesChange}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
