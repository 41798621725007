import React, { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { TextField } from "@mui/material";
import { useGoogleMaps } from "../../Context/GoogleMapContext";

const libraries = ["places"];

interface AddressAutocompleteProps {
  onSelect: (place: google.maps.places.PlaceResult | null) => void;
  label: string;
  placeholder: string;
  initialLocation?: any;
  error?: boolean;
  helperText?: string | null;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  onSelect,
  label,
  placeholder,
  initialLocation = null,
  error,
  helperText,
}) => {
  const [locations, setLocations] = useState<any>(initialLocation);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const [inputValue, setInputValue] = useState<string>(
    initialLocation?.place || ""
  );

  useEffect(() => {
    setLocations(initialLocation);
    setInputValue(initialLocation?.place || "");
  }, [initialLocation]);

  // useEffect(() => {
  //   const loadScript = () => {
  //     if (!window.google) {
  //       const script = document.createElement("script");
  //       script.src = `https://maps.googleapis.com/maps/api/js?key=${address_API_key}&libraries=places`;
  //       script.async = true;
  //       script.onload = () => setScriptLoaded(true);
  //       script.onerror = () => {
  //         console.error("Error loading Google Maps API script.");
  //         setScriptLoaded(false);
  //       };
  //       document.head.appendChild(script);
  //     } else {
  //       setScriptLoaded(true);
  //     }
  //   };

  //   loadScript();

  //   return () => {
  //     setScriptLoaded(false);
  //   };
  // }, [address_API_key]);

  const handleLoad = (auto: google.maps.places.Autocomplete) => {
    setAutocomplete(auto);
  };

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      onSelect(place);
      setLocations(place);
      setInputValue(place?.formatted_address || "");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      // console.log("first")
      onSelect(null);
      setLocations(null);
    }
    setInputValue(event.target.value);
  };

  // const handleBlur = (
  //   event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   // if (event.target.value.trim() === "") {
  //   //   setLocations(null);
  //   //   setInputValue("");
  //   //   onSelect(null);
  //   // } else if (!autocomplete?.getPlace() && initialLocation?.place) {
  //   //   setInputValue(initialLocation?.place);
  //   // }
  //   if (initialLocation?.place?.formatted_address && inputValue.trim() !== "") {
  //     setLocations(initialLocation?.place);
  //     // setInputValue("");
  //     onSelect(initialLocation?.place);
  //     setInputValue(initialLocation?.place?.formatted_address);
  //   } else if (event.target.value.trim() === "") {
  //     setLocations(null);
  //     setInputValue(event.target.value);
  //     // onSelect(null);
  //   }
  // };

  // const handleBlur = () => {
  //   // if (event.target.value.trim() === "") {
  //   //   setLocations(null);
  //   //   setInputValue("");
  //   //   onSelect(null);
  //   // } else if (!autocomplete?.getPlace() && initialLocation?.place) {
  //   //   setInputValue(initialLocation?.place);
  //   // }

  //   if (locations?.place && !autocomplete?.getPlace() && inputValue.trim() !== "") {
  //     setLocations(locations);
  //     // setInputValue("");
  //     onSelect(locations?.place);
  //     setInputValue(locations?.place);
  //     console.log("check1", locations?.place)
  //     // console.log("check1")
  //   } else if (inputValue.trim() === "") {
  //     setLocations(null);
  //     setInputValue("");
  //     console.log("check2")
  //     // onSelect(null);
  //   }
  // };
  const { isLoaded } = useGoogleMaps();

  return (
    <div>
      {isLoaded && (
        <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
          <TextField
            id="outlined-required"
            label={label}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder}
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent form submission
              }
            }}
            sx={{
              width: "-webkit-fill-available",
            }}
            // onBlur={handleBlur}
            error={error}
            helperText={helperText}
          />
        </Autocomplete>
      )}
      {!isLoaded && <p>Loading Google Maps...</p>}
    </div>
  );
};

export default AddressAutocomplete;
