import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Filters {
  search?: string;
  department?: { id: string; name: string }[];
  group?: { id: string; name: string }[];
  date?: Date | null;
  status?: string;
  page?: number;
  size?: number;
}

const initialState: Filters = {
  search: "",
  department: [],
  group: [],
  status: "",
  date: null,
  page: 1,
  size: 10,
};

const LocationSlice = createSlice({
  name: "locationFilter",
  initialState,
  reducers: {
    setFilterLocationData(state, action: PayloadAction<Partial<Filters>>) {
      return { ...state, ...action.payload };
    },
    clearLocationFilters() {
      return initialState;
    },
  },
});

export const { setFilterLocationData, clearLocationFilters } =
  LocationSlice.actions;
export default LocationSlice;
