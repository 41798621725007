import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import check from "../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import EmployeeSearch from "./EmployeeSearch";
import { createAttendence } from "../common/services/Attendence";
import toast from "react-hot-toast";
import { MenuItem, TextField } from "@mui/material";
import { Button } from "../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const AddAttendence = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (!selectedEmployeeId) {
      setError("Please select an Employee");
    }

    if (!checkIn) {
      setDateError(true);
    }

    if (!modeOfEmp || modeOfEmp === "") {
      setEmpError("Please select a workspace");
    }

    if (selectedEmployeeId && checkIn && modeOfEmp && checkOut) {
      if (checkOut) {
        if (checkOut?.isBefore(checkIn)) {
          toast.error("CheckOut time should be before checkIn");
          return;
        } else {
          postData();
        }
      }
    } else if (selectedEmployeeId && checkIn && modeOfEmp) {
      postData();
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/attendance-management");
  };

  const [checkIn, setCheckIn] = React.useState<Dayjs | null>(null);
  const [checkOut, setCheckOut] = React.useState<Dayjs | null>(null);
  const [date, setDate] = React.useState<string>();
  const [dateError, setDateError] = useState(false);
  const [error, setError] = useState("");
  const [modeOfEmp, setModeOfEmp] = useState<string>("");
  const [empError, setEmpError] = useState<string>("");

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
    setError("");
  };

  const postData = async () => {
    // setLoading(true);
    try {
      await createAttendence(
        selectedEmployeeId,
        checkOut ? "CHECKOUT" : "CHECKIN",
        checkOut,
        checkIn,
        date,
        modeOfEmp
      );

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        navigate("/attendance-management");
      }, 300);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //setLoading(false);
  };

  const handleCheckInChange = (newValue: Dayjs | null) => {
    setCheckIn(newValue);
    setDateError(false);
    setDate(newValue?.format("YYYY-MM-DD"));
    if (newValue?.isAfter(checkOut)) {
      setCheckOut(null);
    }
  };

  const handleCheckOutChange = (newValue: Dayjs | null) => {
    setCheckOut(newValue);
  };

  const ModeOfEmployment = [
    { value: "WFO", label: "Work From Office" },
    { value: "WFH", label: "Work From Home" },
  ];

  const handleModeOfEmp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModeOfEmp(event.target.value);
    setEmpError("");
  };

  return (
    <div className="min-h-[90vh] px-4 py-6 bg-[#F9F9F9]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Attendence added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/attendance-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Add Employee Attendance
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/attendance-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
          >
            <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button
            type="submit"
            form="form"
            variant={"gradient"}
            onClick={handleOpen}
          >
              <div
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
             Save
            </div>
          </Button>
        </div>
      </div>

      <div className="rounded-md mt-10  shadow-custom bg-white">
        <div className="container mx-auto p-4">
          {/* <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">Sick Leave Details</div> */}
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
                error={error}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Check In Time"
                  value={checkIn}
                  format="DD/MM/YYYY HH:mm A"
                  views={["year", "month", "day", "hours", "minutes"]}
                  onChange={(e) => handleCheckInChange(e)}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: dateError,
                      helperText: dateError
                        ? "Please select a CheckIn time"
                        : "",
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Check Out Time"
                  value={checkOut}
                  format="DD/MM/YYYY HH:mm A"
                  views={["year", "month", "day", "hours", "minutes"]}
                  onChange={(e) => handleCheckOutChange(e)}
                  minDateTime={checkIn}
                  disableFuture
                  // slotProps={{
                  //   textField: {
                  //     error: dateError2,
                  //     helperText: dateError2
                  //       ? "Please select a valid CheckOut time"
                  //       : "",
                  //   },
                  // }}
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                select
                label="Workspace"
                defaultValue="hello"
                value={modeOfEmp}
                onChange={handleModeOfEmp}
                error={!!empError}
                helperText={empError}
              >
                {ModeOfEmployment.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddAttendence;
