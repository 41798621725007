import React, { useCallback, useEffect, useState } from "react";
// import view from "../../../assets/Crud_Icons/view.svg";
import eye from "../../../assets/Crud_Icons/view.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import profile from "../../../assets/Ellipse 580.png";
import TablePaginationDemo from "../../common/Pagenation";
import { useLocation, useNavigate } from "react-router-dom";
//import download from "../../../image/Vector (1).png";
import filter from "../../../assets/filter.svg";
import download from "../../../assets/download_new.svg";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import moment from "moment";
// import {
//   Department_Unit,
//   EmployeeUnit,
// } from "../../../redux/actions/Employee/Employee";
import { useSelector } from "react-redux";
import {
  Fetch_Leave_List,
  multipleApproveRejectApi,
} from "../../common/services/Leave";
// import { debounce } from "lodash";
// import Search from "../../SearchBar/Search";
// import Filter from "../../Filter/Filter";
// import { Chip } from "@material-ui/core";
// import { LeaveListActions } from "../../../redux/reducers/Leave/LeaveSlice";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../../common/DynamicTable";
import TableSkeleton from "../../common/TableSkeleton";
import { Close } from "@mui/icons-material";
import AttFilter, { FilterData } from "../../Filter/AttFilter";
import { setFilterPersistData } from "../../../redux/reducers/FilterPersist/FilterPersistSlice";
import { RootState } from "../../../redux/store";
import PersistedSearch from "../../EmployeeManagement/EmployeeListSearch";
import StatusBadge from "../../common/StatusBadge";
import UserBadge from "../../common/UserBadge";
import Checkbox from "@mui/material/Checkbox";
import toast from "react-hot-toast";
import { TextField, MenuItem } from "@mui/material";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface ILeaveList {
  User: {
    firstName: string;
    lastName: string;
    middleName: string;
    employee_id: string;
    profile_image: string;
    Department: {
      name: string;
    };
  };
  relevant_approval_status: string;
  LeaveType: {
    leave_type_name: string;
  };
  from_date: string;
  to_date: string;
  id: number;
  no_of_days: number;
  leave_status: string;
  leave_session: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

interface Approvers {
  approval_id: number;
  approval_status: string;
  role: string;
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
}

const Leave = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  // const currentPage = useSelector((state: any) => state.emplist.currentPage);
  const [page, setPage] = useState(1);
  // const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  // const [bussness, setBusiness] = useState<Business_Unit[]>();
  // const [department, setDepartment] = useState<DepartmentUnit[]>();
  // const [dateRange, setDateRange] = useState(false);
  // const [departmentSelect, setDepartmentSelect] = useState(false);
  // const [businessSelect, setBusinessSelect] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);

  // const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  // const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  // const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  // const [selectedDates, setSelectedDates] = useState<
  //   [Date | null, Date | null]
  // >([null, null]);
  // const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();
  // const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
  //   Business_Unit | DepartmentUnit
  // >();

  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);

  const [loading, setLoading] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState<ILeaveList[]>([]);

  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setPage(1);
    setIsOpen(false);
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  let customer_id = 0;
  let access_token = "";

  const userId = loginData?.userId;
  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }
  // const id = location.state?.id;
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [leaveStatus, setLeaveStatus] = useState<string>("");

  const approveData = async () => {
    const status = "Approved";
    try {
      if (selectedIds?.length) {
        const response = await multipleApproveRejectApi(selectedIds, status);
        if (response?.status === 200) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      setSelectedIds([]);
      fetchData();
    }
  };

  const rejectData = async () => {
    const status = "Rejected";
    try {
      if (selectedIds?.length) {
        const response = await multipleApproveRejectApi(selectedIds, status);
        if (response?.status === 200) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.approval_id === userId) {
        setShowButtons(true);
      }
    });
  }, [approverData]);

  const handleApprove = () => {
    approveData();
  };
  const handleReject = () => {
    rejectData();
  };

  const handleLeaveAllStatusesChange = async (value: string) => {
    setLeaveStatus(value);
    dispatch(setFilterPersistData({ status: value }));
  };

  function handlePageChange(value: number) {
    setPage(value);
    // dispatch(LeaveListActions.setCurrentPage(value));
  }

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_List({
        customer_id: customer_id,
        numOfItems: numOfItems,
        page: page,
        group_id: Number(busId),
        department_id: Number(depId),
        searchTerm: searchTerm ?? "",
        startDate: dates ? dates[0] : null,
        endDate: dates ? dates[1] : null,
        status: leaveStatus,
      });
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
        const fetchedData: EmployeeData[] = response?.data?.result?.rows;

        const columnMapping: { [key: string]: string } = {
          Name: "Name",
          "User.Department.name": "Department",
          leave_session: "Leave Period",
          "LeaveType.leave_type_name": "Leave Type",
          from_date: "Start Date",
          to_date: "End Date",
          no_of_days: "Number of Days",
          createdAt: "Leave Applied Date",
          leave_status: "Leave Approval Status",
        };

        const filteredData = fetchedData.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split("."); // Split the key to handle nested fields
            if (key === "Name") {
              // Combine firstName and lastName under the "Name" column
              filteredRow["Name"] = `${row.User.firstName || ""} ${
                row.User.lastName || ""
              }`.trim();
            } else if (keys.length === 1) {
              // Handle non-nested fields
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "from_date" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else if (keys[0] === "to_date" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else if (keys[0] === "createdAt" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else {
                  filteredRow[keys[0]] = row[keys[0]];
                }
              }
            } else if (keys.length === 2) {
              // Handle nested fields
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            } else if (keys.length === 3) {
              // Handle three-level nested fields
              const [grandParentKey, parentKey, childKey] = keys;

              if (
                row[grandParentKey] &&
                row[grandParentKey][parentKey] &&
                row[grandParentKey][parentKey].hasOwnProperty(childKey)
              ) {
                // If the third level exists, assign its value
                filteredRow[key] = row[grandParentKey][parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setTableData1(filteredData);

        // Set the columns for the table
        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  }, [
    busId,
    customer_id,
    dates,
    depId,
    leaveStatus,
    numOfItems,
    page,
    searchTerm,
  ]);

  // const isFirstRender = useRef(true);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const handleDelete = () => {
    setBusId("");
    setDepId("");
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
    setPage(1);
  };

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leave.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
    }
  };

  const LeaveOptions = [
    { label: "All Leaves", value: "" },
    { label: "Approved Leaves", value: "Approved" },
    { label: "Rejected Leaves", value: "Rejected" },
    { label: "Pending Leaves", value: "Pending" },
  ];

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const columns2: ColumnDef<ILeaveList>[] = [
    ...(tableData?.some(
      (row) => row?.relevant_approval_status?.toLowerCase() === "pending"
    )
      ? [
          {
            id: "select",
            header: ({ table }: any) => {
              const pendingRows = tableData?.filter(
                (row) =>
                  row?.relevant_approval_status?.toLowerCase() === "pending"
              );

              const allSelected = pendingRows.every((row) =>
                selectedIds.includes(Number(row.id))
              );

              return (
                <div className="w-[5px]" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={allSelected}
                    onChange={(e) => {
                      const checked = e.target.checked;

                      setSelectedIds(
                        checked ? pendingRows.map((row) => Number(row.id)) : []
                      );
                    }}
                  />
                </div>
              );
            },
            cell: ({ row }: any) => {
              const status = row?.original?.relevant_approval_status ?? "-";
              if (status.toLowerCase() !== "pending") return null;

              return (
                <div className="w-[5px]" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={selectedIds.includes(Number(row.original.id))}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const id = Number(row.original.id);

                      setSelectedIds((prev) =>
                        checked
                          ? [...prev, id]
                          : prev.filter((prevId) => prevId !== id)
                      );
                    }}
                  />
                </div>
              );
            },
          },
        ]
      : []),
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item?.User} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "leave_session",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Period Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "leave_type_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.LeaveType?.leave_type_name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.LeaveType?.leave_type_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "from_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.from_date
          ? new Date(row.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.from_date
          ? new Date(row.original.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "to_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.to_date
          ? new Date(row.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.to_date
          ? new Date(row.original.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          User Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.relevant_approval_status,
      cell: ({ row }) => {
        const status = row?.original?.relevant_approval_status;
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];

        return status ? (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        ) : (
          <span>-</span>
        );
      },
    },

    {
      accessorKey: "leave_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.leave_status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.leave_status ?? "-";
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];
        return (
          <StatusBadge
            status={status ?? "-"}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-3">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/leave-details/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/edit-leave/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-6 flex justify-between space-x-2">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center gap-3">
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-3">
              <div>
                <TextField
                  id="outlined-required"
                  select
                  placeholder="All Leave"
                  name="leaveStatus"
                  value={leaveStatus}
                  className="min-w-[70px] w-full sm:w-[120px] md:w-[150px]"
                  sx={{
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    "& .MuiOutlinedInput-root": {
                      height: "100%",
                      borderRadius: "0.5rem",
                      border: "none",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "29px",
                      color: "#49454F",
                      "& fieldset": {
                        border:
                          "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
                      },
                      "&:hover fieldset": {
                        borderColor:
                          "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                        borderWidth: "1px",
                      },
                    },
                  }}
                  onChange={(e) => handleLeaveAllStatusesChange(e.target.value)}
                  SelectProps={{ displayEmpty: true }}
                >
                  {LeaveOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="h-full flex justify-end items-center">
                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full ">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
              {selectedIds.length > 0 && (
                <div className="flex flex-start gap-3">
                  <button
                    onClick={handleReject}
                    className="flex w-[83px] bg-[#B3261E] space-x-2 justify-center items-center self-stretch rounded-lg h-10 hover:bg-[#96150e]"
                  >
                    <div className="text-[#fff] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      Reject
                    </div>
                  </button>

                  <button
                    type="submit"
                    form="form"
                    className="flex w-[83px] space-x-2 bg-[#359349] justify-center items-center flex-[1,1,0] self-stretch rounded-lg text-white h-10 hover:bg-[#1f8735]"
                    onClick={handleApprove}
                  >
                    <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      Approve
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<ILeaveList>
              onRowClick={(row) => {
                navigate(`/leave-details/${row?.id}`, {
                  state: { id: row?.id },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Leave Filter"
        onApply={handleApply}
        isDate={true}
        isOther
      />
    </>
  );
};

export default Leave;
