import React, { useEffect, useState, ChangeEvent, useCallback } from "react";
import eye from "../../assets/Crud_Icons/view.svg";
import filter from "../../assets/filter.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import del from "../../assets/Crud_Icons/delete.svg";
import { useNavigate } from "react-router-dom";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Policy_List } from "../common/services/companyManagement";
import { useSelector } from "react-redux";
import Filter from "../Filter/Filter";
import Search from "../SearchBar/Search";
import { Chip } from "@material-ui/core";
import { debounce } from "lodash";
import { ColumnDef } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import DynamicTable from "../common/DynamicTable";
import { IPolicyList } from "../common/interfaces/CompanyManagement";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import { Close } from "@mui/icons-material";
import { base_api, api_key_work as api_key } from "../../utils/helperData";
import { DeleteConfirmationModal } from "../common/shared/Dialogs/DeleteConfirmModal";
import TableSkeleton from "../common/TableSkeleton";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Leave_Type {
  id: string;
  name: string;
  recurrence: string;
  renewal_date: string;
}

export const getPolicyStatus = {
  CREATION_OF_POLICY: "Policy Created",
  REVIEW_OF_POLICY: "Policy in Review",
  POLICY_IN_USE: "Policy In Use",
};

export const Policies = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit | undefined
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit | undefined
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit | undefined
  >();

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IPolicyList[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  let customer_id = 0;
  let access_token = "";

  const dispatch = useDispatch();

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const fetchPolicies = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_List(
        customer_id,
        searchTerm,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id)
      );

      if (response.status === 200) {
        setTableData(response?.data?.result?.rows);

        setCount(response.data?.result?.count);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    fetchPolicies();
  }, [
    searchTerm,
    numOfItems,
    page,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startSession,
    endSession,
  ]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const handleDeleteClick = (item: string) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/policy/${selectedItem}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
      } catch (error) {
      } finally {
        setShowModal(false);
        setSelectedItem(null);
        fetchPolicies();
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };
  const columns: ColumnDef<IPolicyList>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Policy Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "category",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Category
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.PolicyCategory?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.PolicyCategory?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "recurrence",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Recurrence
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.PolicyDepartments?.map(
          (item) => item?.Department?.name ?? ""
        ).join(", ") || "-",
      cell: ({ row }) => {
        const departmentNames: string[] = row?.original?.PolicyDepartments?.map(
          (item) => item?.Department?.name ?? ""
        );
        const name: string =
          departmentNames?.length > 0 ? departmentNames.join(", ") : "-";

        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "renewal_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Renewal Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.renewal_date
          ? new Date(row.renewal_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.renewal_date
          ? new Date(row.original.renewal_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";

        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Workflow Stage
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.status
          ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
          : "-",
      cell: ({ row }) => {
        let status = "";
        if (row?.original?.status) {
          status =
            getPolicyStatus[
              row.original.status as keyof typeof getPolicyStatus
            ];
        }

        return <div className="">{status ?? "-"}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate("/view-policy-details", {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-policy-details`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() => handleDeleteClick(String(row?.original?.id) ?? "")}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, []);

  const handleFilterApply = () => {
    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDelete = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchPolicies();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCalanderClick = () => {};
  const handleDateChange = () => {};

  return (
    <>
      <div className="pt-3 flex flex-col gap-[10px]">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with policy name"
            />
            <div className="h-full flex items-center ">
              <div className="h-full flex items-center gap-2">
                {departmentSelect || businessSelect ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
            </div>
          </div>
        </div>

        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content  w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white ">
            <DynamicTable<IPolicyList>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>
      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
      {showModal && (
        <DeleteConfirmationModal
          onDelete={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      <Filter
        isDate={false}
        isOther
        heading="Policy Filter"
        selectnameBusiness={
          selectedBusinessUnit?.name ?? "Select Business Unit"
        }
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment={
          selectedDepartmentUnit?.name ?? "Select Department"
        }
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </>
  );
};
