import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import check from "../../../image/checkmark-circle-02.svg";
import { fetchEditHoliday, updateHoliday } from "../../common/services/Holiday";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "../../common/Button";
import { Checkbox } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  description: string;
  holiday_name: string;
}

const EditHoliday = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isOptional, setIsOptional] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    const res1 = validate();
    if (res1) {
      submitChanges();
    } else {
      toast.error("Please fill all the required details.");
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const [formValues, setFormValues] = useState<FormValues>({
    description: "",
    holiday_name: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let temperrors: Partial<FormValues> = {};

    if (formValues.holiday_name === "")
      temperrors.holiday_name = "Holiday Name is required";
    // if (formValues.description === "")
    //   temperrors.description = "Description is required";

    setErrors(temperrors);
    return Object.keys(temperrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    fetchEditHoliday(id)
      .then((data) => {
        if (data?.result) {
          // setUpdateData(data);

          setFormValues({
            description: data?.result?.description,
            holiday_name: data?.result?.holiday_name,
          });
          setIsOptional(data?.result?.optional_holiday);
          setStartDate(dayjs(data?.result?.holiday_date));
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  }, []);

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    setLoading(true);
    try {
      const data: any = await updateHoliday(
        id,
        formValues?.holiday_name,
        formValues?.description,
        dayjs(startDate)?.format("YYYY-MM-DD"),
        isOptional
      );

      if (data && data?.result) {
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          navigate(-1);
        }, 1000);
      } else {
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Holiday details updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#F9F9F9] py-6 px-4 min-h-[90vh]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate(-1)}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Holiday Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate(-1)}
              className="flex w-[148px] border border-solid borderButton p-[13.18px,50px,14.82px,51px]  justify-center items-center self-stretch rounded-lg h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <Button
              variant={"gradient"}
              form="form"
              onClick={handleOpen}
              //   onClick={() => handleCurrentPageChange("addNew")}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </Button>
          </div>
        </div>

        <div className="rounded overflow-hidden shadow-custom bg-white mt-10">
          <div className="container mx-auto p-4">
            <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">
              Holiday details
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-3 mt-4 gap-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    format="DD/MM/YYYY"
                    label="Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    className="w-full"
                  />
                </LocalizationProvider>
                <TextField
                  required
                  id="outlined-required"
                  label="Holiday Name"
                  defaultValue=" "
                  name="holiday_name"
                  value={formValues?.holiday_name}
                  onChange={handleFrormChange}
                  error={!!errors.holiday_name}
                  helperText={errors.holiday_name}
                />
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={isOptional}
                    onChange={(e) => setIsOptional(e.target.checked)}
                  />
                  <span className="text-base font-medium  text-gray-800">
                    Optional Holiday
                  </span>
                </div>
              </div>

              <div className="grid grid-cols-1">
                <TextField
                  id="outlined-required"
                  label="Description"
                  defaultValue=" "
                  multiline
                  rows="3"
                  name="description"
                  value={formValues?.description}
                  onChange={handleFrormChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHoliday;
