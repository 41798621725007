import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import upload from "../../image/System Icons.svg";
import { api_key_work as api_key } from "../../utils/helperData";
import axiosInstance from "../../app/api/axiosInstance";

interface FileUploadProps {
  onFilesChange: (
    files: {
      originalName: string;
      url: string;
      fileType: string;
      original: string;
    }[]
  ) => void;
  existingFiles?: {
    originalName: string;
    url: string;
    fileType: string;
    original: string;
  }[];
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  existingFiles = [],
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileDetails, setFileDetails] = useState(existingFiles);
  const [uploading, setUploading] = useState<boolean>(false);

  // useEffect(() => {
  //   setFileDetails(existingFiles);
  // }, [existingFiles]);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  useEffect(() => {
    if (files.length > 0) {
      uploadFiles(files);
    }
  }, [files]);

  const removeFile = (
    file:
      | File
      | {
          originalName: string;
          url: string;
          fileType: string;
          original: string;
        }
  ) => {
    const updatedFiles = files.filter((f) => f !== file);
    const updatedFileDetails = fileDetails.filter(
      (f) => f.url !== (file as { url: string }).url
    );
    setFiles(updatedFiles);
    setFileDetails(updatedFileDetails);
    onFilesChange(updatedFileDetails);
  };

  // const uploadFiles = async (filesToUpload: File[]) => {
  //   setUploading(true);
  //   try {
  //     const formData = new FormData();
  //     filesToUpload.forEach((file) => {
  //       formData.append("files", file);
  //     });

  //     const access_token = localStorage.getItem("access_token");

  //     const response = await axiosInstance.post(
  //       `${process.env.REACT_APP_BASE_API}/api/v1/upload-document`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           "x-api-key": api_key,
  //           Authorization: "Bearer " + access_token,
  //         },
  //       }
  //     );

  //     const result = response.data.result;
  //     const updatedFileDetails = [...fileDetails, ...result];
  //     setFileDetails(updatedFileDetails);
  //     onFilesChange(updatedFileDetails);
  //   } catch (error) {
  //     console.error("Error uploading files:", error);
  //   } finally {
  //     setUploading(false);
  //   }
  // };

  const uploadFiles = async (filesToUpload: File[]) => {
    setUploading(true);
    try {
      const formData = new FormData();
      filesToUpload.forEach((file) => {
        formData.append("files", file);
      });

      const access_token = localStorage.getItem("access_token");

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API}/api/v1/upload-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );

      const result = response.data.result;
      setFileDetails((prevFileDetails) => [...prevFileDetails, ...result]);
      onFilesChange([...fileDetails, ...result]);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setUploading(false);
      setFiles([]); // Clear the files state after upload
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="p-2">
      <div className="mb-4 grid grid-cols-3 gap-4">
        {fileDetails.map((file, index) => (
          <div
            key={index}
            className="flex items-center justify-between border-[#AD8CF1CF] p-2 border rounded-md mb-2"
          >
            <span className="text-[#000000] font-[500] text-[18px] font-[Poppins]">
              {file?.originalName
                ? String(file?.originalName)?.split("/")?.pop()
                : ""}{" "}
              {file?.fileType}{" "}
              {file?.url ? String(file.url)?.split("/")?.pop() : ""}
            </span>
            <button onClick={() => removeFile(file)} className="text-[#79747E]">
              X
            </button>
          </div>
        ))}
      </div>

      <div
        {...getRootProps()}
        className="border-2 mb-4 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer"
      >
        <input {...getInputProps()} />
        <p>
          <img className="m-[auto]" src={upload} alt="" />
        </p>
        <p>
          <span className="text-[#4B5563] font-[400] font-[Poppins] text-[16px]">
            Drop here to attach or
          </span>{" "}
          <span className="text-blue-500">upload</span>
        </p>
        <p className="text-[12px] text-[#4B5563] font-[400] font-[Poppins]">
          PDF or Word File | Max size: 10MB
        </p>
        {uploading && <p>Uploading...</p>}
      </div>
    </div>
  );
};

export default FileUpload;
