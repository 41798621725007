import React, { useState } from "react";
import right from "../../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import check from "../../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { createLeaveType } from "../../common/services/LeaveType";
import toast from "react-hot-toast";
import { Button } from "../../common/Button";
import { MenuItem } from "@mui/material";

interface FormValues {
  leaveType: string;
  no_of_leaves: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const AddLeaveType = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    const res = validate();
    res && postData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };

  const [formValues, setFormValues] = useState<FormValues>({
    leaveType: "",
    no_of_leaves: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [showOtherField, setShowOtherField] = useState(false);

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (!formValues.leaveType.trim()) {
      tempErrors.leaveType = "Leave Type is required";
    }

    if (!formValues.no_of_leaves.trim()) {
      tempErrors.no_of_leaves = "Number of Leaves is required";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "leaveType") {
      setShowOtherField(value === "Other");
      setFormValues({
        ...formValues,
        leaveType: value === "Other" ? "" : value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const postData = async () => {
    try {
      await createLeaveType(formValues.leaveType, formValues.no_of_leaves);
      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate(-1);
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  //setLoading(false);
  };

  const leaveTypeOptions = [
    { label: "Earned Leave", value: "Earned Leave" },
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Other", value: "Other" },
  ];

  return (
    <div className="min-h-[90vh] px-4 py-6 bg-[#F9F9F9]">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Leave Type added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate(-1)}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Leave Type
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex  rounded-lg  justify-center items-center  borderButton "
          >
            <div className="text text-center  font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button variant={"gradient"} form="form" onClick={handleOpen}>
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </Button>
        </div>
      </div>

      <div className="rounded-md overflow-hidden shadow-custom bg-white mt-10">
        <div className="container mx-auto p-4">
          <div className="text-[#262626] font-[500] text-[14px] pl-2 font-['Poppins']">
            Leave Type Details
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 mt-4 gap-4">
              <TextField
                required
                select
                label="Leave Types"
                name="leaveType"
                value={showOtherField ? "Other" : formValues.leaveType}
                fullWidth
                onChange={handleFrormChange}
                error={!!errors.leaveType && !showOtherField}
                helperText={!showOtherField ? errors.leaveType || "" : ""}
              >
                {leaveTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {showOtherField && (
                <TextField
                  required
                  label="Specify Other Leave Type"
                  value={formValues.leaveType}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      leaveType: event.target.value,
                    })
                  }
                  error={!!errors.leaveType && showOtherField}
                  helperText={errors.leaveType || ""}
                />
              )}

              <TextField
                required
                label="No Of Leaves"
                name="no_of_leaves"
                value={formValues.no_of_leaves}
                onChange={handleFrormChange}
                error={!!errors.no_of_leaves}
                helperText={errors.no_of_leaves}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddLeaveType;
