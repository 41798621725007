import { useEffect, useState } from "react";
import TablePaginationDemo from "../../common/Pagenation";
import edit from "../../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import { Fetch_Leave_Type_List } from "../../common/services/Leave";
// import { debounce } from "lodash";
// import Search from "../../SearchBar/Search";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../common/Button";
import { ArrowUpDown } from "lucide-react";
import DynamicTable from "../../common/DynamicTable";
import TableSkeleton from "../../common/TableSkeleton";
import PersistedSearch from "../../EmployeeManagement/EmployeeListSearch";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

interface ILeave_Type_List {
  id: string;
  leave_type_name: string;
  allotted_leaves: string;
}

const LeaveType = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<ILeave_Type_List[]>([]);
  // const [searchTerm, setSearchTerm] = useState("");
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_year" || event.key === "end_year") {
      const start = localStorage.getItem("start_year") || "";
      const end = localStorage.getItem("end_year") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_year") || "";
    const end = localStorage.getItem("end_year") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(()=>{
    setPage(1);
  }, [searchTerm])

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_Type_List(
        searchTerm ?? "",
        numOfItems,
        page
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);

        setCount(response?.data.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  // const isFirstRender = useRef(true);

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    fetchLeaveType();
  }, [page, searchTerm, startSession, endSession]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  // const updateSearchTerm = useCallback(
  //   debounce((event: any) => {
  //     setSearchTerm(event.target.value);
  //     setPage(1);
  //   }, 1000), // delay in ms
  //   [setSearchTerm]
  // );

  const columns: ColumnDef<ILeave_Type_List>[] = [
    {
      accessorKey: "leave_type_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 ml-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const name: string = row?.original?.leave_type_name ?? "-";
        return <div className="ml-2">{name}</div>;
      },
    },
    {
      accessorKey: "allotted_leaves",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Total Leave
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.allotted_leaves ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.allotted_leaves ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0 mr-4"
              onClick={() =>
                navigate(`/edit-leave-type/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-6">
        {/* <Search onChange={updateSearchTerm} label="Search with leave type" /> */}
        <PersistedSearch label="Search with leave type" />
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<ILeave_Type_List>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </>
  );
};

export default LeaveType;
