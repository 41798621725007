import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import upload from "../../assets/plus 1.png";
//import Project from "./Project";
//import Clients from "./Clients";
import "../EmployeeManagement/employee.css";
import Projects from "./Projects";
import Clients from "./Clients";
import maskPlus from "../../image/Mask group.svg";
import CustomTabs from "../common/CustomTabs";
import { useDispatch } from "react-redux";
import {
  resetProjectEndDate,
  resetProjectId,
  resetProjectStartDate,
  resetProjectTab,
} from "../../redux/reducers/ProjectManagement/ProjectSlice2";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import { Button } from "../common/Button";
import ProjectDashboard from "./Overview/ProjectDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setMainTabValue } from "../../redux/reducers/Tab/TabSlice";

const tabp = {
  padding: "0px",
};

export const ProjectManagement = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.mainTabValue.value);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(
      setFilterPersistData({
        date: [null, null],
        search: "",
      })
    );
    dispatch(setMainTabValue(newValue));
    // if (newValue === "1") {
    //   history("/project-dashboard");
    // } else if (newValue === "2") {
    //   history("/project-management");
    // } else if (newValue === "3") {
    //   history("/client");
    // }
  };

  const tabs = [
    { label: "Overview", value: "1" },
    { label: "Project", value: "2" },
    { label: "Client", value: "3" },
  ];

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <div className="">
        <Box>
          <TabContext value={value}>
            <div className="flex justify-between">
              <Box>
                <CustomTabs tabs={tabs} onChange={handleChange} />
              </Box>

              {value === "2" ? (
                <div>
                  <Button
                    onClick={() => {
                      dispatch(resetProjectTab());
                      dispatch(resetProjectId());
                      dispatch(resetProjectStartDate());
                      dispatch(resetProjectEndDate());
                      history("/add-project");
                    }}
                    variant={"gradient"}
                  >
                    <img
                      className="w-5 h-5 mr-2"
                      src={maskPlus}
                      alt="plus icon"
                    />
                    Add Project
                  </Button>
                </div>
              ) : value === "3" ? (
                <div>
                  <Button
                    onClick={() => history("/add-clients")}
                    variant={"gradient"}
                  >
                    <img
                      className="w-5 h-5 mr-2"
                      src={maskPlus}
                      alt="plus icon"
                    />
                    Add Client
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>

            <TabPanel className="tabpanel" value="1">
              <ProjectDashboard />
            </TabPanel>
            <TabPanel className="tabpanel" value="2">
              <Projects />
            </TabPanel>
            <TabPanel className="tabpanel" value="3">
              <Clients />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
