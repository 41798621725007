import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DateFilter {
  date?: [Date | null, Date | null];
}

const today = new Date();
const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

// const initialState: DateFilter = {
//   date: [null, null],
// };

const initialState: DateFilter = {
  date: [startOfMonth, today],
};

const EmployeeViewDateSlice = createSlice({
  name: "employeeViewDate",
  initialState,
  reducers: {
    setEmployeeViewDate(state, action: PayloadAction<DateFilter>) {
      return { ...state, ...action.payload };
    },
    clearEmployeeViewDate(state) {
      return initialState;
    },
  },
});

export const { setEmployeeViewDate, clearEmployeeViewDate } =
  EmployeeViewDateSlice.actions;
export default EmployeeViewDateSlice;
