import React, { useCallback, ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";

import maskPlus from "../../image/Mask group.svg";
import Search from "../SearchBar/Search";
import filter from "../../assets/filter.svg";
import view from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";

import TablePaginationDemo from "../common/Pagenation";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { Button } from "../common/Button";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

function Appraisal() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState(false);

  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    if (dates[0] && dates[1]) {
      // close the calendar when both the dates are selected
      setIsCalendarOpen(false);
    }
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    } else {
      setDateRange(false);
    }
    setIsOpen(false);

    // Clear all if all fields are empty or undefined
    if (selectedDates[0] === null || selectedDates[1] === null) {
      setSelectedDates([null, null]);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {};

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-start">
          <span className="font-[Poppins] font-semibold text-[20px] leading-[30px] text-[#1D1A22]">
            Employee Appraisals / Performance
          </span>
          <Button variant={"gradient"}>
            <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
            Add Employee Appraisal
          </Button>
        </div>

        <div className="flex flex-col gap-2 5">
          <div className="h-10 flex justify-between items-center">
            <Search
              label="Search with Employee Name"
              onChange={updateSearchTerm}
            />

            <div className="h-full flex items-center gap-5">
              {/* {dateRange && (
                <Chip
                  label={`${moment(selectedDates[0]).format(
                    "YYYY-MM-DD"
                  )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                  onDelete={handleDeleteDate}
                  size="small"
                />
              )} */}

              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <button
                    onClick={handleDeleteDate}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                )}
              </div>
              {/* {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )} */}

              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white hover:bg-gray-100"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
            </div>
          </div>
          <div className="flex justify-end gap-8">
            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Open Appraisal
              </span>
            </div>

            <div className="flex flex-row justify-between gap-1 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FA1464" />
              </svg>
              <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                Closed Appraisal
              </span>
            </div>
          </div>
          <div className="overflow-x-auto shadow-sm">
            <table className="w-full min-w-max text-left table-auto bg-white border border-[#E0E0E0]">
              <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                <tr className="w-full">
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5 pl-5">
                    Employee Name & ID
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Business Unit
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Department
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Financial Year
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Appraisal Mode
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5">
                    Period
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-3.5 flex justify-end items-center pr-5">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="w-full hover:bg-gray-100">
                  <td className="pl-4 py-2.5">
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle
                          cx="3.99537"
                          cy="3.74537"
                          r="3.49537"
                          //   fill={
                          //     item.status === "published"
                          //       ? "#00CBA0"
                          //       : item.status === "scheduled"
                          //       ? "#FF9950"
                          //       : "#D3D3D3"
                          //   }
                          fill="#D3D3D3"
                        />
                      </svg>
                      <div className="flex items-center gap-2 5">
                        <img
                          src={profile}
                          alt="profile image"
                          className="h-10 w-10"
                        />
                        <div className="flex flex-col">
                          <span className="font-[Poppins] font-medium text-[14px] leading[21px] text-[#1D1A22]">
                            Brooklyn Simmons
                          </span>
                          <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                            EMP0062
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      AppVin, Noida
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      Human Resource
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      2024-2024
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      Monthly
                    </span>
                  </td>
                  <td className="py-2.5">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F]">
                      Jan
                    </span>
                  </td>
                  <td className="py-2.5 pr-4">
                    <span className="font-[Poppins] font-medium text-[12px] leading-[11.19px] tracking-[6%] text-[#49454F] flex justify-end items-center">
                      <img
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                        src={view}
                        alt="View"
                        onClick={() => navigate("/view-appraisal")}
                      />
                      <img
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                        src={edit}
                        alt="Edit"
                        onClick={() => navigate("/edit-appraisal")}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={1}
              numOfItems={10}
            />
          )}

          <Filter
            isDate
            isOther={false}
            heading="Appraisal Filter"
            selectnameBusiness="Select Business Unit"
            optionsBusiness={bussness}
            onOptionSelectBusiness={handleBusinessUnitSelect}
            selectnameDepartment="Select Department"
            optionsDepartment={department}
            onOptionSelectDepartment={handleDepartmentUnitSelect}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onApply={handleFilterApply}
            selected={selectedDates[1]}
            startDate={selectedDates[0]}
            endDate={selectedDates[1]}
            isCalendarOpen={isCalendarOpen}
            handleCalanderClick={handleCalanderClick}
            handleDateChange={handleDateChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Appraisal;
