import { Task } from "@mui/icons-material";
import { endOfDay } from "date-fns";
import dayjs from "dayjs";
import { Clock, Laptop, MapPin } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import noResult from "../../assets/noResult.svg";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import eye from "../../assets/Crud_Icons/view.svg";
import {
  Fetch_Attendance_Stats,
  Fetch_Attendance_Status,
  Fetch_User_Attendance,
  Fetch_User_Projects,
  Fetch_User_Tasks,
} from "../common/services/Employee";
// import UseEmployee from "./UseEmployee";
import { setSelectedTab } from "../../redux/reducers/Employee/EmpoyeeViewSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetViewProjectTab,
  setProjectId,
  setViewProjectTab,
} from "../../redux/reducers/ProjectManagement/ProjectSlice2";
import DoughnutChart from "../common/Charts/DoughnutChart";
import PieChart from "../common/Charts/PieChart";
import { RootState } from "../../redux/store";

interface IEMPStats {
  WFO: number;
  WFH: number;
  OnLeave: number;
}

interface IProject {
  id: number;
  project_name: string;
  currency: string;
  client_id: number | null;
  customer_id: number;
  group_id: number | null;
  project_type: string;
  status: string;
  project_status: string;
  start_date: string;
  end_date: string;
  Client: {
    id: number;
    client_name: string;
  };
  ProjectResources: ProjectResource[];
}

interface ProjectResource {
  id: number;
  user_id: number;
  project_id: number;
  employee_type: string;
  customer_id: number;
  User: User;
}

interface User {
  id: number;
  employee_id: string;
  customer_id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string | null;
}

export interface IAttendanceRecord {
  id: number;
  user_id: number;
  customer_id: number;
  status: string;
  start_time: string;
  end_time: string;
  date: string;
  check_in_address: string;
  check_out_address: string;
  checkout_by: string;
  mode_of_work: string;
  mode_of_work_checkout: string;
  check_in_status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  duration: string;
  User: {
    id: number;
    prefix: string;
    firstName: string;
    lastName: string;
    employee_id: string;
    department_id: number;
    profile_image: string;
  };
}

interface ITask {
  id: number;
  project_id: number;
  Project: {
    project_name: string;
  };
  customer_id: number;
  module_id: number;
  task_old_id: number | null;
  group_id: number | null;
  task_name: string;
  estimated_hours: number;
  actual_hours: number | null;
  start_date: string;
  end_date: string | null;
  status: string;
  task_status: string | null;
  description: string | null;
  task_document: string[];
  created_by: number;
  modified_by: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  TaskResources: TaskResource[];
}

interface TaskResource {
  id: number;
  user_id: number;
  project_id: number;
  task_id: number;
  customer_id: number;
  start_date: string | null;
  end_date: string | null;
  description: string | null;
  created_by: number;
  modified_by: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
interface Client {
  id: string;
  client_name: string;
}
interface Resource {
  User: {
    firstName: string;
    lastName: string;
  };
}
interface IProjectList {
  id: number;
  project_name: string;
  ProjectResources: Resource;
  ProjectManager: Resource[];
  Client: Client | null;
  project_type: string;
  billing_type: string;
  efforts: string;
  project_status: string;
}

export default function EmployeeOverview({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statusList, setStatusList] = useState<IEMPStats>();
  const [projectData, setProjectData] = useState<IProject[]>([]);
  const [tableData, setTableData] = useState<IProjectList[]>([]);
  const [tableDataCount, setTableDataCount] = useState<number>(0);
  const [status, setStatus] = useState<string | null>("ongoing");
  const [page, setpage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [todayAttendance, setTodayAttendance] = useState<IAttendanceRecord>();
  const [taskData, setTaskData] = useState<ITask[]>([]);
  const [taskDataCount, setTaskDataCount] = useState<number>(0);
  const [taskStatus, setTaskStatus] = useState<string>("inprogress");
  // const { statusList, projectData, taskData, todayAttendance } = UseEmployee({
  //   id,
  //   // date: new_date.toISOString().split("T")[0],
  // });

  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const fetchData = async () => {
    try {
      const response = await Fetch_Attendance_Stats({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setStatusList(response?.data?.result ?? {});
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [endDate, id, startDate]);

  const fetchProjectData = async () => {
    try {
      const response = await Fetch_User_Projects({
        id: id,
        // status: status,
        page: page,
        size: 5,
        search: search,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setProjectData(response?.data?.result?.rows ?? []);
        setTableData(response?.data?.result?.rows ?? []);
        setTableDataCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [endDate, id, startDate, status, page, search]);

  const fetchTodayData = async () => {
    try {
      const response = await Fetch_User_Attendance({
        id: id,
        date: dayjs().format("YYYY-MM-DD"),
        customer_id: customer_id,
      });
      if (response.status === 200) {
        const data = response?.data?.result?.rows;
        if (data?.length > 0) {
          const obj = data[data.length - 1];
          setTodayAttendance(obj);
        }
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchTodayData();
  }, [customer_id, id]);

  const fetchTaskData = async () => {
    try {
      const response = await Fetch_User_Tasks({
        id: id,
        // status: taskStatus,
        start_date: new Date(),
        // end_date: new Date(),
      });
      if (response.status === 200) {
        setTaskData(response?.data?.result?.rows ?? []);
        setTaskDataCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, [id, startDate, taskStatus]);

  const fetchAttendanceStatusChart = async () => {
    try {
      const response = await Fetch_Attendance_Status({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setAttendanceStatus(response?.data?.result ?? {});
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAttendanceStatusChart();
  }, [endDate, id, startDate]);

  const WFH = statusList?.WFH ? statusList?.WFH : 0;
  const WFO = statusList?.WFO ? statusList?.WFO : 0;
  const leave = statusList?.OnLeave ? statusList?.OnLeave : 0;

  const columns1: ColumnDef<ITask>[] = [
    {
      accessorKey: "project_id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const name: string = row?.original?.Project?.project_name;
        return <div className="py-3">{name || "-"}</div>;
      },
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "task_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // cell: (info) => info.getValue(),
      accessorFn: (row) => row?.task_name || "-",
      cell: ({ row }) => {
        const name: string = row?.original?.task_name;
        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {name || "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated Hours
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Progress
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const name: string = row?.original?.status;
        return <div className="py-3">{name || "-"}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/view-task-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns: ColumnDef<IProjectList>[] = [
    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // cell: (info) => info.getValue(),
      cell: ({ row }) => {
        const name: string = row?.original?.project_name;
        return <div className="py-3">{name || "-"}</div>;
      },
    },
    {
      accessorKey: "ProjectManager",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Project Manager
        </Button>
      ),
      // accessorFn: (row) => row?.ProjectManager?.User?.firstName || "-",
      // cell: ({ row }) => {
      //   const name: string =
      //     row?.original?.ProjectManager?.User?.firstName +
      //     row?.original?.ProjectManager?.User?.lastName;
      //   return (
      //     <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
      //       {name || "-"}
      //     </div>
      //   );
      // },
      cell: ({ row }) => {
        const managers = row?.original?.ProjectManager?.map((item: any) => ({
          name: `${item?.User?.firstName} ${item?.User?.lastName}`.trim(),
          id: item?.User?.employee_id,
        }));

        if (!managers?.length) return <div>-</div>;

        const displayedManagers = managers
          .slice(0, 2)
          .map((m: any) => m?.name)
          .join(", ");
        const remainingManagers = managers.slice(2);

        return (
          <div className="rounded-full relative group inline-block">
            <span className="rounded-full cursor-pointer group-hover">
              {displayedManagers}
              {remainingManagers.length > 0 && `, +${remainingManagers.length}`}
            </span>

            <div className="absolute left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block bg-white text-gray-900 text-sm font-inter font-normal shadow-lg w-[200px] gap-[10px] z-10 rounded-[2px] px-[16px] py-[8px] max-h-[200px] overflow-auto">
              {managers?.map((manager: any, index: any) => (
                <div key={index} className="w-full text-left whitespace-nowrap">
                  {manager?.name}{" "}
                  <span className="text-gray-500"> (ID: {manager?.id})</span>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "Client",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Client Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // cell: (info) => info.getValue(),
      accessorFn: (row) => row?.Client?.client_name || "-",
      cell: ({ row }) => {
        return (
          <div className="py-3">
            {row?.original?.Client?.client_name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "project_type",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project/Billing Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const projectType = row?.original?.project_type || "";
        const billingType = row?.original?.billing_type || "";

        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {projectType && billingType
              ? `${projectType} , ${billingType}`
              : projectType || billingType}
          </div>
        );
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated Hours
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Actual Hours
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
  ];

  interface IAttendanceStatus {
    WFH: number;
    WFO: number;
    DELAYED: number;
    NOT_CHECKED_IN: number;
  }

  const [attendanceStatus, setAttendanceStatus] = useState<IAttendanceStatus>();

  const employeeStats = [
    {
      label: "In Office",
      value: statusList?.WFO ? statusList?.WFO : 0,
      color: "#CDEBC5",
    },
    {
      label: "WFH",
      value: statusList?.WFH ? statusList?.WFH : 0,
      color: "#8AB3FF",
    },
    {
      label: "On leave",
      value: statusList?.OnLeave ? statusList?.OnLeave : 0,
      color: "#F4C1C1",
    },
  ];

  const employeeStatsChartData = {
    labels: employeeStats?.map((stat) => stat?.label),
    datasets: [
      {
        data: employeeStats?.map((stat) => stat?.value),
        backgroundColor: employeeStats?.map((stat) => stat?.color),
        borderColor: employeeStats?.map(() => "#FFFFFF"),
        borderWidth: 2,
      },
    ],
  };

  // const employeeStatsChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: "bottom" as const,
  //       labels: {
  //         boxWidth: 15,
  //         boxHeight: 15,
  //       },
  //     },
  //     tooltip: {
  //       enabled: true,
  //       backgroundColor: "#fff",
  //       titleColor: "#474747",
  //       bodyColor: "#6B7280",
  //     },
  //   },
  // };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    centerText: `${WFH + WFO + leave}\ndays`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
    },
  };

  return (
    <div className="flex flex-col gap-4 mt-2">
      {/* <div className="flex justify-between gap-4 flex-wrap items-center ">
        <div className="h-[98px] rounded-lg flex-1 bg-[rgba(15,148,70,0.07)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Total Working Days
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {WFH + WFO}{" "}
            <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>{" "}
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(57,104,242,0.08)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Working From Office
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {WFO} <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(65,101,196,0.08)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Working From Home
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {WFH} <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(217,37,37,0.08)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            On Leave
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {leave}{" "}
            <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>
      </div> */}
      {/* <div className="grid grid-cols gap-3"> */}

      <div className="border rounded-md p-4">
        <div className="flex justify-between items-center pb-1">
          <p className="text-light-black font-semibold text-base font-['Poppins']">
            Allocated Projects ({tableDataCount})
          </p>
          {
            <button
              onClick={() => dispatch(setSelectedTab("dsr"))}
              className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
            >
              View All
            </button>
          }
        </div>
        {/* <div className="table_main_content w-full relative overflow-auto py-6 bg-white"> */}
        <div
          className={`table_main_content w-full ${
            tableDataCount >= 5 ? "h-[300px]" : "h-auto"
          } relative overflow-auto bg-white my-2`}
        >
          <DynamicTable<IProjectList>
            onRowClick={(row) => {
              dispatch(setProjectId(row?.id));
              dispatch(resetViewProjectTab());
              navigate("/view-project-details", {
                state: {
                  id: row?.id,
                },
              });
            }}
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 lg:gap-0 lg:flex-row">
        <div className="border rounded-md bg-white p-4 w-full 2xl:w-[100%] flex flex-col justify-between">
          <p className="text-light-black font-semibold text-base font-['Poppins'] pb-2">
            Today's Attendance
          </p>
          <div className="flex flex-col 2xl:flex-row gap-3">
            <div className="bg-custom-bg-gray2 border border-grey200 rounded-[3px] p-2 shadow-sm flex flex-col justify-between pt-[4px] pb-[8px] pl-[14px] pr-[14px] 2xl:w-[50%] h-auto">
              <div className="flex justify-between ">
                <p className="text-sm font-['Poppins'] text-grey400 font-normal">
                  Check in
                </p>
                {todayAttendance?.check_in_status && (
                  <div
                    className={`flex justify-center items-center gap-2 py-[2px] px-[10px] text-[10px] font-medium font-['Poppins'] rounded-full capitalize ${
                      todayAttendance?.check_in_status === "Delayed"
                        ? "text-[#EB3F3F] bg-[#EDCFCF]"
                        : "text-[#0F9446] bg-[rgba(15,148,70,0.10)]"
                    } `}
                  >
                    {todayAttendance?.check_in_status
                      ? todayAttendance?.check_in_status
                      : "-"}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center leading-[20px] text-sm font-medium font-['Poppins'] text-gray-700">
                  <Clock size={16} />
                  <p>
                    {todayAttendance?.start_time
                      ? dayjs(todayAttendance?.start_time).format("h:mm A")
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-2  items-center text-sm font-medium font-['Poppins'] text-dark-grey ">
                  <MapPin size={16} />
                  <p className="max-w-[70%] text-ellipsis whitespace-nowrap overflow-hidden">
                    {todayAttendance?.check_in_address
                      ? todayAttendance?.check_in_address
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-custom-bg-gray2 border border-grey200 rounded-[3px] p-2 shadow-sm flex flex-col justify-between pt-[8px] pb-[8px] pl-[14px] pr-[14px] 2xl:w-[50%] auto">
              <div className="flex justify-between ">
                <p className="text-sm font-['Poppins'] text-grey400 font-normal">
                  Check out
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center text-sm font-medium font-['Poppins'] text-dark-grey">
                  <Clock size={16} />
                  <p>
                    {" "}
                    {todayAttendance?.end_time
                      ? dayjs(todayAttendance?.end_time).format("h:mm A")
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-2 items-center text-sm font-medium font-['Poppins'] text-dark-grey ">
                  <MapPin size={16} />
                  <p className="max-w-[70%] text-ellipsis whitespace-nowrap overflow-hidden">
                    {todayAttendance?.check_out_address
                      ? todayAttendance?.check_out_address
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3"></div>
        </div>

        {/* <div className="bg-[#FCFCFC] border border-[#E2E8F0] rounded-md p-4 2xl:h-[197px] xl:w-[50%] 2xl:w-[25%] lg:ml-4">
          <div className="flex justify-between items-center">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Attendance Overview
            </p>
          </div>
          <div className="flex justify-center w-full h-[80%] mt-1">
            <PieChart data={employeeStatsChartData} />
          </div>
          <div className="flex justify-center text-[10px] font-medium leading-[10px] tracking-[-0.1px] text-gray-800 font-poppins">
            Total Working Days (
            {(statusList?.WFO ?? 0) +
              (statusList?.WFH ?? 0) +
              (statusList?.OnLeave ?? 0)}
            )
          </div>
        </div> */}
      </div>

      <div className="bg-[#FCFCFC] border rounded-md p-4">
        {/* Header */}
        <div className="flex justify-between items-center mb-2">
          <p className="text-light-black font-semibold text-base font-['Poppins']">
            Today's Tasks ({taskDataCount})
          </p>
          {/* {taskData?.length > 5 ? (
              <button
                onClick={() =>
                  navigate(`/employee-dsr-list`, {
                    state: { id: id },
                  })
                }
                className="flex h-[32px] px-3 justify-center items-center gap-[0.5rem] rounded-[6px] border border-[#E2E8F0] bg-white text-[12px] text-gray-800 font-medium font-poppins"
              >
                View All
              </button>
            ) : null} */}
        </div>
        <div
          className={`table_main_content w-full ${
            taskDataCount >= 5 ? "h-[300px]" : "h-auto"
          } relative overflow-auto bg-white my-1`}
        >
          <DynamicTable<ITask>
            onRowClick={(row) => {
              navigate("/view-task-details", {
                state: {
                  id: row?.id,
                },
              });
            }}
            data={taskData}
            loading={false}
            columns={columns1}
            enableSorting
          />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
