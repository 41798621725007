import { useEffect, useState, useRef, useCallback } from "react";
import eye from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate } from "react-router-dom";
import filter from "../../assets/filter.svg";
import { Fetch_WFH_List, Fetch_WFH_List1 } from "../common/services/Leave";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, Download } from "lucide-react";
import { Button } from "../common/Button";
import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";
import { Close } from "@mui/icons-material";
import { RootState } from "../../redux/store";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import UserBadge from "../common/UserBadge";
import StatusBadge from "../common/StatusBadge";
import toast from "react-hot-toast";
import { multiWFHApproveRejectApi } from "../common/services/WFH";
import { Checkbox } from "@mui/material";

interface Department {
  name: string;
}
interface IWFHList {
  User: {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
    employee_id: string;
    profile_image: string;
    Department: Department | null;
  };
  relevant_approval_status: string;
  no_of_days: string;
  from_date: string;
  to_date: string;
  id: string;
  wfh_status: string;
  wfh_session: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

export const WFH = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IWFHList[]>([]);
  // const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  // const [bussness, setBusiness] = useState<Business_Unit[]>();
  // const [department, setDepartment] = useState<DepartmentUnit[]>();
  const dispatch = useDispatch();
  // const [dateRange, setDateRange] = useState(false);
  // const [departmentSelect, setDepartmentSelect] = useState(false);
  // const [businessSelect, setBusinessSelect] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setPage(1);
    setIsOpen(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_WFH_List(
        numOfItems,
        page,
        searchTerm ?? "",
        Number(busId),
        Number(depId),
        dates ? dates[0] : null,
        dates ? dates[1] : null
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);

        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.log(response?.data?.message);
      }
    } catch (error) {
      console.log("Error fetching WFH data.");
    }
    setLoading(false);
  }, [page, numOfItems, searchTerm, busId, depId, dates]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchData1 = async () => {
    setLoading(true);
    try {
      const response = await Fetch_WFH_List1();
      if (response.status === 200) {
        // setTableData(response.data.result.rows);

        const fetchedData: EmployeeData[] = response.data?.result?.rows;

        const columnMapping: { [key: string]: string } = {
          Name: "Name",
          "User.Department.name": "Department",
          from_date: "Start Date",
          to_date: "End Date",
          wfh_status: "WFH Approval Status",
        };

        const filteredData = fetchedData.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split("."); // Split the key to handle nested fields

            if (key === "Name") {
              // Combine firstName and lastName under the "Name" column
              filteredRow["Name"] = `${row.User.firstName || ""} ${
                row.User.lastName || ""
              }`.trim();
            } else if (keys.length === 1) {
              // Handle non-nested fields
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "from_date" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else if (keys[0] === "to_date" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM, YYYY"
                  );
                } else filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              // Handle nested fields
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            } else if (keys.length === 3) {
              // Handle three-level nested fields
              const [grandParentKey, parentKey, childKey] = keys;

              if (
                row[grandParentKey] &&
                row[grandParentKey][parentKey] &&
                row[grandParentKey][parentKey].hasOwnProperty(childKey)
              ) {
                // If the third level exists, assign its value
                filteredRow[key] = row[grandParentKey][parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setTableData1(filteredData);

        // Set the columns for the table
        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchData1();
  }, []);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "work-from-home.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
    }
  };

  // const updateSearchTerm = useCallback(
  //   debounce((event: ChangeEvent<HTMLInputElement>) => {
  //     setSearchTerm(event.target.value);
  //     // fetchData(event.target.value); // call API to fetch data
  //   }, 100), // delay in ms
  //   []
  // );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const handleDelete = () => {
    setBusId("");
    setDepId("");
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
    setPage(1);
  };

  const columns2: ColumnDef<IWFHList>[] = [
    ...(tableData?.some(
      (row) => row?.relevant_approval_status?.toLowerCase() === "pending"
    )
      ? [
          {
            id: "select",
            header: ({ table }: any) => {
              const pendingRows = tableData?.filter(
                (row) =>
                  row?.relevant_approval_status?.toLowerCase() === "pending"
              );

              const allSelected = pendingRows.every((row) =>
                selectedIds.includes(Number(row.id))
              );

              return (
                <div className="w-[5px]" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={allSelected}
                    onChange={(e) => {
                      const checked = e.target.checked;

                      setSelectedIds(
                        checked ? pendingRows.map((row) => Number(row.id)) : []
                      );
                    }}
                  />
                </div>
              );
            },
            cell: ({ row }: any) => {
              const status = row?.original?.relevant_approval_status ?? "-";
              if (status.toLowerCase() !== "pending") return null;

              return (
                <div className="w-[5px]" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={selectedIds.includes(Number(row.original.id))}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const id = Number(row.original.id);

                      setSelectedIds((prev) =>
                        checked
                          ? [...prev, id]
                          : prev.filter((prevId) => prevId !== id)
                      );
                    }}
                  />
                </div>
              );
            },
          },
        ]
      : []),
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item?.User} user_id={item?.User?.id} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "wfh_session",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Session
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.wfh_session ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.wfh_session ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "from_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.from_date
          ? new Date(row.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.from_date
          ? new Date(row.original.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "to_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.to_date
          ? new Date(row.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.to_date
          ? new Date(row.original.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          User Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.relevant_approval_status,
      cell: ({ row }) => {
        const status = row?.original?.relevant_approval_status;
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];

        return status ? (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        ) : (
          <span>-</span>
        );
      },
    },
    {
      accessorKey: "wfh_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          WFH Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.wfh_status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.wfh_status ?? "-";
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];
        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/work-from-home-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-work-from-home", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleMultiSelect = async (status: string) => {
    try {
      if (selectedIds?.length) {
        const response = await multiWFHApproveRejectApi({
          status,
          selectedIds,
        });

        if (response?.status === 200) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setSelectedIds([]);
      fetchData();
    }
  };

  return (
    <div className="pb-14">
      <div className="pt-6 flex justify-between space-x-2 w-full">
        <div className="w-full">
          <div className="h-full flex justify-between items-center w-full">
            {/* <Search onChange={updateSearchTerm} label="Search with name & id" /> */}
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>

              {selectedIds?.length > 0 && (
                <>
                  <Button
                    variant={"border"}
                    className="bg-[#B3261E] text-white"
                    onClick={() => handleMultiSelect("Rejected")}
                  >
                    Reject
                  </Button>

                  <Button
                    variant={"border"}
                    className="bg-[#359349] text-white"
                    onClick={() => handleMultiSelect("Approved")}
                  >
                    Approve
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<IWFHList>
              onRowClick={(row) => {
                navigate("/work-from-home-details", {
                  state: { id: row?.id },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
      {/* <Filter
        isDate
        isOther
        heading="Work From Home Filter"
        selectnameBusiness={
          selectedBusinessUnit?.name ?? "Select Business Unit"
        }
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment={
          selectedDepartmentUnit?.name ?? "Select Department"
        }
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      /> */}

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="WFH Filter"
        onApply={handleApply}
        isDate={true}
        isOther
      />
    </div>
  );
};
