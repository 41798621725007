import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import right from "../../../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import upload from "../../../../image/Mask group.svg";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";

import dayjs, { Dayjs } from "dayjs";
import { MenuItem } from "@mui/material";
import {
  createTaskApi,
  Fetch_Task_List,
} from "../../../common/services/Project";
import toast from "react-hot-toast";
import eye from "../../../../assets/Crud_Icons/view.svg";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../../../common/Pagenation";
import Search from "../../../SearchBar/Search";
import DynamicTable from "../../../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../common/Button";
import { ArrowUpDown } from "lucide-react";
import { debounce } from "lodash";
import profile from "../../../../assets/Ellipse 580.png";
import AddTaskDialog from "../AddTaskDialog";
import date2 from "../../../../assets/date2.svg";
import {
  setProjectId,
  setProjectTab,
} from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Search2 from "../../../SearchBar/Search2";
import {
  calculateVariance,
  convertHoursIntoHoursMinutes,
} from "../../../../utils/helperData";

interface RowData {
  id: number;
  project_id: number;
  customer_id: number;
  group_id: number | null;
  task_name: string;
  estimated_hours: number;
  actual_hours: number;
  start_date: string;
  end_date: string;
  status: string;
  description: string;
  task_document: any[];
  TaskResources: TaskResource[];
  Module: { module_name: string } | null;
  ProjectMilestone: {
    milestone_name: string;
  } | null;
  efforts: string | null;
}

interface TaskResource {
  id: number;
  task_id: number;
  user_id: number;
  User: User;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  Department: Department;
  Designation: Designation;
  employee_id: string;
  profile_image: string;
}

interface Department {
  id: number;
  name: string;
}

interface Designation {
  id: number;
  name: string;
}
const Status = [
  { value: "", label: "All Tasks" },
  { value: "inprogress", label: "Working" },
  { value: "todo", label: "To do" },
  { value: "completed", label: "Finished" },
  { value: "onhold", label: "Hold" },
];
const statusMap = Object.fromEntries(
  Status.map(({ value, label }) => [value, label])
);

const EditTask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.state?.path;
  const [show, setShow] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState<string>("");
  const [projectName, setProjectName] = useState("");
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );

  // State for selected date using Dayjs type
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  // Handle date selection in Dayjs format
  const handleSelectedDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
  };

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; project_id: number }[]
  >([]);
  // const param = useParams();

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: "milestone_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Milestone / Sprint
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.ProjectMilestone?.milestone_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.ProjectMilestone?.milestone_name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "module_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Module
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Module?.module_name ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">{row?.original?.Module?.module_name ?? "-"}</div>
        );
      },
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },

    {
      accessorKey: "task_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "category",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Assigned To
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.TaskResources?.[0]?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const data = row?.original;

        if (data?.TaskResources?.length > 0) {
          const [firstUser, ...otherUsers] = data?.TaskResources;

          return (
            <div className="flex items-center gap-1">
              {firstUser?.User && (
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={firstUser?.User?.profile_image || profile}
                    alt="Profile"
                  />
                  <div className="flex flex-col justify-between">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/employee-list-details", {
                          state: { id: data?.TaskResources[0]?.User?.id },
                        });
                      }}
                      className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]"
                    >
                      {firstUser?.User?.firstName} {firstUser?.User?.lastName} (
                      {firstUser.User?.Department?.name})
                    </span>
                    <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                      {firstUser?.User?.employee_id}
                    </span>
                  </div>
                </div>
              )}

              {otherUsers?.length > 0 && (
                <span className="text-gray-900 pb-1">
                  ,{" "}
                  <span className="rounded-full p-1 bg-[#E5E7EB] ">
                    +{otherUsers?.length}
                  </span>
                </span>
              )}
            </div>
          );
        }

        return "-";

        // const resources = row?.original?.TaskResources?.map(
        //   (item) => item?.User?.firstName
        // );

        // if (!resources?.length) return <div>-</div>;

        // const displayedResources = resources?.slice(0, 2)?.join(", ");
        // const remainingCount = resources?.length - 2;

        // return (
        //   <div>
        //     {displayedResources}
        //     {remainingCount > 0 && (
        //       <span className="text-gray-900">
        //         ,{" "}
        //         <span className="rounded-full p-1 bg-[#E5E7EB] ">
        //           +{remainingCount}
        //         </span>
        //       </span>
        //     )}
        //   </div>
        // );
      },
    },

    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // cell: (info) => info.getValue(),
      accessorFn: (row) =>
        row?.start_date
          ? new Date(row.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.start_date
          ? new Date(row.original.start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "end_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_date
          ? new Date(row.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.end_date
          ? new Date(row.original.end_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Estimated hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.estimated_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.estimated_hours
              ? convertHoursIntoHoursMinutes(
                  row?.original?.estimated_hours ?? 0
                )
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Filled hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.actual_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.actual_hours
              ? // ? `${row?.original?.actual_hours} hrs`
                convertHoursIntoHoursMinutes(row?.original?.actual_hours ?? 0)
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "variance",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Variance
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      // accessorFn: (row) =>
      //   row?.status
      //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
      //     : "-",
      cell: ({ row }) => {
        let diff = null;
        if (row?.original?.actual_hours && row?.original?.estimated_hours) {
          // diff = row?.original?.estimated_hours - row?.original?.actual_hours;
          diff = calculateVariance(
            row?.original?.estimated_hours,
            row?.original?.actual_hours
          );
        }
        return <div className="">{diff ?? "-"}</div>;
      },
    },
    {
      accessorKey: "efforts",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Efforts
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.efforts ?? "-",
      cell: ({ row }) => {
        return <div className="">{row?.original?.efforts ?? "-"}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Progress
          {/* <ArrowUpDown className="ml-2 size-4" /> */}
        </Button>
      ),
      // accessorFn: (row) =>
      //   row?.status
      //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
      //     : "-",\
      cell: ({ row }) => {
        let diff = null;
        let status = "";
        if (row?.original?.actual_hours && row?.original?.estimated_hours) {
          diff = row?.original?.estimated_hours - row?.original?.actual_hours;
          status = diff < 0 ? "Delay" : "On Time";
        }

        // return <div className="capitalize">{status ?? "-"}</div>;
        return row?.original?.actual_hours && row.original?.estimated_hours ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full border ${
              status === "On Time"
                ? "border-[#0F9446] bg-[rgba(15,148,70,0.10)]"
                : "border-[#EB3F3F] bg-[#EDCFCF]"
            }`}
          >
            {status}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status ?? "-";
        return <div className="">{statusMap[status] ?? status}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/view-task-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-task", {
                  state: { id: row?.original?.id, projectId: projectId },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    //setLoading(true);

    // if (!status || status === "") {
    //   // Avoid API call if no status is selected
    //   return;
    // }
    try {
      const response = await Fetch_Task_List({
        project_id: projectId,
        numOfItems: numOfItems,
        page: page,
        searchTerm: searchTerm,
        status: status,
        date: selectedDate,
      });

      if (response.status === 200) {
        // setTableData(response?.data?.result?.rows ?? []);
        const rows = response?.data?.result?.rows || [];

        const formattedData = rows?.map((row: any) => ({
          id: row?.id,
          project_id: row?.project_id,
          customer_id: row?.customer_id,
          group_id: row?.group_id,
          task_name: row?.task_name,
          estimated_hours:
            row?.estimated_hours && row?.estimated_hours !== 0
              ? row?.estimated_hours
              : null,
          actual_hours: row?.actual_hours,
          start_date: row?.start_date,
          end_date: row?.end_date,
          status: row?.status,
          description: row?.description,
          task_document: row?.task_document,
          TaskResources: row?.TaskResources,
          Module: row?.Module,
          ProjectMilestone: row?.ProjectMilestone,
          efforts: row?.efforts,
        }));

        setTableData(formattedData);
        setProjectName(response?.data?.result?.projectName?.project_name ?? "");
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const selectedCalendarRef = useRef<HTMLDivElement>(null);

  const handleSelectedCalanderClick = () => {
    setIsSelectedOpen(!isSelectedOpen);
  };

  const handleClickOutside = () => {
    setIsSelectedOpen(false); // Close when clicked outside
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems, status, selectedDate, searchTerm]);

  const handleBackClick = () => {
    // dispatch(setProjectId(param.id ?? null));

    if (path === "/view-project-details") {
      navigate(path, {
        state: { tab: "tasks", id: projectId },
      });
    } else {
      dispatch(setProjectTab(1));
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between mt-4">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() => {
                // navigate(`/edit-resources-details/${param.id}`, {
                //   state: { id: param.id },
                // })
                handleBackClick();
              }}
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-4 w-4 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
              {`Edit Tasks (${projectName})`}
            </span>
          </div>
          <div className="flex gap-4">
            <Button
              type="submit"
              form="form"
              variant="gradient"
              onClick={() => navigate("/project-management")}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Done
              </div>
            </Button>
          </div>
        </div>

        <div className="bg-[#FFFFFF] border border-[#E2E8F0] shadow-custom p-4 rounded-lg mt-4 pb-10">
          <div className="flex justify-between">
            <div className="ml-1 text-[#374151] font-poppins text-[22px] font-[500] mt-1">
              Tasks
            </div>
            <button
              onClick={() => setShow(true)}
              className="flex items-center font-[600] text-[14px] px-4 buttoncss text-white h-10 rounded-lg"
            >
              <img className="w-4 h-4 mr-2" src={upload} alt="plus icon" />
              Add Task
            </button>
          </div>
          <div className="flex justify-between mt-2">
            <Search2
              onChange={updateSearchTerm}
              label="Search with task name"
            />
            <div className="flex gap-4">
              <div
                className="flex h-12 p-4 justify-between items-center rounded-md border-[1px] border-solid border-[#E2E8F0] bg-white"
                ref={selectedCalendarRef}
              >
                <DatePicker
                  className="w-[125px] text-center text-['Inter'] font-[400] cursor-pointer pl-1 pr-3 border-[#E2E8F0]"
                  dateFormat={"dd MMM yyyy"}
                  selected={selectedDate?.toDate() || null} // pass null if no date is selected
                  onChange={(date: Date | null) => {
                    handleSelectedDateChange(date ? dayjs(date) : null); // convert to dayjs or null
                    handleSelectedCalanderClick();
                  }}
                  open={isSelectedOpen}
                  onInputClick={handleSelectedCalanderClick}
                  onClickOutside={handleClickOutside}
                  isClearable
                  placeholderText="DD/MM/YYYY"
                  readOnly
                  customInput={
                    <input
                      style={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                        textAlign: "left",
                      }}
                    />
                  }
                />
                <img
                  src={date2}
                  alt="date icon"
                  className="w-5 h-5 cursor-pointer"
                  onClick={handleSelectedCalanderClick}
                />
              </div>

              <TextField
                id="outlined-required"
                select
                // label="Task Status"
                placeholder="Select Status"
                name="status"
                value={status}
                sx={{
                  height: "48px",
                  backgroundColor: "white",
                  borderColor: "#E2E8F0",
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    width: "150px",
                  },
                }}
                onChange={(e) => setStatus(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                {Status?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div className="overflow-x-auto mt-4">
            <div className="table_main_content w-full relative overflow-auto bg-white ">
              <DynamicTable<RowData>
                onRowClick={(row) => {
                  navigate("/view-task-details", {
                    state: { id: row?.id },
                  });
                }}
                data={tableData}
                loading={false}
                columns={columns}
                enableSorting
                enablePagination
              />
            </div>
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={page}
              numOfItems={numOfItems}
            />
          )}
        </div>
      </div>
      <AddTaskDialog show={show} setShow={setShow} fetchData={fetchData} />
    </>
  );
};

export default EditTask;
