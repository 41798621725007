import { useCallback, useEffect, useState } from "react";
import Search from "../SearchBar/Search";
import { debounce, size } from "lodash";
import DynamicTable from "../common/DynamicTable";
import TablePaginationDemo from "../common/Pagenation";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import toast from "react-hot-toast";
import { Fetch_Timesheet_list } from "../common/services/Employee";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import eye from "../../assets/Crud_Icons/view.svg";

interface ITimeSheetRecord {
  id: number;
  Project: {
    id: 339;
    project_name: string;
  };
  Task: {
    id: number;
    task_name: string;
    task_document: string[];
    estimated_hours: number;
  };
  date: string;
  duration: string;
  status: string;
}

export default function EmpTimeSheet({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const navigate = useNavigate();
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [timesheetSearchTerm, setTimesheetSearchTerm] = useState("");
  const [timeSheetData, setTimeSheetData] = useState<ITimeSheetRecord[]>([]);
  const [timesheetCount, setTimesheetCount] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [numOfItems, setNumOfItems] = useState<number>(10);

  const fetchTimesheetList = async () => {
    try {
      const response = await Fetch_Timesheet_list({
        customer_id: customer_id,
        user_id: id,
        page: page,
        size: numOfItems,
        start_date: startDate,
        end_date: endDate,
        search: timesheetSearchTerm,
      });
      if (response.status === 200) {
        setTimeSheetData(response.data?.result?.rows ?? []);
        setTimesheetCount(response.data?.result?.count);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    fetchTimesheetList();
  }, [startDate, endDate, timesheetSearchTerm, id, page, numOfItems]);

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setTimesheetSearchTerm(event.target.value);
      setPage(1);
    }, 1000),
    [timesheetSearchTerm]
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const columns: ColumnDef<ITimeSheetRecord>[] = [
    {
      accessorKey: "Project",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Project?.project_name ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <div>{item?.Project?.project_name}</div>;
      },
    },
    {
      accessorKey: "Task",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Task?.task_name ?? "-",
      cell: ({ row }) => {
        return <div className="">{row?.original?.Task?.task_name ?? "--"}</div>;
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date
          ? new Date(row.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date
          ? new Date(row.original.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px]">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "allocated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated Hours
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Task?.estimated_hours ?? "--",
      cell: ({ row }) => {
        return (
          <div className="">{row?.original?.Task?.estimated_hours || "--"}</div>
        );
      },
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Duration
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.duration ?? "-",
      cell: ({ row }) => {
        return <div>{row?.original?.duration} Hrs.</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        return row?.original?.status ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full ${
              row.original.status === "Approved"
                ? "bg-[#E0F1E7] text-[#0F9446] bg-[rgba(15,148,70,0.10)]"
                : " bg-[#EDCFCF] text-[#EB3F3F] bg-[#EDCFCF]"
            }`}
          >
            {row.original.status}
          </div>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0 mr-4"
              onClick={() =>
                navigate(`/employee-dsr-details`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col gap-3 mt-2">
      {/* <Search onChange={updateSearchTerm} label="Search" /> */}
      <div className="overflow-x-auto mt-2">
        <div className="table_main_content w-full relative overflow-auto bg-white">
          <DynamicTable<ITimeSheetRecord>
            data={timeSheetData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>
      </div>

      {timesheetCount >= 10 && (
        <TablePaginationDemo
          count={timesheetCount}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </div>
  );
}
