import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_EXPENSE_TYPE_LIST } from "../api";
import axiosInstance from "../../../app/api/axiosInstance";

// const session_query = `session_start=${localStorage.getItem(
//   "start_session"
// )}&session_end=${localStorage.getItem("end_session")}`;

export const Fetch_Expense_Type_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axiosInstance.get(
    `${FETCH_EXPENSE_TYPE_LIST}${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const createExpenseType = async (
  expense_category_name: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    expense_category_name: expense_category_name,
  };

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/expenses-category`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Edit_Expense_Type = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");

  return fetch(`${FETCH_EXPENSE_TYPE_LIST}?customer_id=${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Edit_Expense_Type_List = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");

  return axiosInstance.get(
    `${FETCH_EXPENSE_TYPE_LIST}?customer_id=${customerId}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const updateExpenseType = async (
  id: number,
  expense_category_name: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    expense_category_name: expense_category_name,
  };

  try {
    const response = await axiosInstance.patch(
      `${base_api}/api/v1/expenses-category/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
