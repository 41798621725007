import React from "react";

interface StatusBadgeProps {
  status: string;
  statusList?: string[];
  statusColors?: string[];
  textColors?: string[];
}

const defaultStatusList = ["active", "inactive"];
const defaultStatusColors = ["#ECFDF3", "#FFEAEA"];
const defaultTextColors = ["#137F40", "#DC2626"];

const StatusBadge: React.FC<StatusBadgeProps> = ({
  status = "-",
  statusList = defaultStatusList,
  statusColors = defaultStatusColors,
  textColors = defaultTextColors,
}) => {
  const normalizedStatus = status?.toLowerCase();
  const index = statusList?.findIndex((s) => s.toLowerCase() === normalizedStatus);
  const background = statusColors[index] || "#FFFFFF";
  const textColor = textColors[index] || "#000000";
  const displayStatus =
    status === "-" ? "-" : status?.charAt(0)?.toUpperCase() + status?.slice(1);
  return (
    <div
      className="flex justify-center items-center px-[10px] rounded-full w-fit py-[2px]"
      style={{ background: background, color: textColor }}
    >
      {displayStatus}
    </div>
  );
};

export default StatusBadge;
