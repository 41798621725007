import { useSelector } from "react-redux";
// import cross from "../../../../../assets/close-circle.svg"
import cross from "../../../assets/images/cross.png";
import "./styles.css";

import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userManagementActions } from "../../../redux/reducers/userManagement/userManagementSlice";
import { fetchUserManagementList } from "../../../redux/actions/UserManagement/UserManagementActions";
import AddressAutocomplete from "../../common/AddressAutocomplete";
import { Dayjs } from "dayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { fetchTimeZonesList } from "../../common/services/Employee";

interface AddUnitProps {
  onClose: () => void;
  onConfirm: (event: React.FormEvent<HTMLFormElement>) => void;
  modalType: string;
  handleIdChange: (value: string) => void;
  handleTimezoneChange?: any;
  handleLocationChange?: any;
}

interface IAddress {
  place: string;
  coordinates: [number | null, number | null];
}

interface ITimezone {
  id: number;
  abbv: string;
  name: string;
  utc_offset: string;
}

export const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};

const AddUnit: React.FC<AddUnitProps> = ({
  onConfirm,
  onClose,
  modalType,
  handleIdChange,
  // handleTransferLocation,
  handleTimezoneChange,
  handleLocationChange,
}) => {
  const dispatch = useDispatch();
  const signupData = useSelector((state: any) => state.signup.details);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [isLoading, setIsLoading] = useState(false);
  let customer_id = 0;
  if (signupData !== null) {
    customer_id = signupData.sign_up_details.customer_id;
  }

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const tableData = useSelector(
    (state: any) => state.userManagement.userManagementData.rows
  );

  const [curretLabel, setCurrentLabel] = useState({ id: 0, name: "" });
  const [time_zone_id, setTimezone_id] = useState<number | null>(null);
  const [timezonesList, setTimezonesList] = useState<ITimezone[]>([]);
  // const [businessAddress, setBusinessAddress] = useState<IAddress | null>(null);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      let url = `/api/v1/user/list?customer_id=${customer_id}&page=${1}&size=${10000}`;

      try {
        const resData = await dispatch<any>(fetchUserManagementList(url));

        const userManagementListData = resData?.result?.rows;

        dispatch(
          userManagementActions.setUserManagementData(
            userManagementListData || { count: 0, rows: [] }
          )
        );
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
        } else {
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  let modalLabel;
  if (modalType === "Business") {
    modalLabel = "Business";
  } else if (modalType === "Department") {
    modalLabel = "Department";
  } else {
    modalLabel = "Process";
  }

  const handleRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const currentRole = tableData.find(
      (item: any) => item.id == event.target.value
    );
    setCurrentLabel(currentRole);
    handleIdChange(currentRole.id);
  };

  const fetchTimeZones = useCallback(async () => {
    try {
      const timeZones = await fetchTimeZonesList();
      setTimezonesList(timeZones?.result || []);
    } catch (error) {
      console.error("Error fetching timezones:", error);
    }
  }, []);

  useEffect(() => {
    fetchTimeZones();
  }, [fetchTimeZones]);

  const handleTimezoneChange2 = (event: ChangeEvent<HTMLSelectElement>) => {
    const currentTimezone = timezonesList?.find(
      (item: any) => item.id == event.target.value
    );
    setTimezone_id(currentTimezone?.id ?? null);
    handleTimezoneChange(currentTimezone?.id ?? null);
  };

  return (
    <>
      <form
        // className="modal-overlay"
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm(e);
        }}
        className="placeholder-gray-500"
      >
        <div
          className={`${
            modalType === "Business"
              ? "h-[500px] overflow-auto bg-[ghostWhite] rounded-lg"
              : "modal-wrapper"
          } overflow-x-auto py-2 px-1`}
        >
          <div className="pb-3">
            <div className="close-btn-wrapper">
              <button className="close-modal-btn" onClick={onClose}>
                <img src={cross} alt="any" />
              </button>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <label className="ml-4 font-[Poppins]">
                  Add {modalLabel} Unit
                </label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder={`Enter ${modalLabel} Unit`}
                  className=" ml-4 w-[93%] p-3 border border-solid border-[#CCC] rounded-[4px] my-[6px] resize-y box-border placeholder-gray-500 h-14"
                />
              </div>
              {modalType === "Business" && (
                <>
                  <div>
                    <label className="ml-4 font-[Poppins]">Add Location</label>
                    <div className="ml-4 w-[93%] rounded-[4px] my-[6px] box-border bg-white">
                      <AddressAutocomplete
                        onSelect={(add) => handleLocationChange?.(add)}
                        label=""
                        placeholder="Enter Business Address"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="ml-4 font-[Poppins]" id="spoc">
                      Timezone
                    </label>
                    <select
                      className="ml-4 w-[93%] p-3 border  border-solid border-[#CCC] rounded-[4px] my-[6px] bg-white placeholder-gray-500 h-14 "
                      id="timezone_id"
                      name="timezone_id"
                      value={String(time_zone_id)}
                      onChange={handleTimezoneChange2}
                    >
                      <option value="">Select</option>
                      {timezonesList.map((option: any) => (
                        <option
                          key={option.id}
                          value={option.id}
                          style={menuStyle}
                        >
                          GMT{option.utc_offset} ({option.abbv})
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="ml-4 font-[Poppins]">
                      Organization Shift Start Time
                    </label>
                    <div className="flex justify-center my-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          // label="Shift Start"
                          name="shift_start"
                          className="ml-4 w-[93%] bg-white placeholder-gray-500 h-14"
                          ampm={false}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div>
                    <label className="ml-4 font-[Poppins]">
                      Organization Shift End Time
                    </label>
                    <div className="flex justify-center my-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          // label="Shift End"
                          name="shift_end"
                          className="ml-4 w-[93%] bg-white placeholder-gray-500 h-14"
                          ampm={false}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </>
              )}
              <div>
                <label className="ml-4 font-[Poppins]" id="spoc">
                  Assign SPOC
                </label>
                <select
                  className="ml-4 w-[93%] p-3 border  border-solid border-[#CCC] bg-white rounded-[4px] my-[6px] resize-y box-border font-[Poppins] placeholder-gray-500 h-14 "
                  id="spoc_id"
                  name="spoc_id"
                  defaultValue=""
                  value={curretLabel.id}
                  onChange={handleRoleChange}
                >
                  <option value="Select">Select</option>
                  {tableData?.map((role: any) => (
                    <option value={role.id} style={menuStyle}>
                      {role.firstName} {role.lastName}
                    </option>
                  ))}
                </select>
              </div>

              {modalType === "Business" && (
                <>
                  <div>
                    <label className="ml-4 font-[Poppins]">HR Team Email</label>
                    <input
                      id="hr_email"
                      type="email"
                      name="hr_email"
                      placeholder="Enter HR Team Email"
                      className="ml-4 w-[93%] p-3 border border-[#CCC] rounded-[4px] my-[6px] placeholder-gray-500 h-14"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="justify-end flex">
            <input
              type="submit"
              className="w-[25%] p-2 px-5 border-none rounded-[4px] bg-custom-primary text-white cursor-pointer my-[4px] resize-y box-border  font-[poppins] mr-4"
              value="Submit"
            ></input>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddUnit;
