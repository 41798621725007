import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

import { debounce } from "lodash";
import toast from "react-hot-toast";

import download from "../../assets/download_new.svg";
import profile from "../../assets/Ellipse 580.png";
import eye from "../../assets/Crud_Icons/view.svg";
import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Asset_Request_List } from "../common/services/Asset";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";

interface AssetRequestData {
  id: number;
  status: string;
  application_date: string;
  asset_name: string;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  AssetCategory: {
    id: number;
    category: string;
  };
  Project: {
    project_name: string;
  };
}

function AssetRequest() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<AssetRequestData[]>([]);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Asset_Request_List(
        customer_id,
        numOfItems,
        page,
        searchTerm
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer_id, page, numOfItems, searchTerm]);



  const handleDownload = () => {
    if (tableData.length > 0) {
      // Define the column headers based on the table structure
      const columns = [
        "Employee Name & ID",
        "Asset Category",
        "Asset Name",
        "Request Date",
        "Status",
      ];
  
      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...tableData.map((row) => [
          // Employee Name & ID: Combine firstName, lastName, and employee_id (if available)
          row?.User?.firstName && row?.User?.lastName
            ? `${row.User.firstName} ${row.User.lastName}`
            : "-", // Combine firstName and lastName, or fallback to "-",
          row?.User?.employee_id || "-", // Display employee_id, or fallback to "-",
  
          // Asset Category: Category of the asset (if available)
          row?.AssetCategory?.category ?? "-",
  
          // Asset Name: Asset name (if available)
          row?.asset_name ?? "-",
  
          // Request Date: Formatted application_date or fallback to "-"
          row?.application_date
            ? new Date(row.application_date).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "-",
  
          // Status: Status (if available)
          row?.status ?? "-",
        ]),
      ];
  
      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Asset Request Data");
  
      // Generate the binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "asset_request_data.xlsx"; // Customize download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };
  

  const columns: ColumnDef<AssetRequestData>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 pl-2 pr-2.5">
            <img
              className="w-8 h-8 rounded-full"
              src={item?.User?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item?.User?.firstName} {item?.User?.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item.User?.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "AssetCategory",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset Category
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.AssetCategory?.category ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.AssetCategory?.category ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "asset_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.asset_name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.asset_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "application_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Request Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.application_date
          ? new Date(row.application_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.application_date
          ? new Date(row.original.application_date).toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "short",
                year: "numeric",
              }
            )
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const name: string = row.original.status ?? "-";
        // return <div className="">{name}</div>;
        return row?.original?.status ? (
          <div
            className={`flex justify-center items-center py-[2px] px-[2px] rounded-full ${
              row.original.status === "Approved"
                ? "bg-[#ECFDF3] text-[#137F40]"
                : row.original.status === "Pending"
                ? "bg-[#FEEBDD] text-[#FF9950]"
                : row.original.status === "Rejected"
                ? "bg-[#FFEAEA] text-[#DC2626]"
                : "bg-[rgba(254, 235, 221, 0.40)] text-[#FF9950]"
            }`}
          >
            <div>{row?.original?.status}</div>
          </div>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/view-asset-request`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-6">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full">
              <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white">
            <DynamicTable<AssetRequestData>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        )}
      </div>
    </div>
  );
}

export default AssetRequest;
