import React, { forwardRef, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  BusinessUnit,
  DepartmentUnit,
} from "../EmployeeManagement/AddEmployee";
import { useDispatch } from "react-redux";
import {
  Department_Unit as DepartmentUnitType,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { X } from "lucide-react";
import DatePicker from "react-datepicker";
import date from "../../assets/date.svg";
import { Button } from "../common/Button";

interface FilterProps {
  open: boolean;
  handleClose: () => void;
  heading: string;
  onApply: (data: FilterData) => void;
}

export interface FilterData {
  dateRange?: [Date | null, Date | null];
  department?: string | null;
  business?: string | null;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

const CustomDateInput = forwardRef(
  ({ value, onClick, onClear }: any, ref: any) => (
    <div
      className="flex justify-between items-center"
      onClick={onClick}
      ref={ref}
    >
      <input
        type="text"
        value={value}
        className="w-full min-w-[200px] px-1 text-[12px] h-9 bg-white border-white focus-visible:outline-none text-ellipsis placeholder:text-gray-500 placeholder:font-poppins placeholder:text-[12px] placeholder:font-normal placeholder:leading-[24px]"
        placeholder="Select date range"
      />
      {value && (
        <X
          className="clear-icon cursor-pointer mr-1"
          size={16}
          onClick={onClear}
        />
      )}
    </div>
  )
);

const ExpenseFilter: React.FC<FilterProps> = ({
  open,
  handleClose,
  heading,
  onApply,
}) => {
  let customer_id = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector(
    (state: RootState) => state.expenseFilterSlice
  );

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [bussness, setBusiness] = useState<BusinessUnit[]>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [filterData, setFilterData] = useState<FilterData>({
    dateRange: [
      filter_data?.date ? filter_data?.date[0] : null,
      filter_data?.date ? filter_data?.date[0] : null,
    ],
    department: filter_data?.department ?? null,
    business: filter_data?.group ?? null,
  });

  useEffect(() => {
    setFilterData({
      ...filterData,
      department: filter_data?.department,
      business: filter_data?.group,
      dateRange: filter_data?.date,
    });
  }, [open, handleClose]);

  console.log({ filterData });

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          DepartmentUnitType(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, []);

  const handleApply = (filterData: FilterData) => {
    onApply(filterData);
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startingDate, endingDate] = dates;
    if (startingDate && endingDate) setIsCalendarOpen(!isCalendarOpen);
  };

  const handleClear = () => {
    setFilterData({
      ...filterData,
      dateRange: [null, null],
    });
    handleDateChange([null, null]);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="flex flex-col gap-4 justify-center items-start px-2">
            <p className="font-[Poppins] font-medium text-[20px] leading-4 text-[#262626]">
              {heading}
            </p>
            <div className="flex flex-col gap-4 w-full">
              <div>
                <FormLabel
                  sx={{
                    color: "#09090B",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Date
                </FormLabel>

                <div className="flex h-10 pr-2 justify-between items-center rounded-md border-[1px] border-solid border-[#CACACA]">
                  <DatePicker
                    selected={filterData?.dateRange?.[0]}
                    onChange={(date) => {
                      setFilterData({
                        ...filterData,
                        dateRange: date,
                      });
                      handleDateChange(date as [Date | null, Date | null]);
                    }}
                    startDate={filterData?.dateRange?.[0]}
                    endDate={filterData?.dateRange?.[1]}
                    selectsRange
                    className="w-full py-2.5 px-1 text-[12px] h-[38px] bg-white border-white placeholder:text-gray-500 placeholder:font-poppins placeholder:text-[12px] placeholder:font-normal placeholder:leading-[24px]"
                    customInput={<CustomDateInput onClear={handleClear} />}
                    dateFormat="MMM d, yyyy"
                    placeholderText="Select date range"
                    open={isCalendarOpen}
                    onInputClick={handleCalanderClick}
                    onClickOutside={() => {
                      handleClear();
                      handleCalanderClick();
                    }}
                    popperContainer={({ children }) => (
                      <div style={{ zIndex: 9 }}>{children}</div>
                    )}
                  />
                  <img
                    src={date}
                    alt="date icon"
                    onClick={handleCalanderClick}
                    className="flex items-center justify-between relative cursor-pointer"
                  />
                </div>
              </div>

              <div>
                <FormLabel
                  sx={{
                    color: "#09090B",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Business Unit
                </FormLabel>
                <TextField
                  id="outlined-required"
                  select
                  placeholder="Select"
                  name="business"
                  value={filterData?.business ?? ""}
                  fullWidth
                  size="small"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      business: e.target.value || null,
                    })
                  }
                  SelectProps={{
                    displayEmpty: true,
                    sx: {
                      color: "#6B7280",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "24px",
                      "& .MuiSelect-select": {
                        padding: "10px",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: filterData?.business && (
                      <InputAdornment
                        position="end"
                        sx={{ position: "absolute", right: "40px" }}
                      >
                        <IconButton
                          onClick={() =>
                            setFilterData({
                              ...filterData,
                              business: null,
                            })
                          }
                          edge="end"
                          aria-label="clear selection"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {bussness?.map((option) => (
                    <MenuItem key={Number(option.id)} value={Number(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <FormLabel
                  sx={{
                    color: "#09090B",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Department
                </FormLabel>
                <TextField
                  id="outlined-required"
                  select
                  placeholder="Department"
                  name="department"
                  value={filterData?.department || ""}
                  fullWidth
                  size="small"
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      department: e.target.value || null,
                    })
                  }
                  SelectProps={{
                    displayEmpty: true,
                    sx: {
                      color: "#6B7280",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 400,
                      fontStyle: "normal",
                      lineHeight: "24px",
                      "& .MuiSelect-select": {
                        padding: "10px",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: filterData?.department && (
                      <InputAdornment
                        position="end"
                        sx={{ position: "absolute", right: "40px" }}
                      >
                        <IconButton
                          onClick={() =>
                            setFilterData({
                              ...filterData,
                              department: null,
                            })
                          }
                          edge="end"
                          aria-label="clear selection"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {department?.map((option) => (
                    <MenuItem key={Number(option.id)} value={Number(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="flex justify-between gap-4 w-full pt-1">
              <button
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
                onClick={handleClose}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <Button
                onClick={() => handleApply(filterData)}
                variant={"gradient"}
              >
                <div
                  // onClick={handleOpen}
                  className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
                >
                  Apply
                </div>
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExpenseFilter;
