import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { debounce } from "lodash";

import avatar from "../../assets/avatar.svg";
import Search from "../SearchBar/Search";
import cross from "../../assets/cross_new.svg";
import TablePaginationDemo from "../common/Pagenation";

import "./Dashboard.css";
import { Fetch_NotCheckin } from "../common/services/Dashboard";
import dayjs, { Dayjs } from "dayjs";


interface ShowWFHProps {
  isOpen: boolean;
  onClose: () => void;
  date: Dayjs;
}

interface NotCheckIn {
    firstName: string;
    lastName: string;
    profile_image: string;
    notCheckIn_session: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "70%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ShowNotCheckInModal: React.FC<ShowWFHProps> = ({ isOpen, onClose,date }) => {
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<NotCheckIn[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );

  const fetchNotCheckIn = useCallback(async () => {
    try {
      const response = await Fetch_NotCheckin({
        numOfItems: numOfItems,
        page: page,
        searchTerm: searchTerm,
        date: date,
      });

        if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
        } else {
        console.error("Error:", response.status, response.statusText);
        }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [page, numOfItems, searchTerm, date])

  useEffect(() => {
    if(isOpen){
      fetchNotCheckIn();
    }
  }, [fetchNotCheckIn, isOpen]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="flex flex-col gap-6 h-full">
              <div className="flex flex-row justify-between items-center h-8">
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  Not Check In
                </span>
                <button onClick={onClose} className="">
                  <img
                    src={cross}
                    alt="close"
                    className="p-1.5 hover:bg-gray-100 rounded-md"
                  />
                </button>
              </div>
              <div className="h-11">
                <Search
                  label="Search with Employee Name"
                  onChange={updateSearchTerm}
                />
              </div>
              <div className="h-[calc(100%-128px)] overflow-y-auto scrollBar pr-2">
                <div className="grid grid-cols-4 gap-4">
                  {tableData?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.profile_image || avatar}
                        alt="profile"
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.firstName} {user?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.notCheckIn_session}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {count >= 11 && (
                <TablePaginationDemo
                  count={count}
                  handleItemsChange={handleNumberOfPages}
                  handlePageChange={handlePageChange}
                  currentPage={page}
                  numOfItems={numOfItems}
                />
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ShowNotCheckInModal;
