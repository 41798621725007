import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import { useSelector } from "react-redux";
import DynamicTable from "../../common/DynamicTable";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../../common/Pagenation";
import StatusBadge from "../../common/StatusBadge";
import UserBadge from "../../common/UserBadge";
import { RootState } from "../../../redux/store";
import { Fetch_WFH_List } from "../../common/services/Leave";
import eye from "../../../assets/Crud_Icons/view.svg";
import TableSkeleton from "../../common/TableSkeleton";
import { filter } from "lodash";
import { MenuItem, TextField } from "@mui/material";

interface Department {
  name: string;
}

interface UpcomingWFH {
  User: {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
    employee_id: string;
    profile_image: string;
    Department: Department | null;
  };
  no_of_days: string;
  from_date: string;
  to_date: string;
  id: string;
  wfh_status: string;
  wfh_session: string;
}

interface UpcomingWFHProps {
  depId: string | null;
  busId: string | null;
  dates: [Date | null, Date | null] | undefined;
}

const UpcomingWFH: React.FC<UpcomingWFHProps> = ({ depId, busId, dates }) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<UpcomingWFH[]>([]);

  const [loading, setLoading] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  // const [status, setStatus] = useState<string>("");

  const filter_data = useSelector((state: RootState) => state.filterPersist);
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  function handlePageChange(value: number) {
    setPage(value);
  }
  const [status, setStatus] = useState<string>("");

  // const WFHOptions = [
  //   { label: "All WFH", value: "" },
  //   { label: "Approved", value: "Approved" },
  //   { label: "Rejected", value: "Rejected" },
  //   { label: "Pending", value: "Pending" },
  // ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_WFH_List(
        numOfItems,
        page,
        searchTerm ?? "",
        Number(busId),
        Number(depId),
        dates ? dates[0] : null,
        dates ? dates[1] : null,
        true,
        status
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      }
    } catch (error) {}
    setLoading(false);
  }, [numOfItems, page, searchTerm, busId, depId, dates, status]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns2: ColumnDef<UpcomingWFH>[] = [
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item?.User} user_id={item?.User?.id} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "wfh_session",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          WFH Period
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.wfh_session ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.wfh_session ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "from_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.from_date
          ? new Date(row.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.from_date
          ? new Date(row.original.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "to_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.to_date
          ? new Date(row.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.to_date
          ? new Date(row.original.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "wfh_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.wfh_status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.wfh_status ?? "-";
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];
        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/work-from-home-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* <div className="flex items-center justify-end mt-[-32px] mb-3"> */}{" "}
      {/* <TextField
          id="outlined-required"
          select
          placeholder="All WFH"
          name="status"
          value={status}
          className="ml-auto w-[150px]"
          sx={{
            height: "40px",
            backgroundColor: "white",
            borderRadius: "0.5rem",
            marginTop: 0,
            "& .MuiOutlinedInput-root": {
              height: "100%",
              minWidth: 80,
              borderRadius: "0.5rem",
              border: "none",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "29px",
              color: "#49454F",
              outline: "none",
              "&:focus": { outline: "none" },
              "& fieldset": {
                border:
                  "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
              },
              "&:hover fieldset": {
                borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important",
                borderWidth: "1px",
              },
            },
          }}
          onChange={(e) => {
            setStatus(e.target.value);
            fetchData();
          }}
          SelectProps={{ displayEmpty: true }}
        >
          {WFHOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
      {/* </div> */}
      <div className="overflow-x-auto mt-4 mb-10">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<UpcomingWFH>
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>
      {count >= 10 && (
        <div className="mb-4">
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        </div>
      )}
      {/* <div className="relative">
                          <TextField
                            id="outlined-required"
                            select
                            // label="Task Status"
                            placeholder="WFH"
                            name="status"
                            value={selectedWFHStatus}
                            sx={{
                              height: "40px",
                              backgroundColor: "white",
                              borderRadius: "0.5rem",
                              "& .MuiOutlinedInput-root": {
                                height: "100%",
                                minWidth: 80,
                                borderRadius: "0.5rem",
                                border: "none",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "29px",
                                color: "#49454F",
                                outline: "none",
                                "&:focus": {
                                  outline: "none",
                                },
                                "& fieldset": {
                                  border:
                                    "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))", // Default border transparent
                                },
                                "&:hover fieldset": {
                                  borderColor:
                                    "rgb(205 205 205 / var(--tw-border-opacity, 1))", // Green border on hover
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    "rgb(205 205 205 / var(--tw-border-opacity, 1)) !important", // Green border on focus
                                  borderWidth: "1px",
                                },
                              },
                            }}
                            onChange={(e) =>
                              handleWFHStatusesChange(e.target.value)
                            }
                            SelectProps={{
                              displayEmpty: true,
                            }}
                          >
                            {WFHOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div> */}
    </>
  );
};

export default UpcomingWFH;
