import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { debounce } from "lodash";
import Footer from "../Layout/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Role_Management_List } from "../common/services/roleManagement";
import "react-datepicker/dist/react-datepicker.css";
import maskPlus from "../../image/Mask group.svg";
import filter from "../../assets/filter.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import {
  ROLE_MANAGEMENT_EDIT_ROLE_DETAILS,
  ROLE_MANAGEMENT_ROLE_DETAILS,
} from "../../utils/routeConstant";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import moment from "moment";
import "./roleManagement.css";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import TableSkeleton from "../common/TableSkeleton";
import { Close } from "@mui/icons-material";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface createdByUser {
  firstName: string;
  lastName: string;
}

interface IRoleManagement {
  role_name: string;
  total_user: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  id: number;
  createdByUser: createdByUser;
}

const RoleManagement = () => {
  const [tableData, setTableData] = useState<IRoleManagement[]>([]);
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState(false);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const loginData = useSelector((state: any) => state.auth.login.login_details);

  /////////////pagination////////////////

  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  ////////////////////////////////////

  const [sortType, setSortType] = useState<any>({
    key: "createdAt",
    order: "DESC",
  });

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Role_Management_List(
        customer_id,
        numOfItems,
        page,
        sortType,
        searchTerm,
        startDate,
        endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, page, startDate, endDate, numOfItems]);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.refresh) {
      fetchData();
    }
  }, [location]);

  //////////////////////////////searching///////////////////////////

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const filteredTableData = useMemo(() => {
    if (!tableData) {
      return [];
    }

    return tableData.filter((row: any) =>
      row.role_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [tableData, searchTerm]);

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    if (dates[0] && dates[1]) {
      // close the calendar when both the dates are selected
      setIsCalendarOpen(false);
    }
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleDelete = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    fetchData();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {};

  const columns: ColumnDef<IRoleManagement>[] = [
    {
      accessorKey: "role_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Role Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const item = row.original;
        const fill =
          item.status === "active"
            ? "#00CBA0"
            : item.status === "inactive"
            ? "#FF9950"
            : "#FA1464";

        return (
          <button
            onClick={() =>
              navigate(`${ROLE_MANAGEMENT_EDIT_ROLE_DETAILS}`, {
                state: { id: item.id },
              })
            }
            className="cursor-pointer"
          >
            <div className="flex flex-row items-center gap-2">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
              >
                <circle cx="5.5" cy="5.35156" r="5" fill={fill} />
              </svg> */}
              {/* <span className="text-[12px] leading-[13.981px]"> */}
              {item.role_name}
              {/* </span> */}
            </div>
          </button>
        );
      },
    },
    {
      accessorKey: "createdByUser",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Created By
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => {
        const createdBy = info.getValue() as createdByUser;
        return createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : "-";
      },
    },
    {
      accessorKey: "total_user",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          No. of Users
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const item = row.original;

        return (
          <div
            onClick={() =>
              navigate(`${ROLE_MANAGEMENT_ROLE_DETAILS}`, {
                state: { id: item?.id },
              })
            }
            className="underline cursor-pointer text-blue-800"
          >
            {item.total_user}
          </div>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Added Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.createdAt
          ? new Date(row.original.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Last Updated
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.updatedAt
          ? new Date(row.updatedAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.updatedAt
          ? new Date(row.original.updatedAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";

        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "check_in_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        return row?.original?.status ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] min-w-[80px] rounded-full capitalize w-fit ${
              row.original.status === "active"
                ? "bg-[#E0F1E7] text-[#0F9446]"
                : "bg-[#EDCFCF] text-[#EB3F3F]"
            }`}
          >
            {row.original.status}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div className="flex items-center justify-end gap-2 mr-4">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-role`, {
                  state: { id: item.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col px-4 py-6 bg-[#F9F9F9]">
      <div className="flex-grow">
        {/* Your main content goes here */}
        <div className="h-full">
          <div className="">
            <div className="flex flex-row items-center h-12 justify-between">
              <div className="flex flex-row gap-[10px] justify-center items-center">
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  Role Management
                </span>
              </div>
              <div>
                <button
                  onClick={() => navigate(`/add-role`)}
                  className="flex items-center font-['Poppins'] font-[600] text-[14px] px-6 buttoncss text-white rounded-lg h-12"
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={maskPlus}
                    alt="plus icon"
                  />
                  Add Role
                </button>
              </div>
            </div>

            <div className="pt-3 flex flex-col gap-[10px]">
              <div className="h-[40px] w-full">
                <div className="h-full flex justify-between items-center">
                  <Search
                    onChange={updateSearchTerm}
                    label="Search with role name"
                  />
                  <div className="h-full flex items-center gap-5">
                    <div className="h-full flex justify-end items-center">
                      {dateRange ? (
                        <button
                          onClick={handleDelete}
                          className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                        >
                          <Close
                            sx={{
                              fontSize: "15px",
                            }}
                          />
                          Clear All
                        </button>
                      ) : null}
                    </div>
                    <Button variant={"border"} onClick={() => setIsOpen(true)}>
                      <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                      Filter
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex flex-row mb-4 justify-end gap-[31px]">
                {/* <div className="flex flex-row justify-between gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill="#00CBA0"
                    />
                  </svg>
                  <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                    Active
                  </span>
                </div> */}

                {/* <div className="flex flex-row justify-between gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill="#FF9950"
                    />
                  </svg>
                  <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                    Inactive
                  </span>
                </div> */}

                {/* <div className="flex flex-row justify-between gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.99537"
                      cy="3.74537"
                      r="3.49537"
                      fill="#FA1464"
                    />
                  </svg>
                  <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                    Archived
                  </span>
                </div> */}
              </div>
            </div>

            <div className="">
              {loading ? (
                <TableSkeleton />
              ) : (
                <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
                  <DynamicTable<IRoleManagement>
                    data={tableData}
                    loading={false}
                    columns={columns}
                    enableSorting
                    enablePagination
                  />
                </div>
              )}

              <div className="mb-6 mt-2">
                {count >= 10 && (
                  <TablePaginationDemo
                    count={count}
                    handleItemsChange={handleNumberOfPages}
                    handlePageChange={handlePageChange}
                    currentPage={page}
                    numOfItems={numOfItems}
                  />
                )}
                <Filter
                  isDate
                  isOther={false}
                  heading="Role Management Filter"
                  selectnameBusiness="Select Business Unit"
                  optionsBusiness={bussness}
                  onOptionSelectBusiness={handleBusinessUnitSelect}
                  selectnameDepartment="Select Department"
                  optionsDepartment={department}
                  onOptionSelectDepartment={handleDepartmentUnitSelect}
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  onApply={handleFilterApply}
                  selected={selectedDates[1]}
                  startDate={selectedDates[0]}
                  endDate={selectedDates[1]}
                  isCalendarOpen={isCalendarOpen}
                  handleCalanderClick={handleCalanderClick}
                  handleDateChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default RoleManagement;
