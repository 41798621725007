import { useCallback, ChangeEvent, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import eye from "../../assets/Crud_Icons/view.svg";
import { debounce } from "lodash";
import * as XLSX from "xlsx";
import { Chip } from "@material-ui/core";

import maskPlus from "../../image/Mask group.svg";
import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import edit from "../../assets/Crud_Icons/edit.svg";

import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import TablePaginationDemo from "../common/Pagenation";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { Fetch_Announcement_List } from "../common/services/Announcements";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";
import { Close } from "@mui/icons-material";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import { RootState } from "../../redux/store";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import StatusBadge from "../common/StatusBadge";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface IAnnouncementList {
  id: number;
  title: string;
  status: string;
  // group_id: Number;
  date: string;
  description: string;
  // Group: {
  //   name: string;
  // };
  AnnouncementGroups: {
    group_id: Number;
    Group: {
      id: number;
      name: string;
    };
  }[];
  AnnouncementDepartments: {
    department_id: number;
    Department: {
      id: number;
      name: string;
    };
  }[];
}

interface Column {
  id: string;
  displayName: string;
}

interface AnnouncementData {
  [key: string]: any;
}

function Announcements() {
  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  // const [searchTerm, setSearchTerm] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [reportData, setReportData] = useState<AnnouncementData[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IAnnouncementList[]>([]);
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);

  // const updateSearchTerm = useCallback(
  //   debounce((event: ChangeEvent<HTMLInputElement>) => {
  //     setSearchTerm(event.target.value);
  //     setPage(1);
  //   }, 100),
  //   [setSearchTerm]
  // );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setIsOpen(false);
  };
  const handleDelete = () => {
    setBusId(null);
    setDepId(null);
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
      })
    );
  };

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from reportData
      const worksheetData = [
        columns?.map((col) => col?.displayName), // Header row
        ...reportData?.map((row) =>
          columns?.map((col) => row[col?.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Apply formatting to the header row (make bold)
      const headerRange = XLSX.utils.decode_range(worksheet["!ref"] || "");
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: { bold: true }, // Make headers bold
          alignment: { horizontal: "center", vertical: "center" }, // Center headers
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: {
            fgColor: { rgb: "E0EBF5" }, // Light background color
          },
        };
      }

      // Auto-width columns based on their content
      worksheet["!cols"] = columns.map(() => ({ wch: 20 })); // Default column width

      // Add cell borders to all data cells
      for (let R = headerRange.s.r + 1; R <= headerRange.e.r; ++R) {
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
            alignment: { horizontal: "left", vertical: "center" }, // Align text properly
          };
        }
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Announcement Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "announcement_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Announcement_List(
        customer_id,
        numOfItems,
        page,
        Number(busId),
        Number(depId),
        searchTerm ?? ""
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);

        const fetchedData: AnnouncementData[] = response?.data?.result?.rows;
        const columnMapping: { [key: string]: string } = {
          id: "ID",
          title: "Announcment Title",
          description: "Description",
          "Group.name": "Business Unit",
          departments: "Departments",
        };

        const filteredData = fetchedData?.map((row) => {
          const filteredRow: Partial<AnnouncementData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (key === "departments") {
                filteredRow[key] =
                  row?.AnnouncementDepartments?.map(
                    (dept: any) => dept?.Department?.name
                  ).join(", ") || "No Departments";
              } else if (row.hasOwnProperty(keys[0])) {
                filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setReportData(filteredData);

        const cols: Column[] = Object?.keys(columnMapping)?.map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  // const isFirstRender = useRef(true);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchData();
  }, [searchTerm, page, numOfItems, busId, depId]);

  const columns2: ColumnDef<IAnnouncementList>[] = [
    {
      accessorKey: "title",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Title
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.title ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2">
            <div className="max-w-[300px] whitespace-nowrap text-ellipsis overflow-hidden">
              {item?.title}
            </div>
            {/* <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22] max-w-[200px] whitespace-nowrap overflow-hidden">
                {item.title}
              </span>
            </div> */}
          </div>
        );
      },
    },
    {
      accessorKey: "Group",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Business Unit
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.AnnouncementGroups?.map((item) => item?.Group?.name ?? "").join(
          ", "
        ) || "-",
      cell: ({ row }) => {
        const businessName: string[] = row?.original?.AnnouncementGroups?.map(
          (item) => item?.Group?.name ?? ""
        );
        const BussName: string =
          businessName?.length > 0 ? businessName?.join(", ") : "-";
        return (
          <div className="max-w-[300px] whitespace-nowrap text-ellipsis overflow-hidden">
            {BussName}
          </div>
        );
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Departments
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.AnnouncementDepartments?.map(
          (item) => item?.Department?.name ?? ""
        ).join(", ") || "-",
      cell: ({ row }) => {
        const departmentNames: string[] =
          row?.original?.AnnouncementDepartments?.map(
            (item) => item?.Department?.name ?? ""
          );
        const name: string =
          departmentNames?.length > 0 ? departmentNames?.join(", ") : "-";

        return (
          <div className="max-w-[300px] whitespace-nowrap text-ellipsis overflow-hidden">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Published / Scheduled Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date
          ? new Date(row.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date
          ? new Date(row.original.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status ?? "-";
        const statusList = ["published", "scheduled"];
        const statusColors = ["#ECFDF3", "#FEEBDD"];
        const textColors = ["#137F40", "#FF9950"];

        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex items-center justify-end mr-4">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2 mr-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate("/view-announcement", {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-announcement`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex flex-row gap-[2px] justify-center items-center">
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Announcements
            </span>
          </div>
          <div>
            <Button
              onClick={() => navigate("/add-announcement")}
              variant={"gradient"}
            >
              <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
              Add Announcements
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="h-[40px]">
            <div className="h-full flex justify-between items-center">
              <PersistedSearch label="Search with Announcement" />
              <div className="h-full flex items-center gap-5">
                <div className="h-full flex justify-end items-center">
                  {(busId && busId !== "") || (depId && depId !== "") ? (
                    <button
                      onClick={handleDelete}
                      className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                    >
                      <Close
                        sx={{
                          fontSize: "15px",
                        }}
                      />
                      Clear All
                    </button>
                  ) : null}
                </div>
                <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2.5">
            {loading ? (
              <TableSkeleton />
            ) : (
              <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white">
                <DynamicTable<IAnnouncementList>
                  data={tableData}
                  loading={false}
                  columns={columns2}
                  enableSorting
                  enablePagination
                />
              </div>
            )}
          </div>
          <div>
            {count >= 10 && (
              <TablePaginationDemo
                count={count}
                handleItemsChange={handleNumberOfPages}
                handlePageChange={handlePageChange}
                currentPage={1}
                numOfItems={10}
              />
            )}
          </div>
        </div>
      </div>

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Announcement Filter"
        onApply={handleApply}
        isDate={false}
        isOther
      />
    </div>
  );
}

export default Announcements;
