import React, { useEffect, useState, useCallback } from "react";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import UserBadge from "../common/UserBadge";
import {
  Fetch_Employee,
  Fetch_Employee_Count,
  Fetch_Employee_Department,
  Fetch_Employee_newUsers,
  Fetch_Employee_Notice,
  Fetch_Employee_Upcoming_Anniversaries,
  Fetch_Employee_Upcoming_Birthday,
} from "../common/services/Employee";
import DoughnutChart, {
  centerTextPlugin,
} from "../common/Charts/DoughnutChart";
import BarChart from "../common/Charts/BarChart";
import { useInView } from "react-intersection-observer";

interface IEmployeeData {
  total_users: string;
  active_users: string;
  inactive_users: string;
  users_on_notice: string;
}

interface IEmployement_Data {
  full_time: number;
  part_time: number;
  internship: number;
  contract: number;
}

interface Employee_Department {
  full_time: number;
  part_time: number;
  internship: number;
  contract: number;
  Department: IDepartment;
}

interface IDepartment {
  id: number;
  name: string;
}

interface DepartmentOverviewData {
  label: string;
  data: number[];
  color: string;
}

interface NewJoinees {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  employee_id: string;
  Department: {
    name: string;
  };
  profile_image: string;
  date_of_joining: number;
}

interface Notice_Period {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  employee_code: string;
  Department: {
    name: string;
  };
  profile_image: string;
  date_of_leaving: number;
}

interface Upcoming_Birthdays {
  id: number;
  firstName: string;
  lastName: string;
  profile_image: string;
  date_of_birth: string;
  Department: {
    name: string;
    id: number;
  };
}

interface Anniversaries {
  id: number;
  employee_code: string;
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  date_of_joining: string;
  Department: {
    id: number;
    name: string;
  };
}

interface DashboardProps {
  businessId: string | null;
}

const EmployeeDashboard: React.FC<DashboardProps> = ({ businessId }) => {
  const [tableData, setTableData] = useState<NewJoinees[]>([]);
  const [tableData1, setTableData1] = useState<Notice_Period[]>([]);
  const [tableData2, setTableData2] = useState<Upcoming_Birthdays[]>([]);
  const [tableData3, setTableData3] = useState<Anniversaries[]>([]);
  const [countList, setCountList] = useState<IEmployeeData>();
  const [employmentList, setEmployementList] = useState<IEmployement_Data>();
  const [department, setDepartment] = useState<Employee_Department>();
  const [loading, setLoading] = useState(true);
  const [departmentData, setdepartmentData] = useState<Employee_Department[]>(
    []
  );
  const numOfItems = 10;
  const [hasMore, setHasMore] = useState(true);
  const { ref: birthdayRef, inView: birthdayInView } = useInView();
  const [upcomingBirthday, setUpcomingBirthday] = useState<number>(1);
  useEffect(() => {
    if (birthdayInView && hasMore) {
      setUpcomingBirthday((prev) => prev + 1);
    }
  }, [birthdayInView, hasMore]);

  const [hasMore2, setHasMore2] = useState(true);
  const { ref: anniversaryRef, inView: anniversaryInView } = useInView();
  const [upcomingAnniversary, setUpcomingAnniversary] = useState<number>(1);
  useEffect(() => {
    if (anniversaryInView && hasMore2) {
      setUpcomingAnniversary((prev) => prev + 1);
    }
  }, [anniversaryInView, hasMore2]);

  const fetchEmployee_birthday = useCallback(async () => {
    try {
      const response = await Fetch_Employee_Upcoming_Birthday(
        Number(businessId),
        upcomingBirthday,
        numOfItems
      );
      if (response.status === 200) {
        const newBirthdays = response?.data?.result?.rows ?? [];
        const totalUpcomingBirthday = response?.data?.result?.count ?? 0;
        setTableData2((prev) => [...prev, ...newBirthdays]);
        if (upcomingBirthday * numOfItems >= totalUpcomingBirthday) {
          setHasMore(false);
        }
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [businessId, upcomingBirthday]);

  useEffect(() => {
    fetchEmployee_birthday();
  }, [fetchEmployee_birthday]);

  const fetchEmployeeAnniversaries = useCallback(async () => {
    try {
      const response = await Fetch_Employee_Upcoming_Anniversaries(
        Number(businessId),
        upcomingAnniversary,
        numOfItems
      );
      if (response.status === 200) {
        const newAnniversary = response?.data?.result?.rows ?? [];
        const totalUpcomingAnniversary = response?.data?.result?.count ?? 0;
        setTableData3((prev) => [...prev, ...newAnniversary]);
        if (upcomingAnniversary * numOfItems >= totalUpcomingAnniversary) {
          setHasMore2(false);
        }
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [businessId, upcomingAnniversary]);

  useEffect(() => {
    fetchEmployeeAnniversaries();
  }, [fetchEmployeeAnniversaries]);

  const fetchEmployee_Notice = useCallback(async () => {
    try {
      const response = await Fetch_Employee_Notice(Number(businessId));
      if (response.status === 200) {
        setTableData1(response.data?.result?.rows);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [businessId]);

  useEffect(() => {
    fetchEmployee_Notice();
  }, [fetchEmployee_Notice]);

  const fetchEmployeeNewUsers = useCallback(async () => {
    try {
      const response = await Fetch_Employee_newUsers(Number(businessId));
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [businessId]);

  useEffect(() => {
    fetchEmployeeNewUsers();
  }, [fetchEmployeeNewUsers]);

  const fetchEmployeeCount = useCallback(async () => {
    try {
      const response = await Fetch_Employee_Count({
        group_id: Number(businessId),
      });
      if (response.status === 200) {
        setCountList(response.data?.result);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [businessId]);

  useEffect(() => {
    fetchEmployeeCount();
  }, [fetchEmployeeCount]);

  const fetchEmployee = useCallback(async () => {
    setLoading(true);
    try {
      const employeeOverviewData = await Fetch_Employee(Number(businessId));
      if (employeeOverviewData?.result) {
        const employeeOverview = {
          full_time: employeeOverviewData?.result?.full_time,
          part_time: employeeOverviewData?.result?.part_time,
          internship: employeeOverviewData?.result?.internship,
          contract: employeeOverviewData?.result?.contract,
        };
        setEmployementList(employeeOverview);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [businessId]);

  useEffect(() => {
    fetchEmployee();
  }, [fetchEmployee]);

  const fetchDepartment = useCallback(async () => {
    setLoading(true);
    try {
      const employeeDepartmentData = await Fetch_Employee_Department({
        group_id: Number(businessId),
      });

      if (employeeDepartmentData?.result) {
        const employee_departments: Employee_Department[] =
          employeeDepartmentData.result.map((item: any) => ({
            full_time: parseInt(item.full_time),
            part_time: parseInt(item.part_time),
            internship: parseInt(item.internship),
            contract: parseInt(item.contract),
            Department: {
              id: item.Department.id,
              name: item.Department.name,
            },
          }));
        setdepartmentData(employee_departments);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [businessId]);

  useEffect(() => {
    fetchDepartment();
  }, [fetchDepartment]);

  const taskDataDoughNut = [
    {
      label: "Full Time",
      value: employmentList?.full_time ? employmentList?.full_time : 0,
      color: "#CDEBC5",
    },
    {
      label: "Part Time",
      value: employmentList?.part_time ? employmentList?.part_time : 0,
      color: "#8AB3FF",
    },
    {
      label: "Interns",
      value: employmentList?.internship ? employmentList?.internship : 0,
      color: "#EDD9F2",
    },
    {
      label: "Contract",
      value: employmentList?.contract ? employmentList?.contract : 0,
      color: "#FBD796",
    },
  ];

  const optionsDoughtNut = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    cutout: "70%",
    plugins: {
      legend: {
        display: true,
        position: "right" as const,
        labels: {
          color: "#1F2937",
          boxWidth: 20,
          boxHeight: 20,
          borderRadius: 4,
        },
      },
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "#000000",
        borderColor: "#dddddd",
        borderWidth: 1,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            const label = dataset.labels?.[index] || tooltipItem.label;
            return ` ${label} (${value})`;
          },
        },
      },
      centerTextPlugin,
    },
  };

  const departmentOverviewLabels: string[] = departmentData?.length
    ? departmentData.map((item) => item?.Department?.name)
    : [];

  const employeeDepartmentOverviewData: DepartmentOverviewData[] = [
    {
      label: "Full Time",
      data: departmentData?.map((item) => Number(item.full_time) || 0),
      color: "#CDEBC5",
    },
    {
      label: "Part Time",
      data: departmentData?.map((item) => Number(item.part_time) || 0),
      color: "#8AB3FF",
    },
    {
      label: "Interns",
      data: departmentData?.map((item) => Number(item.internship) || 0),
      color: "#EDD9F2",
    },
    {
      label: "Contract",
      data: departmentData?.map((item) => Number(item.contract) || 0),
      color: "#FBD796",
    },
  ];

  const departmentOverviewOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#1C2024",
          boxWidth: 15,
          boxHeight: 15,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: false,
        external: function (context: any) {
          const { chart, tooltip } = context;

          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create the tooltip element if it doesn't exist
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "#fff";
            tooltipEl.style.border = "1px solid #E5E7EB";
            tooltipEl.style.padding = "10px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.borderRadius = "4px";
            tooltipEl.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
            tooltipEl.style.zIndex = "100";
            tooltipEl.style.fontSize = "12px";
            tooltipEl.style.color = "#6B7280";
            document.body.appendChild(tooltipEl);
          }

          // If tooltip is not visible, hide the tooltip element
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          const dataIndex = tooltip.dataPoints?.[0]?.dataIndex;
          if (dataIndex === undefined) {
            tooltipEl.style.opacity = "0";
            return;
          }

          const total = employeeDepartmentOverviewData.reduce(
            (sum: number, dataset) => {
              return sum + dataset.data[dataIndex];
            },
            0
          );

          // Generate tooltip content with individual values and the total
          const tooltipContent = employeeDepartmentOverviewData
            .map((dataset) => {
              const value = dataset.data[dataIndex];
              return `
                  <div style="display: flex; align-items: center; margin-bottom: 4px;">
                      <div style="
                          width: 12px;
                          height: 12px;
                          background-color: ${dataset.color};
                          margin-right: 8px;
                          border-radius: 2px;
                      "></div>
                      <div>${dataset.label}: ${value}</div>
                  </div>
              `;
            })
            .join("");

          // Set the tooltip content, showing individual dataset values and the total
          tooltipEl.innerHTML = `
              <div style="font-weight: bold; margin-bottom: 6px; color: #474747;">
                  ${tooltip.title || ""}
              </div>
              ${tooltipContent}
              <div style="margin-top: 10px; font-weight: bold;">
                  Total: ${total}
              </div>
          `;

          // Get the position of the canvas and place the tooltip accordingly
          const canvasRect = chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = "1";

          tooltipEl.style.left =
            canvasRect.left +
            window.scrollX +
            tooltip.caretX -
            tooltipEl.offsetWidth -
            10 +
            "px";

          tooltipEl.style.top =
            canvasRect.top + window.scrollY + tooltip.caretY + "px";
        },
      },
    },
    scales: {
      x: {
        stacked: true, // Stack the bars on the x-axis (horizontally)
        grid: { display: false },
      },
      y: {
        stacked: true,
        grid: { display: false },
        ticks: {
          beginAtZero: true,
          stepSize: 1, // Ensures only integer steps
          callback: function (value:any) {
            return Number.isInteger(value) ? value : '';
          },
        },
      },
    },
    datasets: employeeDepartmentOverviewData.map((item) => ({
      label: item.label,
      data: item.data,
      backgroundColor: item.color,
    })),
  };

  const columns: ColumnDef<NewJoinees>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.Department?.name ?? "-";
        return <div>{name}</div>;
      },
    },
    {
      accessorKey: "date_of_joining",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Joined On
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date_of_joining
          ? new Date(row.date_of_joining).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date_of_joining
          ? new Date(row.original.date_of_joining).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
  ];

  const columns1: ColumnDef<Notice_Period>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.Department?.name ?? "-";
        return <div>{name}</div>;
      },
    },
    {
      accessorKey: "date_of_leaving",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Last Working Day
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date_of_leaving
          ? new Date(row.date_of_leaving).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date_of_leaving
          ? new Date(row.original.date_of_leaving).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
  ];

  const columns2: ColumnDef<Upcoming_Birthdays>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.Department?.name ?? "-";
        return <div>{name}</div>;
      },
    },
    {
      accessorKey: "date_of_birth",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date_of_birth
          ? new Date(row.date_of_birth).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date_of_birth
          ? new Date(row.original.date_of_birth).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
  ];

  const columns3: ColumnDef<Anniversaries>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.Department?.name ?? "-";
        return <div>{name}</div>;
      },
    },
    {
      accessorKey: "date_of_joining",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date_of_joining
          ? new Date(row.date_of_joining).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date_of_joining
          ? new Date(row.original.date_of_joining).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
  ];

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div className="flex justify-between gap-4 flex-wrap items-center ">
        <div className="h-[98px] rounded-lg flex-1 bg-[#FFFFFF] flex flex-col justify-center items-start pl-4 gap-2 border border-default-gray-200 shadow-sm">
          <p className="text-[#4B5563] text-base font-medium font-['Inter']">
            Total Employee
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.total_users ?? 0}
          </p>
        </div>{" "}
        <div className="h-[98px] flex-1 rounded-lg bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-['Inter']">
            Active Employee
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.active_users ?? 0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-['Inter']">
            Inactive Employee
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.inactive_users ?? 0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-['Inter']">
            On Notice Period
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.users_on_notice ?? 0}
          </p>
        </div>
      </div>
      <div className="flex w-full gap-4">
        {/* Employment Mode Overview - Left Side (50%) */}
        <div className="flex flex-col w-1/2 h-[298px] bg-[#FFFFFF] rounded-md border border-default-gray-200 shadow-sm">
          <h2 className="text-[#1F2937] text-[14px] font-medium tracking-[-0.4px] font-poppins mt-3 mb-1 ml-3">
            Employment Mode Overview
          </h2>
          <div className="flex items-center justify-center h-full">
            <div className="w-full max-w-[300px] h-full">
              <DoughnutChart
                chartData={taskDataDoughNut}
                options={optionsDoughtNut}
              />
              {/* <DoughnutChart chartData={employeeStats} options={defaultOptions} /> */}
            </div>
          </div>
        </div>

        {/* Employee Distribution by Department - Right Side (50%) */}
        <div className="flex flex-col w-1/2 h-[298px] bg-[#FFFFFF] rounded-md overflow-hidden border border-default-gray-200 shadow-sm">
          <h2 className="text-[#1F2937] text-[14px] font-medium leading-[16px] tracking-[-0.4px] font-poppins mt-3 mb-1 ml-3">
            Employee Distribution by Department
          </h2>
          <div className="pl-4 pr-4 overflow-x-auto h-[85%] w-full">
            <BarChart
              chartData={employeeDepartmentOverviewData}
              labels={departmentOverviewLabels}
              options={departmentOverviewOptions}
              barThickness={departmentData?.length > 8 ? 20 : 35}
            />
          </div>
        </div>
      </div>

      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* New Joinees */}
          <div className="bg-white rounded-md border border-default-gray-200 shadow-sm p-4">
            <h2 className="font-poppins text-[14px] font-medium text-[#1F2937] items-center justify-between mb-3">
              New Joinees
            </h2>

            {/* Table content */}
            <div className="table_main_content w-full max-h-[calc(100vh-25rem)] relative overflow-auto bg-white scrollbar-hide">
              <DynamicTable<NewJoinees>
                data={tableData}
                loading={false}
                columns={columns}
                enableSorting
              />
            </div>
          </div>
          {/* Notice Period */}
          <div className="bg-white rounded-md border border-default-gray-200 shadow-sm p-4">
            <h2 className="font-poppins text-[14px] font-medium text-[#1F2937] items-center justify-between mb-3">
              On Notice Period
            </h2>
            <div className="overflow-x-auto scrollbar-hide table_main_content w-full max-h-[calc(100vh-25rem)] relative bg-white">
              <DynamicTable<Notice_Period>
                data={tableData1}
                loading={false}
                columns={columns1}
                enableSorting
              />
            </div>
          </div>

          {/* Upcoming Birthdays */}
          <div className="bg-white rounded-md border border-default-gray-200 shadow-sm p-4">
            <h2 className="font-poppins text-[14px] font-medium text-[#1F2937] items-center justify-between mb-3">
              Upcoming Birthdays
            </h2>

            {/* Table content */}
            <div className="table_main_content w-full max-h-[calc(100vh-25rem)] relative overflow-auto bg-white scrollbar-hide">
              <DynamicTable<Upcoming_Birthdays>
                data={tableData2}
                loading={false}
                columns={columns2}
                enableSorting
                enablePagination={false}
              />
              {hasMore && (
                <div
                  ref={birthdayRef}
                  className="text-center p-4 text-sm text-gray-500"
                >
                  Loading more...
                </div>
              )}
            </div>
          </div>

          {/* Upcoming Work Anniversaries */}
          <div className="bg-white rounded-md border border-default-gray-200 shadow-sm p-4">
            <h2 className="font-poppins text-[14px] font-medium text-[#1F2937] items-center justify-between mb-3">
              Upcoming Work Anniversaries
            </h2>
            <div className="table_main_content w-full max-h-[calc(100vh-25rem)] relative overflow-auto bg-white scrollbar-hide">
              <DynamicTable<Anniversaries>
                data={tableData3}
                loading={false}
                columns={columns3}
                enableSorting
                enablePagination={false}
              />
              {hasMore2 && (
                <div
                  ref={anniversaryRef}
                  className="text-center p-4 text-sm text-gray-500"
                >
                  Loading more...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
