import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fetch_Employee_List_List_Management } from "../../../redux/actions/Employee/Employee";
import profile from "../../../assets/Ellipse 580.png";
import cross from "../../../assets/cross_new.svg";

interface IUser {
  id: number;
  employee_code: string;
  employee_id: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string | null;
  Department: { id: number; name: string } | null;
  Designation: { id: number; name: string } | null;
}

interface IData {
  id: number;
  employee_code: string;
  employee_id: string;
  user_id: number;
  employee_type: string;
  customer_id: number;
  User: IUser;
}

interface EmployeeSearchProps {
  onEmployeeSelect: (employeeIds: string[]) => void;
  onEmployeeSelectId: (user_Ids: string[]) => void;
  projectId: string;
  selectedResources: number[];
}

const ResourceSearch: React.FC<EmployeeSearchProps> = ({
  projectId,
  onEmployeeSelect,
  onEmployeeSelectId,
  selectedResources,
}) => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const employeeList = useSelector((state: any) => state.emplist?.EmployeeData);
  const [employee, setEmployee] = useState<IUser[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<IData[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  let customer_id = loginData?.customer_id || 0;

  useEffect(() => {
    if (isDropdownOpen) {
      setEmployee(
        [...employeeList].sort((a, b) => a.firstName.localeCompare(b.firstName))
      );
    }
  }, [isDropdownOpen, employeeList]);

  useEffect(() => {
    dispatch<any>(
      Fetch_Employee_List_List_Management(customer_id, 100, 1, searchQuery)
    );
  }, [searchQuery, dispatch, customer_id]);

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setIsDropdownOpen(true);
  };

  const filteredEmployees = employee.filter(
    (emp) =>
      emp?.id?.toString().includes(searchQuery.toLowerCase()) ||
      emp?.firstName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleEmployeeSelection = (employee: IUser) => {
    if (selectedResources.includes(employee.id)) return;
    const isSelected = selectedEmployees.some(
      (emp) => emp.User.id === employee.id
    );
  
    if (isSelected) {
      const updatedSelectedEmployees = selectedEmployees.filter(
        (emp) => emp.User.id !== employee.id
      );
      setSelectedEmployees(updatedSelectedEmployees);
      onEmployeeSelect(
        updatedSelectedEmployees.map((emp) => emp.User.id.toString())
      );
      onEmployeeSelectId(
        updatedSelectedEmployees.map((emp) => emp.user_id.toString())
      );
    } else {
      const newEmployee: IData = {
        id: employee.id,
        employee_code: employee.employee_code,
        employee_id: employee.employee_id,
        user_id: employee.id,
        employee_type: "Employee",
        customer_id,
        User: employee,
      };
  
      const updatedSelectedEmployees = [...selectedEmployees, newEmployee];
      setSelectedEmployees(updatedSelectedEmployees);
      onEmployeeSelect(
        updatedSelectedEmployees.map((emp) => emp.User.id.toString())
      );
      onEmployeeSelectId(
        updatedSelectedEmployees.map((emp) => emp.user_id.toString())
      );
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full">
      <TextField
        autoComplete="off"
        fullWidth
        required
        id="outlined-required"
        label="Employee Name & ID"
        placeholder="Search with Employee name & ID"
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        value={searchQuery}
      />
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute mt-2 w-full p-4 bg-white shadow-lg rounded-lg max-h-52 overflow-y-auto z-10"
        >
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee) => (
              <div
                key={employee.id}
                className="flex items-center p-2 mb-2 bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg"
                onClick={() => toggleEmployeeSelection(employee)}
              >
                <input
                  type="checkbox"
                  checked={selectedEmployees.some(
                    (emp) => emp.User.id === employee.id
                  )|| selectedResources.includes(employee.id)}
                  disabled={selectedResources.includes(employee.id)}
                  onChange={() => toggleEmployeeSelection(employee)}
                  className="mr-2"
                />
                <div className="flex items-center gap-2.5">
                  <div className="w-[40px] rounded-full">
                    <img
                      src={employee.profile_image || profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-medium text-sm">
                      {employee.firstName} {employee.lastName}
                    </span>
                    <span className="text-xs text-gray-600">{employee.id}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-600">No results found</div>
          )}
        </div>
      )}
      <div className="max-h-[60vh] overflow-auto mt-2">
        {selectedEmployees.map((employee) => (
          <div
            key={employee.id}
            className="flex items-center justify-between p-2 mb-2 bg-gray-50 rounded-md shadow-sm"
          >
            <div className="flex items-center gap-2">
              <img
                src={employee.User.profile_image || profile}
                alt="profile"
                className="w-10 h-10 rounded-full"
              />
              <div>
                <p className="text-sm font-medium">
                  {employee.User.firstName} {employee.User.lastName}
                </p>
                <p className="text-xs text-gray-500">{employee.User.id}</p>
              </div>
            </div>
            <img
              src={cross}
              alt="remove"
              className="w-5 h-5 cursor-pointer"
              onClick={() => toggleEmployeeSelection(employee.User)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourceSearch;
