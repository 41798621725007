import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Filters {
  search?: string;
  department?: string | null;
  group?: string | null;
  date?: [Date | null, Date | null];
  status?: string;
  page?: number;
  size?: number;
}

const initialState: Filters = {
  search: "",
  department: null,
  group: null,
  status: "",
  date: [null, null],
  page: 1,
  size: 10,
};

const ExpenseFilter = createSlice({
  name: "filterPersist",
  initialState,
  reducers: {
    setFilterExpenseData(state, action: PayloadAction<Filters>) {
      return { ...state, ...action.payload };
    },
    clearExpenseFilters(state) {
      return initialState;
    },
  },
});

export const { setFilterExpenseData, clearExpenseFilters } =
  ExpenseFilter.actions;
export default ExpenseFilter;
