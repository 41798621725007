import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartDataItem {
  label: string;
  data: number[];
  color: string;
}

interface LineChartProps {
  chartData: ChartDataItem[];
  labels: string[];
  title?: string;
  options?: any;
  lineWidth?: number;
}

const LineChart: React.FC<LineChartProps> = ({
  chartData,
  labels,
  title,
  options,
  lineWidth = 2,
}) => {
  const data = {
    labels,
    datasets: chartData.map((item) => ({
      label: item.label,
      data: item.data,
      borderColor: item.color,
      backgroundColor: `${item.color}`,
      borderWidth: lineWidth,
      pointRadius: 3,
      pointHoverRadius: 5,
      fill: true,
    })),
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
          boxHeight: 15
        },
      },
      title: {
        display: !!title,
        text: title,
        font: { size: 16 },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 10,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        ticks: { beginAtZero: true },
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <Line data={data} options={options || defaultOptions} />
    </div>
  );
};

export default LineChart;
