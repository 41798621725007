interface User {
  id: number;
  employee_code: string;
  employee_id: string;
  firstName: string;
  lastName: string;
  email: string;
  profile_image: string | null;
  department_id: number;
  designation_id: number;
  status: string;
  Department: Department;
  Designation: Designation;
}

interface Department {
  id: number;
  name: string;
}

interface Designation {
  id: number;
  name: string;
}

const TeamCard = ({ user }: { user: User }) => {
  const initials = `${user?.firstName?.[0] ?? ""}${
    user?.lastName?.[0] ?? ""
  }`?.toUpperCase();

  return (
    <div className="relative flex flex-col items-center pt-7">
      <div className="absolute -top-0">
        {user?.profile_image ? (
          <img
            src={user.profile_image}
            alt="Profile"
            className="w-10 h-10 rounded-full border-2 border-[#D1D5DB] shadow object-cover"
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-[#BDBDBD] flex items-center justify-center text-sm font-semibold text-white border-2 border-[#D1D5DB] shadow">
            {initials}
          </div>
        )}
      </div>

      <div className="cursor-pointer pt-3.5 min-w-[188px] px-1 min-h-[80px] rounded-lg border-2 border-[#D1D5DB] bg-white flex flex-col items-center gap-1.5 shadow-sm hover:shadow-md transition-shadow duration-300">
        <div className="text-[#1F2937] text-center font-poppins text-xs font-semibold leading-[15px]">
          {user?.firstName} {user?.lastName}
          <span className="text-[#4B5563] text-[10px] font-normal">
            {" "}
            ({user?.employee_id})
          </span>
        </div>
        <div className="text-[#1F2937] text-center font-poppins text-[10px] font-medium leading-none">
          {user?.Designation?.name}
        </div>
        <div className="text-[#374151] text-center font-poppins text-[10px] font-normal leading-none">
          {user?.Department?.name}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
