import { ChangeEvent, useCallback, useEffect, useState } from "react";
import TablePaginationDemo from "../../../common/Pagenation";
import DynamicTable from "../../../common/DynamicTable";
import Search2 from "../../../SearchBar/Search2";
import {
  calculateVariance,
  convertHoursIntoHoursMinutes,
} from "../../../../utils/helperData";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import profile from "../../../../assets/Ellipse 580.png";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import { debounce } from "lodash";
import { Fetch_Resources_List } from "../../../common/services/Project";
import { useSelector } from "react-redux";
import ModalResources from "../../Modal";

interface IUser {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
  Department: {
    name: string;
  };
  Designation: {
    name: string;
  };
}

interface RowData {
  id: number;
  user_id: number;
  project_id: number;
  employee_type: string | null;
  customer_id: number;
  group_id: number | null;
  billing_cost: number;
  actual_cost: number | null;
  User: IUser;
  estimated_hours: number | null;
  actual_hours: number | null;
  currency: string | null;
}

const Resources = () => {
  const navigate = useNavigate();
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );
  const [currency, setCurrency] = useState<string>("");
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [tableDataCount, setTableDataCount] = useState<number>(0);

  const handleEditClick = (id: string, currency: string) => {
    setSelectedEmployeeId(id);
    setIsModalOpen(true);
    setCurrency(currency || "");
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    [setSearchTerm]
  );

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const onClose = () => {
    setIsModalOpen(false);
    fetchResourcesList();
  };

  const fetchResourcesList = useCallback(async () => {
    try {
      const response = await Fetch_Resources_List(
        projectId,
        searchTerm,
        numOfItems,
        page
      );

      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setTableDataCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [
    projectId,
    searchTerm,
    numOfItems,
    page,
  ]);

  useEffect(() => {
    fetchResourcesList();
  }, [fetchResourcesList]);

  const formatCurrency = (value: number, currency: string) => {
    const locales: Record<string, string> = {
      INR: "en-IN",
      USD: "en-US",
      EUR: "de-DE",
      GBP: "en-GB",
      AED: "en-AE",
      JPY: "ja-JP",
      CNY: "zh-CN",
      AUD: "en-AU",
      CAD: "en-CA",
    };
  
    return new Intl.NumberFormat(locales[currency] || "en-US").format(value);
  }; 

  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: "name",
      accessorFn: (row) => `${row.User?.firstName} ${row.User?.lastName}`,
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Resources
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const data = row?.original;
        return (
          <div className="flex gap-2 items-center ml-[-18px]">
            <div className="flex h-full items-center">
              <div
                className={`h-8 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] flex items-center justify-center ${
                  data?.employee_type === "Manager"
                    ? "bg-[#FEE2E2]"
                    : "bg-[#EAE0FF]"
                }`}
              >
                <span className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]">
                  {data?.employee_type === "Manager" ? (
                    <span>M</span>
                  ) : (
                    <span>E</span>
                  )}
                </span>
              </div>
            </div>
            <img
              className="w-8 h-8 rounded-full ml-3"
              src={data?.User?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span
                onClick={() => {
                  navigate("/employee-list-details", {
                    state: { id: data?.user_id },
                  });
                }}
                className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]"
              >
                {data?.User?.firstName} {data?.User?.lastName} (
                {data?.User?.Designation?.name || "--"})
              </span>
              <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                {data?.User?.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="py-3">{name}</div>;
      },
    },
    {
      accessorKey: "billing_cost",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Billable Cost/hr
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.billing_cost ?? "-", // Null if billing_cost is missing
      cell: ({ row }) => {
        const billingCost = row?.original?.billing_cost;
        const currency: string = row?.original?.currency ?? "";

        // Agar billingCost null, undefined, ya 0 ho to sirf "-"
        if (
          billingCost === null ||
          billingCost === undefined ||
          billingCost === 0
        ) {
          return <div>-</div>;
        }

        return (
          <div>
            {currency} {formatCurrency(billingCost, currency)}
          </div>
        );
      },
    },
    // {
    //   accessorKey: "billing_cost",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       className="p-0"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //     >
    //       Billable Cost/hr
    //       <ArrowUpDown className="ml-2 size-4" />
    //     </Button>
    //   ),
    //   accessorFn: (row) => row?.billing_cost ?? "-",
    //   cell: ({ row }) => {
    //     const name: number = row?.original?.billing_cost ?? "-";
    //     const currency: string = row?.original?.currency ?? "";

    //     return (
    //       <div className="">
    //         {currency} {name}
    //       </div>
    //     );
    //   },
    // },

    {
      accessorKey: "estimated_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Allocated hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.estimated_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.estimated_hours
              ? // ? `${row?.original?.estimated_hours} hrs`
                convertHoursIntoHoursMinutes(
                  row?.original?.estimated_hours ?? 0
                )
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "actual_hours",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Filled hrs
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.actual_hours ?? "-",
      cell: ({ row }) => {
        return (
          <div className="">
            {row?.original?.actual_hours
              ? // ? `${row?.original?.actual_hours} hrs`
                convertHoursIntoHoursMinutes(row?.original?.actual_hours ?? 0)
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "variance",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Variance
        </Button>
      ),
      // accessorFn: (row) =>
      //   row?.status
      //     ? getPolicyStatus[row.status as keyof typeof getPolicyStatus]
      //     : "-",
      cell: ({ row }) => {
        const data = row?.original;
        let diff;
        if (data?.actual_hours && data?.estimated_hours) {
          // diff = data?.estimated_hours - data?.actual_hours;.
          diff = calculateVariance(data?.estimated_hours, data?.actual_hours);
        }

        return <div className="">{diff ? diff : "-"}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0">
          Action
        </Button>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                handleEditClick(
                  String(row?.original?.id),
                  row?.original?.currency ? String(row?.original?.currency) : ""
                )
              }
            >
              <img
                src={edit}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex justify-between mt-4">
        <Search2
          onChange={updateSearchTerm}
          label="Search with resource name"
        />
      </div>
      <div className="overflow-x-auto mt-5">
        <div className="table_main_content w-full relative overflow-auto bg-white ">
          <DynamicTable<RowData>
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>

        {tableDataCount >= 10 && (
          <TablePaginationDemo
            count={tableDataCount}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        )}
        {selectedEmployeeId && (
          <ModalResources
            isOpen={isModalOpen}
            onClose={onClose}
            employeeId={selectedEmployeeId}
            fetchData={fetchResourcesList}
            currency={currency}
          />
        )}
      </div>
    </div>
  );
};

export default Resources;
