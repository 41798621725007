import { useCallback, useEffect, useState } from "react";
import TablePaginationDemo from "../../common/Pagenation";
import * as XLSX from "xlsx";
import { ColumnDef } from "@tanstack/react-table";

import { Button } from "../../common/Button";
import { Fetch_Wfh_Balance_List } from "../../common/services/Leave";
import { ArrowUpDown, Download } from "lucide-react";
import TableSkeleton from "../../common/TableSkeleton";
import DynamicTable from "../../common/DynamicTable";
import PersistedSearch from "../../EmployeeManagement/EmployeeListSearch";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import UserBadge from "../../common/UserBadge";
import AttFilter, { FilterData } from "../../Filter/AttFilter";
import { useDispatch } from "react-redux";
import { setFilterPersistData } from "../../../redux/reducers/FilterPersist/FilterPersistSlice";
import { Close } from "@mui/icons-material";
import filter from "../../../assets/filter.svg";

interface UserWfhBalance {
  // id: number;
  allotted_wfh: number;
  // allotted_year: number;
  used_wfh: number;
  // exceeded_weeks: any[];
  total_exceeded_days: number;
}

interface IWFHBalance {
  id: number;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
  UserWfhBalances: UserWfhBalance[];
}


function WFHBalance() {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  // const [searchTerm, setSearchTerm] = useState("");
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<IWFHBalance[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleDelete = () => {
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        date: [null, null],
      })
    );
    setPage(1);
  };

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        date: filterData?.dateRange,
      })
    );
    setDates(filterData?.dateRange);
    setPage(1);
    setIsOpen(false);
  };

  // const updateSearchTerm = useCallback(
  //   debounce((event: any) => {
  //       setSearchTerm(event.target.value);
  //   }, 1000), // delay in ms
  //   [setSearchTerm]
  // );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const handleDownload = () => {
    if (tableData?.length > 0) {
      // Define the column headers
      const columns = [
        "Employee Name",
        "Employee ID",
        "Total WFH / Week",
        "Taken WFH",
        "Exceeded WFH",
      ];

      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...tableData?.map((row) => [
          `${row?.firstName} ${row?.lastName}`,
          row?.employee_id,
          row?.UserWfhBalances?.[0]?.allotted_wfh,
          row?.UserWfhBalances?.[0]?.used_wfh,
          row?.UserWfhBalances?.[0]?.total_exceeded_days
        ]),
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leave Balance Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "Leave_Balance_Report.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Wfh_Balance_List(
        numOfItems,
        page,
        searchTerm ?? "",
        dates ? dates[0] : null,
        dates ? dates[1] : null
      );
      // console.log("Response Data:-------------------------------->", response);  // Add this line to inspect the data
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  }, [page, numOfItems, searchTerm, dates]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // download button

  const columns: ColumnDef<IWFHBalance>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item} user_id={item?.id} />;
      },
    },
    {
      accessorKey: "allotted_wfh",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Total WFH / Week
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.UserWfhBalances?.[0]?.allotted_wfh ?? "-",
      cell: ({ row }) => {
        const name: number =
          row?.original?.UserWfhBalances?.[0]?.allotted_wfh ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "used_wfh",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Taken WFH
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.UserWfhBalances?.[0]?.used_wfh ?? "-",
      cell: ({ row }) => {
        const name: number =
          row?.original?.UserWfhBalances?.[0]?.used_wfh ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "total_exceeded_days",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Exceeded WFH
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => {
        return row?.UserWfhBalances?.[0]?.total_exceeded_days ?? 0;
      },
      cell: ({ row }) => {
        const exceeded =
          row?.original?.UserWfhBalances?.[0]?.total_exceeded_days ?? 0;

        return <div>{exceeded}</div>;
      },
    },
    // {
    //   id: "actions",
    //   header: ({ column }) => (
    //     <div className="flex justify-end mr-2">
    //       <Button variant="ghost" className="p-0">
    //         Action
    //       </Button>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div className="flex items-center justify-end gap-2">
    //         <Button
    //           type="button"
    //           className="p-0"
    //           // onClick={() =>
    //           //   navigate("/view-wfh-balance", {
    //           //     state: { id: row?.original?.id },
    //           //   })
    //           // }
    //         >
    //           <img
    //             src={eye}
    //             alt="View"
    //             className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
    //           />
    //         </Button>
    //         <Button
    //           type="button"
    //           className="p-0"
    //           // onClick={() =>
    //           //   navigate("/edit-wfh-balance", {
    //           //     state: { id: row?.original?.id },
    //           //   })
    //           // }
    //         >
    //           <img
    //             src={edit}
    //             alt="Edit"
    //             className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
    //           />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className="mt-6 flex justify-between">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            {/* <Search
              onChange={updateSearchTerm}
              label="Search with Employee Name"
            /> */}
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {dates && !(dates[0] === null && dates[1] === null) ? (
                  <button
                    onClick={handleDelete}
                    className="text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<IWFHBalance>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="WFH Balance Filter"
        onApply={handleApply}
        isDate={true}
        isOther={false}
      />
    </>
  );
}

export default WFHBalance;
