import React, { useEffect, useState, ChangeEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import veiw from "../../assets/Crud_Icons/view.svg";
import delete001 from "../../assets/Crud_Icons/delete.svg";
import eye from "../../assets/Crud_Icons/view.svg";
import del from "../../assets/Crud_Icons/delete.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import check from "../../image/checkmark-circle-02.svg";
import close from "../../assets/close.svg";
import TablePaginationDemo from "../common/Pagenation";
import { TextField, Box, Typography, Modal } from "@mui/material";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import Search from "../SearchBar/Search";
import {
  Fetch_Policy_Category_List,
  fetchEditPolicyCategory,
  updatePolicyCategory,
} from "../common/services/companyManagement";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import DynamicTable from "../common/DynamicTable";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { IPolicyCategoryManagement } from "../common/interfaces/CompanyManagement";
import TableSkeleton from "../common/TableSkeleton";

const style1 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "791px",
  height: "465px",
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  zIndex: "1001",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "548px",
  height: "352.6px",
  bgcolor: "#ffffff",
  boxShadow: 24,
  borderRadius: "8px",
  padding: "30px",
  zIndex: "1001",
};

const overlayStyle = {
  position: "fixed" as "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
};

// interface RowData {
//   name: string;
//   description: string;
//   id: string;
// }

interface PolicyCategoriesProps {
  shouldRefresh: boolean;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancelDelete: () => void;
}> = ({ onDelete, onCancelDelete }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Policy Category
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this!
        </h4>
        <div className="flex space-x-4 justify-center">
          <Button onClick={onDelete} variant={"gradient"} className="mr-2">
            Yes Sure
          </Button>
          <button
            onClick={onCancelDelete}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
          >
 <div 
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Cancel
            </div>
                      </button>
        </div>
      </div>
    </div>
  );
};

const ViewPolicyModal: React.FC<{
  onQuit: () => void;
  policyCategoryDetails: { name: string; description: string; id: string };
}> = ({ onQuit, policyCategoryDetails }) => {
  return (
    <>
      <div style={overlayStyle} onClick={onQuit}></div>
      <Box sx={style2}>
        <div className="flex flex-col gap-5 w-full">
          <div className="w-full h-4 flex justify-end">
            <img
              src={close}
              alt="close"
              onClick={onQuit}
              className="cursor-pointer text-[#605D66]"
            />
          </div>
          <div className="w-full h-6 px-[10px]">
            <span className="font-['Inter'] font-semibold text-[20px] leading-[24.2px] text-[#000000]">
              Policy Category Details
            </span>
          </div>
          <div
            className="h-[213px] border rounded-md px-2.5 py-5 bg-gradient-to-r"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="flex flex-col gap-[15px]">
              <div className="px-2.5 flex flex-col gap-[7px] h-[45px]">
                <span className="h-[19px] font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#99A1B7]">
                  Policy Category
                </span>
                <span className="h-[19px] font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                  {policyCategoryDetails?.name}
                </span>
              </div>
              <hr />
              <div className="pl-2.5 pr-1.5">
                <div className="flex flex-col gap-[7px] h-[98px]">
                  <span className="h-[19px] font-['Inter'] font-medium text-[16px] leading-[19.36px] text-[#99A1B7]">
                    Description
                  </span>
                  <span className="h-[19px] font-['Inter'] font-medium text-base text-[#322F37]">
                    {policyCategoryDetails?.description}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const EditPolicyCategoryModal: React.FC<{
  onUpdate: (policyCategory: string, description: string) => void;
  onCancel: () => void;
  policyCategoryDetails: { name: string; description: string; id: string };
}> = ({ onUpdate, onCancel, policyCategoryDetails }) => {
  const [policyCategory, setPolicyCategory] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (policyCategoryDetails) {
      setPolicyCategory(policyCategoryDetails.name);
      setDescription(policyCategoryDetails.description);
    }
  }, [policyCategoryDetails]);

  const handleSave = () => {
    onUpdate(policyCategory, description);
  };
  return (
    <>
      <div style={overlayStyle} onClick={onCancel}></div>
      <Box sx={style1}>
        <div className="px-[35px] py-[50px]">
          <span className="font-['Poppins'] font-medium text-[20px] leading-4 text-[#262626]">
            Edit Policy Category Details
          </span>
          <div className="pt-10 w-full h-[53px] flex flex-col gap-[29px]">
            <div className="w-full h-full">
              <TextField
                required
                id="outlined-required"
                label="Policy Category"
                value={policyCategory}
                onChange={(e) => setPolicyCategory(e.target.value)}
                name="policy_category"
                className="w-[344px] font-[Poppins] font-meduim text-[14px] leading-[21] text-[#49454F]"
              ></TextField>
            </div>
            <div className="w-full h-[117px]">
              <TextField
                required
                id="outlined-required"
                label="Description"
                // placeholder="Enter Declaration"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="description"
                sx={{
                  width: "100%",
                  marginTop: "50px",
                  "& .MuiInputBase-root": {
                    height: "117px",
                    alignItems: "flex-start",
                    textWrap: "wrap",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "117px",
                    "& input": {
                      padding: "10px 14px",
                      textWrap: "wrap",
                    },
                  },
                }}
              ></TextField>
            </div>
            <div className="w-full flex flex-row justify-end gap-5 mt-[79px]">
              <button
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
                onClick={onCancel}
              >
                  <div 
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Cancel
            </div>
              </button>
              <Button
                type="submit"
                form="form"
                onClick={handleSave}
                variant={"gradient"}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export const PolicyCategories: React.FC<PolicyCategoriesProps> = ({
  shouldRefresh,
  setShouldRefresh,
}) => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IPolicyCategoryManagement[]>([]);
  const [selectedItem, setSelectedItem] =
    useState<IPolicyCategoryManagement | null>(null);
  const [editItemId, setEditItemId] = useState("");

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_Category_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
      );
      if (response.status === 200) {
        setTableData(response.data?.result);
        setCount(response.data?.result?.count);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, page, numOfItems, startSession, endSession]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchData();
      setShouldRefresh(false);
    }
  }, [shouldRefresh, setShouldRefresh]);

  const [open, setOpen] = React.useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [editItemDetails, setEditItemDetails] = useState<any>(null);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const handledelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/policy-category/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
        }
      } catch (error) {
      } finally {
        //setShowModal(false);
        setShowDeleteModal(false);
        setSelectedItem(null);
        fetchData();
      }
    }
    setShowDeleteModal(false);
  };

  const handleEditClick = async (id: string) => {
    try {
      const data = await fetchEditPolicyCategory(id);
      if (data.result) {
        const resultArray = Array.isArray(data.result)
          ? data.result
          : [data.result];
        const formattedRows = resultArray.map((item: any) => ({
          name: item.name,
          description: item.description,
          id: item.id,
        }));
        setEditItemDetails(formattedRows[0]);
      }
    } catch (error) {}
    setEditItemId(id);
    setShowEditModal(true);
  };
  const handleDeleteClick = (item: IPolicyCategoryManagement) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/policy-categories");
  };

  const handleView = async (id: any) => {
    try {
      const data = await fetchEditPolicyCategory(id);
      if (data.result) {
        const resultArray = Array.isArray(data.result)
          ? data.result
          : [data.result];
        const formattedRows = resultArray.map((item: any) => ({
          name: item.name,
          description: item.description,
          id: item.id,
        }));
        setEditItemDetails(formattedRows[0]);
      }
    } catch (error) {}
    setShowViewModal(true);
  };

  const handleUpdate = async (policyCategory: string, description: string) => {
    try {
      const response = await updatePolicyCategory(
        Number(editItemId),
        policyCategory,
        description
      );

      setTimeout(() => {
        // setOpen(false);
        setShowEditModal(false);
        setOpen(true);
        navigate("/policy-categories");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    // setOpen(true);
    // setShowEditModal(false);
  };

  // const [count, setCount] = useState(0);
  // const [numOfItems, setNumOfItems] = useState(10);
  // const [page, setPage] = useState(1);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const columns: ColumnDef<IPolicyCategoryManagement>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Policy Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "description",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Category
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.description ?? "-",
      cell: ({ row }) => {
        const category: string = row?.original?.description ?? "-";
        return <div className="">{category}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex items-center justify-end mr-6">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2 justify-end">
            <Button
              type="button"
              className="p-0"
              onClick={() => handleView(row?.original?.id)}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() => handleEditClick(row?.original?.id)}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() => handleDeleteClick(row?.original)}
              // onClick={() => handleDeleteClick(String(row?.original?.id) ?? "")}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="pt-3 w-full">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Policy Category Edited Successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="flex flex-col gap-[10px]">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with policy category"
            />
          </div>
        </div>
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white ">
            <DynamicTable<IPolicyCategoryManagement>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
        <div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={page}
              numOfItems={numOfItems}
            />
          )}
        </div>
      </div>

      {showEditModal && (
        <EditPolicyCategoryModal
          // policyCategory={policyCategory}
          // description={description}
          onUpdate={handleUpdate}
          onCancel={() => setShowEditModal(false)}
          policyCategoryDetails={editItemDetails}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          onDelete={handledelete}
          onCancelDelete={() => setShowDeleteModal(false)}
        />
      )}
      {showViewModal && (
        <ViewPolicyModal
          onQuit={() => setShowViewModal(false)}
          policyCategoryDetails={editItemDetails}
        />
      )}
    </div>
  );
};
