import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { ClassNames } from "@emotion/react";
import {
  Fetch_Loss_Making_Projects,
  Fetch_Profitable_Projects,
  Fetch_Projects_Chart_Data,
} from "../common/services/Project";
import toast from "react-hot-toast";
import DoughnutChart, {
  centerTextPlugin,
} from "../common/Charts/DoughnutChart";
import {
  Fetch_Employee_Count,
  Fetch_Employee_Department,
} from "../common/services/Employee";
import { RootState } from "../../redux/store";
import {
  Fetch_Expense_Type_Chart,
  Fetch_Expense_Type_Graph,
} from "../common/services/EmployeeExpense";
import BarChart from "../common/Charts/BarChart";
import LineChart from "../common/Charts/LineChart";
import { TextField, MenuItem } from "@mui/material";
import {
  Fetch_Attendance_Employee_Overview,
  Fetch_Financial_year,
} from "../common/services/Dashboard";
import BusinessUnitSelect from "../common/shared/BusinessUnitSelect";
import StackedBarChart from "../common/Charts/StackedBarChart";
interface IProjectStatus {
  status: string;
  count: string | number;
}

interface IEmployeeData {
  total_users: string;
  active_users: string;
  inactive_users: string;
  users_on_notice: string;
}

interface ExpenseItem {
  category_id: number;
  total_amount: string;
  expense_count: string;
  ExpenseCategory: ExpenseCategory | null;
}

interface ExpenseCategory {
  expense_category_name: string;
}
interface Employee_Department {
  full_time: number;
  part_time: number;
  internship: number;
  contract: number;
  Department: IDepartment;
}

interface IDepartment {
  id: number;
  name: string;
}

interface DepartmentOverviewData {
  label: string;
  data: number[];
  color: string;
}

interface AttendanceOverviewData {
  WFO: number;
  WFH: number;
  OnLeave: number;
}

interface IClientStatus {
  status: string;
  count: string | number;
}

interface IFinancialYear {
  month: number;
  year: number;
  monthName: string;
  totalProfit: number;
  totalLoss: number;
}

const DashboardAdmin = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const signupData = useSelector((state: any) => state.signup.details);
  const [set, setSet] = useState("week");
  const [financialYear, setFinancialYear] = useState("year");

  let customer_id = 0;
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [clientStatusOverview, setClientStatusOverview] = useState<
    IClientStatus[]
  >([]);
  const formatSession = (
    startDate: string | null,
    endDate: string | null
  ): string => {
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);

      const currentFYStart =
        dayjs().month() >= 3
          ? dayjs().startOf("year").month(3)
          : dayjs().subtract(1, "year").startOf("year").month(3);

      const currentFYEnd = currentFYStart.add(1, "year").subtract(1, "day");

      if (
        start.isSame(currentFYStart, "day") &&
        end.isSame(currentFYEnd, "day")
      ) {
        return "Current Financial Year";
      } else {
        const startYear = start.year();
        const endYear = end.year();
        return `${startYear}-${endYear.toString().slice(-2)}`;
      }
    }

    return "Current Financial Year";
  };
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");
  const session = formatSession(startSession, endSession);
  const [lossMakingAmount, setLossMakingAmount] = useState<number>(0);
  const [projectCount, setProjectCount] = useState<number>(0);
  const [onTimeCount, setOnTimeCount] = useState<number>(0);
  const [delayedCount, setDelayedCount] = useState<number>(0);
  const [clientCount, setClientCount] = useState<number>(0);
  const [profitablePage, setProfitablePage] = useState<number>(1);
  const [profitableListCount, setProfitableListCount] = useState<number>(0);
  const [profitableAmount, setProfitableAmount] = useState<number>(0);
  const numOfItems = 10;
  const [attendanceOverview, setAttendanceOverview] = useState<
    AttendanceOverviewData[]
  >([]);
  const [earnings, setEarnings] = useState<IFinancialYear[]>([]);
  const [projectStatusOverview, setProjectStatusOverview] = useState<
    IProjectStatus[]
  >([]);
  const [countList, setCountList] = useState<IEmployeeData>();
  const [expensechartData, setExpenseChartData] = useState<ExpenseItem[]>([]);
  const [departmentData, setdepartmentData] = useState<Employee_Department[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = React.useState<number[]>(
    []
  );
  const fetchEmployeeCount = useCallback(async () => {
    try {
      const response = await Fetch_Employee_Count({
        groupIds: selectedBusinesses,
      });
      if (response.status === 200) {
        setCountList(response.data?.result);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [selectedBusinesses]);

  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchEmployeeCount();
    }
  }, [fetchEmployeeCount]);

  const fetchProjectChartData = useCallback(async () => {
    try {
      const response = await Fetch_Projects_Chart_Data(
        customer_id,
        selectedBusinesses
      );
      if (response.status === 200) {
        setProjectStatusOverview(
          response?.data?.result?.projectStatusOverview ?? {}
        );
        // setProjectCount(
        //   response?.data?.result?.projectProgressStatusOverview
        //     ?.map((item: any) => Number(item.count))
        //     ?.reduce((sum: number, count: number) => sum + count, 0)
        // );
        setClientCount(
          response?.data?.result?.clientStatusOverview
            ?.map((item: any) => Number(item.count))
            ?.reduce((sum: number, count: number) => sum + count, 0)
        );
        setClientStatusOverview(
          response?.data?.result?.clientStatusOverview ?? {}
        );

        const projectStatusOverview =
          response?.data?.result?.projectProgressStatusOverview ?? [];
        const totalProjects = projectStatusOverview
          .map((item: any) => Number(item.count))
          .reduce((sum: number, count: number) => sum + count, 0);

        const onTimeProjects =
          projectStatusOverview.find(
            (item: any) => item.project_status === "On time"
          )?.count ?? 0;

        const delayedProjects =
          projectStatusOverview.find(
            (item: any) => item.project_status === "Delayed"
          )?.count ?? 0;

        // Set values in state
        setProjectCount(totalProjects);
        setOnTimeCount(Number(onTimeProjects));
        setDelayedCount(Number(delayedProjects));
      } else {
        console.error("Error:", response.status, response.statusText);
        toast.error(response?.data?.result?.error);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching data");
    }
  }, [customer_id, selectedBusinesses]);
  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchProjectChartData();
    }
  }, [fetchProjectChartData]);

  const fetchProfitableProjects = useCallback(async () => {
    try {
      const response = await Fetch_Profitable_Projects(
        customer_id,
        profitablePage,
        numOfItems,
        selectedBusinesses
      );
      if (response.status === 200) {
        const newProjects = response?.data?.result?.profitableProjects ?? [];
        const profitableProjectTotal =
          response?.data?.result?.summary?.totalProfitableProjects ?? 0;
        setProfitableListCount(profitableProjectTotal);
        setProfitableAmount(
          response?.data?.result?.summary?.totalProfitAmount ?? 0
        );
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching profitable projects.");
    }
  }, [customer_id, profitablePage, selectedBusinesses]);
  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchProfitableProjects();
    }
  }, [fetchProfitableProjects]);

  const fetchLossMakingProjects = useCallback(async () => {
    try {
      const response = await Fetch_Loss_Making_Projects(customer_id);
      if (response.status === 200) {
        const lossMakingProjectTotal =
          response?.data?.result?.summary?.totalLossProjects ?? 0;
        setLossMakingAmount(
          response?.data?.result?.summary?.totalLossAmount ?? 0
        );
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching loss maaking projects.");
    }
  }, [customer_id]);
  useEffect(() => {
    fetchLossMakingProjects();
  }, [fetchLossMakingProjects]);

  const fetchExpenseChart = useCallback(async () => {
    try {
      const response = await Fetch_Expense_Type_Chart({
        groupIds: selectedBusinesses,
      });
      if (response?.status === 200) {
        setExpenseChartData(
          response?.data?.result ? response?.data?.result : []
        );
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [selectedBusinesses]);

  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchExpenseChart();
    }
  }, [fetchExpenseChart]);

  const fetchDepartment = useCallback(async () => {
    setLoading(true);
    try {
      const employeeDepartmentData = await Fetch_Employee_Department({
        groupIds: selectedBusinesses,
      });

      if (employeeDepartmentData?.result) {
        const employee_departments: Employee_Department[] =
          employeeDepartmentData.result.map((item: any) => ({
            full_time: parseInt(item.full_time),
            part_time: parseInt(item.part_time),
            internship: parseInt(item.internship),
            contract: parseInt(item.contract),
            Department: {
              id: item.Department.id,
              name: item.Department.name,
            },
          }));
        setdepartmentData(employee_departments);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [selectedBusinesses]);

  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchDepartment();
    }
  }, [fetchDepartment]);

  const fetchAttendanceEmployee = useCallback(async () => {
    try {
      const response = await Fetch_Attendance_Employee_Overview(
        set,
        selectedBusinesses
      );
      if (response?.status === 200) {
        setAttendanceOverview(
          response?.data?.result ? response?.data?.result : []
        );
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [set, selectedBusinesses]);

  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchAttendanceEmployee();
    }
  }, [fetchAttendanceEmployee]);

  const fetchFinancialYear = useCallback(async () => {
    try {
      const response = await Fetch_Financial_year({
        set: financialYear,
        groupIds: selectedBusinesses,
      });
      if (response?.status === 200) {
        setEarnings(response?.data?.result ? response?.data?.result : []);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [selectedBusinesses]);

  useEffect(() => {
    if (selectedBusinesses?.length) {
      fetchFinancialYear();
    }
  }, [fetchFinancialYear]);

  const departmentOverviewLabels: string[] = departmentData?.length
    ? departmentData.map((item) => item?.Department?.name)
    : [];

  const employeeDepartmentOverviewData: DepartmentOverviewData[] = [
    {
      label: "Full Time",
      data: departmentData?.map((item) => Number(item.full_time) || 0),
      color: "#CDEBC5",
    },
    {
      label: "Part Time",
      data: departmentData?.map((item) => Number(item.part_time) || 0),
      color: "#8AB3FF",
    },
    {
      label: "Interns",
      data: departmentData?.map((item) => Number(item.internship) || 0),
      color: "#EDD9F2",
    },
    {
      label: "Contract",
      data: departmentData?.map((item) => Number(item.contract) || 0),
      color: "#FBD796",
    },
  ];

  const departmentOverviewOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "#1C2024",
          boxWidth: 15,
          boxHeight: 15,
          borderRadius: 4,
        },
      },
      tooltip: {
        enabled: false,
        external: function (context: any) {
          const { chart, tooltip } = context;

          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create the tooltip element if it doesn't exist
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "#fff";
            tooltipEl.style.border = "1px solid #E5E7EB";
            tooltipEl.style.padding = "10px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.borderRadius = "4px";
            tooltipEl.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
            tooltipEl.style.zIndex = "100";
            tooltipEl.style.fontSize = "12px";
            tooltipEl.style.color = "#6B7280";
            document.body.appendChild(tooltipEl);
          }

          // If tooltip is not visible, hide the tooltip element
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          const dataIndex = tooltip.dataPoints?.[0]?.dataIndex;
          if (dataIndex === undefined) {
            tooltipEl.style.opacity = "0";
            return;
          }

          const total = employeeDepartmentOverviewData.reduce(
            (sum: number, dataset) => {
              return sum + dataset.data[dataIndex];
            },
            0
          );

          // Generate tooltip content with individual values and the total
          const tooltipContent = employeeDepartmentOverviewData
            .map((dataset) => {
              const value = dataset.data[dataIndex];
              return `
                  <div style="display: flex; align-items: center; margin-bottom: 4px;">
                      <div style="
                          width: 12px;
                          height: 12px;
                          background-color: ${dataset.color};
                          margin-right: 8px;
                          border-radius: 2px;
                      "></div>
                      <div>${dataset.label} (${value})</div>
                  </div>
              `;
            })
            .join("");

          // Set the tooltip content, showing individual dataset values and the total
          tooltipEl.innerHTML = `
              <div style="font-weight: bold; margin-bottom: 6px; color: #474747;">
                  ${tooltip.title || ""} 
              </div>
              ${tooltipContent}
              <div style="margin-top: 10px; font-weight: bold;">
                  Total: ${total}
              </div>
          `;

          // Get the position of the canvas and place the tooltip accordingly
          const canvasRect = chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = "1";

          tooltipEl.style.left =
            canvasRect.left +
            window.scrollX +
            tooltip.caretX -
            tooltipEl.offsetWidth -
            10 +
            "px";

          tooltipEl.style.top =
            canvasRect.top + window.scrollY + tooltip.caretY + "px";
        },
      },
    },
    scales: {
      x: {
        stacked: true, // Stack the bars on the x-axis (horizontally)
        grid: { display: false },
      },
      y: {
        stacked: true,
        grid: { display: false },
        ticks: {
          beginAtZero: true,
          stepSize: 1, // Ensures only integer steps
          callback: function (value: any) {
            return Number.isInteger(value) ? value : "";
          },
        },
      },
    },
    datasets: employeeDepartmentOverviewData.map((item) => ({
      label: item.label,
      data: item.data,
      backgroundColor: item.color,
    })),
  };

  const colorMapping: Record<string, string> = {
    Food: "#CDEBC5",
    Travel: "#FBD796",
    Other: "#8AB3FF",
  };

  const transformData = (data: ExpenseItem[]) => {
    let categoryTotals: Record<string, number> = {
      Food: 0,
      Travel: 0,
      Other: 0,
    };

    let total = 0;

    data?.forEach((item) => {
      const categoryName =
        item?.ExpenseCategory?.expense_category_name || "Other";
      const amount = parseFloat(item.total_amount);
      total += amount;

      if (categoryName === "Food" || categoryName === "Travel") {
        categoryTotals[categoryName] += amount;
      } else {
        categoryTotals["Other"] += amount;
      }
    });

    return Object.keys(categoryTotals).map((key) => ({
      label: key,
      value: categoryTotals[key], // Keep zero values to ensure legend appears
      color: colorMapping[key],
    }));
  };

  const totalApprovedAmount = expensechartData.reduce((total, item) => {
    return total + parseFloat(Number(item.total_amount).toFixed(2) || "0");
  }, 0);

  const expenseChartData = transformData(expensechartData);
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    showCenterText: true,
    centerText: `${totalApprovedAmount}\n Total Expense`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
      centerTextPlugin,
    },
  };
  const projectStats = [
    {
      label: "Ongoing",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "ongoing")
            ?.count
        ) || 0,
      color: "#8AB3FF",
    },
    {
      label: "On Hold",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "onhold")
            ?.count
        ) || 0,
      color: "#F4C1C1",
    },
    {
      label: "Completed",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "completed")
            ?.count
        ) || 0,
      color: "#BEE5B9",
    },
    {
      label: "Upcoming",
      value:
        Number(
          projectStatusOverview.find((status) => status.status === "upcoming")
            ?.count
        ) || 0,
      color: "#FFFFA3",
    },
  ];

  const projectStatsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    cutout: "60%",
    centerText: `${projectCount}\n Total Projects`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 10,
          generateLabels: (chart: any) => {
            const data = chart?.data;
            if (data.labels && data?.datasets?.length) {
              return data?.labels?.map((label: any, i: any) => ({
                text: `${label} (${data?.datasets[0]?.data[i]})`,
                fillStyle: data?.datasets[0]?.backgroundColor[i],
                strokeStyle: data?.datasets[0]?.backgroundColor[i],
                // lineWidth: 1,
              }));
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        titleFont: { weight: "bold" },
        bodyFont: { size: 12 },
        displayColors: true,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            const label = dataset.labels?.[index] || tooltipItem.label;
            return ` ${label} ${value}`;
          },
        },
      },
    },
  };

  const AttendanceOptions = [
    { label: "Last 7 Days", value: "week" },
    { label: "Last 30 Days", value: "month" },
    { label: "Last 3 Months", value: "Last3months" },
    { label: "This Year", value: "year" },
  ];

  const attendanceData = [
    {
      label: `In Office${set === "week" ? "" : " (avg)"}`,
      data: Object.values(attendanceOverview)?.map((i) =>
        set === "month"
          ? Math.round((i?.WFO ?? 0) / 7)
          : set === "Last3months" || set === "year"
          ? Math.round((i?.WFO ?? 0) / 30)
          : i?.WFO ?? 0
      ),
      color: "#AEDCAE",
    },
    {
      label: `WFH${set === "week" ? "" : " (avg)"}`,
      data: Object.values(attendanceOverview)?.map((i) =>
        set === "month"
          ? Math.round((i?.WFH ?? 0) / 7)
          : set === "Last3months" || set === "year"
          ? Math.round((i?.WFH ?? 0) / 30)
          : i?.WFH ?? 0
      ),
      color: "#6496F4",
    },
    {
      label: `On Leave${set === "week" ? "" : " (avg)"}`,
      data: Object.values(attendanceOverview)?.map((i) =>
        set === "month"
          ? Math.round((i?.OnLeave ?? 0) / 7)
          : set === "Last3months" || set === "year"
          ? Math.round((i?.OnLeave ?? 0) / 30)
          : i?.OnLeave ?? 0
      ),
      color: "#F97C7C",
    },
  ];

  const attendanceLabels = Object.keys(attendanceOverview).map((date) =>
    set === "week" ? dayjs(date).format("DD MMM") : date
  );

  const attendenceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => tooltipItems[0]?.label,
          label: (tooltipItem: any) => {
            let datasetLabel =
              tooltipItem.dataset.label || "Unknown";
            let value = tooltipItem.raw;
            return `${datasetLabel}: ${value}`;
          },
          footer: (tooltipItems: any) => {
            const total = tooltipItems.reduce(
              (sum: number, item: any) => sum + item.raw,
              0
            );
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true, // Ensure x-axis bars are stacked
        grid: { display: false },
      },
      y: {
        stacked: true, // Stack values on the y-axis
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
  
  const financialData = [
    {
      label: "Profit",
      data: earnings.map((item: IFinancialYear) => {
        const netProfit = (item.totalProfit ?? 0) - (item.totalLoss ?? 0);
        return netProfit;
      }),
      color: "#AEDCAE",
    },
  ];
  const financialLabels = earnings.map(
    (item) => `${item.monthName} ${item.year}`
  );

  const formatValue = (value: number): string => {
    const absValue = Math.abs(value);
  
    if (absValue >= 1e12) return `${(value / 1e12).toFixed(1)}T`; // Trillions
    if (absValue >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billions
    if (absValue >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millions
    if (absValue >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Thousands
    return value.toString();
  };
  
  const financialOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any[]) => {
            const dataIndex = tooltipItems[0].dataIndex;
            return `${earnings[dataIndex].monthName} ${earnings[dataIndex].year}`;
          },
          label: () => "",
          afterBody: (tooltipItems: any[]) => {
            const dataIndex = tooltipItems[0].dataIndex;
            const totalProfit = (earnings[dataIndex].totalProfit ?? 0).toFixed(2);
            const totalLoss = (earnings[dataIndex].totalLoss ?? 0).toFixed(2);
  
            const netValue = (
              parseFloat(totalProfit) - parseFloat(totalLoss)
            ).toFixed(2);
  
            return netValue > "0.00"
              ? [`Total Profit: ${formatValue(parseFloat(netValue))}`]
              : [`Total Loss: ${formatValue(Math.abs(parseFloat(netValue)))}`];
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: {},
      },
      y: {
        beginAtZero: true,
        suggestedMin: -5000,
        suggestedMax: 5000,
        ticks: {
          stepSize: 1000,
          minTicksLimit: 5,
          maxTicksLimit: 10,
          callback: (value: any) => formatValue(value), // Dynamic formatting
        },
        grid: {
          drawOnChartArea: true,
          drawTicks: true,
          zeroLineColor: "#000",
          zeroLineWidth: 2,
          color: (context: any) =>
            context.tick.value === 0 ? "#CDCDCD" : "rgba(0, 0, 0, 0.1)",
          lineWidth: (context: any) => (context.tick.value === 0 ? 1.2 : 1),
        },
      },
    },
  };

  const expenseStats = [
    { label: "Owned", value: 20, color: "#CDEBC5" },
    { label: "Rented", value: 15, color: "#8AB3FF" },
  ];

  const expenseOptionsStats = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    centerText: `35\n Total Assets`,
    cutout: "60%",
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          generateLabels: (chart: any) => {
            const data = chart?.data;
            if (data.labels && data?.datasets?.length) {
              return data?.labels?.map((label: any, i: any) => ({
                text: `${label} (${data?.datasets[0]?.data[i]})`,
                fillStyle: data?.datasets[0]?.backgroundColor[i],
                strokeStyle: data?.datasets[0]?.backgroundColor[i],
              }));
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        titleFont: { weight: "bold" },
        bodyFont: { size: 12 },
        displayColors: true,
        callbacks: {
          title: () => "",
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const value = dataset.data[index];
            const label = dataset.labels?.[index] || tooltipItem.label;
            return ` ${label}: ${value}`;
          },
        },
      },
    },
  };

  const formattedDate = dayjs().format("dddd, DD MMMM YYYY");

  const activeCount =
    Number(
      clientStatusOverview.find((status) => status.status === "active")?.count
    ) || 0;

  const inactiveCount =
    Number(
      clientStatusOverview.find((status) => status.status === "inactive")?.count
    ) || 0;
    
    const totalEarning = profitableAmount - lossMakingAmount;
    

  return (
    <div>
      <header className="flex justify-between items-center h-10 px-4 pt-6 pb-2">
        <div>
          <p className="text-[#64748B] font-[400] font-[Poppins] text-[14px]">
            {formattedDate}
          </p>
          <h1 className="text-[#3F3F3F] font-[500] font-[Poppins] text-[20px]">
            {loginData && loginData.first_name ? (
              <span>
                {" "}
                <span className="text-[#3D3D3D] font-[400] pr-1">Welcome</span>
                {loginData.first_name} {loginData.last_name}
              </span>
            ) : signupData && signupData.sign_up_details.firstName ? (
              `Welcome ${signupData.sign_up_details.firstName} ${signupData.sign_up_details.lastName}`
            ) : (
              "Welcome"
            )}
          </h1>
        </div>
        <div className="flex gap-x-2">
          <div className="">
            <BusinessUnitSelect
              selectedBusinesses={selectedBusinesses}
              setSelectedBusinesses={setSelectedBusinesses}
            />
          </div>
        </div>
      </header>
      <div className="flex flex-col gap-3 mt-6 ml-3 mr-3">
        <div className="flex justify-between gap-4 flex-wrap h-full ">
          <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex gap-2 font-[Poppins]">
              <div className="text-[16px] font-medium leading-6 text-default-gray-600">
                Total Earning{" "}
              </div>

              <div className="text-[10px] font-normal leading-6 text-default-gray-700">
                ({session})
              </div>
            </div>

            <div className="flex gap-2">
              <span className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
                {formatValue(totalEarning) ?? 0}{" "}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(15,148,70,0.10)] text-[12px] font-medium leading-4 text-completed-green font-[Poppins]">
              Profit: {formatValue(profitableAmount) ?? 0}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(217,37,37,0.10)] text-[12px] font-medium leading-4 text-hold-red font-[Poppins]">
                Loss: {formatValue(lossMakingAmount) ?? 0}
              </span>
            </div>
          </div>{" "}
          <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="flex gap-2 font-[Poppins]">
              <div className="text-[16px] font-medium leading-6 text-default-gray-600">
                Total Employee
              </div>
            </div>

            <div className="flex gap-2">
              <span className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
                {countList?.total_users ?? 0}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(15,148,70,0.10)] text-[12px] font-medium leading-4 text-completed-green font-[Poppins]">
                Active: {countList?.active_users ?? 0}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(217,37,37,0.10)] text-[12px] font-medium leading-4 text-hold-red font-[Poppins]">
                Inactive: {countList?.inactive_users ?? 0}
              </span>
            </div>
          </div>{" "}
          <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              Total Projects
            </div>
            <div className="flex gap-2">
              <span className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
                {projectCount || "-"}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[#3968F21A] text-[12px] font-medium leading-4 text-[#3968F2] font-[Poppins]">
                On Time: {onTimeCount}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(217,37,37,0.10)] text-[12px] font-medium leading-4 text-hold-red font-[Poppins]">
                Delayed: {delayedCount}
              </span>
            </div>
          </div>
          <div className="flex min-h-[90px] p-2 px-4 flex-col justify-center items-start gap-2 flex-[1_0_0] rounded-md border border-default-gray-200 bg-white shadow-sm">
            <div className="text-[16px] font-medium leading-6 text-default-gray-600 font-[Poppins]">
              Total Clients
            </div>
            <div className="flex gap-2">
              <span className="text-[22px] font-semibold leading-[32px] text-default-gray-700 font-[Poppins]">
                {activeCount + inactiveCount || "-"}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(15,148,70,0.10)] text-[12px] font-medium leading-4 text-completed-green font-[Poppins]">
                Active: {activeCount}
              </span>
              <span className="flex items-center justify-center gap-[10px] p-[2px_10px] my-[3px] rounded-[2px] bg-[rgba(217,37,37,0.10)] text-[12px] font-medium leading-4 text-hold-red font-[Poppins]">
                Inactive: {inactiveCount}
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4">
          <div className="flex flex-col w-1/2 h-[345px] bg-[#FFFFFF] border border-gray-200 rounded-md shadow-sm">
            <h2 className="text-[#1F2937] text-[14px] font-medium tracking-[-0.4px] font-poppins mt-3 mb-1 ml-3">
              Financial Year Earning
            </h2>
            <div className="h-[90%]">
              <LineChart
                chartData={financialData}
                labels={financialLabels}
                options={financialOptions}
              />
            </div>
          </div>
          <div className="flex flex-col w-1/2 h-[345px] bg-[#FFFFFF] border border-gray-200 rounded-md overflow-hidden shadow-sm">
            <h2 className="text-[#1F2937] text-[14px] font-medium leading-[16px] tracking-[-0.4px] font-poppins mt-3 mb-1 ml-3">
              Employee Distribution by Department
            </h2>
            <div className="pl-4 pr-4 overflow-x-auto h-[85%] w-full">
              <BarChart
                chartData={employeeDepartmentOverviewData}
                labels={departmentOverviewLabels}
                options={departmentOverviewOptions}
                barThickness={departmentData?.length > 8 ? 20 : 35}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-2 gap-3">
          {/* Project Status Overview */}
          <div className="flex w-full h-[345px] p-4 flex-col justify-between items-start rounded-[6px] border border-custom-border-gray shadow-sm bg-white">
            <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
              Project Status Overview
            </div>
            <div className="flex justify-center h-[91%] my-2 w-full">
              <DoughnutChart
                chartData={projectStats}
                options={projectStatsOptions}
              />
            </div>
          </div>

          {/* Expense Type Distribution */}
          <div className="flex w-full h-[345px] p-4 flex-col justify-between items-start rounded-[6px] border border-custom-border-gray shadow-sm bg-white">
            <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
              Expense Type Distribution
            </div>
            <div className="flex justify-center h-[91%] my-2 w-full">
              <DoughnutChart
                chartData={expenseChartData}
                options={defaultOptions}
              />
            </div>
          </div>

          {/* Asset Ownership Distribution */}
          {/* <div className="flex w-full h-[345px] p-4 flex-col justify-between items-start rounded-[6px] border border-custom-border-gray shadow-sm bg-white">
            <div className="text-[14px] font-medium leading-[20px] tracking-[-0.42px] text-light-black font-[Poppins]">
              Asset Ownership Distribution
            </div>
            <div className="flex justify-center h-[91%] my-2 w-full">
              <DoughnutChart
                chartData={expenseStats}
                options={expenseOptionsStats}
              />
            </div>
          </div> */}
        </div>
        <div className="flex flex-col w-full h-[400px] bg-white border border-gray-200 rounded-md shadow-sm mb-10">
          <div className="flex justify-between items-center p-4">
            <h2 className="text-[#1F2937] text-[14px] font-medium font-poppins">
              Employee Attendance Overview
            </h2>
          </div>

          {/* Filter Dropdown - Positioned after the legend */}
          <div className="flex flex-wrap items-center justify-end gap-2 w-full mb-2 pr-4 mt-[-30px]">
            <TextField
              id="outlined-required"
              select
              placeholder=""
              name="set"
              value={set}
              className="min-w-[70px] w-full sm:w-[120px] md:w-[150px]"
              sx={{
                height: "40px",
                backgroundColor: "white",
                borderRadius: "0.5rem",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  borderRadius: "0.5rem",
                  border: "none",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "29px",
                  color: "#49454F",
                  "& fieldset": {
                    border:
                      "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
                  },
                  "&:hover fieldset": {
                    borderColor:
                      "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                    borderWidth: "1px",
                  },
                },
              }}
              onChange={(e) => {
                setSet(e.target.value);
              }}
              SelectProps={{ displayEmpty: true }}
            >
              {AttendanceOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="h-[90%]">
            <StackedBarChart
              chartData={attendanceData}
              labels={attendanceLabels}
              options={attendenceOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
