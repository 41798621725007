import React from "react";
import { useNavigate } from "react-router-dom";

import right from "../../../image/chevron-right.png";
import { Box, TextField } from "@mui/material";
import { Button } from "../../common/Button";

function ConfigureManagers() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-2.5">
      <div className="h-12 flex justify-between items-start">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/initialization")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Configure Line Managers
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            onClick={() => navigate("/appraisal-management")}
          >
            <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <Button type="submit" form="form" variant={"gradient"}>
            <div
              className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize"
              onClick={() => navigate("/configure-parameters")}
            >
              Save & Continue
            </div>
          </Button>
        </div>
      </div>
      <div className="w-full bg-white rounded-lg shadow-custom p-6">
        <div className="flex flex-col gap-[30px]">
          <div className="flex flex-col gap-[30px]">
            <span className="font-[Poppins] font-medium text-[16px] leading-[16px]">
              Employee Details
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-y-[30px] gap-x-[33px]">
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Employee Name & ID"
                  name="emp"
                  className="w-full"
                ></TextField>
              </div>
            </Box>
            <span className="font-[Poppins] font-medium text-[16px] leading-[16px]">
              Line Managers
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-y-[30px] gap-x-[33px]">
                <TextField
                  required
                  select
                  id="outline-required"
                  label="Line Managers Level"
                  name="managers"
                  className="w-full"
                ></TextField>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigureManagers;
