import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { debounce } from "lodash";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import search from "../../assets/Search.svg";

interface ModalProps {
  label: string;
  width?: string;
}

const PersistedSearch: React.FC<ModalProps> = ({ label, width }) => {
  const dispatch = useDispatch();

  const reduxSearchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const [localSearch, setLocalSearch] = useState(reduxSearchTerm);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      dispatch(
        setFilterPersistData({
          search: value,
        })
      );
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    setLocalSearch(reduxSearchTerm);
  }, [reduxSearchTerm]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocalSearch(value);
    debouncedSearch(value);
  };

  return (
    <div
      style={{ width: width || "226px" }}
      className="h-[40px] border border-[#CDCDCD] rounded-md py-3 pl-3 pr-2.5 flex justify-between items-center bg-white"
    >
      <input
        type="text"
        value={localSearch}
        placeholder={label}
        className="font-[Poppins] font-medium text-xs outline-none w-[calc(100%-22px)]"
        onChange={handleChange}
      />
      <img
        src={search}
        alt="search sign"
        className="h-[19px] w-[19px] text-[#79747E]"
      />
    </div>
  );
};

export default PersistedSearch;
