import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface SupportState {
    dashboard: Dashboard | null,
    currentPage: number;
    itemsPerPage: number;
    count: number;
    business_unit: number | null;
}


const initialState: SupportState = {
    dashboard: null,
    currentPage: 1,
    itemsPerPage: 10000,
    count: 0,
    business_unit: null,
};

interface Dashboard {
    name: string;
    id: number;
}

const EmployeeSlice = createSlice({
    name: "employee",
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setDevelopment(state, action: PayloadAction<Dashboard>) {
            state.dashboard = action.payload;
        },
        setBusinessUnit(state, action: PayloadAction<number>) {
            state.business_unit = action.payload;
        },
    },
});


export const EmployeeActions = EmployeeSlice.actions;
export default EmployeeSlice;