import React, { useEffect, useState } from "react";
import polygon from "../../assets/Polygon 1.png";
import icon from "../../assets/icon.png";
import { Fetch_Company_Details_List } from "../common/services/companyManagement";

interface CompanyDetails {
  id: number;
  name: string;
  country_code: string | null;
  status: string;
  is_active: boolean;
  email: string;
  hr_email: string | null;
  address: string;
  address_secondary: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  business_size: string;
  industry_vertical: number;
  createdby: string | null;
  logo: string;
  website: string | null;
  start_date: string | null;
  modifiedby: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  industry_vertical_name: string | null;
}

interface Leave_Type {
  business_size: string;
}

export const Details = () => {
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails | null>(
    null
  );

  const fetchCompanyDetails = async () => {
    try {
      const response = await Fetch_Company_Details_List();

      if (response.status === 200) {
        // console.log("Data: company management Tpye", response.data);
        setCompanyDetails(response.data.result);
      } else {

      }
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  return (
    <div className="relative pt-3">
      {/* Header */}
      <div className="flex flex-col md:flex-row bg-custom-bg-gray rounded-lg h-auto md:h-[180px] mt-20 md:mt-0">
        <div className="relative w-full h-[155px]">
          <div className="absolute w-[160px] h-[160px] bg-custom-bg-gray flex rounded-full justify-center items-center border-[4px] border-white -bottom-20 left-8 md:left-12 lg:left-16">
            {
              companyDetails?.logo && (
                <img
                src={companyDetails?.logo}
                // alt="Logo"
                className="w-[96px] h-[96px]"
            />
              )
            }
          </div>
        </div>
      </div>

      {/* Details Section */}
      <div className="flex flex-col p-[16px] rounded-lg bg-white gap-[25px] mt-[95px] mb-4">

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black  text-[16px] font-[600] font-poppins">
        Organisation Name:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.name || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Company Type:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.industry_vertical_name || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Company Size:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.business_size || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Organization's Email:
          </div>
          <div className="flex w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.email || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Country:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.country || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        State:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.state || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        City:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.city || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Address:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.address || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Postal Code:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.postal_code || "--"}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-[9px] md:gap-[12px] lg:gap-[16px]">
        <div className="mt-[10px] ml-[15px] w-[20%] text-custom-black text-[16px] font-[600] font-poppins">
        Website:
          </div>
          <div className="w-[80%] items-start rounded-[6px] bg-custom-bg-gray2 px-[16px] py-[13px] text-custom-lightBlack font-medium text-[16px] font-poppins">
            {companyDetails?.website ? (
              <a
                href={companyDetails.website}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-600 hover:underline"
              >
                {companyDetails.website}
              </a>
            ) : (
              "--"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
