import React, { useCallback, useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import { CountryCode } from "libphonenumber-js/core";
import {
  Department_Unit,
  Designation_Manager,
  EmployeeUnit,
  Reporting_Manager,
  Role_Unit,
  Team_Manager,
} from "../../redux/actions/Employee/Employee";
import { MuiTelInput } from "mui-tel-input";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchEditEmployee,
  fetchTimeZonesList,
  updateEmployee,
} from "../common/services/Employee";
import toast from "react-hot-toast";
import { getShiftTime, inputStyle } from "../../utils/helperData";
import moment from "moment";
import AddressAutocomplete from "../common/AddressAutocomplete";
import { TimePicker } from "@mui/x-date-pickers";
import { Button } from "../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

export interface BusinessUnit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

export interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Team_Unit {
  firstName: string;
  lastName: string;
  id: Number;
}
interface Report_Unit {
  firstName: string;
  lastName: string;
  id: Number;
}

interface Designation_Unit {
  name: string;
  id: Number;
}
interface Role_Data {
  role_name: string;
  id: Number;
}

interface IAddress {
  place: string;
  coordinates: [number | null, number | null];
}

interface FormValues {
  id: string;
  employeeCode: string;
  employeeId: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  experience: Number;
  experience_months: Number;
  // current_address: string | undefined;
  // current_coordinates: [number | undefined, number | undefined] | undefined;
  // permanent_address: string | undefined;
  // permanent_coordinates: [number | undefined, number | undefined] | undefined;
}

interface ITimeRange {
  start: Dayjs | null;
  end: Dayjs | null;
}

interface IAddress {
  place: string;
  coordinates: [number | null, number | null];
}

interface ITimezone {
  id: number;
  abbv: string;
  name: string;
  utc_offset: string;
}

interface FormSelectValues {
  business: string;
  department: string;
  designation: string;
  role: string;
  team: String;
  report: string;
  empStatus: string;
  ModeOfEmp: string;
  alloted_wfh_per_week: number | null;
  timezone: number | null;
}

export const EditEmployee = () => {
  //roleManagement
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const location = useLocation();
  const id = location.state?.id;
  const [timezonesList, setTimezonesList] = useState<ITimezone[]>([]);

  const [birth, setBirth] = React.useState<Dayjs | string | null>(null);
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [joiningErr, setJoiningErr] = React.useState<boolean>(false);
  const [leaving, setLeaving] = React.useState<Dayjs | string | null>(null);
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [bussness, setBusiness] = useState<BusinessUnit[]>();
  const [team, setTeam] = useState<Team_Unit[]>();
  const [selectReport, setSelectReport] = useState<Report_Unit[]>();
  const [designation, setDesignation] = useState<Designation_Unit[]>();
  const [roledata, setRoleData] = useState<Role_Data[]>();
  const [countryInitial, setCountryInitial] = useState<CountryCode>();
  const [nationalNumber, setNationalNumber] = useState("");
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);
  const [permanentAdd, setPermanentAdd] = useState<IAddress | null>(null);
  const [currentAdd, setCurrentAdd] = useState<IAddress | null>(null);
  const [errorPermanentAdd, setErrorPermanentAdd] = useState<boolean>(false);
  const [errorCurrentAdd, setErrorCurrentAdd] = useState<boolean>(false);
  const [errorPermanentAddError, setErrorPermanentAddError] =
    useState<string>("");
  const [errorCurrentAddError, setErrorCurrentAddError] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    id: "",
    employeeCode: "",
    employeeId: "",
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    experience: 0,
    experience_months: 0,
    // current_address: "",
    // current_coordinates: [undefined, undefined],
    // permanent_address: "",
    // permanent_coordinates: [undefined, undefined],
  });

  // const [orgTime, setOrgTime] = useState<ITimeRange>({
  //   start: null,
  //   end: null,
  // });
  // const [orgTimeError, setOrgTimeError] = useState<string | null>(null);
  const [empTime, setEmpTime] = useState<ITimeRange>({
    start: null,
    end: null,
  });
  const [empTimeError, setEmpTimeError] = useState<string | null>(null);

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const fetchTimeZones = useCallback(async () => {
    try {
      const timeZones = await fetchTimeZonesList();
      setTimezonesList(timeZones?.result || []);
    } catch (error) {
      console.error("Error fetching timezones:", error);
    }
  }, [customer_id]);

  useEffect(() => {
    fetchTimeZones();
  }, [fetchTimeZones]);

  const handlePermanentAddressChange = (
    value: google.maps.places.PlaceResult | null
  ) => {
    setErrorPermanentAdd(false);
    setErrorPermanentAddError("");
    setPermanentAdd({
      place: value?.formatted_address || "",
      coordinates: [
        value?.geometry?.location?.lat() || null,
        value?.geometry?.location?.lng() || null,
      ],
    });
  };

  const handleCurrentAddressChange = (
    value: google.maps.places.PlaceResult | null
  ) => {
    setErrorCurrentAdd(false);
    setErrorCurrentAddError("");
    setCurrentAdd({
      place: value?.formatted_address || "",
      coordinates: [
        value?.geometry?.location?.lat() || null,
        value?.geometry?.location?.lng() || null,
      ],
    });
  };

  useEffect(() => {
    // Fetch country based on latitude and longitude
    async function fetchCountry() {
      try {
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        if (response.ok) {
          const data = await response.json();
          setCountryInitial(data.countryCode);
        } else {
        }
      } catch (error) {}
    }

    if (latitude !== undefined && longitude !== undefined) {
      fetchCountry();
    }
  }, [latitude, longitude]);

  const handleClose = () => {
    setOpen(false);
    // navigate("/employee-management");
    navigate(-1);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    // navigate("/employee-management");
    navigate(-1);
  };

  // useEffect(() => {
  //   setFormValues({
  //     ...formValues,
  //     current_address: currentAdd?.formatted_address,
  //     current_coordinates: [
  //       currentAdd?.geometry?.location?.lat(),
  //       currentAdd?.geometry?.location?.lng(),
  //     ],
  //     permanent_address: permanentAdd?.formatted_address,
  //     permanent_coordinates: [
  //       permanentAdd?.geometry?.location?.lat(),
  //       permanentAdd?.geometry?.location?.lng(),
  //     ],
  //   });
  // }, [currentAdd, permanentAdd]);

  // const [phone, setPhone] = React.useState<string>("");
  // const [countryCode, setCountryCode] = React.useState<CountryCode | null>(null);
  const [countryCode, setCountryCode] = React.useState("");

  //const EmployeeStatus = ["active", "inactive", "archived"];
  const EmployeeStatus = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "archived", label: "Archived" },
  ];

  const Prefix = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
  ];
  //const ModeOfEmployment = ["full_time", "part_time", "contract", "intern"];

  const ModeOfEmployment = [
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    { value: "contract", label: "Contract" },
    { value: "internship", label: "Intern" },
  ];

  const [formSelectValues, setFormSelectValues] = useState<FormSelectValues>({
    business: "",
    department: "",
    designation: "",
    role: "",
    team: "",
    report: "",
    empStatus: "",
    ModeOfEmp: "",
    alloted_wfh_per_week: 0,
    timezone: null,
  });

  const handleSelectFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === "alloted_wfh_per_week") {
      setFormSelectValues({
        ...formSelectValues,
        alloted_wfh_per_week: Number(value),
      });
    } else {
      setFormSelectValues({
        ...formSelectValues,
        [name]: value,
      });
      setErrorsSelect({
        ...errorsSelect,
        [name]: "",
      });
    }

    // if (value !== "") {
    //   setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    // }
    if (
      !permanentAdd ||
      permanentAdd?.place === "" ||
      !permanentAdd?.coordinates ||
      permanentAdd?.coordinates?.[0] === null ||
      permanentAdd?.coordinates?.[1] === null
    ) {
      setErrorPermanentAdd(true);
      setErrorPermanentAddError("Permanent Address is required.");
      return;
    }

    // if (value !== "") {
    //   setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    // }
  };

  // const handleDesignationChange = (designationId: string) => {
  //   setFormSelectValues({
  //     ...formSelectValues,
  //     designation: designationId,
  //   })
  // }

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, []);

  useEffect(() => {
    const fetchRoledata = async () => {
      try {
        const historyData = await dispatch<any>(
          Role_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setRoleData(recentThree);
        }
      } catch (error) {}
    };

    fetchRoledata();
  }, []);

  const fetchDesignation = async () => {
    try {
      const firstResponse = await dispatch<any>(
        Designation_Manager(customer_id, navigate, 1)
      );

      const totalCount = firstResponse?.result.count || 0;
      const totalPages = Math.ceil(totalCount / 10);

      const remainingPages = [];
      for (let num = 2; num <= totalPages; num++) {
        remainingPages.push(num);
      }

      const remainingResponses = await Promise.all(
        remainingPages.map(async (num) => {
          return await dispatch<any>(
            Designation_Manager(customer_id, navigate, num)
          );
        })
      );

      const allDesignations = [
        ...firstResponse.result.rows,
        ...remainingResponses.flatMap(
          (response) => response?.result.rows || []
        ),
      ];

      setDesignation(allDesignations);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDesignation();
  }, [id]);

  useEffect(() => {
    const fetchTeamManager = async () => {
      try {
        const historyData = await dispatch<any>(
          Team_Manager(Number(formSelectValues.department), navigate)
          // Reporting_Manager(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result?.rows;
          setTeam(recentThree);
        }
      } catch (error) {}
    };

    fetchTeamManager();
  }, [formSelectValues.department]);

  useEffect(() => {
    const fetchReporting = async () => {
      try {
        const historyData = await dispatch<any>(
          Reporting_Manager(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result?.rows;
          setSelectReport(recentThree);
        }
      } catch (error) {}
    };

    fetchReporting();
  }, []);

  const ConditionalComponent: React.FC = () => {
    const location = useLocation();

    if (location.pathname === "/add-employee") {
      return <div>Employee details added successfully!</div>;
    } else if (location.pathname === "/edit-employee") {
      return <div>Employee details updated successfully!</div>;
    } else {
      return <div>Default Content</div>;
    }
  };

  // const [inputValue, setInputValue] = useState<string>("");
  // const [inputValue, setInputValue] = useState<string>(
  //   formValues?.permanent_address || ""
  // );

  // const handlePermanentAddressChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setInputValue(event.target.value);
  // };

  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [errorsSelect, setErrorsSelect] = useState<
    Partial<Record<keyof FormSelectValues, string>>
  >({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (!formValues?.employeeCode.trim())
      tempErrors.employeeCode = "Employee Code is required";
    if (!formValues?.employeeId.trim())
      tempErrors.employeeId = "Employee Id is required";
    if (!formValues?.prefix.trim()) tempErrors.prefix = "Prefix is required";
    if (!formValues?.firstName.trim())
      tempErrors.firstName = "First Name is required";
    if (!formValues?.lastName.trim())
      tempErrors.lastName = "Last Name is required";
    if (!formValues?.email.trim()) {
      tempErrors.email = "Email is required";
    }
    if (nationalNumber?.length < 6 || !countryCode || countryCode === "")
      tempErrors.phone = "Phone is required";
    // if (nationalNumber.length < 6) tempErrors.phone = "Phone length should be longer";
    // if (!formValues?.current_address || formValues?.current_address === "") {
    //   tempErrors.current_address = "Current Address is required";
    // }
    // if (
    //   !formValues?.permanent_address ||
    //   formValues?.permanent_address === ""
    // ) {
    //   tempErrors.permanent_address = "Permanent_address is required";
    // }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const selectValidate = (): boolean => {
    // let tempErrors: Partial<FormSelectValues> = {};
    let tempErrors: Partial<Record<keyof FormSelectValues, string>> = {};

    if (formSelectValues?.business === "")
      tempErrors.business = "Business Unit is required";
    if (formSelectValues?.department === "")
      tempErrors.department = "Department Unit is required";
    if (formSelectValues?.designation === "")
      tempErrors.designation = "Designation is required";
    if (formSelectValues?.role === "") tempErrors.role = "Role is required";
    if (formSelectValues?.report === "")
      tempErrors.report = "Reporting Manager is required";
    if (formSelectValues?.empStatus === "")
      tempErrors.empStatus = "Employement Status is required";
    if (formSelectValues?.ModeOfEmp === "")
      tempErrors.ModeOfEmp = "Employement Mode is required";
    setErrorsSelect(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handlePhoneChange = (value: string, info: any) => {
    // setPhone(value);
    setFormValues({
      ...formValues,
      phone: value,
    });
    setErrors({
      ...errors,
      phone: "",
    });
    setNationalNumber(info?.nationalNumber);
    setCountryCode(`+${info?.countryCallingCode}`);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const isFormValid = validate();
    // const isSelectValid = selectValidate();
    // if (isFormValid && isSelectValid) {

    // } else {

    // }
  };

  const submitChanges = async () => {
    try {
      await updateEmployee(
        id,
        formValues?.employeeCode,
        formValues?.employeeId,
        formValues?.email,
        nationalNumber,
        // phone,
        formValues?.prefix,
        formValues?.firstName,
        formValues?.middleName,
        formValues?.lastName,
        countryCode,
        Number(formSelectValues?.business),
        customer_id,
        Number(formSelectValues?.role),
        Number(formSelectValues?.department),
        Number(formSelectValues?.designation),
        formSelectValues?.team !== "" && formSelectValues?.team
          ? Number(formSelectValues.team)
          : null,
        Number(formSelectValues?.report),
        formSelectValues?.empStatus,
        formSelectValues?.ModeOfEmp,
        formValues?.experience,
        formValues?.experience_months,
        birth && moment(birth?.toString()).format("YYYY-MM-DD"),
        moment(joining?.toString()).format("YYYY-MM-DD"),
        // formValues?.current_address,
        // formValues?.current_coordinates,
        // formValues?.permanent_address,
        // formValues?.permanent_coordinates
        currentAdd?.place || "",
        currentAdd?.coordinates || [null, null],
        permanentAdd?.place || "",
        permanentAdd?.coordinates || [null, null],
        formSelectValues?.alloted_wfh_per_week ?? 0,
        formSelectValues?.timezone,
        // orgTime,
        empTime?.start
          ? moment(empTime?.start?.toISOString())?.format("HH:mm:ss")
          : null,
        empTime?.end
          ? moment(empTime?.end?.toISOString())?.format("HH:mm:ss")
          : null
      );

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        // navigate("/employee-management");
        navigate(-1);
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data?.result?.error);
    }
  };

  const handleClick = async () => {
    // !isJoining && setIsJoiningDisplay(true);
    // const res3 = validatePhone();
    const res1 = validate();
    const res2 = selectValidate();
    if (!joining || joining === "Invalid Date") {
      setJoiningErr(true);
      return;
    }

    if (
      !permanentAdd ||
      permanentAdd?.place === "" ||
      !permanentAdd?.coordinates ||
      permanentAdd?.coordinates?.[0] === null ||
      permanentAdd?.coordinates?.[1] === null
    ) {
      setErrorPermanentAdd(true);
      setErrorPermanentAddError("Permanent Address is required.");
      return;
    }
    if (
      !currentAdd ||
      currentAdd?.place === "" ||
      !currentAdd?.coordinates ||
      currentAdd?.coordinates?.[0] === null ||
      currentAdd?.coordinates?.[1] === null
    ) {
      setErrorCurrentAdd(true);
      setErrorCurrentAddError("Current Address is required.");
      return;
    }
    if (res1 && res2) {
      submitChanges();
    } else {
      toast.error("Please fill all the required details");
    }
  };

  // const validateBirthDate = (birth: any): string => {
  //   return birth && dayjs(birth).isValid()
  //     ? moment(birth.toString()).format("YYYY-MM-DD")
  //     : "--";
  // };

  // const [selectTeam, setSelectTeam] = useState("");

  // const handleTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   setSelectTeam(value);
  //   setFormSelectValues({
  //     ...formSelectValues,
  //     team: value,
  //   });
  //   setErrorsSelect((prevErrors) => ({
  //     ...prevErrors,
  //     team: "",
  //   }));
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchEditEmployee(id);
        if (data?.result) {
          const phonevalue = data?.result?.country_code + data?.result?.phone;

          setFormValues({
            id: String(data?.result?.id),
            employeeCode: data?.result?.employee_code || "",
            employeeId: data?.result?.employee_id || "",
            prefix: data?.result?.prefix || "",
            firstName: data?.result?.firstName || "",
            middleName: data.result?.middleName || "",
            lastName: data?.result?.lastName || "",
            email: data?.result?.email || "",
            phone: phonevalue,
            experience: Number(data?.result?.experience) || 0,
            experience_months: Number(data?.result?.experience_months) || 0,
            // current_address: data?.result?.current_address,
            // current_coordinates: data?.result?.current_coordinates,
            // permanent_address: data?.result?.permanent_address,
            // permanent_coordinates: data?.result?.permanent_coordinates,
          });
          setJoining(data?.result?.date_of_joining);
          setBirth(data?.result?.date_of_birth);
          setFormSelectValues({
            business: data?.result?.Group?.id,
            department: data?.result?.Department?.id,
            designation: data?.result?.designation_id,
            role: data?.result?.Role.id,
            team: data?.result?.team_manager?.id,
            report: data?.result?.reporting_manager?.id,
            empStatus: data?.result?.status,
            ModeOfEmp: data?.result?.mode_of_employment,
            alloted_wfh_per_week: data?.result?.alloted_wfh_per_week ?? 0,
            timezone: data?.result?.timings?.time_zone_id ?? 0,
          });
          setLeaving(
            data?.result?.date_of_leaving ? data?.result?.date_of_leaving : null
          );
          setCurrentAdd({
            place: data?.result?.current_address,
            coordinates: data?.result?.current_coordinates,
          });
          setPermanentAdd({
            place: data?.result?.permanent_address,
            coordinates: data?.result?.permanent_coordinates,
          });
          setNationalNumber(data?.result?.phone);
          setCountryCode(data?.result?.country_code);
          setEmpTime(
            getShiftTime(
              data?.result?.timings?.shift_start_time ?? null,
              data?.result?.timings?.shift_end_time ?? null
            )
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    fetchData();
  }, [id]);

  const handleClear = () => {
    setFormSelectValues({
      ...formSelectValues,
      team: "",
    });
    setErrorsSelect((prevErrors) => ({
      ...prevErrors,
      team: "",
    }));
  };

  return (
    <>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <img style={{ margin: "auto" }} src={check} alt="" />
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              <ConditionalComponent />
            </Typography>
          </Box>
        </Modal>
      </div>

      <div>
        <div className="bg-[#F9F9F9] px-4 pt-6 pb-12 h-full">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => navigate(-1)}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Edit Employee Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <Button
                onClick={handleCancel}
                className="flex w-[148px] rounded-lg p-[13.18px_50px_14.82px_51px] justify-center items-center  borderButton h-12"
                //onClick={(event) => handleCurrentPageChange("table", event)}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </Button>
              <Button
                type="submit"
                form="form"
                variant={"gradient"}
                onClick={handleClick}
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  {" "}
                  Update
                </div>
              </Button>
            </div>
          </div>
          <div className="mt-10 bg-white shadow-custom rounded-md">
            <div className="container mx-auto p-4">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    // disabled
                    required
                    id="outlined-required"
                    label="Company Code"
                    defaultValue=" "
                    name="employeeCode"
                    value={formValues?.employeeCode}
                    onChange={handleFrormChange}
                    error={!!errors.employeeCode}
                    helperText={errors.employeeCode}
                  />
                  <TextField
                    // disabled
                    required
                    id="outlined-required"
                    label="Employee Id"
                    defaultValue=" "
                    name="employeeId"
                    value={formValues?.employeeId}
                    onChange={handleFrormChange}
                    error={!!errors.employeeId}
                    helperText={errors.employeeId}
                  />
                  <TextField
                    required
                    select
                    id="outlined-required"
                    label="Prefix"
                    defaultValue=" "
                    name="prefix"
                    value={formValues?.prefix}
                    onChange={handleFrormChange}
                    error={!!errors.prefix}
                    helperText={errors.prefix}
                  >
                    {Prefix.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    label="First Name"
                    defaultValue=" "
                    name="firstName"
                    value={formValues?.firstName}
                    onChange={handleFrormChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                  <TextField
                    id="outlined-required"
                    label="Middle Name"
                    defaultValue=" "
                    name="middleName"
                    value={formValues?.middleName}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="Last Name"
                    defaultValue=" "
                    name="lastName"
                    value={formValues?.lastName}
                    onChange={handleFrormChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    defaultValue=" "
                    name="email"
                    value={formValues?.email}
                    onChange={handleFrormChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <div className="flex flex-row mt-1">
                    <MuiTelInput
                      label="Phone Number"
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      defaultCountry={countryInitial}
                      focusOnSelectCountry
                      autoComplete="off"
                      className="w-full h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2"
                      required
                      error={!!errors.phone}
                      helperText={errors.phone}
                      InputLabelProps={{
                        shrink: true,
                        // shrink: phone.length > 0,
                        // sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          // marginLeft: "20px",
                          marginBottom: "8px",
                          borderRadius: "3px",
                          height: "4.5rem",
                          // width: "300px",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={formValues?.phone}
                      onChange={handlePhoneChange}
                    />
                  </div>

                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Business Unit"
                    name="business"
                    defaultValue="hello"
                    // value={selectedDepartment}
                    // onChange={handleChange}
                    variant="outlined"
                    value={formSelectValues?.business}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.business}
                    helperText={errorsSelect.business}
                  >
                    {bussness?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    // required
                    id="outlined-required"
                    select
                    label="Timezone"
                    name="timezone"
                    variant="outlined"
                    value={String(formSelectValues?.timezone) ?? ""}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.timezone}
                    helperText={errorsSelect.timezone}
                  >
                    {timezonesList?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        GMT{option?.utc_offset} ({option?.abbv})
                      </MenuItem>
                    ))}
                  </TextField>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={`flex gap-1`}>
                      <TimePicker
                        label={`Shift Start`}
                        value={empTime?.start}
                        onChange={(newValue) => {
                          if (empTime?.end?.isBefore(newValue)) {
                            setEmpTime(() => ({
                              start: newValue,
                              end: null,
                            }));
                          } else {
                            setEmpTime((prev) => ({
                              ...prev,
                              start: newValue,
                            }));
                          }
                        }}
                        className="w-[95%]"
                        ampm={false}
                      />
                    </div>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={`flex gap-1`}>
                      <TimePicker
                        label={`Shift End`}
                        value={empTime?.end}
                        onChange={(newValue) => {
                          setEmpTime((prev) => ({ ...prev, end: newValue }));
                        }}
                        minTime={empTime?.start}
                        className="w-[95%]"
                        ampm={false}
                      />
                    </div>
                  </LocalizationProvider>

                  <AddressAutocomplete
                    // onSelect={setPermanentAdd}
                    // handleInputChange={handlePermanentAddressChange}
                    onSelect={(address) =>
                      handlePermanentAddressChange(address)
                    }
                    label="Permanent Address *"
                    placeholder="Enter Permanent Address *"
                    initialLocation={permanentAdd}
                    error={errorPermanentAdd}
                    helperText={errorPermanentAddError}
                  />
                  <AddressAutocomplete
                    // onSelect={setCurrentAdd}
                    onSelect={(address) => handleCurrentAddressChange(address)}
                    label="Current Address *"
                    placeholder="Enter Current Address *"
                    initialLocation={currentAdd}
                    error={errorCurrentAdd}
                    helperText={errorCurrentAddError}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    select
                    name="department"
                    label="Department"
                    defaultValue="hello"
                    //  sx={{ height: '300px' }}
                    value={formSelectValues?.department}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.department}
                    helperText={errorsSelect.department}
                  >
                    {department?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Designation"
                    defaultValue="hello"
                    name="designation"
                    value={formSelectValues?.designation}
                    // onFocus={fetchDesignation}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.designation}
                    helperText={errorsSelect.designation}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {designation?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Role"
                    name="role"
                    defaultValue="hello"
                    value={formSelectValues?.role}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.role}
                    helperText={errorsSelect.role}
                  >
                    {roledata?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.role_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-required"
                    select
                    label="Team Manager"
                    defaultValue="hello"
                    name="team"
                    value={formSelectValues?.team}
                    onChange={handleSelectFormChange}
                    InputProps={{
                      endAdornment: formSelectValues.team && (
                        <InputAdornment
                          position="end"
                          sx={{ position: "absolute", right: "40px" }}
                        >
                          <IconButton
                            onClick={handleClear}
                            edge="end"
                            aria-label="clear selection"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {selectReport?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Reporting Manager"
                    defaultValue="hello"
                    name="report"
                    value={formSelectValues?.report}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.report}
                    helperText={errorsSelect.report}
                  >
                    {selectReport?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Employee Status"
                    defaultValue="hello"
                    name="empStatus"
                    value={formSelectValues?.empStatus}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.empStatus}
                    helperText={errorsSelect.empStatus}
                  >
                    {EmployeeStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Mode Of Employment"
                    defaultValue="hello"
                    name="ModeOfEmp"
                    value={formSelectValues?.ModeOfEmp}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.ModeOfEmp}
                    helperText={errorsSelect.ModeOfEmp}
                  >
                    {ModeOfEmployment.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date Of Birth"
                        format="DD/MM/YYYY"
                        value={birth ? dayjs(birth) : null}
                        onChange={(newValue) => {
                          setBirth(newValue);
                        }}
                        slotProps={{
                          textField: {
                            inputProps: { readOnly: true },
                          },
                        }}
                        maxDate={dayjs()}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date Of Joining *"
                        format="DD/MM/YYYY"
                        value={joining ? dayjs(joining) : null}
                        onChange={(newValue) => {
                          setJoining(newValue);
                          setJoiningErr(false);
                        }}
                        maxDate={dayjs()}
                        className="w-full"
                        slotProps={{
                          textField: {
                            error: joiningErr,
                            helperText: joiningErr
                              ? "Please select a joining date"
                              : "",
                            inputProps: { readOnly: true },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>

                  <TextField
                    select
                    label="Alloted WFH per week"
                    value={formSelectValues?.alloted_wfh_per_week ?? 0}
                    onChange={handleSelectFormChange}
                    name="alloted_wfh_per_week"
                    variant="outlined"
                    id="outlined-required"
                  >
                    {Array.from({ length: 7 }, (_, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </TextField>

                  <div className="flex flex-col gap-1">
                    <div className="flex border border-gray-300 rounded-md overflow-hidden">
                      <div className="flex items-center px-2 bg-gray-100 text-gray-600 text-sm">
                        Past Experience
                      </div>
                      <TextField
                        placeholder="Months"
                        label="Month"
                        name="experience_months"
                        value={formValues.experience_months ?? 0}
                        onChange={handleFrormChange}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        InputProps={{
                          sx: {
                            border: "none",
                            outline: "none",
                            width: "90px",
                          },
                        }}
                        variant="standard"
                      />
                      <TextField
                        placeholder="Years"
                        label="Year"
                        name="experience"
                        value={formValues.experience ?? 0}
                        onChange={handleFrormChange}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        InputProps={{
                          sx: {
                            border: "none",
                            outline: "none",
                            width: "90px",
                          },
                        }}
                        variant="standard"
                      />
                    </div>
                  </div>

                  {formSelectValues?.empStatus !== "active" && (
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date Of Leaving"
                          format="DD/MM/YYYY"
                          value={
                            formSelectValues?.empStatus === "active"
                              ? null
                              : dayjs(leaving)
                          }
                          onChange={(newValue) => {
                            setLeaving(newValue);
                          }}
                          slotProps={{
                            textField: {
                              inputProps: { readOnly: true },
                            },
                          }}
                          maxDate={dayjs()}
                          className="w-full"
                        />
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
