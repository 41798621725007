import { FormHelperText } from "@mui/material";
import React, { useState } from "react";
import RichTextEditor, { EditorValue } from "react-rte";

interface MyStatefulEditorProps {
  onChange?: (value: string) => void;
  error?: boolean;
  helperText?: string;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({
  onChange,
  error,
  helperText,
}) => {
  const [value, setValue] = useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );

  const handleChange = (value: EditorValue) => {
    setValue(value);
    if (onChange) {
      onChange(value.toString("html"));
    }
  };

  return (
    <div>
      <RichTextEditor
        value={value}
        onChange={handleChange}
        className="h-[300px]"
        editorClassName="h-full"
        placeholder="Enter Description here ..."
        editorStyle={{
          height: "240px",
        }}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </div>
  );
};

export default MyStatefulEditor;
