import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface BarChartProps {
  chartData: {
    label1: string;
    label2: string;
    data1: number[];
    data2: number[];
    color1: string;
    color2: string;
  }[];
  labels: string[]; // Dynamically passed labels
  title?: string;
  options?: any; // Dynamically passed options for tooltips, etc.
  barThickness?: number;
}

const DSRGraph: React.FC<BarChartProps> = ({
  chartData,
  labels,
  title,
  options,
  barThickness = 40,
}) => {
  // Ensure chartData is formatted correctly with data for each week
  const data = {
    labels,
    datasets: [
      {
        label: "Allocated Hours",
        data: chartData.map((item) => item.data1[0]),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        barThickness,
      },
      {
        label: "Actual Hours",
        data: chartData.map((item) => item.data2[0]),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        barThickness,
      },
    ],
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: !!title,
        text: title,
        font: { size: 16 },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
      },
    },
    scales: {
      x: {
        stacked: false, // Allow side-by-side bars
        grid: { display: false },
        ticks: {
          autoSkip: false, // Ensure all labels are visible
        },
      },
      y: {
        stacked: false, // Allow side-by-side bars
        ticks: { beginAtZero: true },
      },
    },
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <Bar data={data} options={options || defaultOptions} />
    </div>
  );
};

export default DSRGraph;
// Show Week label
