import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback, ChangeEvent } from "react";

import DatePicker from "react-datepicker";
import search from "../../assets/Search.svg";
import del from "../../assets/Crud_Icons/delete.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../common/Pagenation";
import styled, { createGlobalStyle } from "styled-components";

import date from "../../assets/date.svg";
import ascending from "../../assets/ascending.svg";
import descending from "../../assets/descending.svg";
import "react-datepicker/dist/react-datepicker.css";
import edit_new from "../../assets/images/edit_new.png";
import right from "../../image/chevron-right.png";

import {
  Edit_Department_Detail,
  Edit_Process_Detail,
  GroupViewDetails,
  Patch_Edit_Group_Detail,
} from "../../redux/actions/CompanyStructure/companyStructureActions";

import { debounce } from "lodash";
import { getShiftTime, inputStyle, menuStyle } from "../../utils/helperData";
import { COMPANY_STRUCTURE, USER_MANAGEMENT } from "../../utils/routeConstant";
//import { Label } from "../../@/components/ui/Common/Elements/Label/Label";

import {
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { fetchUserManagementList } from "../../redux/actions/UserManagement/UserManagementActions";
import { userManagementActions } from "../../redux/reducers/userManagement/userManagementSlice";
import { NodeDatum } from "./CompanyTree";
import AddressAutocomplete from "../common/AddressAutocomplete";
import Search from "../SearchBar/Search";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { fetchTimeZonesList } from "../common/services/Employee";
import { time } from "console";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
// import TimeRangePick from "../EmployeeManagement/TimeRangePick";

const addType = "Edit";
const tableHeadings = [
  { id: "first_name", label: "First Name", width: "w-[9%]" },
  { id: "last_name", label: "Last Name", width: "w-[9%]" },
  { id: "email", label: "Email", sort: false, width: "w-[9%]" },
  {
    id: "contact_number",
    label: "Contact Number",
    sort: false,
    width: "w-[10%]",
  },
  { id: "createdAt", label: "Added Date", sort: true, width: "w-[10%]" },
  { id: "updatedAt", label: "Last Updated", sort: true, width: "w-[9%]" },
  { id: "groups", label: "Groups", sort: true, width: "w-[9%]" },
  { id: "role", label: "Role", sort: false, width: "w-[9%]" },
  { id: "action", label: "Action", sort: false, width: "w-[7%]" },
];

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }
      .react-datepicker-popper {
    z-index: 20 !important;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
}
`;

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    width: "85%",
    height: "100%",
  },
});
// interface Group {
//   id: number;
//   name: string;
//   parent_id: number | null;
//   children?: Group[];
// }

// interface ParentList {
//   id: number;
//   name: string;
//   parent_id: number | null
// }
interface Customer {
  id: number;
  name: string;
  email: string;
  address: string;
}
interface IAddress {
  place: string;
  coordinates: [number | null, number | null];
}
interface Role {
  id: number;
  role_name: string;
  status: string;
  customer_id: number;
}

interface Group {
  id: number;
  name: string;
  parent_id: number;
  customer_id: number;
  user_id: number | null;
  spoc_id: number;
  hr_email: string;
  status: string;
  businessUnit_address: string;
  businessUnit_coordinates: [number, number];
  businessUnit_shift_start_time: string;
  businessUnit_shift_end_time: string;
  week_off_days: number[];
  time_zone_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface User {
  id: number;
  employee_id: string;
  prefix: string;
  firstName: string;
  lastName: string;
  role_id: number;
  email: string;
  country_code: string;
  phone: string;
  customer_id: number;
  status: string;
  address: string | null;
  profile_image: string | null;
  group_id: number;
  createdAt: string;
  updatedAt: string;
  Customer: Customer;
  Role: Role;
  Group: Group | null;
}
interface ParentList {
  id: number;
  name: string;
  parent_id: number | null;
}

interface ITimeRange {
  start: Dayjs | null;
  end: Dayjs | null;
}
interface ITimezone {
  id: number;
  abbv: string;
  name: string;
  utc_offset: string;
}

const formatTime = (timeString: string): string => {
  const [hours, minutes] = timeString.split(":");
  return `${hours}:${minutes}`;
};
const ViewGroupDetails = () => {
  //! USE SELECTOR

  const companyStructureData = useSelector(
    (state: any) => state.CompanyStructure.companyStructureData
  );

  // console.log("company structure ===>", companyStructureData);

  //! VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedNodeData = location.state;
  const counting = companyStructureData.count;
  let itemId = "";
  let customer_id = 0;
  let access_token = "";

  const [businessLocation, setBusinessLocation] = useState<IAddress | null>({
    place: companyStructureData?.groups?.businessUnit_address,
    coordinates: companyStructureData?.groups?.businessUnit_coordinates,
  });

  const dateFormatter = (isoTimestamp: string) => {
    const dateObject = new Date(isoTimestamp);
    const formattedDate = dateObject.toISOString().substring(0, 10);

    return formattedDate;
  };

  const navigateToEditGroup = (id: any) => {
    navigate(`${USER_MANAGEMENT}`, {
      state: {
        id: id,
        currentPage: "editUser",
        previous: location.pathname,
      },
    });
  };

  //! Redux states
  const tableData = useSelector(
    (state: any) => state.userManagement.userManagementData.rows
  );
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  //! STATES
  const [loading, setLoading] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [hrTeamEmail, setHRTeamEmail] = useState(
    companyStructureData?.groups?.hr_email ?? ""
  );
  // const [businessAddress, setBusinessAddress] = useState("");
  const [orgTime, setOrgTime] = useState<ITimeRange>(() => {
    const { start, end } = getShiftTime(
      companyStructureData?.groups?.businessUnit_shift_start_time ?? null,
      companyStructureData?.groups?.businessUnit_shift_end_time ?? null
    );
    return { start: start, end: end };
  });
  const [id, setId] = useState(selectedNodeData.id);
  const [isEditMode, setIsEditMode] = useState(false);
  const [type, setType] = useState(selectedNodeData?.type);
  const [currentLabel, setCurrentLabel] = useState({
    id: companyStructureData?.groups?.spoc_id ?? 0,
  });
  const [groupName, setGroupName] = useState(
    companyStructureData?.groups?.name ?? ""
  );
  const [timezone, setTimezone] = useState(
    companyStructureData?.groups?.time_zone_id ?? 0
  );
  const [parentId, setparentId] = useState(
    selectedNodeData?.type === "Group" ||
      selectedNodeData?.type === "Department" ||
      selectedNodeData?.type === "Processes"
      ? selectedNodeData?.group_id || selectedNodeData?.parent_id
      : selectedNodeData?.group_id || selectedNodeData?.parent_id
  );
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState<any>({
    key: "createdAt",
    order: "DESC",
  });
  console.log({ currentLabel, groupName, timezone, companyStructureData });
  const [isInitial, setIsInitial] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleEdit, setToggleEdit] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [parentList, setParentList] = useState<ParentList[]>([]);
  const [timezonesList, setTimezonesList] = useState<ITimezone[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  //! CONDITIONS

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  //! HANDLER FUNCTIONS

  useEffect(() => {
    if (companyStructureData && !isEditMode) {
      setBusinessLocation({
        place: companyStructureData?.groups?.businessUnit_address,
        coordinates: companyStructureData?.groups?.businessUnit_coordinates,
      });
      setGroupName(companyStructureData?.groups?.name ?? "");
      setCurrentLabel({ id: companyStructureData?.groups?.spoc_id ?? 0 });
      setHRTeamEmail(companyStructureData?.groups?.hr_email ?? "");
      setTimezone(companyStructureData?.groups?.time_zone_id ?? 0);
      setOrgTime(() => {
        const { start, end } = getShiftTime(
          companyStructureData?.groups?.businessUnit_shift_start_time ?? null,
          companyStructureData?.groups?.businessUnit_shift_end_time ?? null
        );
        return { start: start, end: end };
      });
    }
  }, [companyStructureData, isEditMode]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  function handleGroupChange(value: string) {
    setGroupName(value);
  }

  function handleParentChange(value: string) {
    setShowSearch(true);
    // setParentName(value);
  }

  function handleHRTeamEmail(value: string) {
    setHRTeamEmail(value);
  }

  // function handleBusinessAddress(value: string) {
  //   setBusinessAddress(value);
  // }
  const handleValidation = () => {
    let newErrors: { [key: string]: string } = {};

    if (!groupName?.trim()) newErrors.groupName = "Group name is required";
    if (!hrTeamEmail?.trim()) {
      newErrors.hrTeamEmail = "HR email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(hrTeamEmail)) {
      newErrors.hrTeamEmail = "Invalid email format";
    }
    if (!timezone) newErrors.timezone = "Timezone is required";
    if (!businessLocation?.place?.trim())
      newErrors.businessAddress = "Business address is required";
    if (!currentLabel?.id) newErrors.spoc = "SPOC selection is required";
    if (!orgTime?.start) newErrors.startTime = "Shift start time is required";
    if (!orgTime?.end) newErrors.endTime = "Shift end time is required";
    if (
      orgTime?.start &&
      orgTime?.end &&
      orgTime?.end?.isBefore(orgTime?.start)
    ) {
      newErrors.endTime = "Shift end time must be after start time";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleParentSelectName = (name: string, id: number) => {
    // setParentName(name);
    setparentId(id);
    setId(id);
    setType(name);
    setShowSearch(false);
  };

  function handleSortType(item: any) {
    setLoading(true);
    itemId = item.id;
    if (item.sort) {
      if (sortType && sortType.key === item.id) {
        const newOrder = sortType.order === "ASC" ? "DESC" : "ASC";
        setSortType({ key: item.id, order: newOrder });
      } else {
        setSortType({ key: item.id, order: "DESC" });
      }
    }
    setLoading(false);
  }

  const handleSubmit = async () => {
    if (!groupName.trim()) {
      return;
    }
    if (selectedNodeData?.type === "Processes") {
      const response = await Edit_Process_Detail(
        groupName,
        id,
        currentLabel.id
      );
      if (response?.status === 200) {
        dispatch<any>(
          GroupViewDetails(
            customer_id,
            page,
            numOfItems,
            sortType,
            id,
            type,
            searchTerm,
            startDate,
            endDate
          )
        );
        // setIsEditMode(false);
        navigate(COMPANY_STRUCTURE);
      }
    }
    if (!parentId) {
      return;
    }

    if (selectedNodeData?.type === "Group") {
      if (!handleValidation()) {
        return;
      }
      const response = await Patch_Edit_Group_Detail(
        groupName,
        id,
        currentLabel.id,
        businessLocation?.place ?? "",
        [
          businessLocation?.coordinates?.[0] ?? null,
          businessLocation?.coordinates?.[1] ?? null,
        ],
        orgTime?.start,
        orgTime?.end,
        timezone,
        hrTeamEmail
      );
      if (response?.status === 200) {
        dispatch<any>(
          GroupViewDetails(
            customer_id,
            page,
            numOfItems,
            sortType,
            id,
            type,
            searchTerm,
            startDate,
            endDate
          )
        );
        handleGroupEdit();
        setErrors({});
      }
    } else if (selectedNodeData?.type === "Department") {
      const response = await Edit_Department_Detail(
        groupName,
        id,
        currentLabel.id
      );
      if (response?.status === 200) {
        dispatch<any>(
          GroupViewDetails(
            customer_id,
            page,
            numOfItems,
            sortType,
            id,
            type,
            searchTerm,
            startDate,
            endDate
          )
        );
        // setIsEditMode(false);
        handleGroupEdit();
        setErrors({});
      }
    } else {
      return null;
    }
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  const handleGroupEdit = () => {
    setIsEditMode(!isEditMode);
    setToggleEdit(!toggleEdit);
  };

  const handleSpocChange = (event: SelectChangeEvent<unknown>) => {
    const currentSpoc = tableData.find(
      (item: any) => item.id == event.target.value
    );
    setCurrentLabel({ id: currentSpoc?.id });
  };
  const handleTimezoneChange = (value: string) => {
    setTimezone(value ? value : null);
  };
  const fetchTimeZones = useCallback(async () => {
    try {
      const timeZones = await fetchTimeZonesList();
      setTimezonesList(timeZones?.result || []);
    } catch (error) {
      console.error("Error fetching timezones:", error);
    }
  }, []);

  useEffect(() => {
    fetchTimeZones();
  }, [fetchTimeZones]);

  useEffect(() => {
    const fetchData = async () => {
      let url = `/api/v1/user/list?customer_id=${customer_id}&page=${1}&size=${10000}`;

      try {
        const resData = await dispatch<any>(fetchUserManagementList(url));

        const userManagementListData = resData?.result?.rows;

        dispatch(
          userManagementActions.setUserManagementData(
            userManagementListData || { count: 0, rows: [] }
          )
        );
      } catch (error) {
        if (error instanceof Error) {
        } else {
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    dispatch<any>(
      GroupViewDetails(
        customer_id,
        page,
        numOfItems,
        sortType,
        id,
        type,
        searchTerm,
        startDate,
        endDate
      )
    );
  }, [
    customer_id,
    page,
    type,
    numOfItems,
    sortType,
    parentId,
    searchTerm,
    startDate,
    endDate,
    dispatch,
    id,
  ]);

  useEffect(() => {
    if (parentList.length && isInitial) {
      const tempParentName = parentList.find(
        (item: any) => item.id === parentId
      );
      // const tempParentName = parentList.find((item: any) => item.id === selectedParentId);
      if (tempParentName) {
        // setParentName(tempParentName?.name);
      } else {
        // setParentName("NA");
      }
      setIsInitial(false);
    }
  }, [parentList, parentId]);

  const handleGoBack = () => {
    if (isEditMode) {
      setIsEditMode(!isEditMode);
    } else {
      navigate("/company-structure");
    }
  };
  const getFormattedTimezone = (id: number): string => {
    const option = timezonesList?.find((tz: any) => tz.id === id);
    return option ? `GMT${option.utc_offset} (${option.abbv})` : "-";
  };
  const current_spoc = tableData?.find(
    (item: any) => item.id == currentLabel?.id
  );
  console.log({ current_spoc, currentLabel, tableData });
  const columns: ColumnDef<User>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          First Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row.firstName ?? "-",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "lastName",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Last Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row.lastName,
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row.email ?? "-",
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "phone",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Contact Number
        </Button>
      ),
      accessorFn: (row) => row.phone ?? "-",
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Added Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => dateFormatter(row.createdAt),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Last Updated
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => dateFormatter(row.updatedAt),
      cell: ({ row }) => <span>{row.getValue("updatedAt")}</span>,
    },
    {
      accessorKey: "Group",
      header: "Group",
      accessorFn: (row) => (row?.Group?.name ? row?.Group?.name : ""),
      cell: ({ row }) => <span>{row?.original?.Group?.name ?? "-"}</span>,
    },
    {
      accessorKey: "Role",
      header: ({ column }) => (
        <Button
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Role
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row.Role.role_name ?? "-",
      cell: ({ row }) => <span>{row?.original?.Role?.role_name ?? "-"}</span>,
    },
  ];

  const handleAddressChange = (
    value: google.maps.places.PlaceResult | null
  ) => {
    setBusinessLocation({
      place: value?.formatted_address || "",
      coordinates: [
        value?.geometry?.location?.lat() || null,
        value?.geometry?.location?.lng() || null,
      ],
    });
  };

  const DetailRow = ({
    label,
    value,
    labelClassName = "font-bold lg:text-[18px] text-[13px]",
  }: {
    label: string;
    value: React.ReactNode;
    labelClassName?: string;
  }) => (
    <div className="grid grid-cols-[minmax(120px,_180px)_30px_1fr] items-start gap-2 mb-1">
      <span className={`${labelClassName} break-words whitespace-normal`}>
        {label}
      </span>
      <span className="text-center self-start">:</span>
      <span className="lg:text-[18px] text-[13px] break-words whitespace-normal">
        {value || "-"}
      </span>
    </div>
  );

  return (
    <>
      {/* <div className="flex flex-col min-h-screen bg-white">
      <div className="flex-grow">
        Your main content goes here
        <div className=""> */}
      <div className="px-5">
        <div className="bg-white px-5 pt-5 h-full rounded-md">
          <div className="flex flex-row items-center h-12 justify-between w-full">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={handleGoBack}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                {isEditMode ? "Edit Group Details" : "Group Details"}
              </span>
            </div>
            {/* <div className="flex flex-row gap-[12px] justify-center items-center">
                <button
                  onClick={isEditMode ? () => setIsEditMode(false) : handleBack}
                >
                  <img
                    src={rightArrow}
                    alt="right arrow sign"
                    className="h-6 w-6 shrink-0 bg-black rounded-full"
                  />
                </button>
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  {crmType}
                </span>
              </div> */}
            {isEditMode ? (
              <div className="flex flex-start gap-5">
                <button
                  className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-custom-primary h-12"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  <div className="text-custom-primary text-center font-[Poppins] text-xs font-medium leading-[19.5px] capitalize">
                    Cancel
                  </div>
                </button>
                <button
                  type="submit"
                  form="form"
                  onClick={handleSubmit}
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg bg-custom-primary h-12"
                >
                  <div className="text-white text-center font-[Poppins] text-xs font-medium leading-[19.5px] capitalize">
                    Save Changes
                  </div>
                </button>
              </div>
            ) : (
              <button
                className="flex w-[100px] py-[11px] px-[15px] justify-center items-center gap-2.5 rounded-lg bg-custom-primary h-12"
                onClick={handleGroupEdit}
              >
                <div className="flex justify-center items-center gap-2">
                  <div className="flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.2573 5.07168C15.7234 4.60559 16.3555 4.34375 17.0147 4.34375C17.3411 4.34375 17.6643 4.40803 17.9658 4.53293C18.2673 4.65783 18.5413 4.8409 18.7721 5.07168C19.0028 5.30247 19.1859 5.57645 19.3108 5.87798C19.4358 6.17952 19.5 6.5027 19.5 6.82907C19.5 7.15545 19.4358 7.47863 19.3108 7.78017C19.1859 8.0817 19.0028 8.35568 18.7721 8.58647L17.8526 9.50597C17.5272 9.83141 16.9995 9.83141 16.6741 9.50597L14.3378 7.1697C14.0123 6.84427 14.0123 6.31663 14.3378 5.99119L15.2573 5.07168ZM13.1593 8.34822C12.8338 8.02277 12.3062 8.02277 11.9808 8.34822L6.17571 14.1533C5.64171 14.6873 5.26289 15.3563 5.07973 16.089L4.5249 18.3083C4.45391 18.5923 4.53711 18.8927 4.7441 19.0997C4.95108 19.3067 5.25149 19.3898 5.53546 19.3188L7.75478 18.764C8.48741 18.5809 9.1565 18.2021 9.69049 17.6681L15.4955 11.863C15.821 11.5376 15.821 11.0099 15.4955 10.6845L13.1593 8.34822Z"
                        fill="white"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 18.5052C12 18.045 12.3731 17.6719 12.8333 17.6719H18.6667C19.1269 17.6719 19.5 18.045 19.5 18.5052C19.5 18.9655 19.1269 19.3385 18.6667 19.3385H12.8333C12.3731 19.3385 12 18.9655 12 18.5052Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <span className="text-white font-[Poppins] text-sm font-medium leading-[29px]">
                    {addType}
                  </span>
                </div>
              </button>
            )}
          </div>

          <section
            style={{
              backgroundColor: "#F6F6F6",
              marginTop: "10px",
              borderRadius: "5px",
            }}
          >
            {!isEditMode ? (
              <div className="bg-gray-100 p-2 rounded-lg">
                <div className="container mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
                    {/* Left Column */}
                    <div className="space-y-1">
                      <DetailRow
                        label="Name"
                        value={companyStructureData?.groups?.name}
                      />
                      <DetailRow
                        label="Parent"
                        value={companyStructureData?.parents?.name}
                      />
                      <DetailRow
                        label="No. Of Users"
                        value={companyStructureData?.count}
                      />
                      {selectedNodeData?.type === "Group" && (
                        <>
                          <DetailRow
                            label="HR Email"
                            value={companyStructureData?.groups?.hr_email}
                          />
                          <DetailRow
                            label="Business Address"
                            value={
                              companyStructureData?.groups?.businessUnit_address
                            }
                          />
                        </>
                      )}
                    </div>

                    {/* Right Column */}
                    <div className="space-y-1">
                      <DetailRow
                        label="Created on"
                        value={
                          companyStructureData?.groups?.createdAt
                            ? dateFormatter(
                                companyStructureData?.groups?.createdAt
                              )
                            : null
                        }
                      />
                      <DetailRow
                        label="Updated on"
                        value={
                          companyStructureData?.groups?.updatedAt
                            ? dateFormatter(
                                companyStructureData?.groups?.updatedAt
                              )
                            : null
                        }
                      />
                      <DetailRow
                        label="SPOC"
                        value={
                          current_spoc
                            ? `${current_spoc?.firstName} ${current_spoc?.lastName}`
                            : null
                        }
                      />
                      {selectedNodeData?.type === "Group" && (
                        <>
                          <DetailRow
                            label="Shift Start"
                            value={
                              companyStructureData?.groups
                                ?.businessUnit_shift_start_time
                                ? formatTime(
                                    companyStructureData?.groups
                                      ?.businessUnit_shift_start_time
                                  )
                                : null
                            }
                          />
                          <DetailRow
                            label="Shift End"
                            value={
                              companyStructureData?.groups
                                ?.businessUnit_shift_end_time
                                ? formatTime(
                                    companyStructureData?.groups
                                      ?.businessUnit_shift_end_time
                                  )
                                : null
                            }
                          />
                          <DetailRow
                            label="Timezone"
                            value={
                              companyStructureData?.groups?.time_zone_id
                                ? getFormattedTimezone(
                                    companyStructureData?.groups?.time_zone_id
                                  )
                                : null
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="group-detail bg-white mt-8 p-6 rounded-lg shadow-md">
                <div className="container">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <TextField
                      id="outlined-basic"
                      value={groupName}
                      label="Group name"
                      name="Group_name"
                      variant="outlined"
                      placeholder="Enter Group Name"
                      onChange={(e) => handleGroupChange(e.target.value)}
                      error={!!errors?.groupName}
                      helperText={errors?.groupName}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                    />

                    <>
                      <TextField
                        id="outlined-basic"
                        label="Parent name"
                        name="Parent_name"
                        disabled
                        // value={parentName}
                        value={companyStructureData?.parents?.name}
                        onChange={(e) => handleParentChange(e.target.value)}
                        variant="outlined"
                        placeholder="Enter Parent Name"
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            alignItems: "center",
                            flexDirection: "row",
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      />

                      {parentList && showSearch && (
                        <div className=" bg-white z-10 absolute shadow-md border rounded-lg mt-1 max-h-[100px] overflow-y-auto ml-4">
                          {parentList.map(
                            (item: { name: string; id: number }) => (
                              <div
                                className="p-2.5 hover:bg-[#efefef] text-xs font-medium font-['Poppins'] leading-[14px]"
                                key={item.id}
                                onClick={() =>
                                  handleParentSelectName(item.name, item.id)
                                }
                              >
                                {item.name}
                              </div>
                            )
                          )}
                          {parentList.length === 0 && (
                            <div className="p-2.5 text-xs font-medium font-['Poppins'] leading-[14px]">
                              {/* {isValid["policy_author"] === true}{" "} */}
                              {/* Set the variable outside JSX */}
                              Parent does not exist
                            </div>
                          )}
                        </div>
                      )}
                    </>

                    <FormControl
                      fullWidth
                      error={!!errors.spoc}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: errors.spoc ? "red" : "",
                        },
                      }}
                    >
                      <InputLabel id="select-user-label">SPOC</InputLabel>
                      <Select
                        labelId="select-user-label"
                        id="outlined-basic"
                        value={currentLabel ? currentLabel?.id : "0"}
                        onChange={(e) => handleSpocChange(e)}
                        input={<OutlinedInput label="SPOC" />}
                        sx={{
                          ...inputStyle,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {tableData?.map((user: any) => (
                          <MenuItem value={user.id} style={menuStyle}>
                            {user.firstName} {user.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.spoc && (
                        <p className="text-red-500 text-xs">{errors?.spoc}</p>
                      )}
                    </FormControl>
                    {selectedNodeData?.type === "Group" ? (
                      <>
                        <TextField
                          id="outlined-basic"
                          value={hrTeamEmail}
                          label="HR Email"
                          name="hr_email"
                          variant="outlined"
                          placeholder="Enter HR Team Email"
                          error={!!errors.hrTeamEmail}
                          helperText={errors.hrTeamEmail}
                          onChange={(e) => handleHRTeamEmail(e.target.value)}
                          InputProps={{
                            sx: {
                              ...inputStyle,
                              alignItems: "center",
                              flexDirection: "row",
                              "& input:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                WebkitTextFillColor: "inherit",
                              },
                            },
                          }}
                        />

                        {/* <TextField
                        id="outlined-basic"
                        value={businessAddress}
                        label="Business address"
                        name="business_address"
                        variant="outlined"
                        placeholder="Enter Business Address"
                        onChange={(e) => handleBusinessAddress(e.target.value)}
                        sx={{ width: "35ch" }}
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            alignItems: "center",
                            flexDirection: "row",
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                      /> */}

                        <AddressAutocomplete
                          onSelect={(address) => handleAddressChange(address)}
                          label="Business Address"
                          placeholder="Enter Business Address"
                          initialLocation={businessLocation}
                          error={!!errors?.businessAddress}
                          helperText={errors?.businessAddress}
                        />

                        <FormControl
                          fullWidth
                          error={!!errors.timezone}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: errors.timezone ? "red" : "",
                            },
                          }}
                        >
                          <InputLabel id="select-user-label">
                            Timezone
                          </InputLabel>
                          <Select
                            labelId="select-user-label"
                            id="outlined-basic"
                            value={timezone ? timezone : "0"}
                            onChange={(e) =>
                              handleTimezoneChange(e.target.value)
                            }
                            input={<OutlinedInput label="Timezone" />}
                            sx={{
                              ...inputStyle,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {timezonesList?.map((option) => (
                              <MenuItem
                                key={Number(option.id)}
                                value={Number(option.id)}
                              >
                                GMT{option?.utc_offset} ({option?.abbv})
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.timezone && (
                            <p className="text-red-500 text-xs">
                              {errors.timezone}
                            </p>
                          )}
                        </FormControl>

                        <div className="w-full">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="flex gap-1 ">
                              <TimePicker
                                label={`Shift Start *`}
                                value={orgTime?.start}
                                onChange={(newValue) => {
                                  if (orgTime?.end?.isBefore(newValue)) {
                                    setOrgTime((prev) => ({
                                      start: newValue,
                                      end: null,
                                    }));
                                  } else {
                                    setOrgTime((prev) => ({
                                      ...prev,
                                      start: newValue,
                                    }));
                                  }
                                }}
                                slotProps={{
                                  textField: {
                                    error: !!errors.startTime,
                                    helperText: errors.startTime,
                                  },
                                }}
                                className="w-full bg-white"
                                ampm={false}
                              />
                              <TimePicker
                                label={`Shift End *`}
                                value={orgTime?.end}
                                minTime={orgTime?.start}
                                onChange={(newValue) => {
                                  setOrgTime((prev) => ({
                                    ...prev,
                                    end: newValue,
                                  }));
                                }}
                                slotProps={{
                                  textField: {
                                    error: !!errors.endTime,
                                    helperText: errors.endTime,
                                  },
                                }}
                                className="w-full bg-white"
                                ampm={false}
                              />
                            </div>
                          </LocalizationProvider>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </section>
          <div className="w-full mt-[17px]">
            <div className="pt-5 pb-5 flex flex-col items-flex-end gap-[17px] self-stretch rounded-md ">
              <div className="flex flex-start self-stretch justify-between w-full h-11 pb-12">
                <div className=" ml-0 pl-0 flex justify-between w-full h-11">
                  {/* <div className="h-full w-[35ch] flex pr-2 pl-[11px] justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA]">
                    <input
                      type="text"
                      placeholder="Search"
                      className=" text-[#B5B5C3] font-[Poppins] text-[13px] font-light leading-normal bg-transparent outline-none"
                      onChange={updateSearchTerm}
                    />
                    <img
                      src={search}
                      alt="search sign"
                      className="h-[19px] w-[19px] shrink-0"
                    />
                  </div> */}

                  <Search
                    onChange={updateSearchTerm}
                    label="Search with First Name & Last Name"
                    sizeWidth="310px"
                    inputSize="260px"
                  />

                  <div className="flex w-[314.08px] h-full p-[3px] justify-between text-[#B5B5C3] font-[Poppins] font-light items-center rounded-md border-[1px] border-solid border-[#CACACA] text-[13px]">
                    <GlobalStyle />
                    <DatePicker
                      selected={startDate}
                      onChange={(dates: any) => {
                        const [start, end] = dates;
                        setStartDate(start);
                        setEndDate(end);
                      }}
                      className="w-full flex py-2.5 h-[38px] justify-between items-center flex-[1,0,0] bg-transparent border-transparent"
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      customInput={<input />}
                      isClearable
                      dateFormat="MMMM d, yyyy"
                      placeholderText="DD/MM/YYYY - DD/MM/YYYY"
                      popperClassName="z-50"
                    />
                    {<img src={date} alt="date icon" className="w-6 h-6"></img>}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="table_main_content w-full relative overflow-auto bg-white mt-5"
              style={{
                height: `calc(100vh - ${!isEditMode ? "34rem" : "32rem"})`,
              }}
            >
              <DynamicTable<User>
                onRowClick={(row) => {
                  navigate("/employee-list-details", {
                    state: { id: row?.id },
                  });
                }}
                data={companyStructureData?.rows ?? []}
                loading={loading}
                columns={columns}
                enableSorting
                enablePagination
              />
            </div>

            <TablePaginationDemo
              count={counting}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={page}
              numOfItems={numOfItems}
            />
          </div>
        </div>
      </div>
      {/* </div>
        <Footer />
      </div>
    </div>  */}
    </>
  );
};
export default ViewGroupDetails;
