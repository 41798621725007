import axios from "axios";
import { base_api, api_key_work as api_key } from "../../../utils/helperData";
import { FETCH_DESIGNATION_MANAGEMENT_LIST } from "../api";
import axiosInstance from "../../../app/api/axiosInstance";

export const Fetch_Designation_Management_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  //sortType: any,
  searchTerm: string
  // startDate: Date | null,
  // endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }
  // &session_start=${localStorage.getItem("start_session")}&session_end=${localStorage.getItem("end_session")}
  return axiosInstance.get(`${FETCH_DESIGNATION_MANAGEMENT_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createDesignation = async (
  designation: string,
  customerId: number
  //resourceList: number[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    name: designation,
    customer_id: customerId,
    //resource_list: resourceList,
  };

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/designation`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditDesignation = async (id: string) => {
  const access_token = localStorage.getItem("access_token");

  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/designation/${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateDesignation = async (
  id: string,
  name: string,
  //resourceChanges: { resource_id: number; type: string }[],
  status: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name: name,
    //resource_list: resourceChanges,
    status,
  };

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/designation/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
