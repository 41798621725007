import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";
import pdfIcon from "../../assets/pdfImage.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  approveRejectApi,
  fetchPolicyDetails,
  fetchPolicyRecievers,
} from "../common/services/companyManagement";
import ModalViewer from "../ExpenseManagement/ModalViewer";
import Receipt from "../ExpenseManagement/ImageView";
import toast from "react-hot-toast";
import { getPolicyStatus } from "./Policies";
import {
  IPolicyDetail,
  IPolicyReceiveList,
} from "../common/interfaces/CompanyManagement";
import StatusChip, { getColors } from "../common/shared/StatusChip";
import DocumentCard from "../common/shared/DocumentCard";
import { Button } from "../common/Button";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import moment from "moment";
import { useSelector } from "react-redux";

interface Approvers {
  id: number;
  employee_id: string;
  name: string;
  status: string;
}

export const ViewPolicy = () => {
  const navigate = useNavigate();
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const userId = loginData?.userId;

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const downloadFile = (url: string, filename: string) => {
    try {
      const link = document.createElement("a");
      link.href = url;

      const cleanFilename = filename.replace(/[^a-zA-Z0-9.\-_]/g, "_");
      link.download = cleanFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  const [PolicyData, setPolicyData] = useState<IPolicyDetail | null>(null);
  const [tableData, setTableData] = useState<IPolicyReceiveList[] | []>([]);
  const location = useLocation();
  const id = location.state?.id;

  const columns: ColumnDef<IPolicyReceiveList>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const data = row?.original;
        return (
          <div className="flex flex-col justify-between">
            <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
              {data?.User?.firstName} {data?.User?.lastName}
            </span>
            <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
              {data?.User?.employee_id || ""}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "businessUnit",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Business Unit
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.Group?.name ?? "-",
      cell: ({ row }) => {
        const name: string =
          row?.original?.User?.Department?.Group?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Department
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";

        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Date & Time
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      accessorFn: (row) =>
        row?.updatedAt
          ? new Date(row.updatedAt).toLocaleString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : "-",
      cell: ({ row }) => {
        const data = row?.original?.updatedAt;
        let name = "";
        if (data) {
          name = moment(data)?.format("DD MMM YYYY, HH:mm");
        }

        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "acknowledge_status",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Acknowledge
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const status = row?.original?.acknowledge_status ? "Yes" : "No";
        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {status}
          </div>
        );
      },
    },
  ];
  const departmentNames: string[] =
    PolicyData?.PolicyDepartments?.map(
      (item) => item?.Department?.name ?? ""
    ) ?? [];

  useEffect(() => {
    fetchPolicyDetails(id)
      .then((data) => {
        if (data?.result) {
          setPolicyData(data?.result);
          setApproverData(data?.result?.approvers);
        }
      })
      .catch((error) => console.error(error));
  }, [id]);
  useEffect(() => {
    fetchPolicyRecievers({
      numOfItems: 3,
      page: 1,
      search: "",
      id: id,
      status: null,
      group_id: null,
      department_id: null,
    })
      .then((data) => {
        if (data?.data?.result) {
          setTableData(data?.data?.result?.rows ?? []);
        }
      })
      .catch((error) => console.error(error));
  }, [id]);

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  const handleReject = () => {
    postData();
  };

  const handleApproved = () => {
    postData2();
  };
  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.id === userId) {
        setShowButtons(true);
        setStatus(approver?.status ?? "");
      }
    });
  }, [approverData, userId]);

  const postData2 = async () => {
    // setLoading(true);
    setStatus("Approved");
    const status = "Approved";
    try {
      const response = await approveRejectApi(
        //  customer_id,
        id,
        status
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/policies");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  const postData = async () => {
    // setLoading(true);
    setStatus("Rejected");
    const status = "Rejected";
    try {
      const response = await approveRejectApi(
        //  customer_id,
        id,
        status
      );
      if (response.status === 200) {
        navigate("/policies");
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col px-4 pt-6 gap-[30px] pb-6">
      <div className="flex flex-row justify-between items-center h-12">
        <div className="flex flex-row justify-between items-center h-[30px] gap-2">
          <button onClick={() => navigate("/policies")}>
            <img
              src={right}
              alt="right arrow"
              className="h-6 w-6 bg-black rounded-full"
            />
          </button>
          <span className="h-[30px] font-['Poppins'] font-semibold text-2xl text-[#1D1A22] ">
            Policy Details
          </span>
        </div>
        {showButtons ? (
          <div className="flex flex-row gap-2 items-center h-full ">
            {status !== "Rejected" ? (
              <button
                onClick={handleReject}
                className="h-12 w-[166px] rounded-lg bg-[#B3261E] py-[11px] px-[19px] flex flex-row justify-center items-center hover:bg-[#96150e]"
              >
                <div className="flex flex-col justify-center items-center">
                  <span className="text-white font-['Poppins'] font-medium text-[14px] leading-[29px]">
                    Reject
                  </span>
                </div>
              </button>
            ) : null}
            {status !== "Approved" ? (
              <button
                onClick={handleApproved}
                className="h-12 w-[166px] rounded-lg bg-[#359349] py-[11px] px-[19px] flex flex-row justify-center items-center hover:bg-[#1f8735]"
              >
                <div className="flex flex-col justify-center items-center">
                  <span className="text-white font-['Poppins'] font-medium text-[14px] leading-[29px]">
                    Approve
                  </span>
                </div>
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
      <div
        className="flex flex-col gap-5 p-5 shadow rounded-lg max-h-[calc(100vh-13rem)] overflow-auto"
        style={{ boxShadow: "0px 2px 15px 0px rgba(72, 71, 71, 0.1)" }}
      >
        <div className="w-full h-6">
          <span className="font-['Poppins'] font-semibold text-[20px] leading-[24.2px] text-dark-grey">
            Policy Details
          </span>
        </div>
        <div
          className="flex flex-col gap-[15px] px-2.5 lg:px-6 py-5 rounded-md bg-gradient-to-r"
          style={{
            background:
              "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
          }}
        >
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-grey300">
                Policy Name:
              </span>
              <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-[#1D1A22]">
                {PolicyData?.name ? PolicyData?.name : "-"}
              </span>
            </div>
            <div className="flex flex-col gap-[7px] h-full justify-between">
              <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-grey300">
                Policy Category:
              </span>
              <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-[#1D1A22]">
                {PolicyData?.PolicyCategory?.name
                  ? PolicyData?.PolicyCategory?.name
                  : "-"}
              </span>
            </div>
            <div className="flex flex-col gap-[7px]">
              <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-grey300">
                Effective Date:
              </span>
              <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-[#1D1A22]">
                {PolicyData?.effective_date ? PolicyData?.effective_date : "-"}
              </span>
            </div>
          </div>
          <hr className="h-[2px] bg-[#EDEDED]" />
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px] h-full justify-between">
                <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-grey300">
                  Reccurence Period:
                </span>
                <span className="font-['Poppins'] capitalize font-medium text-sm leading-[20px] text-[#1D1A22]">
                  {PolicyData?.recurrence
                    ? PolicyData?.recurrence
                        ?.split("_")
                        ?.join(" ")
                        ?.toLowerCase()
                    : "-"}
                </span>
              </div>
            </div>
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px] h-full justify-between">
                <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-grey300">
                  Version Number:
                </span>
                <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-[#1D1A22]">
                  {PolicyData?.version_no ? PolicyData?.version_no : "-"}
                </span>
              </div>
            </div>
            <div className="flex flex-row w-full h-[60px] justify-between">
              <div className="flex flex-col gap-[7px] h-full justify-between">
                <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-grey300">
                  Workflow Stage:
                </span>
                <span className="font-['Poppins'] font-medium text-sm leading-[20px] text-[#1D1A22]">
                  {getPolicyStatus[
                    PolicyData?.status as keyof typeof getPolicyStatus
                  ] ?? "-"}
                </span>
              </div>
            </div>
          </div>
          <hr className="h-[2px] bg-[#EDEDED]" />

          <div className="flex flex-col gap-[7px] w-full">
            <div className="w-full h-[19px]">
              <span className="font-['Poppins'] font-medium text-sm leading-[19.36px] text-grey300">
                Business Unit:
              </span>
            </div>
            <div className="w-full pr-8">
              <p className="font-['Poppins'] font-medium text-base tracking-[0.15px] text-[#322F37]">
                {PolicyData?.Group?.name ? PolicyData?.Group?.name : "-"}
              </p>
            </div>
          </div>
          <hr className="h-[2px] bg-[#EDEDED]" />
          <div className="flex flex-col gap-[7px] w-full">
            <div className="w-full h-[19px]">
              <span className="font-['Poppins'] font-medium text-sm leading-[19.36px] text-grey300">
                Department:
              </span>
            </div>
            <div className="w-full pr-8">
              <p className="font-['Poppins'] font-medium text-base tracking-[0.15px] text-[#322F37]">
                {departmentNames?.length > 0 ? departmentNames.join(", ") : "-"}
              </p>
            </div>
          </div>
          <hr className="h-[2px] bg-[#EDEDED]" />

          <div className="flex flex-col gap-[7px] w-full">
            <div className="w-full h-[19px]">
              <span className="font-['Poppins'] font-medium text-sm leading-[19.36px] text-grey300">
                Approvers:
              </span>
            </div>
            <div className="grid grid-cols-4 w-full gap-x-2 md:gap-x-6">
              {PolicyData?.approvers?.map((user) => (
                <div className="p-[10px] rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                  <div className="h-[40px] flex items-center gap-2.5">
                    <div className="h-full w-[40px] rounded-full">
                      <img
                        src={user?.profile_image || profile}
                        alt="profile"
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <div className="h-full flex flex-col justify-center">
                      <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                        {user?.firstName} {user?.lastName}
                      </span>
                      <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                        {user?.employee_id}
                      </span>
                    </div>
                  </div>
                  <StatusChip status={user?.status as keyof typeof getColors} />
                </div>
              ))}
            </div>
          </div>
          <hr className="h-[2px] bg-[#EDEDED]" />
          <div className="flex flex-col gap-[7px] w-full">
            <div className="w-full h-[19px]">
              <span className="font-['Poppins'] font-medium text-sm leading-[19.36px] text-grey300">
                Documents:
              </span>
            </div>
            <div className="grid grid-cols-4 w-full gap-x-2 md:gap-x-6">
              {PolicyData?.document_url?.map((doc, i) => (
                <DocumentCard
                  key={i}
                  fileUrl={doc}
                  onView={() => handleView(doc)}
                  onDownload={() =>
                    downloadFile(
                      doc,
                      doc?.split("/")?.pop() || `Receipt ${i + 1}`
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className="w-full h-6 flex justify-between">
          <div className="flex items-start h-full">
            <span className="font-['Poppins'] font-semibold text-[20px] leading-[24.2px] text-dark-grey">
              Policy Recieved By
            </span>
          </div>

          <div className="flex h-full items-center">
            <Button
              onClick={() =>
                navigate(`/policy-receivers`, {
                  state: { id: id },
                })
              }
              className="font-[Poppins] text-sm font-medium border border-light-grey rounded-lg bg-white text-light-black hover:bg-blue-gray-50/80"
            >
              View All
            </Button>
          </div>
        </div>
        <div className="table_main_content  w-full  relative  overflow-visible h-full bg-white ">
          <DynamicTable<IPolicyReceiveList>
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
          />
        </div>
      </div>

      {selectedFileUrl && (
        <ModalViewer
          isOpen={!!selectedFileUrl}
          onRequestClose={closeModal}
          fileUrl={selectedFileUrl}
        />
      )}
    </div>
  );
};
