import React from "react";
import notFound from "../../assets/404page.svg";
import { useNavigate } from "react-router-dom";
import { HOME, LOGIN } from "../../utils/routeConstant";
const InternalServer = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("access_token") !== null;
  const handleCancel = () => {
    if (isLoggedIn) {
      return navigate(`/dashboard`);
    }
    navigate(`/`);
  };
  return (
    <div
      className="flex justify-end items-center pr-20"
      style={{
        background: `url(${notFound})`,
        objectFit: "contain",
        height: "100vh",
        width: "100%",
      }}
    >
      <div className="flex justify-center items-center h-full w-[60%] flex-col">
        <p className="flex justify-center items-center h-auto w-full text-[70px] text-white font-semibold">
          Internal Server Error
        </p>
        <br />
        <p className="text-white text-2xl">Error 500</p>
        <br />
        <p className="text-white text-center text-2xl justify-center items-center pr-10">
          Sorry, there were some technical issues while processing your request.
        </p>
        <br />
        <div className="flex justify-center">
          <button className="flex w-[148px] justify-center items-center self-stretch rounded-lg border border-solid border-white h-12">
            <div
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              onClick={handleCancel}
            >
              Go Back
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InternalServer;
