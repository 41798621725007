import { FormHelperText } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import RichTextEditor, { EditorValue } from "react-rte";

interface MyStatefulEditorProps {
  content?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  helperText?: string;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({
  content,
  onChange,
  error,
  helperText,
}) => {
  const [value, setValue] = useState<EditorValue>(() => {
    return content
      ? RichTextEditor.createValueFromString(content, "html")
      : RichTextEditor.createEmptyValue();
  });

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (content) {
      const newValue = RichTextEditor.createValueFromString(content, "html");
      if (value.toString("html") !== newValue.toString("html")) {
        setValue(newValue);
      }
    }
  }, [content, value]);

  const handleChange = (newValue: EditorValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue.toString("html"));
    }
  };

  return (
    <div>
      <RichTextEditor
        value={value}
        onChange={handleChange}
        className="h-[300px]"
        editorClassName="h-full"
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </div>
  );
};

export default MyStatefulEditor;
