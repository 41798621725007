import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";
import * as XLSX from "xlsx";
import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import view from "../../assets/Crud_Icons/view.svg";
import profile from "../../assets/Ellipse 580.png";
import eye from "../../assets/Crud_Icons/view.svg";
import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Fetch_Asset_List } from "../common/services/Asset";
import moment from "moment";
import TableSkeleton from "../common/TableSkeleton";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import { Close } from "@mui/icons-material";

interface AssignAssetData {
  id: number;
  asset_name: string;
  assigned_date: string;
  acknowledgment_status: boolean;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  AssetCategory: {
    id: number;
    category: string;
  };
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

function AssignedAsset() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rowData, setRowData] = useState<AssignAssetData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState(false);

  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Asset_List(
        customer_id,
        numOfItems,
        page,
        searchTerm
      );
      if (response.status === 200) {
        setRowData(response.data.result.rows);
        // const updatedRows = response?.data?.result?.rows?.map((row: any) => ({
        //   ...row,
        //   acknowledgement_status:
        //     row?.acknowledgement_status === true ? "Yes" : "No",
        // }));

        // setRowData(updatedRows);
        // console.log(updatedRows);
        setCount(response.data.result.count);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer_id, page, numOfItems, searchTerm]);

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    if (dates[0] && dates[1]) {
      // close the calendar when both the dates are selected
      setIsCalendarOpen(false);
    }
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    } else {
      setDateRange(false);
    }


    setIsOpen(false);

      
    // Clear all if all fields are empty or undefined
    if (
      (selectedDates[0] === null || selectedDates[1] === null)
    ) {
      setSelectedDates([null, null]);
      setStartDate(null);
      setEndDate(null);

    }



  }

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    // setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    // setTempSelectedDepartmentUnit(option);
  };





  const handleDownload = () => {
    if (rowData.length > 0) {
      // Define the column headers based on the table structure
      const columns = [
        "Assigned To",
        "Asset Category",
        "Asset Name",
        "Assigned Date",
        "Acknowledgement",
      ];
  
      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...rowData.map((row) => [
          // Assigned To: Full name and employee_id (if available)
          row?.User?.firstName && row?.User?.lastName
            ? `${row.User.firstName} ${row.User.lastName}`
            : "-", // Combine firstName and lastName, or fallback to "-",
          
          // Asset Category: Category of the asset (if available)
          row?.AssetCategory?.category ?? "-",
  
          // Asset Name: Asset name (if available)
          row?.asset_name ?? "-",
  
          // Assigned Date: Formatted date or fallback to "-"
          row?.assigned_date
            ? new Date(row.assigned_date).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "-",
  
          // Acknowledgement: If acknowledgment_status is true, show "Yes", else "No"
          row?.acknowledgment_status ? "Yes" : "No",
        ]),
      ];
  
      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Asset Assignment Data");
  
      // Generate the binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "asset_assignment_data.xlsx"; // Customize download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };
  


  const columns: ColumnDef<AssignAssetData>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Assigned To
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 pl-2 pr-2.5">
            <img
              className="w-8 h-8 rounded-full"
              src={item?.User?.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item?.User?.firstName} {item?.User?.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item.User?.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "AssetCategory",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset Category
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.AssetCategory?.category ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.AssetCategory?.category ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "asset_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Asset Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.asset_name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.asset_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "assigned_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Assigned Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.assigned_date
          ? new Date(row.assigned_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.assigned_date
          ? new Date(row.original.assigned_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "acknowledgment_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Acknowledgement
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const status: string = row.original.acknowledgment_status
          ? "Yes"
          : "No";
        return <div className="">{status}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/view-asset`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-asset`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-6">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search onChange={updateSearchTerm} label="Search with name & id" />
            <div className="h-full flex items-center gap-5">
             <div className="h-full flex justify-end items-center">
                               {dateRange && (
                                 <button
                                   onClick={handleDeleteDate}
                                   className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                                 >
                                   <Close
                                     sx={{
                                       fontSize: "15px",
                                     }}
                                   />
                                   Clear All
                                 </button>
                               )}
                             </div>
                             <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
          {loading ? (
            <TableSkeleton />
          ) : (
            <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white">
              <DynamicTable<AssignAssetData>
                data={rowData}
                loading={false}
                columns={columns}
                enableSorting
                enablePagination
              />
            </div>
          )}

          {/* {!loading && rowData?.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )} */}
        </div>
        {!loading && count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        )}

        <Filter
          isDate
          isOther={false}
          heading="Assets Filter"
          selectnameBusiness="Select Business Unit"
          optionsBusiness={bussness}
          onOptionSelectBusiness={handleBusinessUnitSelect}
          selectnameDepartment="Select Department"
          optionsDepartment={department}
          onOptionSelectDepartment={handleDepartmentUnitSelect}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onApply={handleFilterApply}
          selected={selectedDates[1]}
          startDate={selectedDates[0]}
          endDate={selectedDates[1]}
          isCalendarOpen={isCalendarOpen}
          handleCalanderClick={handleCalanderClick}
          handleDateChange={handleDateChange}
        />
      </div>
    </div>
  );
}

export default AssignedAsset;
