import {
  api_key_work as api_key,
  base_api,
  // session_query,
} from "../../../utils/helperData";
import axios from "axios";
import {
  FETCH_EMPLOYEE_EXPENSE_TYPE,
  FETCH_EXPENSE_MONTH_GRAPH,
  FETCH_EXPENSE_STATS,
  FETCH_EXPENSE_TYPE_CHART,
  FETCH_EXPENSE_TYPE_GRAPH,
} from "../api";
import { Dayjs } from "dayjs";
import moment from "moment";
import axiosInstance from "../../../app/api/axiosInstance";

// const session_query = `session_start=${localStorage.getItem(
//   "start_session"
// )}&session_end=${localStorage.getItem("end_session")}`;

export const Fetch_Employee_Expense_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axiosInstance.get(
    `${FETCH_EMPLOYEE_EXPENSE_TYPE}${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_Expense_Stats = ({
  startDate,
  endDate,
  group_id,
  department_id,
}: {
  startDate: Date | null;
  endDate: Date | null;
  group_id: number | null;
  department_id: number | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  return axiosInstance.get(`${FETCH_EXPENSE_STATS}?${session_query}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Expense_Type_Chart = ({
  startDate,
  endDate,
  group_id,
  department_id,
  groupIds
}: {
  startDate?: Date | null;
  endDate?: Date | null;
  group_id?: number | null;
  department_id?: number | null;
  groupIds?: (number | null)[] | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(
    `${FETCH_EXPENSE_TYPE_CHART}?${session_query}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_Expense_Type_Graph = ({
  startDate,
  endDate,
  group_id,
  department_id,
}: {
  startDate: Date | null;
  endDate: Date | null;
  group_id: number | null;
  department_id: number | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  return axiosInstance.get(
    `${FETCH_EXPENSE_TYPE_GRAPH}?${session_query}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_Expense_Monthly_Graph = ({
  startDate,
  endDate,
  group_id,
  department_id,
}: {
  startDate: Date | null;
  endDate: Date | null;
  group_id: number | null;
  department_id: number | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  return axiosInstance.get(
    `${FETCH_EXPENSE_MONTH_GRAPH}?${session_query}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Employee_Expense_List1 = (customer_id: number) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  return axiosInstance.get(`${FETCH_EMPLOYEE_EXPENSE_TYPE}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createEmployeeExpense = async (
  //customerId: number,
  user_id: string | null,
  category_id: string,
  expense_date: string | null | Dayjs,
  expense_amount: string,
  expense_currency: string,
  receipt_name: string[],
  receipt_filename: string[],
  // receipt_file_type:string[],
  description: string
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    //customerId: customerId,
    user_id: user_id,
    category_id: category_id,
    expense_date: expense_date,
    expense_amount: expense_amount,
    expense_currency: expense_currency,
    receipt_name: receipt_name,
    receipt_filename: receipt_filename,
    //receipt_file_type:receipt_file_type,
    description: description,
  };

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/expenses`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditEmployeeExpense = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/expenses/${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeExpense = async (
  id: string,
  category_id: Number,
  expense_date: string | null | Dayjs,
  expense_amount: string,
  expense_currency: string,
  //receipt_name:string[],
  receipt_filename: string[],
  // receipt_file_type:string[],
  description: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    category_id: category_id,
    expense_date: expense_date,
    expense_amount: expense_amount,
    expense_currency: expense_currency,
    // receipt_name:receipt_name,
    receipt_filename: receipt_filename,
    // receipt_file_type:receipt_file_type,
    description: description,
  };

  try {
    const response = await axiosInstance.patch(
      `${base_api}/api/v1/expenses/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/expenses/approve-reject/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
