import {
  EMP_STATS,
  REPORTING_MANAGER,
  SELECT_BUSINESS_UNIT,
  SELECT_DEPARTMENT_UNIT,
  SELECT_DESIGNATION,
  SELECT_EMPLOYEE_LIST,
  SELECT_ROLE,
  TEAM_MANAGER,
  USER_ASSET,
  USER_ATTENDANCE,
  USER_ATTENDANCE_DATA,
  USER_ATTENDANCE_STATUS,
  USER_EXPENSE_STATUS,
  USER_EXPENSE_DATA,
  USER_PROJECTS,
  USER_TASKS,
  USER_EXPENSE_LIST,
  USER_MULTI_ATTENDANCE_DATA,
  FETCH_ATTENDENCE_USER,
  USER_TIMESHEET_LIST,
  PROJECT_TASKS,
  DASHBOARD_EMPLOYEE_COUNT,
  EMPLOYEE_DASHBOARD,
  EMPLOYEE_DEPARTMENT,
  EMPLOYEE_NEW_USERS,
  EMPLOYEE_UPCOMING_BIRTHDAYS,
  EMPLOYEE_UPCOMING_ANNIVERSARIES,
  EMPLOYEE_NOTICE_PERIOD,
  FETCH_BUSINESS_UNIT,
  FETCH_BUSINESS_UNIT_2,
} from "../api";
import {
  api_key_work as api_key,
  base_api,
  // session_query,
} from "../../../utils/helperData";
import axios from "axios";
import { Dayjs } from "dayjs";
import moment from "moment";
import axiosInstance from "../../../app/api/axiosInstance";
import { useCallback } from "react";

// const session_query = `session_start=${localStorage.getItem(
//   "start_session"
// )}&session_end=${localStorage.getItem("end_session")}`;

export const Select_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SELECT_BUSINESS_UNIT}?customer_id=${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Business_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${FETCH_BUSINESS_UNIT_2}?customer_id=${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Dept_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SELECT_DEPARTMENT_UNIT}/${customerId}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Role_Unit = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${SELECT_ROLE}?customer_id=${customerId}&size=100&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Reporting_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(`${TEAM_MANAGER}?department_id=${customerId}&size=200&page=1`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Designation_Manager = (
  customerId: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(
    `${SELECT_DESIGNATION}?customer_id=${customerId}&size=10&page=${page}`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_Attendance_Stats = ({
  id,
  start_date,
  end_date,
}: {
  id: string;
  start_date: Date | null;
  end_date: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(`${EMP_STATS}/${id}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_User_Projects = ({
  id,
  status,
  page,
  size,
  search,
  start_date,
  end_date,
}: {
  id: string;
  status?: string | null;
  page?: number;
  size?: number;
  search?: string;
  start_date?: Date | null;
  end_date?: Date | null;
}) => {
  let query = "";
  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }
  if (status && status !== "") query += `&status=${status}`;
  if (search) query += `&search=${search}`;
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(`${USER_PROJECTS}/${id}?${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Project_Tasks = ({
  id,
  status,
  page,
  size,
  search,
  date,
  project_id,
}: {
  id: string;
  status?: string | null;
  page?: number;
  size?: number;
  search?: string;
  date?: Dayjs | null;
  project_id?: string | number;
}) => {
  let query = `?page=${page}&size=${size}`;

  if (status) query += `&status=${status}`;
  if (search) query += `&search=${search}`;
  if (date) {
    const new_date = date.format("YYYY-MM-DD");
    query += `&date=${new_date}`;
  }
  if (project_id) {
    query += `&project_id=${project_id}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(`${PROJECT_TASKS}/${id}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_User_Tasks = ({
  id,
  status,
  page,
  search,
  start_date,
  end_date,
}: {
  id: string;
  status?: string;
  page?: number;
  search?: string;
  start_date: Date | null;
  end_date?: Date | null;
}) => {
  let query = "";
  if (status) query += `&status=${status}`;
  if (page) query += `&page=${page}`;
  if (search) query += `&search=${search}`;
  if (start_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    // const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(`${USER_TASKS}/${id}?${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_User_Billability_Report = ({
  id,
  start_date,
  end_date,
}: {
  id: string;
  start_date: Date | null;
  end_date: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(
    `${base_api}/api/v1/employee-timesheet/chart/${id}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_Project_Timelog = ({
  id,
  start_date,
  end_date,
}: {
  id: string;
  start_date: Date | null;
  end_date: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(
    `${base_api}/api/v1/employee-timesheet/graph/${id}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Attendance_Status = ({
  id,

  start_date,
  end_date,
}: {
  id: string;

  start_date: Date | null;
  end_date: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(`${USER_ATTENDANCE_STATUS}/${id}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Expense_Status = ({
  id,
  start_date,
  end_date,
}: {
  id: string;
  start_date: Date | null | undefined;
  end_date: Date | null | undefined;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(`${USER_EXPENSE_STATUS}/${id}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Attendance_Data = ({
  id,

  start_date,
  end_date,
}: {
  id: string;

  start_date: Date | null;
  end_date: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(`${USER_ATTENDANCE_DATA}/${id}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_User_Attendance_List = ({
  id,
  customer_id,
  start_date,
  end_date,
  page,
  size,
}: {
  id: string;
  customer_id: number;
  start_date: Date | null;
  end_date: Date | null;
  page: number;
  size: number;
}) => {
  let query = `?customer_id=${customer_id}&user_id=${id}&page=${page}&size=${size}`;
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(`${FETCH_ATTENDENCE_USER}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Expense_Data = ({
  id,
  start_date,
  end_date,
}: {
  id: string;
  start_date?: Date | null;
  end_date?: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(
    `${USER_EXPENSE_DATA}/${id}?${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Timesheet_list = ({
  customer_id,
  user_id,
  page,
  size,
  start_date,
  end_date,
  search,
}: {
  customer_id: number;
  user_id: string;
  page: number;
  size: number;
  start_date: Date | null;
  end_date: Date | null;
  search: string;
}) => {
  let query = `?customer_id=${customer_id}&page=${page}&size=${size}&user_id=${user_id}`;
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (search && search !== "") {
    query += `&search=${search}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(`${USER_TIMESHEET_LIST}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_User_Attendance = ({
  customer_id,
  id,
  date,
  numOfItems,
  page,
  searchTerm,
  startDate,
  endDate,
}: {
  customer_id: number;
  id: string;
  date?: string | null;
  numOfItems?: number;
  page?: number;
  searchTerm?: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  let query = `?customer_id=${customer_id}`;

  if (searchTerm !== "" && searchTerm) {
    query += `&search=${searchTerm}`;
  }
  if (numOfItems) {
    query += `&size=${numOfItems}`;
  }
  if (page) {
    query += `&page=${page}`;
  }

  if (date) {
    query += `&date=${date}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(`${USER_ATTENDANCE}/${id}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_User_Attendance_Multi_List = ({
  customer_id,
  id,
  date,
  numOfItems,
  page,
  searchTerm,
}: {
  customer_id: number;
  id: string | undefined;
  date: string | undefined;
  numOfItems: number;
  page: number;
  searchTerm?: string;
}) => {
  let query = `&customer_id=${customer_id}&size=${numOfItems}&page=${page}&date=${date}`;

  if (searchTerm !== "" && searchTerm) {
    query += `&search=${searchTerm}`;
  }

  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(
    `${USER_MULTI_ATTENDANCE_DATA}/${id}?${session_query}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_User_Asset = ({
  customer_id,
  id,
  date,
  numOfItems,
  page,
  searchTerm,
  startDate,
  endDate,
}: {
  customer_id: number;
  id: string;
  date?: string | null;
  numOfItems?: number;
  page?: number;
  searchTerm?: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  let query = `&customer_id=${customer_id}&user_id=${id}`;

  if (searchTerm !== "" && searchTerm) {
    query += `&search=${searchTerm}`;
  }
  if (numOfItems) {
    query += `&size=${numOfItems}`;
  }
  if (page) {
    query += `&page=${page}`;
  }

  if (date) {
    query += `&date=${date}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  return axiosInstance.get(`${USER_ASSET}?${session_query}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Select_Team_Manager = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  return fetch(
    `${REPORTING_MANAGER}?customer_id=${customerId}&size=200&page=1`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Employee_Management_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  searchTerm: string,
  modeOfEmp: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (modeOfEmp && modeOfEmp !== "") {
    query += `&mode_of_employment=${modeOfEmp}`;
  }

  return axiosInstance.get(`${SELECT_EMPLOYEE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchQuery: string
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  if (searchQuery !== "") {
    query += `&search=${searchQuery}`;
  }

  return axiosInstance.get(`${SELECT_EMPLOYEE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createEmployee = async (
  employee_code: string,
  employee_id: string,
  email: string,
  phone: string,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  country_code: string,
  group_id: Number,
  customerId: number,
  role_id: Number,
  department_id: Number,
  designation_id: Number,
  team_manager: Number | null,
  reporting_manager: Number,
  status: string,
  mode_of_employment: string,
  experience: Number,
  experience_months: Number,
  date_of_birth: string | null | Dayjs,
  date_of_joining: string | null | Dayjs,
  current_address: string,
  current_coordinates: [number | null, number | null],
  permanent_address: string,
  permanent_coordinates: [number | null, number | null],
  alloted_wfh_per_week: number | null,
  time_zone_id: number | null,
  shift_start_time: string | null,
  shift_end_time: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    // employee_code: employee_code,
    // employee_id: employee_id,
    email: email,
    phone: phone,
    // prefix: prefix,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    country_code: country_code,
    group_id: group_id,
    customer_id: customerId,
    role_id: role_id,
    department_id: department_id,
    designation_id: designation_id,
    // team_manager: team_manager,
    reporting_manager: reporting_manager,
    status: status,
    mode_of_employment: mode_of_employment,
    experience: experience,
    experience_months: experience_months,
    // date_of_birth: date_of_birth,
    date_of_joining: date_of_joining,
    current_address: current_address,
    current_coordinates: current_coordinates,
    permanent_address: permanent_address,
    permanent_coordinates: permanent_coordinates,
    alloted_wfh_per_week: alloted_wfh_per_week,
  };
  if (time_zone_id) {
    data.time_zone_id = time_zone_id;
  }
  if (shift_start_time && shift_end_time) {
    data.shift_start_time = shift_start_time;
    data.shift_end_time = shift_end_time;
  }
  if (employee_code !== "") data.employee_code = employee_code;
  if (employee_id !== "") data.employee_id = employee_id;
  if (prefix !== "") data.prefix = prefix;
  // if (phone !== "") data.phone = phone;
  // if (country_code !== "") data.country_code = country_code;
  if (date_of_birth && date_of_birth !== "Invalid Date") {
    data.date_of_birth = date_of_birth;
  } else {
    data.date_of_birth = null;
  }
  if (team_manager) data.team_manager = team_manager;

  try {
    const response = await axiosInstance.post(`${base_api}/api/v1/user`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditEmployee = async (id: string) => {
  console.log("object");
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axiosInstance.get(`${base_api}/api/v1/user/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployee = async (
  id: string,
  employee_code: string,
  employee_id: string,
  email: string,
  phone: string,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  country_code: string,
  group_id: Number,
  customerId: number,
  role_id: Number,
  department_id: Number,
  designation_id: Number,
  team_manager: Number | null,
  reporting_manager: Number,
  status: string,
  mode_of_employment: string,
  experience: Number,
  experience_months: Number,
  date_of_birth: string | null | Dayjs,
  date_of_joining: string | null | Dayjs,
  current_address: string,
  current_coordinates: [number | null, number | null],
  permanent_address: string,
  permanent_coordinates: [number | null, number | null],
  allottedWfh: number | null,
  time_zone_id: number | null,
  shift_start_time: string | null,
  shift_end_time: string | null,
  date_of_leaving?: string | null | Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  const body: any = {
    // employee_code: employee_code,
    // employee_id: employee_id,
    email: email,
    phone: phone,
    prefix: prefix,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    country_code: country_code,
    group_id: group_id,
    customer_id: customerId,
    role_id: role_id,
    department_id: department_id,
    designation_id: designation_id,
    reporting_manager: reporting_manager,
    team_manager: team_manager || null,
    status: status,
    mode_of_employment: mode_of_employment,
    experience: experience,
    experience_months: experience_months,
    date_of_birth: date_of_birth || null,
    date_of_joining: date_of_joining,
    current_address: current_address,
    current_coordinates: current_coordinates,
    permanent_address: permanent_address,
    permanent_coordinates: permanent_coordinates,
    alloted_wfh_per_week: allottedWfh,
  };
  if (time_zone_id) {
    body.time_zone_id = time_zone_id;
  }
  if (shift_start_time && shift_end_time) {
    body.shift_start_time = shift_start_time;
    body.shift_end_time = shift_end_time;
  }
  if (date_of_leaving && date_of_leaving !== "Invalid date")
    body.date_of_leaving = date_of_leaving;
  // if (date_of_birth && date_of_birth !== "Invalid date") body.date_of_birth = date_of_birth;
  if (employee_code && employee_code !== "") body.employee_code = employee_code;
  if (employee_id && employee_id !== "") body.employee_id = employee_id;
  // if(team_manager !== null) body.team_manager = team_manager;
  // if (team_manager) body.team_manager = team_manager;
  // if (!team_manager) body.team_manager = reporting_manager;

  // if (team_manager || team_manager!==null || team_manager !== "") {
  //   body.team_manager = team_manager;
  // } else {
  //   body.team_manager = null;
  // }

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/user/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Employee_Timelog_Overview = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/employee-timesheet/graph/${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Expense_List = ({
  id,
  customer_id,
  search,
  page,
  size,
  start_date,
  end_date,
}: {
  id: string;
  customer_id: string | number;
  search?: string;
  page?: number;
  size?: number;
  start_date?: Date | null;
  end_date?: Date | null;
}) => {
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  if (search && search !== "") query += `&search=${search}`;
  if (page && size) query += `&page=${page}&size=${size}`;
  const access_token = localStorage.getItem("access_token");
  return axiosInstance.get(
    `${USER_EXPENSE_LIST}?customer_id=${customer_id}&user_id=${id}${query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
export const Fetch_User_Leave_Data = async (
  id: string,
  start_date?: Date | null,
  end_date?: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/leave-balance-details?user_id=${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const Fetch_User_Expense_Chart = async (
  id: string,
  start_date?: Date | string | null,
  end_date?: Date | string | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `?start_date=${start}&end_date=${end}`;
  }
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/expense/user-chart/${id}${query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchTimeZonesList = async () => {
  const access_token = localStorage.getItem("access_token");

  try {
    const response = await axiosInstance.get(`${base_api}/api/v1/timezones`, {
      headers: {
        "x-api-key": api_key,
        Authorization: `Bearer ${access_token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching time zones:", error);
    throw error; // Re-throwing to allow calling function to handle it
  }
};
export const Fetch_Employee_Count = ({
  group_id,
  groupIds,
}: {
  group_id?: number;
  groupIds?: (number | null)[] | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(`${DASHBOARD_EMPLOYEE_COUNT}?${query}${session_query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
  });
};

export const Fetch_Employee = async (group_id: number) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = "";

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  try {
    const response = await axiosInstance.get(`${EMPLOYEE_DASHBOARD}?${query}${session_query}`, {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Employee_Department = async ({
  group_id,
  groupIds,
}: {
  group_id?: number;
  groupIds?: (number | null)[] | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  try {
    const response = await axiosInstance.get(
      `${EMPLOYEE_DEPARTMENT}?${query}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Employee_newUsers = (group_id: number) => {
  const access_token = localStorage.getItem("access_token");

  let query = "";

  if (group_id) {
    query += `&group_id=${group_id}`;
  }
  return axiosInstance.get(`${EMPLOYEE_NEW_USERS}?${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Upcoming_Birthday = (
  group_id: number,
  page: number,
  size: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }
  return axiosInstance.get(`${EMPLOYEE_UPCOMING_BIRTHDAYS}?${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Upcoming_Anniversaries = (
  group_id: number,
  page: number,
  size: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (group_id) {
    query += `&group_id=${group_id}`;
  }
  if (page && size) {
    query += `&page=${page}&size=${size}`;
  }

  return axiosInstance.get(`${EMPLOYEE_UPCOMING_ANNIVERSARIES}?${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Notice = (group_id: number) => {
  const access_token = localStorage.getItem("access_token");
  let query ="";

  if (group_id) {
    query += `&group_id=${group_id}`;
  }
  return axiosInstance.get(`${EMPLOYEE_NOTICE_PERIOD}?${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};