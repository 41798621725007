import React, { useState } from "react";
import { IResource, Milestone } from "../AddProject/AddProjectDetails";
import DocumentCard from "../../../common/shared/DocumentCard";
import { ApiResponse } from "./ViewProject";
import ModalViewer from "../../../ExpenseManagement/ModalViewer";

interface EmployeeData {
  project_name: string;
  currency: string;
  status: string;
  Client: string;
  estimated_cost: string;
  actual_cost: string;
  start_date: string;
  end_date: string;
  contractual_hours: number | null;
  contractual_cost: number | null;
  actual_hours: string;
  project_type: string;
  approval_code: string;
  description: string;
  type: string;
  milestone: Milestone[];
  resource: IResource[];
}

interface HtmlContentProps {
  htmlString: string | undefined;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ htmlString }) => {
  const trimmedHtml = htmlString?.trim() || "-";
  return <div dangerouslySetInnerHTML={{ __html: trimmedHtml }} />;
};

interface SummaryProps {
  employeeData: EmployeeData;
  receipts?: ApiResponse["rows"];
}

const Summary: React.FC<SummaryProps> = ({ employeeData, receipts }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  const downloadFile = (url: string, filename: string) => {
    try {
      const link = document.createElement("a");
      link.href = url;

      const cleanFilename = filename.replace(/[^a-zA-Z0-9.\-_]/g, "_");
      link.download = cleanFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  const formatCurrency = (value: number | null, currency: string) => {
    if (value === null || value === undefined) return "-";
    
    const locales: Record<string, string> = {
      INR: "en-IN",
      USD: "en-US",
      EUR: "de-DE",
      GBP: "en-GB",
      AED: "en-AE", 
      JPY: "ja-JP",
      CNY: "zh-CN",
      AUD: "en-AU",
      CAD: "en-CA",
    };
  
    return new Intl.NumberFormat(locales[currency] || "en-US").format(value);
  };

  return (
    <div className="border shadow-sm rounded-md p-4 mt-3 h-full overflow-auto">
      <div className="grid grid-cols-4 p-2 gap-4">
        <p>
          <div className="text-gray-600 font-['Poppins'] font-[500] text-[16px] ">
            Project Name
          </div>{" "}
          <div className="text-[#1D1A22] font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {employeeData.project_name ?? "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] text-[16px] ">
            Client Name
          </div>{" "}
          <div className="text-[#1D1A22] font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {employeeData.Client ?? "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] text-[16px] ">
            Currency
          </div>

          <div className="text-[#1D1A22] font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {" "}
            {employeeData.currency ?? "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500]  text-[16px] ">
            Project Type
          </div>

          <div className="text-[#1D1A22] font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {" "}
            {employeeData.type
              ? employeeData.type === "Fix Cost"
                ? "Fixed Cost"
                : employeeData.type
              : "-"}
          </div>
        </p>
      </div>
      <div className="grid grid-cols-4 p-2 gap-4">
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            Contractual Hours
          </div>{" "}
          <div className="font-[500] mt-2 font-['Poppins'] text-[16px] ">
            {employeeData?.contractual_hours || "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            Contractual Cost
          </div>{" "}
          <div className="font-[500] mt-2 font-['Poppins'] text-[16px] ">
            {/* {employeeData?.contractual_cost || "-"} */}
            {formatCurrency(employeeData?.contractual_cost, employeeData?.currency)}

          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            Billing Type
          </div>{" "}
          <div className="text-[#1D1A22] font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {employeeData.project_type ?? "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            Start Date
          </div>{" "}
          <div className="text-[#1D1A22] font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {employeeData?.start_date
              ? new Date(employeeData?.start_date).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </div>
        </p>
      </div>
      <div className="grid grid-cols-4 p-2 gap-4">
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            End Date
          </div>{" "}
          <div className="font-[500] mt-2 font-['Poppins'] text-[16px] ">
            {employeeData?.end_date
              ? new Date(employeeData?.end_date).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            Status
          </div>{" "}
          <div className="font-[500] mt-2 font-['Poppins'] text-[16px] ">
            {employeeData?.status
              ? employeeData.status.charAt(0).toUpperCase() +
                employeeData.status.slice(1)
              : "-"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            No. Of Milestones
          </div>{" "}
          <div className="font-[500] mt-2  font-['Poppins'] text-[16px] ">
            {employeeData?.milestone?.length
              ? employeeData?.milestone?.length
              : "0"}
          </div>
        </p>
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            No. Of Resources
          </div>{" "}
          <div className="font-[500] mt-2 font-['Poppins'] text-[16px] ">
            {employeeData?.resource?.length
              ? employeeData?.resource?.length
              : "0"}
          </div>
        </p>
      </div>

      <div className="grid grid-cols-1 p-2 gap-4">
        <p>
          <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] ">
            Description
          </div>{" "}
          <div className="font-['Poppins'] font-[500] mt-2 text-[16px] ">
            {/* {employeeData?.description ?? "-"} */}
            <HtmlContent htmlString={employeeData?.description} />
          </div>
        </p>
      </div>

      <div className="mt-4 p-1">
        <div className=" text-gray-600 font-['Poppins'] font-[500] mt-2 text-[16px] mb-4">
          Attachments
        </div>
        <div className="grid grid-cols-3 gap-4">
          {receipts?.map((receipt) =>
            receipt?.project_document?.map((doc, i) => {
              return (
                <DocumentCard
                  key={i}
                  fileUrl={doc}
                  onView={() => handleView(doc)}
                  onDownload={() =>
                    downloadFile(
                      doc,
                      doc?.split("/")?.pop() || `Receipt ${i + 1}`
                    )
                  }
                />
              );
            })
          )}

          {selectedFileUrl && (
            <ModalViewer
              isOpen={!!selectedFileUrl}
              onRequestClose={closeModal}
              fileUrl={selectedFileUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
