import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldWithPopup from "../TextFeildPopup";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  createTaskApi,
  UploadTaskFileApi,
} from "../../common/services/Project";
import MyStatefulEditor from "../Editor";
import FileUpload from "../../ExpenseManagement/FileUpload";
import { X } from "lucide-react";
import AutomationFileUpload from "../AutomationFileUpload";
import { Fetch_Milestone_List } from "../../common/services/Project";
import { MilestoneOption } from "./AddProject/AddProjectDetails";
import { useSelector } from "react-redux";
import { Button } from "../../common/Button";

interface FormValues {
  task_name: string;
  estimated_hours: number | string | null;
  actual_hours: number | null;
  status: string;
  module_name: string;
  milestone_id: number | null;
}
interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}
export default function AddTaskDialog({
  show,
  setShow,
  fetchData,
  module,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: () => Promise<void>;
  module?: number | null;
}) {
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const projectStartDate = useSelector(
    (state: any) => state.projectSlice2.project_startDate
  );
  const projectEndDate = useSelector(
    (state: any) => state.projectSlice2.project_endDate
  );
  const [editorContent, setEditorContent] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [autoFiles, setAutoFiles] = useState<UploadedFile[]>([]);
  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  const handleAutoFilesChange = (files: UploadedFile[]) => {
    setAutoFiles(files);
  };
  const [hasresource, setHasResource] = useState(false);
  const navigate = useNavigate();
  const [dateError, setDateError] = useState(false);
  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; project_id: number }[]
  >([]);
  // const param = useParams();
  const [enddateError, setEndDateError] = useState(false);
  const [milestoneOptions, setMilestoneOptions] = useState<MilestoneOption[]>(
    []
  );

  const handleSelectedEmployeesChange = (ids: number[]) => {
    if (!projectId) {
      console.error("Project ID is undefined");
      return;
    }

    if (ids.length > 0) {
      const newResources = ids.map((id) => ({
        user_id: id,
        project_id: projectId,
      }));
      setProjectResources(newResources);
    } else {
      setProjectResources([]);
    }
  };

  const Status = [
    { value: "inprogress", label: "Working" },
    { value: "todo", label: "To do" },
    { value: "completed", label: "Finished" },
    { value: "onhold", label: "Hold" },
  ];

  const [formValues, setFormValues] = useState<FormValues>({
    task_name: "",
    estimated_hours: null,
    actual_hours: null,
    status: "",
    module_name: "",
    milestone_id: null,
  });

  const downloadFile = () => {
    try {
      const link = document.createElement("a");
      link.href =
        "http://148.113.6.50:9004/go-employee/1737027380402-297232990.xlsx";
      const filename = "sample_task_format";
      const cleanFilename = filename.replace(/[^a-zA-Z0-9.\-_]/g, "_");
      link.download = cleanFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [disabelAll, setDisableAll] = useState(false);

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.task_name === "")
      tempErrors.task_name = "Task Name is required";
    if (formValues.status === "") tempErrors.status = "Task Status is required";
    // if (formValues?.estimated_hours === 0 || !formValues?.estimated_hours)
    //   tempErrors.estimated_hours = "Estimated Hours are required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleFrormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "estimated_hours") {
      setFormValues({
        ...formValues,
        [name]: value ? Number(value) : null,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };
  // console.log({ autoFiles });
  useEffect(() => {
    if (autoFiles?.length !== 0) {
      setFormValues({
        task_name: "",
        estimated_hours: null,
        actual_hours: null,
        status: "",
        module_name: "",
        milestone_id: null,
      });
      setDateError(false);
      setStartData(null);
      setEditorContent("");
      setUploadedFiles([]);
      setProjectResources([]);
      setDisableAll(true);
      setErrors({});
    } else {
      setDisableAll(false);
    }
  }, [autoFiles]);
  const handleSave = () => {
    if (autoFiles?.length > 0) {
      uploadFile();
    } else {
      !startDate && setDateError(true);
      if (!startDate) {
        setDateError(true);
        return;
      }
      if (endDate && endDate?.isBefore(startDate)) {
        setEndDateError(true);
        return;
      }
      const res = validate();
      if (startDate && res && endDate) {
        postData();
      } else {
        toast.error("Please fill all required details.");
      }
    }
  };
  const uploadFile = async () => {
    try {
      const response = await UploadTaskFileApi({
        docs: autoFiles?.map((file) => file?.url),
        projectId: projectId,
      });
      if (response?.data?.result?.error) {
        toast.error(response?.data?.message);
      }
      setShow(false);
      fetchData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const postData = async () => {
    // setLoading(true);
    try {
      const response = await createTaskApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        //projectResources
        formValues?.task_name,
        projectId,
        hasresource ? Number(formValues?.estimated_hours) : null,
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        dayjs(endDate).format("YYYY-MM-DD"),
        editorContent?.replace(/<[^>]+>/g, ""),
        formValues?.status,
        uploadedFiles?.map((files) => files?.url),
        projectResources,
        formValues?.module_name,
        formValues?.milestone_id
      );

      setFormValues({
        task_name: "",
        estimated_hours: null,
        actual_hours: null,
        status: "",
        module_name: "",
        milestone_id: null,
      });
      setDateError(false);
      setStartData(null);
      setEditorContent("");
      setUploadedFiles([]);
      setProjectResources([]);
      setHasResource(false);
      setDisableAll(false);
      setErrors({});
      setShow(false);
      fetchData();
      //   setTimeout(() => {
      //     navigate("/project-management");
      //     //  setOpen(false);
      //     //navigate(`${ROLE_MANAGEMENT}`);
      //     // navigate("/add-task")
      //     setShow(false);
      //   }, 1000);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
    //  setLoading(false);
  };

  const fetchMilestoneOptions = async () => {
    try {
      const response = await Fetch_Milestone_List({
        customer_id: projectId,
        // numOfItems: 10,
        // page: 1,
      });
      if (response.status === 200) {
        setMilestoneOptions(
          response?.data?.result?.rows?.map((milestone: any) => ({
            id: Number(milestone?.id),
            name: milestone?.milestone_name,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching milestone options:", error);
    }
  };

  useEffect(() => {
    fetchMilestoneOptions();
  }, [projectId]);

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          height: "90vh",
          overflow: "hidden",
        },
      }}
      open={show}
      onClose={() => {
        setFormValues({
          task_name: "",
          estimated_hours: null,
          actual_hours: null,
          status: "",
          module_name: "",
          milestone_id: null,
        });
        setDateError(false);
        setStartData(null);
        setEditorContent("");
        setUploadedFiles([]);
        setProjectResources([]);
        setHasResource(false);
        setAutoFiles([]);
        setShow(false);
        setErrors({});
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle display={"flex"} justifyContent={"space-between"}>
        <div className="text-xl font-bold pt-1"> Add Task Details</div>
        <X
          onClick={() => {
            setFormValues({
              task_name: "",
              estimated_hours: null,
              actual_hours: null,
              status: "",
              module_name: "",
              milestone_id: null,
            });
            setDateError(false);
            setStartData(null);
            setHasResource(false);
            setEndDate(null);
            setEditorContent("");
            setUploadedFiles([]);
            setProjectResources([]);
            setAutoFiles([]);
            setShow(false);
            setErrors({});
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div className="p-6">
          <div className="w-full flex justify-between items-center bg-[#F1F5F9] rounded-md h-18 p-3 gap-2.5 border-2 border-gray-200">
            <div className="flex flex-col gap-1">
              <p className="excel-title h-6 font-[Inter] leading-6 font-semibold text-base text-gray-900">
                Excel Template
              </p>
              <p className="excel-description h-5 font-[Poppins] font-normal leading-5 text-gray-700">
                Download the excel template to ensure the correct format
              </p>
            </div>
            <button
              onClick={downloadFile}
              className="bg-white font-[Poppins] leading-5 font-medium text-gray-800 h-9 py-2 px-4 gap-2 rounded-lg"
            >
              Download
            </button>
          </div>
        </div>
        <div className="p-4 pt-2">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <AutomationFileUpload
              text="Only Excel Files"
              onFilesChange={handleAutoFilesChange}
              accept={{
                "application/vnd.ms-excel": [".xls"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
                "text/csv": [".csv"],
              }}
              isMultiple={false}
              height="100%"
              width="100%"
              project_id={projectId}
            />
            <hr className="my-2" />
            <div className="grid grid-cols-3 gap-3 ">
              <TextField
                label="Milestone / Sprint"
                select
                name="milestone_id"
                value={
                  formValues?.milestone_id
                    ? String(formValues.milestone_id)
                    : ""
                }
                // onChange={handleFrormChange}
                onChange={handleFrormChange}
              >
                {milestoneOptions?.length > 0 ? (
                  milestoneOptions?.map((option) => (
                    <MenuItem key={option?.id} value={option?.id}>
                      {option?.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </TextField>
              <TextField
                id="outlined-required"
                label="Module Name"
                disabled={disabelAll}
                placeholder="Enter Module Name"
                name="module_name"
                value={formValues?.module_name}
                onChange={handleFrormChange}
              />
              <TextField
                required
                id="outlined-required"
                label="Task Name"
                disabled={disabelAll}
                placeholder="Enter Task Name"
                name="task_name"
                value={formValues?.task_name}
                onChange={handleFrormChange}
                error={!!errors.task_name}
                helperText={errors.task_name}
              />
              {hasresource && (
                <TextField
                  id="outlined-required"
                  label="Estimate Hours"
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  disabled={disabelAll}
                  placeholder="Enter Estimated Hours"
                  name="estimated_hours"
                  value={formValues?.estimated_hours}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    if (value?.length <= 4) {
                      setFormValues({
                        ...formValues,
                        [name]: value,
                      });

                      if (value !== "") {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          [name]: "",
                        }));
                      }
                    }
                  }}
                  // error={!!errors.estimated_hours}
                  // helperText={errors.estimated_hours}
                />
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date *"
                  value={startDate}
                  disabled={disabelAll}
                  format="DD/MM/YYYY"
                  onChange={(newValue) => {
                    setStartData(newValue);
                    setDateError(false);
                    if (newValue?.isAfter(endDate)) {
                      setEndDate(null);
                    }
                  }}
                  minDate={dayjs(projectStartDate)}
                  maxDate={dayjs(projectEndDate)}
                  // maxDate={dayjs(projectEndDate)}
                  slotProps={{
                    textField: {
                      error: dateError,
                      helperText: dateError ? "Please select a start date" : "",
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date *"
                  format="DD/MM/YYYY"
                  value={endDate}
                  disabled={disabelAll}
                  minDate={startDate}
                  maxDate={dayjs(projectEndDate)}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                    setEndDateError(false);
                  }}
                  slotProps={{
                    textField: {
                      error: enddateError,
                      helperText: enddateError
                        ? "End Date is required."
                        : "",
                      inputProps: { readOnly: true },
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                select
                label="Status"
                placeholder="Select Status"
                name="status"
                disabled={disabelAll}
                value={formValues?.status}
                onChange={handleFrormChange}
                error={!!errors.status}
                helperText={errors.status}
              >
                {Status?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="grid grid-cols-1">
              <TextFieldWithPopup
                onSelectedEmployeeChange={handleSelectedEmployeesChange}
                disable={disabelAll}
                setHasResource={setHasResource}
              />
            </div>

            <div className="mt-3">
              <MyStatefulEditor
                onChange={setEditorContent}
                disable={disabelAll}
              />
            </div>
            <div className="mt-3">
              <span className="font-[Poppins]">Attachments</span>
              <FileUpload
                onFilesChange={handleFilesChange}
                disable={disabelAll}
              />
            </div>
          </Box>
        </div>
        {/* <div className="flex gap-2 w-full justify-end mb-2">
          <button
            onClick={() => {
              setFormValues({
                task_name: "",
                estimated_hours: null,
                actual_hours: null,
                status: "",
                module_name: "",
                milestone_id: null,
              });
              setDateError(false);
              setStartData(null);
              setEndDate(null);
              setEditorContent("");
              setUploadedFiles([]);
              setProjectResources([]);
              setAutoFiles([]);
              setShow(false);
              setErrors({});
            }}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            onClick={() => {
              handleSave();
              setFormValues({
                task_name: "",
                estimated_hours: null,
                actual_hours: null,
                status: "",
                module_name: "",
                milestone_id: null,
              });
              setDateError(false);
              setStartData(null);
              setEndDate(null);
              setEditorContent("");
              setUploadedFiles([]);
              setProjectResources([]);
              setAutoFiles([]);
              setShow(false);
              setErrors({});
              fetchData();
            }}
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            // onClick={() => handleSave()}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div> */}
      </DialogContent>
      <div className="sticky bottom-0 bg-white z-10 flex gap-2 w-full justify-end px-2 pr-10 pb-4">
        <button
          onClick={() => {
            setFormValues({
              task_name: "",
              estimated_hours: null,
              actual_hours: null,
              status: "",
              module_name: "",
              milestone_id: null,
            });
            setDateError(false);
            setStartData(null);
            setEndDate(null);
            setEditorContent("");
            setUploadedFiles([]);
            setProjectResources([]);
            setAutoFiles([]);
            setShow(false);
            setErrors({});
          }}
          className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
          >
          <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
            Cancel
          </div>
        </button>

        <Button
          onClick={() => {
            handleSave();
          }}
          variant={"gradient"}
        >
           <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
        </Button>
      </div>
    </Dialog>
  );
}
