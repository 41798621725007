import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import right from "../../image/chevron-right.png";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createExitEmployee } from "../common/services/ExitEmployee";
import toast from "react-hot-toast";
import EmployeeSearch from "./EmployeeSearch";
import { Button } from "../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormSelectValues {
  empId: string;
  name: string;
  department: string;
  designation: string;
  reason: string;
}

const AddExitProcedure = () => {
  const [initiationDate, setInitiationDate] = React.useState<Dayjs | null>(
    null
  );
  const [lastWorking, setLastWorking] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);

  const [isDOE, setIsDOE] = useState<boolean | null>(false);
  const [isDOEDisplay, setIsDOEDisplay] = useState<boolean | null>(false);
  const [isLastDay, setIsLastday] = useState<boolean | null>(false);
  const [isLastDayDisplay, setIsLastDayDisplay] = useState<boolean | null>(
    false
  );

  const handleOpen = () => {
    // if (validate()) {
    //   postData();
    //   // setOpen(true);
    // }
    //setOpen(true);
    !isDOE && setIsDOEDisplay(true);
    !isLastDay && setIsLastDayDisplay(true);
    try {
      validate();
      postData();
    } catch (error) {
      alert(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    // navigate("/add-exit");
    navigate(-1);
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    // navigate("/exit-procedure");
    navigate(-1);
  };

  const [formSelectValues, setFormSelectValues] = useState<FormSelectValues>({
    empId: "",
    name: "",
    department: "",
    designation: "",
    reason: "",
  });

  const [errors, setErrors] = useState<Partial<FormSelectValues>>({});

  const handleSelectFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFormSelectValues({
      ...formSelectValues,
      [name]: value,
    });

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validate = (): boolean => {
    let tempErrors: Partial<FormSelectValues> = {};
    if (formSelectValues.department === "")
      tempErrors.department = "Department Unit is required";
    if (formSelectValues.designation === "")
      tempErrors.designation = "Designation Id is required";
    if (!formSelectValues.empId.trim())
      tempErrors.empId = "Employee Id is required";
    if (!formSelectValues.name.trim())
      tempErrors.name = "Full Name is required";
    if (!formSelectValues.reason.trim())
      tempErrors.reason = "Reason is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const postData = async () => {
    try {
      await createExitEmployee(
        // customer_id,
        //formSelectValues.empId,
        selectedEmployeeId,
        formSelectValues.reason,
        initiationDate,
        lastWorking
      );
      // console.log(response,"respost--->>>");

      // setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        // navigate("/exit-procedure");
        navigate(-1);
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    //setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  const yesterday = dayjs().subtract(1, "day");

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="flex justify-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Initiate Exit procedure
          </Typography>
          <Typography
            className="flex justify-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to proceed
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <div className="flex flex-start gap-5">
              <button
                onClick={handleClose}
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
                //onClick={(event) => handleCurrentPageChange("table", event)}
              >
                <div
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
                  Cancel
                </div>
              </button>
              <Button
                type="submit"
                form="form"
                variant={"gradient"} //   onClick={() => handleCurrentPageChange("addNew")}
              >
                <div
                  onClick={handleCancel}
                  className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
                >
                  Yes Sure
                </div>
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
      <div>
        <div className="bg-[#F9F9F9] px-4 pt-6 pb-12 min-h-[90vh]">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => navigate(-1)}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Initiate Exit Procedure Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <button
                onClick={handleCancel}
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
                //onClick={(event) => handleCurrentPageChange("table", event)}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <Button
                type="submit"
                form="form"
                onClick={handleOpen}
                variant={"gradient"} //   onClick={() => handleCurrentPageChange("addNew")}
              >
                <div
                              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
                // onClick={handleOpen}
                >
                  Proceed
                </div>
              </Button>
            </div>
          </div>

          <div className="rounded overflow-hidden shadow-custom bg-white mt-10">
            <div className="container mx-auto p-4">
              <div className="text-[#262626] font-[500] text-[14px] ml-2 font-['Poppins']">
                Procedure details
              </div>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
              >
                <div className="grid grid-cols-3 mt-6 gap-4">
                  {/* <TextField
                    required
                    id="outlined-required"
                    label="Employee Id"
                    name="empId"
                    defaultValue=" "
                    value={formSelectValues.empId}
                    onChange={handleSelectFormChange}
                    error={!!errors.empId}
                    helperText={errors.empId}
                  /> */}
                  <EmployeeSearch
                    onEmployeeSelect={handleEmployeeSelect}
                    onEmployeeSelectId={handleEmployeeSelectId}
                  />
                  {/* <TextField
                    required
                    id="outlined-required"
                    label="Name"
                    name="name"
                    defaultValue=" "
                    value={formSelectValues.name}
                    onChange={handleSelectFormChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Department"
                    name="department"
                    defaultValue="hello"
                    value={formSelectValues.department}
                    onChange={handleSelectFormChange}
                    error={!!errors.department}
                    helperText={errors.department}
                  >
                    {department?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Designation"
                    name="designation"
                    defaultValue="hello"
                    value={formSelectValues.designation}
                    onChange={handleSelectFormChange}
                    error={!!errors.designation}
                    helperText={errors.designation}
                  >
                    {designation?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label="Date Of Joining"
                        value={joiningDate}
                        onChange={(newValue) => setJoiningDate(newValue)}
                        className="w-full"
                      />
                    </DemoContainer>
                  </LocalizationProvider> */}
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date Of Exit Initiate"
                        value={initiationDate}
                        onChange={(newValue) => {
                          setInitiationDate(newValue);
                          setIsLastday(true);
                          setIsLastDayDisplay(false);
                        }}
                        className="w-full"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isLastDayDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isLastDayDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isLastDayDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isLastDayDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isLastDayDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isLastDayDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        Date of Exit Initiation is required
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Last Working Day"
                        value={lastWorking}
                        onChange={(newValue) => {
                          setLastWorking(newValue);
                          setIsDOE(true);
                          setIsDOEDisplay(false);
                        }}
                        className="w-full"
                        defaultValue={yesterday}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isDOEDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isDOEDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isDOEDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isDOEDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isDOEDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                      {/* <DemoItem label="DatePicker">
                        <DatePicker
                        // defaultValue={}
                        disablePast
                          views={["year", "month", "day"]}
                        />
                      </DemoItem> */}
                    </LocalizationProvider>
                    {isDOEDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        Date of Exit Initiation is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 mt-6 gap-4">
                  <TextField
                    required
                    id="outlined-required"
                    label="Reason"
                    name="reason"
                    multiline
                    rows="3"
                    defaultValue="hello"
                    value={formSelectValues.reason}
                    onChange={handleSelectFormChange}
                    error={!!errors.reason}
                    helperText={errors.reason}
                  ></TextField>
                </div>

                {/* <div className="w-[100%]">
             <TextField
                  required
                  id="outlined-required"
                  label="Employee Id"
                  defaultValue="Type Here"
                />
             </div> */}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExitProcedure;
