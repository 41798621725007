import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Business_Unit,
  Department_Unit,
  IPolicyList,
  IPolicyReceiveList,
} from "../common/interfaces/CompanyManagement";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { ColumnDef } from "@tanstack/react-table";
import Search from "../SearchBar/Search";
import { Close } from "@mui/icons-material";
import filter from "../../assets/filter.svg";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../common/Button";
import {
  Department_Unit as Dep_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import DynamicTable from "../common/DynamicTable";
import TablePaginationDemo from "../common/Pagenation";
import Filter from "../Filter/Filter";
import { fetchPolicyRecievers } from "../common/services/companyManagement";
import moment from "moment";
import right from "../../image/chevron-right.png";

export default function PolicyRecieveList() {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit | undefined
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit | undefined
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit | undefined
  >();
  const location = useLocation();
  const id = location.state?.id;
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IPolicyReceiveList[] | []>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState<boolean | null>(null);

  let customer_id = 0;
  let access_token = "";

  const dispatch = useDispatch();

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100),
    []
  );

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }
  const fetchLeaveType = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchPolicyRecievers({
        numOfItems: numOfItems,
        page: page,
        search: searchTerm,
        id: id,
        status: status,
        group_id: Number(selectedBusinessUnit?.id),
        department_id: Number(selectedDepartmentUnit?.id),
      });

      if (response.status === 200) {
        setTableData(response?.data?.result?.rows);

        setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  }, [
    id,
    numOfItems,
    page,
    searchTerm,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    status,
  ]);

  useEffect(() => {
    fetchLeaveType();
  }, [fetchLeaveType]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const columns: ColumnDef<IPolicyReceiveList>[] = [
    {
      accessorKey: "name",
      accessorFn: (row) => `${row.User?.firstName} ${row.User?.lastName}`,
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const data = row?.original;
        return (
          <div className="flex flex-col justify-between">
            <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
              {data?.User?.firstName} {data?.User?.lastName}
            </span>
            <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
              {data?.User?.employee_id || ""}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "businessUnit",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Business Unit
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row.User?.Department?.Group?.name ?? "-",
      cell: ({ row }) => {
        const name: string =
          row?.original?.User?.Department?.Group?.name ?? "-";

        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Department
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      accessorFn: (row) => row.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";

        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Date & Time
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      accessorFn: (row) =>
        row.updatedAt ? moment(row.updatedAt).format("YYYY-MM-DD HH:mm") : "-",
      cell: ({ row }) => {
        const data = row?.original?.updatedAt;
        let name = "";
        if (data) {
          name = moment(data)?.format("DD MMM YYYY, HH:mm");
        }

        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {name ?? "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "acknowledge_status",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Acknowledge
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const status = row?.original?.acknowledge_status ? "Yes" : "No";
        return (
          <div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            {status}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Dep_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  const handleFilterApply = () => {
    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDelete = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchLeaveType();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCalanderClick = () => {};
  const handleDateChange = () => {};

  return (
    <div className="bg-[#F9F9F9] min-h-[90vh]">
      <div className="p-5">
        <div className=" flex flex-col gap-[15px]">
          <div className="flex flex-row mb-2  items-center h-[30px] gap-3">
            <button
              onClick={() =>
                navigate("/view-policy-details", {
                  state: { id: id },
                })
              }
            >
              <img
                src={right}
                alt="right arrow"
                className="h-6 w-6 bg-black rounded-full"
              />
            </button>
            <span className="h-[30px] font-['Poppins'] font-semibold text-2xl text-[#1D1A22] ">
              Policy Received By
            </span>
          </div>
          <div className="h-[40px] w-full">
            <div className="h-full flex justify-between items-center">
              <Search
                onChange={updateSearchTerm}
                label="Search with policy name"
              />
              <div className="h-full flex items-center ">
                <div className="h-full flex items-center gap-2">
                  {departmentSelect || businessSelect ? (
                    <button
                      onClick={handleDelete}
                      className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                    >
                      <Close
                        sx={{
                          fontSize: "15px",
                        }}
                      />
                      Clear All
                    </button>
                  ) : null}
                </div>
                <button
                  className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                  onClick={() => setIsOpen(true)}
                >
                  <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                    Filter
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="table_main_content  w-full max-h-[calc(100vh-280px)] relative overflow-auto bg-white ">
            <DynamicTable<IPolicyReceiveList>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
        <Filter
          isDate={false}
          isOther
          heading="Policy Filter"
          selectnameBusiness={
            selectedBusinessUnit?.name ?? "Select Business Unit"
          }
          optionsBusiness={bussness}
          onOptionSelectBusiness={handleBusinessUnitSelect}
          selectnameDepartment={
            selectedDepartmentUnit?.name ?? "Select Department"
          }
          optionsDepartment={department}
          onOptionSelectDepartment={handleDepartmentUnitSelect}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onApply={handleFilterApply}
          selected={selectedDates[1]}
          startDate={selectedDates[0]}
          endDate={selectedDates[1]}
          isCalendarOpen={isCalendarOpen}
          handleCalanderClick={handleCalanderClick}
          handleDateChange={handleDateChange}
        />
      </div>
    </div>
  );
}
