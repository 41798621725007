import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import maskPlus from "../../image/Mask group.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Designation_Management_List } from "../common/services/DesignationManagement";
import TableSkeleton from "../common/TableSkeleton";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown } from "lucide-react";
import del from "../../assets/Crud_Icons/delete.svg";
import { base_api } from "../common/api";
import { api_key_home } from "../../utils/helperData";

export const Designation = () => {
  interface IDesignationManagement {
    name: string;
    created_by: string;
    total_user: number;
    createdAt: string;
    updatedAt: string;
    status: string;
    id: number;
  }
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState<IDesignationManagement[]>([]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleClick = () => {
    navigate("/add-designation");
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 100),
    []
  );

  const filteredTableData = useMemo(() => {
    if (!tableData) {
      return [];
    }

    return tableData.filter((row: any) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [tableData, searchTerm]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] =
    useState<IDesignationManagement | null>(null);
  const [updated, setUpdated] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Designation_Management_List(
        customer_id,
        numOfItems,
        page,
        searchTerm
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [customer_id, searchTerm, page, numOfItems]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteClick = (item: IDesignationManagement) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const DeleteConfirmationModal: React.FC<{
    onDelete: () => void;
    onCancel: () => void;
  }> = ({ onDelete, onCancel }) => {
    return (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-8 rounded shadow-lg">
          <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
            Delete Designation
          </h2>
          <h4 className="mb-6 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
            Are you sure you want to delete this designation?
          </h4>
          <div className="flex space-x-4 justify-center">
            <Button
              variant={"gradient"}
              onClick={onDelete}
              className=" rounded mr-2"
            >
              Yes Sure
            </Button>
            <button
              onClick={onCancel}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      console.error("No access token found");
      return;
    }

    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/designation/${selectedItem?.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key_home,
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.ok) setUpdated(!updated);
        else
          throw new Error(
            `Failed to delete designation: ${response.statusText}`
          );
      } catch (error) {
        console.error("Error deleting designation:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
        fetchData();
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const columns: ColumnDef<IDesignationManagement>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Designation
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end">
          <Button variant="ghost" className="p-0 pr-4">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div className="flex items-center justify-end gap-2 mr-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-designation`, {
                  state: { id: item.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row?.original);
              }}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex justify-between">
        <div className="text-[#1D1A22] font-[Poppins] font-[600] text-[20px] leading-[30px] mt-2">
          Designation
        </div>
        {/* <div> */}
        <Button onClick={handleClick} variant={"gradient"}>
          <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
          Add Designation
        </Button>
        {/* </div> */}
      </div>

      <div className="my-2 h-10">
        <Search onChange={updateSearchTerm} label="Search with designation" />
      </div>

      <div className="mt-3">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white">
            <DynamicTable<IDesignationManagement>
              data={tableData}
              loading={false}
              columns={columns}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      {showModal && (
        <DeleteConfirmationModal
          onDelete={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
