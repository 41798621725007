import { Dispatch } from "redux";
import { base_api, api_key_work as api_key } from "../../../utils/helperData";
import { companyStructureActions } from "../../reducers/CompanyStructure/CompanyStructureSlice";
import {
  Company_Structure_Group_List,
  Company_Structure_View_Group_List,
  Get_Company_Structure_Department,
  Send_Create_Bussiness_Unit_Data,
  Send_Create_Department_Unit_Data,
  Send_Create_Process_Unit_Data,
} from "../../../components/common/services/companyStructure";
import {
  SEND_COMPANY_STRUCTURE_DATA,
  UPDATE_DEPARTMENT_DATA,
  UPDATE_PROCESS_DATA,
} from "../../../components/common/api";
import axios from "axios";
import toast from "react-hot-toast";
import InternalServer from "../../../components/common/InternalServer";
import axiosInstance from "../../../app/api/axiosInstance";
import { Dayjs } from "dayjs";
import moment from "moment";

export const companyStructureGroupList = (customer_id: number) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const response = await Company_Structure_Group_List(customer_id);
      if (!response.ok) {
        if (response.status === 500) {
          return <InternalServer />;
        }
        throw new Error("Could not fetch group data!");
      }
      const data = await response.json();
      return data.result;
    };
    try {
      const resData = await fetchData();
      const new_data = findMaxLevels(resData);
      const preparedData = transformNestedArray(
        new_data?.new_array,
        new_data.typeLevels["Group"] || 0,
        "Department"
      );
      const new_level_data = findMaxLevels(preparedData);
      const secondPreparation = transformNestedArray(
        new_level_data?.new_array,
        new_level_data.typeLevels["Department"] || 0,
        "Processes"
      );
      const treeDataWithLevel = secondPreparation.map((node: any) =>
        addLevel(node, 1)
      );
      console.log({ treeDataWithLevel });

      dispatch(companyStructureActions.setUserGroupData(treeDataWithLevel));
    } catch (error) {
      if (error instanceof Error) {
      } else {
      }
    }
  };
};

const findMaxLevels = (data: any) => {
  const typeLevels: any = {};

  data.forEach((item: any) => {
    getMaxLevels(item, typeLevels);
  });
  return { typeLevels, new_array: data };
};

const getMaxLevels = (node: any, typeLevels: any, level = 0) => {
  if (!node) return null;

  typeLevels[node.type] = Math.max(typeLevels[node.type] || 0, level);

  node.level = level;

  if (node.children && node.children.length) {
    node.children.forEach((child: any) => {
      getMaxLevels(child, typeLevels, level + 1);
    });
  }
};

const transformNestedArray = (
  item: any,
  maxLevel: number,
  searchType: string
) => {
  const insertEmptyNodes = (node: any, currentLevel: number) => {
    if (currentLevel > maxLevel) {
      return;
    }
    if (node.children?.length > 0) {
      let hasOneDownType = false;
      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].type === searchType) {
          hasOneDownType = true;
          if (node.children[i].level >= maxLevel) {
            const emptyNode = {
              level: node.children[i].level,
              type: "empty",
              children: [],
            };
            node.children.push(emptyNode);
            insertEmptyNodes(emptyNode, currentLevel + 1);
          }
          break;
        }
      }
      if (hasOneDownType) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          if (node.children[i].type === searchType) {
            const removedDepartment = node.children.splice(i, 1)[0];
            let lastEmptyNodeIndex = -1;
            // Find the index of the last empty node
            for (let j = node.children.length - 1; j >= 0; j--) {
              if (node.children[j].type === "empty") {
                lastEmptyNodeIndex = j;
                break;
              }
            }
            if (lastEmptyNodeIndex !== -1) {
              // If an empty node exists, push the removed department into it
              const lastEmptyNode = node.children[lastEmptyNodeIndex];
              lastEmptyNode.children.push(removedDepartment);
            } else {
              // If no empty node exists, create a new one and push the department into it
              const newEmptyNode = {
                level: maxLevel,
                type: "empty",
                children: [removedDepartment],
                parent_type:
                  Object.keys(node).includes("parent_type") &&
                  (node.parent_type !== null || node.parent_type !== undefined)
                    ? node.parent_type
                    : node.type,
                id: node.id,
              };
              node.children.push(newEmptyNode);
            }
            // break;
          }
        }
      }

      for (const child of node?.children) {
        insertEmptyNodes(child, currentLevel + 1);
      }
    }
  };
  const result = JSON.parse(JSON.stringify(item));
  insertEmptyNodes({ children: result }, 0);
  return result;
};

const addLevel = (node: any, level: number) => {
  const newNode = {
    ...node,
    level: level,
    children: [],
  };
  if (node.type !== "Processes") {
    newNode.children = node.children
      ? node.children.map((child: any) => addLevel(child, level + 1))
      : [];
  } else {
    newNode.children = node.children
      ? node.children.map((child: any) => addLevel(child, level))
      : [];
  }
  if (
    node.type !== "Activity" &&
    !(node.type === "empty" && node.children.length < 10)
  ) {
    newNode.children.push({
      parent_id: newNode.id,
      customer_id: newNode.customer_id,
      user_id: newNode.user_id == null ? newNode.spoc_id : newNode.user_id,
      parent_type:
        Object.keys(newNode).includes("parent_type") &&
        (newNode.parent_type !== null || newNode.parent_type !== undefined)
          ? newNode.parent_type
          : newNode.type,
      children: [],
      level: level + 1,
    });
    // console.log(newNode);
  }
  return newNode;
};

export const Fetch_Parent_List = async (
  customer_id: number,
  searchValue: string
) => {
  const access_token = localStorage.getItem("access_token");
  const response = await fetch(
    `${SEND_COMPANY_STRUCTURE_DATA}?customer_id=${customer_id}&search=${searchValue}`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );

  if (!response.ok) {
    if (response.status === 500) {
      return <InternalServer />;
    }
    throw new Error("Failed to fetch data.");
  }

  const resData = await response.json();

  return resData?.result;
};

export const Patch_Edit_Group_Detail = async (
  name: string,
  id: number,
  spoc_id: number,
  address: string,
  coordinates: [number | null, number | null],
  shift_start_time: Dayjs | null,
  shift_end_time: Dayjs | null,
  timezone_id: number | null,
  hr_team: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name: name,
    spoc_id: spoc_id,
    businessUnit_address: address,
    businessUnit_coordinates: coordinates,
    businessUnit_shift_start_time: shift_start_time
      ? moment(shift_start_time?.toISOString())?.format("HH:mm:ss")
      : null,
    businessUnit_shift_end_time: shift_end_time
      ? moment(shift_end_time?.toISOString())?.format("HH:mm:ss")
      : null,
    time_zone_id: timezone_id,
    hr_email: hr_team,
  };

  try {
    toast.loading("Processing in...");
    const response = await axiosInstance.patch(
      `${SEND_COMPANY_STRUCTURE_DATA}/${id}`,
      body,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success("Process completed successfully!");
    }
    return response;
  } catch (error: any) {
    console.error("There was an error!", error);
    toast.dismiss();
    toast.error(error.response?.data?.message || "An error occurred");
  }
};

export const Edit_Department_Detail = async (
  name: string,
  id: number,
  spoc_id: number
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name,
    spoc_id,
  };

  try {
    toast.loading("Processing in...");
    const response = await axiosInstance.patch(
      `${UPDATE_DEPARTMENT_DATA}/${id}`,
      body,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success("Process completed successfully!");
    }

    return response;
  } catch (error: any) {
    console.error("There was an error!", error);
    toast.dismiss();
    toast.error(error.response?.data?.message || "An error occurred");
  }
};

export const Edit_Process_Detail = async (
  name: string,
  id: number,
  spoc_id: number
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    name,
    spoc_id,
  };
  try {
    toast.loading("Processing in...");
    const response = await axiosInstance.patch(
      `${UPDATE_PROCESS_DATA}/${id}`,
      body,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );
    if (response.status === 200) {
      toast.dismiss();
      toast.success("Process completed successfully!");
    }
    return response;
  } catch (error: any) {
    console.error("There was an error!", error);
    toast.dismiss();
    toast.error(error.response?.data?.message || "An error occurred");
  }
};

export const sendCreateBussinessUnitData =
  (
    name: string,
    spoc_id: number,
    customer_id: number,
    parent_id: any,
    businessUnit_address: string,
    businessUnit_coordinates: [number | null, number | null],
    time_zone_id: number | null,
    businessUnit_shift_start_time: string | null,
    businessUnit_shift_end_time: string | null,
    hr_email: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await Send_Create_Bussiness_Unit_Data(
        name,
        spoc_id,
        customer_id,
        parent_id,
        businessUnit_address,
        businessUnit_coordinates,
        time_zone_id,
        businessUnit_shift_start_time,
        businessUnit_shift_end_time,
        hr_email
      );
      if (response.status === 201) {
        return response;
      }
      if (response.status === 500) {
        return <InternalServer />;
      }
    } catch (error) {
      throw new Error("Could not send create department data!");
    }
  };

export const GroupViewDetails = (
  customer_id: number,
  page: number,
  numOfItems: number,
  sortType: any,
  group_id: number,
  type: any,
  searchTerm: string,
  startDate: any,
  endDate: any
) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      try {
        const response = await Company_Structure_View_Group_List(
          customer_id,
          page,
          numOfItems,
          sortType,
          group_id,
          type,
          searchTerm,
          startDate,
          endDate
        );
        if (!response?.ok) {
          throw new Error("Response not okay");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Fetch data error:", error);
        throw error;
      }
    };

    try {
      const resData = await fetchData();

      dispatch(companyStructureActions.setFetchNodeViewDetails(resData));
    } catch (error) {
      console.error("Fetch data failed:", error);
      if (error instanceof Error) {
        //console.log("Fetching user data failed:", error.message);
      } else {
        //console.log("An unknown error occurred:", error);
      }
    }
  };
};

export const sendCreateDepartmentData =
  (
    name: string,
    spoc_id: any,
    group_id: any,
    customer_id: any,
    parent_id: any
  ) =>
  async (dispatch: Dispatch) => {
    const response = await Send_Create_Department_Unit_Data(
      name,
      spoc_id,
      group_id,
      customer_id,
      parent_id
    );
    if (!response.ok) {
      if (response.status === 500) {
        return <InternalServer />;
      }
      throw new Error("Could not send create department data!");
    }
    const data = await response.json();
    return data;
  };

export const sendCreateProcessData =
  (
    name: string,
    spoc_id: number,
    customer_id: number,
    department_id: any,
    parent_id: any
  ) =>
  async (dispatch: Dispatch) => {
    const response = await Send_Create_Process_Unit_Data(
      name,
      spoc_id,
      customer_id,
      department_id,
      parent_id
    );
    if (!response.ok) {
      if (response.status === 500) {
        return <InternalServer />;
      }
      throw new Error("Could not send create department data!");
    }
    const data = await response.json();
    return data;
  };

export const fetchUserGroupAccessList =
  (access_token: string, url: string) => async (dispatch: Dispatch) => {
    const response = await fetch(base_api + url, {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      if (response.status === 500) {
        return <InternalServer />;
      }
      throw new Error("Could not fetch group data!");
    }
    return data;
  };

export const GetDepartmentData = (customer_id: number) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const response = await Get_Company_Structure_Department(customer_id);
      console.log("responsessssssssssss", response);
      if (!response.ok) {
        if (response.status === 500) {
          return <InternalServer />;
        }
        throw new Error("Could not fetch group data!");
      }
      const data = await response.json();
      return data;
    };
    try {
      const resData = await fetchData();

      const data = resData.result;
      console.log("responsessssssssssss", data);
      dispatch(companyStructureActions.setDepartments(data.rows));
    } catch (error) {
      if (error instanceof Error) {
        console.error("department is not fetch");
      } else {
        // Handle error
      }
    }
  };
};
