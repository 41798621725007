import React from "react";
import profile from "../../assets/Ellipse 580.png";
import bussiness_man_icon from "../../assets/avatar.svg";


interface User {
  firstName: string;
  middleName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
  Designation: {
    name: string;
  };
}

interface Resource {
  user_id: string;
  User: User;
}

interface ResourceCardProps {
  resource: Resource;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ resource }) => {
  return (
    <div className="flex items-center gap-2 px-3 py-1 rounded-md border border-gray-200 bg-gray-50">
      <img
        className="w-9 h-9 rounded-full"
        src={resource?.User?.profile_image || bussiness_man_icon}
        alt="Profile"
        onError={(e) => (e.currentTarget.src = bussiness_man_icon)}
      />
      <div className="flex flex-col justify-between py-1">
        <span className="flex flex-col justify-center light-black font-poppins text-[14px] font-medium">
          {resource?.User?.firstName} {resource?.User?.lastName}
        </span>
        <span className="flex flex-col justify-center text-custom-gray-500 font-poppins text-[14px] font-normal">
          {resource?.User?.employee_id || ""}
        </span>
      </div>
      <div className="flex flex-col justify-center items-center light-black font-poppins text-[12px] font-medium">
        {resource?.User?.Designation?.name ? (
          <>({resource.User.Designation.name})</>
        ) : ""}
      </div>
    </div>
  );
};

export default ResourceCard;
