import React, { useEffect, useRef, useState } from "react";
import downarrow from "../../image/Icon.png";
import { X } from "lucide-react";
import { Cancel, Close } from "@mui/icons-material";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface SelectDropdownProps {
  selectname: string;
  options: Business_Unit[] | Department_Unit[] | undefined;
  onOptionSelect: (option: Business_Unit | Department_Unit | undefined) => void; // New callback prop
  // naam: string; 
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  selectname,
  options,
  onOptionSelect,
  // naam,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | undefined>(selectname);

  const toggleDropdown = () => setIsOpen(!isOpen);
  // const handleOptionClick = (option: Business_Unit[] | Department_Unit[]) => {
  //   setSelected(option);
  //   setIsOpen(false);
  //   onOptionSelect(option); // Call the callback with the selected option
  // };



  const dropdownRef = useRef<HTMLDivElement>(null);


  const handleOptionClick = (
    option: Business_Unit | Department_Unit | undefined
  ) => {
    // If option is undefined and the user clicked the cross icon, reset selectname based on condition
    if (!option) {
      setSelected(selectname === "Business Unit" ? "Select Business Unit" : "Select Department");
      onOptionSelect(undefined); // Pass undefined to reset the selected option
    } else {
      setSelected(option?.name);
      onOptionSelect(option); // Pass the selected option to the callback
    }
    setIsOpen(false);
  };
  
  
  

  // const handleOptionClick = (
  //   option: Business_Unit | Department_Unit | undefined
  // ) => {
  //   setSelected(option?.name);
  //   setIsOpen(false);
  //   onOptionSelect(option); // Call the callback with the entire option object
  // };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  console.log({ selected, selectname });
  return (
    <>
      {window.location.pathname === "/attendance-management" ||
      window.location.pathname === "/leave-holiday-management" ||
      window.location.pathname === "/work-from-home" ||
      window.location.pathname === "/expense-management" ||
      window.location.pathname === "/employee-management" ||
      window.location.pathname === "/announcement-management" ||
      window.location.pathname === "/employee-dsr" ? (
        <div className="relative inline-block" ref={dropdownRef}>
          <div
            className="border border-gray-300 h-[40px] rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={toggleDropdown}
          >
            <span
              className={`text-[12px] ${
                selected === "Select Department" ||
                selected === "Select Business Unit"
                  ? "text-[#938f99]"
                  : "text-black font-normal"
              }  font-[Poppins]`}
            >
              {selected ?? selectname}
            </span>
            <div className="flex items-center gap-1">
              {/* <img src={downarrow} alt="" /> */}
              {selected !== undefined &&
                selected !== "Select Department" &&
                selected !== "Select Business Unit" && (
                  <X
                    size={16}
                    className="cursor-pointer"
                    onClick={() => {
                      handleOptionClick(undefined) 
                      onOptionSelect(undefined); 

                    }}
                  />
                )}

              <svg
                className={`w-5 h-5 transition-transform duration-300 ${
                  isOpen ? "transform rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  // d="M10 3a1 1 0 01.707.293l6 6a1 1 0 11-1.414 1.414L10 5.414 4.707 10.707a1 1 0 01-1.414-1.414l6-6A1 1 0 0110 3z"
                  d="M3 7a1 1 0 011.707-.707L10 11.586l5.293-5.293A1 1 0 1116.707 8.707l-6 6a1 1 0 01-1.414 0l-6-6A1 1 0 013 7z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          {isOpen && (
            <ul className="absolute left-0 font-[Poppins] right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto z-10">
              {options?.map((option) => (
                <li
                  key={Number(option.id)}
                  className={`p-2 cursor-pointer hover:bg-gray-100 ${
                    selected === option.name ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="font-[Poppins]">{option.name}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="relative inline-block">
          <div
            className="border border-gray-300 h-[40px] rounded-md p-2 flex justify-between items-center cursor-pointer"
            onClick={toggleDropdown}
          >
            <span className="text-[12px] text-[#938f99] font-[500] font-[Poppins]">
              {selected}
            </span>
            <img src={downarrow} alt="" />
            {/* <svg
              className={`w-5 h-5 transition-transform duration-300 ${
                isOpen ? "transform rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l6 6a1 1 0 11-1.414 1.414L10 5.414 4.707 10.707a1 1 0 01-1.414-1.414l6-6A1 1 0 0110 3z"
                clipRule="evenodd"
              />
            </svg> */}
          </div>
          {isOpen && (
            <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto z-10">
              {options?.map((option) => (
                <li
                  key={Number(option.id)}
                  className={`p-2 cursor-pointer hover:bg-gray-100 ${
                    selected === option.name ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="font-[Poppins]">{option.name}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default SelectDropdown;
