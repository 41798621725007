import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import Leave from "./Leave/Leave";
import { WFH } from "../WorkFromHome/WFH";
import Holiday from "./Holiday/Holiday";
import LeaveBalance from "./LeaveBalance/LeaveBalance";
import LeaveType from "./LeaveType/LeaveType";
import "../EmployeeManagement/employee.css";
import maskPlus from "../../image/Mask group.svg";
import WFHBalance from "../WorkFromHome/WFHBalance/WFHBalance";
import WFHTypes from "../WorkFromHome/WFHTypes/WFHTypes";
import CustomTabs from "../common/CustomTabs";
import { useDispatch } from "react-redux";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import { Button } from "../common/Button";
import { setMainTabValue } from "../../redux/reducers/Tab/TabSlice";
import LeaveOverview from "./LeaveAndHolidayOverview/LeaveOverview";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import filter from "../../assets/filter.svg";
import AttFilter, { FilterData } from "../Filter/AttFilter";

export const LeaveAndHoliday = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const role = useSelector(
    (state: any) => state?.auth?.login?.login_details?.role
  );
  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  const value = useSelector((state: RootState) => state.mainTabValue.value);
  useEffect(() => {
    if (!["HR", "Admin", "IT"].includes(role)) dispatch(setMainTabValue("2"));
  }, []);

  const location = useLocation();

  const handleDelete = () => {
    setBusId("");
    setDepId("");
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
  };

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setIsOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // dispatch(
    //   setFilterPersistData({
    //     department: "",
    //     group: "",
    //     date: [null, null],
    //     search: "",
    //   })
    // );
    dispatch(setMainTabValue(newValue));
  };

  const allTabs = [
    { label: "Overview", value: "1" },
    { label: "Leave", value: "2" },
    { label: "Leave Balance", value: "3" },
    { label: "Leave Types", value: "4" },
    { label: "Work From Home", value: "5" },
    { label: "WFH Balance", value: "6" },
    // { label: "WFH Types", value: "7" },
    { label: "Holiday", value: "8" },
  ];

  const restrictedTabs = [
    { label: "Leave", value: "2" },
    { label: "Work From Home", value: "5" },
  ];

  const tabs = ["HR", "Admin", "IT"].includes(role) ? allTabs : restrictedTabs;

  return (
    <>
      <div className="px-4 pt-6 bg-[#F9F9F9] min-h-[90vh]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between flex-wrap gap-y-4">
                <Box>
                  <CustomTabs tabs={tabs} onChange={handleChange} />
                </Box>

                {value === "1" ? (
                  <div className="h-full flex items-center  gap-5">
                    <div className="h-full flex justify-end items-center">
                      {(busId && busId !== "") ||
                      (depId && depId !== "") ||
                      (dates && !(dates[0] === null && dates[1] === null)) ? (
                        <button
                          onClick={handleDelete}
                          className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                        >
                          <Close
                            sx={{
                              fontSize: "15px",
                            }}
                          />
                          Clear All
                        </button>
                      ) : null}
                    </div>
                    <Button variant={"border"} onClick={() => setIsOpen(true)}>
                      <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                      Filter
                    </Button>
                  </div>
                ) : null}

                {value === "2" ? (
                  <div className="ml-auto">
                    <Button
                      onClick={() => history("/add-leave")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Employee Leave
                    </Button>
                  </div>
                ) : value === "5" ? (
                  <div className="ml-auto">
                    <Button
                      onClick={() => history("/add-work-from-home")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add WFH
                    </Button>
                  </div>
                ) : value === "8" ? (
                  <div className="ml-auto">
                    <Button
                      onClick={() => history("/add-holiday")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Holiday
                    </Button>
                  </div>
                ) : value === "3" ? (
                  <div></div>
                ) : value === "4" ? (
                  <div className="ml-auto">
                    <Button
                      onClick={() => history("/add-leave-type")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Leave Type
                    </Button>
                  </div>
                ) : value === "6" ? (
                  <></>
                ) : (
                  <></>
                  // <div className="ml-auto">
                  //   <Button
                  //     onClick={() => history("/add-wfh-type")}
                  //     variant={"gradient"}
                  //   >
                  //     <img
                  //       className="w-5 h-5 mr-2"
                  //       src={maskPlus}
                  //       alt="plus icon"
                  //     />
                  //     Add WFH Type
                  //   </Button>
                  // </div>
                )}
              </div>
              <TabPanel className="tabpanel" value="2">
                <Leave />
              </TabPanel>
              <TabPanel className="tabpanel" value="5">
                <WFH />
              </TabPanel>

              {/* Show additional panels only if user is HR, Admin, or IT */}
              {["HR", "Admin", "IT"].includes(role) && (
                <>
                  <TabPanel className="tabpanel" value="1">
                    <LeaveOverview depId={depId} busId={busId} dates={dates} />
                  </TabPanel>
                  <TabPanel className="tabpanel" value="3">
                    <LeaveBalance />
                  </TabPanel>
                  <TabPanel className="tabpanel" value="4">
                    <LeaveType />
                  </TabPanel>
                  <TabPanel className="tabpanel" value="6">
                    <WFHBalance />
                  </TabPanel>
                  <TabPanel className="tabpanel" value="7">
                    <WFHTypes />
                  </TabPanel>
                  <TabPanel className="tabpanel" value="8">
                    <Holiday />
                  </TabPanel>
                </>
              )}
            </TabContext>
          </Box>
        </div>
      </div>
      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Filter"
        onApply={handleApply}
        isDate={true}
        isOther
      />
    </>
  );
};
