import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface SideBarData {
  resource_id: number;
  resource_name: string;
  parent_id: number;
  icon: string;
  children: [];
}

interface SideBarState {
  sidebar: SideBarData[];
  selected_resource_id: number;
  // children_resource_id: number;
}

const initialState: SideBarState = {
  sidebar: [],
  selected_resource_id: -1,
  // children_resource_id: -1,
};

const SideBarSlice = createSlice({
  name: "SideBar",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setSideBarData(state, action: PayloadAction<any>) {
      state.sidebar = action.payload;
    },
    setSelectedChild(state, action: PayloadAction<any>) {
      state.selected_resource_id = action.payload;
    },
    // setSelectedChildId(state, action: PayloadAction<any>) {
    //   state.children_resource_id = action.payload;
    // },
  },
});

export const SideBarActions = SideBarSlice.actions;
export default SideBarSlice;
