import {
  api_key_work as api_key,
  base_api,
  // session_query,
  // year_session_query,
} from "../../../utils/helperData";
import axios from "axios";
import { FETCH_HOLIDAY_LIST } from "../api";
import { Dayjs } from "dayjs";
import moment from "moment";
import axiosInstance from "../../../app/api/axiosInstance";
import { isObject } from "lodash";

// const year_session_query = `session_start=${localStorage.getItem(
//   "start_year"
// )}&session_end=${localStorage.getItem("end_year")}`;

export const Fetch_Holiday_List = (
  numOfItems: number,
  page: number,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null,
  sortType: any,
  upcoming?: boolean,
  group_id?: number,
  department_id?: number
) => {
  const access_token = localStorage.getItem("access_token");
  const year_session_query = `session_start=${localStorage.getItem(
    "start_year"
  )}&session_end=${localStorage.getItem("end_year")}`;
  let query = `?size=${numOfItems}&page=${page}`;

  if (sortType !== undefined) {
    query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (upcoming) {
    const current_date = moment().format("YYYY-MM-DD");
    query += `&upcoming=${current_date}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axiosInstance.get(
    `${FETCH_HOLIDAY_LIST}${query}&${year_session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const createHoliday = async (
  //customerId: number,
  description: string | null,
  holiday_name: string,
  holiday_date: string | null | Dayjs,
  isOptional: boolean
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    //customerId: customerId,
    // description: description,
    holiday_name: holiday_name,
    holiday_date: holiday_date,
    optional_holiday: isOptional,
  };

  if (description !== "" && description) data.description = description;

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/holiday`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditHoliday = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/holiday/${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateHoliday = async (
  id: string,
  holiday_name: string,
  description: string | null,
  holiday_date: string | null | Dayjs,
  isOptional: boolean
) => {
  const access_token = localStorage.getItem("access_token");
  const body: any = {
    holiday_name: holiday_name,
    holiday_date: holiday_date,
    optional_holiday: isOptional,
  };

  if (description !== "" && description) body.description = description;

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/holiday/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
