import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import profile from "../../assets/Ellipse 580.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  approveDSRRejectApi,
  fetchDSRDetails,
} from "../common/services/EmployeeDSR";
import moment from "moment";
import ModalViewer from "../ExpenseManagement/ModalViewer";
import Receipt from "../ExpenseManagement/ImageView";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

interface Approvers {
  id: number;
  employee_id: string;
  name: string;
  profile_image: string | null;
  approver_status: string;
  role_name: string;
}

interface DSRData {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
  project_id: string;
  project_name: string;
  task_id: string;
  task_name: string;
  task_document: string[];
  date: string;
  duration: string;
  status: string;
  notes: string;
}

export const EmployeeDSRDetails = () => {
  const navigate = useNavigate();
  const [DSRData, setDSRData] = useState<DSRData>({
    firstName: "",
    middleName: "",
    lastName: "",
    employee_id: "",
    profile_image: "",
    project_id: "",
    project_name: "",
    task_id: "",
    task_name: "",
    task_document: [],
    date: "",
    duration: "",
    status: "",
    notes: "",
  });
  const location = useLocation();
  const id = location.state?.id;

  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("Pending");
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const userId = loginData?.userId;

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };
  const closeModal = () => {
    setSelectedFileUrl(null);
  };
  const handleapproveData = async (status: string) => {
    try {
      const response = await approveDSRRejectApi(id, status);
      if (response?.status === 200) {
        toast.success(response.data?.message);
      }

      navigate("/employee-dsr");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.id === userId) {
        setShowButtons(true);
        setStatus(approver?.approver_status ?? "");
      }
    });
  }, [approverData, userId]);

  useEffect(() => {
    fetchDSRDetails(id)
      .then((data) => {
        if (data.result) {
          setDSRData({
            firstName: data.result.User.firstName,
            middleName: data.result.User.middleName,
            lastName: data.result.User.lastName,
            employee_id: data.result.User.employee_id,
            profile_image: data.result.User.profile_image,
            project_id: data.result.Project.id,
            project_name: data.result.Project.project_name,
            task_id: data.result.Task.id,
            task_name: data.result.Task.task_name,
            task_document: data.result.Task.task_document,
            date: data.result.date,
            duration: data.result.duration,
            status: data.result.status,
            notes: data.result?.notes,
          });
          setApproverData(data?.result?.approvers);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="px-4 pt-6 pb-12 bg-white">
      <div className="flex flex-row items-start h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate(-1)}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Employee DSR Details
          </span>
        </div>

        {showButtons ? (
          <div className="flex flex-start gap-5">
            {status !== "Rejected" ? (
              <button
                onClick={() => handleapproveData("Rejected")}
                className="flex w-[148px] bg-[#B3261E] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12 hover:bg-[#96150e]"
              >
                <div className="text-[#fff] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Reject
                </div>
              </button>
            ) : (
              <></>
            )}
            {status !== "Approved" ? (
              <button
                type="submit"
                form="form"
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] bg-[#359349] justify-center items-center flex-[1,1,0] self-stretch rounded-lg text-white h-12 hover:bg-[#1f8735]"
                onClick={() => handleapproveData("Approved")}
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Approve
                </div>
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className="flex flex-col gap-5 rounded-md bg-white mt-3"
        style={{ boxShadow: "1px 2px 10px 1px rgba(72, 71, 71, 0.1)" }}
      >
        {/* <div
          className="flex flex-col mt-5 gap-5 p-5 rounded-lg bg-white"
          style={{ boxShadow: "0px 2px 15px 0px rgba(72, 71, 71, 0.1)" }}
        > */}
        <div className="flex flex-col gap-[5px] px-2.5 py-5 rounded-md">
          <div className="flex justify-between items-start pb-1">
            <div>
              <div className="flex gap-2">
                <div className="h-[55px] w-[55px]">
                  <img
                    src={DSRData.profile_image || profile}
                    alt="profile"
                    className="h-full w-full rounded-full"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="font-['Poppins'] font-semibold text-[16px] text-[#111827]">
                    {DSRData.firstName} {DSRData.middleName} {DSRData.lastName}
                  </span>
                  <span className="font-['Poppins'] font-normal text-[15px] leading-[21px] text-[#1F2937]">
                    {DSRData?.employee_id}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-right mr-1">
              <div className="font-[Poppins] font-semibold text-[16px]  text-[#111827]">
                DSR Date
              </div>
              <div className="text-[#6B7280] text-[15px] font-normal leading-[12px] mt-2">
                <span className="font-[Poppins] font-normal text-[15px] text-[#1F2937]">
                  {moment(DSRData.date).format("DD MMM YYYY")}
                </span>
              </div>
            </div>
          </div>
          <hr className="px-2.5 h-[2px] " />
          {/* <div className="grid grid-cols-3 gap-x-6"> */}
          <div className="flex flex-col gap-[7px] h-full mt-2 mb-3 pl-2">
            <span className="font-[Poppins] font-meduim text-base  text-[#6B7280]">
              Task Name & ID
            </span>
            <span className="font-[Poppins] font-semibold text-[15px] leading-4 text-[#1F2937]">
              {DSRData.task_name} ({DSRData.task_id})
            </span>
          </div>
          <div className="flex flex-col gap-[7px] h-full mt-2 mb-3 pl-2">
            <span className="font-[Poppins] font-meduim text-base  text-[#6B7280]">
              Project Name & ID
            </span>
            <span className="font-[Poppins] font-semibold text-[15px] leading-4 text-[#1F2937]">
              {DSRData.project_name} ({DSRData.project_id})
            </span>
          </div>
          {/* </div> */}
          <div className="grid grid-cols-3 gap-x-4 mt-2 mb-3 pl-2">
            <div className="flex flex-col gap-[7px] h-full">
              <span className="font-[Poppins] font-meduim text-base  text-[#6B7280]">
                Efforts
              </span>
              <span className="font-[Poppins] font-semibold text-[15px] leading-4 text-[#1F2937]">
                {DSRData.duration} Hrs.
              </span>
            </div>
            <div className="flex flex-col gap-[7px] h-full">
              <span className="font-[Poppins] font-meduim text-base text-[#6B7280]">
                Task Status
              </span>
              <span
                className={`font-[Poppins] px-2 py-1 max-w-min font-semibold text-[15px] leading-4 ${
                  DSRData.status === "Approved"
                    ? "bg-[#E0F1E7] text-[#0F9446]"
                    : DSRData.status === "Pending"
                    ? "bg-[#FEEBDD] text-[#FF9950]"
                    : DSRData.status === "Rejected"
                    ? "bg-[#EDCFCF] text-[#EB3F3F]"
                    : ""
                }`}
              >
                {DSRData.status}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-6 mt-2 mb-3 pl-2">
            <div className="flex flex-col gap-[7px] h-full">
              <span className="font-[Poppins] font-meduim text-base text-[#6B7280]">
                DSR
              </span>
              <span className="font-[Poppins] font-semibold text-[15px] leading-5 text-[#1F2937]">
                {DSRData.notes}
              </span>
            </div>
          </div>
          <div className="flex mt-2 mb-1 pl-2">
            <p className="w-full">
              <div className="font-[Poppins] font-meduim text-base text-[#6B7280]">
                Approval Status
              </div>
              {/* <br />{" "} */}
              <div className="flex flex-wrap gap-4 mt-1">
                {approverData?.map((approver: Approvers) => {
                  const statusColor =
                    approver?.approver_status === "Approved"
                      ? "bg-[#E0F1E7] text-[#0F9446]"
                      : approver?.approver_status === "Pending"
                      ? "bg-[#FEEBDD] text-[#FF9950]"
                      : approver?.approver_status === "Rejected"
                      ? "bg-[#EDCFCF] text-[#EB3F3F]"
                      : "";

                  return (
                    <div
                      key={approver?.id}
                      className="flex flex-col gap-2 h-full min-w-[350px]"
                    >
                      <div className="font-['Poppins'] font-[500] text-[#4B5563] text-left text-[16px] min-h-[24px]">
                        {approver?.role_name ? approver.role_name : ""}
                      </div>

                      {/* Card */}
                      <div className="flex items-center justify-between bg-white rounded-md p-3 border border-[#E5E7EB] h-full">
                        {/* Left part */}
                        <div className="flex items-center gap-2 min-h-[50px]">
                          <img
                            src={approver?.profile_image || profile}
                            alt={approver?.name}
                            className="w-10 h-10 rounded-full object-cover flex-shrink-0"
                          />
                          <div>
                            <div className="font-['Poppins'] font-medium text-[15px] text-[#1D1A22]">
                              {approver?.name?.length > 16
                                ? approver?.name?.slice(0, 16) + "..."
                                : approver?.name}
                            </div>

                            <div className="text-[13px] font-[400] text-[#6B7280]">
                              {approver?.employee_id || "-"}
                            </div>
                          </div>
                        </div>

                        {/* Right part - Status */}
                        <div
                          className={`px-3 py-1 rounded-md text-xs font-medium min-h-[24px] flex items-center ${statusColor}`}
                        >
                          {approver?.approver_status}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </p>
          </div>

          {DSRData?.task_document?.length ? (
            <>
              <hr className="px-2.5 h-[2px]" />
              <div className="grid grid-cols-1 gap-x-6">
                <div className="flex flex-col gap-[7px] h-full">
                  <span className="font-[Poppins] font-meduim text-base  text-[#99A1B7]">
                    Attachment
                  </span>
                  <div className="grid grid-cols-2">
                    {DSRData?.task_document?.map((task, index) => (
                      <Receipt
                        key={index}
                        fileName={
                          task?.split("/")?.pop() || `Receipt ${index + 1}`
                        }
                        fileSize={`${(Math?.random() * 2 + 1)?.toFixed(2)} MB`}
                        onView={() => handleView(task)}
                      />
                    ))}
                  </div>
                </div>
                {selectedFileUrl ? (
                  <ModalViewer
                    isOpen={!!selectedFileUrl}
                    onRequestClose={closeModal}
                    fileUrl={selectedFileUrl}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
