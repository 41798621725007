import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";
import { useGoogleMaps } from "../../../Context/GoogleMapContext";
import pin from "../../../assets/location.svg";
import clock from "../../../assets/clock.svg";
import dayjs from "dayjs";

interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string | null;
  department_id: number;
  group_id: number;
}

interface Location {
  lat: number;
  lng: number;
  profileImage: string | null;
  id: number;
  user_id: number;
  created_at: string;
  location: string;
  User: IUser;
}

interface Props {
  locations: Location[];
  containerStyle?: React.CSSProperties;
}

const jitterLocations = (locations: Location[]) => {
  const jittered: Location[] = [];
  const seen = new Map<string, number>();

  locations.forEach((location) => {
    const key = `${location.lat},${location.lng}`;
    let count = seen.get(key) || 0;
    seen.set(key, count + 1);

    const jitterAmount = count * 0.000001;
    jittered.push({
      ...location,
      lat: location.lat + jitterAmount,
      lng: location.lng + jitterAmount,
    });
  });

  return jittered;
};

const MultipleLocationsMap: React.FC<Props> = ({
  locations,
  containerStyle,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const { isLoaded } = useGoogleMaps();

  const [hoveredLocation, setHoveredLocation] = useState<Location | null>(null);

  const jitteredLocations = useMemo(
    () => jitterLocations(locations),
    [locations]
  );

  const finalContainerStyle = {
    width: "100%",
    height: "500px",
    ...containerStyle,
  };

  useEffect(() => {
    if (!isLoaded || !mapRef.current || jitteredLocations.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    jitteredLocations.forEach((location) => bounds.extend(location));

    if (!bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds);
    }
  }, [isLoaded, jitteredLocations]);

  if (!isLoaded || !window.google) return <p>Loading maps...</p>;

  return (
    <GoogleMap
      mapContainerStyle={finalContainerStyle}
      zoom={10}
      onLoad={(map) => {
        mapRef.current = map;
      }}
      center={
        jitteredLocations.length > 0
          ? jitteredLocations[0]
          : { lat: 37.7749, lng: -122.4194 }
      }
      options={{
        minZoom: 2,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        disableDefaultUI: false,
        keyboardShortcuts: false,
        styles: [
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          { featureType: "transit", stylers: [{ visibility: "off" }] },
          {
            featureType: "road.highway",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      }}
    >
      {jitteredLocations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.lat, lng: location.lng }}
          onMouseOver={() => setHoveredLocation(location)}
          onMouseOut={() => setHoveredLocation(null)}
        />
      ))}

      {hoveredLocation && (
        <InfoWindow
          position={{ lat: hoveredLocation.lat, lng: hoveredLocation.lng }}
          options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center">
              <img src={clock} alt="time" className="h-[12px] w-[12px]" />
              <span className="text-[12px] font-medium text-[#020617]">
                Last Updated at-{" "}
                {hoveredLocation?.created_at
                  ? dayjs(hoveredLocation?.created_at).format("h:mm A")
                  : "-"}
              </span>
            </div>
            <div className="flex gap-1 items-center">
              <img src={pin} alt="location" className="h-[12px] w-[12px]" />
              <span className="text-[12px] font-medium text-[#938F99]">
                {hoveredLocation?.location ?? "-"}
              </span>
            </div>
          </div>
        </InfoWindow>
      )}

      {jitteredLocations.length > 1 && (
        <Polyline
          path={jitteredLocations}
          options={{
            strokeColor: "#1662cc",
            strokeOpacity: 0.8,
            strokeWeight: 2.5,
          }}
        />
      )}
    </GoogleMap>
  );
};

export default MultipleLocationsMap;
