import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import upload from "../../image/System Icons.svg";
import { api_key_work as api_key } from "../../utils/helperData";
import { Close } from "@mui/icons-material";
import pdf from "../../assets/pdfImage.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileImage,
  faFileWord,
  faFileArchive,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faFilePowerpoint,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axiosInstance from "../../app/api/axiosInstance";

interface IFileType {
  pdf: Element;
  jpg: Element;
  jpeg: Element;
  png: Element;
  doc: Element;
  docx: Element;
  zip: Element;
  rar: Element;
  xls: Element;
  xlsx: Element;
  csv: Element;
  ppt: Element;
  pptx: Element;
  default: Element;
}
const FileIcon = ({ extension }: { extension: keyof IFileType }) => {
  const iconMap = {
    pdf: (
      <FontAwesomeIcon
        icon={faFilePdf}
        className="text-red-500 h-full w-full"
      />
    ),
    jpg: (
      <FontAwesomeIcon
        icon={faFileImage}
        className="text-blue-500 h-full w-full"
      />
    ),
    jpeg: (
      <FontAwesomeIcon
        icon={faFileImage}
        className="text-blue-500 h-full w-full"
      />
    ),
    png: (
      <FontAwesomeIcon
        icon={faFileImage}
        className="text-blue-500 h-full w-full"
      />
    ),
    doc: (
      <FontAwesomeIcon
        icon={faFileWord}
        className="text-blue-700 h-full w-full"
      />
    ),
    docx: (
      <FontAwesomeIcon
        icon={faFileWord}
        className="text-blue-700 h-full w-full"
      />
    ),
    zip: (
      <FontAwesomeIcon
        icon={faFileArchive}
        className="text-yellow-500 h-full w-full"
      />
    ),
    rar: (
      <FontAwesomeIcon
        icon={faFileArchive}
        className="text-yellow-500 h-full w-full"
      />
    ),
    txt: (
      <FontAwesomeIcon
        icon={faFileAlt}
        className="text-gray-500 h-full w-full"
      />
    ),
    xls: (
      <FontAwesomeIcon
        icon={faFileExcel}
        className="text-green-600 h-full w-full"
      />
    ),
    csv: (
      <FontAwesomeIcon
        icon={faFileExcel}
        className="text-green-600 h-full w-full"
      />
    ),
    xlsx: (
      <FontAwesomeIcon
        icon={faFileExcel}
        className="text-green-600 h-full w-full"
      />
    ),
    ppt: (
      <FontAwesomeIcon
        icon={faFilePowerpoint}
        className="text-orange-500"
        size="lg"
      />
    ),
    pptx: (
      <FontAwesomeIcon
        icon={faFilePowerpoint}
        className="text-orange-500 h-full w-full"
      />
    ),
    default: (
      <FontAwesomeIcon icon={faFile} className="text-gray-400 h-full w-full" />
    ), // Default icon for unsupported types
  };

  return iconMap[extension] || iconMap["default"];
};

export interface Accept {
  [key: string]: readonly string[];
}
interface FileUploadProps {
  onFilesChange: (
    files: { originalName: string; url: string; fileType: string }[]
  ) => void;
  accept?: Accept;
  text?: string;
  fullWidth?: boolean;
  disable?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  accept,
  text = "PDF File or WORD File",
  fullWidth = false,
  disable,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles([...files, ...acceptedFiles]);
    uploadFiles([...files, ...acceptedFiles]);
  };

  const removeFile = (file: File) => {
    const updatedFiles = files.filter((f) => f !== file);
    setFiles(updatedFiles);
    onFilesChange(
      updatedFiles.map((file) => ({
        originalName: file.name,
        url: URL.createObjectURL(file),
        fileType: file.type.split("/").pop() || "",
      }))
    );
  };

  const uploadFiles = async (filesToUpload: File[]) => {
    setUploading(true);
    try {
      const formData = new FormData();
      filesToUpload.forEach((file) => {
        formData.append("files", file);
      });
      const access_token = localStorage.getItem("access_token");

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API}/api/v1/upload-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );

      const result = response.data.result;
      onFilesChange(result);
    } catch (error: any) {
      toast.error("Error uploading files.");
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
    disabled: disable,
  });

  return (
    <div className={`p-2 ${fullWidth ? " w-full" : ""}`}>
      {files?.length > 0 ? (
        <div className="mt-2 mb-4 grid grid-cols-3 lg:grid-cols-4 gap-3">
          {files?.map((file, index) => {
            const extension = file?.name
              ?.split(".")
              ?.pop()
              ?.toLowerCase() as keyof IFileType;
            return (
              <div
                key={index}
                className="flex items-center justify-between border-[#EDEDED] p-2 border rounded-md mb-2 w-full"
              >
                <div className="h-[40px] w-[90%] flex items-center gap-2.5">
                  <div className="h-[32px] w-[32px] rounded-full">
                    <FileIcon extension={extension} />
                  </div>
                  <div className="h-full flex flex-col justify-center w-[85%]">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22] max-w-full whitespace-nowrap overflow-hidden text-ellipsis">
                      {file?.name}
                      {/* {file?.name.length > 11 ? `${file.name.split(".")[0]?.slice(0, 8)}.${file.name.split(".").pop()}` : `${file?.name}`} */}
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {(file?.size / 1024 / 1024)?.toFixed(2)} MB
                    </span>
                  </div>
                </div>

                <button
                  onClick={() => removeFile(file)}
                  className="text-[#79747E]"
                >
                  <Close />
                </button>
              </div>
            );
          })}
        </div>
      ) : null}

      <div
        {...getRootProps()}
        className="border-2 mb-4 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer"
      >
        <input {...getInputProps()} />
        <p>
          <img className="m-[auto]" src={upload} alt="" />
        </p>
        <p>
          <span className="text-[#4B5563] font-[400] font-[Poppins] text-[16px]">
            Drop here to attach or
          </span>{" "}
          <span className="text-blue-500">upload</span>
        </p>
        <p className="text-[12px] text-[#4B5563] font-[400] font-[Poppins]">
          {text} | Max size: 10MB
        </p>
        {uploading && <p>Uploading...</p>}
      </div>
    </div>
  );
};

export default FileUpload;
