import {
  api_key_work as api_key,
  base_api,
  // session_query,
} from "../../../utils/helperData";
import axios from "axios";
import {
  FETCH_CLIENTS_LIST,
  FETCH_MILESTONE_LIST,
  FETCH_MODULE_LIST,
  FETCH_PROJECT_LIST,
  FETCH_RESOURCES_LIST,
  FETCH_REVIEWER_DATA,
  FETCH_TASk_LIST,
  STATUS_LIST,
} from "../api";
import { Dayjs } from "dayjs";
import moment from "moment";
import {
  IAddress,
  ICR,
  IResource,
  Milestone,
} from "../../ProjectManagement/Project/AddProject/AddProjectDetails";
import axiosInstance from "../../../app/api/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// const session_query = `session_start=${localStorage.getItem(
//   "start_session"
// )}&session_end=${localStorage.getItem("end_session")}`;
// const session_startR = useSelector(
//   (state: RootState) => state.sessionSlice.session_start
// );
// const session_endR = useSelector(
//   (state: RootState) => state.sessionSlice.session_end
// );
// const session_query = `session_start=${session_startR}&session_end=${session_endR}`;

export const Fetch_Clients_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  startDate: Date | null,
  endDate: Date | null,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  // Initial query string
  // let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}&${session_query}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  // Adding search term to the query if provided
  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // Handling start and end dates
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  // Making the API call
  return axiosInstance.get(`${FETCH_CLIENTS_LIST}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

// console.log("Access Token:", access_token);

export const createProjectsApi = async ({
  project_name,
  currency,
  client,
  start_date,
  end_date,
  ChangeRequests,
  status,
  project_documents,
  project_type,
  description,
  billing_type,
  milestones,
  resources,
  estimated_hours,
  contractual_cost,
  addressses,
  approval_code,
}: {
  project_name: string | null;
  currency: string | null;
  client: string | null;
  start_date: string | null | Dayjs;
  end_date: string | null | Dayjs;
  ChangeRequests: ICR[];
  status: string | null;
  project_documents: string[];
  project_type: string | null;
  description: string | null;
  billing_type: string | null;
  milestones: Milestone[] | null;
  resources: IResource[] | null;
  estimated_hours: number | null;
  contractual_cost: number | null;
  addressses: (IAddress | null)[];
  approval_code: string | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    project_name: project_name,

    start_date: start_date,
    status: status,
    // project_document: project_documents,
    project_type: project_type,
    approval_code: approval_code,
    // billing_type: billing_type,
    // description: description,
    estimated_hours: estimated_hours,
    contractual_cost: contractual_cost,
    addresses: addressses,
  };

  if (billing_type) data.billing_type = billing_type;
  if (currency) data.currency = currency;
  if (client) data.client_id = client;
  if (description) data.description = description;
  if (end_date !== "Invalid Date" && end_date) data.end_date = end_date;
  if (resources && resources?.length > 0) {
    data.ProjectResources = resources;
  }
  if (milestones) {
    data.ProjectMilestones = milestones;
  }
  if (ChangeRequests && ChangeRequests?.length > 0) {
    data.ChangeRequests = ChangeRequests;
  }
  if (project_documents && project_documents?.length > 0) {
    data.project_document = project_documents;
  }

  // if (estimated_hours) {
  //   data.estimated_hours = estimated_hours;
  // }

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/project`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
export const editProjectDetail = async ({
  project_name,
  currency,
  client,
  start_date,
  end_date,
  status,
  project_documents,
  project_type,
  description,
  billing_type,
  projectId,
  milestones,
  resources,
  ChangeRequests,
  estimated_hours,
  contractual_cost,
  addressses,
  approval_code
}: {
  project_name: string | null;
  currency: string | null;
  client: string | null;
  start_date: string | null | Dayjs;
  end_date: string | null | Dayjs;
  approval_code: string| null;
  status: string | null;
  project_documents: string[];
  project_type: string | null;
  description: string | null;
  billing_type: string | null;
  projectId: string;
  milestones?: Milestone[];
  resources?: IResource[] | null;
  ChangeRequests?: ICR[];
  estimated_hours: number | null;
  contractual_cost: number | null;
  addressses: (IAddress | null)[];
}) => {
  const access_token = localStorage.getItem("access_token");
  if (!access_token) {
    throw new Error("Access token is missing. Please log in again.");
  }

  const body: any = {
    project_name: project_name,
    // currency: currency,
    // client_id: client_id,

    start_date: start_date,
    // end_date: end_date,
    // billing_type: billing_type,
    status: status,
    // project_document: project_documents,
    project_type: project_type,
    addresses: addressses,
    // description: description,
    approval_code:approval_code,
    estimated_hours: estimated_hours,
    contractual_cost: contractual_cost,
  };
  // if (estimated_hours && estimated_hours !== 0) {
  //   body.estimated_hours = estimated_hours;
  // }
  if (billing_type) {
    body.billing_type = billing_type;
  }
  if (milestones) {
    body.ProjectMilestones = milestones;
  }
  if (resources) {
    body.ProjectResources = resources;
  }
  if (ChangeRequests && ChangeRequests?.length > 0) {
    body.ChangeRequests = ChangeRequests;
  }
  if (currency) body.currency = currency;
  if (client) body.client_id = client;
  if (description) body.description = description;
  if (end_date !== "Invalid Date" && end_date) body.end_date = end_date;
  if (project_documents && project_documents?.length > 0)
    body.project_document = project_documents;

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/project/${projectId}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const createResourcesApi = async (
  project_resources: {
    user_id: number;
    project_id: number;
    employee_type: string;
  }[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    project_resources: project_resources,
  };

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/project-resources`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Resources_List = (
  customer_id: number | string | undefined,
  searchTerm: string,
  numOfItems: number | null,
  page: number,
  filterByEmployeeType: boolean = false
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?project_id=${customer_id}&page=${page}&sort_by=employee_type&sort_order=ASC&filterByEmployeeType=${filterByEmployeeType}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (numOfItems) {
    query += `&size=${numOfItems}`;
  }
  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axiosInstance.get(`${FETCH_RESOURCES_LIST}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Status_List = ({ userId }: { userId?: string }) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (userId) {
    query += `&user_id=${userId}`;
  }
  // return axiosInstance.get(`${STATUS_LIST}${query}`, {

  return axiosInstance.get(`${STATUS_LIST}?${session_query}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createTaskApi = async (
  task_name: string,
  project_id: string | undefined,
  estimated_hours: number | null,
  start_date: string | null | Dayjs,
  end_date: string | null | Dayjs,
  description: string,
  status: string,
  task_document: string[],
  task_resources: {
    user_id: number;
    project_id: number;
  }[],
  module?: string,
  milestone_id?: number | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    task_name: task_name,
    project_id: project_id,
    //  estimated_hours:estimated_hours,
    //  actual_hours:actual_hours,
    start_date: start_date,
    //  end_date:end_date,
    //  description:description,
    status: status,
    // task_document: task_document,
  };
  if (start_date && start_date !== "Invalid Date") data.start_date = start_date;
  if (task_document?.length > 0) data.task_document = task_document;
  if (task_resources?.length > 0) data.task_resources = task_resources;
  if (estimated_hours) {
    data.estimated_hours = estimated_hours;
  } else {
    data.estimated_hours = 0;
  }
  if (end_date !== "Invalid Date" && end_date) data.end_date = end_date;
  if (description !== "") data.description = description;
  if (module) data.module_name = module;
  if (milestone_id) data.milestone_id = milestone_id;

  try {
    const response = await axiosInstance.post(`${base_api}/api/v1/task`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};
export const CreateModuleApi = async ({
  module_name,
  project_id,
  description,
}: {
  module_name: string;
  project_id: string;
  description: string;
}) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    module_name: module_name,
    project_id: project_id ? parseInt(project_id) : null,
  };

  if (description) data.description = description;

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/module`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    console.log({ error });
  }
};
export const UploadTaskFileApi = async ({
  docs,
  projectId,
}: {
  docs: string[];
  projectId: number | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    fileUrl: docs[0],
  };

  try {
    const response = await axiosInstance.post(
      `${base_api}/api/v1/upload-task?project_id=${projectId}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    console.log({ error });
  }
};

export const Fetch_Task_List = ({
  project_id,
  numOfItems,
  page,
  searchTerm,
  module_id,
  status,
  date,
}: {
  project_id: string | undefined;
  numOfItems: number;
  page: number;
  searchTerm?: string;
  status?: string | null;
  date?: Dayjs | null;
  module_id?: string;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?project_id=${project_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  if (module_id) {
    query += `&module_id=${module_id}`;
  }
  if (status && status !== "") {
    query += `&status=${status}`;
  }
  if (date) {
    const new_date = date.format("YYYY-MM-DD");
    query += `&date=${new_date}`;
  }
  if (searchTerm) {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axiosInstance.get(`${FETCH_TASk_LIST}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Milestone_List = ({
  customer_id,
  numOfItems,
  page,
  search,
}: {
  customer_id: string;
  numOfItems?: number;
  page?: number;
  search?: string;
}) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = `project_id=${customer_id}`;

  if (numOfItems && page) {
    query += `&size=${numOfItems}&page=${page}`;
  }

  if (search && search !== "") {
    query += `&search=${search}`;
  }
  // if (searchTerm) {
  //   query += `&search=${searchTerm}`;
  // }

  return axiosInstance.get(`${FETCH_MILESTONE_LIST}?${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Module_Data = (customer_id: string | undefined) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `project_id=${customer_id}`;

  return axiosInstance.get(`${FETCH_MODULE_LIST}?${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Task_List_All = (
  customer_id: string | undefined,
  searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm && searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axiosInstance.get(`${FETCH_TASk_LIST}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const fetchEditTask = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `?session_start=${startSession}&session_end=${endSession}`
      : "";

  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/task/${id}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateTask = async (
  id: string,
  task_name: string,
  project_id: string | undefined,
  estimated_hours: number | null,
  start_date: string | null | Dayjs,
  end_date: string | null | Dayjs,
  description: string,
  status: string,
  task_document: string[],
  task_resources: {
    user_id: number;
    action: string;
  }[],
  module?: string,
  milestone_id?: number | null
) => {
  const access_token = localStorage.getItem("access_token");
  const body: any = {
    task_name: task_name,
    project_id: project_id,
    // estimated_hours:estimated_hours,
    // actual_hours:actual_hours,
    start_date: start_date,
    // end_date:end_date,
    // description:description,
    estimated_hours: estimated_hours,
    status: status,
  };
  if (task_document?.length > 0) body.task_document = task_document;
  if (task_resources?.length > 0) body.task_resources = task_resources;

  if (end_date !== "Invalid Date" && end_date) body.end_date = end_date;
  if (description && description !== "") body.description = description;
  if (module) body.module_name = module;
  if (milestone_id) body.milestone_id = milestone_id;
  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/task/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Project_List = (
  // customer_id: string|undefined,
  searchTerm: string,
  numOfItems: number,
  page: number,
  start_date: Date | null,
  end_date: Date | null,
  customer_id: Number,
  value: string,
  project_type?: string | null,
  billing_type?: string | null,
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = `?size=${numOfItems}&page=${page}&customer_id=${customer_id}`;
  if (value === "Delay") {
    query += `&project_status=${value}`;
  } else if (value !== "") {
    query += `&status=${value}`;
  }
  //let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (project_type !== "") {
    query += `&project_type=${project_type}`;
  }

  if (billing_type !== "") {
    query += `&billing_type=${billing_type}`;
  }

  if (searchTerm && searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  // Handle API request with axios
  return axiosInstance
    .get(`${FETCH_PROJECT_LIST}${query}${session_query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: `Bearer ${access_token}`,
      },
    })
    .catch((error) => {
      // Handle errors appropriately (e.g., token expired or API issue)
      console.error("API Request Failed:", error);
      throw error;
    });
};

export const fetchEditProject = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `?session_start=${startSession}&session_end=${endSession}`
      : "";
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/project/${id}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateProject = async (
  id: string,
  project_name: string | null,
  currency: string | null,
  client_id: string | null,
  estimated_cost: number | null,
  start_date: string | null | Dayjs,
  end_date: string | null | Dayjs,
  estimated_hours: number | null,
  status: string | null,
  project_document: string[],
  project_type: string | null,
  description: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `?session_start=${startSession}&session_end=${endSession}`
      : "";
  const body: any = {
    project_name: project_name,
    // currency: currency,
    // client_id: client_id,
    estimated_cost: estimated_cost,
    start_date: start_date,
    // end_date: end_date,
    estimated_hours: estimated_hours,
    status: status,
    project_document: project_document,
    project_type: project_type,
    // description: description,
  };
  if (currency) body.currency = currency;
  if (client_id && client_id !== "") body.client_id = client_id;
  if (description) body.description = description;
  if (end_date && end_date !== "Invalid Date") body.end_date = end_date;

  try {
    const response = await axiosInstance.put(
      `${base_api}/api/v1/project/${id}${session_query}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Edit_Resources_List_List = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `?session_start=${startSession}&session_end=${endSession}`
      : "";
  return fetch(`${FETCH_RESOURCES_LIST}${session_query}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const updateResources = async (id: string, billing_cost: number) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `?session_start=${startSession}&session_end=${endSession}`
      : "";
  const body = {
    billing_cost: billing_cost,
  };

  try {
    const response = await axiosInstance.patch(
      `${base_api}/api/v1/project-resources/${id}${session_query}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// export const Fetch_Project_Overview = (
//   project_id: number,
// ) => {
//   const access_token = localStorage.getItem("access_token");
//   // let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

//   return axiosInstance.get(`${FETCH_PROJECT_OVERVIEW}${project_id}`, {
//     headers: {
//       "x-api-key": api_key,
//       Authorization: "Bearer " + access_token,
//     },
//   });
// };

export const fetchProjectOverview = async (
  id: string,
  start_date?: Date | null,
  end_date?: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/projects/${id}/overview?${query}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchProjectOngoingTasks = async (
  id: string,
  size?: number,
  start_date?: Date | null,
  end_date?: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (size) {
    query += `&size=${size}`;
  }
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/projects/${id}/ongoing-task?${query}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchProjectResources = async (
  id: string,
  size?: number,
  start_date?: Date | null,
  end_date?: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  if (size) {
    query += `&size=${size}`;
  }
  if (start_date && end_date) {
    const start = moment(start_date).format("YYYY-MM-DD");
    const end = moment(end_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/projects/${id}/resources?${query}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchProjectRunningCost = async (
  id: string,
  // period?: string,
  from_date?: Date | null,
  to_date?: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  // if (period) {
  //   query += `?period=${period}`;
  // }
  // if (from_date && to_date) {
  //   query += `&start_date=${from_date}&end_date=${to_date}`;
  // }
  if (from_date && to_date) {
    const start = moment(from_date).format("YYYY-MM-DD");
    const end = moment(to_date).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }
  // if (from_date) {
  //   query += `&from_date=${from_date}`;
  // }
  // if (to_date) {
  //   query += `&to_date=${to_date}`;
  // }
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/projects/${id}/timelogs?${query}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchProjectTimeline = async (
  id: string,
  // period?: string,
  from_date?: Date | null,
  to_date?: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";
  let query = "";
  // if (period) {
  //   query += `&period=${period}`;
  // }
  if (from_date && to_date) {
    query += `&start_date=${from_date}&end_date=${to_date}`;
  }
  // if (to_date) {
  //   query += `&to_date=${to_date}`;
  // }
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/projects/${id}/timeline?${query}${session_query}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// new all list data call

export const Fetch_Add_All_Resource_List = (
  customer_id: number,
  searchTerm: string
) => {
  const access_token = localStorage.getItem("access_token");

  let query = `?customer_id=${customer_id}&size=200&page=1`;
  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }
  return axiosInstance.get(`${FETCH_REVIEWER_DATA}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Projects_Chart_Data = (
  customer_id: number,
  groupIds?: (number | null)[] | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = `customer_id=${customer_id}`;

  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(
    `${base_api}/api/v1/project-overview?${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Projects_Stats = (customer_id: number) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = `customer_id=${customer_id}`;

  return axiosInstance.get(
    `${base_api}/api/v1/project/count?${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Profitable_Projects = (
  customer_id: number,
  page: number,
  size: number,
  groupIds?: (number | null)[] | null
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = `customer_id=${customer_id}`;

  // if (page && size) {
  //   query += `&page=${page}&size=${size}`;
  // }
  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  return axiosInstance.get(
    `${base_api}/api/v1/project-profit-list?${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Loss_Making_Projects = (
  customer_id: number,
  page?: number,
  size?: number
) => {
  const access_token = localStorage.getItem("access_token");
  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  let query = `customer_id=${customer_id}`;

  // if (page && size) {
  //   query += `&page=${page}&size=${size}`;
  // }

  return axiosInstance.get(
    `${base_api}/api/v1/project-loss-list?${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};
