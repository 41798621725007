import React, { useCallback, useEffect, useState } from "react";
// import view from "../../../assets/Crud_Icons/view.svg";
import eye from "../../../assets/Crud_Icons/view.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../../common/Pagenation";
import { useNavigate } from "react-router-dom";
//import download from "../../../image/Vector (1).png";
import filter from "../../../assets/filter.svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../common/Button";
import { ArrowUpDown, Download, Info } from "lucide-react";
import DynamicTable from "../../common/DynamicTable";
import TableSkeleton from "../../common/TableSkeleton";
import { Close } from "@mui/icons-material";
import AttFilter, { FilterData } from "../../Filter/AttFilter";
import { setFilterPersistData } from "../../../redux/reducers/FilterPersist/FilterPersistSlice";
import { RootState } from "../../../redux/store";
import PersistedSearch from "../../EmployeeManagement/EmployeeListSearch";
import UserBadge from "../../common/UserBadge";
import { Fetch_Deductions_List } from "../../common/services/Attendence";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../common/Tooltip/Tooltip";
import toast from "react-hot-toast";
import moment from "moment";
import axiosInstance from "../../../app/api/axiosInstance";
import dayjs from "dayjs";
import { datePickerToolbarClasses } from "@mui/x-date-pickers";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  profile_image: string;
  employee_id: string;
  department: string;
}

interface DeductionDetail {
  count: number;
  loss_of_pay_per_day: string;
  dates: string[];
}

interface DeductionSummary {
  delayed_check_in: DeductionDetail;
  missed_checkout: DeductionDetail;
  unauthorised_wfh: DeductionDetail;
  not_checked_in: DeductionDetail;
  unauthorised_leave: DeductionDetail;
}

interface IDeductionList {
  userList: User;
  deductionSummary: DeductionSummary;
  total_loss_of_pay: number | string;
}

const formatLabel = (key: string) => {
  const labelMap: Record<string, string> = {
    delayed_check_in: "Delayed Check In",
    missed_checkout: "Missed Check Out",
    unauthorised_wfh: "Not Approved WFH",
    not_checked_in: "Not Checked In",
    unauthorised_leave: "Not Approved Leave",
  };
  return labelMap[key] ?? key.replace(/_/g, " ");
};

const Deductions = () => {
  const dispatch = useDispatch();
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [tableData, setTableData] = useState<IDeductionList[]>([]);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setPage(1);
    setIsOpen(false);
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handlePageChange(value: number) {
    setPage(value);
  }

  const fetchDeductionData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Deductions_List({
        customer_id: customer_id,
        numOfItems: numOfItems,
        page: page,
        group_id: Number(busId),
        department_id: Number(depId),
        searchTerm: searchTerm ?? "",
        startDate: dates ? dates[0] : null,
        endDate: dates ? dates[1] : null,
      });

      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.result?.error);
    }
    setLoading(false);
  }, [customer_id, numOfItems, page, busId, depId, searchTerm, dates]);

  useEffect(() => {
    fetchDeductionData();
  }, [fetchDeductionData]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const handleDelete = () => {
    setBusId(null);
    setDepId(null);
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
      })
    );
    setPage(1);
  };

  const columns2: ColumnDef<IDeductionList>[] = [
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.userList?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item?.userList} />;
      },
    },
    {
      accessorKey: "department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.userList?.department ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.userList?.department ?? "-";
        return <div className="py-2.5">{name}</div>;
      },
    },
    {
      accessorKey: "total_loss_of_pay",
      header: ({ column }) => (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="p-0 flex items-center"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Total Loss of Pay (in days)
                <Info size={14} className="ml-1 cursor-pointer" />
                <ArrowUpDown className="ml-2 size-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className="bg-white p-2 shadow-md rounded-md text-sm text-gray-600"
              side="top"
            >
              {Object.entries(tableData?.[0]?.deductionSummary ?? {})?.map(
                ([key, value]: any) => (
                  <div key={key} className="flex justify-between">
                    <span>{formatLabel(key)}:</span>
                    <span>{value?.loss_of_pay_per_day}%</span>
                  </div>
                )
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ),
      accessorFn: (row) => {
        const deductionSummary = row?.deductionSummary ?? {};
        const totalCount = parseFloat(
          Object.values(deductionSummary || {})
            ?.reduce((acc, item) => {
              const count = item?.count ?? 0;
              const lossOfPayPercent = item?.loss_of_pay_per_day ?? 0;

              return acc + count * (lossOfPayPercent / 100);
            }, 0)
            ?.toFixed(2)
        );
        return totalCount;
      },
      cell: ({ row }) => {
        const deductionSummary = row?.original?.deductionSummary ?? {};
        const totalCount = parseFloat(
          Object.values(deductionSummary || {})
            ?.reduce((acc, item) => {
              const count = item?.count ?? 0;
              const lossOfPayPercent = item?.loss_of_pay_per_day ?? 0;

              return acc + count * (lossOfPayPercent / 100);
            }, 0)
            ?.toFixed(2)
        );

        return <div className="">{totalCount}</div>;
      },
    },
    {
      accessorKey: "delayed_check_in",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Delayed Check in
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.deductionSummary?.delayed_check_in?.count ?? "-",
      cell: ({ row }) => {
        const data = row?.original?.deductionSummary?.delayed_check_in;
        const count = data?.count ?? 0;

        if (!count) return <div>{count}</div>;

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="">{count}</div>
              </TooltipTrigger>
              <TooltipContent className="bg-white border border-gray-300 shadow-lg p-2 rounded-md">
                <div className="flex flex-col gap-1 text-grey300">
                  {data?.dates?.map((date, index) => {
                    const formattedDate = new Date(date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    );
                    return (
                      <div
                        key={index}
                        className="text-xs font-[Poppins]  text-grey300"
                      >
                        {formattedDate} (
                        {data?.loss_of_pay_per_day
                          ? parseInt(data?.loss_of_pay_per_day) / 100
                          : "-"}
                        )
                      </div>
                    );
                  })}
                  <hr className="border-gray-300" />
                  <div className="text-xs font-[Poppins] flex justify-between items-center p-[2px]">
                    <span className="font-medium  text-grey300">Total:</span>
                    <span className="font-medium  text-light-black">
                      {count
                        ? (count * parseInt(data?.loss_of_pay_per_day)) / 100
                        : "-"}
                    </span>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      accessorKey: "missed_checkout",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Missed Checkout
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.deductionSummary?.missed_checkout?.count ?? "-",
      cell: ({ row }) => {
        const data = row?.original?.deductionSummary?.missed_checkout;
        const count = data?.count ?? 0;

        if (!count) return <div>{count}</div>;

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="">{count}</div>
              </TooltipTrigger>
              <TooltipContent className="bg-white border border-gray-300 shadow-lg p-2 rounded-md">
                <div className="flex flex-col gap-1 text-grey300">
                  {data?.dates?.map((date, index) => {
                    const formattedDate = new Date(date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    );
                    return (
                      <div
                        key={index}
                        className="text-xs font-[Poppins]  text-grey300"
                      >
                        {formattedDate} (
                        {data?.loss_of_pay_per_day
                          ? parseInt(data?.loss_of_pay_per_day) / 100
                          : "-"}
                        )
                      </div>
                    );
                  })}
                  <hr className="border-gray-300" />
                  <div className="text-xs font-[Poppins] flex justify-between items-center p-[2px]">
                    <span className="font-medium  text-grey300">Total:</span>
                    <span className="font-medium  text-light-black">
                      {count
                        ? (count * parseInt(data?.loss_of_pay_per_day)) / 100
                        : "-"}
                    </span>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      accessorKey: "unauthorised_wfh",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Not Approved WFH
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.deductionSummary?.unauthorised_wfh?.count ?? "-",
      cell: ({ row }) => {
        const data = row?.original?.deductionSummary?.unauthorised_wfh;
        const count = data?.count ?? 0;

        if (!count) return <div>{count}</div>;

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="">{count}</div>
              </TooltipTrigger>
              <TooltipContent className="bg-white border border-gray-300 shadow-lg p-2 rounded-md">
                <div className="flex flex-col gap-1 text-grey300">
                  {data?.dates?.map((date, index) => {
                    const formattedDate = new Date(date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    );
                    return (
                      <div
                        key={index}
                        className="text-xs font-[Poppins]  text-grey300"
                      >
                        {formattedDate} (
                        {data?.loss_of_pay_per_day
                          ? parseInt(data?.loss_of_pay_per_day) / 100
                          : "-"}
                        )
                      </div>
                    );
                  })}
                  <hr className="border-gray-300" />
                  <div className="text-xs font-[Poppins] flex justify-between items-center p-[2px]">
                    <span className="font-medium  text-grey300">Total:</span>
                    <span className="font-medium  text-light-black">
                      {count
                        ? (count * parseInt(data?.loss_of_pay_per_day)) / 100
                        : "-"}
                    </span>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      accessorKey: "not_checked_in",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Not Checked In
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.deductionSummary?.not_checked_in?.count ?? "-",
      cell: ({ row }) => {
        const data = row?.original?.deductionSummary?.not_checked_in;
        const count = data?.count ?? 0;

        if (!count) return <div>{count}</div>;

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="">{count}</div>
              </TooltipTrigger>
              <TooltipContent className="bg-white border border-gray-300 shadow-lg p-2 rounded-md">
                <div className="flex flex-col gap-1 text-grey300">
                  {data?.dates?.map((date, index) => {
                    const formattedDate = new Date(date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    );
                    return (
                      <div
                        key={index}
                        className="text-xs font-[Poppins]  text-grey300"
                      >
                        {formattedDate} (
                        {data?.loss_of_pay_per_day
                          ? parseInt(data?.loss_of_pay_per_day) / 100
                          : "-"}
                        )
                      </div>
                    );
                  })}
                  <hr className="border-gray-300" />
                  <div className="text-xs font-[Poppins] flex justify-between items-center p-[2px]">
                    <span className="font-medium  text-grey300">Total:</span>
                    <span className="font-medium  text-light-black">
                      {count
                        ? (count * parseInt(data?.loss_of_pay_per_day)) / 100
                        : "-"}
                    </span>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      accessorKey: "unauthorised_leave",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Not Approved Leave
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.deductionSummary?.unauthorised_leave?.count ?? "-",
      cell: ({ row }) => {
        const data = row?.original?.deductionSummary?.unauthorised_leave;
        const count = data?.count ?? 0;

        if (!count) return <div>{count}</div>;

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="">{count}</div>
              </TooltipTrigger>
              <TooltipContent className="bg-white border border-gray-300 shadow-lg p-2 rounded-md">
                <div className="flex flex-col gap-1 text-grey300">
                  {data?.dates?.map((date, index) => {
                    const formattedDate = new Date(date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    );
                    return (
                      <div
                        key={index}
                        className="text-xs font-[Poppins]  text-grey300"
                      >
                        {formattedDate} (
                        {data?.loss_of_pay_per_day
                          ? parseInt(data?.loss_of_pay_per_day) / 100
                          : "-"}
                        )
                      </div>
                    );
                  })}
                  <hr className="border-gray-300" />
                  <div className="text-xs font-[Poppins] flex justify-between items-center p-[2px]">
                    <span className="font-medium  text-grey300">Total:</span>
                    <span className="font-medium  text-light-black">
                      {count
                        ? (count * parseInt(data?.loss_of_pay_per_day)) / 100
                        : "-"}
                    </span>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },

    // {
    //   id: "actions",
    //   header: ({ column }) => (
    //     <div className="flex justify-end mr-2">
    //       <Button variant="ghost" className="p-0">
    //         Action
    //       </Button>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div className="flex items-center justify-end gap-2">
    //         <Button
    //           type="button"
    //           className="p-0"
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             // navigate(`/edit-leave/${row?.original?.id}`, {
    //             //   state: { id: row?.original?.id },
    //             // });
    //           }}
    //         >
    //           <img
    //             src={edit}
    //             alt="Edit"
    //             className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
    //           />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleDownload = async () => {
    try {
      const startSession = localStorage.getItem("start_session");
      const endSession = localStorage.getItem("end_session");

      const session_query =
        startSession && endSession
          ? `&session_start=${startSession}&session_end=${endSession}`
          : "";
      let query = `/api/v1/download/pay-deduction?customer_id=${customer_id}`;

      if (dates && dates[0] && dates[1]) {
        const start = moment(dates[0]).format("YYYY-MM-DD");
        const end = moment(dates[1]).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      if (busId) {
        query += `&group_id=${busId}`;
      }
      if (depId) {
        query += `&department_id=${depId}`;
      }
      if (searchTerm && searchTerm !== "") {
        query += `&search=${searchTerm}`;
      }
      const response = await axiosInstance.get(`${query}${session_query}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Pay_Deductions_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
      toast.error("Error downloading report");
    }
  };

  return (
    <>
      <div className="flex w-full text-default-gray-800 font-poppins text-[20px] font-semibold">
        Pay Deductions{" "}
        <span className="text-default-gray-600 font-medium ml-2">
          {!dates || (!dates[0] && !dates[1])
            ? "(Current Month)"
            : `(${dayjs(dates[0]).format("DD MMM YYYY")} - ${dayjs(
                dates[1]
              ).format("DD MMM YYYY")})`}
        </span>
      </div>
      <div className="mt-2 flex justify-between space-x-2">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center gap-5">
            <PersistedSearch label="Search" />
            <div className="h-full flex items-center gap-4">
              <div className="h-full flex justify-end items-center">
                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
              {/* <div className="h-full pl-4">
                <button
                  // onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] leading-[29px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-2">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-18rem)] relative overflow-auto bg-white rounded-s">
            <DynamicTable<IDeductionList>
              // onRowClick={(row) => {
              //   navigate(`/leave-details/${row?.id}`, {
              //     state: { id: row?.id },
              //   });
              // }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      {isOpen && (
        <AttFilter
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          heading="Deduction Filter"
          onApply={handleApply}
          isDate={true}
          isOther
        />
      )}
    </>
  );
};

export default Deductions;
