import React, { useEffect, useState } from "react";
import { Box, Tab, TextField, Modal, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import edit from "../../assets/images/edit.png";
import check from "../../image/checkmark-circle-02.svg";
import cross from "../../assets/cross_new.svg";
import upload from "../../image/Mask group.svg";
import { Details } from "./Details";
import { Policies } from "./Policies";
import { PolicyCategories } from "./PolicyCategories";
import { useLocation, useNavigate } from "react-router-dom";
import { createPolicyCategory } from "../common/services/companyManagement";
import toast from "react-hot-toast";
import CustomTabs from "../common/CustomTabs";
import { Button } from "../common/Button";

const style1 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "791px",
  height: "435px",
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  zIndex: "1001",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const overlayStyle = {
  position: "fixed" as "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
};

const AddPolicyCategoryModal: React.FC<{
  //onSave: () => void;
  onSave: (policyCategory: string, description: string) => void;
  onCancel: () => void;
}> = ({ onSave, onCancel }) => {
  const [policyCategory, setPolicyCategory] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = () => {
    onSave(policyCategory, description);
  };
  return (
    <>
      <div style={overlayStyle} onClick={onCancel}></div>
      <Box sx={style1}>
        <div className="pt-[13px] w-full flex items-center justify-end px-[23px]">
          <img
            src={cross}
            alt="close"
            className="cursor-pointer"
            onClick={onCancel}
          />
        </div>
        <div className="px-[35px] py-[20px]">
          <span className="font-['Poppins'] font-medium text-[20px] leading-4 text-[#262626]">
            Add Policy Category Details
          </span>
          <div className="pt-10 w-full h-[53px] flex flex-col gap-[29px]">
            <div className="w-full h-full">
              <TextField
                required
                id="outlined-required"
                label="Policy Category"
                value={policyCategory}
                onChange={(e) => setPolicyCategory(e.target.value)}
                name="policy_category"
                className="w-[344px]"
              ></TextField>
            </div>
            <div className="w-full h-[117px]">
              <TextField
                required
                id="outlined-required"
                label="Description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{
                  width: "100%",
                  marginTop: "50px",
                  "& .MuiInputBase-root": {
                    height: "117px",
                    alignItems: "flex-start",
                    textWrap: "wrap",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "117px",
                    "& input": {
                      padding: "10px 14px",
                      textWrap: "wrap",
                    },
                  },
                }}
              ></TextField>
            </div>
            <div className="w-full flex flex-row justify-end gap-5 mt-[50px]">
              <button
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            onClick={onCancel}
              >
                <div
            className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
                  Cancel
                </div>
              </button>
              <Button
                type="submit"
                form="form"
                onClick={handleSave}
                variant={"gradient"}
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Save
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const tabs = [
  { label: "Company Details", value: "1" },
  { label: "Policies", value: "2" },
  { label: "Policy Categories", value: "3" },
];

export const CompanyManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [value, setValue] = React.useState<string>("1");
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    navigate("/policy-categories");
  };

  useEffect(() => {
    if (location.pathname === "/company-management") {
      setValue("1");
    } else if (location.pathname === "/policies") {
      setValue("2");
    } else if (location.pathname === "/policy-categories") {
      setValue("3");
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      navigate("/company-management");
    } else if (newValue === "2") {
      navigate("/policies");
    } else if (newValue === "3") {
      navigate("/policy-categories");
    }
  };

  const handleAddclick = () => {
    setShowModal(true);
  };
  const handleAddPolicy = async (
    policyCategory: string,
    description: string
  ) => {
    try {
      const response = await createPolicyCategory(policyCategory, description);
      // console.log(response,"respost--->>>");

      setShowModal(false);
      setOpen(true);
      setTimeout(() => {
        // setOpen(false);
        setShowModal(false);
        setOpen(false);
        setShouldRefresh(true);
        navigate("/policy-categories");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="bg-[#F9F9F9] min-h-[90vh]">
      <div className="p-5">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <img style={{ margin: "auto" }} src={check} alt="" />
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Policy Category Added Successfully!
            </Typography>
          </Box>
        </Modal>
        <Box>
          <TabContext value={value}>
            <div className="flex justify-between pb-3">
              <Box>
                <CustomTabs tabs={tabs} onChange={handleChange} />
              </Box>

              {value === "1" ? (
                <div>
                  <Button
                    variant="gradient"
                    onClick={() => navigate("/edit-company-details")}
                  >
                    <img className="w-5 h-5 mr-2" src={edit} alt="edit icon" />
                    Edit Details
                  </Button>
                </div>
              ) : value === "2" ? (
                <div>
                  <Button
                    variant="gradient"
                    onClick={() => navigate("/add-policy")}
                  >
                    <img
                      className="w-5 h-5 mr-2"
                      src={upload}
                      alt="plus icon"
                    />
                    Create Policy
                  </Button>
                </div>
              ) : value === "3" ? (
                <div>
                  <Button variant="gradient" onClick={handleAddclick}>
                    <img
                      className="w-5 h-5 mr-2"
                      src={upload}
                      alt="plus icon"
                    />
                    Add Policy Category
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
            <TabPanel className="tabpanel" value="1">
              <Details />
            </TabPanel>
            <TabPanel className="tabpanel" value="2">
              <Policies />
            </TabPanel>
            <TabPanel className="tabpanel" value="3">
              <PolicyCategories
                shouldRefresh={shouldRefresh}
                setShouldRefresh={setShouldRefresh}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      {showModal && (
        <AddPolicyCategoryModal
          onSave={handleAddPolicy}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
