import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import filter from "../../assets/filter.svg";
import eye from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import TablePaginationDemo from "../common/Pagenation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import {
  Fetch_Employee_DSR_List,
  multiDSRApproveRejectApi,
} from "../common/services/EmployeeDSR";
import moment from "moment";
import TableSkeleton from "../common/TableSkeleton";
import { Close } from "@mui/icons-material";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import UserBadge from "../common/UserBadge";
import StatusBadge from "../common/StatusBadge";
import PersistedSearch from "../EmployeeManagement/EmployeeListSearch";
import { RootState } from "../../redux/store";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import toast from "react-hot-toast";
import { Checkbox } from "@mui/material";
import { CustomDsrSearch } from "../EmployeeManagement/CustomDsrSearch";

interface IEmployeeDSR {
  id: number;
  date: string;
  duration: string;
  status: string;
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
    profile_image: string;
    employee_id: string;
  };
  Project: {
    id: string;
    project_name: string;
  };
  Task: {
    id: string;
    task_name: string;
  };
  relevant_approver_status: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface DSRData {
  [key: string]: any; // Adjust this based on your data structure
}

export const EmployeeDSR = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IEmployeeDSR[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const projectSearch = useSelector(
    (state: RootState) => state.filterPersist.search2
  );
  const [dsrData, setDsrData] = useState<DSRData[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const fetchDSR = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_DSR_List(
        customer_id,
        searchTerm ?? "",
        numOfItems,
        page,
        Number(busId),
        Number(depId),
        dates ? dates[0] : null,
        dates ? dates[1] : null,
        projectSearch ?? ""
        // Number(selectedBusinessUnit?.id),
        // Number(selectedDepartmentUnit?.id),
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);

        // Report
        const fetchedData: DSRData[] = response.data?.result?.rows;

        const columnMapping: { [key: string]: string } = {
          "User.firstName": "Name",
          date: "Date",
          "Project.project_name": "Project",
          "Task.task_name": "Task",
          notes: "Description",
          duration: "Efforts (Hrs.)",
          createdAt: "Time of filling",
        };
        const filteredData = fetchedData.map((row) => {
          const filteredRow: Partial<DSRData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "createdAt" && row[keys[0]]) {
                  filteredRow[keys[0]] = moment(row[keys[0]]).format(
                    "DD MMM YYYY, HH:mm"
                  );
                } else {
                  filteredRow[keys[0]] = row[keys[0]];
                }
              }
            } else if (keys.length === 2) {
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null;
              }
            }
          });

          return filteredRow;
        });

        setDsrData(filteredData);

        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [
    customer_id,
    searchTerm,
    numOfItems,
    page,
    busId,
    depId,
    dates,
    projectSearch,
  ]);

  useEffect(() => {
    fetchDSR();
  }, [fetchDSR]);

  const handleDownload = () => {
    if (dataFetched) {
      const worksheetData = [
        columns.map((col) => col.displayName),
        ...dsrData.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ),
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Employee DSR Data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleDelete = () => {
    setBusId("");
    setDepId("");
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: "",
        group: "",
        date: [null, null],
      })
    );
    setPage(1);
  };

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const columns2: ColumnDef<IEmployeeDSR>[] = [
    {
      id: "select",
      header: ({ table }) => {
        const hasPending = tableData?.some(
          (row) => row?.relevant_approver_status?.toLowerCase() === "pending"
        );
        if (!hasPending) {
          return null;
        }
        const allPendingRows = table
          .getRowModel()
          ?.rows?.filter(
            (row) =>
              row?.original?.relevant_approver_status?.toLowerCase() ===
              "pending"
          );
        const allSelected = allPendingRows?.every((row) => row.getIsSelected());

        return (
          // <input
          //   type="checkbox"
          //   checked={allSelected}
          //   onChange={(e) => {
          //     const checked = e.target.checked;
          //     allPendingRows.forEach((row) => row.toggleSelected(checked));

          //     setSelectedIds(
          //       checked ? allPendingRows?.map((row) => row.original.id) : []
          //     );
          //   }}
          //   onClick={(e) => e.stopPropagation()}
          // />
          <div className="w-[5px]" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={allSelected}
              onChange={(e) => {
                const checked = e.target.checked;
                allPendingRows.forEach((row) => row.toggleSelected(checked));

                setSelectedIds(
                  checked
                    ? allPendingRows?.map((row) => Number(row.original.id))
                    : []
                );
              }}
            />
          </div>
        );
      },
      cell: ({ row }) => {
        const status = row?.original?.relevant_approver_status ?? "-";
        if (status?.toLowerCase() !== "pending") return null;

        return (
          // <input
          //   type="checkbox"
          //   checked={row.getIsSelected()}
          //   onChange={(e) => {
          //     const id = row?.original?.id;
          //     const checked = e.target.checked;

          //     row.toggleSelected(checked);
          //     setSelectedIds((prev) =>
          //       checked
          //         ? [...prev, id]
          //         : prev?.filter((prevId) => prevId !== id)
          //     );
          //   }}
          //   onClick={(e) => e.stopPropagation()}
          // />
          <div className="w-[5px]" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={row.getIsSelected()}
              onChange={(e) => {
                const id = row?.original?.id;
                const checked = e.target.checked;

                row.toggleSelected(checked);
                setSelectedIds((prev) =>
                  checked
                    ? [...prev, Number(id)]
                    : prev?.filter((prevId) => prevId !== Number(id))
                );
              }}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item?.User} />;
      },
    },
    {
      accessorKey: "project_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Project Name & ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Project?.project_name ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex flex-col justify-between">
            <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
              {item?.Project?.project_name || "-"}
            </span>
            <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
              {item?.Project?.id || ""}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "task_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Task Name & ID
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Task?.task_name ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex flex-col justify-between">
            <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
              {item?.Task?.task_name || "--"}
            </span>
            <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
              {item?.Task?.id || ""}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date
          ? new Date(row?.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date
          ? new Date(row.original?.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Efforts
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          User Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.relevant_approver_status,
      cell: ({ row }) => {
        const status = row?.original?.relevant_approver_status;
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];

        return status ? (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        ) : (
          <span>-</span>
        );
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Timesheet Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status ?? "-";
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];

        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0 mr-4"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/employee-dsr-details`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            {/* <Button
              type="button"
              className="p-0"
              onClick={() => navigate("/edit-dsr-details")}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button> */}
          </div>
        );
      },
    },
  ];

  const handleMultiSelect = async (status: string) => {
    try {
      if (selectedIds?.length) {
        const response = await multiDSRApproveRejectApi({
          status,
          selectedIds,
        });

        if (response?.status === 200) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setSelectedIds([]);
      fetchDSR();
    }
  };

  return (
    <>
      <div className="px-4 pt-6 pb-12 bg-[#F9F9F9] min-h-[90vh]">
        <div className="flex flex-col gap-5">
          <div className="h-12">
            <div className="h-[30px]">
              <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
                Employee DSR
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="">
              <div className="h-full flex justify-between items-center">
                <div className="flex gap-3">
                  <PersistedSearch label="Search with employee name" />
                  <CustomDsrSearch label="Search with project name" />
                </div>
                <div className="h-full flex items-center gap-5">
                  <div className="h-full flex justify-end items-center">
                    {(busId && busId !== "") ||
                    (depId && depId !== "") ||
                    (dates && !(dates[0] === null && dates[1] === null)) ? (
                      <button
                        onClick={handleDelete}
                        className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                      >
                        <Close
                          sx={{
                            fontSize: "15px",
                          }}
                        />
                        Clear All
                      </button>
                    ) : null}
                  </div>
                  <Button variant={"border"} onClick={() => setIsOpen(true)}>
                    <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                    Filter
                  </Button>
                  <div className="h-full">
                    <Button onClick={handleDownload} variant={"border"}>
                      <Download size={15} strokeWidth={2.5} />
                      Download
                    </Button>
                  </div>

                  {selectedIds?.length > 0 && (
                    <>
                      <Button
                        variant={"border"}
                        className="bg-[#B3261E] text-white"
                        onClick={() => handleMultiSelect("Rejected")}
                      >
                        Reject
                      </Button>

                      <Button
                        variant={"border"}
                        className="bg-[#359349] text-white"
                        onClick={() => handleMultiSelect("Approved")}
                      >
                        Approve
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="overflow-x-auto mt-2">
              {loading ? (
                <TableSkeleton />
              ) : (
                <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
                  <DynamicTable<IEmployeeDSR>
                    onRowClick={(row) => {
                      navigate(`/employee-dsr-details`, {
                        state: { id: row?.id },
                      });
                    }}
                    data={tableData}
                    loading={false}
                    columns={columns2}
                    enableSorting
                    enablePagination
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        )}
        {isOpen && (
          <AttFilter
            open={isOpen}
            handleClose={() => setIsOpen(false)}
            heading="DSR Filter"
            onApply={handleApply}
            isDate={true}
            isOther
          />
        )}
      </div>
    </>
  );
};
