import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.css";
import cross from "../../assets/images/cross.png";

interface ModalProps {
  open: Boolean;
  onClose: () => void;
  children: React.ReactNode;
  cssClass: string;
  x?: number;
  y?: number;
}

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
  cssClass,
  x,
  y,
}) => {
  const dialog = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const modal = dialog.current;

    if (modal === null) {
      return;
    }

    if (open) {
      modal.showModal();
    }

    return () => modal.close();
  }, [open]);

  const closeButton = (
    <button className={styles.closeButton} onClick={onClose}>
      <img src={cross} alt="cross" />
    </button>
  );

  if (x && y) {
    return createPortal(
      <dialog
        ref={dialog}
        style={{ position: "fixed", top: `${x - 185}px`, left: `${y - 435}px` }}
        className={`${cssClass} ${styles.dialog}`}
        onClose={onClose}
      >
        {closeButton}
        {children}
      </dialog>,
      document.getElementById("modal") as HTMLDivElement
    );
  } else {
    return createPortal(
      <dialog
        ref={dialog}
        className={`${cssClass} ${styles.dialog}`}
        onClose={onClose}
      >
        {closeButton}
        {children}
      </dialog>,
      document.getElementById("modal") as HTMLDivElement
    );
  }
};

export default Modal;
