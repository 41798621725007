import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../common/Button";
import { ArrowUpDown } from "lucide-react";
import { useSelector } from "react-redux";
import DynamicTable from "../../common/DynamicTable";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../../common/Pagenation";
import UserBadge from "../../common/UserBadge";
import StatusBadge from "../../common/StatusBadge";
import { Fetch_Leave_List } from "../../common/services/Leave";
import { RootState } from "../../../redux/store";
import eye from "../../../assets/Crud_Icons/view.svg";
import TableSkeleton from "../../common/TableSkeleton";
import { TextField, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { setFilterPersistData } from "../../../redux/reducers/FilterPersist/FilterPersistSlice";
import CustomTabs from "../../common/CustomTabs";

interface Upcoming_Leave {
  User: {
    firstName: string;
    lastName: string;
    middleName: string;
    employee_id: string;
    profile_image: string;
    Department: {
      name: string;
    };
  };
  LeaveType: {
    leave_type_name: string;
  };
  from_date: string;
  to_date: string;
  id: string;
  no_of_days: number;
  leave_status: string;
  leave_session: string;
}

interface UpcomingLeaveProps {
  depId: string | null;
  busId: string | null;
  dates: [Date | null, Date | null] | undefined;
}

const UpcomingLeave: React.FC<UpcomingLeaveProps> = ({
  depId,
  busId,
  dates,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<Upcoming_Leave[]>([]);

  const [loading, setLoading] = useState(false);
  const [numOfItems, setNumOfItems] = useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const filter_data = useSelector((state: RootState) => state.filterPersist);

  // const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
  //   filter_data?.date
  // );
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  function handlePageChange(value: number) {
    setPage(value);
  }
  const [status, setStatus] = useState<string>("");

  // const LeaveOptions = [
  //   { label: "All Leave", value: "" },
  //   { label: "Approved", value: "Approved" },
  //   { label: "Rejected", value: "Rejected" },
  //   { label: "Pending", value: "Pending" },
  // ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_List({
        customer_id: customer_id,
        numOfItems: numOfItems,
        page: page,
        group_id: Number(busId),
        department_id: Number(depId),
        searchTerm: searchTerm ?? "",
        startDate: dates ? dates[0] : null,
        endDate: dates ? dates[1] : null,
        upcoming: true,
        status: status
      });

      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      }
    } catch (error) {}
    setLoading(false);
  }, [customer_id, numOfItems, page, busId, depId, searchTerm, dates, status]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns2: ColumnDef<Upcoming_Leave>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return <UserBadge user={item?.User} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "from_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.from_date
          ? new Date(row.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.from_date
          ? new Date(row.original.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "to_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.to_date
          ? new Date(row.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.to_date
          ? new Date(row.original.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "leave_session",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Period
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "leave_type_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.LeaveType?.leave_type_name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.LeaveType?.leave_type_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "leave_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.leave_status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.leave_status ?? "-";
        const statusList = ["Pending"];
        const statusColors = ["#FEEBDD"];
        const textColors = ["#FF9950"];
        return (
          <StatusBadge
            status={status ?? "-"}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/leave-details/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* <div className="flex flex-wrap items-center justify-end gap-2 w-full mb-2 mt-[-32px]"> */}
        {/* <TextField
          id="outlined-required"
          select
          placeholder="All Leave"
          name="status"
          value={status}
          className="min-w-[70px] w-full sm:w-[120px] md:w-[150px]" // Flexible width for responsiveness
          sx={{
            height: "40px",
            backgroundColor: "white",
            borderRadius: "0.5rem",
            "& .MuiOutlinedInput-root": {
              height: "100%",
              borderRadius: "0.5rem",
              border: "none",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "29px",
              color: "#49454F",
              "& fieldset": {
                border:
                  "1px solid rgb(205 205 205 / var(--tw-border-opacity, 1))",
              },
              "&:hover fieldset": {
                borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgb(205 205 205 / var(--tw-border-opacity, 1))",
                borderWidth: "1px",
              },
            },
          }}
          onChange={(e) => {
            setStatus(e.target.value);
            fetchData();
          }}
          SelectProps={{ displayEmpty: true }}
        >
          {LeaveOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
      {/* </div> */}

      <div className="overflow-x-auto mt-4 mb-10">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<Upcoming_Leave>
              onRowClick={(row) => {
                navigate(`/leave-details/${row?.id}`, {
                  state: { id: row?.id },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <div className="mb-4">
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        </div>
      )}
    </>
  );
};

export default UpcomingLeave;
