import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import upload from "../../image/System Icons.svg";
import { api_key_work as api_key } from "../../utils/helperData";
import { Close } from "@mui/icons-material";
import pdf from "../../assets/pdfImage.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileImage,
  faFileWord,
  faFileArchive,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faFilePowerpoint,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axiosInstance from "../../app/api/axiosInstance";

interface IFileType {
  pdf: Element;
  jpg: Element;
  jpeg: Element;
  png: Element;
  doc: Element;
  docx: Element;
  zip: Element;
  rar: Element;
  xls: Element;
  xlsx: Element;
  csv: Element;
  ppt: Element;
  pptx: Element;
  default: Element;
}
const FileIcon = ({ extension }: { extension: keyof IFileType }) => {
  const iconMap = {
    pdf: (
      <FontAwesomeIcon
        icon={faFilePdf}
        className="text-red-500 h-full w-full"
      />
    ),
    jpg: (
      <FontAwesomeIcon
        icon={faFileImage}
        className="text-blue-500 h-full w-full"
      />
    ),
    jpeg: (
      <FontAwesomeIcon
        icon={faFileImage}
        className="text-blue-500 h-full w-full"
      />
    ),
    png: (
      <FontAwesomeIcon
        icon={faFileImage}
        className="text-blue-500 h-full w-full"
      />
    ),
    doc: (
      <FontAwesomeIcon
        icon={faFileWord}
        className="text-blue-700 h-full w-full"
      />
    ),
    docx: (
      <FontAwesomeIcon
        icon={faFileWord}
        className="text-blue-700 h-full w-full"
      />
    ),
    zip: (
      <FontAwesomeIcon
        icon={faFileArchive}
        className="text-yellow-500 h-full w-full"
      />
    ),
    rar: (
      <FontAwesomeIcon
        icon={faFileArchive}
        className="text-yellow-500 h-full w-full"
      />
    ),
    txt: (
      <FontAwesomeIcon
        icon={faFileAlt}
        className="text-gray-500 h-full w-full"
      />
    ),
    xls: (
      <FontAwesomeIcon
        icon={faFileExcel}
        className="text-green-600 h-full w-full"
      />
    ),
    csv: (
      <FontAwesomeIcon
        icon={faFileExcel}
        className="text-green-600 h-full w-full"
      />
    ),
    xlsx: (
      <FontAwesomeIcon
        icon={faFileExcel}
        className="text-green-600 h-full w-full"
      />
    ),
    ppt: (
      <FontAwesomeIcon
        icon={faFilePowerpoint}
        className="text-orange-500"
        size="lg"
      />
    ),
    pptx: (
      <FontAwesomeIcon
        icon={faFilePowerpoint}
        className="text-orange-500 h-full w-full"
      />
    ),
    default: (
      <FontAwesomeIcon icon={faFile} className="text-gray-400 h-full w-full" />
    ), // Default icon for unsupported types
  };

  return iconMap[extension] || iconMap["default"];
};

export interface Accept {
  [key: string]: readonly string[];
}
interface FileUploadProps {
  onFilesChange: (
    files: { originalName: string; url: string; fileType: string }[]
  ) => void;
  accept?: Accept;
  isMultiple?: boolean;
  height?: string;
  width?: string;
  text?: string;
  project_id?: string | number;
}

const AutomationFileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  accept = {},
  isMultiple = true,
  height = "auto",
  width = "auto",
  text = "Upload Files",
  project_id,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = isMultiple ? [...files, ...acceptedFiles] : acceptedFiles;
    setFiles(newFiles);
    uploadFiles(newFiles);
  };

  const removeFile = (file: File) => {
    const updatedFiles = files.filter((f) => f !== file);
    setFiles(updatedFiles);
    onFilesChange(
      updatedFiles.map((file) => ({
        originalName: file.name,
        url: URL.createObjectURL(file),
        fileType: file.type.split("/").pop() || "",
      }))
    );
  };

  const uploadFiles = async (filesToUpload: File[]) => {
    setUploading(true);
    try {
      const formData = new FormData();
      filesToUpload.forEach((file) => {
        formData.append("file", file);
      });
      const access_token = localStorage.getItem("access_token");

      // const response = await axiosInstance.post(
      //   `${process.env.REACT_APP_BASE_API}/api/v1/upload-document`,
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       "x-api-key": api_key,
      //       Authorization: "Bearer " + access_token,
      //     },
      //   }
      // );

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API}/api/v1/v2/upload-task?project_id=${project_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
          },
        }
      );

      const result = response.data.result;
      onFilesChange(result);
    } catch (error) {
      toast.error("Error uploading files");
      setFiles([]);
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: isMultiple,
  });

  return (
    <div style={{ height, width }} className="p-2  flex flex-col">
      {/* File List */}
      {files?.length > 0 ? (
        <div className="mt-2 mb-4 grid grid-cols-3 gap-3 overflow-auto max-h-[50%]">
          {files?.map((file, index) => {
            const extension = file?.name
              ?.split(".")
              ?.pop()
              ?.toLowerCase() as keyof IFileType;
            return (
              <div
                key={index}
                className="flex items-center justify-between border-[#EDEDED] p-2 border rounded-md"
              >
                <div className="h-[40px] flex items-center gap-2.5">
                  {/* File Icon */}
                  <div className="h-[32px] w-[32px] rounded-full">
                    <FileIcon extension={extension} />
                  </div>
                  {/* File Details */}
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {file?.name}
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {(file?.size / 1024 / 1024)?.toFixed(2)} MB
                    </span>
                  </div>
                </div>
                {/* Remove Button */}
                <button
                  onClick={() => removeFile(file)}
                  className="text-[#79747E]"
                >
                  <Close />
                </button>
              </div>
            );
          })}
        </div>
      ) : null}

      {/* Dashed Dropzone */}
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer flex-grow flex flex-col items-center justify-center"
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <img className="m-[auto] h-8 w-8" src={upload} alt="Upload" />
          <p>
            <span className="text-[#4B5563] font-[400] font-[Poppins] text-[16px]">
              Drop here to attach or
            </span>{" "}
            <span className="text-blue-500">upload</span>
          </p>
          <p className="text-[12px] text-[#4B5563] font-[400] font-[Poppins]">
            {text} | Max size: 10MB
          </p>
          {uploading && (
            <p className="text-sm text-gray-700 mt-2">Uploading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutomationFileUpload;
