import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { ExitProcedure } from "./ExitProcedure";
import EmployeeList from "./EmployeeList";
import "./employee.css";
import maskPlus from "../../image/Mask group.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomTabs from "../common/CustomTabs";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import { useDispatch } from "react-redux";
import { setMainTabValue } from "../../redux/reducers/Tab/TabSlice";
import EmployeeDashboard from "./EmployeeDashboard";
import dayjs, { Dayjs } from "dayjs";
import { createGlobalStyle } from "styled-components";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { MenuItem, TextField, IconButton, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "../common/Button";
import filter from "../../assets/filter.svg";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { EmployeeUnit } from "../../redux/actions/Employee/Employee";
import { BusinessUnit } from "../EmployeeManagement/AddEmployee";

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const Employee = () => {
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.mainTabValue.value);
  // const [value, setValue] = React.useState<string>("1");
  const [bussness, setBusiness] = useState<BusinessUnit[]>();
  const [businessId, setBusinessId] = useState<string | null>("");

  const AddEmployee = () => {
    navigate("/add-employee");
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, [customer_id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
        search: "",
      })
    );
    dispatch(setMainTabValue(newValue));
  };

  const handleFileChange = () => {};

  const handlenavigate = () => {
    navigate("/add-exit");
  };

  const tabs = [
    { label: "Overview", value: "1" },
    { label: "Employee List", value: "2" },
    { label: "Ex Employee List", value: "3" },
  ];

  return (
    <div className="px-4 pt-6 pb-12 bg-[#F9F9F9] min-h-[90vh]">
      <div className="">
        <Box>
          <TabContext value={value}>
            <div className="flex justify-between">
              <Box>
                <CustomTabs tabs={tabs} onChange={handleChange} />
              </Box>

              {value === "1" ? (
                <>
                  <div className="h-full flex items-center gap-2">
                    <div className="relative pr-4">
                      <TextField
                        id="outlined-required"
                        select
                        placeholder="All Business Unit"
                        name="business"
                        value={businessId ?? ""}
                        sx={{
                          height: "38px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            minWidth: "170px",
                            borderRadius: "8px",
                            border: "none",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "29px",
                            color: "#49454F",
                            outline: "none",
                          },
                        }}
                        onChange={(e) => {
                          setBusinessId(e.target.value);
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          sx: {
                            color: "#374151",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontStyle: "normal",
                            lineHeight: "22px",
                            "& .MuiSelect-select": {
                              padding: "10px",
                              paddingRight: businessId ? "62px" : "10px",
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: businessId && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setBusinessId("");
                                }}
                                sx={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  zIndex: 2,
                                  padding: 0,
                                  width: "24px",
                                  height: "24px",
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem value="">All Business Unit</MenuItem>
                        {bussness?.map((option) => (
                          <MenuItem
                            key={Number(option.id)}
                            value={Number(option.id)}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </>
              ) : value === "2" ? (
                <div className="flex space-x-4">
                  <Button variant={"gradient"}>
                    <FileUploadIcon />
                    Bulk Upload
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                      accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </Button>
                  <div>
                    <Button onClick={AddEmployee} variant={"gradient"}>
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Employee
                    </Button>
                  </div>
                </div>
              ) : value === "3" ? (
                <div>
                  <Button onClick={handlenavigate} variant={"gradient"}>
                    <img
                      className="w-5 h-5 mr-2"
                      src={maskPlus}
                      alt="plus icon"
                    />
                    Initiate Exit procedure
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <TabPanel className="tabpanel" value="1">
              <EmployeeDashboard businessId={businessId} />
            </TabPanel>
            <TabPanel className="tabpanel" value="2">
              <EmployeeList />
            </TabPanel>
            <TabPanel className="tabpanel" value="3">
              <ExitProcedure />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
