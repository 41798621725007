import React from "react";
import profile from "../../assets/Ellipse 580.png";
import { Calendar, Mail, MapPin, Phone } from "lucide-react";
import dayjs from "dayjs";

export interface EmployeeData {
  empCode: string;
  empId: string;
  empName: string;
  empLastName: string;
  email: string;
  code: string;
  number: string;
  business_unit: string;
  role: string;
  department: string;
  designation: string;
  team_manager_firstName: string;
  team_manager_lastName: string;
  reporting_manager_firstName: string;
  reporting_manager_lastName: string;
  status: string;
  MoE: string;
  YOE: string;
  DOB: string;
  DOJ: string;
  DOL: string | null;
  profile_image: string | null;
  location: string | null;
  employeeType: string;
  bloodGroup: string | null;
  shiftId: number | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  prefix: string;
  business_unitaddress: string;
}

export default function EmployeeBanner({
  employeeData,
}: {
  employeeData: EmployeeData;
}) {
  return (
    <div className="bg-white border p-4 rounded-md flex flex-wrap md:flex-nowrap gap-4 shadow-sm mt-3 items-center">
      <div className="w-10 h-10 md:w-[40px] md:h-[40px] rounded-full overflow-hidden">
        <img
          src={employeeData?.profile_image || profile}
          alt="profile"
          className="w-full h-full rounded-full"
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="pb-2 flex flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <p className="text-dark-black text-base md:text-lg font-semibold leading-[22px]">
                {employeeData?.empName} {employeeData?.empLastName}
              </p>
              <p className="text-gray-900 font-normal text-sm font-['Poppins']">
                ({employeeData?.designation ?? "-"})
              </p>
            </div>
            <div className="flex flex-wrap gap-3 items-center text-xs md:text-sm leading-[13px] text-gray-700 font-['Poppins'] font-normal">
              <p>EMP ID: {employeeData?.empId}</p>
              <div className="flex gap-1 items-center">
                <Mail
                  strokeWidth={2}
                  size={16}
                  className="text-[#5D76C0] text-xs"
                />
                <p className="text-dark-grey text-xs font-['Poppins']">
                  <span>{employeeData?.email ?? "-"}</span>
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <Phone
                  strokeWidth={2}
                  size={16}
                  className="text-[#5D76C0] text-xs"
                />
                <p className="text-dark-grey text-xs font-['Poppins']">
                  <span>
                    {employeeData?.number ? employeeData?.number : "-"}
                  </span>
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <MapPin
                  strokeWidth={2}
                  size={16}
                  className="text-[#5D76C0] text-xs"
                />
                <p className="text-dark-grey text-xs font-['Poppins']">
                  <span>{employeeData?.business_unitaddress ?? "-"}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-4 ">
            <div className="flex flex-col pt-2.5 text-left gap-1">
              <p className="text-xs font-poppins text-gray-700">Team Manager</p>
              <p className="text-gray-800 font-medium text-xs font-poppins ">
                {employeeData?.team_manager_firstName
                  ? `${employeeData?.team_manager_firstName} ${employeeData?.team_manager_lastName}`
                  : "-"}
              </p>
            </div>
            <div className="flex flex-col pt-2.5 text-left gap-1">
              <p className="text-xs font-poppins text-gray-700">
                Reporting Manager
              </p>
              <p className="text-gray-800 font-medium text-xs font-poppins">
                {employeeData?.reporting_manager_firstName
                  ? `${employeeData?.reporting_manager_firstName} ${employeeData?.reporting_manager_lastName}`
                  : "-"}
              </p>
            </div>
            <div className="flex flex-col text-left pt-2.5 gap-1">
              <p className="text-xs font-poppins text-gray-700">Joined On</p>
              <p className="text-gray-800 font-medium text-xs font-poppins">
                {dayjs(employeeData?.DOJ).format("DD MMM YYYY")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
