import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { debounce } from "lodash";

import avatar from "../../../assets/avatar.svg";
import Search from "../../SearchBar/Search";
import cross from "../../../assets/cross_new.svg";

import { Dayjs } from "dayjs";
import { Fetch_WFH_List } from "../../common/services/Attendence";
import { useInView } from "react-intersection-observer";
import bussiness_man_icon from "../../../assets/avatar.svg";


interface ShowWFHProps {
  isOpen: boolean;
  onClose: () => void;
  date: Dayjs;
  groupIds: (number | null)[];
}

interface ITableData {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string;
  wfh_session: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "70%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ShowWFH: React.FC<ShowWFHProps> = ({
  isOpen,
  onClose,
  date,
  groupIds,
}) => {
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { ref, inView } = useInView();
  const LIMIT = 20;

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
      setPage(1);
      setTableData([]);
      setHasMore(true);
    }, 300),
    []
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  const fetchWfh = useCallback(async () => {
    if (!hasMore || loading) return;

    setLoading(true);

    try {
      const response = await Fetch_WFH_List(page,
        LIMIT,
        searchTerm,
        date,
        groupIds);

      if (response.status === 200) {
        const newRows = response?.data?.result?.rows ?? [];
        const totalCount = response?.data?.result?.count ?? 0;

        setTableData((prev) => [...prev, ...newRows]);
        setCount(totalCount);

        const totalFetched = (page - 1) * LIMIT + newRows.length;
        if (totalFetched >= totalCount) {
          setHasMore(false);
        }
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [page, searchTerm, date, groupIds, hasMore, loading]);

  useEffect(() => {
    if (isOpen) {
      setPage(1);
      setTableData([]);
      setHasMore(true);
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && hasMore && !loading) {
      fetchWfh();
    }
  }, [page]);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  }, [inView, hasMore, loading]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="flex flex-col gap-6 h-full">
              <div className="flex flex-row justify-between items-center h-8">
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  Working from Home ({count})
                </span>
                <button onClick={onClose} className="">
                  <img
                    src={cross}
                    alt="close"
                    className="p-1.5 hover:bg-gray-100 rounded-md"
                  />
                </button>
              </div>
              <div className="h-11">
                <Search
                  label="Search with Employee Name"
                  onChange={handleSearchChange}
                />
              </div>
              <div className="h-[calc(100%-128px)] overflow-y-auto scrollBar pr-2">
                <div className="grid grid-cols-4 gap-4">
                  {tableData?.map((user, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center gap-2"
                    >
                      <img
                        src={user?.profile_image || avatar}
                        alt="profile"
                        className="w-12 h-12 rounded-full"
                        onError={(e) => (e.currentTarget.src = bussiness_man_icon)}
                      />
                      <div className="flex flex-col gap-0.5">
                        <p className="text-[#1D1A22] font-[500] text-[14px] font-[Poppins] text-center">
                          {user?.firstName} {user?.middleName} {user?.lastName}
                        </p>
                        <p className="text-[#49454F] font-[500] text-[12px] font-[Poppins] text-center">
                          {user?.wfh_session}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {hasMore && (
                  <div
                    ref={ref}
                    className="text-center py-4 text-sm text-gray-500"
                  >
                    {loading ? "Loading more..." : "Scroll to load more"}
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ShowWFH;
