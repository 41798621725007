import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface ProjectData {
  count: number;
  ProjectData: any[];
  AttendenceEditData: any[];
  currentPage: number;
  itemsPerPage: number;
  startDate: Date | null;
  endDate: Date | null;
}

const initialState: ProjectData = {
  count: 0,
  ProjectData: [],
  AttendenceEditData: [],
  currentPage: 1,
  itemsPerPage: 10,
  startDate: null,
  endDate: null,
};

// interface TabState {
//   selectedTab: string;
// }

const ProjectListSlice = createSlice({
  name: "EmployeeData",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setProjectData(state, action: PayloadAction<any>) {
      state.ProjectData = action.payload;
      state.count = action.payload.count;
      // console.log(action.payload, "message attendence ------>>>>");
    },
    setEditProjectData(state, action: PayloadAction<any>) {
      state.AttendenceEditData = action.payload;
      state.count = action.payload.count;
      // console.log(action.payload, "message edit attendence ------>>>>");
    },
    setStartDate(state, action: PayloadAction<Date | null>) {
      state.startDate = action.payload;
    },
    setEndDate(state, action: PayloadAction<Date | null>) {
      state.endDate = action.payload;
    },
  },
});

export const ProjectActionData = ProjectListSlice.actions;
export default ProjectListSlice;
