import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";

interface IProjectSlice {
  project_tab: number;
  view_project_tab: string;
  project_id: number | null;
  project_startDate: Dayjs | null;
  project_endDate: Dayjs | null;
}

const initialState: IProjectSlice = {
  project_tab: 0,
  view_project_tab: "overview",
  project_id: null,
  project_startDate: null,
  project_endDate: null,
};

const ProjectSlice2 = createSlice({
  name: "projectSlice",
  initialState,
  reducers: {
    setProjectTab(state, action: PayloadAction<number>) {
      state.project_tab = action.payload;
    },
    setViewProjectTab(state, action: PayloadAction<string>) {
      state.view_project_tab = action.payload;
    },
    setProjectId(state, action: PayloadAction<number | null>) {
      state.project_id = action.payload;
    },
    setProjectStartDate(state, action: PayloadAction<Dayjs | null>) {
      state.project_startDate = action.payload;
    },
    setProjectEndDate(state, action: PayloadAction<Dayjs | null>) {
      state.project_endDate = action.payload;
    },
    resetProjectTab(state) {
      state.project_tab = 0;
    },
    resetViewProjectTab(state) {
      state.view_project_tab = "overview";
    },
    resetProjectId(state) {
      state.project_id = null;
    },
    resetProjectStartDate(state) {
      state.project_startDate = null;
    },
    resetProjectEndDate(state) {
      state.project_endDate = null;
    },
  },
});

export const { setProjectTab, setViewProjectTab, setProjectId, setProjectStartDate, setProjectEndDate, resetProjectTab, resetViewProjectTab, resetProjectId, resetProjectStartDate, resetProjectEndDate } = ProjectSlice2.actions;
export default ProjectSlice2;
