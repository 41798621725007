// App.tsx

import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./Routes";
import { GoogleMapsProvider } from "./Context/GoogleMapContext";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <GoogleMapsProvider>
        <Routes />
      </GoogleMapsProvider>
    </Provider>
  );
};
export default App;
