import { Button } from "../../Button";

export const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Policy
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this policy?
        </h4>
        <div className="flex space-x-4 justify-center">
          <Button onClick={onDelete} variant={"gradient"}>
            Yes
          </Button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-12 py-2"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};
