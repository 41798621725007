import { useCallback, useEffect, useState } from "react";
import edit from "../../assets/Crud_Icons/edit.svg";
import view from "../../assets/Crud_Icons/view.svg";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../assets/download.svg";
import filter from "../../assets/filter.svg";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import {
  Fetch_Exit_Employee_Management_List,
} from "../common/services/ExitEmployee";
// import moment from "moment";
import { RootState } from "../../redux/store";
import PersistedSearch from "./EmployeeListSearch";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";
import { Close } from "@mui/icons-material";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import UserBadge from "../common/UserBadge";
import StatusBadge from "../common/StatusBadge";

interface IExitProcedure {
  User: {
    firstName: string;
    lastName: string;
    profile_image: string;
    date_of_exit_initiation: string;
    employee_id: string;
    date_of_leaving: string;
  };
  id: Number;
  user_id: number;
  exit_status: string;
}

export const ExitProcedure = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState<IExitProcedure[]>([]);

  const [page, setPage] = useState(1);
  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );

  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setIsOpen(false);
  };
  const handleDelete = () => {
    setBusId(null);
    setDepId(null);
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
      })
    );
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Exit_Employee_Management_List(
        customer_id,
        numOfItems,
        page,
        Number(busId),
        Number(depId),
        //sortType,
        searchTerm ?? "",
        dates ? dates[0] : null,
        dates ? dates[1] : null
        // endDate
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);
        setCount(response?.data?.result?.count ?? 0);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  }, [searchTerm, page, numOfItems, busId, depId]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDownload = () => {
    if (tableData?.length > 0) {
      // Define the column headers based on the table structure
      const columns = [
        "Employee Name & Id",
        "Date of Initiation",
        "Date of Leaving",
      ];

      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...tableData.map((row) => [
          // Employee Name & Id with full name and employee id
          row.User?.firstName && row.User?.lastName
            ? `${row.User.firstName} ${row.User.lastName}`
            : "-", // Combine firstName and lastName, or fallback to "-"

          // Date of Initiation formatted as "DD-MMM-YYYY"
          row.User?.date_of_exit_initiation
            ? new Date(row.User.date_of_exit_initiation).toLocaleDateString(
                "en-GB",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }
              )
            : "-",

          // Date of Leaving formatted as "DD-MMM-YYYY"
          row.User?.date_of_leaving
            ? new Date(row.User.date_of_leaving).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
            : "-",
        ]),
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Exit Procedure Data");

      // Generate the binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "exit_procedure_data.xlsx"; // Customize download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const dispatch = useDispatch();

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const columns2: ColumnDef<IExitProcedure>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original ?? "-";
        return (
          <UserBadge user={item?.User} user_id={item?.user_id} />
        );
      },
    },
    {
      accessorKey: "date_of_exit_initiation",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date of Initiation
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.User?.date_of_exit_initiation
          ? new Date(row.User?.date_of_exit_initiation)?.toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "short",
                year: "numeric",
              }
            )
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.date_of_exit_initiation
          ? new Date(
              row.original.User?.date_of_exit_initiation
            ).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "date_of_leaving",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date of Leaving
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.User?.date_of_leaving
          ? new Date(row.User?.date_of_leaving).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.User?.date_of_leaving
          ? new Date(row.original.User?.date_of_leaving).toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "short",
                year: "numeric",
              }
            )
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "exit_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.exit_status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.exit_status ?? "-";
        const statusList = ["Approved", "Pending", "Rejected"];
        const statusColors = ["#ECFDF3", "#FEEBDD", "#FFEAEA"];
        const textColors = ["#137F40", "#FF9950", "#DC2626"];
        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/exit-procedure-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={view}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/edit-exit", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="pt-6">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
                <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<IExitProcedure>
              onRowClick={(row) => {
                navigate("/exit-procedure-details", {
                  state: { id: row?.id },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      {/* <Filter
        isDate={false}
        isOther
        heading="Exit Procedure Filter"
        selectnameBusiness={
          selectedBusinessUnit?.name ?? "Select Business Unit"
        }
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment={
          selectedDepartmentUnit?.name ?? "Select Department"
        }
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      /> */}

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Ex Employee Filter"
        onApply={handleApply}
        isDate={false}
        isOther
      />
    </>
  );
};
