import { useCallback, useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useSelector } from "react-redux";
import {
  Create_Team_Structure,
  Fetch_Team_Structure,
} from "../common/services/TeamStructure";
import noResult from "../../assets/noResult.svg";
import TeamCard from "./TeamCard";
import TeamStructureTable from "./TeamStructureTable";
import { RootState } from "../../redux/store";
import { setMainTabValue } from "../../redux/reducers/Tab/TabSlice";
import { useDispatch } from "react-redux";

export interface TeamMember {
  id: number;
  team_manager_id: number | null;
  reporting_manager_id: number;
  depth: number;
  user: User;
  children: TeamMember[];
}

interface User {
  id: number;
  employee_code: string;
  employee_id: string;
  firstName: string;
  lastName: string;
  email: string;
  profile_image: string | null;
  department_id: number;
  designation_id: number;
  status: string;
  Department: Department;
  Designation: Designation;
}

interface Department {
  id: number;
  name: string;
}

interface Designation {
  id: number;
  name: string;
}

const RenderTreeNode = ({ member }: { member: TeamMember }) => {
  return (
    <TreeNode label={<TeamCard user={member?.user} />}>
      {member?.children?.map((child) => (
        <RenderTreeNode key={child?.id} member={child} />
      ))}
    </TreeNode>
  );
};

const TeamStructure = () => {
  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.mainTabValue.value);
  const [teamTreeData, setTeamTreeData] = useState<TeamMember[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const customer_id = loginData?.customer_id || 0;

  const createTeamStructure = async (customer_id: number) => {
    try {
      const response = await Create_Team_Structure(customer_id);
      if (response.status === 200) {
        console.log("Team Structure Created.");
      } else {
        console.error("Unexpected response:", response);
      }
      return response;
    } catch (error) {
      console.error("Error creating team structure:", error);
      return null;
    }
  };
  
  const fetchData = useCallback(async (hasRetried = false) => {
    setLoading(true);
    try {
      const response = await Fetch_Team_Structure(customer_id);
      if (response.status === 200) {
        const result = response?.data?.result ?? [];
        if (result.length === 0 && !hasRetried) {
          const res = await createTeamStructure(customer_id);
          if (res && res.status === 200) {
            await fetchData(true);
          } else {
            console.warn("Team structure creation failed or returned empty.");
          }
        } else {
          setTeamTreeData(result);
        }
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error fetching team structure:", error);
    } finally {
      setLoading(false);
    }
  }, [customer_id]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  

  const handleTreeIconClick = () => {
    dispatch(setMainTabValue("1"));
  };

  const handleTableIconClick = () => {
    dispatch(setMainTabValue("2"));
  };

  return (
    <>
      <div className="flex ml-5 mt-7">
        <div className="text-[#020617] font-poppins text-[20px] font-semibold tracking-[-0.3px]">
          Team Structure
        </div>
        <div className="flex justify-end ml-auto mr-4">
          <button onClick={handleTableIconClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="40"
              viewBox="0 0 54 40"
              fill="none"
            >
              {/* Button Background */}
              <path
                d="M0.273438 6C0.273438 2.68629 2.95973 0 6.27344 0H53.6087V40H6.27343C2.95972 40 0.273438 37.3137 0.273438 34V6Z"
                fill={value === "2" ? "url(#gradientTable)" : "#F3F4F6"}
              />
              {/* Icon Paths */}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1118 14.6C15.1118 13.7627 15.736 13.084 16.5059 13.084C17.2759 13.084 17.9001 13.7627 17.9001 14.6C17.9001 15.4373 17.2759 16.1161 16.5059 16.1161C15.736 16.1161 15.1118 15.4373 15.1118 14.6ZM19.2942 14.6C19.2942 14.1814 19.6063 13.842 19.9913 13.842H32.5384C32.9234 13.842 33.2355 14.1814 33.2355 14.6C33.2355 15.0187 32.9234 15.3581 32.5384 15.3581H19.9913C19.6063 15.3581 19.2942 15.0187 19.2942 14.6ZM15.1118 19.9062C15.1118 19.0689 15.736 18.3902 16.5059 18.3902C17.2759 18.3902 17.9001 19.0689 17.9001 19.9062C17.9001 20.7435 17.2759 21.4223 16.5059 21.4223C15.736 21.4223 15.1118 20.7435 15.1118 19.9062ZM19.2942 19.9062C19.2942 19.4876 19.6063 19.1482 19.9913 19.1482H32.5384C32.9234 19.1482 33.2355 19.4876 33.2355 19.9062C33.2355 20.3249 32.9234 20.6643 32.5384 20.6643H19.9913C19.6063 20.6643 19.2942 20.3249 19.2942 19.9062ZM15.1118 25.2124C15.1118 24.3751 15.736 23.6964 16.5059 23.6964C17.2759 23.6964 17.9001 24.3751 17.9001 25.2124C17.9001 26.0497 17.2759 26.7285 16.5059 26.7285C15.736 26.7285 15.1118 26.0497 15.1118 25.2124ZM19.2942 25.2124C19.2942 24.7938 19.6063 24.4544 19.9913 24.4544H32.5384C32.9234 24.4544 33.2355 24.7938 33.2355 25.2124C33.2355 25.6311 32.9234 25.9705 32.5384 25.9705H19.9913C19.6063 25.9705 19.2942 25.6311 19.2942 25.2124Z"
                fill={value === "2" ? "white" : "#6B7280"}
              />
              <defs>
                <linearGradient
                  id="gradientTable"
                  x1="0"
                  y1="20"
                  x2="54"
                  y2="20"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4165C4" />
                  <stop offset="1" stopColor="#8E1FD2" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          <button onClick={handleTreeIconClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="40"
              viewBox="0 0 54 40"
              fill="none"
            >
              {/* Button Background */}
              <path
                d="M0.608643 0.0932617H48.0001C51.3138 0.0932617 54.0001 2.77955 54.0001 6.09326V33.9065C54.0001 37.2203 51.3138 39.9066 48.0001 39.9066H0.608643V0.0932617Z"
                fill={value === "1" ? "url(#gradientTree)" : "#F3F4F6"}
              />
              {/* Icon Paths */}
              <path
                d="M29.084 17.7884V16.6825H28.5755C28.3058 16.6825 28.0471 16.799 27.8564 17.0064C27.6657 17.2138 27.5586 17.4951 27.5586 17.7884V22.2121C27.5586 22.5055 27.6657 22.7868 27.8564 22.9942C28.0471 23.2016 28.3058 23.3181 28.5755 23.3181H29.084V22.2121C29.084 21.9188 29.1912 21.6375 29.3819 21.4301C29.5726 21.2227 29.8313 21.1062 30.101 21.1062H33.1519C33.4217 21.1062 33.6803 21.2227 33.8711 21.4301C34.0618 21.6375 34.1689 21.9188 34.1689 22.2121V25.5299C34.1689 25.8232 34.0618 26.1045 33.8711 26.3119C33.6803 26.5193 33.4217 26.6358 33.1519 26.6358H30.101C29.8313 26.6358 29.5726 26.5193 29.3819 26.3119C29.1912 26.1045 29.084 25.8232 29.084 25.5299V24.424H28.5755C28.0361 24.424 27.5187 24.191 27.1373 23.7762C26.7559 23.3614 26.5416 22.7988 26.5416 22.2121V20.5533H24.5076V21.1062C24.5076 21.3995 24.4005 21.6808 24.2097 21.8882C24.019 22.0956 23.7604 22.2121 23.4906 22.2121H21.4567C21.187 22.2121 20.9283 22.0956 20.7376 21.8882C20.5468 21.6808 20.4397 21.3995 20.4397 21.1062V18.8944C20.4397 18.6011 20.5468 18.3198 20.7376 18.1124C20.9283 17.905 21.187 17.7884 21.4567 17.7884H23.4906C23.7604 17.7884 24.019 17.905 24.2097 18.1124C24.4005 18.3198 24.5076 18.6011 24.5076 18.8944V19.4473H26.5416V17.7884C26.5416 17.2018 26.7559 16.6392 27.1373 16.2244C27.5187 15.8096 28.0361 15.5766 28.5755 15.5766H29.084V14.4707C29.084 14.1774 29.1912 13.8961 29.3819 13.6887C29.5726 13.4813 29.8313 13.3647 30.101 13.3647H33.1519C33.4217 13.3647 33.6803 13.4813 33.8711 13.6887C34.0618 13.8961 34.1689 14.1774 34.1689 14.4707V17.7884C34.1689 18.0818 34.0618 18.3631 33.8711 18.5705C33.6803 18.7779 33.4217 18.8944 33.1519 18.8944H30.101C29.8313 18.8944 29.5726 18.7779 29.3819 18.5705C29.1912 18.3631 29.084 18.0818 29.084 17.7884Z"
                fill={value === "1" ? "white" : "#6B7280"}
              />
              <defs>
                <linearGradient
                  id="gradientTree"
                  x1="0"
                  y1="20"
                  x2="54"
                  y2="20"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4165C4" />
                  <stop offset="1" stopColor="#8E1FD2" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>
      </div>

      {/* Team Structure Tree */}
      {value === "1" && (
        <div>
          {loading ? (
            <div className="flex justify-center items-center h-full w-full">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500" />
            </div>
          ) : teamTreeData?.length ? (
            <div className="w-full h-full flex justify-center mb-6">
              <div className="w-max overflow-auto p-5 scrollbar-hide">
                {teamTreeData?.map((rootMember) => (
                  <Tree
                    key={rootMember?.id}
                    label={
                      <div className="relative flex flex-col items-center pt-4">
                        <div className="absolute -top-3">
                          {rootMember?.user?.profile_image ? (
                            <img
                              src={rootMember.user.profile_image}
                              alt="Profile"
                              className="w-10 h-10 rounded-full border-2 border-[#D1D5DB] shadow object-cover"
                            />
                          ) : (
                            <div className="w-10 h-10 rounded-full bg-[#BDBDBD] flex items-center justify-center text-sm font-semibold text-white border-2 border-[#D1D5DB] shadow">
                              {`${rootMember?.user?.firstName?.[0] ?? ""}${
                                rootMember?.user?.lastName?.[0] ?? ""
                              }`?.toUpperCase()}
                            </div>
                          )}
                        </div>

                        <div className="cursor-pointer pt-3.5 min-w-[188px] px-1 min-h-[80px] rounded-lg border-2 border-[#D1D5DB] bg-white flex flex-col items-center gap-1.5 shadow-sm hover:shadow-md transition-shadow duration-300">
                          <div className="text-[#1F2937] text-center font-poppins text-xs font-semibold leading-[15px]">
                            {rootMember?.user?.firstName}{" "}
                            {rootMember?.user?.lastName}
                            <span className="text-[#4B5563] text-[10px] font-normal">
                              {" "}
                              ({rootMember?.user?.employee_id})
                            </span>
                          </div>
                          <div className="text-[#1F2937] text-center font-poppins text-[10px] font-medium leading-none">
                            {rootMember?.user?.Designation?.name}
                          </div>
                          <div className="text-[#374151] text-center font-poppins text-[10px] font-normal leading-none">
                            {rootMember?.user?.Department?.name}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {rootMember?.children?.map((child) => (
                      <RenderTreeNode key={child?.id} member={child} />
                    ))}
                  </Tree>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full w-full">
              <img
                src={noResult}
                alt="no-result"
                className="w-[250px] h-[250px]"
              />
            </div>
          )}
        </div>
      )}

      {/* Team Structure Table */}
      {value === "2" && <TeamStructureTable teamData={teamTreeData} />}
    </>
  );
};

export default TeamStructure;
