import React, { useCallback, useEffect, useState } from "react";
import StackedBarChart from "../common/Charts/StackedBarChart";
import DynamicTable from "../common/DynamicTable";
import { ArrowUpDown, Download } from "lucide-react";
import Search from "../SearchBar/Search";
import { Button } from "../common/Button";
import { debounce } from "lodash";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import eye from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import { Fetch_User_Leave_Data } from "../common/services/Employee";
import { Fetch_Leave_List } from "../common/services/Leave";
import { useSelector } from "react-redux";
import TablePaginationDemo from "../common/Pagenation";
import moment from "moment";
import axiosInstance from "../../app/api/axiosInstance";

interface LeaveType {
  id: number;
  leave_type_name: string;
  leave_code: string;
  allotted_leaves: number;
}

interface UserLeave {
  id: number;
  allotted_leaves: number;
  used_leave: number;
  LeaveType: LeaveType;
}

interface ILeave {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
  UserLeaves: UserLeave[];
  total_leave: number;
  used_leave: number;
  remaining_leave: number;
}
interface ILeaveList {
  User: {
    firstName: string;
    lastName: string;
    middleName: string;
    employee_id: string;
    profile_image: string;
    Department: {
      name: string;
    };
  };
  LeaveType: {
    leave_type_name: string;
  };
  from_date: string;
  to_date: string;
  id: string;
  no_of_days: number;
  leave_status: string;
  leave_session: string;
}
const graphLabels = [
  "Sick Leaves",
  "Casual Leaves",
  "Earned Leaves",
  "Leave Without Pay",
];

const leaveCodeToLabel: Record<string, string> = {
  SL: "Sick Leaves",
  CL: "Casual Leaves",
  EL: "Earned Leaves",
  LWP: "Leave Without Pay",
};

export default function EmployeeLeave({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [leaveData, setLeaveData] = useState<ILeave>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tableData, setTableData] = useState<ILeaveList[]>([]);
  const loginData = useSelector(
    (state: any) => state?.auth?.login?.login_details
  );

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
      setPage(1);
    }, 1000),
    [setSearchTerm]
  );
  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const fetchData = useCallback(async () => {
    try {
      const response = await Fetch_Leave_List({
        customer_id: customer_id,
        numOfItems: numOfItems,
        page: page,
        searchTerm: searchTerm,
        startDate: startDate,
        endDate: endDate,
        user_id: id,
      });
      if (response.status === 200) {
        setTableData(response.data?.result?.rows);
        setCount(response?.data?.result?.count);
      } else {
      }
    } catch (error) {}
  }, [customer_id, endDate, numOfItems, page, searchTerm, startDate, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const downloadExcelFile = async () => {
    try {
      let query = `/api/v1/user-leave-report?customer_id=${customer_id}&user_id=${id}`;

      if (startDate && endDate) {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Leave_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file");
    }
  };

  const fetchUserLeaveData = useCallback(async () => {
    try {
      const response = await Fetch_User_Leave_Data(id, startDate, endDate);
      if (response.status_code === 200) {
        setLeaveData(response?.result ?? {});
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [endDate, id, startDate]);

  useEffect(() => {
    fetchUserLeaveData();
  }, [fetchUserLeaveData]);

  const columns: ColumnDef<ILeaveList>[] = [
    {
      accessorKey: "leave_session",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Period Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "leave_type_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Leave Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.LeaveType.leave_type_name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.LeaveType.leave_type_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "from_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.from_date
          ? new Date(row.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.from_date
          ? new Date(row.original.from_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "to_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.to_date
          ? new Date(row.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.to_date
          ? new Date(row.original.to_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "leave_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.leave_status ?? "-",
      cell: ({ row }) => {
        return row?.original?.leave_status ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full max-w-[100px] ${
              row.original.leave_status === "Approved"
                ? " bg-[#E0F1E7] text-[#0F9446]"
                : row.original.leave_status === "Rejected"
                ? " bg-[#EDCFCF] text-[#EB3F3F]"
                : " bg-[#f8f2e2]"
            }`}
          >
            {row?.original?.leave_status}
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/leave-details/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate(`/edit-leave/${row?.original?.id}`, {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const sickLeaves =
    leaveData?.UserLeaves?.find(
      (item) => item.LeaveType?.leave_type_name === "Sick Leaves"
    )?.allotted_leaves || 0;

  const elLeave =
    leaveData?.UserLeaves?.find(
      (item) => item.LeaveType?.leave_type_name === "EL"
    )?.allotted_leaves || 0;

  const casual =
    leaveData?.UserLeaves?.find(
      (item) => item.LeaveType?.leave_type_name === "Casual Leaves"
    )?.allotted_leaves || 0;

  const lwp =
    leaveData?.UserLeaves?.find(
      (item) => item.LeaveType?.leave_type_name === "LWP"
    )?.allotted_leaves || 0;

  const leaveMap = leaveData?.UserLeaves?.reduce((acc, leave) => {
    const label =
      leaveCodeToLabel[
        leave.LeaveType.leave_code as keyof typeof leaveCodeToLabel
      ];

    acc[label] = {
      used: leave.used_leave || 0,
      remaining: leave.allotted_leaves - leave.used_leave || 0,
    };

    return acc;
  }, {} as Record<string, { used: number; remaining: number }>);

  if (leaveMap && !leaveMap["Leave Without Pay"]) {
    leaveMap["Leave Without Pay"] = { used: 0, remaining: 0 };
  }

  const GraphData = [
    {
      label: "Used Leaves",
      data: graphLabels.map((label) => leaveMap?.[label]?.used || 0),
      color: "#F4C1C1",
    },
    {
      label: "Remaining Leaves",
      data: graphLabels.map((label) => leaveMap?.[label]?.remaining || 0),
      color: "#BEE5B9",
    },
  ];

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div className="">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search onChange={updateSearchTerm} label="Search" />
            <div className="h-full flex items-center">
              <div className="h-full pl-4">
                <Button
                  onClick={downloadExcelFile}
                  className="border rounded-md gap-2"
                >
                  <Download /> Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-2">
        <div className="table_main_content w-full relative overflow-auto bg-white">
          <DynamicTable<ILeaveList>
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>
      </div>
      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      <div className="border rounded-md px-5 pb-3">
        <div className="py-3 text-light-black font-semibold text-base font-['Poppins']">
          Leave Overview
        </div>
        <div className="flex justify-between gap-5 flex-wrap items-center ">
          <div className="h-[98px] rounded-lg flex-1 bg-[rgba(172,201,255,0.25)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Allocated Leaves
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {leaveData?.total_leave}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>{" "}
          <div className="h-[98px] flex-1 rounded-lg bg-[rgba(253,224,224,0.30)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Used Leaves
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {leaveData?.used_leave}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>
          <div className="h-[98px] flex-1 rounded-lg bg-[rgba(205,235,197,0.30)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Remaining Leaves
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {leaveData?.remaining_leave}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>
        </div>
        <div className="flex justify-between gap-5 flex-wrap items-center py-4">
          <div className="h-[98px] rounded-lg flex-1  shadow-md flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Sick Leaves
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {sickLeaves || 0}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>{" "}
          <div className="h-[98px] flex-1 rounded-lg  shadow-md flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Casual Leaves
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {casual || 0}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>
          <div className="h-[98px] flex-1 rounded-lg  shadow-md flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Earned Leaves
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {elLeave || 0}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>
          <div className="h-[98px] flex-1 rounded-lg  shadow-md flex flex-col justify-center items-start pl-4 gap-2">
            <p className="text-grey300 text-base font-medium font-['Inter']">
              Leave Without Pay
            </p>
            <p className="text-2xl font-semibold text-light-black">
              {lwp || 0}{" "}
              <span className="text-lg font-normal text-grey400">days</span>
            </p>
          </div>
        </div>
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[350px] w-full">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Leave Balance
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <StackedBarChart chartData={GraphData} labels={graphLabels} />
          </div>
        </div>
      </div>
    </div>
  );
}
