import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Box, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import right from "../../image/chevron-right.png";
import FileUpload from "../ExpenseManagement/FileUpload";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import { AssetGategoryData } from "./AssetCategory";
import {
  Add_Asset,
  Fetch_Asset_Catergory,
  Fetch_Project_List,
} from "../common/services/Asset";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import MyStatefulEditor from "../ProjectManagement/Editor";
import SuccessModal from "../common/SuccessModal";
import { Button } from "../common/Button";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface data {
  user_id: string;
  asset_category_id: string;
  asset_name: string;
  asset_serial_number: string;
  assigned_date: Dayjs | string | null;
  description: string;
  project_id: string;
  image_filename: string[] | null;
}

interface Projects {
  id: number;
  project_name: string;
}

function AddAsset() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const [category, setCategory] = useState<AssetGategoryData[]>();
  const [date, setDate] = React.useState<Dayjs | string | null>(null);
  const [editorContent, setEditorContent] = useState("");
  const [errors, setErrors] = useState<Partial<data>>({});
  const [project, setProject] = useState<Projects[]>([]);
  const [open, setOpen] = useState(false);
  const [assetData, setAssetData] = useState<data>({
    user_id: "",
    asset_category_id: "",
    asset_name: "",
    asset_serial_number: "",
    assigned_date: date,
    description: editorContent,
    project_id: "",
    image_filename: null,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilesChange = (files: UploadedFile[]) => {
    setAssetData({
      ...assetData,
      image_filename: files?.map((files) => files.url),
    });
  };

  const handleEmployeeSelect = (employeeId: string) => {};

  const handleEmployeeSelectId = (employeeId: string) => {
    setAssetData({
      ...assetData,
      user_id: String(employeeId),
    });
  };

  const handleSave = () => {
    const res = Validate();
    if (res) postData();
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAssetData({
      ...assetData,
      [name]: value,
    });
    // setErrors({
    //   ...errors,
    //   [name]: "",
    // });
  };

  const Validate = (): boolean => {
    let tempErrors: Partial<data> = {};

    if (assetData.user_id === "")
      tempErrors.user_id = "Please Select an Employee";
    if (assetData.asset_category_id === "")
      tempErrors.asset_category_id = "Please Select an Asset Category";
    if (!assetData.asset_name.trim())
      tempErrors.asset_name = "Asset Name is Required";
    if (!assetData.asset_serial_number.trim())
      tempErrors.asset_serial_number = "Serial Number is Required";
    if (assetData.project_id === "")
      tempErrors.project_id = "Please select a Project";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const postData = async () => {
    try {
      const response = await Add_Asset(assetData);
      if (response.status === 200) {
        setOpen(true);
        setTimeout(() => {
          handleClose();
          navigate("/asset-management");
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    setAssetData({
      ...assetData,
      assigned_date: dayjs(date).format("MM-DD-YYYY"),
      description: editorContent,
    });
  }, [date, setDate, editorContent, setEditorContent]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Asset_Catergory(customer_id, 100, 1, "");
        if (response.status === 200) {
          setCategory(response.data.result.rows);
        } else {
          toast.error(response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Project_List(customer_id);
        if (response.status === 200) {
          setProject(response.data.result);
        } else {
          toast.error(response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/asset-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Add Asset Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/asset-management")}
              className="flex w-[148px] rounded-lg p-[13.18px_50px_14.82px_51px] justify-center items-center  borderButton h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <Button
              type="submit"
              form="form"
              variant={"gradient"}
              onClick={handleSave}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Save
              </div>
            </Button>
          </div>
        </div>
        <div className="rounded-lg shadow-custom p-4 bg-white">
          <div className="flex flex-col gap-8">
            <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626]">
              Assigned Assset Details
            </span>
            <Box component="form" noValidate autoComplete="off">
              <div className="grid grid-cols-3 gap-8">
                <EmployeeSearch
                  onEmployeeSelect={handleEmployeeSelect}
                  onEmployeeSelectId={handleEmployeeSelectId}
                />
                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Asset Category"
                  name="asset_category_id"
                  value={category}
                  onChange={handleFormChange}
                  error={!!errors.asset_category_id}
                  helperText={errors.asset_category_id}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                >
                  {category?.map((option) => (
                    <MenuItem key={Number(option.id)} value={String(option.id)}>
                      {option.category}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  id="outlined-required"
                  label="Asset Name"
                  name="asset_name"
                  value={assetData.asset_name}
                  onChange={handleFormChange}
                  error={!!errors.asset_name}
                  helperText={errors.asset_name}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Serial Number"
                  name="asset_serial_number"
                  value={assetData.asset_serial_number}
                  onChange={handleFormChange}
                  error={!!errors.asset_serial_number}
                  helperText={errors.asset_serial_number}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Assigned Date"
                    minDate={dayjs()}
                    onChange={(newValue) => setDate(newValue)}
                    value={date}
                  />
                </LocalizationProvider>
                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Assigned Project"
                  name="project_id"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          scrollbarWidth: "thin",
                        },
                      },
                    },
                  }}
                  value={assetData.project_id}
                  onChange={handleFormChange}
                  error={!!errors.project_id}
                  helperText={errors.project_id}
                >
                  {project?.map((option) => (
                    <MenuItem key={Number(option.id)} value={String(option.id)}>
                      {option.project_name}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="col-span-3">
                  <MyStatefulEditor onChange={setEditorContent} />
                </div>
                <div className="col-span-3 relative">
                  <span className="font-[Poppins] font-medium tect-[16px] leading-[16px] text-[#262626] absolute px-2">
                    Attachment
                  </span>
                  <FileUpload onFilesChange={handleFilesChange} />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <SuccessModal
        label="Asset Added Succesfully!"
        isOpen={open}
        onclose={handleClose}
      />
    </div>
  );
}

export default AddAsset;
