import React, { useCallback, useEffect, useState } from "react";
import DoughnutChart, {
  centerTextPlugin,
} from "../common/Charts/DoughnutChart";
import StackedBarChart from "../common/Charts/StackedBarChart";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import dayjs from "dayjs";
import { LocationAndAddress } from "../AttendenceDetails/MultiCheckInDetails";
import profile from "../../assets/Ellipse 580.png";
import { useNavigate } from "react-router-dom";
import edit from "../../assets/Crud_Icons/edit.svg";
import DynamicTable from "../common/DynamicTable";
import TableSkeleton from "../common/TableSkeleton";
import TablePaginationDemo from "../common/Pagenation";
import Search from "../SearchBar/Search";
import { debounce } from "lodash";
import {
  Fetch_Attendance_Data,
  Fetch_Attendance_Stats,
  Fetch_Attendance_Status,
  Fetch_User_Attendance,
  Fetch_User_Attendance_List,
} from "../common/services/Employee";
import { useSelector } from "react-redux";
import moment from "moment";
import axiosInstance from "../../app/api/axiosInstance";

export interface IAttendanceRecord {
  id: number;
  user_id: number;
  customer_id: number;
  status: string;
  start_time: string;
  end_time: string;
  date: string;
  check_in_address: string;
  check_out_address: string;
  checkout_by: string;
  mode_of_work: string;
  mode_of_work_checkout: string;
  check_in_status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  duration: string;
  User: {
    id: number;
    prefix: string;
    firstName: string;
    lastName: string;
    employee_id: string;
    department_id: number;
    profile_image: string;
  };
  selected_address: string;
  selected_checkout_address: string;
}

interface IEMPStats {
  WFO: number;
  WFH: number;
  OnLeave: number;
  totalWorking: number;
}

interface IAttendanceStatus {
  WFH: number;
  WFO: number;
  DELAYED: number;
  NOT_CHECKED_IN: number;
}

interface IWeeklyAttendance {
  WFO: number;
  WFH: number;
  OnLeave: number;
  totalWorking: number;
}

export default function EmployeeAttendance({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
}) {
  // const {
  //   statusList,
  //   attendanceStatus,
  //   weeklyAttendance,
  //   attendanceNumOfItems,
  //   attendancePage,
  //   attendanceCount,
  //   attendanceData,
  //   setAttendanceSearchTerm,
  //   setAttendanceNumOfItems,
  //   setAttendancePage,
  // } = UseEmployee({
  //   id,
  // });
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [statusList, setStatusList] = useState<IEMPStats>();
  const [attendanceCount, setAttendanceCount] = useState(0);
  const [attendanceNumOfItems, setAttendanceNumOfItems] = useState(10);
  const [attendancePage, setAttendancePage] = useState(1);
  const [attendanceSearchTerm, setAttendanceSearchTerm] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState<IAttendanceStatus>();
  const [weeklyAttendance, setWeeklyAttendance] = useState<
    IWeeklyAttendance | {}
  >({});

  const [attendanceData, setAttendanceData] = useState<IAttendanceRecord[]>([]);
  const WFH = statusList?.WFH ? statusList?.WFH : 0;
  const WFO = statusList?.WFO ? statusList?.WFO : 0;
  const leave = statusList?.OnLeave ? statusList?.OnLeave : 0;
  const totalWorkingDays = statusList?.totalWorking
    ? statusList?.totalWorking
    : 0;
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await Fetch_Attendance_Stats({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setStatusList(response?.data?.result ?? {});
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [endDate, id, startDate]);

  const fetchAttendanData = async () => {
    try {
      const response = await Fetch_User_Attendance_List({
        id: id,
        customer_id: customer_id,
        start_date: startDate,
        end_date: endDate,
        page: attendancePage,
        size: attendanceNumOfItems,
        // searchTerm: attendanceSearchTerm,
      });
      if (response.status === 200) {
        const dataWithDurations = response.data.result?.rows?.map(
          (item: IAttendanceRecord) => {
            const startDateTime = dayjs(item.start_time);
            const endDateTime = dayjs(item.end_time);
            const duration = dayjs.duration(endDateTime.diff(startDateTime));

            const totalMinutes = Math.floor(duration.asMinutes());
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");

            const durationString = `${formattedHours}:${formattedMinutes}`;

            return {
              ...item,
              duration: durationString,
            };
          }
        );

        setAttendanceData(dataWithDurations?.length ? dataWithDurations : []);
        setAttendanceCount(response?.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchAttendanData();
  }, [
    attendanceNumOfItems,
    attendancePage,
    attendanceSearchTerm,
    customer_id,
    endDate,
    id,
    startDate,
  ]);

  const downloadExcelFile = async () => {
    try {
      let query = `/api/v1/attendance-report?customer_id=${customer_id}&user_id=${id}`;

      if (startDate && endDate) {
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");
        query += `&start_date=${start}&end_date=${end}`;
      }
      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Attendance_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const fetchAttendanceStatusChart = async () => {
    try {
      const response = await Fetch_Attendance_Status({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setAttendanceStatus(response?.data?.result ?? {});
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAttendanceStatusChart();
  }, [endDate, id, startDate]);

  const fetchAttendanceGraphData = async () => {
    try {
      const response = await Fetch_Attendance_Data({
        id: id,
        start_date: startDate,
        end_date: endDate,
      });
      if (response.status === 200) {
        setWeeklyAttendance(response?.data?.result ?? {});
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAttendanceGraphData();
  }, [endDate, id, startDate]);

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    centerText: `${totalWorkingDays}\ndays`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
      centerTextPlugin,
    },
  };
  function handleNumberOfItems(value: number) {
    setAttendanceNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setAttendancePage(value);
  }
  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setAttendanceSearchTerm(event.target.value);
    }, 1000),
    [setAttendanceSearchTerm]
  );

  const columns: ColumnDef<IAttendanceRecord>[] = [
    {
      accessorKey: "date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.date
          ? new Date(row.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.date
          ? new Date(row.original.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return (
          <div className="font-inter text-[14px] font-medium leading-[20px]">
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: "start_time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Check In Time & Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.start_time ? dayjs(row.start_time).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div className="">
            <LocationAndAddress
              dateTime={dayjs(row?.original?.start_time).format("h:mm A")}
              address={row?.original?.check_in_address}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "end_time",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Check Out Time & Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.end_time ? dayjs(row.end_time).format("h:mm A") : "-",
      cell: ({ row }) => {
        return (
          <div className="">
            <div className="">
              {row.original.end_time ? (
                <>
                  <LocationAndAddress
                    dateTime={dayjs(row?.original?.end_time).format("h:mm A")}
                    address={row?.original?.check_out_address}
                  />
                </>
              ) : (
                <span>--</span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Duration
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.duration ?? "-",
      cell: ({ row }) => {
        if (row?.original?.end_time) {
          return <div>{row?.original?.duration} Hrs.</div>;
        } else {
          return <div>--</div>;
        }
      },
    },
    {
      accessorKey: "check_in_status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.check_in_status ?? "-",
      cell: ({ row }) => {
        return row?.original?.check_in_status ? (
          <div
            className={`flex justify-center items-center gap-2 py-[2px] px-[10px] rounded-full ${
              row.original.check_in_status === "OnTime"
                ? " bg-[#E0F1E7] text-[#0F9446]"
                : " bg-[#EDCFCF] text-[#EB3F3F]"
            }`}
          >
            {row.original.check_in_status}
          </div>
        ) : (
          <div>--</div>
        );
      },
    },
    {
      accessorKey: "mode_of_work",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Workspace
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.mode_of_work ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.mode_of_work ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "selected_address",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Selected Check in Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.selected_address ? row?.selected_address : "-",
      cell: ({ row }) => {
        return row?.original?.selected_address ? (
          <div>
            <LocationAndAddress address={row.original.selected_address} />
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      accessorKey: "selected_checkout_address",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Selected Check Out Location
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.selected_checkout_address ? row?.selected_checkout_address : "-",
      cell: ({ row }) => {
        return row?.original?.selected_checkout_address ? (
          <div>
            <LocationAndAddress
              address={row.original.selected_checkout_address}
            />
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    // {
    //   id: "actions",
    //   header: ({ column }) => (
    //     <div className="flex justify-end mr-2">
    //       <Button variant="ghost" className="p-0">
    //         Action
    //       </Button>
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div className="flex items-center justify-end gap-2">
    //         <Button
    //           type="button"
    //           className="p-0 mr-4"
    //           onClick={(e) => {
    //             // e.stopPropagation();
    //             navigate(
    //               `/user-attendance-management/${row?.original?.user_id}/${row.original?.date}`
    //             );
    //           }}
    //         >
    //           <img
    //             src={edit}
    //             alt="Edit"
    //             className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
    //           />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const employeeStats = [
    {
      label: "Delayed",
      value: attendanceStatus?.DELAYED ? attendanceStatus?.DELAYED : 0,
      color: "#FDAAAA",
    },
    {
      label: "On time",
      value:
        Number(attendanceStatus?.WFH ? attendanceStatus?.WFH : 0) +
        Number(attendanceStatus?.WFO ? attendanceStatus?.WFO : 0) -
        Number(attendanceStatus?.DELAYED ? attendanceStatus?.DELAYED : 0),
      color: "#8AB3FF",
    },
    {
      label: "Not checked in",
      value: attendanceStatus?.NOT_CHECKED_IN
        ? attendanceStatus?.NOT_CHECKED_IN
        : 0,
      color: "#CEDFFF",
    },
  ];

  const attendanceGraphData = [
    {
      label: "Working From Office",
      data: Object.values(weeklyAttendance)?.map((week) => week.WFO),
      color: "#BEE5B9",
    },
    {
      label: "Working From Home",
      data: Object.values(weeklyAttendance)?.map((week) => week.WFH),
      color: "#ACC9FF",
    },
    {
      label: "On Leave",
      data: Object.values(weeklyAttendance)?.map((week) => week.OnLeave),
      color: "#F4C1C1",
    },
  ];

  const attendanceLabels = Object?.keys(weeklyAttendance)?.map(
    (week, index) => `Week ${index + 1}`
  );

  const attendenceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => {
            return tooltipItems[0].label;
          },
          // label: (tooltipItem: any) => {
          //   let datasetLabel = data?.datasets[tooltipItem.datasetIndex].label;
          //   let value = tooltipItem.raw;
          //   return `${datasetLabel}: ${value}`;
          // },
          footer: (tooltipItems: any) => {
            // Calculate total sum for the hovered bar
            const total = tooltipItems?.reduce(
              (sum: number, item: any) => sum + item.raw,
              0
            );
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="flex flex-col gap-3 mt-2">
      <div className="flex justify-between gap-4 flex-wrap items-center ">
        <div className="h-[98px] rounded-lg flex-1 bg-[rgba(15,148,70,0.07)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Total Working Days
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {totalWorkingDays}{" "}
            <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>{" "}
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(57,104,242,0.08)]  shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Working From Office
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {WFO} <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(65,101,196,0.08)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Working From Home
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {WFH} <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[rgba(217,37,37,0.08)] shadow-sm flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            On Leave
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {leave}{" "}
            <span className="text-lg font-normal text-grey400">days</span>
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-between">
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[35%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Check in/Check out Overview
            </p>
          </div>
          <div className="flex justify-center w-full h-[90%] mt-2">
            <DoughnutChart chartData={employeeStats} options={defaultOptions} />
          </div>
        </div>
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[65%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Attendance
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <StackedBarChart
              chartData={attendanceGraphData}
              labels={attendanceLabels}
              options={attendenceOptions}
            />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search onChange={updateSearchTerm} label="Search " />
            <div className="h-full flex items-center">
              <div className="h-full pl-4">
                <Button
                  onClick={downloadExcelFile}
                  className="border rounded-md gap-2"
                >
                  <Download onClick={downloadExcelFile} /> Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-2">
        <div className="table_main_content w-full relative overflow-auto bg-white">
          <DynamicTable<IAttendanceRecord>
            onRowClick={(row) => {
              navigate(
                `/user-attendance-management/${row?.user_id}/${row?.date}`
              );
            }}
            data={attendanceData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>
      </div>

      {attendanceCount >= 10 && (
        <TablePaginationDemo
          count={attendanceCount}
          handleItemsChange={handleNumberOfItems}
          handlePageChange={handlePageChange}
          currentPage={attendancePage}
          numOfItems={attendanceNumOfItems}
        />
      )}
    </div>
  );
}
