import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_EMPLOYEE_DSR } from "../api";
import moment from "moment";
import axiosInstance from "../../../app/api/axiosInstance";

export const Fetch_Employee_DSR_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  startDate: Date | null,
  endDate: Date | null,
  projectSearch: string
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }
  if (projectSearch !== "") {
    query += `&project_search=${projectSearch}`;
  }
  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axiosInstance.get(
    `${FETCH_EMPLOYEE_DSR}${query}&session_start=${localStorage.getItem(
      "start_session"
    )}&session_end=${localStorage.getItem("end_session")}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const fetchDSRDetails = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axiosInstance.get(
      `${base_api}/api/v1/employee-timesheet/${id}`,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const approveDSRRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axiosInstance.patch(
      `${base_api}/api/v1/employee-timesheet/approve-reject/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const multiDSRApproveRejectApi = async ({
  status,
  selectedIds,
}: {
  status: string;
  selectedIds: number[];
}) => {
  const access_token = localStorage.getItem("access_token");
  const data: any = {
    status,
    timesheet_id: selectedIds,
  };

  try {
    const response = await axiosInstance.patch(
      `${base_api}/api/v2/employee-timesheet/approve-reject`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
