import {
    api_key_work as api_key,
    base_api,
} from "../../../utils/helperData";
import axiosInstance from "../../../app/api/axiosInstance";
import { CREATE_TEAM_STRUCTURE, GET_TEAM_STRUCTURE } from "../api";

export const Fetch_Team_Structure = (customer_id: number) => {
    const access_token = localStorage.getItem("access_token");
    const startSession = localStorage.getItem("start_session");
    const endSession = localStorage.getItem("end_session");

    const session_query =
        startSession && endSession
            ? `&session_start=${startSession}&session_end=${endSession}`
            : "";

    let query = `?customer_id=${customer_id}`;

    return axiosInstance.get(`${GET_TEAM_STRUCTURE}${query}${session_query}`, {
        headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
        },
    });
};

export const Create_Team_Structure = (customer_id: number) => {
    const access_token = localStorage.getItem("access_token");
    const startSession = localStorage.getItem("start_session");
    const endSession = localStorage.getItem("end_session");

    const session_query =
        startSession && endSession
            ? `&session_start=${startSession}&session_end=${endSession}`
            : "";

    let query = `?customer_id=${customer_id}`;

    return axiosInstance.post(`${CREATE_TEAM_STRUCTURE}${query}${session_query}`, {
        headers: {
            "x-api-key": api_key,
            Authorization: "Bearer " + access_token,
        },
    });
};