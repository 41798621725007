import React, { useEffect, useRef, useState } from "react";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEditEmployee } from "../common/services/Employee";
// import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import profile from "../../assets/Ellipse 580.png";
import dayjs from "dayjs";
import { Calendar, Mail, MapPin, Phone } from "lucide-react";
import { TabContext, TabPanel } from "@mui/lab";
import CustomTabs from "../common/CustomTabs";
import EmployeeOverview from "./EmployeeOverview";
import EmployeeInfo from "./EmployeeInfo";
import EmpDSR from "./EmpDSR";
import EmployeeAttendance from "./EmployeeAttendance";
import DatePicker from "react-datepicker";
import date2 from "../../assets/date2.svg";
// import UseEmployee from "./UseEmployee";
import { RootState } from "../../redux/store";
import { setSelectedTab } from "../../redux/reducers/Employee/EmpoyeeViewSlice";
import EmployeeExpense from "./EmployeeExpense";
import EmployeeLeave from "./EmployeeLeave";
import EmployeeAsset from "./EmployeeAsset";
import EmpTimeSheet from "./EmpTimeSheet";
import {
  clearEmployeeViewDate,
  setEmployeeViewDate,
} from "../../redux/reducers/FilterPersist/employeeViewDateSlice";
import edit from "../../assets/Crud_Icons/edit.svg";
import { Button } from "../common/Button";
import { ClearIcon } from "@mui/x-date-pickers";
import EmployeeBanner from "./EmployeeBanner";

export interface EmployeeData {
  empCode: string;
  empId: string;
  empName: string;
  empLastName: string;
  email: string;
  code: string;
  number: string;
  business_unit: string;
  role: string;
  department: string;
  designation: string;
  team_manager_firstName: string;
  team_manager_lastName: string;
  reporting_manager_firstName: string;
  reporting_manager_lastName: string;
  status: string;
  MoE: string;
  YOE: string;
  DOB: string;
  DOJ: string;
  DOL: string | null;
  profile_image: string | null;
  location: string | null;
  employeeType: string;
  bloodGroup: string | null;
  shiftId: number | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  prefix: string;
  alloted_wfh_per_week?: number | null;
  permanentAddress: string;
  currentAddress: string;
  business_unitaddress: string;
}

interface DateFilter {
  date?: [Date | null, Date | null];
}

const tabs = [
  { label: "Overview", value: "overview" },
  { label: "Employee Info", value: "info" },
  { label: "Project", value: "dsr" },
  { label: "Timesheet", value: "timesheet" },
  { label: "Attendance", value: "attendance" },
  { label: "Leave Balance", value: "leave" },
  { label: "Expense", value: "expense" },
  { label: "Asset", value: "asset" },
];
const ViewEmployeeList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const currentPage = useSelector((state: any) => state.emplist.currentPage);
  // const [loading, setLoading] = useState(false);

  const selectedTab = useSelector(
    (state: RootState) => state.employeeView.selectedTab
  );
  // const filterDate = useSelector(
  //   (state: RootState) => state.employeeViewDate.date
  // );
  const filterDate = useSelector(
    (state: RootState) => state.employeeViewDate.date
  )?.map((date) => (date ? new Date(date) : null));

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setSelectedTab(newValue));
  };

  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    empCode: "",
    empId: "",
    empName: "",
    empLastName: "",
    email: "",
    code: "",
    number: "",
    business_unit: "",
    role: "",
    department: "",
    designation: "",
    team_manager_firstName: "",
    team_manager_lastName: "",
    reporting_manager_firstName: "",
    reporting_manager_lastName: "",
    status: "",
    MoE: "",
    YOE: "",
    DOB: "",
    DOJ: "",
    DOL: null,
    profile_image: null,
    location: null,
    employeeType: "",
    bloodGroup: null,
    shiftId: null,
    isActive: false,
    createdAt: "",
    updatedAt: "",
    prefix: "",
    alloted_wfh_per_week: null,
    currentAddress: "",
    permanentAddress: "",
    business_unitaddress: "",
  });
  const location = useLocation();
  const id = location.state?.id;

  // const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
  //   filterDate ?? [null, null]
  // );

  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const todayDate: [Date | null, Date | null] = [startOfMonth, today];

  const isSameDate = (date1: Date | null, date2: Date | null) => {
    if (!date1 || !date2) return false;
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  // useEffect(() => {
  //   if (filterDate && filterDate[0] && [filterDate[1]]) {
  //     setDateRange(filterDate);
  //   }
  // }, [filterDate]);

  // const [startDate, endDate] = dateRange;
  const [open, setOpen] = useState(false);

  const handleDateChange = (date: [Date | null, Date | null]) => {
    // setDateRange(date);
    dispatch(setEmployeeViewDate({ date: date }));
    if (date[0] && date[1]) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!id) return;

    fetchEditEmployee(id)
      .then((data) => {
        if (data?.result) {
          setEmployeeData({
            empCode: data?.result?.employee_code || "",
            empId: data?.result?.employee_id || "",
            empName: data?.result?.firstName || "",
            empLastName: data?.result?.lastName || "",
            email: data?.result?.email || "",
            code: data?.result?.country_code || "",
            number: data?.result?.phone || "",
            business_unit: data?.result?.Group?.name || "",
            role: data?.result?.Role?.role_name || "",
            department: data?.result?.Department?.name || "",
            designation: data?.result?.Designation?.name || "",
            team_manager_firstName: data?.result?.team_manager?.firstName || "",
            team_manager_lastName: data?.result?.team_manager?.lastName || "",
            reporting_manager_firstName:
              data?.result?.reporting_manager?.firstName || "",
            reporting_manager_lastName:
              data?.result?.reporting_manager?.lastName || "",
            status: data?.result?.status || "",
            MoE: data?.result?.mode_of_employment || "",
            YOE: data?.result?.experience || "",
            DOB: data?.result?.date_of_birth || "",
            DOJ: data?.result?.date_of_joining || "",
            DOL: data?.result?.date_of_leaving || null,
            profile_image: data?.result?.profile_image || null,
            location: data?.result?.location || null,
            employeeType: data?.result?.type || "",
            bloodGroup: data?.result?.blood_group || null,
            shiftId: data?.result?.shift_id || null,
            isActive: data?.result?.is_active || false,
            createdAt: data?.result?.createdAt || "",
            updatedAt: data?.result?.updatedAt || "",
            prefix: data?.result?.prefix || "",
            alloted_wfh_per_week: data?.result?.alloted_wfh_per_week || null,
            currentAddress: data?.result?.current_address || "",
            permanentAddress: data?.result?.permanent_address || "",
            business_unitaddress: data?.result?.Group?.businessUnit_address || "",
          });
        }
      })
      .catch((error) => console.error("Error fetching employee data:", error));
  }, [id]);

  const selectedCalendarRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="font-[Poppins] px-4 pt-6 pb-12 flex flex-col ">
        <div className="flex flex-row gap-[13px] justify-start items-center">
          <button
            onClick={() => {
              navigate(-1);
              dispatch(setSelectedTab("overview"));
              dispatch(clearEmployeeViewDate());
            }}
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-5 w-5 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] md:text-lg font-semibold leading-normal">
            Employee Management
          </span>
        </div>
        <EmployeeBanner employeeData={employeeData} />

        <TabContext value={selectedTab}>
          <div className="flex flex-wrap justify-between gap-2 py-3 mt-2">
            <div className="w-full xl:w-fit ">
              <CustomTabs tabs={tabs} onChange={handleTabChange} />
            </div>

            {selectedTab !== "info" ? (
              <div
                className="cursor-pointer flex h-[42px] p-4 justify-between items-center rounded-md border-[1px] border-solid border-[#E2E8F0] bg-white mr-2"
                ref={selectedCalendarRef}
              >
                <img
                  src={date2}
                  alt="date icon"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => setOpen(true)}
                />
                <DatePicker
                  className="w-[220px] text-center text-sm font-['Inter'] font-[400] cursor-pointer"
                  dateFormat={"dd MMM yyyy"}
                  onChange={handleDateChange}
                  startDate={filterDate![0]}
                  endDate={filterDate![1]}
                  maxDate={new Date()}
                  selectsRange
                  // isClearable
                  placeholderText="dd/mm/yyyy - dd/mm/yyyy"
                  readOnly
                  onClickOutside={() => setOpen(false)}
                  onFocus={() => setOpen(true)}
                  open={open}
                  customInput={
                    <input
                      style={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                        textAlign: "center",
                        // paddingRight: "10px",
                      }}
                    />
                  }
                />
                {filterDate &&
                  !(
                    isSameDate(filterDate[0], todayDate[0]) &&
                    isSameDate(filterDate[1], todayDate[1])
                  ) && (
                    <ClearIcon
                      onClick={() => {
                        dispatch(clearEmployeeViewDate());
                        setOpen(true);
                      }}
                    />
                  )}
              </div>
            ) : (
              <div>
                <Button
                  variant="gradient"
                  onClick={() => {
                    navigate(`/edit-employee`, {
                      state: { id: id },
                    });
                  }}
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={edit}
                    alt="edit icon"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  Edit
                </Button>
              </div>
            )}
          </div>

          <TabPanel className="tabpanel" value="overview">
            <EmployeeOverview
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
          <TabPanel className="tabpanel" value="info">
            <EmployeeInfo id={id} />
          </TabPanel>
          <TabPanel className="tabpanel" value="dsr">
            <EmpDSR
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
          <TabPanel className="tabpanel" value="timesheet">
            <EmpTimeSheet
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
          <TabPanel className="tabpanel" value="attendance">
            <EmployeeAttendance
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
          <TabPanel className="tabpanel" value="leave">
            <EmployeeLeave
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
          <TabPanel className="tabpanel" value="expense">
            <EmployeeExpense
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
          <TabPanel className="tabpanel" value="asset">
            <EmployeeAsset
              id={id}
              startDate={filterDate![0]}
              endDate={filterDate![1]}
            />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default ViewEmployeeList;
