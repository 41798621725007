import { api_key_work as api_key } from "../../../utils/helperData";
import axios from "axios";
import {
  FETCH_ANNOUNCEMENT_LIST,
  FETCH_BIRTHDAY_ANNIVERSARY_LIST,
  FETCH_DASHBOARD_WFH_LIST,
  FETCH_DELAY_LIST,
  FETCH_EMPLOYEE_ATTENDANCE_OVERVIEW,
  FETCH_FINANCIAL_YEAR,
  FETCH_LEAVE_DASHBORAD_LIST,
  FETCH_NOTCHECKIN_LIST,
  FETCH_WORK_FROM_OFFICE_LIST,
} from "../api";
import axiosInstance from "../../../app/api/axiosInstance";
import { Dayjs } from "dayjs";

export const Fetch_WFH_Dashboard_List = (
  numOfItems: number,
  page: number,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";
  query = `?size=${numOfItems}&page=${page}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_DASHBOARD_WFH_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_WFH_Dashboard_List_All = (
  numOfItems: number,
  page: number,
  searchTerm: string,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_DASHBOARD_WFH_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Dashboard_List = (
  numOfItems: number,
  page: number,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_LEAVE_DASHBORAD_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Dashboard_List_All = (
  numOfItems: number,
  page: number,
  searchTerm: string,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_LEAVE_DASHBORAD_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Birthday_Anniversary_Lst = (
  numOfItems: number,
  page: number,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_BIRTHDAY_ANNIVERSARY_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Announcement_Lst = (
  numOfItems: number,
  page: number,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_ANNOUNCEMENT_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Work_From_Office = (
  numOfItems: number,
  page: number,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_WORK_FROM_OFFICE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Delayed = ({
  numOfItems,
  page,
  searchTerm,
  date,
}: {
  numOfItems: number;
  page: number;
  searchTerm?: string;
  date: Dayjs;
}) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  if (searchTerm && searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (date) {
    const date1 = date.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_DELAY_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_NotCheckin = ({
  numOfItems,
  page,
  searchTerm,
  date,
}: {
  numOfItems: number;
  page: number;
  searchTerm?: string;
  date?: Dayjs;
}) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (date) {
    const date1 = date.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  if (searchTerm && searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  return axiosInstance.get(`${FETCH_NOTCHECKIN_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
export const Fetch_Work_From_Office_All = (
  numOfItems: number,
  page: number,
  searchTerm: string,
  selectedDate: Dayjs
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (selectedDate) {
    const date = selectedDate.format("YYYY-MM-DD");
    query += `&date=${date}`;
  }

  return axiosInstance.get(`${FETCH_WORK_FROM_OFFICE_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Attendance_Employee_Overview = (
  set: string,
  groupIds?: (number | null)[] | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (set && set !== "") {
    query += `?set=${set}`;
  }
  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  return axiosInstance.get(
    `${FETCH_EMPLOYEE_ATTENDANCE_OVERVIEW}${query}${session_query}`,
    {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const Fetch_Financial_year = ({
  set,
  groupIds,
}: {
  set?: string;
  groupIds?: (number | null)[] | null;
}) => {
  const access_token = localStorage.getItem("access_token");
  let query = "";

  if (set && set !== "") {
    query += `?set=${set}`;
  }
  if (groupIds) {
    query += `&group_id=${groupIds.join(",")}`;
  }

  const startSession = localStorage.getItem("start_session");
  const endSession = localStorage.getItem("end_session");

  const session_query =
    startSession && endSession
      ? `&session_start=${startSession}&session_end=${endSession}`
      : "";

  return axiosInstance.get(`${FETCH_FINANCIAL_YEAR}${query}${session_query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};
