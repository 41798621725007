import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
  data: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth?: number;
    }[];
  };
  options?: any;
  labels?: string[]; // Add labels prop
}

const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        borderRadius: 4,
      },
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += context.raw;
          }
          return label;
        },
      },
    },
  },
};

const defaultOptions1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          generateLabels: (chart: any) => {
            const data = chart.data;
            if (data.labels && data.datasets.length) {
              return data.labels.map((label: any, i: any) => ({
                text: `${label} (${data.datasets[0].data[i]})`, // Append count in brackets
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: data.datasets[0].borderColor[i],
                lineWidth: 1,
              }));
            }
            return [];
          },
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
    },
  };  

const PieChart: React.FC<PieChartProps> = ({
  data,
  options = defaultOptions1,
  labels,
}) => {
  const chartData = {
    ...data,
    labels: labels || data?.labels,
  };

  return <Pie data={chartData} options={options} />;
};

export default PieChart;
