import React, { useCallback, useEffect, useState } from "react";

import BarChart from "../../common/Charts/BarChart";
import StackedBarChart from "../../common/Charts/StackedBarChart";
import Search from "../../SearchBar/Search";
import { Button } from "../../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import filter from "../../../assets/filter.svg";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
// import { RootState } from "../../redux/store";

import DynamicTable from "../../common/DynamicTable";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import profile from "../../assets/Ellipse 580.png";
// import eye from "../../assets/Crud_Icons/view.svg";
import TablePaginationDemo from "../../common/Pagenation";
import { RootState } from "../../../redux/store";
import UserBadge from "../../common/UserBadge";
import StatusBadge from "../../common/StatusBadge";
import { Fetch_Holiday_List } from "../../common/services/Holiday";

interface UpcomingHoliday {
  holiday_date: string;
  holiday_name: string;
  description: string;
  id: string;
}

interface UpcomingHolidayProps {
  depId : string |  null,
  busId : string | null,
  dates: [Date | null, Date | null] | undefined;

}

const UpcomingHoliday : React.FC<UpcomingHolidayProps> = ({ depId , busId, dates}) => {
  const navigate = useNavigate();
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<UpcomingHoliday[]>([]);
  const [loading, setLoading] = useState(false);
  const sortType = { key: "holiday_date", order: "ASC" };

  // const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
  //   filter_data?.date
  // );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const [selectedStatus, setSelectedStatus] = useState<
      string | undefined
    >(filter_data?.status);
  // useEffect(()=>{
  //   if (filter_data.date)
  //   setDates(filter_data.date)
  // }, [filter_data])

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Holiday_List(
        numOfItems,
        page,
        searchTerm ?? "",
        dates ? dates[0] : null,
        dates ? dates[1] : null,
        sortType,
        true
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);

        setCount(response?.data?.result?.count ?? 0);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  }, [numOfItems, page, searchTerm, dates]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: ColumnDef<UpcomingHoliday>[] = [
    {
      accessorKey: "holiday_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 ml-4"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Holiday
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.holiday_name ?? "-",
      cell: ({row}) => { const name: string = row?.original?.holiday_name ?? "-";
        return <div className="ml-4">{name}</div>;
      }
    },
    {
      accessorKey: "holiday_date",
      header: ({ column }) => (
        <div className="flex justify-end mr-7">
        <Button
          variant="ghost"
          className="p-0 ml-4"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
        </div>
      ),
      accessorFn: (row) =>
        row?.holiday_date
          ? new Date(row.holiday_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.holiday_date
          ? new Date(row.original.holiday_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="py-2 flex items-center justify-end gap-2 mr-4">{name}</div>;
      },
    },
  ];

  return (
    <>
      <div className="overflow-x-auto mt-4 mb-10">
        <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
          <DynamicTable<UpcomingHoliday>
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}
    </>
  );
};

export default UpcomingHoliday;
