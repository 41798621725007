import { useCallback, useEffect, useState } from "react";
import filter from "../../assets/filter.svg";
import view from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import download from "../../assets/download.svg";
import { useNavigate } from "react-router-dom";
import TablePaginationDemo from "../common/Pagenation";
import "./table.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { Fetch_Employee_Management_List } from "../common/services/Employee";
import TableSkeleton from "../common/TableSkeleton";
import DynamicTable from "../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import { Close } from "@mui/icons-material";
import { setSelectedTab } from "../../redux/reducers/Employee/EmpoyeeViewSlice";
import AttFilter, { FilterData } from "../Filter/AttFilter";
import { setFilterPersistData } from "../../redux/reducers/FilterPersist/FilterPersistSlice";
import { RootState } from "../../redux/store";
import PersistedSearch from "./EmployeeListSearch";
import StatusBadge from "../common/StatusBadge";
import UserBadge from "../common/UserBadge";
import EmpFilter, { EmpFilterData } from "./EmpFIlter";
import axiosInstance from "../../app/api/axiosInstance";
import { api_key_work, base_api } from "../../utils/helperData";

interface IEmployeeList {
  firstName: string;
  middleName: string;
  lastName: string;
  employee_id: string;
  Department: {
    name: string;
  };
  Designation: {
    name: string;
  };
  total_user: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  id: number;
  profile_image: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

const EmployeeList = () => {
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector((state: RootState) => state.filterPersist);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  // const currentPage = useSelector((state: any) => state.emplist.currentPage);
  const [page, setPage] = useState(1);
  // const [persistPage, setpersistPage] = useState(false);

  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<IEmployeeList[]>([]);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [depId, setDepId] = useState<string | null>(
    filter_data?.department ?? null
  );
  const [busId, setBusId] = useState<string | null>(filter_data?.group ?? null);
  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const [modeOfEmp, setModeOfEmp] = useState<string | null>(
    filter_data?.mode_of_employment ?? null
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );
  const navigate = useNavigate();

  function handlePageChange(value: number) {
    setPage(value);
    // dispatch(EmployeeListActions.setCurrentPage(value));
  }

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_Management_List(
        customer_id,
        numOfItems,
        page,
        Number(busId),
        Number(depId),
        searchTerm ?? "",
        modeOfEmp
      );
      if (response.status === 200) {
        setTableData(response.data?.result?.rows ?? []);
        setCount(response.data?.result?.count ?? 0);
        const fetchedData: EmployeeData[] = response.data?.result?.rows ?? [];

        const columnMapping: { [key: string]: string } = {
          employee_code: "Employee Code",
          Name: "Name",
          "Designation.name": "Designation",
          "Department.name": "Department",
        };

        const filteredData = fetchedData?.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object?.keys(columnMapping)?.forEach((key) => {
            const keys = key.split("."); // Split the key to handle nested fields

            if (key === "Name") {
              // Combine firstName and lastName under the "Name" column
              filteredRow["Name"] = `${row.firstName || ""} ${
                row.lastName || ""
              }`.trim();
            } else if (keys.length === 1) {
              // Handle non-nested fields
              if (row.hasOwnProperty(keys[0])) {
                filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              // Handle nested fields
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setTableData1(filteredData);

        // Set the columns for the table
        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  }, [searchTerm, page, numOfItems, busId, depId, modeOfEmp]);

  // const isFirstRender = useRef(true);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // useEffect(() => {
  //   if (!persistPage) {
  //     dispatch(EmployeeListActions.setCurrentPage(1));
  //   }
  //   fetchData();
  // }, [persistPage, searchTerm, page, numOfItems, selectedBusinessUnit?.id, selectedDepartmentUnit?.id]);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns?.map((col) => col.displayName), // Header row
        ...tableData1?.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "employee_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
  
      const queryParams = new URLSearchParams({
        customer_id: customer_id.toString(),
      });
      if (busId) queryParams.append("group_id", busId.toString());
      if (depId) queryParams.append("department_id", depId.toString());
      if (modeOfEmp) queryParams.append("mode_of_employment", modeOfEmp.toString());
  
      const query = `/api/v1/user/lists-download?${queryParams.toString()}`;
    
      const response = await axiosInstance.get(query, {
        responseType: "blob",

        headers: {
          "x-api-key": api_key_work,
          Authorization: "Bearer" + access_token,
        },
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      const fileName = "Employee_Report.xlsx";
  
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }

  // const options = [
  //   { value: 'option1', label: 'Option 1' },
  //   { value: 'option2', label: 'Option 2' },
  //   { value: 'option3', label: 'Option 3' },
  // ];

  const columns2: ColumnDef<IEmployeeList>[] = [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original ?? "-";
        return <UserBadge user={item} />;
      },
    },
    {
      accessorKey: "Department",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Department?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.Department?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "Designation",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Designation
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.Designation?.name ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.Designation?.name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status ?? "-";
        const statusList = ["Active", "Inactive"];
        const statusColors = ["#ECFDF3", "#FFEAEA"];
        const textColors = ["#137F40", "#DC2626"];

        return (
          <StatusBadge
            status={status}
            statusList={statusList}
            statusColors={statusColors}
            textColors={textColors}
          />
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setSelectedTab("overview"));
                navigate("/employee-list-details", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={view}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/edit-employee`, {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];
  const handleApply = (filterData: EmpFilterData) => {
    dispatch(
      setFilterPersistData({
        department: filterData?.department,
        group: filterData?.business,
        date: filterData?.dateRange,
        mode_of_employment: filterData?.mode_of_employment,
      })
    );
    setBusId(filterData?.business ?? null);
    setDepId(filterData?.department ?? null);
    setDates(filterData?.dateRange);
    setModeOfEmp(filterData?.mode_of_employment ?? null);
    setIsOpen(false);
  };
  const handleDelete = () => {
    setBusId(null);
    setDepId(null);
    setDates([null, null]);
    setModeOfEmp(null);
    dispatch(
      setFilterPersistData({
        department: null,
        group: null,
        date: [null, null],
        mode_of_employment: null,
      })
    );
  };

  return (
    <>
      <div className="mt-6 flex justify-between">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center">
            <PersistedSearch label="Search with Employee Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {(busId && busId !== "") ||
                (depId && depId !== "") ||
                (modeOfEmp && modeOfEmp !== "") ||
                (dates && !(dates[0] === null && dates[1] === null)) ? (
                  <button
                    onClick={handleDelete}
                    className="text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full leading-[29px]">
                <Button onClick={handleDownloadExcel} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto overflow-y-auto mt-4">
        {loading ? (
          <TableSkeleton />
        ) : (
          <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
            <DynamicTable<IEmployeeList>
              onRowClick={(row) => {
                navigate("/employee-list-details", {
                  state: { id: row?.id },
                });
              }}
              data={tableData}
              loading={false}
              columns={columns2}
              enableSorting
              enablePagination
            />
          </div>
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      <EmpFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Employee Filter"
        onApply={handleApply}
        isDate={false}
        isOther
        empFilter
      />
    </>
  );
};

export default EmployeeList;
