import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface UserData {
  id: number;
  firstName: string;
  lastName: string;
  role_id: number;
  email: string;
  phone: string;
  customer_id: number;
  status: string;
  total_users: number;
  createdAt: string;
  updatedAt: string;
  Customer: {
    id: number;
    name: string;
    email: string | null;
    address: string | null;
  };
  Role: {
    id: number;
    role_name: string;
    status: string;
    customer_id: number;
  };
  group_data: {
    id: number;
    name: string;
    parent_id: number | null;
    customer_id: number;
    user_id: number;
    status: string;
  }[];
}

interface Department {
  id: number;
  name: string;
  parent_id: number | null;
  customer_id: number;
  group_id: number;
  spoc_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface groupData {
  id: number;
  name: string;
  parent_id: number | null;
  customer_id: number;
  group_id: number;
  spoc_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  businessUnit_address: string;
  businessUnit_coordinates: [number | null, number | null];
  hr_email: string;
  time_zone_id: number | null;
  businessUnit_shift_start_time: string | null;
  businessUnit_shift_end_time: string | null;
}

interface ParentData {
  id: number;
  name: string;
  parent_id: number | null;
  customer_id: number;
  user_id: number;
  spoc_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface CompanyStructureState {
  companyStructureData: {
    count: number;
    rows: any[];
    // users: UserData[];
    groups: groupData[];
    parents: ParentData[];
    currentPage: number;
    itemsPerPage: number;
    businessUnit_address: string;
    businessUnit_coordinates: [number | null, number | null];
    hr_email: string;
    time_zone_id: number | null;
    businessUnit_shift_start_time: string | null;
    businessUnit_shift_end_time: string | null;
  };

  CompanyStructureGroupListState: {
    loading: boolean;
    error: string | null;
    groups: any[];
  };
  userRoleData: {
    count: number;
    rows: any[];
    currentPage: number;
    itemsPerPage: number;
    isNext: boolean;
  };
  userData: {};
  userGroupData: {
    rows: any[];
    isInitial: boolean;
    currentPage: number;
    itemsPerPage: number;
  };
  departments: Department[];
}

const initialState: CompanyStructureState = {
  companyStructureData: {
    count: 0,
    rows: [],
    // users: [],
    groups: [],
    parents: [],
    currentPage: 1,
    itemsPerPage: 10,
    businessUnit_address: "",
    businessUnit_coordinates: [null, null],
    hr_email: "",
    time_zone_id: null,
    businessUnit_shift_start_time: null,
    businessUnit_shift_end_time: null,
  },

  CompanyStructureGroupListState: {
    loading: false,
    error: null,
    groups: [],
  },
  userRoleData: {
    count: 0,
    rows: [],
    currentPage: 1,
    itemsPerPage: 5,
    isNext: true,
  },
  userData: {},
  userGroupData: {
    rows: [],
    isInitial: true,
    currentPage: 1,
    itemsPerPage: 10,
  },
  departments: [],
};

const dateFormatter = (isoTimestamp: string) => {
  const dateObject = new Date(isoTimestamp);
  const formattedDate = dateObject.toISOString().substring(0, 10);
  return formattedDate;
};

interface GroupData {
  id: number;
  name: string;
  parent_id: number | null;
  businessUnit_address: string;
  businessUnit_coordinates: [number | null, number | null];
  hr_email: string;
  time_zone_id: number | null;
  businessUnit_shift_start_time: string | null;
  businessUnit_shift_end_time: string | null;
  children?: GroupData[];
  customer_id: number;
  level: number;
  type: string;
  total_users: number;
  user_id: number;
  group_id: number;
  spoc_id: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  group_data?: {
    id: number;
    name: string;
    parent_id: number | null;
    customer_id: number;
    group_id: number;
    spoc_id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  };
  parent_group?: {
    id: number;
    name: string;
    parent_id: number | null;
    customer_id: number;
    user_id: number;
    spoc_id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
  };
  user_data?: {
    id: number;
    firstName: string;
    lastName: string;
    profile_image: string | null;
  };
  parent_type?: string;
}

const updateDataStructure = (data: GroupData[]): any => {
  return data.map(
    ({
      id,
      businessUnit_address,
      businessUnit_coordinates,
      name,
      total_users,
      user_id,
      parent_id,
      spoc_id,
      children,
      customer_id,
      createdAt,
      updatedAt,
      type,
      group_id,
      status,
      user_data,
      parent_type,
    }) => ({
      id,
      businessUnit_address,
      businessUnit_coordinates,
      group_name: name,
      user_id,
      parent_id,
      spoc_id,
      total_users,
      children: children ? updateDataStructure(children) : [],
      customer_id,
      createdAt,
      type,
      group_id,
      updatedAt,
      status,
      user_data,
      ...(parent_type && { parent_type }),
    })
  );
};

const companyStructureSlice = createSlice({
  name: "CompanyStructure",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setCompanyStructureData(state, action: PayloadAction<any>) {
      state.companyStructureData.rows = action.payload.rows.map(
        (item: any) => ({
          id: item.id ?? "",
          status: item.status ?? "",
          firstName: item.firstName ?? "",
          lastName: item.lastName ?? "",
          email: item.email ?? "",
          phone: item.phone ?? "",
          total_users: item.total_users ?? "",
          addedDate: dateFormatter(item.createdAt) ?? "",
          updatedDate: dateFormatter(item.updatedAt) ?? "",
          group:
            item.group_data && item.group_data.length !== 0
              ? item.group_data[0].name
              : "",
          role_name: item.Role ? item.Role.role_name : "",
        })
      );

      state.companyStructureData.count = action.payload.count;
    },
    setCompanyStructureDataCurrentPage(state, action: PayloadAction<number>) {
      state.companyStructureData.currentPage = action.payload;
    },
    setCompanyStructureItemsPerPage(state, action: PayloadAction<number>) {
      state.companyStructureData.itemsPerPage = action.payload;
    },
    setUserGroupData(state, action: PayloadAction<GroupData[]>) {
      state.userGroupData.rows = updateDataStructure(action.payload);
      state.userGroupData = { ...state.userGroupData };

      state.companyStructureData.count = action?.payload?.length;
    },
    setUserGroupDataIsInitial(state) {
      state.userGroupData.isInitial = false;
    },
    setFetchNodeViewDetails(
      state,
      action: PayloadAction<{
        result: {
          count: number;
          rows: UserData[];
          group_data: groupData[];
          parent_group: ParentData[];
        };
      }>
    ) {
      state.companyStructureData.groups = action.payload.result.group_data;
      state.companyStructureData.parents = action.payload.result.parent_group;
      state.companyStructureData.rows = action.payload.result.rows;
      state.companyStructureData.count = action.payload.result.count;
    },
    setDepartments(state, action: PayloadAction<Department[]>) {
      // console.log("setDepartments", action.payload);
      state.departments = action.payload;
    },

    // updateGroupData(state, action: PayloadAction<groupData>) {
    //   const updatedGroup = action.payload;
    //   state.companyStructureData.groups = state.companyStructureData.groups.map(group =>
    //     group.id === updatedGroup.id ? updatedGroup : group
    //   );
    // },
    // updateDepartmentData(state, action: PayloadAction<Department>) {
    //   const updatedDepartment = action.payload;
    //   state.departments = state.departments.map(department =>
    //     department.id === updatedDepartment.id ? updatedDepartment : department
    //   );
    // },
    // updateProcessData(state, action: PayloadAction<ProcessData>) {
    //   const updatedProcess = action.payload;
    //   state.processes = state.processes.map(process =>
    //     process.id === updatedProcess.id ? updatedProcess : process
    //   );
    // },
  },
});

export const companyStructureActions = companyStructureSlice.actions;
export default companyStructureSlice;
