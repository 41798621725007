import React, { useEffect, useState } from "react";
import del from "../../../assets/Crud_Icons/delete.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import filter from "../../../assets/filter.svg";
import TablePaginationDemo from "../../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../../assets/download_new.svg";
import { Fetch_Holiday_List } from "../../common/services/Holiday";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
// import { debounce } from "lodash";
// import Search from "../../SearchBar/Search";
// import Filter from "../../Filter/Filter";
// import { Chip } from "@material-ui/core";
import moment from "moment";
import DynamicTable from "../../common/DynamicTable";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import * as XLSX from "xlsx";

import { Close } from "@mui/icons-material";
import AttFilter, { FilterData } from "../../Filter/AttFilter";
import { setFilterPersistData } from "../../../redux/reducers/FilterPersist/FilterPersistSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import PersistedSearch from "../../EmployeeManagement/EmployeeListSearch";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface IHolidayList {
  holiday_date: string;
  holiday_name: string;
  description: string;
  id: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Holiday
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this holiday?
        </h4>
        <div className="flex space-x-4 justify-center pt-1">
          <Button onClick={onDelete} variant={"gradient"}>
            Yes
          </Button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-4 py-2 "
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const Holiday = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter_data = useSelector((state: RootState) => state.filterPersist);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<IHolidayList[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IHolidayList | null>(null);
  // const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [dateRange, setDateRange] = useState(false);
  // const bussness: Business_Unit[] = [];
  // const department: Department_Unit[] = [];
  const sortType = { key: "holiday_date", order: "ASC" };

  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);

  const [dates, setDates] = useState<[Date | null, Date | null] | undefined>(
    filter_data?.date
  );
  const searchTerm = useSelector(
    (state: RootState) => state.filterPersist.search
  );

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const handleDeleteClick = (item: IHolidayList) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleDownload = () => {
    if (tableData?.length > 0) {
      // Define the column headers
      const columns = ["Date", "Holiday Name", "Description"];

      // Prepare worksheet data
      const worksheetData = [
        columns, // Header row
        ...tableData?.map((row) => [
          moment(row.holiday_date).format("DD-MMM-YYYY"), // Format date
          row?.holiday_name,
          row?.description || "-",
        ]),
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Holiday Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "holiday_data.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.log("No data available for download");
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  // const updateSearchTerm = useCallback(
  //   debounce((event: any) => {
  //     setSearchTerm(event.target.value);
  //     setPage(1);
  //   }, 1000), // delay in ms
  //   [setSearchTerm]
  // );

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/holiday/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item?.id !== selectedItem?.id)
          );
        } else {
        }
      } catch (error) {
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleApply = (filterData: FilterData) => {
    dispatch(
      setFilterPersistData({
        date: filterData?.dateRange,
      })
    );
    setDates(filterData?.dateRange);
    setPage(1);
    setIsOpen(false);
  };

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_year" || event.key === "end_year") {
      const start = localStorage.getItem("start_year") || "";
      const end = localStorage.getItem("end_year") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_year") || "";
    const end = localStorage.getItem("end_year") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Holiday_List(
        numOfItems,
        page,
        searchTerm ?? "",
        dates ? dates[0] : null,
        dates ? dates[1] : null,
        sortType
      );
      if (response.status === 200) {
        setTableData(response?.data?.result?.rows ?? []);

        setCount(response?.data?.result?.count ?? 0);
      } else {
      }
    } catch (error) {}
    setLoading(false);
  };

  // const isFirstRender = useRef(true);

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    fetchData();
  }, [page, numOfItems, searchTerm, dates, startSession, endSession]);

  // const [selectedDates, setSelectedDates] = useState<
  //   [Date | null, Date | null]
  // >([null, null]);

  // const handleDateChange = (dates: [Date | null, Date | null]) => {
  //   setSelectedDates(dates);
  //   if (dates[0] && dates[1]) {
  //     // close the calendar when both the dates are selected
  //     setIsCalendarOpen(false);
  //   }
  //   // dispatch(AttendanceActionData.setStartDate(dates[0]));
  //   // dispatch(AttendanceActionData.setEndDate(dates[1]));
  // };

  // const handleCalanderClick = () => {
  //   setIsCalendarOpen(!isCalendarOpen);
  // };

  // const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // const handleFilterApply = () => {
  //   setStartDate(selectedDates[0]);
  //   setEndDate(selectedDates[1]);

  //   if (selectedDates[0] !== null || selectedDates[1] !== null) {
  //     setDateRange(true);
  //   } else {
  //     setDateRange(false);
  //   }
  //   setIsOpen(false);

  //   if (selectedDates[0] === null || selectedDates[1] === null) {
  //     setSelectedDates([null, null]);
  //     setStartDate(null);
  //     setEndDate(null);

  //     // fetchData();
  //   }
  // };

  // const handleDeleteDate = () => {
  //   setDateRange(false);
  //   setSelectedDates([null, null]);
  //   setStartDate(null);
  //   setEndDate(null);
  //   // fetchDSR();
  // };

  // const handleBusinessUnitSelect = (
  //   option: Business_Unit | Department_Unit | undefined
  // ) => {};

  // const handleDepartmentUnitSelect = (
  //   option: Business_Unit | Department_Unit | undefined
  // ) => {};

  const handleDelete = () => {
    setDates([null, null]);
    dispatch(
      setFilterPersistData({
        date: [null, null],
      })
    );
    setPage(1);
  };

  const columns: ColumnDef<IHolidayList>[] = [
    {
      accessorKey: "holiday_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="ml-2 p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.holiday_date
          ? new Date(row.holiday_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.holiday_date
          ? new Date(row.original.holiday_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="ml-2">{name}</div>;
      },
    },
    {
      accessorKey: "holiday_name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Holiday
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      cell: (info) => info.getValue() ?? "-",
    },
    {
      accessorKey: "description",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Description
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.description ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.description ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-2">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={() =>
                navigate("/edit-holiday", {
                  state: { id: row?.original?.id },
                })
              }
            >
              <img
                src={edit}
                alt="Edit"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
            <Button
              type="button"
              className="p-0"
              onClick={() => handleDeleteClick(row?.original)}
            >
              <img
                src={del}
                alt="Delete"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex justify-between mt-6 w-full">
        <div className="h-[40px] w-full">
          <div className="h-full flex justify-between items-center w-full">
            {/* <Search
              onChange={updateSearchTerm}
              label="Search with Holiday Name"
            /> */}
            <PersistedSearch label="Search with Holiday Name" />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex justify-end items-center">
                {dates && !(dates[0] === null && dates[1] === null) ? (
                  <button
                    onClick={handleDelete}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                ) : null}
                {/* {dateRange && (
                  <button
                    onClick={handleDeleteDate}
                    className="ghostButton text-blue-200 font-medium text-sm font-[poppins]"
                  >
                    <Close
                      sx={{
                        fontSize: "15px",
                      }}
                    />
                    Clear All
                  </button>
                  // <Chip
                  //   label={`${moment(selectedDates[0]).format(
                  //     "YYYY-MM-DD"
                  //   )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                  //   onDelete={handleDeleteDate}
                  //   size="small"
                  // />
                )} */}
              </div>
              <Button variant={"border"} onClick={() => setIsOpen(true)}>
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                Filter
              </Button>
              <div className="h-full">
              <Button onClick={handleDownload} variant={"border"}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        <div className="table_main_content w-full max-h-[calc(100vh-20rem)] relative overflow-auto bg-white">
          <DynamicTable<IHolidayList>
            data={tableData}
            loading={false}
            columns={columns}
            enableSorting
            enablePagination
          />
        </div>

        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={page}
          numOfItems={numOfItems}
        />
      )}

      {/* <Filter
        isDate
        isOther={false}
        heading="Holiday Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      /> */}

      <AttFilter
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        heading="Holiday Filter"
        onApply={handleApply}
        isDate={true}
        isOther={false}
      />
    </>
  );
};

export default Holiday;
