import React from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../assets/Ellipse 580.png";
import bussiness_man_icon from "../../assets/avatar.svg";


interface UserBadgeProps {
  user: {
    firstName: string;
    middleName?: string;
    lastName: string;
    profile_image?: string | null;
    employee_id?: string;
    id?: number | string;
  };
  user_id?: string | number;
}

const UserBadge: React.FC<UserBadgeProps> = ({ user, user_id }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    if (user_id) {
      e.stopPropagation();
      navigate("/employee-list-details", {
        state: { id: user_id || user?.id },
      });
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex items-center space-x-2 pl-2 pr-2.5 py-0.5"
    >
      <img
        className="w-9 h-9 rounded-full"
        src={user?.profile_image ? user.profile_image : bussiness_man_icon}
        alt="Profile"
        onError={(e) => (e.currentTarget.src = bussiness_man_icon)}
      />
      <div className="flex flex-col justify-between">
        <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#020617]">
          {user?.firstName} {user?.middleName} {user?.lastName}
        </span>
        <span className="font-[Poppins] font-medium text-[14px] leading-[15px] text-[#64748B]">
          {user?.employee_id || ""}
        </span>
      </div>
    </div>
  );
};

export default UserBadge;
