import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { forwardRef, useEffect, useState } from "react";
import date from "../../../assets/date.svg";
import {
  Backdrop,
  Box,
  Checkbox,
  Fade,
  FormLabel,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import {
  EmployeeUnit,
  Department_Unit as DepartmentUnitType,
} from "../../../redux/actions/Employee/Employee";
import {
  BusinessUnit,
  DepartmentUnit,
} from "../../EmployeeManagement/AddEmployee";
import { Button } from "../../common/Button";
import { X } from "lucide-react";
import { Clear } from "@mui/icons-material";

interface AttFilterProps {
  open: boolean;
  handleClose: () => void;
  heading: string;
  onApply: (data: FilterData) => void;
  isDate: boolean;
  isOther: boolean;
}

export interface FilterData {
  date?: Date | null;
  department?: { id: string; name: string }[];
  business?: { id: string; name: string }[];
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};
const LocationFilter: React.FC<AttFilterProps> = ({
  open,
  handleClose,
  heading,
  onApply,
  isDate,
  isOther,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const filter_data = useSelector((state: RootState) => state.locationSlice);
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [bussness, setBusiness] = useState<BusinessUnit[]>();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  let customer_id = 0;
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [filterData, setFilterData] = useState<FilterData>({
    department: filter_data?.department ?? [],
    business: filter_data?.group ?? [],
  });

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  useEffect(() => {
    setFilterData({
      department: filter_data?.department ?? [],
      business: filter_data?.group ?? [],
    });
  }, [open, handleClose]);

  const handleApply = () => {
    onApply(filterData);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {}
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          DepartmentUnitType(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {}
    };

    fetchDepartment();
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="flex flex-col gap-4 justify-center items-start px-2">
            <p className="font-[Poppins] font-medium text-[20px] leading-4 text-[#262626]">
              {heading}
            </p>
            <div className="flex flex-col gap-4 w-full">
              {/* {isDate && (
                <div>
                  <FormLabel
                    sx={{
                      color: "#09090B",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                    }}
                  >
                    Date
                  </FormLabel>
                  <div className="flex h-10 pr-2 justify-between items-center rounded-md border-[1px] border-solid border-[#CACACA]">
                    <DatePicker
                      selected={filterData.date}
                      onChange={(date) => handleDateChange(date)}
                      onSelect={() => setIsCalendarOpen(false)}
                      className="w-full py-2.5 px-1 text-[12px] h-[38px] bg-white border-white placeholder:text-gray-500 placeholder:font-poppins placeholder:text-[12px] placeholder:font-normal placeholder:leading-[24px]"
                      dateFormat="MMM d, yyyy"
                      placeholderText="Select date"
                      
                      open={isCalendarOpen}
                      onInputClick={handleCalanderClick}
                      onClickOutside={() => {
                        handleCalanderClick();
                      }}
                      popperContainer={({ children }) => (
                        <div style={{ zIndex: 9 }}>{children}</div>
                      )}
                    />
                    <img
                      src={date}
                      alt="date icon"
                      onClick={handleCalanderClick}
                      className="flex items-center justify-between relative cursor-pointer"
                    />
                  </div>
                </div>
              )} */}

              {isOther && (
                <>
                  <div>
                    <FormLabel
                      sx={{
                        color: "#09090B",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Business Unit
                    </FormLabel>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "12px",
                        },
                      }}
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                          const selectedArray = Array.isArray(selected)
                            ? selected
                            : [selected];
                          const selectedItems = bussness?.filter((b) =>
                            selectedArray.includes(b?.id?.toString() || "")
                          );
                          return selectedItems?.length
                            ? selectedItems.map((b) => b?.name).join(", ")
                            : "Select";
                        },
                      }}
                      value={filterData.business?.map((b) => b?.id) || []}
                      onChange={(e) => {
                        const selectedIds = Array.isArray(e.target.value)
                          ? e.target.value
                          : [e.target.value];

                        const selectedBusiness = bussness
                          ?.filter((b) =>
                            selectedIds.includes(b?.id?.toString() || "")
                          )
                          ?.map((b) => ({
                            id: b?.id?.toString(),
                            name: b?.name,
                          }));

                        setFilterData({
                          ...filterData,
                          business: selectedBusiness,
                        });
                      }}
                      InputProps={{
                        endAdornment: filterData.business?.length ? (
                          <Clear
                            onClick={() =>
                              setFilterData({ ...filterData, business: [] })
                            }
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              fontSize: "16px",
                            }}
                          />
                        ) : null,
                      }}
                    >
                      {bussness?.map((option) => (
                        <MenuItem
                          key={option?.id?.toString()}
                          value={option?.id?.toString()}
                        >
                          <Checkbox
                            checked={filterData.business?.some(
                              (b) => b?.id === option?.id?.toString()
                            )}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              let updatedBusiness = [
                                ...(filterData.business || []),
                              ];

                              if (checked) {
                                updatedBusiness.push({
                                  id: option?.id?.toString(),
                                  name: option?.name,
                                });
                              } else {
                                updatedBusiness = updatedBusiness.filter(
                                  (b) => b?.id !== option?.id?.toString()
                                );
                              }

                              setFilterData({
                                ...filterData,
                                business: updatedBusiness,
                              });
                            }}
                          />
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  <div>
                    <FormLabel
                      sx={{
                        color: "#09090B",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Department
                    </FormLabel>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "12px",
                        },
                      }}
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                          const selectedArray = Array.isArray(selected)
                            ? selected
                            : [selected];
                          const selectedItems = department?.filter((d) =>
                            selectedArray.includes(d?.id?.toString() || "")
                          );
                          return selectedItems?.length
                            ? selectedItems?.map((d) => d?.name).join(", ")
                            : "Select";
                        },
                      }}
                      value={filterData.department?.map((d) => d?.id) || []}
                      onChange={(e) => {
                        const selectedIds = Array.isArray(e.target.value)
                          ? e.target.value
                          : [e.target.value];

                        const selectedDepartment = department
                          ?.filter((d) =>
                            selectedIds.includes(d?.id?.toString() || "")
                          )
                          ?.map((d) => ({
                            id: d?.id?.toString(),
                            name: d?.name,
                          }));

                        setFilterData({
                          ...filterData,
                          department: selectedDepartment,
                        });
                      }}
                      InputProps={{
                        endAdornment: filterData.department?.length ? (
                          <Clear
                            fontSize="small"
                            onClick={() =>
                              setFilterData({ ...filterData, department: [] })
                            }
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              fontSize: "16px",
                            }}
                          />
                        ) : null,
                      }}
                    >
                      {department?.map((option) => (
                        <MenuItem
                          key={option?.id?.toString()}
                          value={option?.id?.toString()}
                        >
                          <Checkbox
                            checked={filterData.department?.some(
                              (d) => d?.id === option?.id?.toString()
                            )}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              let updatedDepartment = [
                                ...(filterData.department || []),
                              ];

                              if (checked) {
                                updatedDepartment.push({
                                  id: option?.id?.toString(),
                                  name: option?.name,
                                });
                              } else {
                                updatedDepartment = updatedDepartment.filter(
                                  (d) => d?.id !== option?.id?.toString()
                                );
                              }

                              setFilterData({
                                ...filterData,
                                department: updatedDepartment,
                              });
                            }}
                          />
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-between gap-4 w-full pt-1">
              <button
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
                onClick={handleClose}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <Button onClick={handleApply} variant={"gradient"}>
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Apply
                </div>
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LocationFilter;
