import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import maskPlus from "../../image/Mask group.svg";

import "./assets.css";
import AssignedAsset from "./AssignedAsset";
import AssetCategory from "./AssetCategory";
import AddAssetCategoryModal from "./AddAssetCategory";
import AssetRequest from "./AssetRequest";
import { Add_Asset_Category } from "../common/services/Asset";
import toast from "react-hot-toast";
import SuccessModal from "../common/SuccessModal";
import CustomTabs from "../common/CustomTabs";
import { Button } from "../common/Button";

interface AddAssetCategoryData {
  category: string;
  quantity: number;
}

function AssetManagement() {
  const history = useNavigate();
  const [value, setValue] = useState<string>("1");
  const [addOpen, setAddOpen] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  const [addAssetCategoryData, setAddAssetCategoryData] =
    useState<AddAssetCategoryData>({
      category: "",
      quantity: 0,
    });

  useEffect(() => {
    if (location.pathname === "/assigned-asset") {
      setValue("1");
    } else if (location.pathname === "/asset-category") {
      setValue("2");
    } else if (location.pathname === "/asset-request") {
      setValue("3");
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      history("/assigned-asset");
    } else if (newValue === "2") {
      history("/asset-category");
    } else if (newValue === "3") {
      history("/asset-request");
    }
  };

  const getIndicatorClass = () => {
    switch (localStorage.getItem("tabValue") || "1") {
      case "1":
        return "indicator-assigned-asset";
      case "2":
        return "indicator-asset-category";
      case "3":
        return "indicator-asset-request";
      default:
        return "indicator-default";
    }
  };

  const handleSave = async (totalAsset: string, assetCategory: string) => {
    try {
      const response = await Add_Asset_Category(
        assetCategory,
        Number(totalAsset)
      );
      if (response.status === 200) {
        setTimeout(() => {
          setOpen(true);
          setAddOpen(false);
        }, 300);
        setTimeout(() => {
          setOpen(false);
          history("/asset-category", {
            state: { id: 1 },
          });
          handleClose();
        }, 1000);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const tabs = [
    { label: "Assigned Asset", value: "1" },
    { label: "Asset Category", value: "2" },
    { label: "Asset Request", value: "3" },
  ];

  return (
    <>
      <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box>
                  <CustomTabs tabs={tabs} onChange={handleChange} />
                </Box>

                {value === "1" ? (
                  <div>
                    <Button
                      onClick={() => history("/add-asset")}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Assigned Asset
                    </Button>
                  </div>
                ) : value === "2" ? (
                  <div>
                    <Button
                      onClick={() => setAddOpen(true)}
                      variant={"gradient"}
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Asset Category
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <TabPanel className="tabpanel" value="1">
                <AssignedAsset />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <AssetCategory />
              </TabPanel>
              <TabPanel className="tabpanel" value="3">
                <AssetRequest />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {addOpen ? (
          <AddAssetCategoryModal
            onCancel={() => setAddOpen(false)}
            onSave={handleSave}
          />
        ) : (
          <></>
        )}
        <SuccessModal
          isOpen={open}
          onclose={handleClose}
          label="Asset Category Added Succesfully!"
        />
      </div>
    </>
  );
}

export default AssetManagement;
