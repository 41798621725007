import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileImage,
  faFileWord,
  faFileArchive,
  faFileAlt,
  faFileExcel,
  faFilePowerpoint,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowBigDown, Eye } from "lucide-react";
import { Button } from "../Button";

interface DocumentCardProps {
  fileUrl: string;
  onView: () => void;
  onDownload: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  fileUrl,
  onView,
  onDownload,
}) => {
  const fileName = fileUrl.split("/").pop() || "Unknown File";
  const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";
  const fileSize = `${(Math.random() * 2 + 1).toFixed(2)} MB`;

  const getFileIcon = (extension: string) => {
    const iconMap: Record<string, JSX.Element> = {
      pdf: (
        <FontAwesomeIcon
          icon={faFilePdf}
          className="text-red-500 h-full w-full"
        />
      ),
      jpg: (
        <FontAwesomeIcon
          icon={faFileImage}
          className="text-blue-500 h-full w-full"
        />
      ),
      jpeg: (
        <FontAwesomeIcon
          icon={faFileImage}
          className="text-blue-500 h-full w-full"
        />
      ),
      png: (
        <FontAwesomeIcon
          icon={faFileImage}
          className="text-blue-500 h-full w-full"
        />
      ),
      doc: (
        <FontAwesomeIcon
          icon={faFileWord}
          className="text-blue-700 h-full w-full"
        />
      ),
      docx: (
        <FontAwesomeIcon
          icon={faFileWord}
          className="text-blue-700 h-full w-full"
        />
      ),
      zip: (
        <FontAwesomeIcon
          icon={faFileArchive}
          className="text-yellow-500 h-full w-full"
        />
      ),
      rar: (
        <FontAwesomeIcon
          icon={faFileArchive}
          className="text-yellow-500 h-full w-full"
        />
      ),
      txt: (
        <FontAwesomeIcon
          icon={faFileAlt}
          className="text-gray-500 h-full w-full"
        />
      ),
      xls: (
        <FontAwesomeIcon
          icon={faFileExcel}
          className="text-green-600 h-full w-full"
        />
      ),
      xlsx: (
        <FontAwesomeIcon
          icon={faFileExcel}
          className="text-green-600 h-full w-full"
        />
      ),
      csv: (
        <FontAwesomeIcon
          icon={faFileExcel}
          className="text-green-600 h-full w-full"
        />
      ),
      ppt: (
        <FontAwesomeIcon icon={faFilePowerpoint} className="text-orange-500" />
      ),
      pptx: (
        <FontAwesomeIcon icon={faFilePowerpoint} className="text-orange-500" />
      ),
      default: <FontAwesomeIcon icon={faFile} className="text-gray-400" />,
    };

    return iconMap[extension] || iconMap["default"];
  };

  return (
    <div className="flex flex-wrap md:flex-nowrap items-center justify-between border border-[#EDEDED] p-2 rounded-md w-full bg-white gap-2">
      <div className="flex items-center gap-2.5 overflow-hidden w-full">
        <div className="h-[32px] w-[32px] rounded-full">
          {getFileIcon(fileExtension)}
        </div>

        <div className="flex flex-col justify-center overflow-hidden w-[60%]">
          <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22] max-w-[120px] sm:max-w-[150px] md:max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
            {fileName}
          </span>
          <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
            {fileSize} • {fileExtension.toUpperCase()} File
          </span>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Button
          onClick={onDownload}
          className="bg-custom-gray border border-custom-lightGray text-grey300 font-[Inter] text-xs font-semibold px-2"
        >
          <ArrowBigDown size={14} />
        </Button>

        <Button
          onClick={onView}
          className="bg-custom-gray border border-custom-lightGray text-grey300 font-[Inter] text-xs font-semibold px-2"
        >
          View
        </Button>
      </div>
    </div>
  );
};

export default DocumentCard;
