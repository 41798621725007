import { io } from "socket.io-client";

const SOCKET_BASE_URL = "https://devsocket.go-emp.com";

const socket = io(SOCKET_BASE_URL, {
  transports: ["websocket"],
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  timeout: 10000,
});

// Add debug listeners
socket.on("connect", () => {
  console.log("Socket connected successfully");
  console.log("Socket ID:", socket.id);
});

socket.on("connect_error", (error) => {
  console.error("Socket connection error:", error);
});

socket.on("disconnect", (reason) => {
  console.log("Socket disconnected:", reason);
});

// Debug middleware for all emitted events
// const originalEmit = socket.emit;
// socket.emit = function (...args) {
//   console.log("Emitting event:", args[0], "with data:", args[1]);
//   return originalEmit.apply(this, args);
// };

export default socket;
