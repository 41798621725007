import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import moment from "moment";

import right from "../../../image/chevron-right.png";
import profile from "../../../assets/Ellipse 580.png";
import check from "../../../image/checkmark-circle-02.svg";

import { Leave_Unit } from "../../../redux/actions/Leave/Leave";

import {
  approveRejectApi,
  Fetch_Edit_Leave_List,
} from "../../common/services/Leave";

// !MODAL STYLE
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

// !INTERFACES
interface Approvers {
  approval_id: number;
  approval_status: string;
  role: string;
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
}

interface FormValues {
  // firstName: string;
  // lastName: string;
  // profile_image: string;
  id: number;
  user_id: string;
  // selected_day: string;
  // no_of_days: number;
  // leave_type: string;
  from_date: string;
  to_date: string;
  reason: string;
  leave_session: string;
  LeaveType: Leave_Type;
  User: User;
}

interface Leave_Type {
  leave_type_name: string;
}

interface User {
  firstName: string;
  middleName: string;
  lastName: string;
  profile_image: string | null;
  employee_id: string;
  reporting_manager: number | null;
  team_manager: number | null;
}

const ViewLeaveDetails = () => {
  // !VARIABLES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = useSelector((state: any) => state.leave.currentPage);
  let customer_id = 0;
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState<string>("Pending");
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [leaveData, setLeaveData] = useState<FormValues>({
    id: 0,
    user_id: "",
    // no_of_days: 0,
    from_date: "",
    to_date: "",
    reason: "",
    leave_session: "",
    LeaveType: {
      leave_type_name: "",
    },
    User: {
      firstName: "",
      middleName: "",
      lastName: "",
      profile_image: null,
      employee_id: "",
      reporting_manager: null,
      team_manager: null,
    },
  });
  // const [formValues, setFormValues] = useState<FormValues>({
  //   firstName: "",
  //   lastName: "",
  //   employee_id: "",
  //   profile_image: "",
  //   selected_day: "",
  //   leave_type: "",
  //   start_date: "",
  //   end_date: "",
  //   reason: "",
  // });

  // !REDUX DATA
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const leave = useSelector((state: any) => state.leave.LeaveData);
  const userId = loginData?.userId;
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  // useEffect(() => {
  //   if (leave) {
  //     setFormValues({
  //       firstName: leave?.User?.firstName,
  //       lastName: leave?.User?.lastName,
  //       profile_image: leave?.User?.profile_image,
  //       employee_id: leave?.User?.employee_id,
  //       selected_day: leave?.leave_session,
  //       leave_type: leave?.LeaveType?.leave_type_name,
  //       start_date: leave?.from_date,
  //       end_date: leave?.to_date,
  //       reason: leave?.reason,
  //     });
  //   }
  // }, [leave]);

  // !LOCATION
  const id = location.state?.id;

  // !FUNCTIONS
  const handleApprove = () => {
    approveData();
  };
  const handleReject = () => {
    rejectData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/leave-holiday-management");
  };

  // !API CALLS
  useEffect(() => {
    Fetch_Edit_Leave_List(id)
      .then((data) => {
        if (data?.data?.result) {
          setLeaveData(data?.data?.result);
          // setLeaveType(data?.result.LeaveType)
          // setUserData(data?.result?.User)
          setApproverData(data?.data?.result?.LeavesRequestApprovals);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  // useEffect(() => {
  //   dispatch<any>(Leave_Unit(customer_id, navigate, id));
  // }, []);

  const approveData = async () => {
    const status = "Approved";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response.data?.message);
      setTimeout(() => {
        navigate("/leave-holiday-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const rejectData = async () => {
    const status = "Rejected";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response.data?.message);
      setTimeout(() => {
        navigate("/leave-holiday-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  // !APPROVERS
  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.approval_id === userId) {
        setShowButtons(true);
        setStatus(approver.approval_status);
      }
    });
  }, [approverData]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="SUCCESS" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee Leave details Approved successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-8 px-[18.5px]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() =>
                // navigate("/leave-holiday-management", {
                //   state: { page: currentPage },
                // })
                navigate(-1)
              }
            >
              {/* <button onClick={() => navigate("/leave-holiday-management", {state: {page: currentPage}})}> */}
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Leave Details
            </span>
          </div>

          {showButtons ? (
            <div className="flex flex-start gap-5">
              {status !== "Rejected" ? (
                <button
                  onClick={handleReject}
                  className="flex w-[148px] bg-[#B3261E] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12 hover:bg-[#96150e]"
                >
                  <div className="text-[#fff] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Reject
                  </div>
                </button>
              ) : (
                <></>
              )}
              {status !== "Approved" ? (
                <button
                  type="submit"
                  form="form"
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] bg-[#359349] justify-center items-center flex-[1,1,0] self-stretch rounded-lg text-white h-12 hover:bg-[#1f8735]"
                  onClick={handleApprove}
                >
                  <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Approve
                  </div>
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-semibold font-[Poppins] mb-4">Details</h2>
          <div
            className=" bg-gradient-to-r p-4 rounded-md"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="grid grid-cols-3 p-1 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Employee Name & ID
                </strong>
                <div className="flex flex-row gap-[7px] items-center">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={leaveData?.User?.profile_image || profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {leaveData?.User?.firstName || "--"}{" "}
                      {leaveData?.User?.lastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {leaveData?.User?.employee_id || "--"}
                    </span>
                  </div>
                </div>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Leave Period
                </strong>
                <br />{" "}
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {leaveData?.leave_session || "--"}
                </span>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Leave Type
                </strong>
                <br />
                <span className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {leaveData?.LeaveType?.leave_type_name || "--"}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Start Date
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {moment(leaveData?.from_date).format("DD MMM YYYY")}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  End Date
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {moment(leaveData?.to_date).format("DD MMM YYYY")}
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-1 p-2 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Reason
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                  {" "}
                  {leaveData?.reason}
                </strong>
              </p>
            </div>
            <div className="flex p-2 border-b-[2px] border-gray-200 w-full">
              <p className="w-full">
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Approval Status
                </strong>
                <br />{" "}
                <div className="grid grid-cols-3 gap-4">
                  {/* {approverData?.map((approver, index) => 
                    approver.role === "Team Manager" && (
                      <div
                        key={index}
                        className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px]"
                      >
                        Team Manager -{" "}
                        <span
                          className={`p-1 rounded-sm ${
                            approver.approval_status === "Approved"
                              ? "bg-[#D4F8C7]"
                              : approver.approval_status === "Pending"
                              ? "bg-[#FFC0767A]"
                              : approver.approval_status === "Rejected"
                              ? "bg-[#F8716A7A]"
                              : ""
                          }`}
                        >
                          {approver.approval_status}
                        </span>
                      </div>
                    )
                  )}

                  {approverData?.map((approver, index) => 
                    approver.role === "Reporting Manager" && (
                      <div
                        key={index}
                        className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px]"
                      >
                        Reporting Manager -{" "}
                        <span
                          className={`p-1 rounded-sm ${
                            approver.approval_status === "Approved"
                              ? "bg-[#D4F8C7]"
                              : approver.approval_status === "Pending"
                              ? "bg-[#FFC0767A]"
                              : approver.approval_status === "Rejected"
                              ? "bg-[#F8716A7A]"
                              : ""
                          }`}
                        >
                          {approver.approval_status}
                        </span>
                      </div>
                    )
                  )}

                  {approverData?.map((approver, index) => 
                    (approver.role === "HR" || !approver.role) && (
                      <div
                        key={index}
                        className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px]"
                      >
                        HR -{" "}
                        <span
                          className={`p-1 rounded-sm ${
                            approver.approval_status === "Approved"
                              ? "bg-[#D4F8C7]"
                              : approver.approval_status === "Pending"
                              ? "bg-[#FFC0767A]"
                              : approver.approval_status === "Rejected"
                              ? "bg-[#F8716A7A]"
                              : ""
                          }`}
                        >
                          {approver.approval_status}
                        </span>
                      </div>
                    )
                  )} */}

                  {/* {approverData?.map((approver) => (
                    <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                      {approver.role || "HR"}-{" "}
                      <span
                        className={`p-1 rounded-sm ${
                          approver.approval_status === "Approved"
                            ? "bg-[#D4F8C7]"
                            : approver.approval_status === "Pending"
                            ? "bg-[#FFC0767A]"
                            : approver.approval_status === "Rejected"
                            ? "bg-[#F8716A7A]"
                            : ""
                        }`}
                      >
                        {approver.approval_status}
                      </span>
                      <br /> ({approver?.User?.firstName}{" "}
                      {approver?.User?.lastName})
                    </strong>
                  ))} */}

                  {approverData?.map((approver, index) => {
                    const statusColor =
                      approver?.approval_status === "Approved"
                        ? "bg-[#D4F8C7]"
                        : approver?.approval_status === "Pending"
                        ? "bg-[#FFC0767A]"
                        : approver?.approval_status === "Rejected"
                        ? "bg-[#F8716A7A]"
                        : "";

                    const displayedName =
                      approver?.role === "HR"
                        ? `${approver?.role}`
                        : approver?.User
                        ? `${approver?.User?.firstName} ${approver?.User?.middleName} ${approver?.User?.lastName}`
                        : "";

                    return (
                      <strong
                        key={index}
                        className="text-[#1D1A22] font-['Poppins'] font-medium text-[16px]"
                      >
                        {displayedName}-{" "}
                        <span className={`p-1 rounded-sm ${statusColor}`}>
                          {approver?.approval_status}
                        </span>{" "}
                        {approver?.role && <br />}
                        {approver?.role && approver?.role !== "HR" && (
                          <span className="text-[rgba(29,26,34,0.7)] font-['Poppins'] font-medium text-[15px]">
                            ({approver?.role})
                          </span>
                        )}
                      </strong>
                    );
                  })}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLeaveDetails;
