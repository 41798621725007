import { TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";

interface TabItem {
  label: string;
  value: string;
  className?: string;
}

interface CustomTabsProps {
  tabs: TabItem[];
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, onChange }) => {
  return (
    <TabList
      onChange={onChange}
      aria-label="custom tabs"
      sx={{
        minHeight: "42px !important",
        flexWrap: "wrap",
        "& .MuiTab-root": {
          minHeight: "32px !important",
          height: "32px !important",
          borderRadius: "4px",
          color: "#6B7280",
          fontFamily: "poppins",
          fontWeight: 500,
          textTransform: "capitalize",
          padding: "6px 12px",
        },
        "& .MuiTabs-flexContainer": {
          height: { xs: "auto", lg: "42px !important" },
          display: "flex",
          flexWrap: "wrap",
          background: "#F3F4F6",
          alignItems: "center",
          borderRadius: "6px",
          padding: "5px",
        },
        "& .Mui-selected": {
          color: "#020617 !important",
          borderRadius: "4px",
          fontFamily: "poppins",
          background: "#fff",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          // transition: "all 0.2s ease-in-out",
        },
        "& .MuiTabs-indicator": {
          height: "0px !important",
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          className={tab.className || ""}
        />
      ))}
    </TabList>
  );
};

export default CustomTabs;
