import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Business_Unit,
  Department_Unit,
} from "../../../components/common/interfaces/CompanyManagement";
import { DepartmentUnit } from "../../../components/AttendenceDetails/AttendenceDetails";

export interface Filters {
  search?: string;
  search2?: string;
  department?: string | null;
  group?: string | null;
  date?: [Date | null, Date | null];
  status?: string;
  page?: number;
  size?: number;
  mode_of_employment?: string | null;
  project?: string | null;
  billing?: string | null;
}

const initialState: Filters = {
  search: "",
  department: null,
  group: null,
  status: "",
  date: [null, null],
  page: 1,
  size: 10,
  search2: "",
  project: "",
  billing: "",
  mode_of_employment: null
};

const FilterPersistSlice = createSlice({
  name: "filterPersist",
  initialState,
  reducers: {
    setFilterPersistData(state, action: PayloadAction<Filters>) {
      return { ...state, ...action.payload };
    },
    clearAllFilters(state) {
      return initialState;
    },
  },
});

export const { setFilterPersistData, clearAllFilters } =
  FilterPersistSlice.actions;
export default FilterPersistSlice;
