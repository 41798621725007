import React from "react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "../common/Tooltip/Tooltip";
const FormattedValue: React.FC<{ value: number; currency?: string, className?:string }> = ({
  value,
  currency,
  className
}) => {
  const formatCompact = (val: number): string => {
    const absValue = Math.abs(val);

    if (absValue >= 1e12) return `${(val / 1e12).toFixed(1)}T`;
    if (absValue >= 1e9) return `${(val / 1e9).toFixed(1)}B`;
    if (absValue >= 1e6) return `${(val / 1e6).toFixed(1)}M`;
    if (absValue >= 1e3) return `${(val / 1e3).toFixed(1)}K`;
    return val.toFixed(2);
  };

  const formatWithCommas = (val: number, currency?: string): string => {
    const currencyLocaleMap: Record<string, string> = {
      INR: "en-IN",
      USD: "en-US",
      EUR: "de-DE",
      GBP: "en-GB",
      AED: "en-AE",
      JPY: "ja-JP",
      CNY: "zh-CN",
      AUD: "en-AU",
      CAD: "en-CA",
    };
  
    const locale = currencyLocaleMap[currency ?? "USD"] || "en-US";
  
    return val.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger  asChild>
          <span className={`cursor-pointer ${className}`}>
            {currency} {formatCompact(value)}
          </span>
        </TooltipTrigger>
        <TooltipContent side={'bottom'} className="bg-white border border-gray-300 shadow-lg p-2 rounded-md text-lg">
            
          {currency} {formatWithCommas(value, currency)}
            
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default FormattedValue;
