import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProjectState {
  taskPhase: string | null;
  module: number | null;
}

const initialState: ProjectState = {
  taskPhase: null,
  module: null,
};

const TaskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTaskPhase(state, action: PayloadAction<string | null>) {
      state.taskPhase = action.payload;
    },
    setModule(state, action: PayloadAction<number | null>) {
      state.module = action.payload;
    },
    resetProjectState(state) {
      state.taskPhase = null;
    },
    resetModule(state) {
      state.module = null;
    },
  },
});

export const { setTaskPhase, resetProjectState, setModule, resetModule } =
  TaskSlice.actions;
export default TaskSlice;
