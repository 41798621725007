import React, { useState } from "react";

import { Box, TextField } from "@mui/material";

import cross from "../../../assets/cross_new.svg";
import { Button } from "../../common/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  zIndex: "1001",
};

const overlayStyle = {
  position: "fixed" as "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
};

const AddQuestionModal: React.FC<{
  onSave: (question: string, description: string) => void;
  onCancel: () => void;
}> = ({ onSave, onCancel }) => {
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = () => {
    onSave(question, description);
  };
  return (
    <>
      <div style={overlayStyle} onClick={onCancel}></div>
      <Box sx={style}>
        <div className="p-2.5 w-full flex items-center justify-end">
          <img
            src={cross}
            alt="close"
            className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
            onClick={onCancel}
          />
        </div>
        <div className="px-8 pt-3">
          <span className="font-['Poppins'] font-medium text-[20px] leading-4 text-[#262626]">
            Add Question Details
          </span>
          <div className="pt-8 w-full flex flex-col gap-12">
            <div className="flex flex-col gap-7">
              <TextField
                required
                id="outlined-required"
                label="Question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                name="question"
                className="w-full"
              />
              <TextField
                required
                id="outlined-required"
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="description"
                className="w-full"
              />
            </div>
            <div className="w-full flex flex-row justify-end gap-5 pb-12">
              <button
                className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
                onClick={onCancel}
              >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
                >
                  Cancel
                </div>
              </button>
              <Button
                type="submit"
                form="form"
                onClick={handleSave}
                variant={"gradient"}
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Save
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default AddQuestionModal;
