import React, { useEffect, useState, useCallback } from "react";
import { Box, Tab } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { ArrowUpDown } from "lucide-react";
import CustomTabs from "../../common/CustomTabs";
import { useDispatch } from "react-redux";
import { setFilterPersistData } from "../../../redux/reducers/FilterPersist/FilterPersistSlice";
import maskPlus from "../../image/Mask group.svg";
import DoughnutChart, {
  centerTextPlugin,
} from "../../common/Charts/DoughnutChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UpcomingLeave from "./UpcomingLeave";
import UpcomingWFH from "./UpcomingWFH";
import UpcomingHoliday from "./UpcomingHoliday";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import filter from "../../../assets/filter.svg";
import { MenuItem, TextField } from "@mui/material";
import LineChart from "../../common/Charts/LineChart";
import AttFilter, { FilterData } from "../../Filter/AttFilter";
import {
  Fetch_Leave_Graph,
  Fetch_Leave_Overview,
  Fetch_WFH_Graph,
} from "../../common/services/Leave";
import dayjs, { Dayjs } from "dayjs";
interface ILeaveData {
  totalLeaveRequests: number;
  totalApproved: number;
  totalRejected: number;
  totalPending: number;
}

interface Leave_Overview {
  Casual_Leaves: LeaveData;
  Sick_Leaves: LeaveData;
  EL: LeaveData;
  LWP: LeaveData;
  Optional_Holiday: LeaveData;
}
interface LeaveData {
  pending: number;
  approved: number;
  rejected: number;
  total: number;
}

interface ILeaveGraph {
  month: string;
  Pending: string;
  Approved: string;
  Rejected: string;
}

interface LeaveOverviewProps {
  depId: string | null;
  busId: string | null;
  dates: [Date | null, Date | null] | undefined;
}

const LeaveOverview: React.FC<LeaveOverviewProps> = ({
  depId,
  busId,
  dates
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countList, setCountList] = useState<ILeaveData>();
  let customer_id = 0;
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const [selected, setSelected] = useState("1");
  // const [selectedGraph, setSelectedGraph] = useState("1");
  const [leaveGraph, setLeaveGraph] = useState<Leave_Overview>();

  const [isOpen, setIsOpen] = useState(false);
  const filter_data = useSelector((state: RootState) => state.filterPersist);


  const [selectedTab, setSelectedTab] = useState("leave"); // 'leave' or 'wfh'

  const [graphData, setGraphData] = useState({
    labels: [] as string[],
    data: [] as ILeaveGraph[],
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(
      setFilterPersistData({
        department: "",
        group: "",
        date: [null, null],
        search: "",
      })
    );

    setSelected(newValue);
  };

  const [selectedWFHStatus, setSelectedWFHStatus] = useState<
    string | undefined
  >(filter_data?.status);


  const handleWFHStatusesChange = async (value: string) => {
    setSelectedWFHStatus(value);
    dispatch(setFilterPersistData({ status: value }));
  };

  const fetchLeaveGraph = useCallback(async () => {
    try {
      const response = await Fetch_Leave_Overview({
        startDate: filter_data?.date?.[0] ? filter_data?.date?.[0] : null,
        endDate: filter_data?.date?.[1] ? filter_data?.date?.[1] : null,
        group_id: filter_data?.group ? Number(filter_data?.group) : null,
        department_id: filter_data?.department
          ? Number(filter_data?.department)
          : null,
      });
      if (response?.status === 200) {
        setLeaveGraph(response?.data?.result ? response?.data?.result : []);
        setCountList(
          response?.data?.result?.total ? response?.data?.result?.total : ""
        );
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [filter_data]);

  const fetchData = useCallback(async () => {
    try {
      const response =
        selectedTab === "leave"
          ? await Fetch_Leave_Graph({
              group_id: Number(busId),
              department_id: Number(depId),
            })
          : await Fetch_WFH_Graph({
              group_id: Number(busId),
              department_id: Number(depId),
            });

      if (response.status === 200) {
        const result = response?.data?.result;

        setGraphData({
          labels: result?.map((i: ILeaveGraph) => i?.month) || [],
          data: result ?? [],
        });
      } else {
        console.error(
          `Error fetching ${selectedTab} data:`,
          response?.status,
          response?.statusText
        );
      }
    } catch (error) {
      console.error(`Error fetching ${selectedTab} data:`, error);
    }
  }, [selectedTab, busId, depId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchLeaveGraph();
  }, [fetchLeaveGraph]);

  const leaveReportStats = [
    {
      label: "Casual Leave",
      color: "#8AB3FF",
      subParts: [
        { label: "Pending", value: leaveGraph?.Casual_Leaves?.pending ?? 0 },
        { label: "Approved", value: leaveGraph?.Casual_Leaves?.approved ?? 0 },
        { label: "Rejected", value: leaveGraph?.Casual_Leaves?.rejected ?? 0 },
      ],
    },
    {
      label: "Sick Leave",
      color: "#CDEBC5",
      subParts: [
        { label: "Pending", value: leaveGraph?.Sick_Leaves?.pending ?? 0 },
        { label: "Approved", value: leaveGraph?.Sick_Leaves?.approved ?? 0 },
        { label: "Rejected", value: leaveGraph?.Sick_Leaves?.rejected ?? 0 },
      ],
    },
    {
      label: "Earned Leave",
      color: "#EDD9F2",
      subParts: [
        { label: "Pending", value: leaveGraph?.EL?.pending ?? 0 },
        { label: "Approved", value: leaveGraph?.EL?.approved ?? 0 },
        { label: "Rejected", value: leaveGraph?.EL?.rejected ?? 0 },
      ],
    },
    {
      label: "LWP",
      color: "#FFD59E",
      subParts: [
        { label: "Pending", value: leaveGraph?.LWP?.pending ?? 0 },
        { label: "Approved", value: leaveGraph?.LWP?.approved ?? 0 },
        { label: "Rejected", value: leaveGraph?.LWP?.rejected ?? 0 },
      ],
    },
    {
      label: "Optional Leave",
      color: "#A1C4FD",
      subParts: [
        { label: "Pending", value: leaveGraph?.Optional_Holiday?.pending ?? 0 },
        {
          label: "Approved",
          value: leaveGraph?.Optional_Holiday?.approved ?? 0,
        },
        {
          label: "Rejected",
          value: leaveGraph?.Optional_Holiday?.rejected ?? 0,
        },
      ],
    },
  ];

  const aggregatedLeaveDoughnutData = leaveReportStats.map((item) => ({
    label: item.label,
    value: item.subParts.reduce((acc, sub) => acc + (sub.value || 0), 0) || 0,
    color: item.color,
    subParts: item.subParts,
  }));

  const optionsDoughtNut = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "65%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#1F2937",
          boxWidth: 12,
          boxHeight: 12,
          borderRadius: 4,
        },
      },
      tooltip: {
        backgroundColor: "#FFFFFF", // Set tooltip background color to white
        titleColor: "#1F2937", // Darker text for better readability
        bodyColor: "#4B5563", // Medium gray for details
        borderColor: "#E5E7EB", // Light gray border for better visibility
        borderWidth: 1,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem: any) {
            const leaveItem = leaveReportStats[tooltipItem.dataIndex];
            if (!leaveItem) return "";

            const { label, subParts } = leaveItem;

            const approved =
              subParts.find((p) => p.label === "Approved")?.value ?? 0;
            const pending =
              subParts.find((p) => p.label === "Pending")?.value ?? 0;
            const rejected =
              subParts.find((p) => p.label === "Rejected")?.value ?? 0;

            return [
              `${label} (${approved + pending + rejected})`,
              `Approved: ${approved}`,
              `Pending: ${pending}`,
              `Rejected: ${rejected}`,
            ];
          },
          labelColor: function (tooltipItem: any) {
            const backgroundColor =
              aggregatedLeaveDoughnutData[tooltipItem.dataIndex]?.color ??
              "#000";
            return {
              borderColor: backgroundColor,
              backgroundColor: backgroundColor,
            };
          },
        },
      },
    },
  };

  const monthlyLeaveGraphData = [
    {
      label: "Approved",
      data: graphData?.data?.map((item) => Number(item.Approved)) || [],
      color: "#CDEBC5",
    },
    {
      label: "Rejected",
      data: graphData?.data?.map((item) => Number(item.Rejected)) || [],
      color: "#F4C1C1",
    },
    {
      label: "Pending",
      data: graphData?.data?.map((item) => Number(item.Pending)) || [],
      color: "#FBD796",
    },
  ];

  const monthlyLeaveGraphLabels = (data: any[]) =>
    data.map((item) => {
      const [year, month] = item?.month?.split("-"); // Extract year and month
      return new Date(`${year}-${month}-01`)?.toLocaleString("en-US", {
        month: "short",
      });
    });

  const monthlyLeaveGraphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          padding: 8,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => {
            const dataIndex = tooltipItems[0]?.dataIndex;
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        offset: true,
      },
      y: {
        ticks: {
          beginAtZero: true,
          padding: 10,
        },
      },
    },
  };
  const handleTabChange = (newValue: any) => {
    setSelectedTab(newValue);
  };

  const tabs = [
    { label: "Leave", value: "1" },
    { label: "Work From Home", value: "2" },
    { label: "Holiday", value: "3" },
  ];

  const graphTabs = [
    { label: "Leave", value: "leave" },
    { label: "Work From Home", value: "wfh" },
  ];

  const WFHOptions = [
    { label: "All WFH", value: "" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Pending", value: "Pending" },
  ];

  return (
    <div className="flex flex-col gap-3 mt-3">
      <div className="flex justify-between gap-4 flex-wrap items-center ">
        <div className="h-[98px] rounded-lg flex-1 bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-3 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-poppins">
            Total Leave Request
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.totalLeaveRequests ?? 0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-3 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-poppins">
            Approved Leave
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.totalApproved ?? 0}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-3 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-poppins ">
            Pending Leave
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.totalPending ?? 0}{" "}
          </p>
        </div>
        <div className="h-[98px] flex-1 rounded-lg bg-[#FFFFFF] border border-default-gray-200 shadow-sm flex flex-col justify-center items-start pl-3 gap-2">
          <p className="text-[#4B5563] text-base font-medium font-poppins">
            Rejected Leave
          </p>
          <p className="text-2xl font-semibold text-light-black">
            {countList?.totalRejected ?? 0}{" "}
          </p>
        </div>
      </div>
      <div className="flex w-full gap-4">
        <div className="flex flex-col w-1/4 h-[400px] bg-[#FFFFFF] rounded-md border border-default-gray-200 shadow-sm">
          <h2 className="text-[#1F2937] text-[14px] font-medium tracking-[-0.3px] font-poppins mt-3 mb-1 ml-3">
            Leave Request Distribution
          </h2>
          <div className="flex items-center justify-center h-full">
            <div className="w-full max-w-[230px] h-full">
              <DoughnutChart
                chartData={aggregatedLeaveDoughnutData}
                options={optionsDoughtNut}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-3/4 h-[400px] bg-[#FFFFFF] border border-default-gray-200 shadow-sm rounded-md overflow-hidden">
          <h2 className="text-[#1F2937] text-[14px] font-medium leading-[16px] tracking-[-0.3px] font-poppins mt-3 mb-1 ml-3">
            Monthly Leave & WFH Insights
          </h2>

          <div className="px-2 pt-1 min-h-[30vh]">
            <div>
              <TabContext value={selectedTab}>
                <div className="flex w-fit border-b border-gray-200 mx-3">
                  {graphTabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`relative px-2 py-2 text-[14px] font-medium font-poppins cursor-pointer text-[#1F2937]
               ${
                 selectedTab === tab.value
                   ? "border-b-2 border-[#8E1FD2]"
                   : "border-b-2 border-gray-200"
               }
              `}
                      onClick={() => handleTabChange(tab.value)}
                    >
                      {tab.label}
                      {selectedTab === tab.value}
                    </div>
                  ))}
                </div>

                <div className="mt-1 h-[300px] w-full">
                  <LineChart
                    chartData={monthlyLeaveGraphData}
                    labels={monthlyLeaveGraphLabels(graphData.data)}
                    options={monthlyLeaveGraphOptions}
                  />
                </div>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md border border-default-gray-200 shadow-sm p-2">
        <p className="font-poppins text-[14px] font-medium text-[#1F2937] items-center justify-between mb-3 ml-2 mt-2">
          Upcoming Leave, Work From Home and Holiday
        </p>
        <div className="px-2 pt-2">
          <div className="">
            <Box>
              <TabContext value={selected}>
                <div className="flex justify-between flex-wrap gap-y-4">
                  <Box>
                    <CustomTabs tabs={tabs} onChange={handleChange} />
                  </Box>
                </div>
                <TabPanel className="tabpanel" value="1">
                  <UpcomingLeave depId={depId} busId={busId} dates={dates} />
                </TabPanel>
                <TabPanel className="tabpanel" value="2">
                  <UpcomingWFH depId={depId} busId={busId} dates={dates}/>
                </TabPanel>
                <TabPanel className="tabpanel" value="3">
                  <UpcomingHoliday depId={depId} busId={busId} dates={dates} />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default LeaveOverview;
