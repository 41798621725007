import axios from "axios";
import { base_api } from "../../utils/helperData";
import { useDispatch } from "react-redux";
import { revertAll } from "../../redux/ResetRedux/RevertAll";
import store from "../../redux/store";

const axiosInstance = axios.create({
  baseURL: base_api,
  headers: {
    "x-api-key": "your_api_key",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("Session expired. Logging out...");
      localStorage.clear();
      store.dispatch(revertAll());
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
