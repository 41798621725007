import React, { useEffect, useRef, useState } from "react";
import right from "../../../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchEditProject,
  fetchProjectOverview,
} from "../../../common/services/Project";
// import Receipt from "../../ExpenseManagement/ImageView";
import { TabContext, TabPanel } from "@mui/lab";
import CustomTabs from "../../../common/CustomTabs";
import { IResource, Milestone } from "../AddProject/AddProjectDetails";
import { Button } from "../../../common/Button";
import date2 from "../../../../assets/date2.svg";
import DatePicker from "react-datepicker";
import ProjectOverview, { ProjectOverView } from "./ProjectOverview";
import edit from "../../../../assets/Crud_Icons/edit.svg";
import { useDispatch } from "react-redux";
import {
  setProjectEndDate,
  setProjectId,
  setProjectStartDate,
  setProjectTab,
  setViewProjectTab,
} from "../../../../redux/reducers/ProjectManagement/ProjectSlice2";
import { useSelector } from "react-redux";
import Summary from "./Summary";
import Resources from "./Resources";
import toast from "react-hot-toast";
import Tasks from "./Tasks";
import BillingCosting from "./BillingCosting";

interface EmployeeData {
  project_name: string;
  currency: string;
  status: string;
  Client: string;
  estimated_cost: string;
  actual_cost: string;
  start_date: string;
  end_date: string;
  contractual_hours: number | null;
  contractual_cost: number | null;
  actual_hours: string;
  project_type: string;
  approval_code: string;
  description: string;
  type: string;
  milestone: Milestone[];
  resource: IResource[];
}

export interface ApiResponse {
  rows: {
    id: number;
    expense_date: string;
    expense_amount: number;
    project_document: string[];
    receipt_filename: string[];
  }[];
}
const tabs = [
  { label: "Overview", value: "overview" },
  { label: "Summary", value: "summary" },
  { label: "Resources", value: "resources" },
  { label: "Tasks", value: "tasks" },
  { label: "Billing/Costing", value: "billing" },
];

const ViewProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewProjectTab = useSelector(
    (state: any) => state.projectSlice2.view_project_tab
  );
  const projectId = useSelector((state: any) => state.projectSlice2.project_id);
  const location = useLocation();
  const id = location.state?.id;
  const [receipts, setReceipts] = useState<ApiResponse["rows"]>([]);
  const [value, setValue] = React.useState<string>(
    viewProjectTab || "overview"
  );
  const [tableDataOverview, setTableDataOverview] = useState<ProjectOverView>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    // setValue(newValue);
    dispatch(setViewProjectTab(newValue));
  };

  useEffect(() => {
    if (viewProjectTab) {
      setValue(viewProjectTab);
    }
  }, [viewProjectTab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectOverviewData = await fetchProjectOverview(
          projectId || id,
          startDate ? startDate : null,
          endDate ? endDate : null
        );
        if (projectOverviewData?.result) {
          const projectOverview = {
            ProjectId: projectOverviewData?.result?.ProjectId,
            project_name: projectOverviewData?.result?.project_name,
            status: projectOverviewData?.result?.status,
            start_date: projectOverviewData?.result?.start_date,
            end_date: projectOverviewData?.result?.end_date,
            contractual_hours: projectOverviewData?.result?.estimated_hours,
            contractual_cost: projectOverviewData?.result?.contractual_cost,
            resources: {
              total_resource: String(
                projectOverviewData?.result?.resources?.total_resource
              ),
              percentage: String(
                projectOverviewData?.result?.resources?.percentage
              ),
            },
            tasks: {
              final_value: String(
                projectOverviewData?.result?.tasks?.final_value
              ),
              percentage: String(
                projectOverviewData?.result?.tasks?.percentage
              ),
            },
            time_logs: {
              final_value: String(
                projectOverviewData?.result?.time_logs?.final_value
              ),
              percentage: String(
                projectOverviewData?.result?.time_logs?.percentage
              ),
              actual_hours: Number(
                projectOverviewData?.result?.time_logs?.actual_hours
              ),
              logged_hours: Number(
                projectOverviewData?.result?.time_logs?.logged_hours
              ),
            },
          };
          setTableDataOverview(projectOverview);
        }
      } catch (error) {
        toast.error("Error fetching project overview.");
        console.error(error);
      }
    };

    fetchData();
  }, [projectId, id]);

  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    project_name: "",
    currency: "",
    status: "",
    Client: "",
    estimated_cost: "",
    actual_cost: "",
    contractual_hours: 0,
    contractual_cost: 0,
    start_date: "",
    end_date: "",
    actual_hours: "",
    project_type: "",
    approval_code: "",
    description: "",
    type: "",
    milestone: [],
    resource: [],
  });

  useEffect(() => {
    fetchEditProject(id || projectId)
      .then((data) => {
        if (data?.result) {
          setEmployeeData({
            project_name: data?.result?.project_name,
            currency: data?.result?.currency,
            status: data.result?.status,
            Client: data.result?.Client?.client_name,
            estimated_cost: data.result?.estimated_cost,
            actual_cost: data.result?.actual_cost,
            start_date: data.result?.start_date,
            end_date: data.result?.end_date,
            contractual_hours: data.result?.estimated_hours,
            contractual_cost: data.result?.contractual_cost,
            actual_hours: data.result?.actual_hours,
            project_type: data.result?.project_type,
            approval_code: data.result?.approval_code,
            description: data.result?.description,
            type: data.result?.billing_type,
            milestone: data.result?.ProjectMilestones,
            resource: data.result?.ProjectResources,
          });

          dispatch(setProjectId(data?.result?.id));
          dispatch(setProjectStartDate(data.result?.start_date));
          dispatch(setProjectEndDate(data.result?.end_date));

          setReceipts([data?.result]);
        }
      })
      .catch((error) => console.error(error));
  }, [id, projectId]);

  const selectedCalendarRef1 = useRef<HTMLDivElement>(null);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="bg-white pt-6 pb-12 px-[18.5px]">
        <div className="flex flex-row items-center h-9 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-4 w-4 shrink-0 bg-black rounded-full"
              />
            </button>
            <div className="text-black font-['Poppins'] text-base font-medium leading-normal">
              Project Details
            </div>
          </div>
        </div>
        {value !== "summary" && (
          <div className="flex flex-col p-4 border border-gray-200 rounded-lg shadow-sm bg-white mt-2">
            <div className="flex justify-between items-start">
              <div>
                <div className="flex gap-2">
                  <h1 className="text-[#111827] text-xl font-semibold font-inter">
                    {tableDataOverview?.project_name || "-"}
                  </h1>
                  <div className="flex flex-col justify-center items-center gap-[7px] px-[9px] py-0 rounded-[2px] bg-[rgba(57,104,242,0.1)]">
                    <span className="text-[#3968F2] text-base font-medium leading-6 font-inter">
                      {tableDataOverview?.status &&
                        tableDataOverview?.status?.charAt(0)?.toUpperCase() +
                          tableDataOverview?.status?.slice(1)}
                    </span>
                  </div>
                </div>
                <div className="text-base mt-1 leading-6 font-normal text-gray-800">
                  Project ID: {tableDataOverview?.ProjectId}
                </div>
              </div>
              <div className="text-right">
                <div className="text-[#111827] text-base font-semibold leading-6 font-inter">
                  Timeline
                </div>
                <div className="text-[#6B7280] text-base font-normal leading-6 font-inter">
                  {tableDataOverview?.start_date} -{" "}
                  {tableDataOverview?.end_date
                    ? tableDataOverview?.end_date
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        )}

        <TabContext value={value}>
          <div className="flex justify-between items-center w-full py-3">
            <CustomTabs tabs={tabs} onChange={handleTabChange} />
            {value === "overview" && (
              <div
                className="flex h-[42px] p-4 justify-between items-center rounded-md border-[1px] border-solid border-[#E2E8F0] bg-white mr-2"
                ref={selectedCalendarRef1}
              >
                <img
                  src={date2}
                  alt="date icon"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => setOpen(true)}
                />
                <DatePicker
                  className="w-[220px] text-center text-sm font-['Inter'] font-[400] cursor-pointer"
                  dateFormat={"dd MMM yyyy"}
                  onChange={(update) => setDateRange(update)}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  selectsRange
                  isClearable
                  placeholderText="dd/mm/yyyy - dd/mm/yyyy"
                  readOnly
                  onClickOutside={() => setOpen(false)}
                  onFocus={() => setOpen(true)}
                  open={open}
                  customInput={
                    <input
                      style={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                        textAlign: "center",
                        paddingRight: "10px",
                      }}
                    />
                  }
                />
              </div>
            )}
            {value === "summary" && (
              <div>
                <Button
                  variant="gradient"
                  onClick={() => {
                    dispatch(setProjectId(projectId));
                    dispatch(setProjectTab(0));
                    navigate("/edit-project-details", {
                      state: {
                        id: projectId,
                        path: "/view-project-details",
                      },
                    });
                  }}
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={edit}
                    alt="edit icon"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  Edit Details
                </Button>
              </div>
            )}
            {value === "resources" && (
              <div>
                <Button
                  variant="gradient"
                  onClick={() => {
                    dispatch(setProjectId(projectId));
                    dispatch(setProjectTab(1));
                    navigate("/edit-project-details", {
                      state: {
                        id: projectId,
                        path: "/view-project-details",
                      },
                    });
                  }}
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={edit}
                    alt="edit icon"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  Edit Resources
                </Button>
              </div>
            )}
            {value === "tasks" && (
              <div>
                <Button
                  variant="gradient"
                  onClick={() => {
                    dispatch(setProjectId(projectId));
                    dispatch(setProjectTab(2));
                    navigate("/edit-project-details", {
                      state: {
                        id: projectId,
                        path: "/view-project-details",
                      },
                    });
                  }}
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src={edit}
                    alt="edit icon"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  Edit Tasks
                </Button>
              </div>
            )}
          </div>
          <TabPanel className="tabpanel" value="overview">
            <ProjectOverview startDate={startDate} endDate={endDate} />
          </TabPanel>
          <TabPanel className="tabpanel" value="summary">
            <Summary employeeData={employeeData} receipts={receipts} />
          </TabPanel>
          <TabPanel className="tabpanel" value="resources">
            <Resources />
          </TabPanel>
          <TabPanel className="tabpanel" value="tasks">
            <Tasks />
          </TabPanel>
          <TabPanel className="tabpanel" value="billing">
            <BillingCosting
              contractualCost={employeeData?.contractual_cost ?? 0}
            />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default ViewProject;
