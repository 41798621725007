import React, { useCallback, useEffect, useState } from "react";
import DoughnutChart, {
  centerTextPlugin,
} from "../common/Charts/DoughnutChart";
import BarChart from "../common/Charts/BarChart";
import StackedBarChart from "../common/Charts/StackedBarChart";
import Search from "../SearchBar/Search";
import { Button } from "../common/Button";
import { ArrowUpDown, Download } from "lucide-react";
import filter from "../../assets/filter.svg";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  Fetch_Employee_Expense_List,
  Fetch_Expense_Monthly_Graph,
  Fetch_Expense_Stats,
  Fetch_Expense_Type_Chart,
  Fetch_Expense_Type_Graph,
} from "../common/services/EmployeeExpense";
import DynamicTable from "../common/DynamicTable";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import profile from "../../assets/Ellipse 580.png";
import eye from "../../assets/Crud_Icons/view.svg";
import TablePaginationDemo from "../common/Pagenation";
import { api_key_work } from "../../utils/helperData";
import moment from "moment";
import axiosInstance from "../../app/api/axiosInstance";

interface MonthlyExpense {
  month: string;
  totalExpense: number;
}

interface Employee_Expense_List {
  id: string;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  ExpenseCategory: {
    expense_category_name: string;
  };
  expense_date: string;
  expense_amount: string;
  expense_currency: string;
  status: string;
}

interface ReimbursementSummary {
  total_approved_amount: number;
  total_pending_amount: number;
  total_rejected_amount: number;
  reimbursement_count: number;
}
interface ExpenseCategory {
  expense_category_name: string;
}
interface ExpenseStatus {
  category_id: number;
  category_name: string;
  Approved: number;
  Pending: number;
  Rejected: number;
}

interface ExpenseItem {
  category_id: number;
  total_amount: string;
  expense_count: string;
  ExpenseCategory: ExpenseCategory | null;
}
const monthNames: Record<string, string> = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};
const transformMonthlyData = (data: MonthlyExpense[]) => {
  const labels = data?.map((item) => monthNames[item?.month?.split("-")[1]]);
  const chartData = [
    {
      label: "Expense",
      data: data.map((item) => item?.totalExpense),
      color: "#8AB3FF",
    },
  ];
  return { labels, chartData };
};
const colorMapping: Record<string, string> = {
  Food: "#CDEBC5",
  Travel: "#FBD796",
  Other: "#8AB3FF",
};
const transformData = (data: ExpenseItem[]) => {
  let categoryTotals: Record<string, number> = {
    Food: 0,
    Travel: 0,
    Other: 0,
  };

  let total = 0;

  data?.forEach((item) => {
    const categoryName =
      item?.ExpenseCategory?.expense_category_name || "Other";
    const amount = parseFloat(item.total_amount);
    total += amount;

    if (categoryName === "Food" || categoryName === "Travel") {
      categoryTotals[categoryName] += amount;
    } else {
      categoryTotals["Other"] += amount;
    }
  });

  return Object.keys(categoryTotals).map((key) => ({
    label: key,
    value: categoryTotals[key], // Keep zero values to ensure legend appears
    color: colorMapping[key],
  }));
};

const typeColorMapping: Record<string, string> = {
  Approved: "#CDEBC5",
  Pending: "#FBD796",
  Rejected: "#F4C1C1",
};
const typeLabels = ["Food", "Travel", "Other"];
const typeTransformData = (data: ExpenseStatus[]) => {
  let categoryTotals: Record<
    string,
    { Approved: number; Pending: number; Rejected: number }
  > = {
    Food: { Approved: 0, Pending: 0, Rejected: 0 },
    Travel: { Approved: 0, Pending: 0, Rejected: 0 },
    Other: { Approved: 0, Pending: 0, Rejected: 0 },
  };

  data?.forEach((item) => {
    const category =
      item?.category_name === "Food" || item?.category_name === "Travel"
        ? item?.category_name
        : "Other";
    categoryTotals[category].Approved += item.Approved;
    categoryTotals[category].Pending += item.Pending;
    categoryTotals[category].Rejected += item.Rejected;
  });

  return Object?.keys(typeColorMapping)?.map((status) => ({
    label: status,
    data: typeLabels?.map(
      (label) =>
        categoryTotals[label][status as keyof (typeof categoryTotals)["Food"]]
    ),
    color: typeColorMapping[status],
  }));
};

const hoursOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        color: "#1C2024",
        boxWidth: 15,
        boxHeight: 15,
        borderRadius: 4,
      },
    },
    tooltip: {
      enabled: true,
      mode: "index",
      intersect: false,
      backgroundColor: "#fff",
      titleColor: "#474747",
      bodyColor: "#6B7280",
    },
  },
  scales: {
    x: {
      stacked: false,
      grid: { display: false },
    },
    y: {
      stacked: false,
      ticks: {
        callback: (value: number) => `${value / 1000}k`,
        stepSize: 1000,
      },
    },
    barThickness: 10,
  },
  datasets: {
    bar: {
      categoryPercentage: 0.6,
      barPercentage: 0.7,
      borderRadius: { topLeft: 4, topRight: 4, bottomLeft: 0, bottomRight: 0 },
    },
  },
};

export default function ExpenseOverview() {
  const [searchTerm, setSearchTerm] = useState("");
  const filter_data = useSelector(
    (state: RootState) => state.expenseFilterSlice
  );
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const [statusList, setStatusList] = useState<ReimbursementSummary>();
  const [expensechartData, setExpenseChartData] = useState<ExpenseItem[]>([]);
  const [expenseGraphData, setExpenseGraphData] = useState<ExpenseStatus[]>([]);
  const [monthGraphData, setMonthGraphData] = useState<MonthlyExpense[]>([]);
  const [tableData, setTableData] = useState<Employee_Expense_List[]>([]);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const fetchStats = useCallback(async () => {
    try {
      const response = await Fetch_Expense_Stats({
        startDate: filter_data?.date?.[0] ? filter_data?.date?.[0] : null,
        endDate: filter_data?.date?.[1] ? filter_data?.date?.[1] : null,
        group_id: filter_data?.group ? Number(filter_data?.group) : null,
        department_id: filter_data?.department
          ? Number(filter_data?.department)
          : null,
      });
      if (response?.status === 200) {
        setStatusList(response?.data?.result ?? {});
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [filter_data]);
  const fetchExpenseChart = useCallback(async () => {
    try {
      const response = await Fetch_Expense_Type_Chart({
        startDate: filter_data?.date?.[0] ? filter_data?.date?.[0] : null,
        endDate: filter_data?.date?.[1] ? filter_data?.date?.[1] : null,
        group_id: filter_data?.group ? Number(filter_data?.group) : null,
        department_id: filter_data?.department
          ? Number(filter_data?.department)
          : null,
      });
      if (response?.status === 200) {
        setExpenseChartData(
          response?.data?.result ? response?.data?.result : []
        );
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [filter_data]);
  const fetchExpenseGraph = useCallback(async () => {
    try {
      const response = await Fetch_Expense_Type_Graph({
        startDate: filter_data?.date?.[0] ? filter_data?.date?.[0] : null,
        endDate: filter_data?.date?.[1] ? filter_data?.date?.[1] : null,
        group_id: filter_data?.group ? Number(filter_data?.group) : null,
        department_id: filter_data?.department
          ? Number(filter_data?.department)
          : null,
      });
      if (response?.status === 200) {
        setExpenseGraphData(
          response?.data?.result ? response?.data?.result : []
        );
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [filter_data]);
  const fetchExpenseMonthlyGraph = useCallback(async () => {
    try {
      const response = await Fetch_Expense_Monthly_Graph({
        startDate: filter_data?.date?.[0] ? filter_data?.date?.[0] : null,
        endDate: filter_data?.date?.[1] ? filter_data?.date?.[1] : null,
        group_id: filter_data?.group ? Number(filter_data?.group) : null,
        department_id: filter_data?.department
          ? Number(filter_data?.department)
          : null,
      });
      if (response?.status === 200) {
        setMonthGraphData(response?.data?.result ? response?.data?.result : []);
      } else {
        console.error("Error:", response?.status, response?.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [filter_data]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }
  const downloadExcelFile = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      const startSession = localStorage.getItem("start_session");
      const endSession = localStorage.getItem("end_session");

      const session_query =
        startSession && endSession
          ? `&session_start=${startSession}&session_end=${endSession}`
          : "";
      let query = `/api/v1/expense-dashboard-report?customer_id=${customer_id}${session_query}`;

      const response = await axiosInstance.get(`${query}`, {
        responseType: "blob",

        headers: {
          "x-api-key": api_key_work,
          Authorization: "Bearer " + access_token,
        },
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName = "Monthly_Expense_Report.xlsx";

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    fetchExpenseChart();
  }, [fetchExpenseChart]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  useEffect(() => {
    fetchExpenseGraph();
  }, [fetchExpenseGraph]);

  useEffect(() => {
    fetchExpenseMonthlyGraph();
  }, [fetchExpenseMonthlyGraph]);

  const fetchExpenseList = useCallback(async () => {
    try {
      const response = await Fetch_Employee_Expense_List(
        customer_id,
        searchTerm ?? "",
        numOfItems,
        page,
        filter_data?.group ? Number(filter_data?.group) : 0,
        filter_data?.department ? Number(filter_data?.department) : 0,
        filter_data?.date?.[0] ? filter_data?.date?.[0] : null,
        filter_data?.date?.[1] ? filter_data?.date?.[1] : null
      );

      if (response.status === 200) {
        setTableData(
          response.data?.result?.rows ? response.data?.result?.rows : []
        );
        setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [
    customer_id,
    filter_data?.date,
    filter_data?.department,
    filter_data?.group,
    numOfItems,
    page,
    searchTerm,
  ]);

  useEffect(() => {
    fetchExpenseList();
  }, [fetchExpenseList]);

  const typeOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 15,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        padding: 15,
        bodySpacing: 6,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
        footerColor: "#6B7280",
        callbacks: {
          title: (tooltipItems: any) => {
            return tooltipItems[0].label;
          },

          footer: (tooltipItems: any) => {
            const total = tooltipItems?.reduce(
              (sum: number, item: any) => sum + item.raw,
              0
            );
            return `Total: ${total}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
    datasets: {
      bar: {
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    },
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showCenterText: true,
    centerText: `INR ${
      statusList?.total_approved_amount
        ? Number(statusList?.total_approved_amount)?.toFixed(2)
        : 0
    }\n Total Expense`,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#fff",
        titleColor: "#474747",
        bodyColor: "#6B7280",
      },
      centerTextPlugin,
    },
  };
  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  const expenseChartData = transformData(expensechartData);
  const typeGraphData = typeTransformData(expenseGraphData);
  const { labels, chartData } = transformMonthlyData(monthGraphData);

  const columns2: ColumnDef<Employee_Expense_List>[] = [
    {
      accessorKey: "User",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0 pl-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Employee Name & Id
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.User?.firstName ?? "-",
      cell: ({ row }) => {
        const item = row?.original;
        return (
          <div className="flex items-center space-x-2 ">
            <img
              className="w-8 h-8 rounded-full"
              src={item.User.profile_image || profile}
              alt="Profile"
            />
            <div className="flex flex-col justify-between">
              <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                {item.User.firstName} {item.User.lastName}
              </span>
              <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                {item.User.employee_id || ""}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "ExpenseCategory",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Expense Type
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.ExpenseCategory.expense_category_name ?? "-",
      cell: ({ row }) => {
        const name: string =
          row?.original?.ExpenseCategory?.expense_category_name ?? "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "expense_date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) =>
        row?.expense_date
          ? new Date(row.expense_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-",
      cell: ({ row }) => {
        const name: string = row?.original?.expense_date
          ? new Date(row.original.expense_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-";
        return <div className="">{name}</div>;
      },
    },
    {
      accessorKey: "expense_amount",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.expense_amount ?? "-",
      cell: ({ row }) => {
        const name: string = row?.original?.expense_amount;
        return (
          <div className="">
            {name ? `${row?.original?.expense_currency} ${name}` : "-"}
          </div>
        );
      },
    },

    {
      accessorKey: "status",
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      ),
      accessorFn: (row) => row?.status ?? "-",
      cell: ({ row }) => {
        const status = row?.original?.status;

        return status === "Rejected" ? (
          <div className="px-[10px] rounded-full w-fit py-[2px] flex justify-center items-center bg-[#EDCFCF] text-[#EB3F3F]">
            Rejected
          </div>
        ) : status === "Approved" ? (
          <div className="px-[10px] rounded-full py-[2px] w-fit flex justify-center items-center bg-[#E0F1E7] text-[#0F9446]">
            Approved
          </div>
        ) : (
          <div className="px-[10px] rounded-full py-[2px] w-fit flex justify-center items-center bg-[#FEEBDD] text-[#FF9950]">
            Pending
          </div>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <div className="flex justify-end mr-6">
          <Button variant="ghost" className="p-0">
            Action
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end gap-2">
            <Button
              type="button"
              className="p-0"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/view-expense", {
                  state: { id: row?.original?.id },
                });
              }}
            >
              <img
                src={eye}
                alt="View"
                className="p-0.5 hover:bg-[#E6E6E6] rounded-md"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col gap-3 mt-6">
      <div className="flex justify-between gap-4 flex-wrap h-full ">
        <div className=" xl:h-[98px] rounded-lg flex-1   shadow-md flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Total Expense <span className="font-normal">(Approved)</span>
          </p>
          <p className="text-2xl font-semibold text-gray-900">
            INR{" "}
            {statusList?.total_approved_amount
              ? Number(statusList.total_approved_amount).toFixed(2)
              : 0}
          </p>
        </div>{" "}
        <div className="xl:h-[98px]  flex-1 rounded-lg   shadow-md flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Pending Expense
          </p>
          <p className="text-2xl font-semibold text-gray-900">
            INR{" "}
            {statusList?.total_pending_amount
              ? Number(statusList?.total_pending_amount).toFixed(2)
              : 0}
          </p>
        </div>
        <div className=" xl:h-[98px] ] flex-1 rounded-lg  shadow-md flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Rejected Expense
          </p>
          <p className="text-2xl font-semibold text-gray-900">
            INR{" "}
            {statusList?.total_rejected_amount
              ? Number(statusList?.total_rejected_amount).toFixed(2)
              : 0}
          </p>
        </div>
        <div className="xl:h-[98px]  flex-1 rounded-lg  shadow-md flex flex-col justify-center items-start pl-4 gap-2">
          <p className="text-grey300 text-base font-medium font-['Inter']">
            Total Reimbursements
          </p>
          <p className="text-2xl font-semibold text-gray-900">
            {statusList?.reimbursement_count
              ? statusList?.reimbursement_count
              : 0}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 justify-between">
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[100%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Expense Type Distribution
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <DoughnutChart
              chartData={expenseChartData}
              options={defaultOptions}
            />
          </div>
        </div>
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[100%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Monthly Expense Breakdown
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <BarChart
              chartData={chartData}
              labels={labels}
              options={hoursOptions}
              barThickness={10}
            />
          </div>
        </div>
        <div className="bg-[#FCFCFC] border rounded-md p-4 h-[400px] w-[100%]">
          <div className="flex justify-between items-center mb-2">
            <p className="text-light-black font-semibold text-base font-['Poppins']">
              Expense Type Breakdown
            </p>
          </div>
          <div className="flex justify-center w-full h-[91%] mt-2">
            <StackedBarChart
              chartData={typeGraphData}
              labels={typeLabels}
              options={typeOptions}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#FCFCFC] border rounded-md p-4 flex flex-col gap-2">
        <div className="flex justify-between items-center mb-2">
          <p className="text-light-black font-semibold text-base font-['Poppins']">
            This Month Expense
          </p>
        </div>

        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search onChange={updateSearchTerm} label="Search " />
            <div className="h-full flex gap-2 items-center">
              <div className="h-full">
                <Button variant={"border"} onClick={downloadExcelFile}>
                  <Download size={15} strokeWidth={2.5} />
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`table_main_content w-full relative overflow-auto bg-white my-1`}
        >
          <DynamicTable<Employee_Expense_List>
            data={tableData}
            onRowClick={(row) => {
              navigate("/view-expense", {
                state: { id: row?.id },
              });
            }}
            loading={false}
            columns={columns2}
            enableSorting
            enablePagination
          />
        </div>

        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={page}
            numOfItems={numOfItems}
          />
        )}
      </div>
    </div>
  );
}
